import { selectNotificationsState } from './state';
import { createSelector } from '@ngrx/store';
import { MessageTypeEnum } from '@app/common/models/signal.model';


export const selectnotificationError = createSelector(selectNotificationsState, x => x.notificationError);
export const selectallNotifications = createSelector(selectNotificationsState, x => x.notifications);
export const selectNotificationsbyType = (type: MessageTypeEnum) => createSelector(selectNotificationsState, (state) =>
{
  return  state.notifications == undefined || state.notifications.length == 0  ? [] :  state.notifications.filter(f=> f.messageType == type);
}
);
