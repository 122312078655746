import { ListeDto } from "@app/api/filialmatrix/models";

/**Feld und Webapi Aufruf für lookups*/
export class NovitätTableCol {
  field: NovitätEnum;
  weapitype: WebapiType;
}

export enum WebapiType {
  idName = "idname",
  string = "string",
  long = "long",
  /**User als soma contacts */
  lipocontacts = "lipocontacts",
  /**Einkauf als entity */
  einkauf = "einkauf",
  color = "color",
  listentypen = "listentypen",
  int = "int",
  decimal = "decimal",
  listen = "listen",
  merkliste = "merkliste",
  vlbtixliste = "vlbtixliste",
  statussoma = "statussoma",
  statuswup = "statuswup",
  wupzielliste = "wupzielliste",
  pflegestatus = "pflegestatus",
  vpevembm = "vpevembm"
}


export class ListenTypDefaults
{
  static nachListungDto = <ListeDto> {name: 'Nachlistung', id: -1 };
}


export enum ListenTypEnum {
 Merkliste = 1,
 Vorschau = 2,
 VlbTixListe = 4,
 ZiellisteWup  = 8
}


export enum LagerEnum {

  Zentrallager = "ZL",
  Lager_Braunschweig = "LBS"

}
































































































































export enum NovitätEnum {
  /**Regions */
  artikel_region = "artikel.region",
  /**Verlag */
  artikel_hersteller_gruppe = "artikel.hersteller.gruppe",
  artikel_hersteller_gruppe_name = "artikel.hersteller.gruppe.name",
  artikel_hersteller = "artikel.hersteller",
  artikel_hersteller_name = "artikel.hersteller.name",
  platzierungen_sortiment = "platzierungen.sortiment",
  platzierungen_kategorie = "platzierungen.kategorie",
  //User zugriff auf Platzierung und Sortiment
  platzierung_user_first = "platzierungen_user_sortiment_user",
  marketing_ereignisse = "marketing.ereignisse",
  marketing_ereignisse_name = "marketing.ereignisse.name",
  marketing_vorschläge_text = "marketing.vorschläge.text",
  listen = "listen",
  listen2 = "listen2",
  listen3 = "listen3",
  //Merklisten mit istgeteilt
  listen4 = "listen4",
  listen_id = "listen.id",
  listen_name = "listen.name",
  artikel_saison = "artikel.saison",
  einkauf = "einkauf",
  tags_name = "tags.name",
  id = "id",
  kommentare = "kommentare",
  /**Warengruppe */
  artikel_product_productgroup_value = "artikel.product.productgroup",
  artikel_product_productgroup = "artikel.product.productgroup",
  artikel_product_contributors = "artikel.product.contributors",
  sortimente = "sortimente",
  klammern = "klammern",
  einkauf_name = "einkauf.name",
  einkauf_id = "einkauf.id",
  artikel_alter = "artikel.alter",
  artikel_alter_von = "artikel.alter.von",
  artikel_alter_bis = "artikel.alter.bis",
  platzierungen_sortimentsklammer = "platzierungen.sortimentsklammer",
  marketing_wkzstatus = "marketing.wkzstatus",
  platzierungen_stichwort_name = "platzierungen.stichwort.name",
  platzierungen_stichwort = "platzierungen.stichwort",
  hap = "hap",
  hek = "hek",
  artikel_vk = "artikel_vk",
  artikel_uvp = "artikel_uvp",
  merkliste = "merkliste",
  vorschauliste = "vorschauliste",
  vlbtixliste = "vlbtixliste",
  kategorien = "kategorien",
  lipocontacts = "lipocontacts",
  anmerkungen = "anmerkungen",
  statussoma = "statussoma",
  statuswup = "statuswup",
  kommentarewup = "kommentarewup",
  hauptkategorie = "hauptkategorie",
  empfohlenebestellmenge = "empfohlenebestellmenge",
  wupzielliste = "wupzielliste",
  artikel_jwws_info = "artikel.jwws.info",
  statusplatzierung = "statusplatzierung",
  bedarf_puffer_zl = "bedarf_puffer_zl",
  bedarf_exemplare_zl = "bedarf_exemplare_zl",
  bedarf_bestellmenge_zl = "bedarf_bestellmenge_zl",
  bedarf_weiterebedarfe_zl = "bedarf_weiterebedarfe_zl",
  bedarf_offenebestellmenge_zl = "bedarf_offenebestellmenge_zl",
  bedarf_lagerbestand_zl = "bedarf_lagerbestand_zl",
  bedarf_exemplare_lbs = "bedarf_exemplare_lbs",
  artikel_productoverlay_product_productgroup = "artikel_productoverlay_product_productgroup",
  artikel_jwws_pflegestatus = "artikel_jwws_pflegestatus",
  artikel_product_manufacturer = "artikel_product_manufacturer",
  marketing_vorschläge1_text = "marketing.vorschläge1.text",
  datenlieferant = "datenlieferant",
  branch = "branch",
  branchgroup = "branchgroup",
  sortimentsgruppe = "sortimentsgruppe",
  /**Herkunft */
  source = "source",
  vpevembm = "artikel_sets_settype"
}

