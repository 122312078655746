import { FieldModel } from './field.model';

export interface RowModel{
    uploadRowId: string;

    uploadComment?: string;
    lastEditBy: number;
    lastEditAt: Date;
    isValid: boolean;
    validationMessage: string;
    fields: FieldModel[];
}
