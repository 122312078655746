export interface LoggedInUser {
    key: string;
    value: string;
}

export interface LoggedInUserDetail {
    UserToken: string;
    UserName: string;
    UserMin: number;
    UserMax: number;
}
