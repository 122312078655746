/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfParameterDto } from '../models/list-response-args-of-parameter-dto';
import { QueryTokenDTO } from '../models/query-token-dto';
import { ParameterDto } from '../models/parameter-dto';
@Injectable({
  providedIn: 'root',
})
class SortimentParameterService extends __BaseService {
  static readonly SortimentParameterQuerySortimentsParameterPath = '/sortiment/param/s';
  static readonly SortimentParameterGetSortimentsParameterPath = '/sortiment/{sortimentId}/param';
  static readonly SortimentParameterCreateSortimentParameterPath = '/sortiment/{sortimentId}/param';
  static readonly SortimentParameterDeleteSortimentParameterPath = '/sortiment/{sortimentId}/param/{id}';
  static readonly SortimentParameterUpdateSortimentParameterPath = '/sortiment/{sortimentId}/param/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Sortimentsparameter
   * @param params The `SortimentParameterService.SortimentParameterQuerySortimentsParameterParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `onePerAssignment`: False: Alle per Sortimentszuordnung verknüpfte Sortimente sind einzeln in der Ergebnisliste enthalten.
   *   True: Je Sortimentszordnung nur ein Eintrag in der Ergebnisliste.
   *
   * - `eagerLoading`:
   */
  SortimentParameterQuerySortimentsParameterResponse(params: SortimentParameterService.SortimentParameterQuerySortimentsParameterParams): __Observable<__StrictHttpResponse<ListResponseArgsOfParameterDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.onePerAssignment != null) __params = __params.set('onePerAssignment', params.onePerAssignment.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sortiment/param/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfParameterDto>;
      })
    );
  }
  /**
   * Sortimentsparameter
   * @param params The `SortimentParameterService.SortimentParameterQuerySortimentsParameterParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `onePerAssignment`: False: Alle per Sortimentszuordnung verknüpfte Sortimente sind einzeln in der Ergebnisliste enthalten.
   *   True: Je Sortimentszordnung nur ein Eintrag in der Ergebnisliste.
   *
   * - `eagerLoading`:
   */
  SortimentParameterQuerySortimentsParameter(params: SortimentParameterService.SortimentParameterQuerySortimentsParameterParams): __Observable<ListResponseArgsOfParameterDto> {
    return this.SortimentParameterQuerySortimentsParameterResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfParameterDto)
    );
  }

  /**
   * Sortimentsparameter by SortimentId
   * @param params The `SortimentParameterService.SortimentParameterGetSortimentsParameterParams` containing the following parameters:
   *
   * - `sortimentId`:
   *
   * - `stop`:
   *
   * - `start`:
   *
   * - `excludeCurrent`:
   *
   * - `eagerLoading`:
   */
  SortimentParameterGetSortimentsParameterResponse(params: SortimentParameterService.SortimentParameterGetSortimentsParameterParams): __Observable<__StrictHttpResponse<ListResponseArgsOfParameterDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.stop != null) __params = __params.set('stop', params.stop.toString());
    if (params.start != null) __params = __params.set('start', params.start.toString());
    if (params.excludeCurrent != null) __params = __params.set('excludeCurrent', params.excludeCurrent.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sortiment/${encodeURIComponent(String(params.sortimentId))}/param`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfParameterDto>;
      })
    );
  }
  /**
   * Sortimentsparameter by SortimentId
   * @param params The `SortimentParameterService.SortimentParameterGetSortimentsParameterParams` containing the following parameters:
   *
   * - `sortimentId`:
   *
   * - `stop`:
   *
   * - `start`:
   *
   * - `excludeCurrent`:
   *
   * - `eagerLoading`:
   */
  SortimentParameterGetSortimentsParameter(params: SortimentParameterService.SortimentParameterGetSortimentsParameterParams): __Observable<ListResponseArgsOfParameterDto> {
    return this.SortimentParameterGetSortimentsParameterResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfParameterDto)
    );
  }

  /**
   * Create Parameter
   * @param params The `SortimentParameterService.SortimentParameterCreateSortimentParameterParams` containing the following parameters:
   *
   * - `sortimentId`: Sortiment Id
   *
   * - `payload`:
   *
   * - `user`: User Id oder Token
   */
  SortimentParameterCreateSortimentParameterResponse(params: SortimentParameterService.SortimentParameterCreateSortimentParameterParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sortiment/${encodeURIComponent(String(params.sortimentId))}/param`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Create Parameter
   * @param params The `SortimentParameterService.SortimentParameterCreateSortimentParameterParams` containing the following parameters:
   *
   * - `sortimentId`: Sortiment Id
   *
   * - `payload`:
   *
   * - `user`: User Id oder Token
   */
  SortimentParameterCreateSortimentParameter(params: SortimentParameterService.SortimentParameterCreateSortimentParameterParams): __Observable<null> {
    return this.SortimentParameterCreateSortimentParameterResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Delete Parameter
   * @param params The `SortimentParameterService.SortimentParameterDeleteSortimentParameterParams` containing the following parameters:
   *
   * - `sortimentId`: Sortiment Id
   *
   * - `id`: Parameter Id
   *
   * - `user`: User Id oder Token
   */
  SortimentParameterDeleteSortimentParameterResponse(params: SortimentParameterService.SortimentParameterDeleteSortimentParameterParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/sortiment/${encodeURIComponent(String(params.sortimentId))}/param/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete Parameter
   * @param params The `SortimentParameterService.SortimentParameterDeleteSortimentParameterParams` containing the following parameters:
   *
   * - `sortimentId`: Sortiment Id
   *
   * - `id`: Parameter Id
   *
   * - `user`: User Id oder Token
   */
  SortimentParameterDeleteSortimentParameter(params: SortimentParameterService.SortimentParameterDeleteSortimentParameterParams): __Observable<null> {
    return this.SortimentParameterDeleteSortimentParameterResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update Sortimente
   * @param params The `SortimentParameterService.SortimentParameterUpdateSortimentParameterParams` containing the following parameters:
   *
   * - `sortimentId`:
   *
   * - `payload`:
   *
   * - `id`:
   *
   * - `user`:
   */
  SortimentParameterUpdateSortimentParameterResponse(params: SortimentParameterService.SortimentParameterUpdateSortimentParameterParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/sortiment/${encodeURIComponent(String(params.sortimentId))}/param/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update Sortimente
   * @param params The `SortimentParameterService.SortimentParameterUpdateSortimentParameterParams` containing the following parameters:
   *
   * - `sortimentId`:
   *
   * - `payload`:
   *
   * - `id`:
   *
   * - `user`:
   */
  SortimentParameterUpdateSortimentParameter(params: SortimentParameterService.SortimentParameterUpdateSortimentParameterParams): __Observable<null> {
    return this.SortimentParameterUpdateSortimentParameterResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module SortimentParameterService {

  /**
   * Parameters for SortimentParameterQuerySortimentsParameter
   */
  export interface SortimentParameterQuerySortimentsParameterParams {
    queryToken: QueryTokenDTO;

    /**
     * False: Alle per Sortimentszuordnung verknüpfte Sortimente sind einzeln in der Ergebnisliste enthalten.
     * True: Je Sortimentszordnung nur ein Eintrag in der Ergebnisliste.
     */
    onePerAssignment?: boolean;
    eagerLoading?: number;
  }

  /**
   * Parameters for SortimentParameterGetSortimentsParameter
   */
  export interface SortimentParameterGetSortimentsParameterParams {
    sortimentId: number;
    stop?: null | string;
    start?: null | string;
    excludeCurrent?: boolean;
    eagerLoading?: number;
  }

  /**
   * Parameters for SortimentParameterCreateSortimentParameter
   */
  export interface SortimentParameterCreateSortimentParameterParams {

    /**
     * Sortiment Id
     */
    sortimentId: number;
    payload: ParameterDto;

    /**
     * User Id oder Token
     */
    user?: null | string;
  }

  /**
   * Parameters for SortimentParameterDeleteSortimentParameter
   */
  export interface SortimentParameterDeleteSortimentParameterParams {

    /**
     * Sortiment Id
     */
    sortimentId: number;

    /**
     * Parameter Id
     */
    id: number;

    /**
     * User Id oder Token
     */
    user?: null | string;
  }

  /**
   * Parameters for SortimentParameterUpdateSortimentParameter
   */
  export interface SortimentParameterUpdateSortimentParameterParams {
    sortimentId: number;
    payload: ParameterDto;
    id: number;
    user?: null | string;
  }
}

export { SortimentParameterService }
