   <mat-sidenav-container class="sidenav-container">
    <mat-sidenav [ngClass]=" getSideNavClass(opened)" [opened]="true" hasBackdrop="false" #drawer  fixedInViewport="true" [attr.role]="'navigation'"   mode="side">
      <mat-nav-list class="navigation-list" *ngFor="let item of navitems$ | async">
          <mat-list-item [id]="'mat-list-item-' + item.id"  class="navigation-item"  [ngClass]="item.active" (click)="select($event,item)"          >
            <button [id]="'button-' + item.id"  [ngClass]="'button-menu-item' +  item?.active"   [matBadgeHidden]="getItemParentCount(item) < 1" matBadgeSize="small" matBadgeColor="warn" [matBadge]="getItemParentCount(item)"         >
            <mat-icon [ngClass]="item.iconClass" [title]="item.tooltip"    >
              {{item.icon}}
            </mat-icon>
            </button>
            <span *ngIf="opened == true" class="navigation-item-label">{{item.label}}</span>
          </mat-list-item>

        <div style="visibility: hidden; position: fixed;" [style.left]="contextMenuPosition.x" class="matpanel"
          [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
        </div>
        <mat-menu #contextMenu="matMenu" class="matpanel">
          <ng-template matMenuContent let-item="item">
            <mat-list-item class="grid sublistItem" [id]="'mat-list-item-' + subitem.id"  *ngFor="let subitem of item.children" (click)="select($event, subitem)" >
              <button  [ngClass]="' col button-menu-item' +  subitem?.active" [id]="'button-' + subitem.id"
              [matBadgeHidden]="!subitem.notificationCount || subitem.notificationCount == 0"
              matBadgeSize="small"
              matBadgeColor="warn" [matBadge]="subitem.notificationCount"              >
            <mat-icon [ngClass]="'subMenuIcon' + subitem?.active + ' '  + subitem.iconClass" [title]="subitem.tooltip"   >            {{subitem.icon}}          </mat-icon>
          </button>
          <span  class=" col mat-item-label"> {{subitem.label}} </span>
            </mat-list-item>
          </ng-template>
        </mat-menu>
        <mat-divider *ngIf="item.seperator" class="navigation-divider"></mat-divider>
      </mat-nav-list>
      <mat-nav-list style="bottom: 0rem; padding-bottom: 0rem !important; position: fixed;" class="navigation-list">

        <a mat-list-item class="menu-button" aria-label="Toggle sidenav" (click)="toggle()">
          <i [title]="opened == true ? 'Verkleinern' : 'Vergrößern'" class="material-icons">{{getIconInputOut()}}</i>
        </a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content [ngClass]="getContentClass(opened)">
      <mat-toolbar class="sidenav-content-toolbar">
        <div class="filler"  >
          <div class="grid" *ngIf="headerHided === false" >
          <div class="col-4">
            <a href="https://www.hugendubel.com/impressum.html" target="_blank"><img [src]="getLogoPath()" id="logo_hugendubel"
                style="padding: 1rem; width: 15rem; height: auto;" /></a>
          </div>
          <div class="col-2" style="padding-top: 1rem; ">
            <div id="headerstartseite" class="h1Header">{{getHeader() | async}} </div>
          </div>
          <div *ngIf="canNotification()" class="col-1">
          <div  style=" float:right; bottom: 0; margin-right: 1rem; margin-top: 0.5rem; "
          [matBadge]="notificationCountAll$ | async" matBadgeSize="small"
          [matBadgeHidden]="!(notificationCountAll$ | async) || (notificationCountAll$ | async) == 0"
          matBadgeColor="warn"          >
            <mat-icon  (click)="gotoDashboard()" class="noticationIcon"
            >notifications_none
            </mat-icon>
          </div>
          </div>
          <div class="col-2" style="padding-top: 0.5rem;">
            <p-menubar [model]="fontsizeitems"></p-menubar>
          </div>
          <div class="col-1" style="padding-top: 0.5rem; ">
            <p-inputSwitch [pTooltip]="'Hell/Dunkel'" (onChange)="changetheme($event)"
              [ngModel]="getSelectedstyleitem()?.name == 'dark'"></p-inputSwitch>
          </div>
          <div class="col" style="padding-top: 0.5rem; ">
            <p-menubar id="sessionmessage"  appendTo="body" [pTooltip]="sessionMessage$ | async" [model]="items"  tooltipPosition="left" ></p-menubar>
          </div>
        </div>
        <mat-icon id="toggleHeader" style="top: 0; float: right" class="defaultIcon material-icons-outlined" (click)="headerToggle($event)" >{{headerToggledIcon() }}</mat-icon>
      </div>
      </mat-toolbar>

      <router-outlet></router-outlet>


    </mat-sidenav-content>
  </mat-sidenav-container>


