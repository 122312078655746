<span>
    <hr>
    <div class="ui-g ui-fluid">
        <div class="ui-g-12 ui-md-12">

            <h5>{{ breadcrumb$ | async }}</h5>
            <hr />
            <h4>{{ taskToView.name }}</h4>

            <br>

            <b>Von:</b> {{ taskToView.creatorFriendlyName }} <b>An:</b> {{ taskToView.fullName }}<br><br>
            <b>Beschreibung:</b> <div [innerHTML] = "convertCarriageReturn(taskToView.taskDescription)"></div>
            <hr>
            <div *ngIf="taskToView.sortimentName">
                <b>Sortiment:</b> {{taskToView.sortimentName}} <br>
            </div>
            <b>Bearbeitungszeitraum:</b> {{ taskToView.taskValidRangeFrom | date }} bis {{ taskToView.taskValidRangeTo | date }}<br>
            <b>Laufzeit:</b> {{ taskToView.taskActiveRangeFrom | date }} bis {{ taskToView.taskActiveRangeUntil | date }}<br>
            <hr>
            <b>Titel Minimum:</b> {{taskToView.min}} Titel Maximum: {{taskToView.max}}<br>
            <hr>
            <div *ngIf="taskToView.fileUrls !== null && taskToView.fileUrls.length > 0">
                <table>
                    <tr *ngFor="let fileUrl of taskToView.fileUrls">
                        <td><a href="#" (click)="downloadFile(fileUrl.url, fileUrl.fileType, fileUrl.fileName, $event)">{{fileUrl.fileName}}</a></td>
                    </tr>
                </table>
            </div>
            <div *ngIf="taskToView.includeUpload == true && taskToView.taskState < 500"><a href="#" (click)="downloadListForTask($event)">Excel-Vorlage laden</a></div>
            <!-- <b>Status:</b> not yet implemented<br> -->
            <!-- <span *ngIf="taskModel.hasMessage"> Der Empfänger wurde Benachrichtigt.</span>
            <span *ngIf="!taskModel.hasMessage"><em>Eine Benachrichtigung wird noch versendet</em></span> -->
            <br>
            <!-- <span *ngIf="taskToView.includeUpload && !isManager"><button pButton label="Keine Liste abgeben"
                pTooltip="Die Aufgabe wird übersprungen. Sie erklären damit, dass von Ihnen keine Vorschläge zur Aufgabe eingereicht werden." tooltipPosition="top"
                class="p-button-warning" (onClick)="skip(taskToView.id)"></button> </span> -->
        </div>
    </div>
</span>
