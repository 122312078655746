import { ColumnTypes } from "./columnBase.model";

export enum  SettingValueTypeEnum
{
  unknown = 0,
  Default = 1,
  Object = 2,
  SettingwithChildren = 3,
}


export enum SettingValueEnum {
  /// <summary>
  /// Medienpräsenz
  /// </summary>
  MedienPräsenz = 1,
  /// <summary>
  /// Typ oder Art der Präsenz z.b. TV
  /// </summary>
  Art = 2,
  WupZiellisten = 3,
  WupZeiträume = 4,
  Downloadlinks = 5,
  WupListenSetting = 6,
  //Farbauswahl bei Soma
  FarbauswahlSoma = 7,
  DBMapping = 8,
}


export interface ISetting
{
  id : number;
  name: string;
  label: string;
  valueType: SettingValueTypeEnum;
}

export interface ISettingValue
{
    id: number;
    setting_Id : SettingValueEnum;
    /**Der Setting Wert */
    settingValue: string;
    isActive: number;
}


export interface ISettingChildrenValue
{
    id: number;
    lipo_Setting_Values_Id: number;
    lipo_Settings_Child_Id : SettingValueEnum;
    /**Der Setting Wert */
    settingValue: any;
    isActive: number;
    //Bezeichnung des Wertes
    label: string;
    name:string;
    valueType: ColumnTypes;
}

