import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { GetDashboardSumAction, GetNoviHitsAction, selectDashboardSum, selectNovitaetenhits, selecthasSOMADiagramm } from "../store";
import { NovitaetenLookupService } from "./NovitaetenLookupService";
import { combineLatest, filter, map, take } from "rxjs";
import { QueryTokenDTO } from "@app/api/filialmatrix/models";
import { AuthService } from "@app/common/services/auth.service";
import { LogUsersClientErrorAction } from "@app/store/user/actions";
import { IClientError } from "@app/common/interfaces/Interfaces";
import { DashboardService } from "@app/api/filialmatrix/services";

@Injectable()
export class StatistikService {


  selecthasSOMADiagramm$ = this.store.pipe(select(selecthasSOMADiagramm));
  selectSOMADiagramm$ = combineLatest([this.store.pipe(select(selectNovitaetenhits)), this.store.pipe(select(selectDashboardSum))]).pipe(map(([hits, sum]) => {
    var array = new Array<any>;
    if (sum && Object.keys(sum).length > 0) {
      const orientierung = {
        "name": "Korridor A - P",
        "series": [
          {
            "name": "Orientierung",
            "value": sum['Orientierung'] ?? 0
          },
          {
            "name": "Aktueller Einkauf",
            "value": sum['Gesamt'] ?? 0
          }
        ]
      }
      array.push(orientierung);
    }

    if (hits && Object.keys(hits).length > 0) {
      const platzierung = {
        "name": "Titel ohne Platzierung",
        "series": [
          {
            "name": "Bereit für Übergabe an WUP",
            "value": hits['platzierungen2'] ?? 0
          },
          {
            "name": "In Bearbeitung",
            "value": hits['platzierungen1'] ?? 0
          }
        ]
      }
      array.push(platzierung);
    }


    return array;


  }))

  selectTitel$ = this.novilookupService.selectAktuellerEinkauf$.pipe(map(m => {
    if (m == undefined && m.name == undefined) return 'Übersicht Novitäten';
    return 'Übersicht Novitäten ' + m.name;
  }));



  constructor(private store: Store<any>, private novilookupService: NovitaetenLookupService, private authuser: AuthService) { }

  /**Starten der Dashboard Statistik Anzeige
   * nur Soma User
   * mit aktuellem Einkauf
   */
  loadDashboardStatistik() {

    this.authuser.getLipoUser().subscribe(user => {
      try {
        //Nur bei Soma laden
        if (user.ismanager == false) return;
        this.novilookupService.loadAktuellerEinkauf();

        this.novilookupService.selectAktuellerEinkauf$.pipe(filter(f => f != undefined && f.id != undefined), take(1)).subscribe(einkauf => {

          /**
   * Status SOMA
   * NotSet = 0 (Unbekannt)
   * Neu = 1 (Neu)
   * UebergabeMoeglich = 2 (Übergabe an WUP möglich)
   * BereitFuerUebergabe = 4 (Bereit für Übergabe an WUP)
   * Ueberabe = 8 (Übergabe an WUP)
   * UeberabeErfolgt = 16 (Übergabe an WUP erfolgt)
   * Geloescht = 4096 (Gelöscht)
   */

          var hitparams: { [key: string]: QueryTokenDTO } = {};
          //offen
          hitparams["platzierungen1"] = <QueryTokenDTO>{
            filter: {
              "einkauf.id.equals": einkauf.id.toString(),
              "platzierungen.user.sortiment.user.benutzer.equals": user.shortId + "|||" + user.shortId + "|||" + user.shortId,
              "platzierungen.user.sortiment.statussoma.equals" : user.shortId + "|||2||1",
              "statussoma.equals" : "1||2||4||8"
            }
          }
          //übergabe erfolgt
          hitparams["platzierungen2"] = <QueryTokenDTO>{
            filter: {
              "einkauf.id.equals": einkauf.id.toString(),
              "platzierungen.user.sortiment.user.benutzer.equals": user.shortId + "|||" + user.shortId + "|||" + user.shortId,
              "platzierungen.user.sortiment.statussoma.equals" : user.shortId + "|||4||8||16",
              "statussoma.equals" : "1||2||4||8||16"
            }
          }
          this.store.dispatch(new GetNoviHitsAction({ params: hitparams }));

          //Dashboard Summen
          const pa = <DashboardService.DashboardGetDashboardSumParams>  {};
          let sumparams =  { filter: {}, input: {} };
          sumparams.filter["einkauf.id.equals"] = einkauf.id.toString();
          sumparams.filter["platzierungen.user.sortiment.user.benutzer.equals"] = user.shortId + '|||' + user.shortId + '|||' + user.shortId,
            sumparams.filter["statussoma.equals"] = "1||2||4||8||16",
            sumparams.input["row"] = "KAT";
          sumparams.input["columns"] = "WG";
          sumparams.input["values"] = "Anzahl";
          pa.queryToken = sumparams;
          pa.bestellmengeNullBerCksichtigen = true;
          this.store.dispatch(new GetDashboardSumAction({ param: pa }));

        })
      } catch (e) {
        this.store.dispatch(new LogUsersClientErrorAction({ error: <IClientError>{ userid: user?.id, modul: 'Statistik-Service', error: e } }));
      }
    }).unsubscribe()


  }

}
