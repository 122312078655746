/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfIDictionaryOfIntegerAndIDictionaryOfStringAndPlanogramElementOf } from '../models/response-args-of-idictionary-of-integer-and-idictionary-of-string-and-planogram-element-of';
import { ResponseArgsOfIEnumerableOfArticlePlacementTypeChange } from '../models/response-args-of-ienumerable-of-article-placement-type-change';
import { ListResponseArgsOfSnapshotDto } from '../models/list-response-args-of-snapshot-dto';
import { ListResponseArgsOfSnapshotModel } from '../models/list-response-args-of-snapshot-model';
import { SnapshotParameterModel } from '../models/snapshot-parameter-model';
@Injectable({
  providedIn: 'root',
})
class SnapshotService extends __BaseService {
  static readonly SnapshotGetPlanogramDataPath = '/adv/snapshot/planogram';
  static readonly SnapshotGetPlacementChangesPath = '/adv/snapshot/placementchanges';
  static readonly SnapshotGetActiveSnapshotsPath = '/adv/snapshot/active/{tenantKey}/{branchNo}';
  static readonly SnapshotGetBubIdsPath = '/adv/snapshot/bubids';
  static readonly SnapshotGetPlatzierungenPath = '/adv/snapshot/platzierungen';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get Planogram Data by Snapshot Id and Module No
   * @param params The `SnapshotService.SnapshotGetPlanogramDataParams` containing the following parameters:
   *
   * - `snapshotId`:
   *
   * - `moduleNo`:
   */
  SnapshotGetPlanogramDataResponse(params: SnapshotService.SnapshotGetPlanogramDataParams): __Observable<__StrictHttpResponse<ResponseArgsOfIDictionaryOfIntegerAndIDictionaryOfStringAndPlanogramElementOf>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.snapshotId != null) __params = __params.set('snapshotId', params.snapshotId.toString());
    if (params.moduleNo != null) __params = __params.set('moduleNo', params.moduleNo.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/adv/snapshot/planogram`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIDictionaryOfIntegerAndIDictionaryOfStringAndPlanogramElementOf>;
      })
    );
  }
  /**
   * Get Planogram Data by Snapshot Id and Module No
   * @param params The `SnapshotService.SnapshotGetPlanogramDataParams` containing the following parameters:
   *
   * - `snapshotId`:
   *
   * - `moduleNo`:
   */
  SnapshotGetPlanogramData(params: SnapshotService.SnapshotGetPlanogramDataParams): __Observable<ResponseArgsOfIDictionaryOfIntegerAndIDictionaryOfStringAndPlanogramElementOf> {
    return this.SnapshotGetPlanogramDataResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIDictionaryOfIntegerAndIDictionaryOfStringAndPlanogramElementOf)
    );
  }

  /**
   * Liefert eine Liste der Artikel mit Änderungen der Platzierung
   * @param params The `SnapshotService.SnapshotGetPlacementChangesParams` containing the following parameters:
   *
   * - `stop`: Zeitraum der Änderung - Stop (excluded)
   *
   * - `start`: Zeitraum der Änderung - Start
   *
   * - `key`: Sortimentstyp A - Aktion, T - Themanliste,  B - Basisortiment, S - Standartsortiment
   *
   * - `f4`: SharplyIncreasing factor (default: 1.5)
   *
   * - `f3`: Increasing factor (default: 1.25)
   *
   * - `f2`: Decreasing factor (default: 0.8)
   *
   * - `f1`: SharplyDecreasing factor (default: 0.3)
   *
   * - `changeTypes`: Art der Änderung(en) - Bitflag
   *               NotSet              =  0,
   *               New                 =  1 (Einlistung)
   *               Removed             =  2 (Auslistung)
   *               Increasing          =  4 (Steigend)
   *               SharplyIncreasing   =  8 (Stark Steigend)
   *               Decreasing          = 16 (Fallend)
   *               SharplyDecreasing   = 32 (Stark Fallend)
   */
  SnapshotGetPlacementChangesResponse(params: SnapshotService.SnapshotGetPlacementChangesParams): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfArticlePlacementTypeChange>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.stop != null) __params = __params.set('stop', params.stop.toString());
    if (params.start != null) __params = __params.set('start', params.start.toString());
    (params.key || []).forEach(val => {if (val != null) __params = __params.append('key', val.toString())});
    if (params.f4 != null) __params = __params.set('f4', params.f4.toString());
    if (params.f3 != null) __params = __params.set('f3', params.f3.toString());
    if (params.f2 != null) __params = __params.set('f2', params.f2.toString());
    if (params.f1 != null) __params = __params.set('f1', params.f1.toString());
    if (params.changeTypes != null) __params = __params.set('changeTypes', params.changeTypes.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/adv/snapshot/placementchanges`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfArticlePlacementTypeChange>;
      })
    );
  }
  /**
   * Liefert eine Liste der Artikel mit Änderungen der Platzierung
   * @param params The `SnapshotService.SnapshotGetPlacementChangesParams` containing the following parameters:
   *
   * - `stop`: Zeitraum der Änderung - Stop (excluded)
   *
   * - `start`: Zeitraum der Änderung - Start
   *
   * - `key`: Sortimentstyp A - Aktion, T - Themanliste,  B - Basisortiment, S - Standartsortiment
   *
   * - `f4`: SharplyIncreasing factor (default: 1.5)
   *
   * - `f3`: Increasing factor (default: 1.25)
   *
   * - `f2`: Decreasing factor (default: 0.8)
   *
   * - `f1`: SharplyDecreasing factor (default: 0.3)
   *
   * - `changeTypes`: Art der Änderung(en) - Bitflag
   *               NotSet              =  0,
   *               New                 =  1 (Einlistung)
   *               Removed             =  2 (Auslistung)
   *               Increasing          =  4 (Steigend)
   *               SharplyIncreasing   =  8 (Stark Steigend)
   *               Decreasing          = 16 (Fallend)
   *               SharplyDecreasing   = 32 (Stark Fallend)
   */
  SnapshotGetPlacementChanges(params: SnapshotService.SnapshotGetPlacementChangesParams): __Observable<ResponseArgsOfIEnumerableOfArticlePlacementTypeChange> {
    return this.SnapshotGetPlacementChangesResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfArticlePlacementTypeChange)
    );
  }

  /**
   * Aktive Snapshots einer Filiale
   * @param params The `SnapshotService.SnapshotGetActiveSnapshotsParams` containing the following parameters:
   *
   * - `tenantKey`: Key Mandant
   *
   * - `branchNo`: Kostenstelle
   */
  SnapshotGetActiveSnapshotsResponse(params: SnapshotService.SnapshotGetActiveSnapshotsParams): __Observable<__StrictHttpResponse<ListResponseArgsOfSnapshotDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/adv/snapshot/active/${encodeURIComponent(String(params.tenantKey))}/${encodeURIComponent(String(params.branchNo))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfSnapshotDto>;
      })
    );
  }
  /**
   * Aktive Snapshots einer Filiale
   * @param params The `SnapshotService.SnapshotGetActiveSnapshotsParams` containing the following parameters:
   *
   * - `tenantKey`: Key Mandant
   *
   * - `branchNo`: Kostenstelle
   */
  SnapshotGetActiveSnapshots(params: SnapshotService.SnapshotGetActiveSnapshotsParams): __Observable<ListResponseArgsOfSnapshotDto> {
    return this.SnapshotGetActiveSnapshotsResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfSnapshotDto)
    );
  }

  /**
   * Ids der Artikel in Aktiven Snapshots
   * @param snapshotParams Abfragedaten
   */
  SnapshotGetBubIdsResponse(snapshotParams: SnapshotParameterModel): __Observable<__StrictHttpResponse<ListResponseArgsOfSnapshotModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = snapshotParams;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/adv/snapshot/bubids`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfSnapshotModel>;
      })
    );
  }
  /**
   * Ids der Artikel in Aktiven Snapshots
   * @param snapshotParams Abfragedaten
   */
  SnapshotGetBubIds(snapshotParams: SnapshotParameterModel): __Observable<ListResponseArgsOfSnapshotModel> {
    return this.SnapshotGetBubIdsResponse(snapshotParams).pipe(
      __map(_r => _r.body as ListResponseArgsOfSnapshotModel)
    );
  }

  /**
   * Ids der Artikel in Aktiven Snapshots
   * @param snapshotParams Abfragedaten
   */
  SnapshotGetPlatzierungenResponse(snapshotParams: SnapshotParameterModel): __Observable<__StrictHttpResponse<ListResponseArgsOfSnapshotModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = snapshotParams;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/adv/snapshot/platzierungen`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfSnapshotModel>;
      })
    );
  }
  /**
   * Ids der Artikel in Aktiven Snapshots
   * @param snapshotParams Abfragedaten
   */
  SnapshotGetPlatzierungen(snapshotParams: SnapshotParameterModel): __Observable<ListResponseArgsOfSnapshotModel> {
    return this.SnapshotGetPlatzierungenResponse(snapshotParams).pipe(
      __map(_r => _r.body as ListResponseArgsOfSnapshotModel)
    );
  }
}

module SnapshotService {

  /**
   * Parameters for SnapshotGetPlanogramData
   */
  export interface SnapshotGetPlanogramDataParams {
    snapshotId?: number;
    moduleNo?: number;
  }

  /**
   * Parameters for SnapshotGetPlacementChanges
   */
  export interface SnapshotGetPlacementChangesParams {

    /**
     * Zeitraum der Änderung - Stop (excluded)
     */
    stop?: null | string;

    /**
     * Zeitraum der Änderung - Start
     */
    start?: null | string;

    /**
     * Sortimentstyp A - Aktion, T - Themanliste,  B - Basisortiment, S - Standartsortiment
     */
    key?: null | Array<string>;

    /**
     * SharplyIncreasing factor (default: 1.5)
     */
    f4?: number;

    /**
     * Increasing factor (default: 1.25)
     */
    f3?: number;

    /**
     * Decreasing factor (default: 0.8)
     */
    f2?: number;

    /**
     * SharplyDecreasing factor (default: 0.3)
     */
    f1?: number;

    /**
     * Art der Änderung(en) - Bitflag
     *             NotSet              =  0,
     *             New                 =  1 (Einlistung)
     *             Removed             =  2 (Auslistung)
     *             Increasing          =  4 (Steigend)
     *             SharplyIncreasing   =  8 (Stark Steigend)
     *             Decreasing          = 16 (Fallend)
     *             SharplyDecreasing   = 32 (Stark Fallend)
     */
    changeTypes?: null | number;
  }

  /**
   * Parameters for SnapshotGetActiveSnapshots
   */
  export interface SnapshotGetActiveSnapshotsParams {

    /**
     * Key Mandant
     */
    tenantKey: string;

    /**
     * Kostenstelle
     */
    branchNo: string;
  }
}

export { SnapshotService }
