export enum ValidationResults
{
    Success = 0,
    Error = 1,
    Warning = 2    
}

export interface ValidResult {
    result: ValidationResults;
    validationMessage: string;
}
