
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { ISetting, ISettingChildrenValue, ISettingValue, SettingValueTypeEnum } from '../models/setting.models';
import { Observable } from 'rxjs';
import { ResultObjectModel } from '../models';
import { OAuthService } from 'angular-oauth2-oidc';
import { IClientError } from '../interfaces/Interfaces';
import { ISuppliergroup, ISupplier, IBranchgroup, IBranch, IBranchbyGroup } from '../models/suppliergroup.model';
import { CmfUserDataModel } from '../models/cmfUserData.model';
import { AuthService } from './auth.service';
import { ILookupValueBase } from '@app/+katalog/app/interfaces/Lookup-interfaces';

/**Service der Settings / Einstellungen    */
@Injectable({ providedIn: 'root' })
export class SettingService {







  constructor(private authService: AuthService, private httpClient: HttpClient) {

  }

  /**Logging von clientseitigen Errors, wenn Service erreichbar. */
  logClientError(error: IClientError) : Observable<any> {
    console.debug( "Error in " +  error.modul + " ->  " + JSON.stringify(error.error) );
    const url = `${environment.connections.SettingService}/errorsetting/logerror`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.httpClient.post<any>(url, error, httpOptions);
  }


  /**Alle Ids die gelöscht werden dürfen, Prüfung auf Verwendung in Umlaufdaten  */
  canDeleteSettings(id: number): Observable<ResultObjectModel<string[]>> {
    const url = `${environment.connections.SettingService}/setting/CanDeleteSettingValues`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { settingId: id.toString() }
    };
    return this.httpClient.get<ResultObjectModel<string[]>>(url, httpOptions);
  }

  getFileModelAction(id: number) : Observable<ResultObjectModel<any>> {
    const url = `${environment.connections.SettingService}/setting/GetFileModel`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { id: id.toString() }
    };
    return this.httpClient.get<ResultObjectModel<any>>(url, httpOptions);
  }




  getLookupValuesbyNameAction(name: string) : Observable<ResultObjectModel<ILookupValueBase[]>> {
    const url = `${environment.connections.SettingService}/setting/GetLookupListbyName`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { listName: name }
    };
    return this.httpClient.get<ResultObjectModel<ILookupValueBase[]>>(url, httpOptions);
  }


  /**Alle Settins plus die Überschrift und Format holen */
  getAllSettings( ): Observable<ResultObjectModel<ISetting[]>> {
    const url = `${environment.connections.SettingService}/setting/getAllSettings`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    return this.httpClient.get<ResultObjectModel<ISetting[]>>(url, httpOptions);
  }

  getSettings(id: number, onlyActive: boolean): Observable<ResultObjectModel<ISettingValue[]>> {
    const url = `${environment.connections.SettingService}/setting/getSettings`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { settingId: id.toString(), onlyActive: onlyActive.toString() }
    };
    return this.httpClient.get<ResultObjectModel<ISettingValue[]>>(url, httpOptions);
  }


  getSettingsChildren(settingvalueId: number) {
    const url = `${environment.connections.SettingService}/setting/GetSettingChildren`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { settingvalueId: settingvalueId.toString() }
    };
    return this.httpClient.get<ResultObjectModel<ISettingChildrenValue[]>>(url, httpOptions);
  }




  deleteBranchGroup(id: number) : Observable<ResultObjectModel<number>> {
    const url = `${environment.connections.SettingService}/setting/deleteBranchGroup`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { id: id.toString() }
    };
    return this.httpClient.get<ResultObjectModel<number>>(url, httpOptions);
  }


  deleteSettingValue(id: number): Observable<ResultObjectModel<number>> {
    const url = `${environment.connections.SettingService}/setting/deleteSettingValue`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { settingId: id.toString() }
    };
    return this.httpClient.get<ResultObjectModel<number>>(url, httpOptions);
  }

  insertSettingValue(newsetting: ISettingValue): Observable<ResultObjectModel<ISettingValue>> {
    const url = `${environment.connections.SettingService}/setting/insertSettingValue`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    return this.httpClient.post<ResultObjectModel<ISettingValue>>(url, newsetting, httpOptions);
  }


  updateSettingChildrenValueAktion(settingvalueId: number, item: ISettingChildrenValue) : Observable<ResultObjectModel<ISettingChildrenValue>> {
    const url = `${environment.connections.SettingService}/setting/updateSettingChildrenvalue/`+ settingvalueId.toString();
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    return this.httpClient.post<ResultObjectModel<ISettingChildrenValue>>(url, item, httpOptions);
  }



  updateSettingValue(setting: ISettingValue): Observable<ResultObjectModel<ISettingValue>> {
    const url = `${environment.connections.SettingService}/setting/updateSettingvalue`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    return this.httpClient.post<ResultObjectModel<ISettingValue>>(url, setting, httpOptions);
  }


  updateBranchGroup(branch: IBranchbyGroup) : Observable<ResultObjectModel<IBranchbyGroup>> {
    const url = `${environment.connections.SettingService}/setting/updateBranchGroup`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    return this.httpClient.post<ResultObjectModel<IBranchbyGroup>>(url, branch, httpOptions);
  }


  getBranchesbyGroup(groupid: number): Observable<ResultObjectModel<IBranchbyGroup[]>> {
    const url = `${environment.connections.SettingService}/setting/getBranchesbyGroup`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { groupid: groupid.toString() }
    };
    return this.httpClient.get<ResultObjectModel<IBranchbyGroup[]>>(url, httpOptions);
  }


  getBranchGroups(): Observable<ResultObjectModel<IBranchgroup[]>> {
    const url = `${environment.connections.SettingService}/setting/getBranchGroups`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    return this.httpClient.get<ResultObjectModel<IBranchgroup[]>>(url, httpOptions);
  }


  getSuppliergroups(): Observable<ResultObjectModel<ISuppliergroup[]>> {
    const url = `${environment.connections.SettingService}/setting/getSupplierGroups`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    return this.httpClient.get<ResultObjectModel<ISuppliergroup[]>>(url, httpOptions);
  }





  getSuppliers(groupid: number): Observable<ResultObjectModel<ISupplier[]>> {
    const url = `${environment.connections.SettingService}/setting/getSuppliers`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { groupid: groupid.toString() }
    };
    return this.httpClient.get<ResultObjectModel<ISupplier[]>>(url, httpOptions);
  }

  updateSuppliergroup(suppliergroup: ISuppliergroup): Observable<ResultObjectModel<ISuppliergroup[]>> {
    const url = `${environment.connections.SettingService}/setting/updateSupplierGroup`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    return this.httpClient.post<ResultObjectModel<ISuppliergroup[]>>(url, suppliergroup, httpOptions);
  }


  addSuppliergroup(suppliergroup: ISuppliergroup): Observable<ResultObjectModel<ISuppliergroup[]>> {
    const url = `${environment.connections.SettingService}/setting/addSupplierGroup`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    return this.httpClient.post<ResultObjectModel<ISuppliergroup[]>>(url, suppliergroup, httpOptions);
  }

  deleteSuppliergroup(suppliergroup: ISuppliergroup): Observable<ResultObjectModel<ISuppliergroup[]>> {
    const url = `${environment.connections.SettingService}/setting/deleteSupplierGroup`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    return this.httpClient.post<ResultObjectModel<ISuppliergroup[]>>(url, suppliergroup, httpOptions);
  }

  getAvailableBranches(id: number) : Observable<ResultObjectModel<IBranch[]>> {
    const url = `${environment.connections.SettingService}/setting/getAvailableBranches`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { id: id.toString() }
    };
    return this.httpClient.get<ResultObjectModel<IBranch[]>>(url, httpOptions);
  }



  getAvailableSuppliersForGroup(groupid: number): Observable<ResultObjectModel<CmfUserDataModel[]>> {
    const url = `${environment.connections.SettingService}/setting/getAvailableSuppliersForGroup`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { groupid: groupid.toString() }
    };
    return this.httpClient.get<ResultObjectModel<CmfUserDataModel[]>>(url, httpOptions);
  }

  addSuppliersToGroup(supplierids: string[], groupid: number): Observable<ResultObjectModel<ISupplier[]>> {
    const url = `${environment.connections.SettingService}/setting/addSuppliersToGroup`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { groupid: groupid.toString() }
    };
    return this.httpClient.post<ResultObjectModel<ISupplier[]>>(url, supplierids, httpOptions);
  }


  addBranchesToGroup(groupid: number, branches: string[]) : Observable<ResultObjectModel<IBranchbyGroup[]>> {
    const url = `${environment.connections.SettingService}/setting/addBranchesToGroup`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { groupid: groupid.toString() }
    };
    return this.httpClient.post<ResultObjectModel<IBranchbyGroup[]>>(url, branches, httpOptions);
  }




  removeSupplierFromGroup(supplier: ISupplier, groupid: number): Observable<ResultObjectModel<ISupplier[]>> {
    const url = `${environment.connections.SettingService}/setting/deleteSupplierFromGroup`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { groupid: groupid.toString() }
    };
    return this.httpClient.post<ResultObjectModel<ISupplier[]>>(url, supplier, httpOptions);
  }

  changeSupplierActivity(supplier: ISupplier, groupid: number): Observable<ResultObjectModel<ISupplier[]>> {
    const url = `${environment.connections.SettingService}/setting/ChangeActivitySupplier`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { groupid: groupid.toString() }
    };
    return this.httpClient.post<ResultObjectModel<ISupplier[]>>(url, supplier, httpOptions);
  }
}

