import { UploadRowModel, UploadStagingModel, RowContentModel, ResultObjectModel, FieldModel, UploadFileModel, FileDescriptionValidationModel, UploadStaging, GeneralResultEnum, LieferantTypeEnum, AufgabenArt } from '../models';
import { Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap, catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { SimpleResult } from '../models/simpleResult.model';
import { ColumnValue } from '../models/columnValue.model';
import { RowInsertResultModel } from '../models/uploadRowSave.model';
import { RowUpdateResultModel } from '../models/rowUpdateResult.model';
import { NotificationService } from './notification.service';
import * as moment from 'moment';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthService } from './auth.service';

import { UploadLieferantParam } from '@app/common-data-module/models/LieferantUserDTO';
import { FinishUploadParam, UpdateUploadParamClient, UpdateUploadParamServer } from '@app/+list-editor/models/updateparam';

@Injectable({ providedIn: 'root' })
export class ListEditorService {







    constructor(private authService: AuthService,private httpClient: HttpClient, private notifier: NotificationService) { }



    saveUploadMultiRows(param: UpdateUploadParamClient[], selctedrows: number[]): Observable<ResultObjectModel<RowUpdateResultModel[]>> {

      const url = `${environment.connections.UploadStagingService}/UpdateMultiRows`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + this.authService.getAccessToken()
        })
      };
      const params: UpdateUploadParamServer[] = [];

     param.forEach(content => {
      var columns = [];
      for (const key in content.cols) {
        if (content.cols.hasOwnProperty(key)) {
            const colValue = new ColumnValue();
            colValue.usedcolumnid = content.cols[key].usedcolumnid;
            colValue.value = content.cols[key].value;
            colValue.columnname = content.cols[key].columnname;
            colValue.columncelltype = content.cols[key].columncelltype;
            colValue.columndesc = content.cols[key].columndesc;
            colValue.items = content.cols[key].items;
            colValue.options = content.cols[key].options;
            colValue.ismandatory = content.cols[key].ismandatory;
            colValue.datevalue = content.cols[key].datevalue;
            colValue.booleanValue = content.cols[key].booleanValue;
            if (colValue.columncelltype == 6) {
              if (colValue.datevalue)  {
                colValue.value = moment(colValue.datevalue).format('DD.MM.YY');
              }
            }
            if (colValue.columncelltype == 2)
            {
                colValue.value = colValue.booleanValue != null ? (colValue.booleanValue ? '1' : '0') : '0';
            }
            columns.push( colValue);
        }

      }
      params.push({id: content.id, columns: columns});
    })


      let httpResult: RowUpdateResultModel[];
      const result = this.httpClient.post<ResultObjectModel<RowUpdateResultModel[]>>(url, params, httpOptions);
      return result;
    }




    createUpload(uploadstaging: UploadStaging ) : Observable<any> {
      const url = `${environment.connections.UploadStagingService}/InsertUpload`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + this.authService.getAccessToken()
        })
      };
      const result = this.httpClient.post<ResultObjectModel<any>>(url, uploadstaging, httpOptions);
      return result;
    }


  /**Uploads von Bildern zu Eans */
   UploadImagefiles(arg0: number, uploadedImageFiles: UploadFileModel) :Observable<ResultObjectModel<FileDescriptionValidationModel[]>> {
    const url = `${environment.connections.UploadStagingService}/UploadImagefiles`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    const result = this.httpClient.post<ResultObjectModel<any>>(url, uploadedImageFiles, httpOptions);
    return result;
  }





    getUploadRows(options: { taskId: number, params: UploadLieferantParam }): Observable<ResultObjectModel<UploadStagingModel>> {
        const httpOptions = {
            headers: new HttpHeaders({
              'Access-Control-Allow-Origin': '*',
              Authorization: 'Bearer ' + this.authService.getAccessToken()
            }),
            params: { taskId: options.taskId.toString() }
          };
        const url = `${environment.connections.UploadStagingService}/GetUploadedRowsForUpload`;
        const result = this.httpClient.post<ResultObjectModel<UploadStagingModel>>(url, options.params, httpOptions).pipe(
          map(x => {
            if ( x.generalResult != GeneralResultEnum.Success && x.generalResult != GeneralResultEnum.LogicWarning) {
              this.notifier.showError(x.userMessage); // Nur fehler
            }
            return x;
          }),
          catchError(x => {
            this.notifier.showError('Fehler beim Laden der Daten: ' + x && x.userMessage ? x.userMessage : ' Unbekannter Fehler');
            return EMPTY;
          })
        );
        return result;

    }

    saveUploadListItem(content: { id: number, cols: {}, isValid: boolean, validationInfo: string}): Observable<RowUpdateResultModel> {
      const url = `${environment.connections.UploadStagingService}/UpdateSingleRow`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + this.authService.getAccessToken()
        }),
        params: { uploadDetailId: content.id.toString() }
      };
      const columnValues: ColumnValue[] = [];
      for (const key in content.cols) {
        if (content.cols.hasOwnProperty(key)) {
            const colValue = new ColumnValue();
            colValue.usedcolumnid = content.cols[key].usedcolumnid;
            colValue.value = content.cols[key].value;
            colValue.columnname = content.cols[key].columnname;
            colValue.columncelltype = content.cols[key].columncelltype;
            colValue.columndesc = content.cols[key].columndesc;
            colValue.items = content.cols[key].items;
            colValue.options = content.cols[key].options;
            colValue.ismandatory = content.cols[key].ismandatory;
            colValue.datevalue = content.cols[key].datevalue;
            colValue.booleanValue = content.cols[key].booleanValue;
            if (colValue.columncelltype == 6) {
              if (colValue.datevalue)  {
                colValue.value = moment(colValue.datevalue).format('DD.MM.YY');
              }
            }
            if (colValue.columncelltype == 2) {
              if (colValue.booleanValue != null) {
                colValue.value = colValue.booleanValue ? '1' : '0';
              }
            }
            columnValues.push(colValue);
        }
      }
      let httpResult: RowUpdateResultModel;
      const result = this.httpClient.post<ResultObjectModel<RowUpdateResultModel>>(url, columnValues, httpOptions).pipe(map(x => httpResult = x.data));
      return result;
    }

    insertUploadListRow(content: { uploadId: number, oldid: number, validationName: string, cols: { } }): Observable<RowInsertResultModel> {
      const url = `${environment.connections.UploadStagingService}/InsertSingleRow`;
      const user = `${environment.user.id}`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + this.authService.getAccessToken()
        }),
        params: { uploadId: content.uploadId.toString(), oldId: content.oldid.toString(), userId: user }
      };
      const columnValues: ColumnValue[] = [];
      for (const key in content.cols) {
        if (content.cols.hasOwnProperty(key)) {
          const colValue = new ColumnValue();
          colValue.usedcolumnid = content.cols[key].usedcolumnid;
          colValue.value = content.cols[key].value;
          colValue.columnname = content.cols[key].columnname;
          colValue.columncelltype = content.cols[key].columncelltype;
          colValue.columndesc = content.cols[key].columndesc;
          colValue.items = content.cols[key].items;
          colValue.options = content.cols[key].options;
          colValue.ismandatory = content.cols[key].ismandatory;
          colValue.datevalue = content.cols[key].datevalue;
          if (colValue.columncelltype == 6) {
            if (colValue.datevalue)  {
              colValue.value = moment(colValue.datevalue).format('DD.MM.YY');
            }
          }
          if (colValue.columncelltype == 2) {
            if (colValue.booleanValue) colValue.value = colValue.booleanValue ? '1' : '0';
          }
          columnValues.push(colValue);
        }
      }
      let httpResult: RowInsertResultModel;
      const result = this.httpClient.post<ResultObjectModel<RowInsertResultModel>>(url, columnValues, httpOptions).pipe(
        map(x => httpResult = x.data), tap(console.log.bind(window)));
      return result;
    }

    validateRow(content: { uploadId: number, rowId: number, validationName: string, rowContent: {} }): Observable<RowUpdateResultModel> {
      const url = `${environment.connections.UploadStagingService}/ValidateSingleRow`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + this.authService.getAccessToken()
        }),
        params: { uploadDetailId: content.uploadId.toString(), rowId: content.rowId.toString(),
          validationName: content.validationName }
      };
      let httpResult: RowUpdateResultModel;
      const result = this.httpClient.post<ResultObjectModel<RowUpdateResultModel>>(url, content.rowContent, httpOptions)
        .pipe(map(x => httpResult = x.data));
      return result;
    }




    deleteRows(ids: number[]): Observable<ResultObjectModel<number>> {
      const url = `${environment.connections.UploadStagingService}/DeleteUploadRows`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + this.authService.getAccessToken()
        })
      };
      let httpResult: ResultObjectModel<number>;
      const result = this.httpClient.post<ResultObjectModel<number>>(url, ids, httpOptions)
        .pipe(map(x => httpResult = x));
      return result;
    }

    finishUpload(content: { id: number} ): Observable<SimpleResult> {
      const url = `${environment.connections.UploadStagingService}/FinishUpload`;
      const httpOptions = {
        headers: new HttpHeaders({'Access-Control-Allow-Origin': '*', Authorization: 'Bearer ' + this.authService.getAccessToken()}),
        params: {uploadId: content.id.toString()}
      };
      let httpResult: SimpleResult;
      const result = this.httpClient.get<SimpleResult>(url, httpOptions)
        .pipe(map(x => httpResult = x));
      return result;
    }

    finishUploadBySupplier(content: { id: number} ): Observable<SimpleResult> {
      const url = `${environment.connections.UploadStagingService}/FinishUploadBySupplier`;
      const httpOptions = {
        headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'}),
        params: {uploadId: content.id.toString()}
      };
      let httpResult: SimpleResult;
      const result = this.httpClient.get<SimpleResult>(url, httpOptions)
        .pipe(map(x => httpResult = x));
      return result;
    }

    finishUploadByManager( id: number, par: FinishUploadParam  ): Observable<SimpleResult> {

      const url = `${environment.connections.UploadStagingService}/FinishUploadByManager`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*'
        }),
        params: { taskId: id.toString() }
      };
      let httpResult: SimpleResult;
      const result = this.httpClient.post<SimpleResult>(url, par, httpOptions)
        .pipe(map(x => httpResult = x));
      return result;
    }

    importBubRows(uploadId: number, eans: string[]): Observable<ResultObjectModel<UploadRowModel[]>>
    {
      const url = `${environment.connections.UploadStagingService}/ImportBubFromEanList`;
      const httpOptions = {
        headers: new HttpHeaders({'Access-Control-Allow-Origin': '*', Authorization: 'Bearer ' + this.authService.getAccessToken()}),
        params: {uploadId: uploadId.toString()}
      };
      const httpResult = this.httpClient.post<ResultObjectModel<UploadRowModel[]>>(url, eans, httpOptions).pipe(
        map(x => {
          if (x.generalResult != 0) {
            this.notifier.showError('ServiceError: Fehler beim Importieren der Bub Daten: ' + x.userMessage);
          }
          return x;
        }),
        catchError(x => {
          this.notifier.showError('Fehler beim Laden der Bub Daten: ' + x.message);
          return EMPTY;
        })
      );
      return httpResult;
    }
}
