/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfTypDto } from '../models/list-response-args-of-typ-dto';
import { QueryTokenDTO } from '../models/query-token-dto';
import { SortimentDto } from '../models/sortiment-dto';
import { ListResponseArgsOfSortimentDto } from '../models/list-response-args-of-sortiment-dto';
import { ListResponseArgsOfSortimentPlatzierungDto } from '../models/list-response-args-of-sortiment-platzierung-dto';
@Injectable({
  providedIn: 'root',
})
class SortimentService extends __BaseService {
  static readonly SortimentQuerySortimentsTypenPath = '/sortiment/typ/s';
  static readonly SortimentCreateSortimentPath = '/sortiment';
  static readonly SortimentUpdateSortimentePath = '/sortiment';
  static readonly SortimentCopySortimentPath = '/sortiment/{id}';
  static readonly SortimentUpdateSortimentPath = '/sortiment/{id}';
  static readonly SortimentDeleteSortimentPath = '/sortiment/{id}';
  static readonly SortimentQuerySortimentePath = '/sortiment/s';
  static readonly SortimentGetMoebelPath = '/sortiment/{id}/moebel';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Sortimente
   * @param params The `SortimentService.SortimentQuerySortimentsTypenParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `eagerLoading`:
   */
  SortimentQuerySortimentsTypenResponse(params: SortimentService.SortimentQuerySortimentsTypenParams): __Observable<__StrictHttpResponse<ListResponseArgsOfTypDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sortiment/typ/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfTypDto>;
      })
    );
  }
  /**
   * Sortimente
   * @param params The `SortimentService.SortimentQuerySortimentsTypenParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `eagerLoading`:
   */
  SortimentQuerySortimentsTypen(params: SortimentService.SortimentQuerySortimentsTypenParams): __Observable<ListResponseArgsOfTypDto> {
    return this.SortimentQuerySortimentsTypenResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfTypDto)
    );
  }

  /**
   * Update Sortimente
   * @param params The `SortimentService.SortimentCreateSortimentParams` containing the following parameters:
   *
   * - `payload`: SortimentDto
   *
   * - `user`: User Id oder Token
   *
   * - `calculateFillingLevel`: Kapazität(sermittlung)
   */
  SortimentCreateSortimentResponse(params: SortimentService.SortimentCreateSortimentParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.user != null) __params = __params.set('user', params.user.toString());
    if (params.calculateFillingLevel != null) __params = __params.set('calculateFillingLevel', params.calculateFillingLevel.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sortiment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update Sortimente
   * @param params The `SortimentService.SortimentCreateSortimentParams` containing the following parameters:
   *
   * - `payload`: SortimentDto
   *
   * - `user`: User Id oder Token
   *
   * - `calculateFillingLevel`: Kapazität(sermittlung)
   */
  SortimentCreateSortiment(params: SortimentService.SortimentCreateSortimentParams): __Observable<null> {
    return this.SortimentCreateSortimentResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update Sortimente
   * @param params The `SortimentService.SortimentUpdateSortimenteParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `user`:
   */
  SortimentUpdateSortimenteResponse(params: SortimentService.SortimentUpdateSortimenteParams): __Observable<__StrictHttpResponse<ListResponseArgsOfSortimentDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/sortiment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfSortimentDto>;
      })
    );
  }
  /**
   * Update Sortimente
   * @param params The `SortimentService.SortimentUpdateSortimenteParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `user`:
   */
  SortimentUpdateSortimente(params: SortimentService.SortimentUpdateSortimenteParams): __Observable<ListResponseArgsOfSortimentDto> {
    return this.SortimentUpdateSortimenteResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfSortimentDto)
    );
  }

  /**
   * Sortiment kopieren
   * @param params The `SortimentService.SortimentCopySortimentParams` containing the following parameters:
   *
   * - `payload`: Payload inklusive Änderungen zum zu kopierenden Sortimentes
   *
   * - `id`: Id des zu kopierenden Sortimentes
   *
   * - `user`: User Id oder Token
   *
   * - `shelflabels`: Sortimentsstruktur kopieren (default false)
   *
   * - `parameter`: Parameter kopieren (default false)
   *
   * - `modules`: Module kopieren (default false)
   *
   * - `floorSpaces`: Möbel kopieren (default false)
   *
   * - `branches`: Filialen kopieren (default false)
   *
   * - `articles`: Artikel kopieren (default false)
   */
  SortimentCopySortimentResponse(params: SortimentService.SortimentCopySortimentParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    if (params.shelflabels != null) __params = __params.set('shelflabels', params.shelflabels.toString());
    if (params.parameter != null) __params = __params.set('parameter', params.parameter.toString());
    if (params.modules != null) __params = __params.set('modules', params.modules.toString());
    if (params.floorSpaces != null) __params = __params.set('floorSpaces', params.floorSpaces.toString());
    if (params.branches != null) __params = __params.set('branches', params.branches.toString());
    if (params.articles != null) __params = __params.set('articles', params.articles.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sortiment/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Sortiment kopieren
   * @param params The `SortimentService.SortimentCopySortimentParams` containing the following parameters:
   *
   * - `payload`: Payload inklusive Änderungen zum zu kopierenden Sortimentes
   *
   * - `id`: Id des zu kopierenden Sortimentes
   *
   * - `user`: User Id oder Token
   *
   * - `shelflabels`: Sortimentsstruktur kopieren (default false)
   *
   * - `parameter`: Parameter kopieren (default false)
   *
   * - `modules`: Module kopieren (default false)
   *
   * - `floorSpaces`: Möbel kopieren (default false)
   *
   * - `branches`: Filialen kopieren (default false)
   *
   * - `articles`: Artikel kopieren (default false)
   */
  SortimentCopySortiment(params: SortimentService.SortimentCopySortimentParams): __Observable<null> {
    return this.SortimentCopySortimentResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update Sortimente
   * @param params The `SortimentService.SortimentUpdateSortimentParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `id`:
   *
   * - `user`:
   */
  SortimentUpdateSortimentResponse(params: SortimentService.SortimentUpdateSortimentParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/sortiment/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update Sortimente
   * @param params The `SortimentService.SortimentUpdateSortimentParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `id`:
   *
   * - `user`:
   */
  SortimentUpdateSortiment(params: SortimentService.SortimentUpdateSortimentParams): __Observable<null> {
    return this.SortimentUpdateSortimentResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Delete Parameter
   * @param params The `SortimentService.SortimentDeleteSortimentParams` containing the following parameters:
   *
   * - `id`: Sortiment Id
   *
   * - `user`: User Id oder Token
   */
  SortimentDeleteSortimentResponse(params: SortimentService.SortimentDeleteSortimentParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/sortiment/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete Parameter
   * @param params The `SortimentService.SortimentDeleteSortimentParams` containing the following parameters:
   *
   * - `id`: Sortiment Id
   *
   * - `user`: User Id oder Token
   */
  SortimentDeleteSortiment(params: SortimentService.SortimentDeleteSortimentParams): __Observable<null> {
    return this.SortimentDeleteSortimentResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Sortimente
   * @param params The `SortimentService.SortimentQuerySortimenteParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `onePerAssignment`: False: Alle per Sortimentszuordnung verknüpfte Sortimente sind einzeln in der Ergebnisliste enthalten.
   *   True: Je Sortimentszordnung nur ein Eintrag in der Ergebnisliste.
   *
   * - `eagerLoading`:
   */
  SortimentQuerySortimenteResponse(params: SortimentService.SortimentQuerySortimenteParams): __Observable<__StrictHttpResponse<ListResponseArgsOfSortimentDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.onePerAssignment != null) __params = __params.set('onePerAssignment', params.onePerAssignment.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sortiment/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfSortimentDto>;
      })
    );
  }
  /**
   * Sortimente
   * @param params The `SortimentService.SortimentQuerySortimenteParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `onePerAssignment`: False: Alle per Sortimentszuordnung verknüpfte Sortimente sind einzeln in der Ergebnisliste enthalten.
   *   True: Je Sortimentszordnung nur ein Eintrag in der Ergebnisliste.
   *
   * - `eagerLoading`:
   */
  SortimentQuerySortimente(params: SortimentService.SortimentQuerySortimenteParams): __Observable<ListResponseArgsOfSortimentDto> {
    return this.SortimentQuerySortimenteResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfSortimentDto)
    );
  }

  /**
   * Liste der Möbel
   * @param params The `SortimentService.SortimentGetMoebelParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `moduleNo`:
   *
   * - `eagerLoading`:
   */
  SortimentGetMoebelResponse(params: SortimentService.SortimentGetMoebelParams): __Observable<__StrictHttpResponse<ListResponseArgsOfSortimentPlatzierungDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.moduleNo != null) __params = __params.set('moduleNo', params.moduleNo.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sortiment/${encodeURIComponent(String(params.id))}/moebel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfSortimentPlatzierungDto>;
      })
    );
  }
  /**
   * Liste der Möbel
   * @param params The `SortimentService.SortimentGetMoebelParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `moduleNo`:
   *
   * - `eagerLoading`:
   */
  SortimentGetMoebel(params: SortimentService.SortimentGetMoebelParams): __Observable<ListResponseArgsOfSortimentPlatzierungDto> {
    return this.SortimentGetMoebelResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfSortimentPlatzierungDto)
    );
  }
}

module SortimentService {

  /**
   * Parameters for SortimentQuerySortimentsTypen
   */
  export interface SortimentQuerySortimentsTypenParams {
    queryToken: QueryTokenDTO;
    eagerLoading?: number;
  }

  /**
   * Parameters for SortimentCreateSortiment
   */
  export interface SortimentCreateSortimentParams {

    /**
     * SortimentDto
     */
    payload: SortimentDto;

    /**
     * User Id oder Token
     */
    user?: null | string;

    /**
     * Kapazität(sermittlung)
     */
    calculateFillingLevel?: boolean;
  }

  /**
   * Parameters for SortimentUpdateSortimente
   */
  export interface SortimentUpdateSortimenteParams {
    payload: Array<SortimentDto>;
    user?: null | string;
  }

  /**
   * Parameters for SortimentCopySortiment
   */
  export interface SortimentCopySortimentParams {

    /**
     * Payload inklusive Änderungen zum zu kopierenden Sortimentes
     */
    payload: SortimentDto;

    /**
     * Id des zu kopierenden Sortimentes
     */
    id: number;

    /**
     * User Id oder Token
     */
    user?: null | string;

    /**
     * Sortimentsstruktur kopieren (default false)
     */
    shelflabels?: boolean;

    /**
     * Parameter kopieren (default false)
     */
    parameter?: boolean;

    /**
     * Module kopieren (default false)
     */
    modules?: boolean;

    /**
     * Möbel kopieren (default false)
     */
    floorSpaces?: boolean;

    /**
     * Filialen kopieren (default false)
     */
    branches?: boolean;

    /**
     * Artikel kopieren (default false)
     */
    articles?: boolean;
  }

  /**
   * Parameters for SortimentUpdateSortiment
   */
  export interface SortimentUpdateSortimentParams {
    payload: SortimentDto;
    id: number;
    user?: null | string;
  }

  /**
   * Parameters for SortimentDeleteSortiment
   */
  export interface SortimentDeleteSortimentParams {

    /**
     * Sortiment Id
     */
    id: number;

    /**
     * User Id oder Token
     */
    user?: null | string;
  }

  /**
   * Parameters for SortimentQuerySortimente
   */
  export interface SortimentQuerySortimenteParams {
    queryToken: QueryTokenDTO;

    /**
     * False: Alle per Sortimentszuordnung verknüpfte Sortimente sind einzeln in der Ergebnisliste enthalten.
     * True: Je Sortimentszordnung nur ein Eintrag in der Ergebnisliste.
     */
    onePerAssignment?: boolean;
    eagerLoading?: number;
  }

  /**
   * Parameters for SortimentGetMoebel
   */
  export interface SortimentGetMoebelParams {
    id: number;
    moduleNo?: null | number;
    eagerLoading?: number;
  }
}

export { SortimentService }
