import { AufgabenArt, LieferantTypeEnum, TaskUserModel } from '@app/common/models/task-user.model';
import { LoggedInUser } from '@app/common/models/loggedInUserModel';
import { AppState as RootState } from '../../store';
import { createFeatureSelector } from '@ngrx/store';
import { IAuthUser } from '@app/common/interfaces/Interfaces';
import { HttpErrorResponse } from '@angular/common/http';
import { SendMessageTypeContainer } from '@app/common/models/signal.model';

export interface UserState extends TaskUserModel {
  actualUser: { [key: string]: any };
  authuser: IAuthUser;
  error: Error | HttpErrorResponse;
}


export interface AppState extends RootState {
  userstate: UserState;
}

export const InitialUserState: UserState = {
  name: 'Niemand',
  organization: '-',
  position: '-',
  id : -1,
  email: '-',
  groupId: -1,
  userId: '',
  isReadOnly: 0,
  isReadOnlyMessage: null,
  isEditExpired: 0,
  isEditExpiredMessage: null,
  min: 0,
  max: 0,
  actualUser: {},
  taskInfo: '',
  taskDescription: '',
  taskValidRange: [],
  taskValidRangeFrom: null,
  taskValidRangeTo: null,
  taskActiveRange: [],
  taskActiveRangeFrom: null,
  taskActiveRangeUntil: null,
  sendInvite: false,
  invitationDate: null,
  sendInviteOne: false,
  sendInviteTwo: false,
  sendInviteThree: false,
  dateInviteOne: null,
  dateInviteTwo: null,
  dateInviteThree: null,
  includeUpload: false,
  selectedValidation: '',
  selectedValidationId: null,
  loadSnapshot: false,
  selected: false,
  taskParentId: null,
  creatorFriendlyName: null,
  creatorMail: null,
  creatorToken: null,
  uploadStagingId: null,
  uploadState: 0,
  uploadFiles: { parentId: 0, userId: '', files: [] },
  fileUrls: [],
  lastName: null,
  firstName: null,
  fullName: null,
  gender: null,
  sortimentName: null,
  sortimentIds: [],
  klammerId: null,
  klammerName: null,
  typeInfoId: null,
  targetUserId: null,
  color: null,
  taskState: null,
  authuser: <IAuthUser>{},
  error: null,
  taskType: 0,
  einkaufId: null,
  aufgabenArt: AufgabenArt.Artikelliste,
  mandantId: null,
  lieferantId: null,
  lieferantType: LieferantTypeEnum.Lieferant

};



