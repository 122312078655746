
import { FilterMetadata, LazyLoadEvent, SortMeta } from 'primeng/api';
import { ValidationModel } from './validation.model';
import { KonfliktModel } from './konflikte.model';
import { NoviProcessActionType } from '@app/+noviprocess/store';
import { CommonDataActionType } from '@app/common-data-module/store';

/**ab 31.10.2022 */
export class NovitaetenSuccess<T>
{
  message: string;
  data: T;
  type: NoviProcessActionType | CommonDataActionType;
}





export class NoviSuccess
{
  dataList: any[];
  type:NoviSuccessEnum;
}


export enum NoviSuccessEnum {
  unbekannt = 0,
  gespeichert = 1,
  gelöscht = 2,
  erstellt = 3,
  kopiert
}

export class FilterNovitaeten {
  filter: string;

}

export class Filter {
  key: string;
  filterData: FilterMetadata[];
}



/**Header Filter / Lookups */
export class HeaderFilter {
  text: string;
  value: any;
  constructor(){}


  static getNachListung() { return  <HeaderFilter> {text: "Nachlistung", value: -1 } };
}



export interface IFilterEventParameter extends LazyLoadEvent {
  /**Spalte, die über Header gefiltert wird */
  filterColumn: string;
  userId: string;
  first?: number;
  rows?: number;
  sortField?: string;
  sortOrder?: number;
  multiSortMeta?: SortMeta[];
  globalFilter?: any;
  filter : Filter[];
  userToken: string;
}


