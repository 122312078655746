<div class="container-fluid grid">
    <div class="col"><h4>{{title}}</h4></div>
    <div class="col-fixed" style="width: 50px;">
        <button [disabled]="hint()" pButton type="button" icon="pi pi-check" (click)="close()"></button>
    </div>
</div>
<div class="container-fluid grid" *ngIf="hint()">
  <div class="col alert alert-warning">{{hint()}}</div>
</div>

<div class="container-fluid">
    <p-table #supplierTable [columns]="userCols" [value]="supplierList()" [paginator]="true" [rows]="10" [reorderableColumns]="true" [resizableColumns]="true" columnResizeMode="expand"
        [globalFilterFields]="globalfilter">
        <ng-template pTemplate="caption">
            <div *ngIf="showLieferantengruppenDropdown==true" style="float:left;width:300px">
                <p-dropdown [style]="{'width':'300px'}" [(ngModel)]="selectedSuppliergroup" id="Gruppenauswahl" [options]="suppliergroupList$ | async" optionLabel="label"
                appendTo = "body" [showClear]="false" (ngModelChange)="onChangeSuppliergroup($event)"
                placeholder="Gruppe auswählen">
            </p-dropdown>
            </div>
            <div style="text-align: right">
                <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                <input type="text" pInputText size="50" (input)="supplierTable.filterGlobal($any($event).target.value, 'contains')" style="width:auto">
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th style="width:3em"><i class="pi pi-check"></i></th>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" pResizableColumn pReorderableColumn>
                        {{col.header}}
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-ri="rowIndex">
            <tr [pSelectableRow]="rowData">
                <td style="width:3em;">
                    <p-checkbox binary="true" [(ngModel)]="rowData.selected" (onChange)="setRecipients($event, rowData.value)"></p-checkbox>
                </td>
                <td *ngFor="let col of userCols" >
                  {{ rowData[col.part][col.field] }}
                </td>

            </tr>
        </ng-template>
    </p-table>
</div>
