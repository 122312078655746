/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfDashboardItemDto } from '../models/list-response-args-of-dashboard-item-dto';
import { ExtendedQueryTokenDTO } from '../models/extended-query-token-dto';
import { ResponseArgsOfIDictionaryOfStringAndInteger } from '../models/response-args-of-idictionary-of-string-and-integer';
@Injectable({
  providedIn: 'root',
})
class DashboardService extends __BaseService {
  static readonly DashboardGetDashboardPath = '/dashboard';
  static readonly DashboardGetDashboardSumPath = '/dashboard/sum';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DashboardService.DashboardGetDashboardParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `bestellmengeNullBerücksichtigen`:
   */
  DashboardGetDashboardResponse(params: DashboardService.DashboardGetDashboardParams): __Observable<__StrictHttpResponse<ListResponseArgsOfDashboardItemDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.bestellmengeNullBerCksichtigen != null) __params = __params.set('bestellmengeNullBerücksichtigen', params.bestellmengeNullBerCksichtigen.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/dashboard`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfDashboardItemDto>;
      })
    );
  }
  /**
   * @param params The `DashboardService.DashboardGetDashboardParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `bestellmengeNullBerücksichtigen`:
   */
  DashboardGetDashboard(params: DashboardService.DashboardGetDashboardParams): __Observable<ListResponseArgsOfDashboardItemDto> {
    return this.DashboardGetDashboardResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfDashboardItemDto)
    );
  }

  /**
   * @param params The `DashboardService.DashboardGetDashboardSumParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `bestellmengeNullBerücksichtigen`:
   */
  DashboardGetDashboardSumResponse(params: DashboardService.DashboardGetDashboardSumParams): __Observable<__StrictHttpResponse<ResponseArgsOfIDictionaryOfStringAndInteger>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.bestellmengeNullBerCksichtigen != null) __params = __params.set('bestellmengeNullBerücksichtigen', params.bestellmengeNullBerCksichtigen.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/dashboard/sum`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIDictionaryOfStringAndInteger>;
      })
    );
  }
  /**
   * @param params The `DashboardService.DashboardGetDashboardSumParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `bestellmengeNullBerücksichtigen`:
   */
  DashboardGetDashboardSum(params: DashboardService.DashboardGetDashboardSumParams): __Observable<ResponseArgsOfIDictionaryOfStringAndInteger> {
    return this.DashboardGetDashboardSumResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIDictionaryOfStringAndInteger)
    );
  }
}

module DashboardService {

  /**
   * Parameters for DashboardGetDashboard
   */
  export interface DashboardGetDashboardParams {
    queryToken: ExtendedQueryTokenDTO;
    bestellmengeNullBerCksichtigen?: boolean;
  }

  /**
   * Parameters for DashboardGetDashboardSum
   */
  export interface DashboardGetDashboardSumParams {
    queryToken: ExtendedQueryTokenDTO;
    bestellmengeNullBerCksichtigen?: boolean;
  }
}

export { DashboardService }
