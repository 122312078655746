import { ResultObjectModel, TaskLieferantParams, TaskUserModel, ValidationList } from '@app/common/models';

import { SetUserDataAction } from '@app/+task-editor/store';
import { TaskStructureModel } from '@app/common/models/taskStructure.model';


import { ISuppliergroup, ISupplier } from '@app/common/models/suppliergroup.model';
import { TaskQueryParameter } from '@app/common/services/task.service';
import { MandantUserDTO } from '@app/common/models/mandant-user-dto';
import { LieferantUserDTO } from '@app/common-data-module/models/LieferantUserDTO';


export enum TaskListActionType {
  TasklistErrorAction = "[TASK-LIST] TasklistErrorAction",

  GetTasksForCurrentUser = '[TASK-LIST] GetTasksForCurrentUser',
  GetTasksForSupplier = '[TASK-LIST] GetTasksForSupplier',
  GetTasksDone = '[TASK-LIST] GetTasksDone',
  GetSupplierTasksDone = '[TASK-LIST] GetSupplierTasksDone',
  SetPage = '[TASK-LIST] SetPage',
  CreateUpload = '[TASK-LIST] CreateUpload',
  CreateUploadDone = '[TASK-LIST] CreateUploadDone',

  CreateSupplierTasks = '[TASK-LIST] CreateSupplierTasks',
  CreateSupplierTasksDone = '[TASK-LIST] CreateSupplierTasksDone',
  GetValidations = '[TASK-LIST] GetValidaitons',
  GetValidationsDone = '[TASK-LIST] GetValidaitonsDone',


  CreateMasterTask = '[TASK-LIST] CreateMasterTask',
  CreateMasterTaskDone = '[TASK-LIST] CreateMasterTaskActionDone',
  UpdateTask = '[TASK-LIST] UpdateTask',
  UpdateTaskDone = '[TASK-LIST] UpdateTaskDone',
  DeleteUpload = '[TASK-LIST] DeleteUpload',
  DeleteUploadDone = '[TASK-LIST] DeleteUploadDone',
  DuplicateMaster = '[TASK-LIST] DuplicateMaster',
  DuplicateMasterDone = '[TASK-LIST] DuplicateMasterDone',
  DuplicateSupplier = '[TASK-LIST] DuplicateSupplier',
  DuplicateSupplierDone = '[TASK-LIST] DuplicateSupplerDone',
  DeleteTask = '[TASK-LIST] DeleteTask',
  DeleteTaskDone = '[TASK-LIST] DeleteTaskDone',
  ApproveTask = '[TASK-LIST] ApproveTask',
  ApproveTaskDone = '[TASK-LIST] ApproveTaskDone',
  SelectAllCategories = '[TASK-LIST] SelectAllCategories',
  AddSpecificCategory = '[TASK-LIST] AddSpecificCategory',
  SetSingleTaskStateAction = "[TASK-LIST] SetSingleTaskStateAction",
  SetSingleTaskStateActionDone = "[TASK-LIST] SetSingleTaskStateActionDone",
  GetSuppliergroups = "[TASK-LIST] GetSuppliergroups",
  GetSuppliergroupsDone = "[TASK-LIST] GetSuppliergroupsDone",
  GetSuppliersForGroup = "[TASK-LIST] GetSuppliersForGroup",
  GetSuppliersForGroupDone = "[TASK-LIST] GetSuppliersForGroupDone",
  GetTaskbyIdAction = "[TASK-LIST] GetTaskbyIdAction",
  GetTaskbyIdActionDone = "[TASK-LIST] GetTaskbyIdActionDone",
  GetMandantenActionDone = "[TASK-LIST] GetMandantenActionDone",
  GetMandantenAction = "[TASK-LIST] GetMandantenAction",
  CreateMandantenTasksActionDone = "[TASK-LIST] CreateMandantenTasksActionDone",
  CreateMandantenTasksAction = "[TASK-LIST] CreateMandantenTasksAction",
  GetTaskUsersAktionDone = "[TASK-LIST] GetTaskUsersAktionDone",
  GetTaskUsersAktion = "[TASK-LIST] GetTaskUsersAktion",
  ClearTaskErrorAktion = "[TASK-LIST] ClearTaskErrorAktion",
  GetLieferantenUserAction = "[TASK-LIST] GetLieferantenUserAction",
  GetLieferantenUserActionDone = "[TASK-LIST] GetLieferantenUserActionDone"
}

export class TasklistErrorAction {
    readonly type =  TaskListActionType.TasklistErrorAction ;
    constructor( public payload: {  error : any}) {}
}

export class ClearTaskErrorAktion {
  readonly type =  TaskListActionType.ClearTaskErrorAktion ;
  constructor( ) {}
}



export class GetTasksForCurrentUserAction {
    readonly type = TaskListActionType.GetTasksForCurrentUser;
    constructor(  public payload: { params: TaskQueryParameter } ) { }
}




export class GetValidationsAction {
    readonly type = TaskListActionType.GetValidations;
    constructor() {}
}

export class GetValidationsDoneAction {
    readonly type = TaskListActionType.GetValidationsDone;
    constructor(public payload: { validations: ValidationList[] }) {}
}





export class GetTasksDoneAction {
    readonly type = TaskListActionType.GetTasksDone;
    constructor(public payload: { item: TaskStructureModel<TaskUserModel>[] }) { }
}



export class CreateUploadAction {
    readonly type = TaskListActionType.CreateUpload;
    constructor(public payload: { taskId: number, task: TaskUserModel }) { }
}

export class CreateUploadDoneAction {
    readonly type = TaskListActionType.CreateUploadDone;
    constructor(public payload: { item: ResultObjectModel<number>, taskId: number }) { }
}






export class CreateMandantenTasksAction {
  readonly type = TaskListActionType.CreateMandantenTasksAction;
  constructor(public payload: { taskModel: TaskUserModel, mandanten: MandantUserDTO[], parentId: string }) {}
}

export class CreateMandantenTasksActionDone {
  readonly type = TaskListActionType.CreateMandantenTasksActionDone;
  constructor(public payload: { taskModels: TaskUserModel[], parentId: string }) {}
}


export class CreateSupplierTasksAction {
    readonly type = TaskListActionType.CreateSupplierTasks;
    constructor(public payload: { params: TaskLieferantParams}) {}
}

export class CreateSupplierTasksDoneAction {
    readonly type = TaskListActionType.CreateSupplierTasksDone;
    constructor(public payload: {
      parentId: string; taskModels: TaskUserModel[]
}) {}
}

export class CreateMasterTaskAction {
    readonly type = TaskListActionType.CreateMasterTask;
    constructor(public payload: { item: TaskUserModel }) {}
}

export class CreateMasterTaskDoneAction {
    readonly type = TaskListActionType.CreateMasterTaskDone;
    constructor(public payload: { item: ResultObjectModel<TaskUserModel[]> }) {}
}

export class UpdateTaskAction {
    readonly type = TaskListActionType.UpdateTask;
    constructor(public payload: { item: TaskUserModel, updateChildren: boolean }) {}
}

export class UpdateTaskDoneAction {
    readonly type = TaskListActionType.UpdateTaskDone;
    constructor(public payload: { items: TaskUserModel[] }) {}
}

export class DeleteUploadAction {
    readonly type = TaskListActionType.DeleteUpload;
    constructor(public payload: { id: number }) {}
}

export class DeleteUploadDoneAction {
    readonly type = TaskListActionType.DeleteUploadDone;
    constructor(public payload: { id: number }) {}
}

export class DuplicateMasterAction {
    readonly type = TaskListActionType.DuplicateMaster;
    constructor(public payload: { taskId: number }) {}
}

export class DuplicateMasterDoneAction {
    readonly type = TaskListActionType.DuplicateMasterDone;
    constructor(public payload: { oldtaskId: number, item: TaskStructureModel<TaskUserModel> }) {}
}

export class DuplicateSupplierAction {
    readonly type = TaskListActionType.DuplicateSupplier;
    constructor(public payload: { taskId: number }) {}
}

export class DuplicateSupplierDoneAction {
    readonly type = TaskListActionType.DuplicateSupplierDone;
    constructor(public payload: { item: TaskUserModel }) {}
}

export class DeleteTaskAction {
    readonly type = TaskListActionType.DeleteTask;
    constructor(public payload: { taskId: number }) {}
}

export class DeleteTaskDoneAction {
    readonly type = TaskListActionType.DeleteTaskDone;
    constructor(public payload: { taskId: number }) {}
}

export class SetSingleTaskStateAction {
  readonly type = TaskListActionType.SetSingleTaskStateAction;
  constructor(public payload: { taskId: number, taskState: number }) {}
}
export class SetSingleTaskStateDoneAction {
  readonly type = TaskListActionType.SetSingleTaskStateActionDone;
  constructor(public payload: { taskId: number , taskState: number }) {}
}


export class ApproveTaskAction {
    readonly type = TaskListActionType.ApproveTask;
    constructor(public payload: { taskId: number, taskState: number }) {}
}
export class ApproveTaskDoneAction {
    readonly type = TaskListActionType.ApproveTaskDone;
    constructor(public payload: { taskId: number }) {}
}

export class SelectAllCategoriesAction {
    readonly type = TaskListActionType.SelectAllCategories;
    constructor () {}
}

export class AddSpecificCategoryAction {
    readonly type = TaskListActionType.AddSpecificCategory;
    constructor() {}
}


export class GetSuppliergroupsAction {
    readonly type =  TaskListActionType.GetSuppliergroups ;
    constructor() {}
}

export class GetSuppliergroupsDoneAction {
    readonly type =  TaskListActionType.GetSuppliergroupsDone ;
    constructor( public payload: {  groups : ISuppliergroup[]}) {}
}

export class GetSuppliersForGroupAction {
    readonly type =  TaskListActionType.GetSuppliersForGroup ;
    constructor( public payload: {  group : ISuppliergroup} ) {}
}

export class GetSuppliersForGroupDoneAction {
    readonly type =  TaskListActionType.GetSuppliersForGroupDone ;
    constructor( public payload: {  suppliers : ISupplier[]}) {}
}


export class GetTaskbyIdAction {
  readonly type =  TaskListActionType.GetTaskbyIdAction ;
  constructor( public payload: {  taskid : number}) {}
}


export class GetTaskbyIdActionDone {
  readonly type =  TaskListActionType.GetTaskbyIdActionDone ;
  constructor( public payload: {  task : TaskStructureModel<TaskUserModel>}) {}
}


export class GetMandantenActionDone {
  readonly type =  TaskListActionType.GetMandantenActionDone ;
  constructor( public payload: {  mandanten : ResultObjectModel<MandantUserDTO[]>}) {}
}

export class GetMandantenAction {
readonly type =  TaskListActionType.GetMandantenAction ;
constructor( public payload: { headerTaskId: any } ) {}
}


export class GetTaskUsersAktionDone {
  readonly type =  TaskListActionType.GetTaskUsersAktionDone ;
  constructor( public payload: {  result : ResultObjectModel<string[]>}) {}
}

export class GetTaskUsersAktion {
readonly type =  TaskListActionType.GetTaskUsersAktion ;
constructor( public payload: { taskId: number } ) {}
}


export class GetLieferantenUserActionDone {
  readonly type =  TaskListActionType.GetLieferantenUserActionDone ;
  constructor( public payload: {  lieferanten : ResultObjectModel<LieferantUserDTO[]>}) {}
}

export class GetLieferantenUserAction {
readonly type =  TaskListActionType.GetLieferantenUserAction ;
constructor( public payload: { headerTaskId: any, categoryManager: boolean } ) {}
}




export type TaskListActions =
    GetTasksForCurrentUserAction
    | GetTasksDoneAction
    | CreateUploadAction
    | CreateUploadDoneAction
    | SetUserDataAction

    | CreateSupplierTasksAction
    | CreateSupplierTasksDoneAction
    | CreateMasterTaskAction
    | CreateMasterTaskDoneAction
    | UpdateTaskAction
    | UpdateTaskDoneAction
    | GetValidationsAction
    | GetValidationsDoneAction
    | DeleteUploadAction
    | DeleteUploadDoneAction
    | DuplicateMasterAction
    | DuplicateMasterDoneAction
    | DuplicateSupplierAction
    | DuplicateSupplierDoneAction
    | DeleteTaskAction
    | DeleteTaskDoneAction
    | ApproveTaskAction
    | ApproveTaskDoneAction
    | SelectAllCategoriesAction
    | AddSpecificCategoryAction
    | SetSingleTaskStateDoneAction
    | GetSuppliergroupsAction
    | GetSuppliergroupsDoneAction
    | GetSuppliersForGroupAction
    | GetSuppliersForGroupDoneAction
    | GetTaskbyIdActionDone | GetTaskbyIdAction
    | GetMandantenAction | GetMandantenActionDone
    | CreateMandantenTasksAction | CreateMandantenTasksActionDone
    | GetTaskUsersAktionDone | GetTaskUsersAktion
    | TasklistErrorAction | ClearTaskErrorAktion
    | GetLieferantenUserAction | GetLieferantenUserActionDone
    ;
