import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import '@angular/compiler';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { authConfig } from './app/oauth.config';


if (environment.production) {
  enableProdMode();
}

(async () => {
  try {
    const res = await fetch('./assets/config/config.json');
    const configuration = await res.json();

    environment.connections.CalendarService = configuration['CalendarService'];
    environment.connections.UploadStagingService = configuration['UploadStagingService'];
    environment.connections.TaskListService = configuration['TaskListService'];
    environment.connections.UploadService = configuration['UploadService'];
    environment.connections.ValidationEditService = configuration['ValidationEditService'];
    environment.connections.SignalService = configuration['SignalService'];
    environment.connections.SettingService = configuration['SettingService'];
    environment.connections.MediatipService = configuration['MediatipService'];
    environment.connections.CommonWebApi = configuration['CommonWebApi'];

    environment.connections.JWWSService = configuration['JWWSService'];



    environment.connections.AdvWebapi = configuration['AdvWebapi'];
    environment.connections.DocumentService = configuration['DocumentService'];
    environment.connections.KatalogWebapi = configuration['KatalogWebapi'];

    environment.protoTyping = configuration['ProtoTyping'];
    authConfig.issuer = configuration['AuthIssuer'];
    environment.managers = configuration['managers'];
    environment.suppliers = configuration['suppliers'];
    environment.admins = configuration['admins'];
    environment.contacts = configuration['contacts'];
    environment.mandant = configuration['mandant'];
    environment.moebelEditorUrl = configuration['moebelEditorUrl'];

     platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));

  } catch (error) {
    console.warn('Unable to launch Application. ' + error);
  }
})();
