import { Injectable } from "@angular/core";

import { KlammerSortimentStichwort } from "@app/api/filialmatrix/models";
import { SortimentsstrukturService } from "@app/api/filialmatrix/services";
import { IClientError } from "@app/common/interfaces/Interfaces";
import { GeneralResultEnum } from "@app/common/models";
import { SimpleResult } from "@app/common/models/simpleResult.model";
import { LogUsersClientErrorAction } from "@app/store/user/actions";
import { Store, select } from "@ngrx/store";

import { TreeNode } from "primeng/api";
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, last, map, Observable, take } from "rxjs";
import { StichworthintEnum, KeywordNode } from "../../common/models/PlatzierungsClientDto";
import {
  AddGetStichwortBaum, CommonDataActionType, CommonDataErrorAction, GetStichwortBaum, GetStichwortBaumbyKlammer, GetStichwortBaumbyKlammerExpand,
  GetStichwortBaumbyKlammerSortiment, GetStichwortBaumbyKlammerSortimentExpand, GetStichwortBaumbyKlammerSortimentStichwort,
  GetStichwortBaumbyKlammerSortimentStichwortExpand, GetStichwortBaumDone, KeyordEbene, selectallSortimente, selectlastkeywordError, selectStichwortStrukturBaum, selectStichwortStrukturBaumAll, selectStichwortStrukturBaumDictionary, selectStichwortStrukturBaumisLoaded
} from "../store";


@Injectable()
export class StichwortTreeService {
  constructor(private store: Store<any>
  ) {

    this.store.pipe(select(selectStichwortStrukturBaumDictionary)).pipe(distinctUntilChanged()).subscribe(all => {
      if (all == undefined) return [];
      var obj = this.stichwortNodesSubj.value;
      var keys = Object.keys(all);
      keys.forEach(k => {
        var f = all[k];
        if (f == undefined) return [];
        var expanded = this.expandNodes[k];
        var array = f.map(g => <TreeNode>{
          label: g.klammerName, data: g,
          selectable: true,
          styleClass: 'alert-primary',
          expanded: expanded && expanded.find(f => f.klammerid == g.klammerId) != undefined,
          icon: 'pi pi-home',
          leaf: false,
          key: g.klammerId.toString(),
          children: g.children ? g.children.map(c => {
            var subtrees = c.children ? StichwortTreeService.getStichwortSubTree(c, expanded) : [];
            return <TreeNode>{
              icon: 'pi pi-book',
              styleClass: 'alert-info',
              key: (g.klammerId + c.sortimentId).toString(),
              selectable: true,
              expanded: expanded && expanded.find(f => f.klammerid == g.klammerId && c.sortimentId && f.sortimentid == c.sortimentId) != undefined,
              leaf: false,
              label: c.sortimentName, data: c,
              children: subtrees,
            }
          })

            : []
        });
        obj[k] = [...array];

      })

      this.stichwortNodesSubj.next(obj);
    })


  }

  /**Subtree Stichworte  */
  static getStichwortSubTree(c: KlammerSortimentStichwort, expanded: KeywordNode[]): TreeNode<any>[] {
    if (c == undefined || c.children == undefined) return [];

    var v = c.children.map(childstichwort => {

      var nodechilderen = childstichwort.children ? StichwortTreeService.getStichwortSubTree(childstichwort, expanded) : [];
      /**Subtree wurde expanded */
      var childexpanded = nodechilderen.find(f => f.expanded == true) != undefined;
      return <TreeNode>{
        icon: 'pi pi-star',
        styleClass: (childstichwort.stichwortId == undefined && childstichwort.stichwortName && childstichwort.stichwortName == StichworthintEnum.keine_weiteren_Stichwörter) ? 'alert-warning' : 'alert-success',
        key: (c.klammerId + c.sortimentId + childstichwort.stichwortId).toString(),
        selectable: childstichwort.stichwortId != undefined && childstichwort.stichwortName != undefined,
        expanded: childexpanded || (childstichwort.stichwortId != undefined
          && expanded && expanded.find(f => f.klammerid == c.klammerId && c.sortimentId
            && f.sortimentid == c.sortimentId && childstichwort.stichwortId && childstichwort.stichwortId == f.stichwortid) != undefined),
        leaf: false,
        children: nodechilderen,
        label: childstichwort.stichwortName, data: childstichwort
      };
    });
    return v;
  }

  selectlastkeywordError$ = this.store.pipe(select(selectlastkeywordError));
  selectStichwortStrukturBaumAll$ = this.store.pipe(select(selectStichwortStrukturBaumAll));
  selectStichwortStrukturBaumisLoading$ = this.store.pipe(select(selectStichwortStrukturBaumisLoaded));

  stichwortNodesSubj = new BehaviorSubject<{ [key: number]: TreeNode[] }>({});
  stichwortNodes: Observable<{ [key: number]: TreeNode[] }> = this.stichwortNodesSubj.asObservable();
  expandNodes: { [key: string]: KeywordNode[] } = {};
  selectedKeyWords: { [key: string]: TreeNode } = {};


  /**eine Tree Selektion entfernen */
  ClearTree(dummyId: any) {
    this.selectedKeyWords[dummyId] = null;
    this.expandNodes[dummyId] = [];
  }


  /**Extentable */
  loadLookupStichwortTree(dummyId: number, klammerid: number, sortimentid: number, stichwortid: number) {
    //Keine Klammer, kein Sortiment, kein Stichwort
    if ((klammerid == undefined || klammerid < 0) && (sortimentid == undefined || sortimentid < 0) && (stichwortid == undefined || stichwortid < 0)) {
      this.selectStichwortStrukturBaumAll$.subscribe(alldata => {
        if (alldata == undefined || alldata.length == 0) {
          this.store.dispatch(new GetStichwortBaum({ id: dummyId }));
        } else {
          this.store.dispatch(new AddGetStichwortBaum({ id: dummyId, data: alldata }));
        }
      }).unsubscribe()

    }
    //nur Klammer
    else if (klammerid != undefined && klammerid > 0 && sortimentid == undefined) {

      this.selectStichwortStrukturBaumisLoading$.subscribe(loaded => {
        if (loaded == undefined && loaded[dummyId] == undefined) {
          this.store.dispatch(new GetStichwortBaumbyKlammerExpand({ id: dummyId, klammerid: klammerid }));
        }
      }).unsubscribe()


    }
    // Klammer und Sortiment
    else if (klammerid != undefined && klammerid > 0 && sortimentid != undefined && sortimentid > 0 && stichwortid == undefined) {
      this.selectStichwortStrukturBaumisLoading$.subscribe(loaded => {
        if (loaded == undefined && loaded[dummyId] == undefined || loaded[dummyId] != KeyordEbene.Sortiment) {
          var p2 = <SortimentsstrukturService.SortimentsstrukturGetExpandedKlammerSortimentStichwort2Params>
            {
              klammerId: klammerid, sortimentId: sortimentid
            }
          this.store.dispatch(new GetStichwortBaumbyKlammerSortimentExpand({ id: dummyId, params: p2 }));
        }
      }).unsubscribe()
    }
    //alle drei
    else if (klammerid != undefined && klammerid > 0 && sortimentid != undefined && stichwortid != undefined) {

      this.selectStichwortStrukturBaumisLoading$.subscribe(loaded => {
        if (loaded == undefined || (loaded[dummyId] == undefined || loaded[dummyId] != KeyordEbene.Stichwort )) {
          var p3 = <SortimentsstrukturService.SortimentsstrukturGetKlammerSortimentStichwort4Params>
            {
              klammerId: klammerid, sortimentId: sortimentid, stichwortId: stichwortid
            }
          this.store.dispatch(new GetStichwortBaumbyKlammerSortimentStichwortExpand({ id: dummyId, params: p3 }));
        }
      }).unsubscribe()


    } else if (klammerid == undefined && sortimentid != undefined && stichwortid == undefined) {
      this.store.pipe(select(selectallSortimente)).subscribe(allesortimene => {
        var klammer = allesortimene.find(s => s.id == sortimentid);
        if (klammer) {
          var klammeridfromSortiment = klammer.klammer.id;
          //klammer unbekannt aber sortiment da
          var p21 = <SortimentsstrukturService.SortimentsstrukturGetExpandedKlammerSortimentStichwort2Params>
            {
              klammerId: klammeridfromSortiment, sortimentId: sortimentid
            }
          this.store.dispatch(new GetStichwortBaumbyKlammerSortimentExpand({ id: dummyId, params: p21 }));
        }
        else {
          this.store.dispatch(new LogUsersClientErrorAction({ error: <IClientError>{ userid: undefined, modul: 'Tree für Struktur laden', error: new Error('Klammer für Sortiment unbekannt -> ' + sortimentid) } }));
        }
      }).unsubscribe()
    }
    else {
      this.selectStichwortStrukturBaumAll$.subscribe(alldata => {
        if (alldata == undefined || !(alldata instanceof Array)) {
          this.store.dispatch(new GetStichwortBaum({ id: dummyId }));
        } else {
          this.store.dispatch(new AddGetStichwortBaum({ id: dummyId, data: alldata }));
        }
      }).unsubscribe()
    }
  }


  /**bestimmte Node expandieren nach Laden der Daten  */
  expandNode(dummyId: number, klammerid: number, sortimentid: number, stichwortid: number) {
    this.expandNodes[dummyId] = [<KeywordNode>{ klammerid: klammerid, sortimentid: sortimentid, stichwortid: stichwortid }];
  }

  /**Pointer auf node */
  selectNode(dummyId: number, klammerid: number, sortimentid: number, stichwortid: number) : void {
    this.getStichwortNodes(dummyId).pipe(filter(f => f && f.length > 0)).subscribe(f => {
      var selsstichwort = null;
      var selsortiment = null;
      var selctedklammer = f.find(f => f.data && f.data.klammerId == klammerid) ;

      if (selctedklammer && sortimentid) {
        selsortiment = selctedklammer.children.find(f => f.data && f.data.sortimentId == sortimentid);
        if (selsortiment && stichwortid) {
          selsstichwort = this.findStichwortNode(selsortiment.children, stichwortid)
        }
      }

      if (klammerid && sortimentid && stichwortid && selsstichwort ) {

        if ( this.selectedKeyWords[dummyId] != undefined && selsstichwort != undefined &&
          this.selectedKeyWords[dummyId].data != undefined && selsstichwort.data != undefined
          && this.selectedKeyWords[dummyId].data.stichwortId == selsstichwort.data.stichwortId)
        {
              return;
        }
        this.selectedKeyWords[dummyId] = selsstichwort; // das schönste und beste

      } else if (klammerid && sortimentid && stichwortid == undefined && selsortiment)
      {
        if ( this.selectedKeyWords[dummyId] != undefined && selsortiment != undefined &&
          this.selectedKeyWords[dummyId].data != undefined && selsortiment.data != undefined
          && this.selectedKeyWords[dummyId].data.sortimentId == selsortiment.data.sortimentId)
        {
              return;
        }

            this.selectedKeyWords[dummyId] = selsortiment;
      } else if (klammerid && sortimentid == undefined && stichwortid == undefined && selctedklammer)
      {
        if ( this.selectedKeyWords[dummyId] != undefined && selctedklammer != undefined &&
          this.selectedKeyWords[dummyId].data != undefined && selctedklammer.data != undefined
          && this.selectedKeyWords[dummyId].data.klammerId == selctedklammer.data.klammerId)
        {
              return;
        }
          this.selectedKeyWords[dummyId] = selctedklammer;

      }
    });
  }


  findStichwortNode(nodes: TreeNode[], stichwortid: number): TreeNode {
    var fd = nodes.find(f => f.data &&  f.data.stichwortId == stichwortid);
    if (fd != undefined) return fd;
    if (nodes.length > 0) {
      var fdsub = undefined;
      nodes.forEach(sub => {
        if (sub.children && sub.children.length > 0 && fdsub == undefined) {
          fdsub = this.findStichwortNode(sub.children, stichwortid);
        }
      })
      if (fdsub != undefined) {
        return fdsub;
      }
    }
  }


  /** */
  loadNodeStichwortbaum(dummyId: number, klammerid: number, sortimentid: number, stichwortid: number) {

    //Keine Klammer, kein Sortiment, kein Stichwort
    if ((klammerid == undefined || klammerid < 0) && (sortimentid == undefined || sortimentid < 0) && (stichwortid == undefined || stichwortid < 0)) {
      this.selectStichwortStrukturBaumAll$.subscribe(alldata => {
        if (alldata == undefined || alldata.length == 0) {
          this.store.dispatch(new GetStichwortBaum({ id: dummyId }));
        } else {
          this.store.dispatch(new AddGetStichwortBaum({ id: dummyId, data: alldata }));
        }
      }).unsubscribe()
    }
    //nur Klammer
    else if (klammerid != undefined && klammerid > 0 && sortimentid == undefined && stichwortid == undefined) {
      this.store.dispatch(new GetStichwortBaumbyKlammer({ id: dummyId, klammerid: klammerid }));
    }
    // Klammer und Sortiment
    else if (klammerid != undefined && klammerid > 0 && sortimentid != undefined && stichwortid == undefined) {
      var p2 = <SortimentsstrukturService.SortimentsstrukturGetExpandedKlammerSortimentStichwort2Params>
        {
          klammerId: klammerid, sortimentId: sortimentid
        }
      this.store.dispatch(new GetStichwortBaumbyKlammerSortiment({ id: dummyId, params: p2 }));
    }
    //alle drei
    else if (klammerid != undefined && klammerid > 0 && sortimentid != undefined && stichwortid != undefined) {
      var p3 = <SortimentsstrukturService.SortimentsstrukturGetKlammerSortimentStichwort4Params>
        {
          klammerId: klammerid, sortimentId: sortimentid, stichwortId: stichwortid
        }
      this.store.dispatch(new GetStichwortBaumbyKlammerSortimentStichwort({ id: dummyId, params: p3 }));
    } else {
      this.store.dispatch(new CommonDataErrorAction({
        type: CommonDataActionType.GetKlammernActionDone, message: "Fehler Baum laden.",
        error: <SimpleResult>{ userMessage: 'Baumstruktur not defined for ', generalResult: GeneralResultEnum.OnlyDebug, error: new Error('Baumstruktur nicht definiert ') }
      }));
    }
  }


  /**Options für Tree  */
  getStichwortNodes(id: number): Observable<TreeNode[]> {
    return this.stichwortNodes.pipe(map(f => {
      if (f == undefined) return [];
      if (f[id]) {
        return f[id];
      }
      return [];
    }
    ));
  }




}



