import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ArticledataEditComponent } from '@app/+articledata/components/articledata-edit/articledata-edit.component';
import { ArticleDataActionType, ClearArticleDataSuccessRsults, ClearArticleErrors, DeleteArticleDataAction, GetArticleDataAction, GetArticleDataSoMaContactsAction,  UpdateArticleDataAction } from '@app/+articledata/store/actions';
import { selectArticleData, selectArticleDataSuccess, selectcurrentError, selectSomaContactData,  selectArticleHits } from '@app/+articledata/store/selectors';
import { ArticleDataActionDone } from '@app/+articledata/store/state';
import { DeleteAllMessagesAction, DeleteSingleMessagesAction } from '@app/+notifications/store';
import { selectNotificationsbyType } from '@app/+notifications/store/selectors';
import { LieferantService } from '@app/api/filialmatrix/services';
import { LieferantenService } from '@app/common-data-module/services/LieferantenService';
import { IAuthUser, IClientError } from '@app/common/interfaces/Interfaces';
import { ActionStates, GeneralResultEnum, TaskTypes, TaskUserModel } from '@app/common/models';
import { LiPoContact } from '@app/common/models/lipocontact.model';
import { PageSorting } from '@app/common/models/PagingSort-model';
import { SendMessageObject, SignalMessageContentModel, MessageStatusEnum, SendMessageTypeContainer, MessageTypeEnum } from '@app/common/models/signal.model';
import { SimpleResult } from '@app/common/models/simpleResult.model';
import { AuthService } from '@app/common/services/auth.service';
import { LipoMessageService } from '@app/common/services/LipoMessageService';
import { NotificationService } from '@app/common/services/notification.service';
import { SignalMessageService } from '@app/common/services/signalmessage.service';
import { AddNavigationTabAction } from '@app/store/navigation/actions';
import { LogUsersClientErrorAction } from '@app/store/user/actions';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import { ConfirmationService, LazyLoadEvent, Message } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';


/**Zwischenspeicherung der Filterung */
export interface CategorySelection {
  selectedCategories: string[];
  selectedalleoderEigene: string[];
  task: TaskUserModel,
}

@Component({
  selector: 'app-articledata-list',
  templateUrl: './articledata-list.component.html',
  styleUrls: ['./articledata-list.component.scss']
})
export class ArticledataListComponent implements OnInit, OnDestroy {

  selectedCategories: string[] = ["0"];
  selectedalleoderEigene: string[] = ["0"];

  pageSorting = new PageSorting(20);
  authUser: IAuthUser;
  isManager: boolean = false;
  isAdmin: boolean = false;
  articledata$: Observable<TaskUserModel[]>;
  errorHandle$: Observable<SimpleResult>;
  selectSomaContactData$: Observable<LiPoContact[]>;
  selectSuccess$: Observable<ArticleDataActionDone>;
  selectArticleDataMessages$: Observable<SendMessageTypeContainer<any>[]>;
  selectSupplierData$: Observable<LiPoContact[]>;
  selectArticleHits$: Observable<number>;



  constructor(private store: Store<any>, private dialogService: DialogService, private router: Router,
    private authService: AuthService,
    private confirmationsService: ConfirmationService,
    private notifier: NotificationService,
    private lieferantenService: LieferantenService,
    private signalmessageService: SignalMessageService,
    private lipomessageService: LipoMessageService<Message>
  ) {
    this.articledata$ = this.store.pipe(select(selectArticleData));
    this.errorHandle$ = this.store.pipe(select(selectcurrentError));
    this.selectSomaContactData$ = this.store.pipe(select(selectSomaContactData));
    this.selectSuccess$ = this.store.pipe(select(selectArticleDataSuccess));
    this.selectArticleDataMessages$ = this.store.select(selectNotificationsbyType(MessageTypeEnum.Artikeldaten));

    this.selectArticleHits$ = this.store.pipe(select(selectArticleHits));

    var filterCategories = this.router.getCurrentNavigation()?.extras?.state?.filterCategories as CategorySelection; // params reverse aus listeditor
    if (filterCategories != undefined) {
      if (filterCategories.selectedCategories) {
        this.selectedCategories = filterCategories.selectedCategories;
      }
      if (filterCategories.selectedalleoderEigene) {
        this.selectedalleoderEigene = filterCategories.selectedalleoderEigene;
      }
    }
  }


  ngOnDestroy(): void {
    //Entfernen aller Counts wenn Articledata wieder verlassen wird
    this.store.dispatch(new DeleteAllMessagesAction({ targetUserId: this.authUser.id, messageType: MessageTypeEnum.Artikeldaten }));
  }



  loadArticleData(e: LazyLoadEvent) {
    this.pageSorting.skipRows = e.first;
    this.pageSorting.rowsToLoad = e.rows;

    this.store.dispatch(new GetArticleDataAction({
      filterOptions: this.getFilterOptions(this.selectedCategories), sortoptions: "articledatachange desc",
      somaAlle: this.selectedalleoderEigene.length > 0 ? this.selectedalleoderEigene[0] == "1" : false,
      skip: this.pageSorting.skipRows, take: this.pageSorting.rowsToLoad
    }));

  }

  ngOnInit(): void {

    this.store.dispatch(new GetArticleDataSoMaContactsAction());


    this.store.dispatch(new GetArticleDataAction({
      filterOptions: this.getFilterOptions(this.selectedCategories), sortoptions: "articledatachange desc",
      somaAlle: (this.selectedalleoderEigene.length > 0 ? this.selectedalleoderEigene[0] == "1" : false),
      skip: this.pageSorting.skipRows, take: this.pageSorting.rowsToLoad

    }));

    this.authService.claims$.pipe(filter(t => t.client_id), map(f => {
      return <IAuthUser>{ name: f.name, id: f.sub, client_id: f.client_id, member_of: f.member_of, }
    })).subscribe(user => {
      this.authUser = user;

      if (environment.managers.some(x => user.member_of.includes(x))) {
        this.isManager = true;
        this.lieferantenService.loadLieferanten(<LieferantService.LieferantQueryParams>{  eagerLoading: 2, queryToken: { friendlyName: 'ArticleData', filter: null }}, true);
      }
      if (environment.admins.some(x => user.member_of.includes(x))) {
        this.isAdmin = true;
      }
    });


    this.errorHandle$.pipe(filter(t => t != undefined && (t.userMessage != undefined || t.serverMessage != undefined))).subscribe(e => {
      this.lipomessageService.Clear();

      if (e.generalResult == GeneralResultEnum.HttpError) {
        this.lipomessageService.Publish({ severity: "error", summary: e.userMessage ? e.userMessage : e.userMessage, detail: e.serverMessage });
      } else if (e.generalResult == GeneralResultEnum.AuthError) {
        this.lipomessageService.Publish({ severity: "error", summary: (e.userMessage ? e.userMessage : "Die Anmeldung ist abgelaufen"), detail: "Sie werden neu angemeldet" });

        this.authService.login();

      } else if (e.generalResult == GeneralResultEnum.OnlyDebug) {
        this.store.dispatch(new LogUsersClientErrorAction({ error: <IClientError>{ userid: this.authUser?.id, modul: 'Articledata-List', error: e.userMessage + " " + e.serverMessage } }));
      }
      else if (e.generalResult == GeneralResultEnum.LogicWarning) {
        this.lipomessageService.Publish({ severity: "warn", summary: e.userMessage ? e.userMessage : "Fehler Artikeldaten bearbeiten", detail: e.userMessage });
      } else if (e.generalResult != GeneralResultEnum.Success) {
        this.notifier.showError(e.userMessage);
        console.debug(e?.serverMessage);
      }

      this.store.dispatch(new ClearArticleErrors());

    });


    this.selectSuccess$.subscribe((data: ArticleDataActionDone) => {
      if (data && data.result) {
        if (data.actionResult == ArticleDataActionType.UpdateArticleDataActionDone && data.statechangedTo == ActionStates.Transfered_Lieferant) {
          this.store.dispatch(new ClearArticleDataSuccessRsults());
          this.sendErstelltNachricht(data.result);
          if (!this.isManager) {
            var newmessgDetail = "Der Input wurde erfolgreich an Hugendubel übergeben. <br>Bei Rückfragen kommen wir auf Sie zu.";
            this.confirmationsService.confirm({
              header: 'Vielen Dank für Ihren Input!',
              message: newmessgDetail,
              acceptLabel: 'OK',
              icon: 'pi pi-check',
              rejectVisible: false
            });
          }
        } else if (data.actionResult == ArticleDataActionType.InsertArticleDataActionDone) {
          this.store.dispatch(new ClearArticleDataSuccessRsults());
          this.lipomessageService.Clear();
          this.lipomessageService.Publish({
            severity: 'success',
            summary: 'Artikeldaten "' + data.result.name + '" wurden erstellt'
          });
        } else if (data.actionResult == ArticleDataActionType.UpdateArticleDataActionDone) {
          this.store.dispatch(new ClearArticleDataSuccessRsults());

          this.lipomessageService.Clear();
          this.lipomessageService.Publish({
            severity: 'success',
            summary: 'Artikeldaten "' + data.result.name + '" wurden gespeichert'
          });

        }
      }
    });
  }

  /**Verschickt Nachricht an soma -> Neu angelegt
   */
  sendErstelltNachricht(item: TaskUserModel): void {
    var target = item.userId; // Soma als target
    var source = this.authUser.id;
    var newMessage = "Neuer Input " + item.name + " angelegt.";
    var messageobject = new Array<SendMessageObject<SignalMessageContentModel>>();
    messageobject.push(<SendMessageObject<SignalMessageContentModel>>{
      id: 0,
      messageTypeId: 0,
      targetUserId: target,
      sendDate: new Date(),
      messageStatus: MessageStatusEnum.New,
      sourceUserId: source,
      data: <SignalMessageContentModel>{ message: newMessage }
    });

    var messcontainer = <SendMessageTypeContainer<SignalMessageContentModel>>{
      id: 0,
      messageType: MessageTypeEnum.Artikeldaten,
      parentId: item.id,
      targetUserId: target,
      creatingUserId: source,           // initialer User
      messages: messageobject,
    }
    this.signalmessageService.SendMessage<SignalMessageContentModel>(messcontainer).subscribe(() => {
      // ok erledigt
    }, e => {
      this.store.dispatch(new LogUsersClientErrorAction({ error: <IClientError>{ userid: this.authUser?.id, modul: 'Soma-Task-View', error: e } }));
    })
  }

  /**Notificationstyle #6202 fette Schrift */
  getNotificationStyle(row: TaskUserModel): Observable<any> {
    return this.selectArticleDataMessages$.pipe(map(m => {
      if (this.isManager == false) return null; // nur bei soma zur Zeit
      var hasmessage = m.filter(h => h.targetUserId == this.authUser.id && h.parentId == row.id).length > 0;
      return hasmessage ? { 'font-weight': 'bold' } : null;
    }));
  }

  isLieferant(): boolean {
    return this.authUser != undefined && !this.isManager;
  }

  canReaktivieren(rowData: TaskUserModel): boolean {
    return this.isManager && (rowData.taskState == 250);
  }

  canDelete(rowData: TaskUserModel): boolean {
    if (this.isManager) return rowData.taskState >= 450 && rowData.taskState < 800;
    return rowData.taskState < 450 && rowData.taskState != 250;
  }

  openArticleDataInsertDialog(): void {
    var dialogHeader = "Artikeldaten anlegen";
    const ref = this.dialogService.open(ArticledataEditComponent,
      {
        header: dialogHeader,
        width: '64rem',
        height: 'auto',
        dismissableMask: false,
        data: {
          id: -1,
          isManager: this.isManager
        }
      });
  }

  openArticledataInfo(row: TaskUserModel): void {
    var dialogHeader = "Artikeldaten " + ((this.isManager || row.isReadOnly == 1) ? "anzeigen" : "bearbeiten");
    const ref = this.dialogService.open(ArticledataEditComponent,
      {
        header: dialogHeader,
        width: '64rem',
        height: 'auto',
        dismissableMask: false,
        data: {
          id: row.id,
          isManager: this.isManager
        }
      });
  }

  getLieferantName(task: TaskUserModel): Observable<string> {
    return this.lieferantenService.selectLieferantenFlat$.pipe(map(m => {
      if (task == undefined) return "";
      var user = m.filter(t => t.lieferantUserId == task.targetUserId)[0];
      if (!user) return "";
      return user.lieferantName ;;
    }))
  }


  /**ManagerName also Empfänger ermitteln, entspricht hier dem CreatingUser = UserId */
  getManagerName(task: TaskUserModel): Observable<string> {
    return this.selectSomaContactData$.pipe(map(m => {
      if (task == undefined) return "";
      var user = m.filter(t => t.id == task.userId)[0];
      if (!user) return "";
      return user.friendlyName;
    }))
  }

  getInfotooltip(rowdatae: TaskUserModel): string {
    if (rowdatae.isReadOnlyMessage) return rowdatae.isReadOnlyMessage;
    return "Artikeldaten";
  }


  openUpload(rowData: TaskUserModel) {

    this.store.dispatch(new AddNavigationTabAction({ displayName: 'Upload ' + rowData.name, url: `list-editor/${rowData.id}/articledata` }, true));
    var filter = <CategorySelection>{ selectedCategories: this.selectedCategories, selectedalleoderEigene: this.selectedalleoderEigene, task: rowData }
    //Task auf Bearbeiten wenn new
    if ((rowData.taskType == 1) && rowData.taskState as ActionStates == ActionStates.New) {
      var updtask = { ...rowData };
      updtask.taskState = ActionStates.OnGoing;
      this.store.dispatch(new UpdateArticleDataAction({ item: updtask, successmessage: true }));
      filter.selectedCategories = [ActionStates.OnGoing.toString()];
    }

    const navigationExtras: NavigationExtras = { state: { filterCategories: filter } };
    this.router.navigate([`list-editor/${rowData.id}/articledata`], navigationExtras);
  }

  getUploadClass(row: TaskUserModel): string {
    return  this.getIconUpload(row) == 'pi pi-paperclip' ? '' : 'p-button-disabled';
  }

  /**Icon je nach Manager oder Lieferant */
  getIconUpload(row: TaskUserModel): string {
    var icon = "pi pi-paperclip";
    if (row && (!this.isManager && row.taskState == ActionStates.Deleted_SOMA)) {
      icon = "pi pi-check";
    } else if (row && (this.isManager && row.taskState == ActionStates.Deleted_SOMA)) {
      icon = "pi pi-circle";
    } else if (row && (!this.isManager && row.taskState > ActionStates.Done)) {
      icon = "pi pi-check";
    } else if (row && (this.isManager && row.taskState >= ActionStates.Transfered)) {
      icon = "pi pi-check";
    }
    return icon;
  }

  getTooltipUploadEdit(row: TaskUserModel): string {
    if (row && row.taskType > ActionStates.Transfered) return 'Upload ansehen';
    if (row && row.isReadOnly == 1) return 'Upload ansehen';
    if (row && row.isEditExpired == 1) return 'Upload ansehen';
    return 'Upload bearbeiten';
  }

  getUploadtooltip(row: TaskUserModel): string {
    var tooltip = "Upload ansehen";
    return tooltip;
  }

  reactivateArticleData(id: number): void {
    var sub = this.articledata$.subscribe(tasks => {
      var task = tasks.filter(t => t.id == id)[0];
      if (task) {
        this.confirmationsService.confirm({
          message: "Möchten Sie '" + task.name + "' reaktivieren ?",
          acceptLabel: 'OK',
          accept: () => {
            var updtask = { ...task };
            updtask.taskState = 450; // wieder auf Transfered
            this.store.dispatch(new UpdateArticleDataAction({ item: updtask, successmessage: true }));
          }
        });
      }
    })
    sub.unsubscribe();
  }


  deleteArticleData(id: number): void {
    var sub = this.articledata$.subscribe(tasks => {
      var task = tasks.filter(t => t.id == id)[0];
      if (task) {
        this.confirmationsService.confirm({
          message: "Möchten Sie diesen Input löschen ?",
          acceptLabel: 'OK',
          accept: () => {
            this.store.dispatch(new DeleteSingleMessagesAction({ targetUserId: task.userId, messageType: MessageTypeEnum.Artikeldaten, id: id }));
            this.store.dispatch(new DeleteArticleDataAction({ id: id, isManager: this.isManager }));
          }
        });
      }
    })
    sub.unsubscribe();

  }

  selectAlleoderEigene(alleodereigene: string): void {
    this.selectedalleoderEigene = [alleodereigene];
    this.store.dispatch(new GetArticleDataAction({
      filterOptions: this.getFilterOptions(this.selectedCategories), sortoptions: "articledatachange desc",
      somaAlle: this.selectedalleoderEigene.length > 0 ? this.selectedalleoderEigene[0] == "1" : false,
      skip: this.pageSorting.skipRows, take: this.pageSorting.rowsToLoad
    }));
  }

  selectCategories(categorie: string): void {
    if (categorie == "0") {
      this.selectedCategories = ["0"];
    } else {
      this.selectedCategories = this.selectedCategories.filter(g => g != "0");
      if (this.selectedCategories.length == 0) {
        this.selectedCategories = ["0"];
      }
    }

    this.store.dispatch(new GetArticleDataAction({
      filterOptions: this.getFilterOptions(this.selectedCategories), sortoptions: "articledatachange desc",
      somaAlle: this.selectedalleoderEigene.length > 0 ? this.selectedalleoderEigene[0] == "1" : false,
      skip: this.pageSorting.skipRows, take: this.pageSorting.rowsToLoad
    }));
  }

  /**Filteroptionen aufbauen */
  getFilterOptions(selectCategories: string[]): string {
    if (!this.isManager) {
      //Verschickte erweitern um die Transferierten und abgeschlossenen
      if (selectCategories.filter(g => g == ActionStates.Transfered_Lieferant.toString()).length > 0) {
        if (selectCategories.filter(g => g == ActionStates.Transfered.toString()).length == 0) {
          selectCategories.push(ActionStates.Transfered.toString());
        }
        if (selectCategories.filter(g => g == ActionStates.Complete.toString()).length == 0) {
          selectCategories.push(ActionStates.Complete.toString());
        }
      } else {
        selectCategories = selectCategories.filter(g => g != ActionStates.Transfered.toString() && g != ActionStates.Complete.toString());
      }
    }
    this.selectedCategories = selectCategories;
    return selectCategories.join(";");
  }

}

