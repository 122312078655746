import { NavigationTab, NavMenuItem } from '@app/common/models';
import { NavigationState } from './state';

export class ReplaceStateAction {
    readonly type = '[NAVIGATION] ReplaceState';
    constructor(public payload: NavigationState) {}
}

export class AddNavigationTabAction{
    readonly type = '[NAVIGATION] AddNavigationTab';
    constructor (public payload: NavigationTab, public ifNotExists?: boolean){}
}

export class RemoveNavigationTabAction{
    readonly type = '[NAVIGATION] RemoveNavigationTab';
    constructor(public payload: number){}
}

export class RemoveTabByNameAction {
    readonly type = '[NAVIGATION] RemoveTabByName';
    constructor (public payload: string) {}
}

export class InitNavMenueAction {
    readonly type = '[NAVIGATION] InitNavMenueAction';
    constructor (public payload: { entries: NavMenuItem[] } ){}
}

export class ChangeNavMenueAction {
    readonly type = '[NAVIGATION] ChangeNavMenueAction';
    constructor (public payload: { entry: NavMenuItem } ){}
}

export class AddNavMenueAction {
    readonly type = '[NAVIGATION] AddNavMenueAction';
    constructor (public payload: { entry: NavMenuItem } ){}
}




export type NavigationAction =
InitNavMenueAction | ChangeNavMenueAction | AddNavMenueAction |
AddNavigationTabAction | RemoveNavigationTabAction | ReplaceStateAction | RemoveTabByNameAction
;
