import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { ListEditorService } from '@app/common/services/list-editor-service';
import { selectListEditorState } from './state';
import {
  ListEditorActionType, GetListWithIdAction, GetRowListDoneAction, SaveUploadListItemAction, InsertUploadListItemAction, InsertRowDoneAction,
  ValidateRowsDoneAction, SaveUploadListItemDoneAction, DeleteRowsAction, DeleteUploadRowsAction, FinishUploadAction, FinishUploadDoneAction, BubImportSelectedRowsAction, BubImportSelectedRowsDoneAction, FinishUploadBySupplierDoneAction,
  FinishUploadByManagerAction, FinishUploadByManagerDoneAction, FinishUploadBySupplierAction, CreateUploadAction, CreateUploadActionDone, GetValidationsAction, GetValidationsDoneAction,
  GetUsedValidationListById,
  GetUsedValidationListByIdDone,
  UpdateUploadMultiRowsAction,
  UpdateUploadMultiRowsDoneAction
} from './action';
import { withLatestFrom, switchMap, map, tap, mergeMap } from 'rxjs/operators';
import { TaskService } from '@app/common/services/task.service';
import { ValidationEditService } from '@app/common/services/validation-edit.service';
import { Observable } from 'rxjs';

@Injectable()
export class ListEditorEffects {
  //@ts-ignore
  private state$ = this.store.pipe(select(selectListEditorState));

  constructor(
    private store: Store<any>,
    private service: ListEditorService,
    private actions: Actions,
    private validationService: ValidationEditService,
  ) { }


  loadValidations$ = createEffect(() => {
    return this.actions.pipe(
      ofType<GetValidationsAction>(ListEditorActionType.GetValidations),
      withLatestFrom(this.state$),
      switchMap(([action, state]) =>
        this.validationService.getValidationProfiles()),
      map(response => new GetValidationsDoneAction({ validations: response }))
    )
  })


  getUsedValidationListById$ = createEffect(() => {
    return this.actions.pipe(
      ofType<GetUsedValidationListById>(ListEditorActionType.GetUsedValidationListById),
      switchMap((action) =>
        this.validationService.GetUsedValidationListById(action.payload.id)
          .pipe(
            map(response => new GetUsedValidationListByIdDone({ validations: response, id: action.payload.id }))
          )
      )
    )
  })



  createUploadAction$ = createEffect(() => {
    return this.actions.pipe(
      ofType<CreateUploadAction>(ListEditorActionType.CreateUploadAction),
      switchMap((action) =>
        this.service.createUpload(action.payload.uploadstaging)
          .pipe(
            map(response => new CreateUploadActionDone({ upload: response, task: action.payload.task }))
          )
      )
    )
  })




  getListWithId$ = createEffect(() => {
    return this.actions.pipe(
      ofType<GetListWithIdAction>(ListEditorActionType.GetListWithId),
      withLatestFrom(this.state$),
      mergeMap(([action, state]) =>
        this.service.getUploadRows({ taskId: action.payload.id, params: action.payload.lieferantenparams })),
      tap(console.log.bind(window)),
      map(response => new GetRowListDoneAction({ result: response }))
    )
  })



  saveUploadMultiRows$ = createEffect(() => {
    return this.actions.pipe(
      ofType<UpdateUploadMultiRowsAction>(ListEditorActionType.UpdateUploadMultiRowsAction),
      withLatestFrom(this.state$),
      mergeMap(([action, state]) =>
        this.service.saveUploadMultiRows(action.payload.param, action.payload.selectedRows).pipe(map(response => new UpdateUploadMultiRowsDoneAction({ result: response.data, selectedRows: action.payload.selectedRows }))
        )))
  })

  saveUploadListItem$ = createEffect(() => {
    return this.actions.pipe(
      ofType<SaveUploadListItemAction>(ListEditorActionType.SaveUploadListItem),
      withLatestFrom(this.state$),
      mergeMap(([action, state]) =>
        this.service.saveUploadListItem({
          id: action.payload.id, cols: action.payload.cols, isValid: action.payload.isValid,
          validationInfo: action.payload.validationInfo
        }).pipe(map(response => new SaveUploadListItemDoneAction({ result: response, selectedRows: action.payload.selectedRows }))
        )))
  })


  insertUploadListItem$ = createEffect(() => {
    return this.actions.pipe(
      ofType<InsertUploadListItemAction>(ListEditorActionType.InsertUploadListItem),
      withLatestFrom(this.state$),
      tap(console.log.bind(window)),
      mergeMap(([action, state]) =>
        this.service.insertUploadListRow({ uploadId: action.payload.uploadId, oldid: action.payload.oldId, validationName: action.payload.validationName, cols: action.payload.cols })
          .pipe(map(response => new InsertRowDoneAction({ result: response })))
      ))
  })







  deleteUploadRows$ = createEffect(() => {
    return this.actions.pipe(
      ofType<DeleteUploadRowsAction>(ListEditorActionType.DeleteUploadRows),
      withLatestFrom(this.state$),
      switchMap(([action, state]) =>
        this.service.deleteRows(action.payload.ids)
          .pipe(map(response => new DeleteRowsAction({ ids: action.payload.ids }))))
    )
  })


  finishUpload$ = createEffect(() => {
    return this.actions.pipe(
      ofType<FinishUploadAction>(ListEditorActionType.FinishUpload),
      withLatestFrom(this.state$),
      switchMap(([action, state]) =>
        this.service.finishUpload({ id: action.payload.uploadId })
          .pipe(map(response => new FinishUploadDoneAction({ finishResult: response }))))
    )
  })


  finishUploadBySupplier$ = createEffect(() => {
    return this.actions.pipe(
      ofType<FinishUploadBySupplierAction>(ListEditorActionType.FinishUploadBySupplier),
      withLatestFrom(this.state$),
      switchMap(([action, state]) =>
        this.service.finishUploadBySupplier({ id: action.payload.uploadId })
          .pipe(map(response => new FinishUploadBySupplierDoneAction({ finishResult: response }))))
    )
  })


  importSelectedFromBub$ = createEffect(() => {
    return this.actions.pipe(
      ofType<BubImportSelectedRowsAction>(ListEditorActionType.BubImportSelectedRows),
      withLatestFrom(this.state$),
      switchMap(([action, state]) =>
        this.service.importBubRows(action.payload.uploadId, action.payload.eanList)
          .pipe(map(response => new BubImportSelectedRowsDoneAction({ uploadId: action.payload.uploadId, stagingDetails: response.data }))))
    )
  })


  finishUploadByManager$ = createEffect(() => {
    return this.actions.pipe(
      ofType<FinishUploadByManagerAction>(ListEditorActionType.FinishUploadByManager),
      withLatestFrom(this.state$),
      switchMap(([action, state]) =>
        this.service.finishUploadByManager( action.payload.taskId,  action.payload.params )
          .pipe(map(response => new FinishUploadByManagerDoneAction({ finishResult: response }))))
    )
  })
}
