import { UploadFileDto } from './uploadFileDto.model';
import { FileUrlDtoModel } from './FileUrlDto.model';
import { LookupValueBase } from './novi/columnLookup.model';
import { LieferantUserDTO } from '@app/common-data-module/models/LieferantUserDTO';


// #7696

export interface TaskLieferantParams {
    parentId: string;
    lieferantType: LieferantTypeEnum;
    taskUserModel: TaskUserModel;
    lieferantUsers: LieferantUserDTO[];
}

export enum  LieferantTypeEnum
{
  unbekannt = 0,
  Lieferant = 1,
  //CategoryCaptain
  CategoryManager = 2
}



/**#7432 */

export enum AufgabenArt {
  unbekannt = -1,
  Artikelliste = 1,
  Novität = 2,
  WeitereBedarfe = 3,
}

/**Aufagbenart Liste zur Auswahl */
export const AufgabenartValidListe =
[
  <LookupValueBase>{ id: AufgabenArt.Artikelliste, value: 'Artikelliste' },
  <LookupValueBase>{ id: AufgabenArt.Novität, value: 'Novität' },
  <LookupValueBase>{ id: AufgabenArt.WeitereBedarfe, value: 'Weitere Bedarfe' },
];



export enum TaskTypes {
  Task = 'task',
  Articledata = 'articledata'
}
/**Task State */

export enum ActionStates {

  Unknown = 0,
  Created = 100,
  New = 200,
  Deleted_SOMA = 250,
  OnGoing = 300,
  Done = 400,
  Transfered_Lieferant = 450,
  Transfered = 500,
  TransferedNoInput = 550,
  Complete = 600,
  Archived = 700,
  Deleted = 800,
  Deferred = 900 // zurückgestellt

}



export interface TaskUserModel {
    name: string;
    organization: string;
    position: string;
    email: string;
    id: number;
    groupId: number;
    //CreatingUserID hier immer Soma
    userId: string;
    isReadOnly: number;
    isReadOnlyMessage: string;
    isEditExpired: number;
    isEditExpiredMessage: string,
    min: number;
    max: number;
    taskInfo: string;
    taskDescription: string;
    taskValidRange: Date[];
    taskValidRangeFrom: Date;
    taskValidRangeTo: Date;
    taskActiveRange: Date[];
    taskActiveRangeFrom: Date;
    taskActiveRangeUntil: Date;
    sendInvite: boolean;
    invitationDate: Date;
    sendInviteOne: boolean;
    sendInviteTwo: boolean;
    sendInviteThree: boolean;
    dateInviteOne: Date;
    dateInviteTwo: Date;
    dateInviteThree: Date;
    includeUpload: boolean;
    selectedValidation: string;
    selectedValidationId: number;
    loadSnapshot: boolean;
    selected: boolean;
    taskParentId: number;
    creatorToken: string;
    creatorMail: string;
    creatorFriendlyName: string;
    uploadStagingId: number;
    uploadFiles: UploadFileDto;
    uploadState: number;
    fileUrls: FileUrlDtoModel[];
    lastName: string;
    firstName: string;
    fullName: string;
    gender: number;
    sortimentName: string;
    sortimentIds: string[];
    klammerName: string;
    klammerId: string;
    typeInfoId: string;

    targetUserId: string;
    color: string;
    taskState: number;
    taskType: number;
    articledatachange?: Date;
    articledatatransfered?: Date;
    hits?: number;

    /*Externe Bedarfe */
    aufgabenArt: AufgabenArt;
    einkaufId: string;
    mandantId: string;
    lieferantId: string;
    lieferantType: LieferantTypeEnum;

}
