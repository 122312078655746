import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TaskUserModel } from '../models';
import { environment } from '@env/environment';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private httpClient: HttpClient, private oauthService: OAuthService) { }

    get(): Observable<TaskUserModel> {
        // const url = `${environment.connections.UserService}/TestAuth`;
        // const httpOptions = {
        //     headers: new HttpHeaders({
        //       'Access-Control-Allow-Origin': '*',
        //       Authorization: 'Bearer ' + this.oauthService.getAccessToken()
        //     })
        //   };
        return null;
    }

    getAll(groupid: number): Observable<TaskUserModel[]> {
        // const url = `${environment.connections.UserService}/GetAllUsersOfGroup`;
        // const httpOptions = {
        //     headers: new HttpHeaders({ 
        //       'Access-Control-Allow-Origin': '*',
        //     }),
        //     params: {groupid: groupid.toString()}
        //   };
        // return this.httpClient.get<TaskUserModel[]>(url, httpOptions);
        return null;
    }

}
