import { SortMeta } from "primeng/api";

/**Paging / Sorting P-table - Caching */
export class PageSorting {
  rowsToLoad: number;
  skipRows: number;
  sortField: string = "";
  sortOrder: number = 1;
  rowSelection: any;
  multisortMeta: SortMeta[] = undefined;

  constructor(rowstoload: number)
  {
    this.skipRows = 0;
    this.rowsToLoad = rowstoload;
  }
}
