import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { GetListsForUserAction, ShowValidationModalAction, SetLoadingAction } from './store/actions';
import { selectLists, selectTabFlag, selectLoading } from './store/selectors';
import { AddNavigationTabAction } from '@app/store/navigation/actions';
import { Router } from '@angular/router';

@Component({
    selector: 'app-list-overview',
    templateUrl: './list-overview.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls:  ['./list-overview.component.scss']
})

export class ListOverviewComponent implements OnInit {

    lists$ = this.store.pipe(select(selectLists));
    isLoading$ = this.store.pipe(select(selectLoading));
    tab = this.store.pipe(select(selectTabFlag));

    constructor(private store: Store<any>, private router: Router) {
        this.load();
    }

    load() {
        this.store.dispatch(new SetLoadingAction({ isLoading: true }));
        this.store.dispatch(new GetListsForUserAction());
    }

    openInTab() {
        this.store.dispatch(new AddNavigationTabAction({ displayName: 'Upload-Listen', url: '/uploads' }, true));
        this.router.navigate(['/uploads']);
    }

    openListEditor(id: number, title: string, uploadName: string, lastEdit: Date) {
        this.store.dispatch(new AddNavigationTabAction({ displayName: title, url: `list-editor/${id}/task` }));
        this.router.navigate([`list-editor/${id}/task`]);
    }

    showValidationDetails(id: number) {
        this.store.dispatch(new ShowValidationModalAction({id}));
    }

    ngOnInit() {

    }
}
