import { createSelector } from '@ngrx/store';
import { selectarticleState } from './state';

export const selectisLoading = createSelector(selectarticleState, x => x.isLoading);
export const selectArticleData = createSelector(selectarticleState, x => x.items);
export const selectcurrentError = createSelector(selectarticleState, x => x.currenterror);

export const selectSomaContactData = createSelector(selectarticleState, x => x.SoMaContacts);



export const selectArticleHits = createSelector(selectarticleState, x => x.itemsHits);


export const selectArticleDataValidationList = createSelector(selectarticleState, x => x.validationList);
export const selectArticleDataSuccess = createSelector(selectarticleState, x => x.successResult);


