import { RowModel } from './row.model';
import { AttributeStatusModel } from './attributeStatus.model';
export interface ListModel {
    id: number;
    name: string;
    maintainerId: number;
    lastEdited: Date;
    startDate: Date;
    dueDate: Date;
    publisher: string;
    statuscode: AttributeStatusModel;
    from: string;
    category?: string;
    description: string;
    validationProfile: string;
    isValid: boolean;
    validationMessage: string;
    rows: RowModel[];
    uploadState: number
}