import { computed } from "@angular/core";
import { DynListOption } from "./columnBase.model";

export interface SingleValue
{
    item: string;
}



export class ColumnValue  {
    usedcolumnid: number;
    columnname: string;
    columndesc: string;
    columncelltype: number;
    ismandatory: boolean;
    items: SingleValue[];
    value: string;
    datevalue: Date;
    booleanValue: boolean;

    options: DynListOption;


}


export class ColumnValuePrime extends ColumnValue {

  field: string;


  constructor(c: ColumnValue)
  {
    super();
    this.field = c.columnname;
    this.usedcolumnid = c.usedcolumnid;
    this.columnname = c.columnname;
    this.columndesc = c.columndesc;
    this.columncelltype = c.columncelltype;
    this.ismandatory = c.ismandatory;
    this.items = c.items;
    this.value = c.value;
    this.datevalue = c.datevalue;
    this.booleanValue = c.booleanValue;
    this.options = c.options;
  }
}
