
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { ISetting, ISettingValue } from '../models/setting.models';
import { Observable } from 'rxjs';
import { ResultObjectModel } from '../models';
import { OAuthService } from 'angular-oauth2-oidc';
import { SignalModel, SendMessageObject, SendMessageTypeContainer, MessageTypeEnum, SendMessageTypeGroupContainer, SignalMessageContentModel, SendMessageTypeGroupsContainer, SimpleMessage, MessageContent } from '../models/signal.model';
import { AuthService } from './auth.service';
import { FeedbackMessageDto, FeedbackMessageParam } from '@app/+usermanagement/models/FeedbackMessageDto';

/**Service für Signalmessaging    */
@Injectable()
export class SignalMessageService {




  constructor(private authService: AuthService, private httpClient: HttpClient)
  {

  }

  sendFeedback(feedbackparam: FeedbackMessageParam)  : Observable<ResultObjectModel<number>>
  {
    const url = `${environment.connections.SignalService}/feedback/SendNotificationFeedback`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    return this.httpClient.post<ResultObjectModel<number>>(url, feedbackparam, httpOptions);
  }

  SendSimpleMessagetoUsers<T>(sendparameter: SimpleMessage<MessageContent>) : Observable<ResultObjectModel<SendMessageTypeContainer<T>[]>> {
    const url = `${environment.connections.SignalService}/signal/sendSimpleMessage`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    return this.httpClient.post<ResultObjectModel<SendMessageTypeContainer<T>[]>>(url, sendparameter, httpOptions);
  }




  updateMessageStatus(targetUserId: string, type: MessageTypeEnum, messagetype: number) : Observable<ResultObjectModel<number>>
  {
    const url = `${environment.connections.SignalService}/signal/updateMessageStatus`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { targetuserid: targetUserId.toString(), messagetypeid: type.toString(), messagetype: messagetype.toString()  },
    };
    return this.httpClient.get<ResultObjectModel<number>>(url, httpOptions);
  }


  SendMessagetoGroup<T>(messcontainergroup: SendMessageTypeGroupContainer<SignalMessageContentModel>) : Observable<ResultObjectModel<SendMessageTypeContainer<T>[]>> {
    const url = `${environment.connections.SignalService}/signal/SendMessagetoGroup`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    return this.httpClient.post<ResultObjectModel<SendMessageTypeContainer<T>[]>>(url, messcontainergroup, httpOptions);
  }

  SendMessagetoGroups<T>(messcontainergroup: SendMessageTypeGroupsContainer<SignalMessageContentModel>, checkifexists: boolean) : Observable<ResultObjectModel<SendMessageTypeContainer<T>[]>> {
    const url = `${environment.connections.SignalService}/signal/SendMessagetoGroups?checkifexists=`+ checkifexists;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    return this.httpClient.post<ResultObjectModel<SendMessageTypeContainer<T>[]>>(url, messcontainergroup, httpOptions);
  }



  SendMessage<T>(message: SendMessageTypeContainer<T>): Observable<ResultObjectModel<SendMessageTypeContainer<T>>> {
    const url = `${environment.connections.SignalService}/signal/SendMessage`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    return this.httpClient.post<ResultObjectModel<SendMessageTypeContainer<T>>>(url, message, httpOptions);
  }


 /**Alle Messsages zu dem Message Type holen  */
  getMessages<T>(targetUserId : string,  messageType: MessageTypeEnum) : Observable<ResultObjectModel<SendMessageTypeContainer<T>[]>>
  {
    const url = `${environment.connections.SignalService}/signal/getMessages`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      } ),
      params: { targetUserId: targetUserId, messageType : messageType.toString()  }
    };
    return this.httpClient.get<ResultObjectModel<SendMessageTypeContainer<T>[]>>(url, httpOptions);
  }


  /**Alle Messsages zu dem Message Type holen  */
  getAllMessages<T>(targetUserId : string) : Observable<ResultObjectModel<SendMessageTypeContainer<T>[]>>
  {
    const url = `${environment.connections.SignalService}/signal/getallMessages`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      } ),
      params: { targetUserId: targetUserId  }
    };
    return this.httpClient.get<ResultObjectModel<SendMessageTypeContainer<T>[]>>(url, httpOptions);
  }



  deletesingleMessage(targetUserId: string, messageType: MessageTypeEnum, id: number) {

    const url = `${environment.connections.SignalService}/signal/deletesingleMessage`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      } ),
      params: { targetUserId: targetUserId, messageType : messageType.toString(), id: id.toString()  }
    };
    return this.httpClient.get<ResultObjectModel<number>>(url, httpOptions);
  }

  /**Löschen aller Meldungen des targetusers und diesem Types */
  deleteAllMessages(targetUserId : string,  messageType: MessageTypeEnum) : Observable<ResultObjectModel<number>>
  {
    const url = `${environment.connections.SignalService}/signal/deleteAllMessages`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      } ),
      params: { targetUserId: targetUserId, messageType : messageType.toString()  }
    };
    return this.httpClient.get<ResultObjectModel<number>>(url, httpOptions);
  }


}

