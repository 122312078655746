
  <div class="container-fluid" >
  <div class="grid"  >
    <div class="col-4">
      <div class="grid" *ngIf=" !isManager && !mediatip.isReadonly && mediatip.mediatipState < 300 ">
        <div class="col-12">EAN
        </div>
        <div class="col-8">
          <div class="p-inputgroup">
            <input [maxlength]="GetMaxLength(mediatip.ean)" id="ean" type="text" pInputText (keyup)="checkean()"
              [(ngModel)]="mediatip.ean" />
            <button pButton type="button" icon="pi pi-times" pTooltip="Löschen"
              (click)="eanreset()"></button>
          </div>
        </div>
        <div class="col-2">&nbsp;</div>
      </div>
      <div class="grid" *ngIf="mediatip.ean && getEanError()">
        <div class="alert alert-warning"> {{getEanError()}}</div>
      </div>
      <div class="grid">
        <div class="col-12">Titelinfo
          <hr />
        </div>
      </div>
      <div class="container-fluid grid p-row">
        <div class="col">
          <img class="bild" *ngIf=" mediatip.bubData && mediatip.bubData.ean && mediatip.bubData.ean.length==13"
            [src]='"https://produktbilder.paragon-data.net/" + mediatip.bubData.ean  + "_120x120.jpg?showDummy=true"'>
        </div>
        <div class="col-8">
          <div class="grid p-row">
            <div class="col-12"><label>{{mediatip.bubData && mediatip.bubData.autor}}</label></div>
            <div class="col-12">{{mediatip.bubData && mediatip.bubData.titel}}</div>
            <div class="col-8">{{mediatip.bubData && mediatip.bubData.ean}}</div>
            <div class="col-4">{{mediatip.bubData && mediatip.bubData.warenGroup}}</div>
            <div class="col-12">{{mediatip.bubData && mediatip.bubData.verlag}}</div>
            <div class="col-8">{{mediatip.bubData && mediatip.bubData.preis}}<span
                *ngIf="mediatip.bubData && mediatip.bubData.preis"> €</span></div>
            <div class="col-4">{{mediatip.bubData && mediatip.bubData.erscheinung | date:"dd.MM.yyyy"}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-8">
      <div class="grid p-row">
        <div class="col-2">Präsenzen</div>
        <div class="col" style="width:50px"> <i *ngIf="!mediatip.isReadonly || isManager"
            class="pi pi-plus plusbutton" (click)="addEmptyMediaPresence(pt)"></i></div>
        <div class="col-2">&nbsp;</div>
        <div class="col-6" *ngIf="!isManager">
          <p-dropdown [style]="{'width':'100%'}" [disabled]="mediatip.isReadonly"
            [options]="selectSomaContactData$ | async" class="dropdownSoma" [(ngModel)]="selectedLiPoContact"
            (onChange)="setLiPoContact(selectedLiPoContact)" optionLabel="friendlyName"
            placeholder="Sortimentsmanager wählen" appendTo="body"></p-dropdown>
        </div>
      </div>
      <div class="grid">
        <p-table #pt class="col-12" [value]="mediatip.mediaPresences" editMode="cell"
          [resizableColumns]="false" dataKey="id" [paginator]="true" [rows]="10" [scrollable]="false"
          [responsive]="true">
          <ng-template pTemplate="header">
            <tr>
              <th class="headerAction">&nbsp;</th>
              <th class="headerDatum">Datum</th>
              <th class="headerName">Name</th>
              <th class="headerArt">Art</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
            <tr>
              <td class="cellAction">
                <button [disabled]="!canAction(rowData, 'löschen') || mediatip.isReadonly" pTooltip="Löschen"
                  class="p-button-warning actionbutton" pButton icon="pi pi-trash"
                  (click)="deleteMediaPresence(rowData, ri)">

                </button>
              </td>

              <td pEditableColumn class="cellDatum">
                <p-cellEditor>
                  <ng-template pTemplate="input">

                    <p-calendar [minDate]="minDatePrecense" [(ngModel)]="rowData.presenceDate" [firstDayOfWeek]="1"
                      [disabled]="!canEdit(rowData, 'presencedate') "
                      dateFormat="dd.mm.yy" appendTo="body" showButtonBar="true" [showIcon]="true" [disabled]="mediatip.isReadonly"
                                        placeholder="Datum wählen"></p-calendar>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <span *ngIf="!rowData.presenceDate">Datum
                      wählen</span>{{ rowData.presenceDate | date:"dd.MM.yyyy" }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td pEditableColumn class="cellName">

                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown [filter]="true" [options]="settingsPresenceNames" [(ngModel)]="rowData.presence"
                     [disabled]="!canEdit(rowData, 'presence') " (onChange)="setPresenceName($event, ri, rowData)"
                      optionLabel="settingValue" placeholder="Präsenz wählen"
                      [style]="{ width: '100%', overflow: 'visible' }" appendTo="body">
                    </p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <span *ngIf="!rowData.presence">Präsenz wählen</span>{{ rowData.presence }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td pEditableColumn class="cellArt">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown [filter]="true" [options]="settingsPresenceTypes" [(ngModel)]="rowData.presenceType"
                      [disabled]="!canEdit(rowData, 'presenceType') "
                      (onChange)="setPresenceType($event, ri, rowData)" optionLabel="settingValue"
                      placeholder="Art wählen" appendTo="body"></p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <span *ngIf="!rowData.presenceType">Art wählen</span>{{ rowData.presenceType }}
                  </ng-template>
                </p-cellEditor>
              </td>
            </tr>
            <tr *ngIf="hasError(mediatip, rowData)">
              <td></td>
              <td colspan="3">
                <div [innerHTML]="getPresenceError(rowData)"></div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

  </div>


  <div class="grid" *ngIf="hasMediatipError(mediatip)">
    <div class="col-4">&nbsp;</div>
    <div class="col-8 alert alert-warning">
      <div [innerHTML]="getMediatipError(mediatip)"></div>
    </div>
  </div>

  <div class="grid col-12">
    <div class="col-4">&nbsp;</div>
    <div class="col field">
      <label for="anmerkung">Anmerkung</label>
      <div class="p-inputgroup">
        <textarea id="anmerkung" [style]="{'width':'100%'}" rows="3" autoResize="false" pInputTextarea [readOnly]="mediatip.isReadonly"
          [(ngModel)]="mediatip.remark">   </textarea>
        <button pButton type="button" icon="pi pi-times" pTooltip="Anmerkung löschen" [disabled]="mediatip.isReadonly"
          (click)="remarkreset()"></button>
      </div>
    </div>
  </div>




  <div class="grid">
    <div class="col-4">

    </div>
    <div *ngIf="mediatip.isReadonly" class="col-6">&nbsp;</div>
    <div *ngIf="mediatip.isReadonly" class="col-2"><button pButton type="button" icon="pi pi-check" label="OK"
        style="padding-left: 15px;" (click)="cancel()"></button></div>
    <div *ngIf="!mediatip.isReadonly" class="col-4"><button [disabled]="!canSaveandSubmit()"
         pButton type="button" icon="pi pi-check"
        [label]="isManager ? 'Speichern + Freigeben' : 'Speichern + Versenden' " style="padding-left: 15px;"
        (click)="saveAndSubmit()"></button></div>
    <div *ngIf="!mediatip.isReadonly" class="col-2"><button [disabled]="!canSave()"
        pButton type="button" icon="pi pi-check" label="Speichern" style="padding-left: 15px;"
        (click)="save(false)"></button></div>
    <div *ngIf="!mediatip.isReadonly" class="col-2"><button  pButton type="button"
        icon="pi pi-times" label="Abbrechen" style="padding-left: 15px;" (click)="cancel()"></button></div>

  </div>


</div>
