import { TaskUserModel } from '@app/common/models';
import { TaskQueryParameter } from '@app/common/services/task.service';





export enum UserViewActionType {
  GetTasksForUser = '[USER-VIEW] GetTasksForUser',
  GetTasksForUserDone = '[USER-VIEW] GetTasksForUserDone',
  GetBreadcrumb = '[USER-VIEW] GetBreadcrump',
  GetBreadcrumbDone = '[USER-VIEW] GetBreadcrumpDone',
  SetTaskState = '[USER_VIEW] SetTaskState',
  UserTaskErrorAction = "UserTaskErrorAction",
  UserTaskClearErrorAction = "UserTaskClearErrorAction"
}

export class UserTaskErrorAction {
  readonly type =  UserViewActionType.UserTaskErrorAction ;
  constructor( public payload: { error : any, message: string}) {}
}

export class UserTaskClearErrorAction {
  readonly type =  UserViewActionType.UserTaskClearErrorAction ;
  constructor( ) {}
}

export class GetTasksForUserAction {
    readonly type = UserViewActionType.GetTasksForUser;
    constructor(public payload: { param : TaskQueryParameter }) {}
}

export class GetTasksForUserDoneAction {
    readonly type = UserViewActionType.GetTasksForUserDone;
    constructor(public payload: { tasks: TaskUserModel[] }) {}
}

export class SetTaskStateAction {
  readonly type = UserViewActionType.SetTaskState;
  constructor(public payload: { taskId: number, newTaskState: number}) {}
}


export class GetBreadcrumbAction {
    readonly type = UserViewActionType.GetBreadcrumb;
    constructor(public options: { id: number}) {}
}

export class GetBreadcrumbActionDone {
    readonly type = UserViewActionType.GetBreadcrumbDone;
    constructor(public payload: { item: string}) {}
}

export type UserViewActions =
    GetTasksForUserAction
    | GetTasksForUserDoneAction
    | GetBreadcrumbAction
    | GetBreadcrumbActionDone
    | SetTaskStateAction
    | UserTaskErrorAction | UserTaskClearErrorAction;
