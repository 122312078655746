import { EntityStatus, LabelDTO, LagerDto, MandantDto, TenantDTO, UserDto } from "@app/api/filialmatrix/models";

export class MandantDtoClient implements  MandantDto
{
  benutzer: Array<UserDto>;
  defaultLager: LagerDto;
  label: LabelDTO;
  name: string;
  tenant: TenantDTO;
  id: number;
  changed?: string;
  created?: string;
  pId?: string;
  status?: EntityStatus;
  version?: number;

   constructor(dto: MandantDto)
  {
    this.id = dto.id;
    this.benutzer = dto.benutzer;
    this.label = dto.label;
    this.defaultLager = dto.defaultLager;
    this.name = dto.name;
    this.tenant = dto.tenant;
    this.pId = dto.pId;
    this.status = dto.status;
    this.version = dto.version;
    this.changed = dto.changed;
    this.created = dto.created;
  }


  get mandantLongId() : string {
    if (this.id == undefined) return null;
    const min = "8280000000000000000";
    if (Number(this.id) < Number(min))
    {
      var result =  min.substring(0,min.length -this.id.toString().length) + this.id.toString();
      return result;
    }
    return this.id.toString();
}


}
