import { FullCalendarModule } from '@fullcalendar/angular';

import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { NgModule, LOCALE_ID, ApplicationRef } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { AppStoreModule } from './app-store.module';
import { ShellModule } from './shell/shell.module';

import { TableModule } from 'primeng/table';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppDialogsModule } from './app-dialogs.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';

import { DialogService} from 'primeng/dynamicdialog';
import { OAuthModule } from 'angular-oauth2-oidc';
import { IsAuthenticatedGuard } from './guards/is-authenticated.guard';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SignalRService } from './common/services/signalr.service';
import { ToastModule } from 'primeng/toast';
import { LipoMessageService } from './common/services/LipoMessageService';

import { TranslateModule } from '@ngx-translate/core';
import { NgSwaggerGenInterceptor } from './interceptors/swaggerngInterceptor';
import { DisableControlDirective } from './common/directives/DisableFormControl';
import { CommonCheckService } from './common/services/common.validation.service';
import { NotificationsModule } from './+notifications/notifications.module';
import { DashboardModule } from './+dashboard/dashboard.module';
import { RouteReuseStrategy } from '@angular/router';
import { LipoRouteReuseStrategy } from './common/services/lipo-reuse-strategy';
import { CommonDataModule } from './common-data-module/common-data-module.module';



registerLocaleData(localeDe);



@NgModule({ declarations: [
        AppComponent,
    ],
    exports: [TranslateModule],
    bootstrap: [AppComponent], imports: [FullCalendarModule,
        CommonDataModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AppStoreModule,
        ShellModule,
        AppDialogsModule,
        FormsModule,
        ToastModule,
        ReactiveFormsModule,
        MatSnackBarModule,
        DashboardModule,
        NotificationsModule,
        TranslateModule.forRoot(),
        OAuthModule.forRoot({
            resourceServer: { sendAccessToken: true }
        })], providers: [
        { provide: LOCALE_ID, useValue: 'de-DE' },
        {
            provide: RouteReuseStrategy,
            useClass: LipoRouteReuseStrategy,
        },
        // { provide: ErrorHandler, useClass: GlobalErrorHandler },
        // { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true},
        DialogService,
        SignalRService,
        ConfirmationService,
        CommonCheckService,
        IsAuthenticatedGuard,
        MessageService,
        LipoMessageService,
        DisableControlDirective,
        { provide: HTTP_INTERCEPTORS, useClass: NgSwaggerGenInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi())
    ] })


export class AppModule {

 }
