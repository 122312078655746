<div class="container-fluid">
    <div class="grid">
        <div class="col-10">&nbsp;</div>
        <div class="col-2"><button pButton type="button" icon="pi pi-check" label="Schliessen" style="float: right"
                (click)="cancel()"></button>
        </div>
    </div>
    <hr>
    <div class="container-fluid">
        <div *ngIf="!hasValidNotifications()">
            <h6 style="text-align: left;">Neue Nachricht an {{messageparamter.correspondUserName}}</h6>
            <div class="grid row">
                <div class="col-2">&nbsp; </div>
                <div class="col">
                    <textarea [id]="'firstinput'" type="text" [(ngModel)]="firstMessage"
                        [rows]="firstMessage && firstMessage.length > 1  ? 2 : 1" pInputTextarea autoResize="autoResize"
                        style="width: 100%; max-width: 100%;">                        </textarea>
                </div>
                <div class="col-2">
                    <button pTooltip="Senden" style="bottom: 8px;" mat-icon-button [disabled]="!firstMessage" (click)="sendFirstMessage(firstMessage)" type="button">
                        <mat-icon>send</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div *ngFor="let item of notifications">
            <div *ngIf="item.messages.length > 0">
                <h6> {{GetSenderName(item)}}</h6>
                <div *ngFor="let message of item.messages">
                    <div class="grid">
                        <div class="col-2">&nbsp; </div>
                        <div class="col ">
                            <div [ngClass]="getBoxClass(message)">
                                <div class="row grid ">
                                    <div class="col">
                                        <mat-icon style="font-size: 1rem; padding-top: 5px;">message</mat-icon>
                                        {{getMessageTitle(message)}}
                                    </div>
                                    <div class="col"> <span style="float: right;"> {{ getSendDate(message)}} </span>
                                    </div>
                                </div>
                                <div class="row grid ">
                                    <div class="col">
                                        <textarea pInputTextarea [ngClass]="getTextClass(message)"
                                            [rows]="getMessageRows(message.data.message)" (click)="autoGrow($event)"
                                            readonly="true" [id]="item.id" type="text"
                                            [(ngModel)]="message.data.message">
                            </textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-2"> &nbsp;</div>
                    </div>
                </div>
                <div class="grid row">
                    <div class="col-2">&nbsp; </div>
                    <div class="col">
                        <textarea *ngIf="newMessages && newMessages.length > 0" [id]="item.id.toString() + 'input'"
                            type="text" [ngModel]="getMessage(item)" (input)="setMessage(item, $event)"
                            (keyup)="autoGrow($event)" pInputTextarea rows="1"
                            style="width: 100%; max-width: 100%;">                        </textarea>
                    </div>
                    <div class="col-2">
                        <button pTooltip="Senden" style="bottom: 8px;" mat-icon-button  [disabled]="!canSend(item)" (click)="send(item)"  type="button">
                            <mat-icon>send</mat-icon>
                        </button>
                    </div>
                </div>
                <hr>
            </div>
        </div>
    </div>
</div>
