import { Component, OnInit } from '@angular/core';
import { AufgabenArt, LieferantTypeEnum, TaskUserModel } from '@app/common/models';
import { ValidationService } from '@app/common/services/validation.service';
import { Store, select } from '@ngrx/store';
import {
  GetValidationsAction,
  ResetStoreAction,
  CreateMasterTaskAction,
  GetCurrentUserAction,
  ClearStoreAction,
  SkipTaskAction,
} from './store';
import {
  selectValidationProfiles,
  selectBreadcrumb,
  selectCurrentUser,
  selectableUsers,
  selectResult,
  isLoading,
  usersLoading,
  taskSaving,
  selectTypeInfos,
} from './store/selectors';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Message } from 'primeng//api';
import { map } from 'rxjs/operators';
import { Actions } from '@ngrx/effects';
import {ConfirmationService} from 'primeng/api';
import { TaskService } from '@app/common/services/task.service';


import { Observable } from 'rxjs';
import { AuthService } from '@app/common/services/auth.service';
import { TaskStructureModel } from '@app/common/models/taskStructure.model';
import { environment } from '@env/environment';
import { UploadFileModel } from '@app/common/models/uploadFile.model';
import { UploadFileDto } from '@app/common/models/uploadFileDto.model';
import { FileDescriptionModel } from '@app/common/models/FileDescription.model';
import * as cloneDeep from 'lodash/fp/cloneDeep';
import * as momentLib from 'moment';
import { GetKlammernAction, GetSortimenteAction, GetSortimentstypenAction, selectallKlammern, selectallSortimente } from '@app/common-data-module/store';
import { KlammerService, SortimentService } from '@app/api/filialmatrix/services';
import { QueryTokenDTO } from '@app/api/commonWebAPI/models';
import { KlammerDto, SortimentDto, TypDto } from '@app/api/filialmatrix/models';
import { SortimentDtoClient } from '@app/common/models/sortimente/SortimentDtoClient';
import { KlammerDtoClient } from '@app/common/models/sortimente/KlammerDtoClient';
import { TypDtoClient } from '@app/common/models/sortimente/SortimentstypenDtoClient';


@Component({
  selector: 'app-task-editor',
  templateUrl: 'task-editor.component.html',
  styleUrls:  ['./task-editor.component.scss'],
})

export class TaskEditorComponent implements OnInit {

  displayDialog = false;
  showSideBar = false;

  taskModel: TaskStructureModel<TaskUserModel>;
  editMode: boolean;
  includeUpload: boolean;
  taskname: { label: string, value: string };
  description: string;

  minListRows = 1;
  maxListRows = 1000;
  sendInvite: boolean = JSON.parse(`${environment.sendInvite}`);
  invitationDate: Date;
  sendReminderOne: boolean = JSON.parse(`${environment.sendInviteOne}`);
  sendReminderTwo: boolean = JSON.parse(`${environment.sendInviteTwo}`);
  sendReminderThree: boolean = JSON.parse(`${environment.sendInviteThree}`);
  remindDateOne: Date;
  remindDateTwo: Date;
  remindDateThree: Date;
  loadSnapshot = false;
  taskInfoTexts = environment.taskTexts;

  taskValidRange: Date[];
  taskActivityRange: Date[];

  msgs: Message[] = [];

  recipients: TaskUserModel[] = [];
  clonedRecipients: { [s: string]: TaskUserModel} = {};

  minimum: number;
  maximum: number;

  selectedMaster: KlammerDtoClient;
  selectedSortiments: SortimentDtoClient[];

  selectedValidation: string;
  service: ValidationService;
  selectedType: TypDtoClient;

  isMasterEditing = false;
  isSortimentEditing = false;
  isActivityEditing = false;


  uploadedFiles: UploadFileModel[] = [];

  klammerIsEdited = false;
  sortimentIsEdited = false;

  showMasterDateEditor: boolean;
  showSortimentDateEditor: boolean;
  showActivityDateEditor: boolean;

  newMasterName: string;
  newSortimentName: string;
  newActivityName: string;

  masterDateRange: Date[];
  sortimentDateRange: Date[];
  activityDateRange: Date[];

  breadcrump$ = this.store.pipe(select(selectBreadcrumb));
  validationProfiles$ = this.store.pipe(select(selectValidationProfiles));

  selectallSortimente$ = this.store.pipe(select(selectallSortimente));

  sortimentsKlammern$ = this.store.pipe(select(selectallKlammern));
  sortiments$: Observable<SortimentDtoClient[]>;

  typeInfos$ = this.store.pipe(select(selectTypeInfos));
  isLoading = false;
  currentUser$ = this.store.pipe(select(selectCurrentUser));
  selectableUsers$ = this.store.pipe(select(selectableUsers));
  lastResult$ = this.store.pipe(select(selectResult));
  isLoading$ = this.store.pipe(select(isLoading));
  isUsersLoading$ = this.store.pipe(select(usersLoading));
  isTaskSaving$ = this.store.pipe(select(taskSaving));

  hideUserEdit = 'hidden';

  userValues$ = this.selectableUsers$.pipe(
    map(c => c.map(item => ({
        id: item.value.id,
        selected: false,
        min: item.value.min,
        max: item.value.max,
        name: item.value.name,
        userId: item.value.userId,
        email: item.value.email,
        includeUpload: item.value.includeUpload
    } as TaskUserModel)
    ))
  );

  userInfo$ = this.authService.claims$.pipe(
    map(claims => claims.id )
  );

  userCols: any[];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public store: Store<any>,
    public actions: Actions,
    private confirmationService: ConfirmationService,
    private taskService: TaskService,
    private authService: AuthService
  ) { }

  ngOnInit() {

    this.showActivityDateEditor = false;
    this.showMasterDateEditor = false;
    this.showSortimentDateEditor = false;

    this.taskModel = this.config.data.taskModel;
    this.editMode = this.config.data.editMode;

    this.loadValidations();
    this.loadCurrentUser();



    this.store.dispatch(new GetSortimentstypenAction({
      params: <SortimentService.SortimentQuerySortimentsTypenParams>{
        eagerLoading: 2, queryToken: <QueryTokenDTO>{
          take: null, skip: null,
          orderBy: [{ by: "name", desc: false }]
        }
      }
    }));


    var pk = <KlammerService.KlammerQueryKlammernParams>{};
    pk.eagerLoading = 2;
    pk.queryToken = <QueryTokenDTO>{
      take: null, skip: null,
      orderBy: [{ by: "sortNumber", desc: false }]
    }
    this.store.dispatch(new GetKlammernAction({ param: pk }));


    var ps = <SortimentService.SortimentQuerySortimenteParams>{}
    ps.eagerLoading = 2;
    ps.queryToken = <QueryTokenDTO>{
      take: null, skip: null,
      orderBy: [{ by: "sortNumber", desc: false }]
    }
    this.store.dispatch(new GetSortimenteAction({ param: ps }));



    if (this.taskModel) {
      // this.taskname = this.taskModel.name;
      // this.minimum = this.taskModel.minimum;
      // this.maximum = this.taskModel.maximum;
    }

    if (!this.editMode) {
      this.loadBreadcrumbs();
    }

    this.userCols = [
      { field: 'value.organisation', header: 'Lieferant'},
      { field: 'value.fullName', header: 'Name' },
      { field: 'value.email', header: 'Mail' }
    ];
  }

  onRowSelect(event) {
    this.displayDialog = true;
  }

  allowVendorEdit(): boolean {
    if ((this.selectedMaster === null || this.selectedMaster === undefined) ||
      (this.taskname === null || this.taskname === undefined || this.taskname.toString().length < 1) ||
      (this.taskValidRange === null || this.taskValidRange === undefined || this.taskActivityRange === null || this.taskActivityRange === undefined)) {
        return false;
    }
    return true;
  }

  loadValidations() {
    this.store.dispatch(new GetValidationsAction());
  }

  loadBreadcrumbs() {
    // this.store.dispatch(new GetBreadcrumbAction({ id: this.taskModel.id }));
  }

  handleMasterEditChanged($event) {
    this.isSortimentEditing = this.isMasterEditing;
    this.isActivityEditing = this.isMasterEditing;
  }

  handleSortimentEditChanged($event) {
    this.isActivityEditing = this.isSortimentEditing;
  }

  loadSortiment() {
    //this.store.dispatch(new GetUsersAction());
    console.log(this.selectedSortiments);
    this.store.dispatch(new ResetStoreAction());
    this.selectedValidation = '';

    this.selectedSortiments = null;

    this.selectedType = null;
    this.sortiments$ = this.selectallSortimente$.pipe(map(x => x.filter(y => y.klammer.id === this.selectedMaster.id)));

  }

  activateTypeFilter() {
    if (this.selectedType == null) {
      this.loadSortiment();
      return;
    }
    this.selectedSortiments = null;
    this.sortiments$ = this.selectallSortimente$.pipe(map(x => x.filter(y => y.klammer.id === this.selectedMaster.id && y.typ && y.typ.id == this.selectedType.id)));
  }


  checkType() {
    if (this.selectedType === null) {
      return;
    }
  }

  getFilesForUser(userId: string): any[] {
    const userUploads = this.uploadedFiles.filter(x => x.userId === userId);
    // if no user specific files exist send header files if exists
    if (userUploads === null || userUploads.length < 1) {
      const headerFiles = this.uploadedFiles.filter(x => x.userId === 'HEADER');
      if (headerFiles === null || headerFiles.length < 1) {
        return null;
      }
      return cloneDeep(headerFiles[0].files);
    }
    return cloneDeep(userUploads[0].files);
  }



  uploadFiles(event) {
    if (event.files.length > 5) {
      alert('Es dürfen maximal 5 Dateien für den Upload angegeben werden');
      return;
    }
    const newFile: UploadFileModel = {
      files: [],
      userId: 'HEADER'
    };
    for (const file of event.files) {
      newFile.files.push(cloneDeep(file));
    }
    this.uploadedFiles = [];
    this.uploadedFiles.push(newFile);
  }

  uploadUserFiles(event, user: TaskUserModel) {
    if (event.files.length > 5) {
      alert('Es dürfen maximal 5 Dateien für den Upload angegeben werden');
      return;
    }

    const userUploads = this.uploadedFiles.filter(x => x.userId === user.userId);
    // delete existing uploads for user
    if (userUploads != null && userUploads.length > 0) {
      for (const upload of userUploads) {
        const idx = this.uploadedFiles.findIndex(x => x === upload);
        if (idx > -1) {
          this.uploadedFiles.splice(idx, 1);
        }
      }
    }

    // Add new Files for user
    const newFile: UploadFileModel = {
      files: [],
      userId: user.userId
    };
    for (const file of event.files) {
      const singleFile = cloneDeep(file);
      newFile.files.push(singleFile);
    }

    this.uploadedFiles.push(newFile);
  }

  loadCurrentUser() {
    this.store.dispatch(new GetCurrentUserAction());
  }

  changeValidRange() {
    // Keine Änderung wenn Bearbeitungszeitraum bereits gefüllt
    if (this.taskValidRange !== undefined && this.taskValidRange !== null && this.taskValidRange.length > 1) {
      return;
    }
    if (this.taskActivityRange === undefined || this.taskActivityRange === null || this.taskActivityRange.length < 2) {
      return;
    }
    let start = momentLib(this.taskActivityRange[0]);
    const startDate = start.clone().subtract(8, 'week').toDate();
    // startDate.setDate(this.taskActivityRange[0].getDate() - 30);
    this.taskValidRange = [];
    this.taskValidRange.push(startDate);
    const endDate = start.clone().subtract(30, 'day').toDate();
    // endDate.setDate(this.taskActivityRange[1].getDate() - 1);
    this.taskValidRange.push(endDate);

    start = momentLib(this.taskValidRange[0]);
    this.invitationDate = start.clone().subtract(environment.inviteDiff, 'day').toDate();
    let end = momentLib(endDate);
    this.remindDateOne = end.clone().subtract(environment.inviteOneDiff, 'day').toDate();
    this.remindDateTwo = start.clone().subtract(environment.inviteTwoDiff, 'day').toDate();
    this.remindDateThree = start.clone().subtract(environment.inviteThreeDiff, 'day').toDate();
  }

  async createTask() {

    if (!this.selectedMaster) {
      alert('Bitte geben Sie mindestens eine Sortimentsklammer an');
      return;
    }
    if (this.includeUpload && !this.selectedValidation) {
      alert('Bitte wählen Sie ein Validierungsprofil aus.');
      return;
    }
    // let usersSelected: {label: string, value: TaskUserModel}[] = [];
    // const userSub = this.selectableUsers$.subscribe(x => usersSelected = (x.filter(y => y.value.selected === true)));
    // userSub.unsubscribe();

    // const tasks: TaskUserModel[] = [];

    // // the last selection is used - if any
    // // tslint:disable-next-line:prefer-for-of
    // for (const userSelected of usersSelected) {
    //   const affectedRow = Object.assign({}, userSelected.value);
    //   if (this.getActualSortiment()) {
    //     affectedRow.taskParentId = this.getActualSortiment();
    //   } else if (this.getActualMaster()) {
    //     affectedRow.taskParentId = this.getActualMaster();
    //   }

    //   // Add Uploads for User if any
    //   const uploadsForUser = this.uploadedFiles.find(x => x.userId === affectedRow.userId);
    //   if (uploadsForUser !== null && uploadsForUser !== undefined) {
    //     affectedRow.uploadFiles = await this.createUploadFile(uploadsForUser, affectedRow.userId);
    //   }
    //   if (this.selectedType && this.selectedType != null) affectedRow.typeInfoId = this.selectedType.id;
    //   tasks.push(affectedRow);
    // }
    // if (tasks.length < 1) {
    //   alert('Bitte wählen Sie mindestens einen Empfänger aus.');
    //   return;
    // }
    const masterTask = this.getMasterTask();
    // Add Master Uploads - if any
    const uploadsForMaster = this.uploadedFiles.find(x => x.userId === 'HEADER');
    if (uploadsForMaster !== null && uploadsForMaster !== undefined) {
      masterTask.uploadFiles = await this.createUploadFile(uploadsForMaster, 'HEADER');
    }
    masterTask.taskState = 100; // Created
    this.store.dispatch(new CreateMasterTaskAction({ item: masterTask }));

  }

  createUploadFile(uploadItem: UploadFileModel, user: string): Promise<UploadFileDto> {
    return new Promise(async (resolve, reject) => {
      const upload: UploadFileDto = {
        parentId: 0,
        userId: user,
        files: []
      };
      for (const uploadFile of uploadItem.files) {
          const fileDesc = await this.getFileFromReader(uploadFile);
          upload.files.push(fileDesc);
      }
      resolve(upload);
    });
  }

  getFileFromReader(uploadFile: any): Promise<FileDescriptionModel> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const fileDesc: FileDescriptionModel = { id: null, fileName: '', fileType: '', fileBase64: '', initialLoad: false};
      reader.readAsDataURL(uploadFile);
      reader.onload = () => {
        fileDesc.fileName = uploadFile.name;
        fileDesc.fileType = uploadFile.type;
        fileDesc.fileBase64 = reader.result.toString().split(',')[1];
        resolve(fileDesc);
      };
    });
  }

  getMasterTask(): TaskUserModel {
    const taskModel = <TaskUserModel>{
      id: 0,
      userId: null,
      name: this.taskname.toString(),
      email: null,
      min: this.minListRows,
      max: this.maxListRows,
      taskInfo: this.description,
      taskDescription: this.taskname.toString(),
      sendInvite: this.sendInvite,
      invitationDate: this.invitationDate,
      sendInviteOne: this.sendReminderOne,
      sendInviteTwo: this.sendReminderTwo,
      sendInviteThree: this.sendReminderThree,
      dateInviteOne: this.remindDateOne,
      dateInviteTwo: this.remindDateTwo,
      dateInviteThree: this.remindDateThree,
      includeUpload: this.includeUpload,
      selectedValidation: null,
      selectedValidationId: Number.parseFloat(this.selectedValidation),
      loadSnapshot: this.loadSnapshot,
      selected: true,
      taskValidRange: this.taskValidRange,
      taskValidRangeFrom: this.taskValidRange[0],
      taskValidRangeTo: this.taskValidRange[1],
      taskActiveRange: this.taskActivityRange,
      taskActiveRangeFrom: this.taskActivityRange[0],
      taskActiveRangeUntil: this.taskActivityRange[1],
      organization: '',
      position: '',
      groupId: 0,
      isReadOnly: 0,
      isReadOnlyMessage: null,
      isEditExpired: 0,
      isEditExpiredMessage:null,
      taskParentId: null,
      creatorFriendlyName: null,
      creatorMail: null,
      creatorToken: null,
      uploadStagingId: null,
      uploadState: 0,
      uploadFiles: { parentId: 0, userId: '', files: [] },
      fileUrls: [],
      firstName: null,
      lastName: null,
      fullName: null,
      gender: null,
      sortimentName: null,
      sortimentIds:[],
      typeInfoId: this.selectedType?.assortmenttypeId,
      typeId: null,
      color: null,
      targetUserId: null,
      klammerId: null,
      klammerName: null,
      taskState: null,
      taskType: 0,
      einkaufId: null,
      aufgabenArt: AufgabenArt.Artikelliste,
      mandantId: null,
      lieferantId: null,
      lieferantType: LieferantTypeEnum.Lieferant
    };

    taskModel.klammerId = this.selectedMaster.assortmentmasterId;

    if (this.selectedSortiments.length > 0) {
      this.selectedSortiments.forEach(x => taskModel.sortimentIds.push(x.id.toString()));
    }

    return taskModel;
  }

  getActualSortiments(): number[] {
    let sortiments: number[] = [];
    if (this.selectedSortiments == null) return null;
    this.selectedSortiments.forEach(x => sortiments.push(x.id));
    return sortiments;
  }

  skip(taskId: number) {
    this.confirmationService.confirm({
      message: 'Wollen Sie wirklich keine Liste zu dieser Anforderung bereitstellen?',
      header: 'Achtung - Überspringen?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
          this.msgs = [{severity: 'info', summary: 'Überspringen', detail: 'Wird bearbeitet... bitte Warten'}];
          this.store.dispatch(new SkipTaskAction({taskId}));
      },
      reject: () => {
          this.msgs = [{severity: 'info', summary: 'Abbruch', detail: 'Aufgabe wird nicht übersprungen.'}];
      }
  });
  }

   onRowEditInit(usr: TaskUserModel) {
    this.clonedRecipients[usr.name] = { ...usr };
  }

  onRowEditSave(usr: TaskUserModel) {
      delete this.clonedRecipients[usr.name];
      console.log(usr);
  }

  onRowEditCancel(usr: TaskUserModel, index: number) {
    this.recipients[index] = this.clonedRecipients[usr.name];
    delete this.clonedRecipients[usr.name];
  }

  close() {
    // if (this.hideUserEdit === 'hidden') { return; }
    this.store.dispatch(new ClearStoreAction());
    this.recipients = [];
    this.ref.close();
  }

  getNewTaskUserModel(): TaskUserModel {
    const result: TaskUserModel = {
      name: '',
    email: '',
    organization: '',
    position: '',
    id: -1,
    groupId: -1,
    userId: '-1',
    isReadOnly: 0,
    isReadOnlyMessage: null,
    isEditExpired: 0,
    isEditExpiredMessage:null,
    min: 0,
    max: 0,
    taskInfo: '',
    taskDescription: '',
    taskValidRange: [],
    taskValidRangeFrom: null,
    taskValidRangeTo: null,
    taskActiveRange: [],
    taskActiveRangeFrom: null,
    taskActiveRangeUntil: null,
    sendInvite: false,
    invitationDate: null,
    sendInviteOne: false,
    sendInviteTwo: false,
    sendInviteThree: false,
    dateInviteOne: null,
    dateInviteThree: null,
    dateInviteTwo: null,
    selectedValidation: '',
    selectedValidationId: null,
    includeUpload: false,
    loadSnapshot: false,
    selected: false,
    taskParentId: null,
    creatorFriendlyName: null,
    creatorMail: null,
    creatorToken: null,
    uploadStagingId: null,
    uploadState: 0,
    uploadFiles: { parentId: 0, userId: '', files: [] },
    fileUrls: [],
    firstName: null,
    lastName: null,
    fullName: null,
    gender: null,
    sortimentName: null,
    sortimentIds: [],
    typeInfoId: null,

    targetUserId: null,
    color: null,
    klammerName: null,
    klammerId: null,
    taskState: 100,
    taskType: 0,
    einkaufId: null,
    aufgabenArt: AufgabenArt.Artikelliste,
    mandantId: "",
    lieferantId: null,
    lieferantType: LieferantTypeEnum.Lieferant
    };

    return result;
  }

}
