
export enum DynListOption {
  nicht_ausgewählt = 0,
  MöbeldesSortimentsundModul = 1,
}

export enum ItemValueListTypeTable {
  LISTCOLUMNS = 1,
  AVAILABLECOLUMNS = 2,
  USEDLISTCOLUMNS = 3
}


export enum ColumnTypes {
  Text = 0,
  Number = 1,
  CheckBox = 2,
  List = 3,
  NumberRange = 4,
  DateRange = 5,
  Date = 6,
  ExternalValidation = 7,
  ///Liste die zur Laufzeit generiert wird
  DynamicList = 8,
  EAN = 9
}

export interface ColumnBase {
  id: number;
  colName: string;
  colDesc: string;
  colType: ColumnTypes;
  dbMapping: string;
  bubMapping: string;
  createdAt: Date;
  changedAt: Date;



  maxLength: number;
  minLength: number;
  listOptions: DynListOption;
  validRegEx: string;
  maxDigits: number;
  maxDecimals: number;
  isNumberOnly: number;
  requiredColumnValue: string;
}



export interface ItemValueModel {
  value: string;
  label: string;
}

export interface ListValue {
  valuelist: { item: string }[];
}


export interface DynListValue {
  label: string;
  value: DynListOption;
}

export interface NumberRangeValue {
  numberrangestart: number;
  numberrangeend: number;
}

export interface DateRangeValue {
  daterangestart: Date;
  daterangeend: Date;
}

export interface DateValue {
  value: Date;
}

export interface BoolValue {
  value: boolean;
}

export interface TextValue {
  maxlength: number;
  minlength: number;
  validregex: string;
}

export interface NumberValue {
  maxdigits: number;
  maxdecimals: number;
  isnumberonly: boolean;
  requiredcolumnvalue: string;
}
export interface EANValue {
  value: string;
}
