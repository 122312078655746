import { Data } from "@angular/router";

/**Parameter zum holen der Daten */
export interface MessageParamter {
  //der die Nachrichten sehen sollen
  // hier der angemeldete User
  targetUserId: string;
  targetUserName: string;
  type: MessageTypeEnum;
  parentId?: number;
  // der User auf der anderen Seite
  correspondUserId: string;
  correspondUserName: string;
}

export interface SignalModel<T> {
  data: T;
  sendCommand: string;
  messageType: number;
  sendDate: Date;
  sourceUserId: string;
  targetUserId: string;
  targetUserName: string;
}


export enum MessageTypeEnum {
  /// <summary>
  /// Unabhängige Nachricht
  /// </summary>
  Unabhängig = 0,
  /// <summary>
  /// Hängt an den Medientipps
  /// parentid != null
  /// </summary>
  Medientipp = 1,
  Artikeldaten = 2,
  /*Sortiment Neu */
  Sortiment = 3,
  /**Neues Modul oder neues Sortiment  */
  ModulSortiment = 4,
  /**an Zuordnung */
  Modulzuordnung = 5,

  // Message an Liste von user/somas
  // Text und Novitaetid
  Aenderungshistorie = 6


}

export interface SendMessageTypeGroupsContainer<T> {
 groupMessages: SendMessageTypeGroupContainer<T>[];
}

/**An Gruppe schicken außer -> exceptUser  */
export interface SendMessageTypeGroupContainer<T> {
  id: number;
  /**groupToken z.b. für admin */
  group: string,
  exceptUser: string[];
  messageTypeData: SendMessageTypeContainer<T>;
}


export interface SendMessageTypeContainer<T> {
  id: number;
  parentId: number;
  messageType: MessageTypeEnum
  creatingUserId: string
  creatingUserName: string;
  targetUserId: string;
  targetUserName: string;
  messages: SendMessageObject<T>[];
}



export interface SendMessageObject<T> {
  id: number;
  messageTypeId: number,
  sourceUserId: string;
  sourceUserName: string;
  targetUserId: string;
  targetUserName: string;
  sendDate: any;
  message: string;
  data: T
  messageStatus: MessageStatusEnum;

}


/// <summary>
/// Status der Message
/// </summary>
export enum MessageStatusEnum {
  New = 1,
  /// <summary>
  /// Message wurde gelesen
  /// </summary>
  Read = 2,
  Deleted = 3
}


export interface SignalMessageContentModel {

  message: string;
}




export class SimpleMessage <T>
{

        sendDate : Data;
        parentId : number;
        messageType : MessageTypeEnum;
        creatingUserId : string;
        targetUserIds : string[]
        message : T;

    }

    export class MessageContent   {
      messages: { [key: string]: string } = {};
  }

