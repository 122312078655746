import { AttributeMainModel } from '@app/common/models';
import { CalendarSearchModel } from '@app/common/models/calendarSearch.model';
import { CalendarDataModel } from '@app/common/models/CalendarData.model';
import { FilterStructureModel } from '@app/common/models/filterStructure.model';
import {  SotimentundKlammerInfoDto as SortimentundKlammerInfoDto } from '@app/common/models/attribute-minimal.model';
import { TaskStructureModel } from '@app/common/models/taskStructure.model';




export enum CalendarActionType {
  DisplayAllAttributes = '[CALENDAR] DisplayAllAttributes',
  GetAllAttributes = '[CALENDAR] GetAllAttributes',
  GetEventsByModel = '[CALENDAR] GetEventsByModel',
  GetEventsByModelDone = '[CALENDAR] GetEventsByModelDone',
  GetStructureModel = '[CALENDAR] GetStructureModel',
  GetStructureModelDone = '[CALENDAR] GetStructureModelDone',

  LoadSingleTask = '[CALENDAR] LoadSingleTask',
  LoadSingleTaskDone = '[CALENDAR] LoadSingleTaskDone',
  GetDistinctUsersSortimente = "GetDistinctUsersSortimente",
  GetDistinctUsersSortimenteDone = "GetDistinctUsersSortimenteDone"
}






export class DisplayAllAttributesAction {
    readonly type = CalendarActionType.DisplayAllAttributes;
    constructor( public payload: { items: AttributeMainModel[]}) {}
}


export class GetEventsByModelAction {
    readonly type = CalendarActionType.GetEventsByModel;
    constructor( public payload: { queryModel: CalendarSearchModel }) {}
}

export class GetEventsByModelDoneAction {
    readonly type = CalendarActionType.GetEventsByModelDone;
    constructor( public payload: { dataModel: CalendarDataModel[] }) {}
}

export class GetStructureModelAction {
    readonly type = CalendarActionType.GetStructureModel;
}

export class GetStructureModelDoneAction {
    readonly type = CalendarActionType.GetStructureModelDone;
    constructor( public payload: { structureModel: FilterStructureModel[] }) {}
}

export class GetDistinctUsersSortimenteAction {
  readonly type = CalendarActionType.GetDistinctUsersSortimente;
  constructor( public payload: { queryModel: CalendarSearchModel }) {}
}

export class GetDistinctUsersSortimenteActionDone {
  readonly type = CalendarActionType.GetDistinctUsersSortimenteDone;
  constructor( public payload: { array: SortimentundKlammerInfoDto[] }) {}
}



export type CalendarActions = DisplayAllAttributesAction |  GetEventsByModelAction | GetEventsByModelDoneAction |
GetStructureModelAction | GetStructureModelDoneAction

| GetDistinctUsersSortimenteActionDone | GetDistinctUsersSortimenteAction;

