export interface ResultObjectModel<T> {
    data: T;
    generalResult: number;
    userMessage: string;
    serverMessage: string;
    stackTrace: string;
}

export enum GeneralResultEnum {
  Success = 0,
  DatabaseError = 1,
  GeneralError = 2,
  LogicWarning = 3,
  Created = 4,
  Updated = 4,
  Deleted = 5,
  //Nur clientseitig
  OnlyDebug = 6,
  //SSO
  AuthError = 7,
  //Http Error -> HttpErrorResponse
  HttpError = 8
}
