import { UploadRowModel, RowContentModel, ResultObjectModel, ValidationResult, UploadStagingModel, FieldModel, InsertUploadModel, UploadStaging, TaskModel, ValidationList, TaskUserModel, LieferantTypeEnum, ValidationListColumn, AufgabenArt } from '@app/common/models';
import { SimpleResult } from '@app/common/models/simpleResult.model';
import { RowUpdateResultModel } from '@app/common/models/rowUpdateResult.model';
import { RowInsertResultModel } from '@app/common/models/uploadRowSave.model';
import { TaskStructureModel } from '@app/common/models/taskStructure.model';
import { UploadLieferantParam } from '@app/common-data-module/models/LieferantUserDTO';
import { FinishUploadParam, UpdateUploadParamClient, UpdateUploadParamServer } from '../models/updateparam';


export enum ListEditorActionType {
  GetListWithId = '[LIST-EDIT] GetListForId',
  GetRowListDone = '[LIST-EDIT] GetRowListDone',
  AddRow = '[LIST-EDIT] AddRow',
  DeleteRow = '[LIST-EDIT] DeleteRow',
  SaveRow = '[LIST-EDIT] SaveRow',
  ValidateRow = '[LIST-EDIT] ValidateRow',
  ValidateRowDone = '[LIST-EDIT] ValidateRowDone',
  ValidateRows = '[LIST-EDIT] ValidateRows',
  ValidateRowsDone = '[LIST-EDIT] ValidateRowsDone',

  SaveUploadListItem = '[LIST-EDIT] SaveUploadListItem',
  SaveUploadListItemDone = '[LIST-EDIT] SaveUploadListItemDone',
  AddEmptyContentRow = '[LIST_EDIT] AddEmptyContentRow',
  UpdateRowStore = '[LIST-EDIT] UpdateRowStore',
  InsertUploadListItem = '[LIST-EDIT] InsertUploadListItem',
  InsertRowDone = '[LIST_EDIT] InsertRowDone',
  GetUploadFields = '[LIST-EDIT] GetUploadFields',
  GetUploadFieldsDone = '[LIST-EDIT] GetUploadFieldsDone',
  CloseEditor = '[LIST-EDIT] CloseEditor',
  DeleteRows = '[LIST-EDIT] DeleteRows',
  DeleteUploadRows = '[LIST-EDIT] DeleteUploadRows',
  FinishUpload = '[LIST-EDIT] FinishUpload',
  FinishUPloadDone = '[LIST-EDIT] FinishUploadDone',
  FinishUploadBySupplier = '[LIST-EDIT] FinishUploadBySupplier',
  FinishUploadBySupplierDone = '[LIST-EDIT] FinishUploadBySupplierDone',
  BubImportSelectedRows = '[LIST-EDIT] BubImportSelectedRows',
  BubImportSelectedRowsDone = '[LIST-EDIT] BubImportSelectedRowsDone',
  BubImportAllRows = '[LIST-EDIT] BubImportAllRows',
  BubImportAllRowsDone = '[LIST-EDIT] BubImportAllRowsDone',
  LoadSortimentsklammern = '[LIST-EDIT] LoadSortimentsklammern',
  LoadSortimentsklammernDone = '[LIST-EDIT] LoadSortimentsklammernDone',
  FinishUploadByManager = '[LIST-EDIT] FinishUploadByManager',
  FinishUploadByManagerDone = '[LIST-EDIT] FinishUploadByManagerDone',
  CreateUploadAction = "CreateUploadAction",
  CreateUploadActionDone = "CreateUploadActionDone",
  GetValidations = "GetValidations",
  GetValidationsDone = "GetValidationsDone",
  GetUsedValidationListById = "GetUsedValidationListById",
  GetUsedValidationListByIdDone = "GetUsedValidationListByIdDone",
  ClearUploadErrorAction = "ClearUploadErrorAction",
  UpdateUploadMultiRowsAction = "UpdateUploadMultiRowsAction",
  UpdateUploadMultiRowsDoneAction = "UpdateUploadMultiRowsDoneAction"
}


export class ClearUploadErrorAction {
  readonly type = ListEditorActionType.ClearUploadErrorAction;
  constructor() { }

}


export class CreateUploadAction {
  readonly type = ListEditorActionType.CreateUploadAction;
  constructor(public payload: { uploadstaging: UploadStaging, task: TaskStructureModel<TaskUserModel> }) { }

}



export class CreateUploadActionDone {
  readonly type = ListEditorActionType.CreateUploadActionDone;
  constructor(public payload: { upload: ResultObjectModel<UploadStagingModel>, task: TaskStructureModel<TaskUserModel> }) { }
}



export class InsertRowDoneAction {
    readonly type = ListEditorActionType.InsertRowDone;
    constructor(public payload: { result: RowInsertResultModel }) { }
}

export class SaveUploadListItemAction {
    readonly type = ListEditorActionType.SaveUploadListItem;
    constructor(public payload: { id: number, cols: {}, isValid: boolean, validationInfo: string, selectedRows: number[] }) { }
}

export class SaveUploadListItemDoneAction {
    readonly type = ListEditorActionType.SaveUploadListItemDone;
    constructor(public payload: { result: RowUpdateResultModel, selectedRows: number[] }) { }
}


export class UpdateUploadMultiRowsAction {
  readonly type = ListEditorActionType.UpdateUploadMultiRowsAction;
  constructor(public payload: {
    param: UpdateUploadParamClient[], selectedRows:  number[]}
  ) { }
}

export class UpdateUploadMultiRowsDoneAction {
  readonly type = ListEditorActionType.UpdateUploadMultiRowsDoneAction;
  constructor(public payload: { result: RowUpdateResultModel[], selectedRows: number[] }) { }
}


export class InsertUploadListItemAction {
    readonly type = ListEditorActionType.InsertUploadListItem;
    constructor(public payload: { uploadId: number, oldId: number, validationName: string, cols: { }, selectedRows: number[] }) { }
}

//obsolet
export class AddEmptyContentRowAction {
    readonly type = ListEditorActionType.AddEmptyContentRow;
    constructor(public payload : { currentValues: any[] }) { }
}

export class UpdateRowStoreAction {
    readonly type = ListEditorActionType.UpdateRowStore;
    constructor(public payload: {id: number, cols: {}, isValid: boolean, validationInfo: string, selectedRows: number[] }) { }
}

export class GetListWithIdAction {
    readonly type = ListEditorActionType.GetListWithId;
    constructor(public payload: { id: number, lieferantenparams: UploadLieferantParam}) { }
}

export class GetRowListDoneAction {
    readonly type = ListEditorActionType.GetRowListDone;
    constructor(public payload: { result: ResultObjectModel<UploadStagingModel> }) {
    }
}

export class BubImportSelectedRowsAction {
    readonly type = ListEditorActionType.BubImportSelectedRows;
    constructor(public payload: { uploadId: number, eanList: string[] }) {}
}

export class BubImportSelectedRowsDoneAction {
    readonly type = ListEditorActionType.BubImportSelectedRowsDone;
    constructor(public payload: { uploadId: number, stagingDetails: UploadRowModel[] }) {}
}

export class BubImportAllRowsAction {
    readonly type = ListEditorActionType.BubImportAllRows;
    constructor(public payload: { uploadId: number }) {}
}

export class BubImportAllRowsDoneAction {
    readonly type = ListEditorActionType.BubImportAllRowsDone;
    constructor() {}
}

export class AddRowAction {
    readonly type = ListEditorActionType.AddRow;
    constructor() { }
}

export class DeleteRowAction {
    readonly type = ListEditorActionType.DeleteRow;
    constructor(public payload: { id: number }) { }
}

export class SaveRowAction {
    readonly type = ListEditorActionType.SaveRow;
    constructor(public payload: { rowData: UploadRowModel }) { }
}

export class ValidateRowAction {
    readonly type = ListEditorActionType.ValidateRow;
    constructor(public payload: { uploadId: number, stagingRowId: number, validationName: string, rowContent: {} }) { }
}



export class ValidateRowsDoneAction {
    readonly type = ListEditorActionType.ValidateRowsDone;
    constructor(public payload: { rowContent: RowContentModel[] }) { }
}

export class GetUploadFieldsAction {
    readonly type = ListEditorActionType.GetUploadFields;
    constructor() {}
}

export class GetUploadFieldsDoneAction {
    readonly type = ListEditorActionType.GetUploadFieldsDone;
    constructor(public payload: { fieldModels: FieldModel[] }) { }
}

export class CloseEditorAction {
    readonly type = ListEditorActionType.CloseEditor;
    constructor() { }
}

export class DeleteRowsAction {
    readonly type = ListEditorActionType.DeleteRows;
    constructor(public payload: { ids: number[] }) {}
}

export class DeleteUploadRowsAction {
    readonly type = ListEditorActionType.DeleteUploadRows;
    constructor(public payload: { ids: number[] }) {}
}

export class FinishUploadAction {
    readonly type = ListEditorActionType.FinishUpload;
    constructor(public payload: { uploadId: number }) {}
}

export class FinishUploadDoneAction {
    readonly type = ListEditorActionType.FinishUPloadDone;
    constructor (public payload: { finishResult: SimpleResult }) {}
}

export class FinishUploadByManagerAction {
    readonly type = ListEditorActionType.FinishUploadByManager;
    constructor(public payload: { taskId: number, params: FinishUploadParam}) {}
}

export class FinishUploadBySupplierAction {
    readonly type = ListEditorActionType.FinishUploadBySupplier;
    constructor(public payload: { uploadId: number }) {}
}

export class FinishUploadByManagerDoneAction {
    readonly type = ListEditorActionType.FinishUploadByManagerDone;
    constructor (public payload: { finishResult: SimpleResult }) {}
  }

export class FinishUploadBySupplierDoneAction {
    readonly type = ListEditorActionType.FinishUploadBySupplierDone;
    constructor (public payload: { finishResult: SimpleResult }) {}
}


export class GetValidationsAction {
  readonly type = ListEditorActionType.GetValidations;
  constructor() {}
}

export class GetValidationsDoneAction {
  readonly type = ListEditorActionType.GetValidationsDone;
  constructor(public payload: { validations: ValidationList[] }) {}
}


export class GetUsedValidationListById {
  readonly type = ListEditorActionType.GetUsedValidationListById;
  constructor(public payload: { id: number }) {}
}

export class GetUsedValidationListByIdDone   {
  readonly type = ListEditorActionType.GetUsedValidationListByIdDone;
  constructor(public payload: { id: number, validations: ValidationListColumn }) {}
}






export type ListEditorActions = GetListWithIdAction | DeleteRowAction | AddRowAction | SaveRowAction | ValidateRowAction |
GetRowListDoneAction | SaveUploadListItemAction | AddEmptyContentRowAction | UpdateRowStoreAction | InsertUploadListItemAction |
InsertRowDoneAction  | ValidateRowsDoneAction | SaveUploadListItemDoneAction |
GetUploadFieldsAction | GetUploadFieldsDoneAction | CloseEditorAction | DeleteRowsAction | DeleteUploadRowsAction | FinishUploadAction |
FinishUploadDoneAction | BubImportAllRowsAction | BubImportAllRowsDoneAction | BubImportSelectedRowsAction | BubImportSelectedRowsDoneAction |
 CreateUploadAction | CreateUploadActionDone | GetValidationsAction | GetValidationsDoneAction|
 GetUsedValidationListById | GetUsedValidationListByIdDone | ClearUploadErrorAction
 | UpdateUploadMultiRowsAction | UpdateUploadMultiRowsDoneAction |
 FinishUploadByManagerAction | FinishUploadByManagerDoneAction;
