import { TaskModel, TaskUserModel, ValidationList } from '@app/common/models';
import { AppState as RootState } from '../../store';
import { createFeatureSelector } from '@ngrx/store';
import { SimpleResult, SimpleResultError } from '@app/common/models/simpleResult.model';

export interface UserTasksState
{
    tasks: TaskUserModel[];
    taskHits: number,
    currenterror: SimpleResultError;
}

export interface AppState extends RootState {
    usertasks: UserTasksState;
}

export const InitialUserTasksState: UserTasksState = {
    tasks: [],
    taskHits: 0,
    currenterror: undefined
};

export const featureName = 'usertasks';

export const selectUserTasksState = createFeatureSelector<UserTasksState>(featureName);
