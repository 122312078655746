<div class="container-fluid">
  <div class="row">
    <h3 style="margin-left: 10px;">{{ headerTask.name }}</h3>
  </div>
  <div class="row">
    <h6 style="margin-left: 10px;">{{ breadcrumb$ | async }}</h6>
  </div>
  <div class="grid">
    <div class="col-1" style="font-weight: bold;">Sortiment</div>
    <div class="col-3">{{ headerTask.sortimentName }}</div>
    @if(listenArt())
    {
    <div  class="col-1" style="font-weight: bold;">Listenart</div>
    <div class="col">{{ listenArt() }}</div>
    }

  </div>
  <div class="grid">
    <div class="col-1" style="font-weight: bold; ">Bearbeitung</div>
    <div class="col-1">{{ headerTask.taskValidRangeFrom | date: 'dd.MM.yyyy' : 'de' }}</div>
    <div class="col-2">{{ headerTask.taskValidRangeTo | date: 'dd.MM.yyyy' : 'de' }}</div>
    <div class="col-1" style="font-weight: bold;">Laufzeit</div>
    <div class="col-1">{{ headerTask.taskActiveRangeFrom | date: 'dd.MM.yyyy' : 'de' }}</div>
    <div class="col-2">{{ headerTask.taskActiveRangeUntil | date: 'dd.MM.yyyy' : 'de' }}</div>
  </div>
  <div class="row">
    <div class="grid">
      <div class="col-10">
        <ngx-charts-pie-grid [view]="view" [scheme]="colorScheme" [results]="getViewResults()" [label]="label"
          (select)="onSelect($event)">

        </ngx-charts-pie-grid>
      </div>
      <!-- <div class="col-2">{{ getChildrenCount() }}</div> -->
    </div>
  </div>


  <p-table [value]="childrenTasks" [paginator]="true" [rows]="4" [lazy]="false">
    <ng-template pTemplate="header">
      <tr>
        <th>Name</th>
        <th>Sortiment</th>
        <th>Bearbeitung</th>
        <th>Laufzeit</th>
        <th>{{getCustomName()}}</th>
        <th>Status</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td>{{ rowData.name }}</td>
        <td>{{ rowData.sortimentName }}</td>
        <td>{{ rowData.taskValidRangeFrom | date: 'dd.MM.yyyy' : 'de' }} - {{ rowData.taskValidRangeTo | date:
          'dd.MM.yyyy' : 'de' }}</td>
        <td>{{ rowData.taskActiveRangeFrom | date: 'dd.MM.yyyy' : 'de' }} - {{ rowData.taskActiveRangeUntil | date:
          'dd.MM.yyyy' : 'de' }}</td>
        <td>{{ rowData.organization }} </td>
        <td>{{ getTaskStatus(rowData) }}</td>
      </tr>
    </ng-template>
  </p-table>

</div>
