/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfKeyValueDto } from '../models/list-response-args-of-key-value-dto';
import { ListResponseArgsOfSortimentPlatzierungDto } from '../models/list-response-args-of-sortiment-platzierung-dto';
import { QueryTokenDTO } from '../models/query-token-dto';
import { ResponseArgsOfSortimentPlatzierungDto } from '../models/response-args-of-sortiment-platzierung-dto';
import { SortimentPlatzierungDto } from '../models/sortiment-platzierung-dto';
import { ResponseArgsOfBoolean } from '../models/response-args-of-boolean';
@Injectable({
  providedIn: 'root',
})
class SortimentPlatzierungService extends __BaseService {
  static readonly SortimentPlatzierungGetKategorienPath = '/sortiment/platz/kategorie';
  static readonly SortimentPlatzierungQuerySortimentePlatzierungenPath = '/sortiment/platz/s';
  static readonly SortimentPlatzierungExportXlsxPath = '/sortiment/platz/xlsx';
  static readonly SortimentPlatzierungCreateSortimentPlatzierungPath = '/sortiment/{sortimentId}/platz';
  static readonly SortimentPlatzierungDeleteSortimentPlatzierungPath = '/sortiment/{sortimentId}/platz';
  static readonly SortimentPlatzierungUpdateSortimentPlatzierungPath = '/sortiment/{sortimentId}/platz';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Liste der Katorien mit Vorabplatzierungen
   * @param sortimentId undefined
   */
  SortimentPlatzierungGetKategorienResponse(sortimentId?: null | Array<number>): __Observable<__StrictHttpResponse<ListResponseArgsOfKeyValueDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (sortimentId || []).forEach(val => {if (val != null) __params = __params.append('sortimentId', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sortiment/platz/kategorie`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfKeyValueDto>;
      })
    );
  }
  /**
   * Liste der Katorien mit Vorabplatzierungen
   * @param sortimentId undefined
   */
  SortimentPlatzierungGetKategorien(sortimentId?: null | Array<number>): __Observable<ListResponseArgsOfKeyValueDto> {
    return this.SortimentPlatzierungGetKategorienResponse(sortimentId).pipe(
      __map(_r => _r.body as ListResponseArgsOfKeyValueDto)
    );
  }

  /**
   * Vorabplatzierung Novitätenpool
   * @param params The `SortimentPlatzierungService.SortimentPlatzierungQuerySortimentePlatzierungenParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `fixePlatzierung`: False: Alle per Sortimentszuordnung verknüpfte Sortimente sind einzeln in der Ergebnisliste enthalten.
   *   True: Je Sortimentszordnung nur ein Eintrag in der Ergebnisliste.
   *
   * - `eagerLoading`:
   */
  SortimentPlatzierungQuerySortimentePlatzierungenResponse(params: SortimentPlatzierungService.SortimentPlatzierungQuerySortimentePlatzierungenParams): __Observable<__StrictHttpResponse<ListResponseArgsOfSortimentPlatzierungDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.fixePlatzierung != null) __params = __params.set('fixePlatzierung', params.fixePlatzierung.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sortiment/platz/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfSortimentPlatzierungDto>;
      })
    );
  }
  /**
   * Vorabplatzierung Novitätenpool
   * @param params The `SortimentPlatzierungService.SortimentPlatzierungQuerySortimentePlatzierungenParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `fixePlatzierung`: False: Alle per Sortimentszuordnung verknüpfte Sortimente sind einzeln in der Ergebnisliste enthalten.
   *   True: Je Sortimentszordnung nur ein Eintrag in der Ergebnisliste.
   *
   * - `eagerLoading`:
   */
  SortimentPlatzierungQuerySortimentePlatzierungen(params: SortimentPlatzierungService.SortimentPlatzierungQuerySortimentePlatzierungenParams): __Observable<ListResponseArgsOfSortimentPlatzierungDto> {
    return this.SortimentPlatzierungQuerySortimentePlatzierungenResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfSortimentPlatzierungDto)
    );
  }

  /**
   * Export Vorabplatzierung Novitätenpool
   * @param params The `SortimentPlatzierungService.SortimentPlatzierungExportXlsxParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `fixePlatzierung`:
   */
  SortimentPlatzierungExportXlsxResponse(params: SortimentPlatzierungService.SortimentPlatzierungExportXlsxParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.fixePlatzierung != null) __params = __params.set('fixePlatzierung', params.fixePlatzierung.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sortiment/platz/xlsx`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Export Vorabplatzierung Novitätenpool
   * @param params The `SortimentPlatzierungService.SortimentPlatzierungExportXlsxParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `fixePlatzierung`:
   */
  SortimentPlatzierungExportXlsx(params: SortimentPlatzierungService.SortimentPlatzierungExportXlsxParams): __Observable<Blob> {
    return this.SortimentPlatzierungExportXlsxResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Create Parameter
   * @param params The `SortimentPlatzierungService.SortimentPlatzierungCreateSortimentPlatzierungParams` containing the following parameters:
   *
   * - `sortimentId`: Sortiment Id
   *
   * - `payload`:
   *
   * - `user`: User Id oder Token
   */
  SortimentPlatzierungCreateSortimentPlatzierungResponse(params: SortimentPlatzierungService.SortimentPlatzierungCreateSortimentPlatzierungParams): __Observable<__StrictHttpResponse<ResponseArgsOfSortimentPlatzierungDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sortiment/${encodeURIComponent(String(params.sortimentId))}/platz`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfSortimentPlatzierungDto>;
      })
    );
  }
  /**
   * Create Parameter
   * @param params The `SortimentPlatzierungService.SortimentPlatzierungCreateSortimentPlatzierungParams` containing the following parameters:
   *
   * - `sortimentId`: Sortiment Id
   *
   * - `payload`:
   *
   * - `user`: User Id oder Token
   */
  SortimentPlatzierungCreateSortimentPlatzierung(params: SortimentPlatzierungService.SortimentPlatzierungCreateSortimentPlatzierungParams): __Observable<ResponseArgsOfSortimentPlatzierungDto> {
    return this.SortimentPlatzierungCreateSortimentPlatzierungResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfSortimentPlatzierungDto)
    );
  }

  /**
   * Delete Parameter
   * @param params The `SortimentPlatzierungService.SortimentPlatzierungDeleteSortimentPlatzierungParams` containing the following parameters:
   *
   * - `sortimentId`: Sortiment Id
   *
   * - `user`: User Id oder Token
   */
  SortimentPlatzierungDeleteSortimentPlatzierungResponse(params: SortimentPlatzierungService.SortimentPlatzierungDeleteSortimentPlatzierungParams): __Observable<__StrictHttpResponse<ResponseArgsOfBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/sortiment/${encodeURIComponent(String(params.sortimentId))}/platz`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBoolean>;
      })
    );
  }
  /**
   * Delete Parameter
   * @param params The `SortimentPlatzierungService.SortimentPlatzierungDeleteSortimentPlatzierungParams` containing the following parameters:
   *
   * - `sortimentId`: Sortiment Id
   *
   * - `user`: User Id oder Token
   */
  SortimentPlatzierungDeleteSortimentPlatzierung(params: SortimentPlatzierungService.SortimentPlatzierungDeleteSortimentPlatzierungParams): __Observable<ResponseArgsOfBoolean> {
    return this.SortimentPlatzierungDeleteSortimentPlatzierungResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfBoolean)
    );
  }

  /**
   * Update Sortimente
   * @param params The `SortimentPlatzierungService.SortimentPlatzierungUpdateSortimentPlatzierungParams` containing the following parameters:
   *
   * - `sortimentId`:
   *
   * - `payload`:
   *
   * - `user`:
   */
  SortimentPlatzierungUpdateSortimentPlatzierungResponse(params: SortimentPlatzierungService.SortimentPlatzierungUpdateSortimentPlatzierungParams): __Observable<__StrictHttpResponse<ResponseArgsOfSortimentPlatzierungDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/sortiment/${encodeURIComponent(String(params.sortimentId))}/platz`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfSortimentPlatzierungDto>;
      })
    );
  }
  /**
   * Update Sortimente
   * @param params The `SortimentPlatzierungService.SortimentPlatzierungUpdateSortimentPlatzierungParams` containing the following parameters:
   *
   * - `sortimentId`:
   *
   * - `payload`:
   *
   * - `user`:
   */
  SortimentPlatzierungUpdateSortimentPlatzierung(params: SortimentPlatzierungService.SortimentPlatzierungUpdateSortimentPlatzierungParams): __Observable<ResponseArgsOfSortimentPlatzierungDto> {
    return this.SortimentPlatzierungUpdateSortimentPlatzierungResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfSortimentPlatzierungDto)
    );
  }
}

module SortimentPlatzierungService {

  /**
   * Parameters for SortimentPlatzierungQuerySortimentePlatzierungen
   */
  export interface SortimentPlatzierungQuerySortimentePlatzierungenParams {
    queryToken: QueryTokenDTO;

    /**
     * False: Alle per Sortimentszuordnung verknüpfte Sortimente sind einzeln in der Ergebnisliste enthalten.
     * True: Je Sortimentszordnung nur ein Eintrag in der Ergebnisliste.
     */
    fixePlatzierung?: boolean;
    eagerLoading?: number;
  }

  /**
   * Parameters for SortimentPlatzierungExportXlsx
   */
  export interface SortimentPlatzierungExportXlsxParams {
    queryToken: QueryTokenDTO;
    fixePlatzierung?: boolean;
  }

  /**
   * Parameters for SortimentPlatzierungCreateSortimentPlatzierung
   */
  export interface SortimentPlatzierungCreateSortimentPlatzierungParams {

    /**
     * Sortiment Id
     */
    sortimentId: number;
    payload: SortimentPlatzierungDto;

    /**
     * User Id oder Token
     */
    user?: null | string;
  }

  /**
   * Parameters for SortimentPlatzierungDeleteSortimentPlatzierung
   */
  export interface SortimentPlatzierungDeleteSortimentPlatzierungParams {

    /**
     * Sortiment Id
     */
    sortimentId: number;

    /**
     * User Id oder Token
     */
    user?: null | string;
  }

  /**
   * Parameters for SortimentPlatzierungUpdateSortimentPlatzierung
   */
  export interface SortimentPlatzierungUpdateSortimentPlatzierungParams {
    sortimentId: number;
    payload: SortimentPlatzierungDto;
    user?: null | string;
  }
}

export { SortimentPlatzierungService }
