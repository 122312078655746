import { Injectable, signal, Signal } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { GetMoebelAction, selectMoebel } from "../store";
import { SortimentService } from "@app/api/filialmatrix/services";
import { toSignal } from "@angular/core/rxjs-interop";
import { MoebelDto } from "@app/api/filialmatrix/models";
import { map } from "rxjs";

@Injectable()
export class MoebelService {

  moebelbySortiment: Signal<{ [key: number]: MoebelDto[] }> = signal({});
  constructor(private store: Store<any>)
  {
   this.moebelbySortiment =  toSignal(this.store.pipe(select(selectMoebel)));
  }

  loadMoebel(sortimentid: number)
  {
    this.store.dispatch(new GetMoebelAction({
      param: <SortimentService.SortimentGetMoebelParams>
        {
          eagerLoading: 2, id: sortimentid, moduleNo: null
        }
    }));

  }








}
