// Ab Angualr 14 ->  https://juristr.com/blog/2019/08/dynamically-load-css-angular-cli/

import { EventEmitter, Inject, Injectable } from "@angular/core";

//https://www.primefaces.org/designer-vue/#/
//https://www.reddit.com/r/angular/comments/lp1d4v/styling_primeng_components/
export interface ISchemaOption {
  name: string;
  label: string;
}

export interface IFontSizeOption extends ISchemaOption {
}
/**Lädt Styles und/oder Fontsize dynamisch und fügt sie zum DOM dazu
 * siehe auch styles.scss und Pfad assets/styles/*.js
 // https://www.primefaces.org/designer-vue/#/
  */
@Injectable({ providedIn: 'root', })
export class SwitchThemeService {

  onClassesChange = new EventEmitter<string>();
  storagekeytheme = "lipotheme";
  storagekeyfontsize = "lipofontsize";
  selectedTheme: ISchemaOption = <ISchemaOption>{ name: 'light', label: 'Hell' }; // primeng

  insertedStyleElement: HTMLElement;
  insertedFontsizeElement: HTMLElement;

  possibleStyles: ISchemaOption[] = [
    <ISchemaOption>{ name: "light", label: "Hell" },
    <ISchemaOption>{ name: "dark", label: "Dunkel" }
  ];

  selectedFontSize: IFontSizeOption = <IFontSizeOption>{ label: 'Groß', name: '09375rem' }; // default fontsize
  possibleFontSizes: IFontSizeOption[] = [
    <IFontSizeOption>{ name: "1rem", label: "Sehr groß" },
    <IFontSizeOption>{ name: "09375rem", label: "Groß" },
    <IFontSizeOption>{ name: "0875rem", label: "Mittel" },
    <IFontSizeOption>{ name: "08125rem", label: "Klein" },
  ];

  constructor() {
  }

  loadStyle( name: string,  styleName: string) {
    const head = document.getElementsByTagName('head')[0];
    let themeLink = document.getElementById(     name    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = `../../../assets/styles/${styleName}.css` ;
    } else {
      const style = document.createElement('link');
      style.id = name;
      style.rel = 'stylesheet';
      style.href = `../../../assets/styles/${styleName}.css` ;

      head.appendChild(style);
    }
  }

  /**Material CSS */
  getMaterialClass(): string {
    if (this.selectedTheme?.name == 'dark') {
      return "darkMode";
    }
    return "lightMode";
  }



  /**laden von light scss oder dark.scss
   * primeng
  */
  getThemeClass(): string {
    var classscss = "size_" + (this.selectedFontSize ?  this.selectedFontSize.name : "1rem");
    var theme = "_" + ( this.selectedTheme ?  this.selectedTheme.name : "light");
    return classscss+theme;
  }

  /**Lade nder initlaen Font und themes */
  load() {
    this.selectedFontSize = this.loadStorageFontsize();
    this.selectedTheme = this.loadStorageTheme();
    this.loadTheme(this.selectedTheme);
    this.loadFontSize(this.selectedFontSize);
  }

  loadStorageTheme(): ISchemaOption {
    try {
      var stored = localStorage.getItem(this.storagekeytheme);
      if (!stored) return this.selectedTheme;
      return JSON.parse(stored) as ISchemaOption;
    } catch (e) {
      localStorage.removeItem(this.storagekeytheme);
      console.debug(e);
      return this.selectedTheme;
    }
  }

  saveStorageTheme(scheme: ISchemaOption): void {
    localStorage.setItem(this.storagekeytheme, JSON.stringify(scheme));
  }

  loadStorageFontsize(): IFontSizeOption {
    try {
      var stored = localStorage.getItem(this.storagekeyfontsize);
      if (!stored) return this.selectedFontSize;
      return JSON.parse(stored) as IFontSizeOption;
    } catch (e) {
      localStorage.removeItem(this.storagekeytheme);
      console.debug(e);
      return this.selectedFontSize;
    }
  }


  saveStorageFontsize(size: IFontSizeOption): void {
    localStorage.setItem(this.storagekeyfontsize, JSON.stringify(size));
  }


  loadFontSize(fontsize: IFontSizeOption) {
    this.selectedFontSize = fontsize;
    this.saveStorageFontsize(fontsize);
    this.onClassesChange.emit(this.getThemeClass() + '  ' + this.getMaterialClass() );

    this.loadStyle('fonttheme', fontsize.name);

  }

  loadTheme(theme: ISchemaOption) {
    this.selectedTheme = theme;
    this.saveStorageTheme(theme);
    this.onClassesChange.emit(this.getThemeClass() + '  ' + this.getMaterialClass() );

    this.loadStyle('darklighttheme', theme.name);


  }


}
