<div class="container ">
    <div class="row">
        <div class="grid p-justify-between">
            <div class="h4 col-8">Upload-Listen</div>
            <div class="col-2">
                    <button pButton type="button" icon="pi pi-window-maximize" (click)='openInTab()' pTooltip="In eigenem Tab öffnen"></button>
                </div>
                <div class="col-2">
                    <button pButton type="button" icon="pi pi-refresh" (click)='load()' pTooltip="Listen neu Laden"></button>
                </div>
        </div>
        <div class="row"  style="margin-left: 4px; margin-right: 0rem;">
            <p-table [value]="lists$ | async" [paginator]="true" [rows]="5" [scrollable]="true" [loading]="isLoading$ | async">
                <ng-template pTemplate="header">
                    <th>Aktion</th>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Validierung</th>
                    <th>Bearbeitet</th>
                    <th>Status</th>
                </ng-template>
                <ng-template pTemplate="body" let-ListModel>
                    <tr>
                        <td>

                                <button pButton icon="pi pi-pencil" (click)='openListEditor(ListModel.id, ListModel.description, ListModel.name, ListModel.lastEdited)'></button>
                                <button pButton *ngIf="!ListModel.isValid" icon="pi pi-pencil" (click)='showValidationDetails(ListModel.id)'></button>
                        </td>
                        <td>{{ ListModel.id }}</td>
                        <td>{{ ListModel.description }}</td>
                        <td>{{ ListModel.validationMessage }}</td>
                        <td>{{ ListModel.lastEdited | date: 'dd.MM.yyyy hh:mm' }}</td>
                        <td *ngIf="ListModel.uploadState === 0">Neu</td>
                        <td *ngIf="ListModel.uploadState === 5">In Arbeit</td>
                        <td *ngIf="ListModel.uploadState === 10">Abgeschlossen</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
