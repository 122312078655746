/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfRegionDto } from '../models/list-response-args-of-region-dto';
import { ExtendedQueryTokenDTO } from '../models/extended-query-token-dto';
import { ResponseArgsOfRegionDto } from '../models/response-args-of-region-dto';
import { RegionDto } from '../models/region-dto';
import { ResponseArgsOfBoolean } from '../models/response-args-of-boolean';
@Injectable({
  providedIn: 'root',
})
class RegionService extends __BaseService {
  static readonly RegionQueryRegionenPath = '/region/s';
  static readonly RegionCreateRegionPath = '/region';
  static readonly RegionUpdateRegionPath = '/region/{id}';
  static readonly RegionDeleteRegionPath = '/region/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Liste der Regionen
   * @param params The `RegionService.RegionQueryRegionenParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `eagerLoading`:
   */
  RegionQueryRegionenResponse(params: RegionService.RegionQueryRegionenParams): __Observable<__StrictHttpResponse<ListResponseArgsOfRegionDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/region/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfRegionDto>;
      })
    );
  }
  /**
   * Liste der Regionen
   * @param params The `RegionService.RegionQueryRegionenParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `eagerLoading`:
   */
  RegionQueryRegionen(params: RegionService.RegionQueryRegionenParams): __Observable<ListResponseArgsOfRegionDto> {
    return this.RegionQueryRegionenResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfRegionDto)
    );
  }

  /**
   * Create
   * @param params The `RegionService.RegionCreateRegionParams` containing the following parameters:
   *
   * - `payload`: RegionDto
   *
   * - `user`: User Id oder Token
   */
  RegionCreateRegionResponse(params: RegionService.RegionCreateRegionParams): __Observable<__StrictHttpResponse<ResponseArgsOfRegionDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/region`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfRegionDto>;
      })
    );
  }
  /**
   * Create
   * @param params The `RegionService.RegionCreateRegionParams` containing the following parameters:
   *
   * - `payload`: RegionDto
   *
   * - `user`: User Id oder Token
   */
  RegionCreateRegion(params: RegionService.RegionCreateRegionParams): __Observable<ResponseArgsOfRegionDto> {
    return this.RegionCreateRegionResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfRegionDto)
    );
  }

  /**
   * Update
   * @param params The `RegionService.RegionUpdateRegionParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `id`:
   *
   * - `user`:
   */
  RegionUpdateRegionResponse(params: RegionService.RegionUpdateRegionParams): __Observable<__StrictHttpResponse<ResponseArgsOfRegionDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/region/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfRegionDto>;
      })
    );
  }
  /**
   * Update
   * @param params The `RegionService.RegionUpdateRegionParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `id`:
   *
   * - `user`:
   */
  RegionUpdateRegion(params: RegionService.RegionUpdateRegionParams): __Observable<ResponseArgsOfRegionDto> {
    return this.RegionUpdateRegionResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfRegionDto)
    );
  }

  /**
   * Delete
   * @param params The `RegionService.RegionDeleteRegionParams` containing the following parameters:
   *
   * - `id`: Saison Id
   *
   * - `user`: User Id oder Token
   */
  RegionDeleteRegionResponse(params: RegionService.RegionDeleteRegionParams): __Observable<__StrictHttpResponse<ResponseArgsOfBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/region/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBoolean>;
      })
    );
  }
  /**
   * Delete
   * @param params The `RegionService.RegionDeleteRegionParams` containing the following parameters:
   *
   * - `id`: Saison Id
   *
   * - `user`: User Id oder Token
   */
  RegionDeleteRegion(params: RegionService.RegionDeleteRegionParams): __Observable<ResponseArgsOfBoolean> {
    return this.RegionDeleteRegionResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfBoolean)
    );
  }
}

module RegionService {

  /**
   * Parameters for RegionQueryRegionen
   */
  export interface RegionQueryRegionenParams {
    queryToken: ExtendedQueryTokenDTO;
    eagerLoading?: number;
  }

  /**
   * Parameters for RegionCreateRegion
   */
  export interface RegionCreateRegionParams {

    /**
     * RegionDto
     */
    payload: RegionDto;

    /**
     * User Id oder Token
     */
    user?: null | string;
  }

  /**
   * Parameters for RegionUpdateRegion
   */
  export interface RegionUpdateRegionParams {
    payload: RegionDto;
    id: number;
    user?: null | string;
  }

  /**
   * Parameters for RegionDeleteRegion
   */
  export interface RegionDeleteRegionParams {

    /**
     * Saison Id
     */
    id: number;

    /**
     * User Id oder Token
     */
    user?: null | string;
  }
}

export { RegionService }
