<div class="container">
  <div class="p-row grid" *ngFor="let message of notification.messages">
    <div class="col-10" > {{ message.data.message }} </div>
    <div class="col-2" >{{ message.sendDate | date }} </div>
  </div>
</div>
<div class="container">
<div class="p-row grid " >
  <div class="col-12"> Von: {{ notification.creatingUserName }} </div>
</div>
</div>

