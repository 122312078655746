import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ResultObjectModel, TaskUserModel, FileDescriptionModel, ActionStates, AufgabenArt, TaskLieferantParams } from '../models';
import { Observable, of, EMPTY } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { map, catchError } from 'rxjs/operators';
import { TaskStructureModel } from '../models/taskStructure.model';
import { NotificationService } from './notification.service';
import { CmfUserDataModel } from '../models/cmfUserData.model';
import { AuthService } from './auth.service';
import { MandantUserDTO, MandantenUserParam } from '../models/mandant-user-dto';
import { LieferantUserDTO } from '@app/common-data-module/models/LieferantUserDTO';

export class TaskQueryParameter
{
  skip: number;
  take: number;
  categories: ActionStates[];
  aufgabenArt: AufgabenArt;
  orderOption: string;
  descending: boolean;
  onlyUser: boolean;
}



@Injectable({ providedIn: 'root' })
export class TaskService {





  constructor(private httpClient: HttpClient, private authServcie: AuthService, private notifier: NotificationService) { }

  getTaskUsers(taskId: number)  : Observable<ResultObjectModel<string[]>> {
    const url = `${environment.connections.TaskListService}/api/task/getTaskUsers`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authServcie.getAccessToken()
      }),
      params: { headerid: taskId.toString() }
    };
    return this.httpClient.get<ResultObjectModel<string[]>>(url, httpOptions);
  }

  getAllTasks(params: TaskQueryParameter): Observable<TaskStructureModel<TaskUserModel>[]> {
    const url = `${environment.connections.TaskListService}/api/task/GetAllTasks`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authServcie.getAccessToken()
      })
    };
    let result: TaskStructureModel<TaskUserModel>[];
    const httpResult = this.httpClient.post<ResultObjectModel<TaskStructureModel<TaskUserModel>[]>>(url, params, httpOptions).pipe(
      map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Fehler beim Laden der Aufgaben: ' + x.userMessage);
          return result;
        }
        result = x.data;
        return result;
      }
    ), catchError(x => {
      this.notifier.showError('Fehler beim Laden der Aufgaben: ' + x.message);
      return of([]);
    }));
    return httpResult;
  }

  getAllUserTasks(param: TaskQueryParameter): Observable<ResultObjectModel<TaskUserModel[]>> {
    const url = `${environment.connections.TaskListService}/api/task/GetAllUserTasks`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authServcie.getAccessToken()
      })
    };

    return  this.httpClient.post<ResultObjectModel<TaskUserModel[]>>(url, param, httpOptions);

  }


  getTaskbyId(taskid: number): Observable<TaskStructureModel<TaskUserModel>> {
    const url = `${environment.connections.TaskListService}/api/task/GetTaskbyId?TaskId=` + taskid.toString();
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authServcie.getAccessToken()
      })
    };
    let result: TaskStructureModel<TaskUserModel>;
    const httpResult = this.httpClient.get<ResultObjectModel<TaskStructureModel<TaskUserModel>>>(url, httpOptions).pipe(
      map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Fehler beim Laden einer Aufgabe ' + x.userMessage);
          return result;
        }
        result = x.data;
        return result;
      }
    ), catchError(x => {
      this.notifier.showError('Fehler beim Laden einer -Aufgabe: ' + x.message);
      return of({});
    }));
    return httpResult;
  }


  getSingleTask(attributeId: number): Observable<TaskStructureModel<TaskUserModel>> {
    const url = `${environment.connections.TaskListService}/api/task/GetAllTasks`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authServcie.getAccessToken()
      }),
      params: { masterTaskId: attributeId.toString()}
    };
    let result: TaskStructureModel<TaskUserModel>;
    const httpResult = this.httpClient.get<ResultObjectModel<TaskStructureModel<TaskUserModel>[]>>(url, httpOptions).pipe(
      map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Fehler beim Laden der Aufgaben: ' + x.userMessage);
          return null;
        }
        result = x.data[0];
        return result;
      }
    ), catchError(x => {
      this.notifier.showError('Fehler beim Laden der Aufgaben: ' + x.message);
      return null;
    }));
    return httpResult;
  }

  //not used for now
  getSupplierTasks(): Observable<TaskUserModel[]> {
    const url = `${environment.connections.TaskListService}/api/task/GetSupplierTasks`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.httpClient.get<TaskUserModel[]>(url, httpOptions);
  }



  getBreadcrumb(id: number): Observable<string> {
    const url = `${environment.connections.TaskListService}/api/task/GetBreadcrumb`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      }),
      params: { attributeId: id.toString() }
    };
    let result: string;
    const httpResult = this.httpClient.get<ResultObjectModel<string>>(url, httpOptions)
      .pipe(map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Fehler beim Laden des Breadcrumbs: ' + x.userMessage);
          return result;
        }
        result = x.data;
        return result;
      }),
      catchError(err => {
        this.notifier.showError('Fehler beim Laden für Aktivität: ' + err);
        return EMPTY;
      }));
    return httpResult;
  }





  getSelectableUsers(): Observable<TaskUserModel[]> {
    const url = `${environment.connections.TaskListService}/api/task/GetTaskUsers`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.httpClient.get<ResultObjectModel<TaskUserModel[]>>(url, httpOptions)
      .pipe(map(x =>{
        if (x.generalResult != 0) {
          throw new Error('ServiceError: Fehler beim Laden der Lieferantendaten: ' + x.userMessage);
        }
        return x.data;
      }));
  }

  getMandanten(headerTaskId: number ): Observable<ResultObjectModel<MandantUserDTO[]>> {
    const url = `${environment.connections.TaskListService}/api/task/GetMandanten`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      }),
      params: {taskid: headerTaskId.toString()}
    };
    let result: ResultObjectModel<MandantUserDTO[]>;
    const httpResult = this.httpClient.get<ResultObjectModel<MandantUserDTO[]>>(url, httpOptions).pipe(
      map(x => {
        if (x.generalResult != 0) {
          throw new Error('ServiceError: Fehler beim Laden der Mandanten: ' + x.userMessage);
        }
        result = x;
        return result;
      }));
      return httpResult;
  }


  getLieferantenUser(headerTaskId: number, categoryManager : boolean ): Observable<ResultObjectModel<LieferantUserDTO[]>> {
    const url = `${environment.connections.TaskListService}/api/task/GetLieferantenUser`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      }),
      params: {taskid: headerTaskId.toString(), categorymanager: categoryManager.toString()}
    };
    let result: ResultObjectModel<LieferantUserDTO[]>;
    const httpResult = this.httpClient.get<ResultObjectModel<LieferantUserDTO[]>>(url, httpOptions).pipe(
      map(x => {
        if (x.generalResult != 0) {
          throw new Error('ServiceError: Fehler beim Laden der Lieferanten / Benutzer : ' + x.userMessage);
        }
        result = x;
        return result;
      }));
      return httpResult;
  }



  skipTask(taskId: number): Observable<ResultObjectModel<any>> {
    const url = `${environment.connections.TaskListService}/api/task/SkipTask`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      }),
      params: { taskId: String(taskId) }
    };

    return this.httpClient.get<ResultObjectModel<any>>(url, httpOptions)
      .pipe(map(x => {
        if (x.generalResult != 0) {
          throw new Error('ServiceError: Fehler bei skipTask: ' + x.userMessage);
        }
        return x;
      }));
  }

  loadFile(id: number): Observable<FileDescriptionModel> {
    const url = `${environment.connections.TaskListService}/api/task/GetFileDownload`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      }),
      params: { id: String(id) }
    };
    return this.httpClient.get<FileDescriptionModel>(url, httpOptions);
  }

  progressTask(taskId: number): Observable<ResultObjectModel<any>> {
    const url = `${environment.connections.TaskListService}/api/task/ProgressTask`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      }),
      params: { taskId: String(taskId) }
    };

    return this.httpClient.get<ResultObjectModel<any>>(url, httpOptions);
  }


  createMasterTask(item: TaskUserModel): Observable<ResultObjectModel<any>> {
    const url = `${environment.connections.TaskListService}/api/task/CreateMasterTask`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      })    };
    let result: ResultObjectModel<TaskUserModel>;
    return this.httpClient.post<ResultObjectModel<TaskUserModel>>(url, item, httpOptions)
      .pipe(map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Aufgabe konnte nicht gespeichert werden: ' + x.userMessage);
          return result;
        }
        return x;
      }),
      catchError((err) => {
        this.notifier.showError('Fehler beim Speichern der Aufgaben: ' + err.message);
        return of(result);
      }));

  }

  setSingleTaskStateAction(taskId: number, taskState: number): Observable<number> {
    const url = `${environment.connections.TaskListService}/api/task/setSingleTaskState`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      }),
      params: {taskId: taskId.toString(), taskState: taskState.toString()}
    };
    let result: ResultObjectModel<number>;
    return this.httpClient.post<ResultObjectModel<number>>(url, null, httpOptions)
      .pipe(map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Aufgabenstatus konnte nicht gespeichert werden: ' + x.userMessage);
          return -1;
        }
        return x.data;
      }));
  }

  setTaskState(taskId: number, taskState: number): Observable<number> {
    const url = `${environment.connections.TaskListService}/api/task/SetTaskState`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      }),
      params: {taskId: taskId.toString(), taskState: taskState.toString()}
    };
    let result: ResultObjectModel<number>;
    return this.httpClient.post<ResultObjectModel<number>>(url, null, httpOptions)
      .pipe(map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Aufgabenstatus konnte nicht gespeichert werden: ' + x.userMessage);
          return -1;
        }
        return x.data;
      }));
  }


  createMandantenTasks(item: TaskUserModel, mandanten: MandantUserDTO[], parent: string): Observable<{tasks: TaskUserModel[], parentId: string}> {
    const url = `${environment.connections.TaskListService}/api/task/CreateMandantenTasks`;
    let httpParams = new HttpParams();
    var p = <MandantenUserParam> { task: item, mandanten: mandanten };
    httpParams.append('parentId', parent.toString());
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      }),
      params: {parentId: parent}
    };
    let result: ResultObjectModel<TaskUserModel[]>;
    return this.httpClient.post<ResultObjectModel<TaskUserModel[]>>(url, p , httpOptions)
      .pipe(map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Mandanten-Aufgaben konnten nicht gespeichert werden: ' + x.userMessage);
          return {tasks: result.data, parentId: parent};
        }
        return {tasks: x.data, parentId: parent};
      }),
      catchError((err) => {
        this.notifier.showError('Fehler beim Speichern der Mandanten-Aufgaben: ' + err.message);
        return of({tasks: result.data, parentId: parent});
      }));

  }

  createSupplierTasks(params: TaskLieferantParams): Observable<{tasks: TaskUserModel[]}> {
    const url = `${environment.connections.TaskListService}/api/task/CreateSupplierTasks`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      })
    };
    let result: ResultObjectModel<TaskUserModel[]>;
    return this.httpClient.post<ResultObjectModel<TaskUserModel[]>>(url, params, httpOptions)
      .pipe(map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Lieferanten-Aufgaben konnten nicht gespeichert werden: ' + x.userMessage);
          return {tasks: result.data, parentId: parent};
        }
        return {tasks: x.data, parentId: parent};
      }),
      catchError((err) => {
        this.notifier.showError('Fehler beim Speichern der Lieferanten-Aufgaben: ' + err.message);
        return of({tasks: result.data, parentId: parent});
      }));

  }

  updateTask(item: TaskUserModel, updatechildren: boolean): Observable<ResultObjectModel<TaskUserModel[]>> {
    const url = `${environment.connections.TaskListService}/api/task/UpdateTask`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      }), params: { updatechildren: updatechildren.toString()}
    };
    let result: ResultObjectModel<TaskUserModel[]>;
    return this.httpClient.post<ResultObjectModel<TaskUserModel[]>>(url, item, httpOptions)
      .pipe(map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Aufgabe konnte nicht aktualisiert werden: ' + x.userMessage);
          return result;
        }
        return x;
      }),
      catchError((err) => {
        this.notifier.showError('Fehler beim Aktualisieren der Aufgabe: ' + err.message);
        return EMPTY;
      }));
  }

  deleteUpload(id: number): Observable<ResultObjectModel<number>> {
    const url = `${environment.connections.TaskListService}/api/task/DeleteUploadReference`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      }),
      params: { refId: id.toString()}
    };
    let result: ResultObjectModel<number>;
    return this.httpClient.get<ResultObjectModel<number>>(url, httpOptions)
      .pipe(map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Fehler beim Löschen einer Datei: ' + x.userMessage);
          return result;
        }
        return x;
      }),
      catchError((err) => {
        this.notifier.showError('Fehler beim Löschen einer Datei: ' + err.message);
        return EMPTY;
      }));
  }

  duplicateMasterTask(id: number): Observable<ResultObjectModel<TaskStructureModel<TaskUserModel>>> {
    const url = `${environment.connections.TaskListService}/api/task/DuplicateMasterTask`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      }),
      params: { masterTaskId: id.toString()}
    };
    let result: ResultObjectModel<TaskStructureModel<TaskUserModel>>;
    return this.httpClient.post<ResultObjectModel<TaskStructureModel<TaskUserModel>>>(url, null, httpOptions)
      .pipe(map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ShowError: Fehler beim Duplizieren einer Master-Aufgabe: ' + x.userMessage);
          return result;
        }
        return x;
      }),
      catchError((err) => {
        this.notifier.showError('Fehler beim Duplizieren einer Master-Aufgabe: ' + err.message);
        return EMPTY;
      }))
  }

  duplicateSupplierTask(id: number): Observable<ResultObjectModel<TaskUserModel>> {
    const url = `${environment.connections.TaskListService}/api/task/DuplicateSupplierTask`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      }),
      params: { taskId: id.toString()}
    };
    let result: ResultObjectModel<TaskUserModel>;
    return this.httpClient.post<ResultObjectModel<TaskUserModel>>(url, null, httpOptions)
      .pipe(map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ShowError: Fehler beim Duplizieren einer Lieferanten-Aufgabe: ' + x.userMessage);
          return result;
        }
        return x;
      }),
      catchError((err) => {
        this.notifier.showError('Fehler beim Duplizieren einer Lieferanten-Aufgabe: ' + err.message);
        return EMPTY;
      }))
  }

  deleteTask(id: number): Observable<ResultObjectModel<number>> {
    const url = `${environment.connections.TaskListService}/api/task/DeleteSingleTask`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      }),
      params: { taskId: id.toString()}
    };
    let result: ResultObjectModel<number>;
    return this.httpClient.get<ResultObjectModel<number>>(url, httpOptions)
      .pipe(map(x => {
        if (x.generalResult == 1) //Database Error
        {
          this.notifier.showError('ServiceError: Fehler beim Löschen einer Aufgabe: ' + x.userMessage);
          return result;
        }
        return x;
      }),
      catchError((err) => {
        this.notifier.showError('Fehler beim Löschen einer Aufgabe: ' + err.message);
        return EMPTY;
      }))
  }
}
