import { UserState, InitialUserState } from './state';
import { UserAction, UserActionType, GetUserDetailsDoneAction, SetUserLoggedInDataAction } from './actions';

export function reduceUser(state: UserState = InitialUserState , action: UserAction): UserState
{
    switch (action.type)
    {

        case UserActionType.GetAuthUserActionDone:
            console.debug("Get User " +   action.payload.user.id );
            return {
                ...state,
               authuser: action.payload.user
            };
            case UserActionType.GetAuthUserErrorAction:
            return {
                ...state,
               error: action.payload.error
            };
        case UserActionType.GetUserDetailsDone:
          return getUserState(state, action);
        case UserActionType.SetUserLoggedInData:
            return setLoggedInUserData(state, action);
    }
    return state;
}

export function getUserState(state: UserState, action: GetUserDetailsDoneAction) {
    console.log('Reducer for PortalUser');

    return {
        ...state,
        name: action.payload.user.name,
        id: action.payload.user.id,
        userId: action.payload.user.userId,
        groupId: action.payload.user.groupId
    };
}

export function setLoggedInUserData(state: UserState, action: SetUserLoggedInDataAction) {
    return {
        ...state,
        actualUser: action.payload
    };
}
