import { createSelector } from '@ngrx/store';
import { selectTaskListState } from './state';
import * as momentLib from 'moment';
import { TaskStructureModel } from '@app/common/models/taskStructure.model';


export const selectCount = createSelector(selectTaskListState, x => x.total);

export const selectPageSize = createSelector(selectTaskListState, x => x.pagesize);

export const selectPage = createSelector(selectTaskListState, x => x.page);

export const selectTasks =  createSelector(selectTaskListState, s => s.tasks);


export const selectLoading = createSelector(selectTaskListState, s => s.loading);


export const selectLastlisttaskparmeter= createSelector(selectTaskListState, s => s.lastlisttaskparmeter);





export const selectAllMandanten = createSelector(selectTaskListState, s => s.mandanten);

export const selectLieferantenUser = createSelector(selectTaskListState, s => s.lieferantenuser);



export const selectValidationProfiles = createSelector(selectTaskListState, s => s.validationProfiles);


export const selectSuppliersofTask = createSelector(selectTaskListState, s => s.taskSupplierIds);
export const selectSuppliersofTaskLoading = createSelector(selectTaskListState, s => s.loadingSupplierIds);








export const selectSuppliergroups = createSelector(selectTaskListState, x => x.suppliergroups);

export const selectSuppliersForGroup = createSelector(selectTaskListState, x => x.suppliersForGroup);

export const selectSingleTaskbyId = createSelector(selectTaskListState, x => x.singleTask);

export const selectTaskerror = createSelector(selectTaskListState, x => x.taskerror);

