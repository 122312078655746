// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  connections: {
    AttributeReader: 'none',
    ListOverviewService: 'none',
    TaskListService: 'none',
    UploadStagingService: 'none',
    CalendarService: 'none',
    SignalService: 'none',
    SettingService: 'none',
    MediatipService: 'none',
    UploadService: 'none',
    ValidationEditService: 'none',
    CommonWebApi: 'none',
    DocumentService: 'none',
    AdvWebapi: 'none',
    KatalogWebapi: 'none',
    StuecklistenWebapi: 'none',
    JWWSService: 'none'
  },
  user: {
    id: 1,
    userId: 7,
    name: 'Peter Maier'
  },

  managers: [ '10001E', '100021' ],
  suppliers: [ '100050' ],
  admins: [ '10007S' ],
  contacts: [ '10007T' ],
  mandant: [ "100051" ],
  moebelEditorUrl: "",

  protoTyping: false,
  sendInvite: true,
  inviteDiff: 2,
  sendInviteOne: true,
  sendInviteTwo: false,
  sendInviteThree: false,
  inviteOneDiff: 2,
  inviteTwoDiff: 5,
  inviteThreeDiff: 1,
  taskTexts: [
    { label: 'Aktualisierung Basissortiment', value: 'Aktualisierung Basissortiment' },
    { label: 'Zentraler Novitäteneinkauf', value: 'Zentraler Novitäteneinkauf' },
    { label: 'Themenliste', value: 'Themenliste' },
    { label: 'Aktion', value: 'Aktion' },
    { label: 'Aktualisierung HBV', value: 'Aktualisierung HBV'} ]

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
