import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TaskModel, ResultObjectModel, UploadStagingModel, UploadUserModel, TaskUserModel } from '../models';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { CompareTitle, CompareTitleFis } from '../models/novi/compareTitel.model';
import { debounceTime, delay, map } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';

@Injectable({ providedIn: 'root' })
export class UploadStagingService {

    constructor(private httpClient: HttpClient) { }

    create(options: { attributeId: number; task: TaskUserModel }): Observable<ResultObjectModel<number>> {
        const url = `${environment.connections.UploadStagingService}/InsertUploadToAttribute`;
        const httpOptions = {
        headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*'
          }),
          params: { attributeId: String(options.attributeId) }};

        const uploadUser: UploadUserModel = {
            id: options.task.id,
            groupId: options.task.groupId,
            isReadOnly: 0,
            userId: options.task.userId
        };

        const newUpload: UploadStagingModel = {
            id: 0,
            changeDate: new Date(),
            createDate: new Date(),
            uploadComment: options.task.name,
            stagingDetails: [],
            uploadingUser: uploadUser,
            uploadState: 0,
            maximum: 0,
            minimum: 0,
            attributeText: options.task.taskDescription || '',
            attributeValidation: '',
            usedValidationListName: '',
            isEditExpired:0,
            isEditExpiredMessage:null,
            isReadOnly: 0,
            isReadOnlyMessage: ''
        };

        return this.httpClient.post<ResultObjectModel<any>>(url, newUpload, httpOptions);
    }


    getCompareDataForEansNovi(eans: string[]): Observable<ResultObjectModel<CompareTitleFis[]>> {
      const url=`${environment.connections.UploadStagingService}/GetCompareListDataNovi`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*'
        })    };
      return this.httpClient.post<ResultObjectModel<CompareTitleFis[]>>(url, eans, httpOptions);
  }


}
