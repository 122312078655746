

import { IMediaTip, ISortOption } from '@app/common/models/mediatips.models';
import { SignalMessageContentModel, SendMessageTypeContainer, MessageTypeEnum } from '@app/common/models/signal.model';
import { IBubData, BubDataResult } from '@app/common/models/bubdata.model';
import { LiPoContact } from '@app/common/models/lipocontact.model';




export enum MediatipActionType {
  GetMediatipAction = "[MEDIATIP] GetMediatipAction",
  GetMediatipActionDone = "[MEDIATIP] GetMediatipActionDone",
  GetMediatipsAction = "[MEDIATIP] GetMediatipsAction",
  GetMediatipsActionDone = "[MEDIATIP] GetMediatipsActionDone",
  MediatipErrorAction = "[MEDIATIP] MediatipErrorAction",
  UpdateMediatipAction = " [MEDIATIP] UpdateMediatipsAction",
  UpdateMediatipActionDone = "[MEDIATIP] UpdateMediatipsActionDone",
  DeleteMediatipAction = "[MEDIATIP] DeleteMediatipsAction",
  DeleteMediatipActionDone = "[MEDIATIP] DeleteMediatipsActionDone",
  MediatipRemovefromStoreAction = "[MEDIATIP] MediatipRemovefromStoreAction",
  InsertMediatipAction = "[MEDIATIP] InsertMediatipAction",
  InsertMediatipActionDone = "[MEDIATIP] InsertMediatipActionDone",
  RemoveMediaTipDoneObjectfromStoreAction = "[Mediatipp] RemoveMediaTipDoneObjectfromStoreAction",
  GetMediatipBubDataAction = "[Mediatipp] GetMediatipBubDataAction",
  GetMediatipBubDataActionDone = "[Mediatipp] GetMediatipBubDataActionDone",
  GetSoMaContactsAction = "[Mediatipp] GetSoMaContactsAction",
  GetSoMaContactsActionDone = "[Mediatipp] GetSoMaContactsActionDone",
  GetBubDataByEansAction = "GetBubDataByEansAction",
  GetBubDataByEansActionDone = "GetBubDataByEansActionDone",
  MediatipClearErrorAction = "MediatipClearErrorAction"
}


export class GetSoMaContactsAction {
    readonly type =  MediatipActionType.GetSoMaContactsAction ;
    constructor( public payload: {  }) {}
}


export class GetSoMaContactsActionDone {
    readonly type =  MediatipActionType.GetSoMaContactsActionDone ;
    constructor( public payload: { somaContacts: LiPoContact[]  }) {}
}





export class MediatipRemovefromStoreAction {
    readonly type =  MediatipActionType.MediatipRemovefromStoreAction ;
    constructor( public payload: {  mediatip : IMediaTip, isManager: boolean}) {}
}


export class RemoveMediaTipDoneObjectfromStoreAction {
    readonly type =  MediatipActionType.RemoveMediaTipDoneObjectfromStoreAction ;
    constructor( public payload: { }) {}
}

export class MediatipErrorAction {
    readonly type =  MediatipActionType.MediatipErrorAction ;
    constructor( public payload: { error : any}) {}
}


export class MediatipClearErrorAction {
  readonly type =  MediatipActionType.MediatipClearErrorAction ;
  constructor() {}
}


export class GetMediatipsAction {
    readonly type =  MediatipActionType.GetMediatipsAction ;
    constructor( public payload: { skip: number, take: number, userid: string, allmediatips : boolean, sortoption: ISortOption, fromDate : Date}) {}
}


export class GetMediatipsActionDone {
    readonly type =  MediatipActionType.GetMediatipsActionDone ;
    constructor( public payload: { mediatips : IMediaTip[], isManager: boolean}) {}
}



export class DeleteMediatipActionDone {
    readonly type =  MediatipActionType.DeleteMediatipActionDone ;
    constructor( public payload: { mediatipid : number, isManager: boolean}) {}
}


export class DeleteMediatipAction {
    readonly type =  MediatipActionType.DeleteMediatipAction ;
    constructor( public payload: { mediatipid : number}) {}
}


export class UpdateMediatipAction {
    readonly type =  MediatipActionType.UpdateMediatipAction ;
    constructor( public payload: { mediatip : IMediaTip, removefromStore: boolean}) {}
}


export class UpdateMediatipActionDone {
    readonly type =  MediatipActionType.UpdateMediatipActionDone ;
    constructor( public payload: { mediatip : IMediaTip, isManager: boolean, removefromStore: boolean}) {}
}


export class InsertMediatipAction {
    readonly type =  MediatipActionType.InsertMediatipAction ;
    constructor( public payload: { newmediatip : IMediaTip, isManager: boolean,}) {}
}


export class InsertMediatipActionDone {
    readonly type =  MediatipActionType.InsertMediatipActionDone ;
    constructor( public payload: { newmediatip : IMediaTip, isManager: boolean}) {}
}


export class GetMediatipAction {
    readonly type =  MediatipActionType.GetMediatipAction ;
    constructor( public payload: {  id : number}) {}
}


export class GetMediatipActionDone {
    readonly type =  MediatipActionType.GetMediatipActionDone ;
    constructor( public payload: { mediatip : IMediaTip}) {}
}


export class GetMediatipBubDataAction {
    readonly type =  MediatipActionType.GetMediatipBubDataAction ;
    constructor( public payload: { ean: string}) {}
}


export class GetMediatipBubDataActionDone {
    readonly type =  MediatipActionType.GetMediatipBubDataActionDone ;
    constructor( public payload: { bubData : BubDataResult }) {}
}


export class GetBubDataByEansAction {
  readonly type =  MediatipActionType.GetBubDataByEansAction ;
  constructor( public payload: { eans: string[]}) {}
}


export class GetBubDataByEansActionDone {
  readonly type =  MediatipActionType.GetBubDataByEansActionDone ;
  constructor( public payload: { bubDataList : IBubData[] }) {}
}


export type MediaTipAction = MediatipErrorAction  | UpdateMediatipAction  | UpdateMediatipActionDone | InsertMediatipAction | InsertMediatipActionDone
| DeleteMediatipActionDone |  DeleteMediatipAction | GetMediatipsAction | MediatipRemovefromStoreAction
| GetMediatipAction | GetMediatipActionDone | GetMediatipsAction | GetMediatipsActionDone   | RemoveMediaTipDoneObjectfromStoreAction
 | GetMediatipBubDataAction |GetMediatipBubDataActionDone
 | GetSoMaContactsAction | GetSoMaContactsActionDone
 | GetBubDataByEansAction |GetBubDataByEansActionDone
 |  MediatipClearErrorAction
 ;
