import { Component, Inject, OnInit, signal, WritableSignal } from '@angular/core';
import { AvailableColumn } from '@app/common/models';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ColumnTypes, NumberRangeValue, NumberValue, TextValue, DateRangeValue, BoolValue, ListValue, DateValue, DynListValue, DynListOption, ItemValueModel, ItemValueListTypeTable, EANValue } from '@app/common/models/columnBase.model';
import { select, Store } from '@ngrx/store';
import { filter, Observable, of, take } from 'rxjs';
import { ISetting, ISettingChildrenValue, ISettingValue, SettingValueEnum, SettingValueTypeEnum } from '@app/common/models/setting.models';
import { CommonCheckService } from '@app/common/services/common.validation.service';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { GetSettingsChildrenAction, GetSettingsStateAction, selectSettingChildrenValues, selectSettingValues } from '@app/common-data-module/store';
import { BranchselektorDialogComponent } from '@app/+settings/lipo-settings/controls/branchgroup-control/dialogs/branchselektor.dialog/branchselektor.dialog.component';
import { GetStaticListValuesAction, selectStaticUsedlistValues, UpdateStaticListValuesAction } from '../store';


@Component({
  selector: 'app-editavailablecolumn-view',
  templateUrl: 'editAvailableColumn.dialog.component.html',
  styleUrls: ['./dialogs.component.scss'],
})

export class EditAvailableColumnDialogComponent implements OnInit {

  availableColumnToView: AvailableColumn;
  numberRangeValue: NumberRangeValue = { numberrangestart: 0, numberrangeend: 0 };
  dateRangeValue: DateRangeValue = { daterangestart: new Date, daterangeend: new Date };
  numberValue: NumberValue = { maxdigits: 0, maxdecimals: 0, isnumberonly: false , requiredcolumnvalue: null};
  textValue: TextValue = { maxlength: 120, validregex: null, minlength: 0 };
  eanValue: EANValue = { value: undefined };
  listValue: ListValue = { valuelist: [] };
  dynListOptions: DynListValue[] = [];
  dynmlistValue: DynListValue =  null;

  checkBoxValue: BoolValue = { value: null };
  dateValue: DateValue = { value: null };

  colTypeOptions: { label: string, value: number }[] = [];
  colEnum: any;

  hint: WritableSignal<string> = signal(null);

  settingValueList: WritableSignal<ISettingValue[]> = signal([]);
  selecteddbMapping: WritableSignal<ISettingValue> = signal(null);
  selectedChildren: WritableSignal<{ [key: string]: ISettingChildrenValue[] }> = signal({});
  selectStaticUsedlistValues$ : Observable<{[key: number]: ItemValueModel[]}> = of({});
  moebeldynmlistValue: DynListValue;

  referenceColumnValuesDynListOptions: WritableSignal<ItemValueModel[]> = signal([]);
  referenceColumnValueDynList: string;

  referenceColumnValueNumberListOptions: WritableSignal<ItemValueModel[]> = signal([]);
  allColumns: AvailableColumn[];


  constructor(
    private store: Store<any>,
    private validateService: CommonCheckService,
    @Inject(DynamicDialogRef) public ref: DynamicDialogRef,
    @Inject(DynamicDialogConfig) public config: DynamicDialogConfig
  ) {

    this.selectStaticUsedlistValues$ = this.store.pipe(select(selectStaticUsedlistValues));

  }

  ngOnInit() {


    const keys1 = Object.keys(DynListOption).filter(key => !isNaN(DynListOption[key]));
    keys1.forEach(x => this.dynListOptions.push({ label: x, value: DynListOption[x] }));
    this.dynmlistValue = {...this.dynListOptions.find(f => f.value == 1)};

    //nur Möbel Options zur Zeit, keine Auswahl
    this.moebeldynmlistValue = this.dynListOptions.find(f => f.value == DynListOption.MöbeldesSortimentsundModul);

    this.availableColumnToView = this.config.data.availableColumnToView;
    this.allColumns = this.config.data.allColumns;
    this.referenceColumnValueNumberListOptions.set(this.allColumns.filter(f=> f.colName != this.availableColumnToView.colName
      && f.dbMapping
      && (f.colType == ColumnTypes.Number || f.colType == ColumnTypes.DynamicList )
     ).map(x => <ItemValueModel>{ label: x.colName, value: x.dbMapping }));

     this.referenceColumnValuesDynListOptions.set(this.allColumns.filter(f=> f.colName != this.availableColumnToView.colName
      && f.dbMapping
      && (f.colType == ColumnTypes.Number || f.colType == ColumnTypes.DynamicList )
     ).map(x => <ItemValueModel>{ label: x.colName, value: x.dbMapping }));

    if (this.availableColumnToView.colType == ColumnTypes.List)
      {
        this.store.dispatch(new GetStaticListValuesAction({columnlistid:  this.availableColumnToView.id, listtabletype: ItemValueListTypeTable.AVAILABLECOLUMNS }));
      }


      switch (this.availableColumnToView.colType) {

        case (ColumnTypes.EAN):
          this.eanValue = <EANValue> { value: null }
          break;

        case (ColumnTypes.Text):
          this.textValue.maxlength = this.availableColumnToView.maxLength;
          this.textValue.minlength = this.availableColumnToView.minLength ?? 0;
          this.textValue.validregex = this.availableColumnToView.validRegEx;

          break;
        case (ColumnTypes.DateRange):
          this.dateRangeValue = <DateRangeValue>  { daterangestart: new Date, daterangeend: new Date };
          break;
        case (ColumnTypes.NumberRange):
          this.numberRangeValue = <NumberRangeValue> { numberrangestart: 0, numberrangeend: 0 };

          break;
        case (ColumnTypes.List):

          this.selectStaticUsedlistValues$.subscribe(data => {
            if (data && data[this.availableColumnToView.id]) {
              this.listValue.valuelist = data[this.availableColumnToView.id].map(x => <any>{item:  x.value }) ;;
            }
          })
          break;
        case (ColumnTypes.Number):

          this.numberValue.isnumberonly = this.availableColumnToView.isNumberOnly == 1;
          this.numberValue.maxdigits = this.availableColumnToView.maxDigits;
          this.numberValue.maxdecimals = this.availableColumnToView.maxDecimals;
          this.numberValue.requiredcolumnvalue = this.availableColumnToView.requiredColumnValue;
          break;
        case (ColumnTypes.CheckBox):
          this.checkBoxValue = <BoolValue> { value: null };

          break;
        case (ColumnTypes.Date):
          this.dateValue = <DateValue>  { value: null };
          break;
          case (ColumnTypes.DynamicList):
          this.dynmlistValue = {...this.dynListOptions.find(f => f.value == this.availableColumnToView.listOptions)};
          this.referenceColumnValueDynList = this.availableColumnToView.requiredColumnValue;
          break;

      }


    const keys = Object.keys(ColumnTypes).filter(key => !isNaN(ColumnTypes[key]));
    keys.forEach(x => this.colTypeOptions.push({ label: x, value: ColumnTypes[x] }));
    this.colEnum = ColumnTypes;




    this.store.dispatch(new GetSettingsStateAction({ settingid:  SettingValueEnum.DBMapping, onlyActive: true }));

    this.store.pipe(select(selectSettingValues)).pipe(filter(f => f && f.length > 0), take(1)).subscribe(d => {
      if (this.availableColumnToView.dbMapping && !d.find(f => f.settingValue == this.availableColumnToView.dbMapping)) {
        d = this.validateService.getClone(d);
        var current = <ISettingValue>{ id: 0, setting_Id: SettingValueEnum.DBMapping, settingValue: this.availableColumnToView.dbMapping, isActive: 1 };
        d.unshift(current);
        this.hint.set('Die Datenbankzuordnung wurde nicht gefunden. ');
      }

      this.settingValueList.set(d);
      this.selecteddbMapping.set(d.find(f => f.settingValue == this.availableColumnToView.dbMapping));
      if (this.selecteddbMapping()) {
        this.changeDbMapping({ value: this.selecteddbMapping(), originalEvent: null }, true);
      }

    }
    )


  }

  changeListenTyp(e)
  {
    if ( e.value == ColumnTypes.DynamicList) {
      this.dynmlistValue = this.moebeldynmlistValue;
    } else
    {
      this.dynmlistValue = null;
    }
  }

  clearDbMapping()
  {
    this.availableColumnToView = this.validateService.getClone(this.availableColumnToView);
    this.availableColumnToView.colType = ColumnTypes.Text;
    this.availableColumnToView.dbMapping  = null;
    this.availableColumnToView.bubMapping = null;
  }



  changeDbMapping(e: DropdownChangeEvent, init: boolean = false) {

    this.hint.set(null);
    this.availableColumnToView = this.validateService.getClone(this.availableColumnToView);
    this.availableColumnToView.dbMapping = e.value.settingValue;
    this.availableColumnToView.bubMapping = null;

    if (this.selectedChildren()[e.value.id] == undefined || this.selectedChildren()[e.value.id].length == 0) {
      this.store.dispatch(new GetSettingsChildrenAction({ settingvalueId: e.value.id }));
    }
    var hint = null;
    this.store.pipe(select(selectSettingChildrenValues)).subscribe(data => {
      var elements = data[e.value.id];
      if (elements instanceof Array) {
        this.availableColumnToView = this.validateService.getClone(this.availableColumnToView);
        var coltype = elements.find(x => x.name == 'columntype');
        if (coltype && coltype.settingValue) {
          var newtype =  Number(coltype.settingValue) as ColumnTypes;
          if (init && this.availableColumnToView.colType != newtype && init)
          {
            hint += 'Spaltentyp wurde geändert von ' + ColumnTypes[this.availableColumnToView.colType] + ' zu ' + ColumnTypes[newtype];
          }
          this.availableColumnToView.colType = newtype;
        }
        var bub = elements.find(x => x.name == 'bubmapping');
        if (bub && bub.settingValue) {
          if (init && this.availableColumnToView.bubMapping && this.availableColumnToView.bubMapping != bub.settingValue) {
            hint += '\n BubMapping wurde geändert';
          }
          if (this.availableColumnToView.dbMapping == e.value.settingValue )
          {
            this.availableColumnToView.bubMapping = bub.settingValue;
          }
        }
        var desc = elements.find(x => x.name == 'description');
        if (desc && !init) {
          if (!this.availableColumnToView.colDesc || this.availableColumnToView.colDesc.indexOf(desc.settingValue) == -1) {
            this.availableColumnToView.colDesc = desc?.settingValue;
          }
        }
      }
      this.changeListenTyp({ value: this.availableColumnToView.colType });
    })

    if (init == true) {
      this.hint.set(hint);
    }
  }

  close() {
    switch (this.availableColumnToView.colType) {

      case (ColumnTypes.EAN):


        break;

      case (ColumnTypes.Text):

        this.availableColumnToView.maxLength = this.textValue.maxlength;
        this.availableColumnToView.minLength = this.textValue.minlength;
        this.availableColumnToView.validRegEx = this.textValue.validregex;

        break;
      case (ColumnTypes.DateRange):

        break;
      case (ColumnTypes.NumberRange):

        break;
      case (ColumnTypes.List):

        this.store.dispatch(new UpdateStaticListValuesAction({columnlistid:  this.availableColumnToView.id, items:
          this.listValue.valuelist.map(x => <ItemValueModel> { label:  x.item, value: x.item }), listtabletype:  ItemValueListTypeTable.AVAILABLECOLUMNS }));
        break;
      case (ColumnTypes.Number):

        this.availableColumnToView.isNumberOnly = this.numberValue.isnumberonly ? 1 : 0;
        this.availableColumnToView.maxDigits = this.numberValue.maxdigits;
        this.availableColumnToView.maxDecimals = this.numberValue.maxdecimals;
        this.availableColumnToView.requiredColumnValue = this.numberValue.requiredcolumnvalue;


        break;
      case (ColumnTypes.CheckBox):

        break;
      case (ColumnTypes.Date):

        break;
        case (ColumnTypes.DynamicList):

          this.availableColumnToView.requiredColumnValue = this.referenceColumnValueDynList;
          this.availableColumnToView.listOptions = this.dynmlistValue.value;

          break;
    }

    this.ref.close(this.availableColumnToView);
  }

  cancel() {
    this.ref.close();
  }

  deleteListValue(itemValue: string) {
    const idx = this.listValue.valuelist.findIndex(x => x.item == itemValue);
    this.listValue.valuelist.splice(idx, 1);
  }

  addNewListValue() {
    this.listValue.valuelist.push({ item: "Neuer Eintrag" });
  }
}
