import { NavigationTab, NavMenuItem, NavMenuItemTypeEnum } from '@app/common/models';

export interface NavigationState {
    tabs: NavigationTab[]; 
    naventries: NavMenuItem[];

}

export const InitialNavigationState: NavigationState = {
    tabs: [
        // { displayName: 'Benachrichtigungen', url: '/notifications'}
    ],
    naventries: [  <NavMenuItem> { label: "Dashboard", routerlink: "/", icon: "desktop_mac", tooltip: "Dashboard", active: "active", seperator: false, type : NavMenuItemTypeEnum.default, notificationCount: 0, children: new Array<NavMenuItem>() } ]
};
