import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { featureName, reducer, ValidationEditorEffects } from '@app/+validationEditor/store';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
    imports: [
        StoreModule.forFeature(featureName, reducer),
        EffectsModule.forFeature([ValidationEditorEffects])
    ]
})

export class ValidationEditorStateModule {}