import { ArticleDataActionDone, ArticleDataState, InitialArticleDataState } from './state';
import { ArticleDataActionType, ArticleDataAction } from './actions';
import { SimpleResult } from '@app/common/models/simpleResult.model';
import { ActionStates, TaskUserModel } from '@app/common/models';


export function reduceArticleData(state: ArticleDataState = InitialArticleDataState, action: ArticleDataAction): ArticleDataState {
  switch (action.type) {
  

    case ArticleDataActionType.ClearArticleDataSuccessResults:
        return {
          ...state,
          isLoading: false,
          successResult: undefined
        };



    case ArticleDataActionType.UpdateArticleDataActionDone:
      const idx = state.items.findIndex(x => x.id == action.payload.changeditem.id);
      var newitems = [...state.items.slice(0, idx), { ...action.payload.changeditem }, ...state.items.slice(idx + 1)]
      return {
        ...state,
        isLoading: false,
        currenterror: undefined,
        items: newitems,
        successResult: (action.payload.successmessage ?  <ArticleDataActionDone>{ result: action.payload.changeditem, statechangedTo: getChangeState(state.items, action.payload.changeditem), actionResult: ArticleDataActionType.UpdateArticleDataActionDone } : undefined)
      };

    case ArticleDataActionType.InsertArticleDataActionDone:
      var items = [...state.items];
      items.unshift(action.payload.newitem);

      return {
        ...state,
        isLoading: false,
        currenterror: undefined,
        items: items,
        successResult: <ArticleDataActionDone>{ statechangedTo: getChangeState(state.items, action.payload.newitem),  result: action.payload.newitem, actionResult: ArticleDataActionType.InsertArticleDataActionDone }
      };

    case ArticleDataActionType.GetArticleDataDone:
      return {
        ...state,
        isLoading: false,
        currenterror: undefined,
        items: action.payload.items,
        successResult: undefined,
        itemsHits: action.payload.items && action.payload.items.length > 0 ? action.payload.items[0].hits : 0
      };

    case ArticleDataActionType.DeleteArticleDataActionDone:
      const delidx = state.items.findIndex(x => x.id == action.payload.id);
      var newitems = [...state.items.slice(0, delidx), ...state.items.slice(delidx + 1)];
      return {
        ...state,
        isLoading: false,
        currenterror: undefined,
        items: newitems,
        successResult: undefined
      };

    case ArticleDataActionType.GetArticleDataValidationsActionDone:
      return {
        ...state,
        currenterror: undefined,
        validationList: action.payload.validationlist
      };

    case ArticleDataActionType.GetSoMaContactsActionDone:
      return {
        ...state,
        currenterror: undefined,
        SoMaContacts: action.payload.somaContacts
      };

    case ArticleDataActionType.ArticleDataErrorAction:
      var err = SimpleResult.getSimpleResult(action.payload.error);
      return {
        ...state,
        currenterror: err,
        isLoading: false,
        successResult: undefined
      };
  }
  return state;
}

/**Prüfen auf welchen Taskstate geändert wurde
 * für Meldung an den User und Messaging
 */
export function getChangeState(items: TaskUserModel[], changeditem: TaskUserModel) : ActionStates {
  var statechanged: ActionStates = ActionStates.New;
  const idx = items.findIndex(x => x.id == changeditem.id);
  if (idx > -1 && changeditem) {
    //status changed auf Tansfered prüfen für Message
    if (items[idx].taskState != ActionStates.Transfered_Lieferant && changeditem.taskState == ActionStates.Transfered_Lieferant) {
      statechanged = ActionStates.Transfered_Lieferant;
    }
  }
  return statechanged as ActionStates;
}
