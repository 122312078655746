import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { GetLagerAction, GetMandantAction, GetMandantenAction, selectMandant, selectMandanten, selectallLager, selectisLoadingMandanten } from "../store";
import { LagerService, MandantService } from "@app/api/filialmatrix/services";
import { QueryTokenDTO } from "@app/api/filialmatrix/models";

@Injectable()
export class MandantenService {

  selectMandant$ = this.store.pipe(select(selectMandant));
  selectMandanten$ = this.store.pipe(select(selectMandanten));
  selectallLager$ = this.store.pipe(select(selectallLager));
  selectisLoadingMandanten$ = this.store.pipe(select(selectisLoadingMandanten));

  constructor(private store: Store<any>) { }

  loadMandant(userid: string) {
    if (userid == undefined) return;
    this.store.dispatch(new GetMandantAction({
      params: <MandantService.MandantQueryParams>
        {
          queryToken: <QueryTokenDTO>{
            filter: { "benutzer.cmfuser.id.equals": userid }
          }
        }
    }))
  }

  loadMandanten(params: MandantService.MandantQueryParams) {
    this.store.dispatch(new GetMandantenAction({ params: params }));
  }


  loadLager()
  {
    this.store.dispatch(new GetLagerAction({ params: <LagerService.LagerQueryLagerParams>{eagerLoading: 2, queryToken: {} } }));
  }

}
