<div class="container-fluid ">
  <div class="grid" style="padding-top:0.5rem">
    <mat-icon class="pageTitleIcon material-icons-outlined">folder_open</mat-icon>
    <h2 class="pageTitleHeader">Listenverwaltung</h2>
  </div>

  <p-toolbar>
    <div class="ui-toolbar-group-left">
      <button pButton type="button" icon="pi pi-plus" label="Neue Liste" (click)="editValidationList(null)"></button>
    </div>
    <div class="ui-toolbar-group-right">
      <button pButton type="button" icon="pi pi-replay" label="Reload" style="margin-left: 15px;"
        (click)="load()"></button>
    </div>
  </p-toolbar>


  <div class="grid">
    <div class="col-4">
      <h3>Vorlagen</h3>
      <p-table #listTable [value]="validationLists$ | async" selectionMode="single" [(selection)]="selectedList"
        dataKey="id" [paginator]="true" [scrollable]="false" [rows]="20" [resizableColumns]="true"
        (onRowSelect)="listSelected()" (onRowUnselect)="listDeselected()" [globalFilterFields]="['listName']">
        <ng-template pTemplate="caption">
          <div style="text-align: right">
            <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
            <input type="text" pInputText size="50" (input)="listTable.filterGlobal($any($event).target.value, 'contains')"
              style="width:auto">
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 2.5rem;"></th>
            <th style="width: 6rem;">Action</th>
            <th style="width: 17rem;">Name</th>
            <th style="width: 5rem;">Aktual.</th>
            <th style="width: 3rem;">Akt.</th>
            <th style="width: 3rem;">Lief.</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col *ngFor="let col of columns">
          </colgroup>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData">
            <td style="width: 2.5rem;">
              <div *ngIf="getValidationHint(rowData) | async" style="color:red;   text-align: center;"
                [pTooltip]="getValidationHint(rowData) | async"><i class="pi pi-circle-fill"></i>
              </div>
              &ensp;
            </td>
            <td style="width: 6rem;">
              <span>
                <button pButton type="button" icon="pi pi-pencil" (click)="editValidationList(rowData)"></button>
                <button pButton type="button" icon="pi pi-trash" (click)="deleteValidationList(rowData)"
                  style="margin-left: 0.313rem;"></button>
              </span>
            </td>
            <td>{{ rowData.listName }}</td>
            <td style=" text-align: center">{{ rowData.changedAt | date}}</td>
            <td>
              <p-checkbox pTooltip="Aktiv für Aufgaben" binary="true" [(ngModel)]="rowData.isActive" readonly="true">
              </p-checkbox>
            </td>
            <td style=" ">
              <p-checkbox pTooltip="Aktiv für Lieferanteninput" binary="true" [(ngModel)]="rowData.isSupplier"
                readonly="true"></p-checkbox>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <div class="col-4"  >
      <h3>Verwendete Spalten</h3>
      <p-table #columnListTable [value]="columnsForSelectedList$ | async" selectionMode="multiple" editMode="row"
        dataKey="id" scrollHeight="800px" [scrollable]="true" scrollDirection="horizontal" [rows]="20" [globalFilterFields]="['colName', 'colDesc']"
        (onRowReorder)="reorderValidationCols(columnListTable)">
        <ng-template pTemplate="caption">
          <div style="text-align: right">
            <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
            <input type="text" pInputText size="50"
              (input)="columnListTable.filterGlobal($any($event).target.value, 'contains')" style="width:auto">
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 3rem">&ensp;</th>
            <th style="width: 7rem" >Action</th>
            <th style="width: 9rem">Name</th>
            <th style="width: 9rem">Beschreibung</th>
            <th style="width: 4rem"> Typ</th>
            <th style="width: 4rem">Muss</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-index="rowIndex">
          <tr [pSelectableRow]="rowData" [pReorderableRow]="index">
            <td style="width: 3rem" >
              <i class="pi pi-bars" style="display: flex;justify-content: center;" pReorderableRowHandle></i>
            </td>
            <td style="width: 7rem">
              <span>
                <button *ngIf="isAdmin" pButton type="button" icon="pi pi-pencil"
                  (click)="editValidationColumn(rowData)"></button>
                <button pButton type="button" icon="pi pi-trash" (click)="deleteValidationColumn(rowData)"
                  style="margin-left: 0.313rem;"></button>
              </span>
            </td>
            <td style="width: 9rem" >{{ rowData.colName }}</td>
            <td style="width: 9rem" >{{ rowData.colDesc | shorten: 17: '...'}}</td>
            <td style="width: 4rem" >{{ getColType(rowData.colType) }}</td>
            <td style="width: 4rem" >
              <p-checkbox binary="true" [(ngModel)]="rowData.isMandatory" readonly="true"></p-checkbox>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <button pButton type="button" icon="pi pi-plus" label="Neue Spalte für diese Liste"
        (click)="editValidationColumn(null)" style="margin-top: 10px;"></button>
    </div>

    <div class="col-4">
      <h3>Verfügbare Spalten</h3>
      <p-table #availableTable [value]="availableColumns$ | async" selectionMode="single" dataKey="id"
        [paginator]="true" [(selection)]="selectedAvailableColumn" [scrollable]="true" scrollDirection="horizontal" [rows]="20"
        [globalFilterFields]="['colName', 'colDesc']">
        <ng-template pTemplate="caption">
          <div class="grid">
            <div class="col-fixed" style="width: 3.1rem;">
              <button  pButton type="button" icon="pi pi-chevron-left" (click)="addAvailableToList()"></button>
            </div>
            <div class="col">
              <div style="text-align: right">
                <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                <input type="text" pInputText size="50"
                  (input)="availableTable.filterGlobal($any($event).target.value, 'contains')" style="width:auto">
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr style="overflow: hidden;">
            <th *ngIf="isAdmin" style="width: 8rem;">Action</th>
            <th style="width: 8rem;" >Name</th>
            <th style="width: 8rem;">Beschreibung</th>
            <th style="width: 5rem;">Typ</th>
            <th style="width: 4rem;">Syst</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowindex">
          <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
            <td style="width: 8rem;" *ngIf="isAdmin">
              <span>
                <button pButton type="button" icon="pi pi-pencil"
                  (click)="editAvailableColumn(rowData)"></button>
                <button pButton type="button" icon="pi pi-trash" (click)="deleteAvailableColumn(rowData)"
                  style="margin-left: 0.313rem; "></button>
              </span>
            </td>
            <td style="width: 9rem;" >{{ rowData.colName }}</td>
            <td style="width: 9rem;">{{ rowData.colDesc | shorten: 17: '...'}}</td>
            <td style="width: 5rem;">{{ getColType(rowData.colType) }}</td>
            <td style="width: 4rem;">
              <p-checkbox binary="true" [(ngModel)]="rowData.systemColumn" readonly="true"></p-checkbox>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div *ngIf="isAdmin" style="margin-top: 15px;">
        <button pButton type="button" icon="pi pi-plus" label="Neue generell verfügbare Spalte"
          (click)="editAvailableColumn(null)"></button>
      </div>
    </div>
  </div>
</div>

<p-confirmDialog header="Bitte bestätigen:" icon="pi pi-exclamation-triangle" message="nix" rejectLabel="Abbrechen">
</p-confirmDialog>
