import { NavigationState, InitialNavigationState } from './state';
import { NavigationAction, AddNavigationTabAction, RemoveNavigationTabAction, RemoveTabByNameAction, InitNavMenueAction, ChangeNavMenueAction, AddNavMenueAction } from './actions';
import { NavMenuItem, NavMenuItemTypeEnum } from '@app/common/models';
import * as cloneDeep from 'lodash/fp/cloneDeep';

export function reduceNavigation(state: NavigationState = InitialNavigationState, action: NavigationAction): NavigationState {

  switch (action.type) {
    case '[NAVIGATION] ReplaceState':
      return action.payload;

    case '[NAVIGATION] AddNavigationTab':
      return addNavigationTab(state, action);
    case '[NAVIGATION] RemoveNavigationTab':
      return removeNavigationTab(state, action);
    case '[NAVIGATION] RemoveTabByName':
      return removeNavigationTabByName(state, action);
    case '[NAVIGATION] InitNavMenueAction':
      return initNavMenueAction(state, action);
    case '[NAVIGATION] ChangeNavMenueAction':
      return changeNavMenueAction(state, action);
    case '[NAVIGATION] AddNavMenueAction':
      return addNavMenueAction(state, action);

  }

  return state;
}


/**Einen NavMenu eintrag hinzufüen, der grade bearbeitet wurde
 * z.B. Themenlisteneeintag oder Aktion
*/
export function addNavMenueAction(state: NavigationState, action: AddNavMenueAction): NavigationState {
  if (state.naventries.find(f => f.type == NavMenuItemTypeEnum.flexible && f.label === action.payload.entry.label)) {
    return state;
  }
  var payload = { ...action.payload.entry };
  payload.type = NavMenuItemTypeEnum.flexible;
  return {
    ...state,
    naventries: [...state.naventries, payload]
  };

}


/**Navmenu ändern z.b. bei aktiviert */
export function changeNavMenueAction(state: NavigationState, action: ChangeNavMenueAction): NavigationState {
  var navitems = cloneDeep(state.naventries);
  if (action.payload.entry.active === 'active' && navitems.filter(t => t.active === 'active').length > 0) {
    var actived = navitems.filter(t => t.active === 'active');
    actived.forEach(f => {
      let index = state.naventries.findIndex(t => t.routerlink === f.routerlink);
      var unchecked = { ...navitems[index] };
      unchecked.active = '';
      navitems[index] = unchecked;
    });
  }
  navitems.forEach(n => {
    if (n.children && n.children.length > 0) {
      n.children.forEach(c => {
        c.active = '';
      })}});

  let index = state.naventries.findIndex(t => t.routerlink === action.payload.entry.routerlink);
  if (index > -1) {
    navitems[index] = action.payload.entry;
  } else {
    navitems.forEach(n => {
      if (n.children && n.children.length > 0) {
        n.children.forEach(c => {
          if (c.routerlink === action.payload.entry.routerlink) {
            n.children[n.children.findIndex(g=> g.routerlink === action.payload.entry.routerlink )] = action.payload.entry;
          }
        })
      }
    })
  }
  return {
    ...state,
    naventries: navitems
  };
}

/**Init des Menues */
export function initNavMenueAction(state: NavigationState, action: InitNavMenueAction): NavigationState {
  return {
    ...state,
    naventries: [...action.payload.entries]
  };
}

export function addNavigationTab(state: NavigationState, action: AddNavigationTabAction): NavigationState {

  if (action.ifNotExists && state.naventries.find(f => f.routerlink === action.payload.url)) {
    return state;
  }

  const navItem: NavMenuItem = {
    id: action.payload.displayName,
    label: action.payload.displayName,
    routerlink: action.payload.url,
    icon: 'attach_file',
    tooltip: action.payload.displayName + ' bearbeiten',
    iconClass: "defaultIcon",
    type: NavMenuItemTypeEnum.default,
    seperator: false,
    active: 'active',
    notificationCount: 0,
    children: new Array<NavMenuItem>()
  }

  return {
    ...state,
    naventries: [...state.naventries, navItem]
  };
}

export function removeNavigationTab(state: NavigationState, action: RemoveNavigationTabAction): NavigationState {
  const tabs = [...state.tabs];
  tabs.splice(action.payload, 1);
  return {
    ...state,
    tabs
  };
}

export function removeNavigationTabByName(state: NavigationState, action: RemoveTabByNameAction): NavigationState {
  const tabs = [...state.naventries];
  const tab = tabs.find(x => x.routerlink.includes(action.payload));
  if (tab) {
    const tabidx = tabs.findIndex(x => x.routerlink == tab.routerlink);
    tabs.splice(tabidx, 1);
  } else {
    console.debug(action.payload + "  in routing currently not found.");
  }
  return {
    ...state,
    naventries: tabs
  }
}
