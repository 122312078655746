import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { CalendarService } from '@app/common/services/calendar.service';
import { TaskService } from '@app/common/services/task.service';
import {  CalendarState } from './state';
import { DisplayAllAttributesAction, CalendarActionType,  GetEventsByModelAction,
    GetEventsByModelDoneAction, GetStructureModelAction, GetStructureModelDoneAction,
     GetDistinctUsersSortimenteAction, GetDistinctUsersSortimenteActionDone } from './actions';
import { withLatestFrom, switchMap, map, tap } from 'rxjs/operators';
import { selectAllAttributes } from './selectors';


@Injectable()
export class CalendarEffects {
    private state$ = this.store.pipe(select(selectAllAttributes));

    constructor(
        private store: Store<any>,
        private service: CalendarService,
        private taskService: TaskService,
        private actions: Actions) { }




    getEventsByModel$ = createEffect(() => { return  this.actions.pipe(
        ofType<GetEventsByModelAction>(CalendarActionType.GetEventsByModel),
        withLatestFrom(this.state$),
        tap(console.log.bind(window)),
        switchMap(([action, state]) => this.service.getEventsByModel( action.payload.queryModel )),
        map(response => new GetEventsByModelDoneAction({ dataModel: response }))
    )})



    GetDistinctUsersSortimente$ = createEffect(() => { return  this.actions.pipe(
        ofType<GetDistinctUsersSortimenteAction>(CalendarActionType.GetDistinctUsersSortimente),
        withLatestFrom(this.state$),
        tap(console.log.bind(window)),
        switchMap(([action, state]) => this.service.GetDistinctUsersSortimente(action.payload.queryModel  )),
        map(response => new GetDistinctUsersSortimenteActionDone({ array: response }))
    )})



    getStructureModel$ = createEffect(() => { return  this.actions.pipe(
        ofType<GetStructureModelAction>(CalendarActionType.GetStructureModel),
        withLatestFrom(this.state$),
        switchMap(([action, state]) => this.service.getFilterStructureModel()),
        map(response => new GetStructureModelDoneAction({ structureModel: response }))
    )})




}
