import { TaskModel, TaskUserModel, ValidationList } from '@app/common/models';
import { AppState as RootState } from '../../store';
import { createFeatureSelector } from '@ngrx/store';
import { TreeNode } from 'primeng/api';
import { TaskStructureModel } from '@app/common/models/taskStructure.model';
import { CmfUserDataModel } from '@app/common/models/cmfUserData.model';

import { ISuppliergroup, ISupplier } from '@app/common/models/suppliergroup.model';
import { TaskQueryParameter } from '@app/common/services/task.service';
import { MandantUserDTO } from '@app/common/models/mandant-user-dto';
import { SimpleResult } from '@app/common/models/simpleResult.model';
import { LieferantUserDTO } from '@app/common-data-module/models/LieferantUserDTO';

export interface TaskListState
{
    page: number;
    pagesize: number;
    total: number;
    username: string;
    tasks: TaskStructureModel<TaskUserModel>[];
    loading: boolean;
    singleTask: TaskStructureModel<TaskUserModel>;
    taskerror: SimpleResult;
    taskSupplierIds: string[];
    loadingSupplierIds: boolean;
    mandanten : MandantUserDTO[];
    lieferantenuser: LieferantUserDTO[];
    validationProfiles: ValidationList[];
    lastlisttaskparmeter: TaskQueryParameter;


    selectedCategories: string[];
    suppliergroups: ISuppliergroup[];
    suppliersForGroup: ISupplier[];
}

export interface AppState extends RootState {
    tasklist: TaskListState;
}

export const InitialTaskListState: TaskListState = {
    tasks: [],
    page: 0,
    pagesize: 4,
    total: 0,
    username: '',
    loading: false,
    singleTask: null,
    taskSupplierIds:[],
    taskerror: undefined,
    loadingSupplierIds: true,
    mandanten:[],
    lieferantenuser:[],
    validationProfiles: [],
    lastlisttaskparmeter: null,
    selectedCategories: ['all'],
    suppliergroups: new Array<ISuppliergroup>(),
    suppliersForGroup: new Array<ISupplier>()
};

export const featureName = 'tasklist';

export const selectTaskListState = createFeatureSelector<TaskListState>(featureName);
