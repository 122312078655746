import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ResultObjectModel, AttributeMainModel, CalendarItemModel } from '../models';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CalendarSearchModel } from '../models/calendarSearch.model';
import { CalendarDataModel } from '../models/CalendarData.model';
import { FilterStructureModel } from '../models/filterStructure.model';


@Injectable({ providedIn: 'root' })
export class CalendarService {

    constructor(private httpClient: HttpClient) { }


    getEventsByModel(queryModel: CalendarSearchModel): Observable<CalendarDataModel[]> {
      console.log("getEventsByModel");
      const url = `${environment.connections.CalendarService}/Calendar/GetFilteredEvents`;
      const httpOptions = {
        headers: new HttpHeaders({

        })
      };
      return this.httpClient.post<ResultObjectModel<any>>(url, queryModel, httpOptions).pipe(map(x => x.data));
    }


    GetDistinctUsersSortimente(queryModel: CalendarSearchModel): Observable<any[]> {
      const url = `${environment.connections.CalendarService}/Calendar/GetDistinctUsersSortimente`;
      const httpOptions = {        headers: new HttpHeaders({        })      };
      return this.httpClient.post<ResultObjectModel<any>>(url,queryModel, httpOptions).pipe(map(x => x.data));
    }

    getFilterStructureModel(): Observable<FilterStructureModel[]> {
      const url = `${environment.connections.CalendarService}/Calendar/GetSortimentStructure`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*'
        })
      };
      return this.httpClient.get<FilterStructureModel[]>(url, httpOptions);
    }
}
