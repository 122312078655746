import { Injectable, NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { articledataFeatureName } from './store/state';
import { reduceArticleData } from './store/reducer';
import { ArticleDataEffects } from './store/effects';
import { SignalMessageService } from '@app/common/services/signalmessage.service';
import { AritcleDataService } from '@app/common/services/articledata-service';

@Injectable()
@NgModule({
    imports: [
        StoreModule.forFeature(articledataFeatureName, reduceArticleData),
        EffectsModule.forFeature([ArticleDataEffects])
    ], providers: [AritcleDataService, SignalMessageService]
})
export class ArticleDataStateModule { }
