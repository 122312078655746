import { IActionbutton } from '@app/+mediatips/list-mediatips/list-mediatips.component';
import { IBubData } from './bubdata.model';
import { MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';

/**Parameter für die Suche mit Sortierung */
export interface IMediatipSearchParameter
{
    userid: string;
    fromDate: Date;
    tryloadall : boolean;
    sortoption: ISortOption;
}

/**SortDefinition für den Server */
export interface ISortOption
{
  name: string;
  caption: string;
  mediastate: number;
  asc: boolean;
}

export enum MediatipState {
    Erstellt = 100,
    Nicht_begonnen = 200,
    //ongoing -> Erstellung
    In_Bearbeitung = 300,
    //Freigabe
    Released = 350,
    //Done -> Verarbeitet
    Verarbeitet = 400,
    ///tranfered -> Verschickt
    Verschickt = 500,
    ///Complete
    Abgeschlossen = 600,
    Archived = 700,
    Gelöscht = 800,
    Verworfen = 900,
}

/**Aktion die dem Objekt zugeordnet ist */
export enum MeditipActionResultEnum {
    /**nicht */
    laden = 0,
    /**Created */
    angelegt = 1,
    /**saved */
    gespeichert = 2,
    /**send */
    versendet = 3,
    /**deleted */
    gelöscht = 4,
    /**released */
    freigegeben = 5,
    verworfen = 6,

}

/**Action Ergebnis nach Speicherung / Insert / Delete */
export class MediaTipActionDone {

    /**Setzt den Status */
    static getAktionUpdateDone(mediatips: IMediaTip[], mediatip: IMediaTip): MediaTipActionDone {
        var oldmediatip = mediatips.find(t => mediatip.id && t.id == mediatip.id );
        if (oldmediatip == undefined) {
            if (mediatip.mediatipState == MediatipState.Verschickt ) {
                return <MediaTipActionDone>{ result: mediatip, actionResult: MeditipActionResultEnum.versendet }
            }
            return <MediaTipActionDone>{ result: mediatip, actionResult: MeditipActionResultEnum.angelegt }
        } else {

            if (mediatip.mediatipState == MediatipState.Verschickt
                && oldmediatip.mediatipState != MediatipState.Verschickt) {
                return <MediaTipActionDone>{ result: mediatip, actionResult: MeditipActionResultEnum.versendet }
            }
            if (mediatip.mediatipState == MediatipState.Released
                && oldmediatip.mediatipState != MediatipState.Released) {
                return <MediaTipActionDone>{ result: mediatip, actionResult: MeditipActionResultEnum.freigegeben }
            }
            if (mediatip.mediatipState == MediatipState.Verworfen
                && oldmediatip.mediatipState != MediatipState.Verworfen) {
                return <MediaTipActionDone>{ result: mediatip, actionResult: MeditipActionResultEnum.verworfen }
            }
            return <MediaTipActionDone>{ result: mediatip, actionResult: MeditipActionResultEnum.gespeichert }
        }
    }
    result: IMediaTip;
    actionResult: MeditipActionResultEnum;
}

export interface INameSort {
    name: string;
    sort: number;
}

export class MediaTipTask {


    static ToTasks(mediatips: IMediaTip[], ismanager: boolean): MediaTipTask[] {
        var amediastasks = new Array<MediaTipTask>();
        if (mediatips == undefined) return amediastasks;

        for (var i = 0, l = mediatips.length; i < l; i++) {
            if (amediastasks.findIndex(t => t.mediatipState == mediatips[i].mediatipState) < 0) {
                var nameandsort = MediaTipTask.toName(mediatips[i].mediatipState, ismanager);
                amediastasks.push(<MediaTipTask>{
                    name: nameandsort.name,
                    sort: nameandsort.sort,
                    mediatipState: mediatips[i].mediatipState
                });
            }
        }
        amediastasks = amediastasks.sort((a, b) => { return (a.sort < b.sort ? -1 : 1) });
        return amediastasks;
    }

    mediatipState: MediatipState;
    name: string;
    sort: number;
    /**Name und Sortierung des Status korrigiert vergeben */
    static toName(state: MediatipState, ismanager: boolean): INameSort {
        switch (state) {
            case MediatipState.In_Bearbeitung:
                return <INameSort>{ name: "In Arbeit", sort: state };
            case MediatipState.Nicht_begonnen:
                return <INameSort>{ name: "Nicht Begonnen", sort: state };
            case MediatipState.Verschickt:
                // Ausnahme -> Verschickt wird für Manager als Neu angezeigt
                if (ismanager) return <INameSort>{ name: "Neu", sort: 100 };
                return <INameSort>{ name: "Verschickt", sort: state };
            case MediatipState.Abgeschlossen:
                return <INameSort>{ name: "Abgeschlossen", sort: state };
            case MediatipState.Verworfen:
                return <INameSort>{ name: "Verworfen", sort: state };
            case MediatipState.Released:
                return <INameSort>{ name: "Freigegeben", sort: state };
            default:
                return <INameSort>{ name: MediatipState[state], sort: state };
        }
    }


}

export interface IMediaMenue {
    id: number;
    menu: MenuItem[];
}

export interface IMediaTipActions {
  menu: MenuItem[] ;
  actions: IActionbutton[] ;

}
/**Der Medientipp z.b. Bericht in der Bildzeitung */
export interface IMediaTip {
    id: number;
    ean: string;
    creatingUserId: string; // eigentlich long
    creatingUserName: string;
    createDat: Date;
    targetUserId: string;
    targetUserName: string;
    mediatipState: MediatipState;
    mediaPresences: IMediaPresence[],
    bubData: IBubData;
    isActive: number;
    isReadonly: boolean;
    sendDat?: Date;
    hits?: number;
    remark : string;
}

export enum MediaPresenceState {
    Erstellt = 100,
    Nicht_begonnen = 200,
    //ongoing -> Erstellung
    In_Bearbeitung = 300,
    //Freigabe
    Freigabe = 350,
    //Done -> Verarbeitet
    Verarbeitet = 400,
    ///tranfered -> Verschickt
    Verschickt = 500,
    ///Complete
    Abgeschlossen = 600,
    Archived = 700,
    Gelöscht = 800,
    Verworfen = 900,
}


/**Die Präsenz z.b. Bild-Zeitung */
export interface IMediaPresence {
    id: number;
    /**Reference auf Mediatip */
    mediatip_id: number;
    /**Präsenztext */
    presence: string;
    /**Art oder Typ als Text */
    presenceType: string;
    /**Datum der Präsenz */
    presenceDate?: Date;
    /**Status */
    presenceState: MediaPresenceState;

}
