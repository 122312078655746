import { Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticledataListComponent } from './pages/articledata-list/articledata-list.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TableModule } from 'primeng/table';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { AritcleDataService } from '@app/common/services/articledata-service';
import { ArticleDataStateModule } from './articledata-state-module';
import { MatIconModule } from '@angular/material/icon';
import { NgStringPipesModule } from 'ngx-pipes';
import { LipoMessageService } from '@app/common/services/LipoMessageService';
import { NotificationService } from '@app/common/services/notification.service';
import { ArticledataEditComponent } from './components/articledata-edit/articledata-edit.component';
import { MediatipService } from '@app/common/services/mediatip.service';
import {InputTextareaModule} from 'primeng/inputtextarea';
import { ValidationEditService } from '@app/common/services/validation-edit.service';
import { InputTextModule } from 'primeng/inputtext';
import { SignalMessageService } from '@app/common/services/signalmessage.service';
import { NotificationsModule } from '@app/+notifications/notifications.module';


@Injectable()
@NgModule({
  providers:[AritcleDataService, LipoMessageService, NotificationService, MediatipService, ValidationEditService, SignalMessageService],
  declarations: [ArticledataListComponent, ArticledataEditComponent, ArticledataEditComponent],
  imports: [ FormsModule, CommonModule,  TableModule, DropdownModule, CheckboxModule, ButtonModule,  ConfirmDialogModule, TooltipModule,
    ArticleDataStateModule, MatIconModule, NgStringPipesModule, InputTextareaModule, InputTextModule, NotificationsModule
  ]
})
export class ArticledataModule { }
