import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewChecked, AfterViewInit, WritableSignal, signal } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { selectAllAttributes, selectAllEvents, selectFilterStructure, selectuserscalendarSortimente, selectWithKlammerFilter, selectWithSortimentFilter } from './store/selectors';

import { AuthService } from '@app/common/services/auth.service';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import timelinePlugin from '@fullcalendar/timeline';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { CalendarService } from '@app/common/services/calendar.service';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import {  TaskUserModel, ResultObjectModel } from '@app/common/models';
import { CalendarItemModel } from '@app/common/models/calendarItem.model';
import { map, filter, take } from 'rxjs/operators';
import { CalendarSearchModel } from '@app/common/models/calendarSearch.model';
import { GetDistinctUsersSortimenteAction, GetEventsByModelAction, GetStructureModelAction } from '@app/+calendar/store';
import { CalendarDataModel } from '@app/common/models/CalendarData.model';

import * as moment from 'moment';


import { environment } from '@env/environment';
import { DialogService } from 'primeng/dynamicdialog';
import { SomaTaskViewDialogComponent } from '@app/dialogs/taskViewDialogs/soma-task-view-dialog/soma-task-view-dialog.component';
import { HttpClient } from '@angular/common/http';
import { TaskStructureModel } from '@app/common/models/taskStructure.model';
import { SupplierTaskDialogComponent } from '@app/dialogs/taskViewDialogs/supplier-task-dialog/supplier-task-dialog.component';
import { MediatippsEditDialogComponent } from '@app/+mediatips/dialogs/mediatippsEditDialog/mediatippsEditDialog.component';
import { GetMediatipAction } from '@app/+mediatips/store/actions';
import { selectMediatipActiondone } from '@app/+mediatips/store/selectors';
import { MeditipActionResultEnum } from '@app/common/models/mediatips.models';
import * as cloneDeep from 'lodash/fp/cloneDeep';
import { CalendarOptions } from '@fullcalendar/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { GetKlammernAction, GetSortimenteAction, GetSortimentstypenAction, selectallKlammern, selectallSortimente, selectallTypen } from '@app/common-data-module/store';
import { KlammerService, SortimentService } from '@app/api/filialmatrix/services';
import { QueryTokenDTO } from '@app/api/commonWebAPI/models';

import { KlammerDtoClient } from '@app/common/models/sortimente/KlammerDtoClient';
import { SortimentDtoClient } from '@app/common/models/sortimente/SortimentDtoClient';
import { TypDtoClient } from '@app/common/models/sortimente/SortimentstypenDtoClient';


@Component({
  selector: 'app-calendar',
  templateUrl: 'calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [DialogService]
})

export class CalendarComponent implements OnInit, AfterViewChecked, AfterViewInit {
  attributes$ = this.store.pipe(select(selectAllAttributes));

  eventsSubject: Subject<CalendarDataModel[]> = new Subject<CalendarDataModel[]>();
  events$ = this.eventsSubject.asObservable();

  sortimentstypenSub: Subject<TypDtoClient[]> = new Subject<TypDtoClient[]>()
  sortimentstypen: Observable<TypDtoClient[]> = this.sortimentstypenSub.asObservable();


  filterStructure$ = this.store.pipe(select(selectFilterStructure), filter(l => l.length > 0), map(cloneDeep));
  sortimentTypes$ = this.store.pipe(select(selectallTypen));
  selectallSortimente$ = this.store.pipe(select(selectallSortimente));
  sortimentsKlammern$ = this.store.pipe(select(selectallKlammern));

  sortiments$: Observable<SortimentDtoClient[]>;
  selectedMaster: KlammerDtoClient;
  selectedSortiments: SortimentDtoClient[];
  resources: any[]; // CalendarResourceModel[];
  events: CalendarDataModel[] = [];
  displayEvents: CalendarDataModel[] = [];
  options: any;
  attributes: CalendarItemModel[];
  rangeDates: Date[];
  isLoading: WritableSignal<boolean> = signal(false);
  show = false;
  defaulteventLineLimit = 4;
  eventLineLimit: number = 4;
  selectedStructures: string[] = [];
  selectedType: TypDtoClient;
  eventRangeTime: number = 1;
  dateRangeLoaded: Date[] = [];
  defaultRangeDate: Date = new Date();
  filterlabel: string = 'kein Filter';
  singleTaskHeader$: Observable<TaskStructureModel<TaskUserModel>>;
  singleTaskTasks$: Observable<TaskStructureModel<TaskUserModel>[]>;
  singleTask$: Observable<TaskStructureModel<TaskUserModel>>;
  isManager$ = this.authService.claims$
    .pipe(
      filter(f => !!f.member_of),
      map(userInfo => environment.managers.some(x => userInfo.member_of.includes(x))));
  public usersCalendarSortimentKlammern$ = this.store.pipe(select(selectuserscalendarSortimente));

  constructor(private store: Store<any>, private calendarService: CalendarService, private cdRef: ChangeDetectorRef,
    private authService: AuthService, private dialogService: DialogService, private httpClient: HttpClient
  ) { }

  ngAfterViewInit(): void {
    this.events$.subscribe(events => {
      if (this.fc && this.fc.getApi()) {
        this.events = events;
        this.displayEvents = events;
        this.isLoading.set(false);
        this.fc.getApi().removeAllEvents();
        this.fc.getApi().removeAllEventSources();
        this.fc.getApi().addEventSource({ events: events });

        this.sortimentTypes$.subscribe(all => {
            this.isManager$.subscribe(ismanager => {
              if (all == undefined) {
                this.sortimentstypenSub.next([]);
                return;
              }
              if (all.length == 0) {
                 this.sortimentstypenSub.next([]);
                 return;
                 }
              if (ismanager == true) {
                this.sortimentstypenSub.next(all);
                return;
              }
              if (events == undefined) {
                this.sortimentstypenSub.next([]);
                return;
              }
              var selected = all.filter(h => events.filter((us: any) => us.extendedProps
                && us.extendedProps.hasOwnProperty("typeInfo") && us.extendedProps.typeInfo === h.id).length > 0);
              this.sortimentstypenSub.next(selected);
            })
        });
      }
    })
  }

  @ViewChild('pickDates') datePicker;
  @ViewChild('fc') fc: FullCalendarComponent;


  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }


  ngOnInit() {
    this.isLoading.set( true);
    this.initCalendar();

    const searchModel: CalendarSearchModel = {
      RangeTimeToShow: this.eventRangeTime,
      EventRangeEnd: null,
      EventRangeStart: null,
      Duration: 190,
      GeneralStartDate: new Date()
    }

    searchModel.GeneralStartDate.setHours(0, 0, 0, 0);
    this.dateRangeLoaded.push(new Date(searchModel.GeneralStartDate));
    this.dateRangeLoaded.push(new Date(searchModel.GeneralStartDate));
    this.dateRangeLoaded[1].setDate(this.dateRangeLoaded[1].getDate() + 190);

     this.store.dispatch(new GetStructureModelAction());

    this.store.dispatch(new GetEventsByModelAction({ queryModel: searchModel }));

    //TODO: unused
   /*  var copysearcModel = { ...searchModel };
    copysearcModel.Duration = 100 * 365; // weit in die Zukunft


    this.store.dispatch(new GetDistinctUsersSortimenteAction({ queryModel: copysearcModel }));
 */

    var pk = <KlammerService.KlammerQueryKlammernParams>{};
    pk.eagerLoading = 2;
    pk.queryToken = <QueryTokenDTO>{
      take: null, skip: null,
      orderBy: [{ by: "name", desc: false }]
    }
    this.store.dispatch(new GetKlammernAction({ param: pk }));


    var ps = <SortimentService.SortimentQuerySortimenteParams>{}
    ps.eagerLoading = 2;
    ps.queryToken = <QueryTokenDTO>{
      take: null, skip: null,
      orderBy: [{ by: "name", desc: false }]
    }
    this.store.dispatch(new GetSortimenteAction({ param: ps }));



        this.store.dispatch(new GetSortimentstypenAction({
      params: <SortimentService.SortimentQuerySortimentsTypenParams>{
        eagerLoading: 2, queryToken: <QueryTokenDTO>{
          take: null, skip: null,
          orderBy: [{ by: "name", desc: false }]
        }
      }
    }));

    this.store.pipe(select(selectAllEvents)).pipe(filter(f=> f != undefined)).subscribe(e =>
      {
        this.eventsSubject.next(e)
      }
      );

  }


  getSupplierKlammern(klammernSortimente: Observable<SortimentDtoClient[]>): Observable<KlammerDtoClient[]> {
    return klammernSortimente.pipe(map(g => {
      var sortimente = g.filter(t => t != undefined);
      var result = new Array<KlammerDtoClient>();
      sortimente.forEach(f=>{
            if (result.find(k=> k.id == f.klammer.id ) == undefined)
            {
                result.push(f.klammer);
            }
      })
      return result;
    }));
  }

  getSupplierSortiments(klammernSortimente: Observable<SortimentDtoClient[]>, masterklammer: any): Observable<SortimentDtoClient[]> {
    if (masterklammer == undefined) return of([]);
    return klammernSortimente.pipe(
      map(f => {
        return f.filter(t => t.klammer.id === masterklammer.id );
      }
      ));
  }

  /**Filter Typen erlaubt ? */
  canSelectTypen(): Observable<boolean> {
    if (this.selectedMaster == undefined) return of(false);
    return this.sortimentstypen.pipe(map(f => {
      var result =  (f != undefined && f.length > 0);
      return result;
    }));
  }

  clearfilter(e) : void
  {
    this.sortimentstypenSub.next([]);
    this.selectedMaster = null;
  }

/**Typen für Lieferant nur die , die im Task gespeichert wurden
   */
  evaluateTypen(e): void {
    this.sortimentTypes$.subscribe(all => {
      this.store.pipe(select(selectAllEvents)).subscribe((userdata  : any)=> {
        this.isManager$.subscribe(ismanager => {

          if (all == undefined) {
            this.sortimentstypenSub.next([]);
            return;
          }
          if (all.length == 0) {
             this.sortimentstypenSub.next([]);
             return;
             }
          if (ismanager == true) {
            this.sortimentstypenSub.next(all);
            return;
          }
          if (userdata == undefined) {
            this.sortimentstypenSub.next([]);
            return;
          }
          var selected = all.filter(h => userdata.filter(us => us.extendedProps
            && us.extendedProps.hasOwnProperty("typeInfo") && us.extendedProps.typeInfo === h.id).length > 0);
          this.sortimentstypenSub.next(selected);
        })
      })
    });
  }





  changedEventRange($event) {
    if (this.isLoading()) return;
    this.ResetFiltering();
    let loadedStart = moment();
    let loadedEnd = moment();
    if (this.rangeDates.length == 2) {
      loadedStart = moment(this.rangeDates[0]);
      loadedEnd = moment(this.rangeDates[1]);
    }
    else {
      loadedStart = moment().subtract(95, 'days');
      loadedEnd = moment().add(95, 'days');
    }
    const newDuration = parseInt(((+loadedEnd.toDate() - +loadedStart.toDate()) / 1000 / 60 / 60 / 24).toString());
    const searchModel: CalendarSearchModel = {
      RangeTimeToShow: this.eventRangeTime,
      EventRangeEnd: null,
      EventRangeStart: null,
      Duration: newDuration,
      GeneralStartDate: loadedStart.toDate()
    }
    searchModel.GeneralStartDate.setHours(0, 0, 0, 0);
    this.store.dispatch(new GetEventsByModelAction({ queryModel: searchModel }));

    var copysearcModel = { ...searchModel };
    copysearcModel.Duration = 100 * 365; // weit in die Zukunft

    this.store.dispatch(new GetDistinctUsersSortimenteAction({ queryModel: copysearcModel }));

  }

  /**Optionen ganz am Anfang setzen */
  initCalendar() {

    //https://fullcalendar.io/docs/events-array

    this.options = <CalendarOptions>{
      initialView: 'dayGridMonth',
      events: this.events,

      eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      },
      plugins: [resourceTimelinePlugin, listPlugin, dayGridPlugin, timeGridPlugin, timelinePlugin],
      displayEventTime: false,
      views: {
        calTimelineYear: {
          type: 'resourceTimeline',
          duration: { months: 12 },
          slotDuration: { weeks: 1 },
          buttonText: 'Jahr'
        },
        calTimeLineSixMonth: {
          type: 'timeline',
          duration: { months: 6 },
          slotDuration: { month: 1 },
          buttonText: '6 Monate',
          slotLabelFormat: { month: 'long', year: 'numeric' }
        },
        calTimelineMonth: {
          type: 'dayGridMonth',
          buttonText: 'Monat',
          // not supported in V6 eventLimit: this.eventLineLimit
        },
        calTimelineWeek: {
          type: 'timeline',

          duration: { weeks: 1 },
          slotDuration: { days: 1 },
          buttonText: 'Woche',
          weekNumbers: false,


        },
        calTimeLineList: {
          type: 'list',
          duration: { days: 30 },

          buttonText: 'Liste',
          weekNumbers: false,

        }
      },
      schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
      aspectRatio: 1.5,
      weekNumbers: true,
      weekText: 'KW',
      weekNumberFormat: { week: 'short' },

      locale: 'de',
      timeZone: 'Europe/Berlin',
      firstDay: 1,
      displayEventEnd: true,
      allDayText: 'Ganztags',

      headerToolbar: {
        left: 'prev,next,today',
        center: 'title',
        right: 'calTimeLineSixMonth,calTimelineMonth,calTimelineWeek,calTimeLineList',

      },
      buttonText: {
        today: 'Heute',
        month: 'Monat',
        day: 'Liste',
        week: 'Woche',
        year: 'Jahr'
      },
      //TODO: nicht mehr unterstützt
      // axisFormat: 'H:mm',
      slotLabelFormat: [
        { month: 'long', year: 'numeric' }, // top level of text
        { weekday: 'short', day: 'numeric', omitCommas: true } // lower level of text
      ],
      editable: false,
      // https://stackoverflow.com/questions/63868338/fullcalendar-eventrender-problem-unknown-option-eventrender
      eventDidMount: function (info) {
        if (info.event.extendedProps.icon) {
          info.el.innerHTML = info.el.innerHTML.replace(info.event.title, "<i class='pi " + info.event.extendedProps.icon + "'></i>&nbsp;" + info.event.title);
        }
      },
      datesSet: (info) => {
        //https://fullcalendar.io/docs/datesSet
        this.eventViewChanged(info);
      },
      eventClick: (info) => {
        info.jsEvent.preventDefault();
        this.eventClicked(info);
      }
    };

  }

  eventClicked(info) {
    let manager: boolean = false;
    let sub = this.isManager$.subscribe(x => manager = x);
    sub.unsubscribe();
    if (this.getMediatipDialog(info.event)) return;
    if (manager && info.event.extendedProps.parentId) this.getSingleParentTask(info.event.extendedProps.parentId);
    if (!manager && info.event.extendedProps.parentId) this.getSingleSupplierTask(info.event.extendedProps.parentId, info.event.extendedProps.taskId);

  }

  /**Mediatip im Kalnder nur readonly anzeigen */
  getMediatipDialog(event: any): boolean {
    if (event.extendedProps && event.extendedProps.mediaTipMetaData
      && event.extendedProps.mediaTipMetaData.mediaTipId
      && event.extendedProps.mediaTipMetaData.mediaTipId > 0) {

      this.store.dispatch(new GetMediatipAction({ id: event.extendedProps.mediaTipMetaData.mediaTipId }));
      this.store.pipe(select(selectMediatipActiondone)).pipe(filter(t => t.result != undefined && t.actionResult == MeditipActionResultEnum.laden), take(1)).subscribe(mediatip => {
        var clone = { ...mediatip.result };
        clone.isReadonly = true;

        const ref = this.dialogService.open(MediatippsEditDialogComponent,
          {
            header: "Medientipp",
            dismissableMask: false,
            width: '1024px',
            height: 'auto',
            data: { mediatip: clone }
          });
      });

    }
    return false;
  }
  getSingleParentTask(attributeId: number) {

    const url = `${environment.connections.TaskListService}/api/task/GetTaskbyId?TaskId=` + attributeId.toString();
    this.httpClient.get<ResultObjectModel<TaskStructureModel<TaskUserModel>>>(url).subscribe(x => {
      const result = x.data;
      const taskHeader = result.data;
      let taskChildren: TaskUserModel[] = [];
      result.children.forEach(x => taskChildren.push(x.data as TaskUserModel));
      console.log('HEADER: ' + taskHeader);
      let dlgHeader = 'Aufgaben Details';
      const ref = this.dialogService.open(SomaTaskViewDialogComponent,
        {
          header: dlgHeader,
          width: '1024px',
          height: 'auto',
          data: { header: taskHeader, children: taskChildren }
        });
    });
  }

  getSingleSupplierTask(parentId: number, id: number) {
    const url = `${environment.connections.TaskListService}/api/task/GetTaskbyId?TaskId=` + id.toString();
    this.httpClient.get<ResultObjectModel<TaskStructureModel<TaskUserModel>>>(url).subscribe(x => {
      let theTaskToView = x.data.data as TaskUserModel;
      let dlgHeader = 'Aufgaben Details';
      const ref = this.dialogService.open(SupplierTaskDialogComponent,
        {
          header: dlgHeader,
          width: '1024px',
          height: 'auto',
          data: { taskToView: theTaskToView }
        });
    });
  }


  private eventViewChanged(info): void {
    if (this.isLoading()) return;
    this.rangeDates = [];
    const actualStart = moment(new Date(info.view.activeStart.setHours(0, 0, 0, 0)));
    const actualEnd = moment(new Date(info.view.activeEnd.setHours(0, 0, 0, 0)));
    let loadedStart = moment(this.dateRangeLoaded[0]);
    let loadedEnd = moment(this.dateRangeLoaded[1]);
    let newDuration: number = 190;

    if (actualStart >= loadedStart && actualEnd <= loadedEnd) return;

    if (actualStart < loadedStart) {
      actualStart.subtract(30, 'days');
      newDuration = parseInt(((+loadedEnd.toDate() - +actualStart.toDate()) / 1000 / 60 / 60 / 24).toString());
    }

    if (loadedEnd < actualEnd) {
      actualEnd.add(30, 'days');
      newDuration = parseInt(((+actualEnd.toDate() - +actualStart.toDate()) / 1000 / 60 / 60 / 24).toString());
    }

    this.dateRangeLoaded[0] = new Date(actualStart.toDate().setHours(0, 0, 0, 0));
    this.dateRangeLoaded[1] = new Date(actualEnd.toDate().setHours(0, 0, 0, 0));

    const searchModel: CalendarSearchModel = {
      RangeTimeToShow: this.eventRangeTime,
      EventRangeEnd: null,
      EventRangeStart: null,
      Duration: newDuration,
      GeneralStartDate: actualStart.toDate()
    }

    this.loadEvents(searchModel)
  }

  reloadWithDateRange() {
    if (this.rangeDates.length != 2) return;
    let loadedStart = moment(this.rangeDates[0]);
    let loadedEnd = moment(this.rangeDates[1]);
    const newDuration = parseInt(((+loadedEnd.toDate() - +loadedStart.toDate()) / 1000 / 60 / 60 / 24).toString());
    const searchModel: CalendarSearchModel = {
      RangeTimeToShow: this.eventRangeTime,
      EventRangeStart: null,
      EventRangeEnd: null,
      Duration: newDuration,
      GeneralStartDate: loadedStart.toDate()
    }
    this.loadEvents(searchModel);
  }

  loadEvents(calQuery: CalendarSearchModel) {
    this.isLoading.set( true);

    //TODO: Prüfen , wird never geused
  //  var copysearcModel = { ...calQuery };
  //  copysearcModel.Duration = 100 * 365; // weit in die Zukunft
  //  this.store.dispatch(new GetDistinctUsersSortimenteAction({ queryModel: copysearcModel }));

    this.store.dispatch(new GetEventsByModelAction({ queryModel: calQuery }));


  }

  clearRangeDates() {
    this.rangeDates = [];
  }

  ResetFiltering() {

    this.selectedStructures = [];
    this.selectedSortiments = [];
    this.selectedMaster = null;
    this.filterStructure$ = this.store.pipe(select(selectFilterStructure), filter(l => l.length > 0), map(cloneDeep));
    this.fc.getApi().setOption('dayMaxEventRows', this.defaulteventLineLimit);
    this.filterlabel = 'kein Filter';
  }

  dateSelected($event) {
    console.log('dateSelected:', { event: $event, selectedDates: this.rangeDates });
    if (this.rangeDates && this.rangeDates[1] != null) {
      this.datePicker.overlayVisible = false;
      this.changeView(this.rangeDates[0], this.rangeDates[1]);
    }

  }

  changeLineLimit() {
    var api = this.fc.getApi();
    api.setOption('dayMaxEventRows', this.eventLineLimit);
    api.render();
    //TODO: not implmented yet in V6
    // siehe https://fullcalendar.io/docs/dynamic-options
    // this.options.calTimelineMonth.dayMaxEventRows = this.eventLineLimit;
  }


  changeView(startDate: Date, endDate: Date) {
    console.log('changing View');
    if (startDate && endDate) {
      console.log('changing View to', { start: startDate, end: endDate });
      const dayDiff = endDate.getDate() - startDate.getDate();
      const monthDiff = endDate.getMonth() - startDate.getMonth();
      const yearDiff = endDate.getFullYear() - startDate.getFullYear();
      let view = 'calTimelineWeek';
      if (dayDiff > 14 || monthDiff > 0) {
        view = 'calTimelineMonth';
      }
      if (monthDiff > 3 || yearDiff > 0) {
        view = 'calTimelineYear';
      }
      console.log('Diffs:', { dayDiff, monthDiff, yearDiff, view })
      this.fc.getApi().changeView(view, {
        start: startDate,
        end: endDate
      });
    }
  }

  activateTypeFilter(selectedType: TypDtoClient): void {

    if (selectedType == null || selectedType.id == undefined || this.selectedMaster == undefined ) {
      this.loadSortiment();
      return;
    }
    this.sortiments$ = this.selectallSortimente$.pipe(map(x => x.filter(y => y.klammer.id === this.selectedMaster.id && y.typ && y.typ.id == selectedType.id)));
    this.selectedSortiments = null;
  }

  loadSortiment() {
      this.selectedType = null;
    this.selectedSortiments = null;
    if (this.selectedMaster == null) {
      this.displayEvents = this.events.slice();
      this.filterlabel = 'kein Filter';
      this.store.pipe(select(selectAllEvents)).pipe(filter(f=> f != undefined)).subscribe(e =>
        {
          this.eventsSubject.next(e)
        }
        );
      return;
    }
    this.sortiments$ = this.selectallSortimente$.pipe(map(x =>
      {
      return x.filter(y => y.klammer == undefined || y.klammer.id === this.selectedMaster.id);
    }
      ));
    this.filterlabel = this.selectedMaster.name;

    this.store.pipe(select(selectWithKlammerFilter(this.selectedMaster.assortmentmasterId))).subscribe(f => this.eventsSubject.next(f)).unsubscribe();

  }

  activateFilter() {
    this.filterlabel = "";
    if (this.selectedSortiments == null || this.selectedSortiments.length == 0) {
      if (this.filterlabel.length == 0) this.filterlabel = 'kein Filter';
      return;
    }
    this.filterlabel = this.selectedMaster?.name;
    this.store.pipe(select(selectWithSortimentFilter(this.selectedSortiments))).subscribe(f => this.eventsSubject.next(f)).unsubscribe();

    if (this.selectedType) {
      this.filterlabel = this.filterlabel + '/' + this.selectedType.name;
    }

    if (this.selectedSortiments.length == 1) {
      this.filterlabel = this.filterlabel + '/' + this.selectedSortiments[0].name;
    }
    else {
      this.filterlabel = this.filterlabel + '/' + this.selectedSortiments[0].name + '...';
    }
  }

}
