import { Component, OnInit, WritableSignal, signal } from '@angular/core';

import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DialogService } from 'primeng/dynamicdialog';

import { Store, select } from '@ngrx/store';

import { IMediaTip, MediatipState, IMediaPresence, MediaPresenceState } from '@app/common/models/mediatips.models';

import { MediatipService } from '@app/common/services/mediatip.service';
import { SettingService } from '@app/common/services/setting.service';
import { AuthService } from '@app/common/services/auth.service';

import { map, filter } from 'rxjs/operators';
import * as cloneDeep from 'lodash/fp/cloneDeep';
import { ISettingValue } from '@app/common/models/setting.models';
import { GeneralResultEnum } from '@app/common/models';
import { Table } from 'primeng/table';
import { LiPoContact } from '@app/common/models/lipocontact.model';
import { IAuthUser, IClientError } from '@app/common/interfaces/Interfaces';

import { InsertMediatipAction, UpdateMediatipAction,  GetMediatipBubDataAction, GetSoMaContactsAction } from '@app/+mediatips/store/actions'
import { environment } from '@env/environment';
import {  selectcurrentMediatipError, selectMediatipActiondone, selectBubData, selectisLoadingBubData, selectSomaContactData} from '@app/+mediatips/store/selectors';
import { SignalMessageService } from '@app/common/services/signalmessage.service';
import { IBubData } from '@app/common/models/bubdata.model';
import { LogUsersClientErrorAction } from '@app/store/user/actions';

@Component({
  selector: 'app-edit-setting.dialog',
  templateUrl: './mediatippsEditDialog.component.html',
  styleUrls: ['./mediatippsEditDialog.component.scss'],
  providers: [MediatipService, SignalMessageService]
})
export class MediatippsEditDialogComponent implements OnInit {

  mediatip: IMediaTip;
  minDatePrecense = new Date();
  settingsPresenceNames: ISettingValue[];
  settingsPresenceTypes: ISettingValue[];
  clonedPresences: { [s: string]: IMediaPresence; } = {};

  newRowId = -1;
  liPoContacts: LiPoContact[];
  selectedLiPoContact: LiPoContact;
  isReadOnly: boolean = false;
  isManager: boolean = false;
  // @ts-ignore
  errorMediatipsHandle$ = this.store.pipe(select(selectcurrentMediatipError));
  // @ts-ignore
  selectMediatipActiondone$ = this.store.pipe(select(selectMediatipActiondone));
  // @ts-ignore
  selectBubDataDone$ = this.store.pipe(select(selectBubData));
  // @ts-ignore
  selectisLoadingBubData$ = this.store.pipe(select(selectisLoadingBubData));
  // @ts-ignore
  selectSomaContactData$ = this.store.pipe(select(selectSomaContactData));

  user: IAuthUser;



  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public store: Store<any>, private authService: AuthService,
    private mediatipService: MediatipService, private settingService: SettingService,
    private dialogService: DialogService,
  ) {


  }


  ngOnInit(): void {
    this.mediatip = cloneDeep(this.config.data.mediatip) as IMediaTip;

    this.store.dispatch(new GetSoMaContactsAction({}));
    this.selectSomaContactData$.subscribe(somas => {
      var contact = somas.filter(t => t.id == this.mediatip.targetUserId)[0];
      this.selectedLiPoContact = contact;
    });


    if (this.mediatip.mediaPresences) this.mediatip.mediaPresences.forEach(x => x.presenceDate = new Date(x.presenceDate));

    this.settingService.getSettings(1, true).subscribe(response => {
      if (response.generalResult != GeneralResultEnum.Success) {
        console.log('error', response)
      } else {
        this.settingsPresenceNames = response.data;
        console.log('settingsName', response.data)
      }
    });
    this.settingService.getSettings(2, true).subscribe(response => {
      if (response.generalResult != GeneralResultEnum.Success) {
        console.log('error', response)
      } else {
        this.settingsPresenceTypes = response.data;
        console.log('settingsType', response.data)
      }
    });
    this.mediatipService.getLiPoContacts().subscribe(response => {
      console.log('LiPoContacts', response);

      if (response.generalResult == GeneralResultEnum.Success) {
        console.log('LiPoContacts Results', response.data);
        this.liPoContacts = response.data;
      } else {
        //this.settingsPresenceTypes = response.data;
        console.log('LiPoContacts Error', response.serverMessage);
      }
    });

    this.authService.claims$.pipe(filter(t => t.client_id), map(f => {
      return <IAuthUser>{ name: f.name, id: f.sub, client_id: f.client_id, member_of: f.member_of }
    }
    )).subscribe(claims => {
      this.user = claims;

      if (environment.managers.some(x => claims.member_of.includes(x))) {
        this.isManager = true;
        console.log('isManager:', this.isManager);
      }
      console.log('User:', claims);

    })


    this.selectBubDataDone$.subscribe(result => {
      if (result && result.ean && result.ean.length > 0 && this.mediatip.ean) {
        var newmediatip = {...this.mediatip};
        newmediatip.bubData = result.bubData;
        this.mediatip = newmediatip;
      }
    });


  }
  setPresenceName(event, rowIndex, rowData) {
    console.log("PresenceName: ", event.value);
    if (!event.value.settingValue) {
      if (event.value && event.value.length > 0) {
        this.mediatip.mediaPresences[rowIndex].presence = event.value;
      }
    }
    else {
      this.mediatip.mediaPresences[rowIndex].presence = event.value.settingValue;
      console.log("PresenceName: OK");
    }
  }
  setPresenceType(event, rowIndex, rowData) {
    console.log("PresenceTyp: ", event.value);
    if (!event.value.settingValue) {
      if (event.value && event.value.length > 0) {
        this.mediatip.mediaPresences[rowIndex].presenceType = event.value;
      }
    }
    else {
      this.mediatip.mediaPresences[rowIndex].presenceType = event.value.settingValue;
      console.log("PresenceType: OK");
    }
  }


  /**Kann die Spalte bearbeitet werden
   * Prüfen des Datums
   */
  canEdit(presence: IMediaPresence, column: string): boolean {
    if (this.mediatip.isReadonly) return false;
    switch (column.toLowerCase()) {
      case "presencedate":
        return presence.presenceState != MediaPresenceState.Freigabe || this.isManager;
      case "presence":
        return presence.presenceState != MediaPresenceState.Freigabe || this.isManager;
      case "presencetype":
        return presence.presenceState != MediaPresenceState.Freigabe || this.isManager;
    }

    return true;
  }

  hasMediatipError(mediatip: IMediaTip): boolean {
    if (!mediatip)  return false;
    return this.getMediatipError(mediatip)?.length > 0;
  }

  getMediatipError(mediatip: IMediaTip): string {
    try {
      var errors = "";
      if (mediatip == undefined) return "";
      if (mediatip.ean == undefined) return "";
      if (!mediatip.bubData || ( mediatip.bubData.ean && mediatip.bubData.ean.length == 0)) return "";
      if (mediatip.targetUserId == undefined || mediatip.targetUserId.length == 0) errors += "Bitte Sortimentsmanager auswählen<br>";
      return errors;
    } catch (e) {
      console.debug(e);
      return "";
    }
  }

  /**Präsenz Fehler ermitteln */
  getPresenceError(rowData: IMediaPresence): string {
    try {
      var errors = "";
      if (this.mediatip == undefined) return "";
      if (this.mediatip.mediatipState == MediatipState.Abgeschlossen
        || this.mediatip.mediatipState == MediatipState.Released
        || this.mediatip.mediatipState == MediatipState.Verworfen
        || this.mediatip.mediatipState == MediatipState.Archived
        || this.mediatip.mediatipState == MediatipState.Gelöscht
      ) return "";
      if (!rowData.presenceDate) errors += "Datum fehlt<br>";
      if (!rowData.presence) errors += "Präsenz fehlt<br>";
      if (rowData.presence && rowData.presence.length > 50) errors += "Präsenztext zu lang<br>";
      if (!rowData.presenceType) errors += "Typ der Präsenz fehlt<br>";
      if (rowData.presenceType && rowData.presenceType.length > 50) errors += "Text für Typ ist zu lang<br>";
      var predate = new Date(rowData.presenceDate);
      predate.setHours(0, 0, 0, 0);
      var today = new Date();
      today.setHours(0, 0, 0, 0);
      if (predate < today) errors += "Datum liegt in der Vergangenheit<br>";
      return errors;
    } catch (e) {
      this.store.dispatch(new LogUsersClientErrorAction({ error: <IClientError>{ userid: this.user.id, modul: 'MediatippEdit', error: e } }));
      return "";
    }
  }

  hasError(mediatip: IMediaTip, rowData: IMediaPresence): boolean {
    if (!mediatip || !mediatip.ean) return false;
    return this.getPresenceError(rowData).length > 0;
  }

  canAction(presence: IMediaPresence, action: string): boolean {
    if (this.mediatip.isReadonly) return false;
    switch (action.toLowerCase()) {
      case "verwerfen":
        return presence.presenceState != MediaPresenceState.Verworfen;
      case "löschen":
        return true;
      case "freigeben":
        var predate = new Date(presence.presenceDate);
        predate.setHours(0, 0, 0, 0);
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        return presence.presence != undefined && presence.presenceDate != undefined && presence.presenceType != undefined
          && presence.presenceState != MediaPresenceState.Freigabe
          && predate >= today;
    }
    return true;
  }


  getStatusClass(presence: IMediaPresence): string {
    if (!presence.presenceState) return "";
    if (presence.presenceState == MediaPresenceState.Freigabe) return "color: green;"
    if (!presence.presenceState) return "";
  }
  /**Status anzeigen den die Präsenz momentan hat
   * alles ausser gelöscht
   */
  getStatus(presence: IMediaPresence): string {
    if (!presence.presenceState) return "unbekannt";
    try {
      return MediaPresenceState[presence.presenceState].replace("_", " ");
    } catch (e) {
      this.store.dispatch(new LogUsersClientErrorAction({ error: <IClientError>{ userid: this.user.id, modul: 'Novilist', error: e } }));
      return MediaPresenceState[presence.presenceState];
    }
  }


  setMediaPresenceStateFreigeben(presence: IMediaPresence, index: number) {
    presence.presenceState = MediaPresenceState.Freigabe;
  }

  setMediaPresenceStateVerwerfen(presence: IMediaPresence, index) {
    presence.presenceState = MediaPresenceState.Verworfen;
  }

  setLiPoContact(contact: LiPoContact) {
    console.log('setLiPoContact:', event);
    this.mediatip.targetUserName = contact.friendlyName;
    this.mediatip.targetUserId = contact.id;
  }

  getLiPoContact(mediatip: IMediaTip) {
    this.selectedLiPoContact = <LiPoContact>
      {
        id: mediatip.targetUserId,
        friendlyName: mediatip.targetUserName
      };
  }

  saveAndSubmit() {
    this.save(true);
  }

  canSaveandSubmit(): boolean {
    if (!this.mediatip) return false;

    if (this.mediatip.mediaPresences == undefined) return false;
    if (this.mediatip.mediaPresences.length == 0) return false;
    for (let index = 0; index < this.mediatip.mediaPresences.length; index++) {
      const element = this.mediatip.mediaPresences[index];
      if (this.getPresenceError(element).length > 0) return false;
      if (!this.canAction(element, 'freigeben')) return false;
    }
    return this.canSave();
  }

  canSave(): boolean {
    if (!this.mediatip) return false;
    if (this.getEanError().length > 0) return false;
    if (this.mediatip.bubData == undefined || this.mediatip.bubData.ean == undefined) return false;

    if (this.mediatip.mediaPresences) // können noch leer sein
    {
      for (let index = 0; index < this.mediatip.mediaPresences.length; index++) {
        const element = this.mediatip.mediaPresences[index];
        if (this.getPresenceError(element).length > 0) return false;
      }
    }
    return this.mediatip != undefined && (this.mediatip.targetUserId != undefined && this.mediatip.targetUserId.length > 0);
  }


  save(submit: boolean) {
    if (submit) {

      if (this.isManager) {
        this.mediatip.mediatipState = MediatipState.Released;
      }
      else {
        this.mediatip.sendDat = new Date();
        this.mediatip.mediatipState = MediatipState.Verschickt;
      }
    } else {
      if (this.mediatip.id === 0) {

        this.mediatip.mediatipState = MediatipState.In_Bearbeitung;
      }
    }

    if (this.mediatip.id === 0) {
      this.mediatip.createDat = new Date();
      this.mediatip.creatingUserName = this.user.name;
      this.mediatip.creatingUserId = this.user.id;
      this.mediatip.ean = this.transformEan(this.mediatip.ean); // ean korrigieren
      this.store.dispatch(new InsertMediatipAction({ newmediatip: this.mediatip, isManager: this.isManager }));
      this.ref.close();
    }
    else {
      this.store.dispatch(new UpdateMediatipAction({ mediatip: this.mediatip, removefromStore: false }));
      this.ref.close();
    }
  }

  remarkreset() {
    if (this.mediatip && this.mediatip.remark) {
      this.mediatip.remark = null;
    }
  }

  eanreset() {
    if (this.mediatip && this.mediatip.ean) {
      this.mediatip.ean = null;
      this.mediatip.bubData = <IBubData>{};
    }
  }
  cancel() {
    this.ref.close();
  }

  /**Ean Tranformieren der erlaubten Zeichen
   * nicht numerisch !
   */
  arraycharacter = ["-", "-", "—", "/n", "/r", " "];
  transformEan(ean: string): string {
    /**alle Arten von Bindestriche und andere sind erlaubt und werden aber entfernt */
    if (!ean) return "";
    if (ean.length == 0) return "";
    for (let index = 0; index < this.arraycharacter.length; index++) {
      const element = this.arraycharacter[index];
      while (ean.indexOf(element) > -1) {
        ean = ean.replace(element, "");
      }
    }
    return ean;
  }

  /**Ean genauer untersuchen auf falsche Zeichen*/
  getEanError(): string {
    var ean = this.transformEan(this.mediatip.ean);
    if (ean.length > 17) return "EAN hat falsche Länge";
    for (let index = 0; index < ean.length; index++) {
      const element = ean[index];
      var testNumber = Number(element);
      if (testNumber == undefined || Number.isNaN(testNumber) || testNumber < 0) {
        return "EAN muss numerisch sein. (" + element + ")";
      }
    }
    return "";
  }


  checkean() {
    if (this.mediatip && this.mediatip.ean) {
      if (this.getEanError().length == 0) {
        var ean = this.transformEan(this.mediatip.ean);
        if (ean.length == 13) {
          this.getBubData(ean);
        }
      }
    }
  }

  /**Die max Länge ean hängt von den Strichen dazwischen ab */
  GetMaxLength(ean: string): number {
    if (!ean) return 20;
    let countStrich = ean.split("-").length - 1;
    if (countStrich > 0) return (13 + countStrich);
    return 20;
  }

  getBubData(ean: string) {
    // try
    this.store.dispatch(new GetMediatipBubDataAction({ ean: ean }));
  }


  addEmptyMediaPresence(table: Table) {
    console.log('AddEmptyMedaitPresence to ', this.mediatip.mediaPresences);
    console.log('table ', table);

    const mediaPref: IMediaPresence = { id: this.newRowId--, mediatip_id: this.mediatip.id, presence: null, presenceType: null, presenceState: MediaPresenceState.Nicht_begonnen };
    if (!this.mediatip.mediaPresences) {
      this.mediatip.mediaPresences = [];
    }
    this.mediatip.mediaPresences.push(mediaPref);
    table.initRowEdit(mediaPref);
  }

  deleteMediaPresence(presence: IMediaPresence, index) {
    console.log("Delete: ", presence);
    this.mediatip.mediaPresences.splice(index, 1);
  }

  onRowEditInit(presence: IMediaPresence) {
    this.clonedPresences[presence.id] = { ...presence };

  }


  onRowEditSave(presence: IMediaPresence) {
    if (presence.presence) {
      delete this.clonedPresences[presence.id];
      //this.messageService.add({severity:'success', summary: 'Success', detail:'Car is updated'});
    }
    else {
      //this.messageService.add({severity:'error', summary: 'Error', detail:'Year is required'});
    }
  }

  onRowEditCancel(presence: IMediaPresence, index: number) {
    this.mediatip.mediaPresences[index] = this.clonedPresences[presence.id];
    delete this.clonedPresences[presence.id];
  }
}
