/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfLagerDto } from '../models/list-response-args-of-lager-dto';
import { ExtendedQueryTokenDTO } from '../models/extended-query-token-dto';
import { ResponseArgsOfLagerDto } from '../models/response-args-of-lager-dto';
import { LagerDto } from '../models/lager-dto';
import { ResponseArgsOfBoolean } from '../models/response-args-of-boolean';
@Injectable({
  providedIn: 'root',
})
class LagerService extends __BaseService {
  static readonly LagerQueryLagerPath = '/Lager/s';
  static readonly LagerCreateLagerPath = '/Lager';
  static readonly LagerUpdateLagerPath = '/Lager/{id}';
  static readonly LagerDeleteLagerPath = '/Lager/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Lager Liste
   * @param params The `LagerService.LagerQueryLagerParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `eagerLoading`:
   */
  LagerQueryLagerResponse(params: LagerService.LagerQueryLagerParams): __Observable<__StrictHttpResponse<ListResponseArgsOfLagerDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Lager/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfLagerDto>;
      })
    );
  }
  /**
   * Lager Liste
   * @param params The `LagerService.LagerQueryLagerParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `eagerLoading`:
   */
  LagerQueryLager(params: LagerService.LagerQueryLagerParams): __Observable<ListResponseArgsOfLagerDto> {
    return this.LagerQueryLagerResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfLagerDto)
    );
  }

  /**
   * Create
   * @param params The `LagerService.LagerCreateLagerParams` containing the following parameters:
   *
   * - `payload`: LagerDto
   *
   * - `user`: User Id oder Token
   */
  LagerCreateLagerResponse(params: LagerService.LagerCreateLagerParams): __Observable<__StrictHttpResponse<ResponseArgsOfLagerDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Lager`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfLagerDto>;
      })
    );
  }
  /**
   * Create
   * @param params The `LagerService.LagerCreateLagerParams` containing the following parameters:
   *
   * - `payload`: LagerDto
   *
   * - `user`: User Id oder Token
   */
  LagerCreateLager(params: LagerService.LagerCreateLagerParams): __Observable<ResponseArgsOfLagerDto> {
    return this.LagerCreateLagerResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfLagerDto)
    );
  }

  /**
   * Update
   * @param params The `LagerService.LagerUpdateLagerParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `id`:
   *
   * - `user`:
   */
  LagerUpdateLagerResponse(params: LagerService.LagerUpdateLagerParams): __Observable<__StrictHttpResponse<ResponseArgsOfLagerDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/Lager/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfLagerDto>;
      })
    );
  }
  /**
   * Update
   * @param params The `LagerService.LagerUpdateLagerParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `id`:
   *
   * - `user`:
   */
  LagerUpdateLager(params: LagerService.LagerUpdateLagerParams): __Observable<ResponseArgsOfLagerDto> {
    return this.LagerUpdateLagerResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfLagerDto)
    );
  }

  /**
   * Delete
   * @param params The `LagerService.LagerDeleteLagerParams` containing the following parameters:
   *
   * - `id`: Lager Id
   *
   * - `user`: User Id oder Token
   */
  LagerDeleteLagerResponse(params: LagerService.LagerDeleteLagerParams): __Observable<__StrictHttpResponse<ResponseArgsOfBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/Lager/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBoolean>;
      })
    );
  }
  /**
   * Delete
   * @param params The `LagerService.LagerDeleteLagerParams` containing the following parameters:
   *
   * - `id`: Lager Id
   *
   * - `user`: User Id oder Token
   */
  LagerDeleteLager(params: LagerService.LagerDeleteLagerParams): __Observable<ResponseArgsOfBoolean> {
    return this.LagerDeleteLagerResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfBoolean)
    );
  }
}

module LagerService {

  /**
   * Parameters for LagerQueryLager
   */
  export interface LagerQueryLagerParams {
    queryToken: ExtendedQueryTokenDTO;
    eagerLoading?: number;
  }

  /**
   * Parameters for LagerCreateLager
   */
  export interface LagerCreateLagerParams {

    /**
     * LagerDto
     */
    payload: LagerDto;

    /**
     * User Id oder Token
     */
    user?: null | string;
  }

  /**
   * Parameters for LagerUpdateLager
   */
  export interface LagerUpdateLagerParams {
    payload: LagerDto;
    id: number;
    user?: null | string;
  }

  /**
   * Parameters for LagerDeleteLager
   */
  export interface LagerDeleteLagerParams {

    /**
     * Lager Id
     */
    id: number;

    /**
     * User Id oder Token
     */
    user?: null | string;
  }
}

export { LagerService }
