/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfEinkaufDto } from '../models/list-response-args-of-einkauf-dto';
import { ExtendedQueryTokenDTO } from '../models/extended-query-token-dto';
import { ResponseArgsOfEinkaufDto } from '../models/response-args-of-einkauf-dto';
import { EinkaufDto } from '../models/einkauf-dto';
import { ResponseArgsOfBoolean } from '../models/response-args-of-boolean';
@Injectable({
  providedIn: 'root',
})
class EinkaufService extends __BaseService {
  static readonly EinkaufQueryEinkaufPath = '/einkauf/s';
  static readonly EinkaufCreateEinkaufPath = '/einkauf';
  static readonly EinkaufUpdateEinkaufPath = '/einkauf/{id}';
  static readonly EinkaufDeleteEinkaufPath = '/einkauf/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Einkauf
   * @param params The `EinkaufService.EinkaufQueryEinkaufParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `eagerLoading`:
   */
  EinkaufQueryEinkaufResponse(params: EinkaufService.EinkaufQueryEinkaufParams): __Observable<__StrictHttpResponse<ListResponseArgsOfEinkaufDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/einkauf/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfEinkaufDto>;
      })
    );
  }
  /**
   * Einkauf
   * @param params The `EinkaufService.EinkaufQueryEinkaufParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `eagerLoading`:
   */
  EinkaufQueryEinkauf(params: EinkaufService.EinkaufQueryEinkaufParams): __Observable<ListResponseArgsOfEinkaufDto> {
    return this.EinkaufQueryEinkaufResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfEinkaufDto)
    );
  }

  /**
   * Create
   * @param params The `EinkaufService.EinkaufCreateEinkaufParams` containing the following parameters:
   *
   * - `payload`: EinkaufDto
   *
   * - `user`: User Id oder Token
   */
  EinkaufCreateEinkaufResponse(params: EinkaufService.EinkaufCreateEinkaufParams): __Observable<__StrictHttpResponse<ResponseArgsOfEinkaufDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/einkauf`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfEinkaufDto>;
      })
    );
  }
  /**
   * Create
   * @param params The `EinkaufService.EinkaufCreateEinkaufParams` containing the following parameters:
   *
   * - `payload`: EinkaufDto
   *
   * - `user`: User Id oder Token
   */
  EinkaufCreateEinkauf(params: EinkaufService.EinkaufCreateEinkaufParams): __Observable<ResponseArgsOfEinkaufDto> {
    return this.EinkaufCreateEinkaufResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfEinkaufDto)
    );
  }

  /**
   * Update
   * @param params The `EinkaufService.EinkaufUpdateEinkaufParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `id`:
   *
   * - `user`:
   */
  EinkaufUpdateEinkaufResponse(params: EinkaufService.EinkaufUpdateEinkaufParams): __Observable<__StrictHttpResponse<ResponseArgsOfEinkaufDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/einkauf/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfEinkaufDto>;
      })
    );
  }
  /**
   * Update
   * @param params The `EinkaufService.EinkaufUpdateEinkaufParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `id`:
   *
   * - `user`:
   */
  EinkaufUpdateEinkauf(params: EinkaufService.EinkaufUpdateEinkaufParams): __Observable<ResponseArgsOfEinkaufDto> {
    return this.EinkaufUpdateEinkaufResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfEinkaufDto)
    );
  }

  /**
   * Delete
   * @param params The `EinkaufService.EinkaufDeleteEinkaufParams` containing the following parameters:
   *
   * - `id`: Saison Id
   *
   * - `user`: User Id oder Token
   */
  EinkaufDeleteEinkaufResponse(params: EinkaufService.EinkaufDeleteEinkaufParams): __Observable<__StrictHttpResponse<ResponseArgsOfBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/einkauf/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBoolean>;
      })
    );
  }
  /**
   * Delete
   * @param params The `EinkaufService.EinkaufDeleteEinkaufParams` containing the following parameters:
   *
   * - `id`: Saison Id
   *
   * - `user`: User Id oder Token
   */
  EinkaufDeleteEinkauf(params: EinkaufService.EinkaufDeleteEinkaufParams): __Observable<ResponseArgsOfBoolean> {
    return this.EinkaufDeleteEinkaufResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfBoolean)
    );
  }
}

module EinkaufService {

  /**
   * Parameters for EinkaufQueryEinkauf
   */
  export interface EinkaufQueryEinkaufParams {
    queryToken: ExtendedQueryTokenDTO;
    eagerLoading?: number;
  }

  /**
   * Parameters for EinkaufCreateEinkauf
   */
  export interface EinkaufCreateEinkaufParams {

    /**
     * EinkaufDto
     */
    payload: EinkaufDto;

    /**
     * User Id oder Token
     */
    user?: null | string;
  }

  /**
   * Parameters for EinkaufUpdateEinkauf
   */
  export interface EinkaufUpdateEinkaufParams {
    payload: EinkaufDto;
    id: number;
    user?: null | string;
  }

  /**
   * Parameters for EinkaufDeleteEinkauf
   */
  export interface EinkaufDeleteEinkaufParams {

    /**
     * Saison Id
     */
    id: number;

    /**
     * User Id oder Token
     */
    user?: null | string;
  }
}

export { EinkaufService }
