import { TaskUserModel } from './task-user.model';
import { UploadRowModel } from './uploadrow.model';
import { UploadUserModel } from './uploaduser.model';

export interface UploadStagingModel {
  id: number;
  createDate: Date;
  changeDate: Date;
  uploadComment: string;
  uploadingUser: UploadUserModel;
  stagingDetails: UploadRowModel[];
  uploadState: number;
  maximum: number;
  minimum: number;
  attributeText: string;
  attributeValidation: string;
  usedValidationListName: string;
  isReadOnly: number;
  isReadOnlyMessage: string;
  isEditExpired: number;
  isEditExpiredMessage: string;
}



/**für Insert als Parameter */
export interface UploadStaging {
  id: number;
  parentId: number;
  uploadParentTypes: number;
  usedValidationListId: number;
  createDate: Date;
  changeDate: Date;
  uploadingUserId: number;
  uploadComment: string;
  uploadState: number;
  isDeleted: boolean;
  name: string;

  stagingDetails: Array<any>;

}
