import { TaskListState, InitialTaskListState } from './state';
import {
  TaskListActionType, TaskListActions, GetTasksDoneAction, GetTasksForCurrentUserAction,
  CreateSupplierTasksDoneAction, GetValidationsDoneAction,
  CreateMasterTaskDoneAction, UpdateTaskDoneAction, DeleteUploadDoneAction, DuplicateMasterDoneAction, DuplicateSupplierDoneAction, ApproveTaskDoneAction,
  SelectAllCategoriesAction,
  AddSpecificCategoryAction, DeleteTaskDoneAction, SetSingleTaskStateDoneAction, GetSuppliergroupsDoneAction, GetSuppliersForGroupDoneAction, GetMandantenActionDone,
  CreateMandantenTasksActionDone,
  GetLieferantenUserActionDone
} from './actions';
import { TaskStructureModel } from '@app/common/models/taskStructure.model';
import * as cloneDeep from 'lodash/fp/cloneDeep';
import { SimpleResult } from '@app/common/models/simpleResult.model';
import { TaskUserModel } from '@app/common/models';

export function reducer(state: TaskListState = InitialTaskListState, action: TaskListActions): TaskListState {
  switch (action.type) {

    case TaskListActionType.ClearTaskErrorAktion:
      return {
        ...state,
        taskerror: null
      }
    case TaskListActionType.TasklistErrorAction:

      return {
        ...state,
        taskerror: SimpleResult.getSimpleResult(action.payload.error),
      }

    case TaskListActionType.GetTaskUsersAktion:
      return {
        ...state,
        taskSupplierIds: [],
        loadingSupplierIds: true
      }
    case TaskListActionType.GetTaskUsersAktionDone:
      return {
        ...state,
        taskSupplierIds: action.payload.result.data,
        loadingSupplierIds: false
      }

    case TaskListActionType.GetTaskbyIdAction:
      return {
        ...state,
        loading: true
      }
    case TaskListActionType.GetTaskbyIdActionDone:
      return {
        ...state,
        singleTask: action.payload.task,
        loading: false
      }

    case TaskListActionType.GetTasksDone:
      return GetTasksDoneReducer(state, action);
    case TaskListActionType.CreateUpload:
      return StartLoading(state);
    case TaskListActionType.GetTasksForCurrentUser:
      return LoadForUser(state, action);

    case TaskListActionType.GetMandantenActionDone:
      return MandantenTasksDone(state, action);
    case TaskListActionType.GetLieferantenUserActionDone:
      return LieferantenTasksDone(state, action);
    case TaskListActionType.CreateMandantenTasksActionDone:
      return MandantenTasksCreated(state, action);
    case TaskListActionType.CreateSupplierTasksDone:
      return SupplierTasksCreated(state, action);
    case TaskListActionType.GetValidationsDone:
      return ValidationsLoaded(state, action);
    case TaskListActionType.CreateMasterTaskDone:
      return CreateMasterTaskDone(state, action);
    case TaskListActionType.UpdateTaskDone:
      return UpdateTaskDone(state, action);
    case TaskListActionType.DeleteUploadDone:
      return DeleteUploadDone(state, action);
    case TaskListActionType.DuplicateMasterDone:
      return DuplicateMasterDone(state, action);
    case TaskListActionType.DuplicateSupplierDone:
      return DuplicateSupplierDone(state, action);
    case TaskListActionType.DeleteTaskDone:
      return DeleteTaskDone(state, action);
    case TaskListActionType.SetSingleTaskStateActionDone:
      return SingleTaskStateActionDone(state, action);
    case TaskListActionType.ApproveTaskDone:
      return ApproveTask(state, action);
    case TaskListActionType.SelectAllCategories:
      return SelectAllCategories(state, action);
    case TaskListActionType.AddSpecificCategory:
      return AddSpecificCategory(state, action);
    case TaskListActionType.GetSuppliergroupsDone:
      return getSuppliergroupsDoneAction(state, action);
    case TaskListActionType.GetSuppliersForGroupDone:
      return getSuppliersForGroupDone(state, action);

  }

  return state;
}

export function SelectAllCategories(state: TaskListState, action: SelectAllCategoriesAction): TaskListState {
  let cats: string[] = [];
  cats.push('all');
  return {
    ...state,
    selectedCategories: cats
  }
}

export function AddSpecificCategory(state: TaskListState, action: AddSpecificCategoryAction): TaskListState {
  let cats = cloneDeep(state.selectedCategories);
  const idx = cats.indexOf('all');
  if (idx >= 0) {
    cats = [...cats.slice(0, idx), ...cats.slice(idx + 1)];
  }

  return {
    ...state,
    selectedCategories: cats
  }
}

export function GetTasksDoneReducer(state: TaskListState, action: GetTasksDoneAction): TaskListState {


  return {
    ...state,
    tasks: action.payload.item,
    total: action.payload.item && action.payload.item.length > 0 ? action.payload.item[0].data.hits : 0,
    loading: false
  };
}



export function LieferantenTasksDone(state: TaskListState, action: GetLieferantenUserActionDone) {
  return {
    ...state,
    lieferantenuser: action.payload.lieferanten.data,
    loadingSupplierIds: false
  }
}


export function MandantenTasksDone(state: TaskListState, action: GetMandantenActionDone) {
  return {
    ...state,
    mandanten: action.payload.mandanten.data,

  }
}



export function StartLoading(state: TaskListState) {
  return {
    ...state,
    loading: true
  };
}

export function LoadForUser(state: TaskListState, action: GetTasksForCurrentUserAction) {
  return {
    ...state,
    activityMode: false,
    lastlisttaskparmeter: action.payload.params,
    loading: true
  };
}


export function MandantenTasksCreated(state: TaskListState, action: CreateMandantenTasksActionDone): TaskListState {
  const idx = state.tasks.findIndex(x => x.data.id == parseFloat(action.payload.parentId));
  let task = cloneDeep(state.tasks.find(x => x.data.id == parseFloat(action.payload.parentId)));
  let newTasks: TaskStructureModel<TaskUserModel>[] = [];
  var tasks = cloneDeep(action.payload.taskModels);
  tasks.forEach(x => {
    x.taskState = 100;
    let newModel: TaskStructureModel<TaskUserModel> = { data: x, children: null };
    task.children.push(newModel);
  });
  return {
    ...state,
    tasks: [...state.tasks.slice(0, idx), task, ...state.tasks.slice(idx + 1)]
  }
}


export function SupplierTasksCreated(state: TaskListState, action: CreateSupplierTasksDoneAction): TaskListState {
  const idx = state.tasks.findIndex(x => x.data.id == parseFloat(action.payload.parentId));
  let task = cloneDeep(state.tasks.find(x => x.data.id == parseFloat(action.payload.parentId)));
  let newTasks: TaskStructureModel<TaskUserModel>[] = [];
  var tasks = cloneDeep(action.payload.taskModels);
  tasks.forEach(x => {
    x.taskState = 100;
    let newModel: TaskStructureModel<TaskUserModel> = { data: x, children: null };
    task.children.push(newModel);
  });
  return {
    ...state,
    tasks: [...state.tasks.slice(0, idx), task, ...state.tasks.slice(idx + 1)]
  }
}

export function SingleTaskStateActionDone(state: TaskListState, action: SetSingleTaskStateDoneAction): TaskListState {
  if (action.payload.taskId < 0) return;
  var cloned = cloneDeep(state.tasks) as TaskStructureModel<TaskUserModel>[];
  cloned.forEach(par => {
    if (par.data && par.data.id == action.payload.taskId) {
      par.data.taskState = action.payload.taskState;
    }
    if (par.children) {
      par.children.forEach(c => {
        if (c.data && c.data.id == action.payload.taskId) {
          c.data.taskState = action.payload.taskState;
        }
      })
    }


  });


  return {
    ...state,
    tasks: cloned,
  }

}
export function ApproveTask(state: TaskListState, action: ApproveTaskDoneAction): TaskListState {
  if (action.payload.taskId < 0) return;
  const idx = state.tasks.findIndex(x => x.data.id == action.payload.taskId);
  let taskModel: TaskStructureModel<TaskUserModel>;
  if (idx > -1) {
    taskModel = cloneDeep(state.tasks[idx]);
  }
  else {
    return;
  }
  taskModel.data.taskState = 200;
  taskModel.children.filter(f => f.data.taskState == 100).forEach(x => x.data.taskState = 200);
  return {
    ...state,
    tasks: [...state.tasks.slice(0, idx), taskModel, ...state.tasks.slice(idx + 1)]
  }
}

export function ValidationsLoaded(state: TaskListState, action: GetValidationsDoneAction): TaskListState {
  console.log('Validations Done Reducer reached');
  return {
    ...state,
    validationProfiles: action.payload.validations
  }
}





export function CreateMasterTaskDone(state: TaskListState, action: CreateMasterTaskDoneAction): TaskListState {
  let addList: TaskStructureModel<TaskUserModel>[] = [];
  if (action.payload.item != undefined && action.payload.item.data != undefined) {
    action.payload.item.data.forEach(x => {
      addList.push({ data: x, children: [], leaf: false, expanded: false });
    });
  }
  return {
    ...state,
    tasks: [...addList, ...state.tasks],
    loading: false,
  };
}

export function UpdateTaskDone(state: TaskListState, action: UpdateTaskDoneAction): TaskListState {
  let idx: number;
  let existingList = cloneDeep(state.tasks);
  for (let index = 0; index < action.payload.items.length; index++) {
    const item = action.payload.items[index];
    for (let i = 0; i < existingList.length; i++) {
      // first of all check data
      if (existingList[i].data.id == item.id) {
        existingList[i].data = item;
      }
      //then check the children
      if (existingList[i].children != undefined && existingList[i].children != null) {
        for (let x = 0; x < existingList[i].children.length; x++) {
          if (existingList[i].children[x].data.id == item.id) {
            existingList[i].children[x].data = item;
          }
        }
      }
    }
  }
  return {
    ...state,
    loading: false,
    tasks: existingList
  };
}

export function DeleteUploadDone(state: TaskListState, action: DeleteUploadDoneAction): TaskListState {
  let existingList = cloneDeep(state.tasks);
  for (let i = 0; i < existingList.length; i++) {
    const idx = existingList[i].data.uploadFiles.files.findIndex(x => x.id == action.payload.id);
    if (idx > -1) {
      let actFiles = existingList[i].data.uploadFiles.files;
      actFiles = [...actFiles.filter(t => t.id != actFiles[idx].id)];
      existingList[i].data.uploadFiles.files = actFiles;
      break;
    } else {
      if (existingList[i].children != undefined && existingList[i].children != null) {
        for (let x = 0; x < existingList[i].children.length; x++) {
          const idx = existingList[i].children[x].data.uploadFiles.files.findIndex(x => x.id == action.payload.id);
          if (idx > -1) {
            let actFiles = existingList[i].children[x].data.uploadFiles.files;
            actFiles = [...actFiles.filter(t => t.id != actFiles[idx].id)];
            existingList[i].children[x].data.uploadFiles.files = actFiles;
            break;
          }
        }
      }
    }
  }
  return {
    ...state,
    tasks: existingList
  }
}

export function DuplicateMasterDone(state: TaskListState, action: DuplicateMasterDoneAction): TaskListState {

  const idx = state.tasks.findIndex(x => x.data.id == action.payload.oldtaskId);
  return {
    ...state,
    tasks: [...state.tasks.slice(0, idx + 1), action.payload.item, ...state.tasks.slice(idx + 1)]
  }
}

export function DuplicateSupplierDone(state: TaskListState, action: DuplicateSupplierDoneAction): TaskListState {
  let existingList = cloneDeep(state.tasks) as TaskStructureModel<TaskUserModel>[];
  const idx = existingList.findIndex(x => x.data.id == action.payload.item.taskParentId);
  let tsm: TaskStructureModel<TaskUserModel> = {};
  tsm.data = action.payload.item;
  existingList[idx].children.push(tsm);
  return {
    ...state,
    tasks: existingList
  }
}

export function DeleteTaskDone(state: TaskListState, action: DeleteTaskDoneAction): TaskListState {
  let existingList = cloneDeep(state.tasks) as TaskStructureModel<TaskUserModel>[];
  const idx = existingList.findIndex(x => x.data.id == action.payload.taskId);
  if (idx > -1) {
    existingList = [...existingList.slice(0, idx), ...existingList.slice(idx + 1)];
  } else {
    for (let i = 0; i < existingList.length; i++) {
      const idx = existingList[i].children.findIndex(x => x.data.id == action.payload.taskId)
      if (idx > -1) {
        existingList[i].children = [...existingList[i].children.slice(0, idx), ...existingList[i].children.slice(idx + 1)];
      }
    }
  }

  return {
    ...state,
    tasks: existingList
  }
}

export function getSuppliergroupsDoneAction(state: TaskListState, action: GetSuppliergroupsDoneAction): TaskListState {

  return {
    ...state,
    suppliergroups: action.payload.groups
  };

}

export function getSuppliersForGroupDone(state: TaskListState, action: GetSuppliersForGroupDoneAction): TaskListState {

  return {
    ...state,
    suppliersForGroup: action.payload.suppliers
  };

}
