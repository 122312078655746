import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store, select } from '@ngrx/store';



import { selectallNotifications, selectNotificationsbyType } from './store/selectors';

import { MessageTypeEnum, SendMessageTypeContainer } from '@app/common/models/signal.model';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavMenuItem } from '@app/common/models';
import { selectNavMenues } from '@app/store/navigation/selectors';
import { Router } from '@angular/router';
import { DeleteAllMessagesAction, RemoveNotificationAction } from './store';
import { AuthService } from '@app/common/services/auth.service';

export class Dashboardnotifictaion extends NavMenuItem {
  message: string;
  queryUrl: string;
  queryParams: any;
}


@Component({
  selector: 'app-notifications',
  templateUrl: 'notifications.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class NotificationsComponent implements OnInit {

  notifications$ = this.store.pipe(select(selectallNotifications));
  navitemsBase$ = this.store.select(selectNavMenues)

  constructor(private store: Store<any>, private router: Router, private authService: AuthService,) {

  }

  /**Motifications entfernen */
  clearNotifications (e, notification: Dashboardnotifictaion): void
  {
    if ( notification.messageTypes == undefined) return;
    notification.messageTypes.forEach(b=>
      {
          this.authService.getLipoUser().subscribe(user=>
            {
              this.store.dispatch(new DeleteAllMessagesAction({ targetUserId: user.id, messageType: b}));
              this.notifications$.subscribe(noti=>
                {
                  var typnotis = noti.filter(f=> f.messageType == b);
                  typnotis.forEach(g=>
                    {
                      this.store.dispatch(new RemoveNotificationAction({ signal: g }))
                    })
                })

            }).unsubscribe()
      })
  }

  /**Link aufrufen, der die Notifications direkt im Detail anzeigt */
  gotoNotifications(e, notification: Dashboardnotifictaion): void {
    if (notification.queryUrl) {
      this.router.navigate([notification.queryUrl], { queryParams: notification.queryParams });
    } else {
      this.router.navigate([notification.routerlink]);
    }
  }

    /**Manchen könne nhier entfernt werden
     * #NP26
     */
  canClear(n: Dashboardnotifictaion) : boolean
  {
    if ( n.messageTypes == undefined) return false;
    return  ( n.messageTypes.find(f=> f == MessageTypeEnum.Aenderungshistorie) != undefined );
  }



  gotoMenueItem(e, notification: Dashboardnotifictaion): void {
    this.router.navigate([notification.routerlink]);
  }

  /**Alle Notifications die so kommen */
  getAllNotification(): Observable<Dashboardnotifictaion[]> {
    return combineLatest([this.navitemsBase$, this.notifications$]).pipe(map(([n, m]) => {
      if (m == undefined || m.length == 0) return [];
      var array = new Array<Dashboardnotifictaion>();
      m = [...m].sort((a, b) => (a.messages.length > 0 && b.messages.length > 0 && new Date(a.messages[0].sendDate) > new Date(b.messages[0].sendDate) ? -1 : 1)); // absteigend
      var messagesTypen = new Set(m.map(f => f.messageType));

      messagesTypen.forEach(messagetype => {
        var navigation = n.filter(t => t.messageTypes && t.messageTypes.filter(f => f == messagetype).length > 0);
        if (navigation.length == 0) {
          n.filter(f => f.children && f.children.length > 0).forEach(par => {
            if (navigation.length == 0) {
              navigation = par.children.filter(t => t.messageTypes && t.messageTypes.filter(f => f == messagetype).length > 0);
            }
          })
        }
        if (navigation.length > 0) {
          var item = { ...navigation[0] } as Dashboardnotifictaion;

          var messages = [...new Set ( m.filter(t => t.messageType && t.messageType == messagetype).map(g=> g.parentId))];
          switch (messagetype) {
            case MessageTypeEnum.Sortiment:
              item.message = messages.length + (messages.length > 1 ? " Neue Sortimente" : " Neues Sortiment");
              item.queryUrl = "/sortimentieren/sortimentsmatrix";
              item.queryParams = { tab: 0, notifications: 1, notificationtype: MessageTypeEnum.Sortiment };
              break;
            case MessageTypeEnum.ModulSortiment:
              item.message = messages.length + (messages.length > 1 ? " Neue Module Sortiment" : " Neues Modul Sortiment");
              item.queryUrl = "/filialmatrix/module";
              item.queryParams = { matrixComponentId: 41, notifications: 1, notificationtype: MessageTypeEnum.ModulSortiment };
              break;
            case MessageTypeEnum.Modulzuordnung:
              item.message = messages.length + (messages.length > 1 ? " Neue Module Zuordnung" : " Neues Modul Zuordnung");
              item.queryUrl = "/filialmatrix/module";
              item.queryParams = { matrixComponentId: 41, notifications: 1, notificationtype: MessageTypeEnum.Modulzuordnung };
              break;
            case MessageTypeEnum.Aenderungshistorie:
              item.message = messages.length + (messages.length > 1 ? " Änderungen in Novitäten" : " Änderung Novität");
              item.queryUrl = "/novi";
              item.queryParams = { notifications: 1 };
              break;

            default:
              item.message = messages.length + " " + this.getMessageTypeEnumText(messagetype);
              item.queryUrl = null;
              item.queryParams = null;
          }

          array.push(item);
        } else {
          console.debug("Navigationsbaum / Menue enthält keine Zuordnung zu MessageType =>" + this.getMessageTypeEnumText(messagetype));
        }
      })

      return array;
    }
    ))
  }


  private getMessageTypeEnumText(key: number): string {
    var idx = Object.values(MessageTypeEnum).findIndex(g => g == key);
    return Object.keys(MessageTypeEnum)[idx];
  }

  getNotification(typemess: MessageTypeEnum): Observable<SendMessageTypeContainer<any>[]> {
    return this.store.pipe(select(selectNotificationsbyType(typemess)));
  }

  ngOnInit() { }

}
