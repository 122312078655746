import { LazyLoadEvent, SortMeta } from "primeng/api";


export class StoreColumnSetting implements LazyLoadEvent {
  columnOrder: string[] = [];
  columnWidths: string = "";
  first: number = 0;
  rows: number = 20;
  multiSortMeta?: SortMeta[];
  tableWidth: string;
}


export class DataColumnConfigSuccess
{
  data: any;
  type:DataColumnConfigSuccessEnum;
}

  export enum DataColumnConfigSuccessEnum
  {
    unbekannt = 0,
    gespeichert = 1 ,
    gelöscht = 2,
    erstellt = 3
  }
