/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfKlammerDto } from '../models/list-response-args-of-klammer-dto';
import { QueryTokenDTO } from '../models/query-token-dto';
import { ResponseArgsOfKlammerDto } from '../models/response-args-of-klammer-dto';
import { KlammerDto } from '../models/klammer-dto';
@Injectable({
  providedIn: 'root',
})
class KlammerService extends __BaseService {
  static readonly KlammerQueryKlammernPath = '/klammer/s';
  static readonly KlammerDeleteKlammerPath = '/klammer/{id}';
  static readonly KlammerCreateKlammerPath = '/klammer';
  static readonly KlammerUpdateKlammernPath = '/klammer';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Sortimente
   * @param params The `KlammerService.KlammerQueryKlammernParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `eagerLoading`:
   */
  KlammerQueryKlammernResponse(params: KlammerService.KlammerQueryKlammernParams): __Observable<__StrictHttpResponse<ListResponseArgsOfKlammerDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/klammer/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfKlammerDto>;
      })
    );
  }
  /**
   * Sortimente
   * @param params The `KlammerService.KlammerQueryKlammernParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `eagerLoading`:
   */
  KlammerQueryKlammern(params: KlammerService.KlammerQueryKlammernParams): __Observable<ListResponseArgsOfKlammerDto> {
    return this.KlammerQueryKlammernResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfKlammerDto)
    );
  }

  /**
   * Klammer Löschen
   * @param id undefined
   */
  KlammerDeleteKlammerResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/klammer/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Klammer Löschen
   * @param id undefined
   */
  KlammerDeleteKlammer(id: number): __Observable<null> {
    return this.KlammerDeleteKlammerResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Neue Klammer
   * @param payload undefined
   */
  KlammerCreateKlammerResponse(payload: KlammerDto): __Observable<__StrictHttpResponse<ResponseArgsOfKlammerDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/klammer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfKlammerDto>;
      })
    );
  }
  /**
   * Neue Klammer
   * @param payload undefined
   */
  KlammerCreateKlammer(payload: KlammerDto): __Observable<ResponseArgsOfKlammerDto> {
    return this.KlammerCreateKlammerResponse(payload).pipe(
      __map(_r => _r.body as ResponseArgsOfKlammerDto)
    );
  }

  /**
   * Update Klammer
   * @param payload undefined
   */
  KlammerUpdateKlammernResponse(payload: Array<KlammerDto>): __Observable<__StrictHttpResponse<ListResponseArgsOfKlammerDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/klammer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfKlammerDto>;
      })
    );
  }
  /**
   * Update Klammer
   * @param payload undefined
   */
  KlammerUpdateKlammern(payload: Array<KlammerDto>): __Observable<ListResponseArgsOfKlammerDto> {
    return this.KlammerUpdateKlammernResponse(payload).pipe(
      __map(_r => _r.body as ListResponseArgsOfKlammerDto)
    );
  }
}

module KlammerService {

  /**
   * Parameters for KlammerQueryKlammern
   */
  export interface KlammerQueryKlammernParams {
    queryToken: QueryTokenDTO;
    eagerLoading?: number;
  }
}

export { KlammerService }
