import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TaskUserModel, ActionStates, GeneralResultEnum } from '@app/common/models';
import { map, filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '@app/common/services/auth.service';
import * as cloneDeep from 'lodash/fp/cloneDeep';
import { LazyLoadEvent, Message } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmationService } from 'primeng/api';
import { NotificationService } from '@app/common/services/notification.service';
import { selectAllTasks, selectAllTasksHits, selectUserTaskError } from '@app/+userTasks/store/selectors';
import { GetTasksForUserAction, SetTaskStateAction, UserTaskClearErrorAction } from './store';
import { TaskViewComponent } from './task-view.component';
import { AddNavigationTabAction } from '@app/store/navigation/actions';
import { LipoMessageService } from '@app/common/services/LipoMessageService';
import { SetSingleTaskStateAction } from '@app/+task-list/store';
import { LogUsersClientErrorAction } from '@app/store/user/actions';
import { IClientError } from '@app/common/interfaces/Interfaces';
import { PageSorting } from '@app/common/models/PagingSort-model';
import { TaskQueryParameter } from '@app/common/services/task.service';
import { TableLazyLoadEvent } from 'primeng/table';


@Component({
  selector: 'app-user-tasklist',
  templateUrl: 'userTasks.component.html',
  styleUrls: ['userTasks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class UserViewComponent implements OnInit {


  selectedCategories: ActionStates[] = [ActionStates.Unknown];


  tasks$ = this.store.pipe(select(selectAllTasks));
  total$ = this.store.pipe(select(selectAllTasksHits));
  selectUserTaskError$ = this.store.pipe(select(selectUserTaskError));
  pageSorting = new PageSorting(20);

  constructor(private store: Store<any>, private dialogService: DialogService, private router: Router,
    private confirmationsService: ConfirmationService, private notifier: NotificationService, private lipomessageService: LipoMessageService<Message>, private authService : AuthService) {

      this.pageSorting.sortOrder = -1; // absteigend

  }

  loadTaskData(e: TableLazyLoadEvent)
  {
    this.pageSorting.skipRows = e.first;
    this.pageSorting.rowsToLoad = e.rows;
    var p = <TaskQueryParameter>{
      skip : this.pageSorting.skipRows,
      take: this.pageSorting.rowsToLoad,
      categories: this.selectedCategories,
      descending: this.pageSorting.sortOrder == -1 ? true : false,
    }
    this.store.dispatch(new GetTasksForUserAction({ param: p }));
  }

  load()
  {
    var p = <TaskQueryParameter>{
      skip : this.pageSorting.skipRows,
      take: this.pageSorting.rowsToLoad,
      categories: this.selectedCategories
    }
    this.store.dispatch(new GetTasksForUserAction({ param: p }));
  }

  ngOnInit() {


   this.load()

    //Handle Errors
    this.selectUserTaskError$.pipe(filter(t => t != undefined)).subscribe(e => {
      this.lipomessageService.Clear();

      if (e.generalResult == GeneralResultEnum.HttpError) {
        this.lipomessageService.Publish({ severity: "error", summary: e.message ? e.message : e.userMessage, detail: e.serverMessage });
      } else   if (e.generalResult == GeneralResultEnum.AuthError) {
        this.lipomessageService.Publish({ severity: "error", summary:  (e.userMessage ? e.userMessage :  "Die Anmeldung ist abgelaufen"), detail: "Sie werden neu angemeldet" });
        this.authService.login();
      } else if (e.generalResult == GeneralResultEnum.OnlyDebug) {
        this.store.dispatch(new LogUsersClientErrorAction({ error: <IClientError>{ userid: null, modul: 'UserTasks', error: e.userMessage + " "+  e.serverMessage } }));
      }
      else    if (e.generalResult == GeneralResultEnum.LogicWarning) {
        this.lipomessageService.Publish({ severity: "warn", summary: e.userMessage ? e.userMessage : "Fehler Lieferantenaufgabe bearbeiten", detail: e.userMessage });
      } else if (e.generalResult != GeneralResultEnum.Success) {
        this.notifier.showError(e.message);
        console.debug(e?.serverMessage);
      }

      this.store.dispatch(new UserTaskClearErrorAction());

    });


  }

  selectAllCategories() {
    this.selectedCategories = [];
    this.selectedCategories.push(ActionStates.Unknown);
    this.load();
  }

  selectSpecificCategory() {
    this.load();
  }

  openTaskInfo(rowData) {
    const ref = this.dialogService.open(TaskViewComponent, {
      header: 'Aufgaben-Übersicht',
      width: '50rem',
      height: 'auto',
      data: { taskToView: rowData, isManager: false }
    });

  }

  getTooltipUploadEdit(row: TaskUserModel) : string
  {
    if (row &&  row.taskType >  ActionStates.Transfered)  return 'Upload ansehen';
    if (row && row.isReadOnly == 1)  return 'Upload ansehen';
    if (row && row.isEditExpired == 1)  return 'Upload ansehen';
    return 'Upload bearbeiten';
  }

  getIconUpload(row: TaskUserModel) : string
  {
    var icon = row.taskState <= 300 ?  "pi pi-paperclip" : "pi pi-check";
    if (row && row.taskState == 550 )
    {
     icon = "pi pi-circle";
    } else if (row && row.taskType >  ActionStates.Transfered) // Aufgaben
    {
      icon = "pi pi-circle";
    }
    return icon;
  }

  getUploadtooltip(row: TaskUserModel) : string
  {
     var tooltip  = "Upload ansehen";
     if (row && row.taskState == 550 )
     {
      tooltip+= " -> Ohne Input abgeschlossen";
     }
     return tooltip;
  }

  /**Aufgabe ohne Input abschliessen */
  closeTaskwithouAction(taskid: number): void {
    var sub = this.tasks$.subscribe(tasks => {
      var task = tasks.filter(t => t.id == taskid)[0];
      if (task) {
        this.confirmationsService.confirm({
          message: "'" + task.name + "' ohne Input abschließen ?",
          acceptLabel: 'OK',
          accept: () => {
            this.store.dispatch(new SetSingleTaskStateAction({ taskId: taskid, taskState: 550}));
            this.store.dispatch(new SetTaskStateAction({ taskId: taskid, newTaskState: 550}));
          }
        });
      }
    })
    sub.unsubscribe();
  }

  getUserTaskClass(pre: string, rowData: TaskUserModel) {
    if(rowData.isEditExpired === 1) {
      return `${pre}-disabled`;
    } else {
      return pre;
    }
  }

  getUserTaskButtonClass(def: string, rowData: TaskUserModel) {
    if(rowData.isEditExpired === 1) {
      return 'p-button-disabled';
    } else {
      return  def;
    }
  }

  openUpload(rowData: TaskUserModel) {
    /* if(rowData.isReadOnly === 1 && rowData.isReadOnlyMessage) {
        alert(rowData.isReadOnlyMessage);
    } */

    this.store.dispatch(new AddNavigationTabAction({ displayName: 'Upload ' + rowData.name, url: `list-editor/${rowData.id}/task` }, true));
    this.router.navigate([`list-editor/${rowData.id}/task`]);
  }

}
