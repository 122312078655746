import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { BehaviorSubject, Subject, ReplaySubject, Observable } from 'rxjs';
import { jwtDecode }  from 'jwt-decode';
import { Injectable } from '@angular/core';
import { authConfig } from '@app/oauth.config';
import { filter, map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { IAuthUser } from '../interfaces/Interfaces';
import { AuthError } from '../models/simpleResult.model';
import { CommonCheckService } from './common.validation.service';

//Refresh token SH
//https://docs.progress.com/de-DE/bundle/openedge-new-info-1172/page/SSO-refresh-model.html
// https://security.stackexchange.com/questions/119371/is-refreshing-an-expired-jwt-token-a-good-strategy
// https://auth0.com/blog/refresh-tokens-what-are-they-and-when-to-use-them/
//https://www.oauth.com/oauth2-servers/making-authenticated-requests/refreshing-an-access-token/
// https://fullstack+-mark.com/post/19/jwt-authentication-flow-with-refresh-tokens-in-aspnet-core-web-api
// https://manfredsteyer.github.io/angular-oauth2-oidc/docs/additional-documentation/refreshing-a-token-(silent-refresh).html
@Injectable({ providedIn: 'root' })
export class AuthService  {

  isAuthenticated$ = new ReplaySubject<boolean>();
  private pIsAuthenticated: boolean;
  notokenAccessSub = new Subject<Date>();
  get isAuthenticated() {
    return this.pIsAuthenticated;
  }
  set isAuthenticated(value) {
    if (this.pIsAuthenticated !== value) {
      this.isAuthenticated$.next(value);
      this.pIsAuthenticated = value;
    }
  }

  claims$ = new BehaviorSubject<{ [claim: string]: any }>({});

  get claims() {
    return this.claims$.value;
  }

  /**aktueller OAutjService für debugging */
  get OAuthService() {
    return this.oauthService;
  }


  constructor(
    private oauthService: OAuthService,
    private lipovalidate: CommonCheckService
  ) {
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.getDiscoveryDocument();
    this.oauthService.silentRefreshTimeout = 20000 * 2 // default mal 2;
    this.oauthService.fallbackAccessTokenExpirationTimeInSec = 60 * 20; // 20min danach refresh
    this.oauthService.showDebugInformation = (environment.production === false);
    // this.oauthService.setupAutomaticSilentRefresh(); manuell siehe event unten

  }

  /**user mit Namen und Berechtigung z.b. manager*/
  getLipoUser(): Observable<IAuthUser> {
    return this.claims$.pipe(filter(t => t.client_id), map(f => {
      var ismanager = false;
      var isadmin = false;
      var ismandant = false;
      var isLieferant = false;
      if (environment.managers.some(x => f.member_of.includes(x))) {
        ismanager = true;
      }
      if (environment.admins.some(x => f.member_of.includes(x))) {
        isadmin = true;
      }
      if (environment.mandant.some(x => f.member_of.includes(x))) {
        ismandant = true;
      }

      if (environment.suppliers.some(x => f.member_of.includes(x))) {
        isLieferant = true;
      }

      return <IAuthUser>{ name: f.name, id: f.sub, client_id: f.client_id, member_of: f.member_of, ismanager: ismanager, isadmin: isadmin, isLieferant: isLieferant,  ismandant: ismandant, user_token: f.id, shortId: this.lipovalidate.getShortUserid(f.sub) }
    }))
  }


  /**
   * Wrapper für getAccessToken() oauthService */
  getAccessToken(): string {
    if (!this.oauthService.hasValidAccessToken() && !this.oauthService.hasValidIdToken()) {
      throw new AuthError("Die Anmeldung ist abgelaufen.", new Error("token Expired"));
    }
    return this.oauthService.getAccessToken();
  }

  private async getDiscoveryDocument() {
    await this.oauthService.loadDiscoveryDocument().then((doc) => {
      this.oauthService.tryLogin()
        .catch(err => {
          console.error(err);
        })
        .then(() => {
          if (!this.oauthService.hasValidAccessToken()) {
            this.oauthService.initImplicitFlow();

          } else {
            this.isAuthenticated$.next(true);
            this.isAuthenticated = true;
            this.claims$.next(jwtDecode(this.oauthService.getAccessToken()));
          }
        });
    });

    this.oauthService.events
      .pipe(filter(element => element.type === 'token_expires'))
      .subscribe(
        (a) => {
          this.oauthService.silentRefresh().then(result => console.log(result)).catch(error => {
            console.debug(error) ;
          });
        }
      );
  }


  /**Neu login nach Wartezeit wegen Meldungen */
  login() {
    var self = this;
    setTimeout(function () {
      self.oauthService.initImplicitFlow();
    }, 2000);
  }


  logoff() {
    this.oauthService.logOut();
  }

}
