/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfListeDto } from '../models/list-response-args-of-liste-dto';
import { ExtendedQueryTokenDTO } from '../models/extended-query-token-dto';
import { ResponseArgsOfListeDto } from '../models/response-args-of-liste-dto';
import { ListeDto } from '../models/liste-dto';
import { ResponseArgsOfBoolean } from '../models/response-args-of-boolean';
@Injectable({
  providedIn: 'root',
})
class ListenService extends __BaseService {
  static readonly ListenQueryListePath = '/liste/s';
  static readonly ListenCreateListePath = '/liste';
  static readonly ListenUpdateListePath = '/liste/{id}';
  static readonly ListenDeleteListePath = '/liste/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Liste Liste
   * @param params The `ListenService.ListenQueryListeParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `eagerLoading`:
   */
  ListenQueryListeResponse(params: ListenService.ListenQueryListeParams): __Observable<__StrictHttpResponse<ListResponseArgsOfListeDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/liste/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfListeDto>;
      })
    );
  }
  /**
   * Liste Liste
   * @param params The `ListenService.ListenQueryListeParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `eagerLoading`:
   */
  ListenQueryListe(params: ListenService.ListenQueryListeParams): __Observable<ListResponseArgsOfListeDto> {
    return this.ListenQueryListeResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfListeDto)
    );
  }

  /**
   * Create
   * @param params The `ListenService.ListenCreateListeParams` containing the following parameters:
   *
   * - `payload`: ListeDto
   *
   * - `user`: User Id oder Token
   */
  ListenCreateListeResponse(params: ListenService.ListenCreateListeParams): __Observable<__StrictHttpResponse<ResponseArgsOfListeDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/liste`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfListeDto>;
      })
    );
  }
  /**
   * Create
   * @param params The `ListenService.ListenCreateListeParams` containing the following parameters:
   *
   * - `payload`: ListeDto
   *
   * - `user`: User Id oder Token
   */
  ListenCreateListe(params: ListenService.ListenCreateListeParams): __Observable<ResponseArgsOfListeDto> {
    return this.ListenCreateListeResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfListeDto)
    );
  }

  /**
   * Update
   * @param params The `ListenService.ListenUpdateListeParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `id`:
   *
   * - `user`:
   */
  ListenUpdateListeResponse(params: ListenService.ListenUpdateListeParams): __Observable<__StrictHttpResponse<ResponseArgsOfListeDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/liste/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfListeDto>;
      })
    );
  }
  /**
   * Update
   * @param params The `ListenService.ListenUpdateListeParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `id`:
   *
   * - `user`:
   */
  ListenUpdateListe(params: ListenService.ListenUpdateListeParams): __Observable<ResponseArgsOfListeDto> {
    return this.ListenUpdateListeResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfListeDto)
    );
  }

  /**
   * Delete
   * @param params The `ListenService.ListenDeleteListeParams` containing the following parameters:
   *
   * - `id`: Liste Id
   *
   * - `user`: User Id oder Token
   */
  ListenDeleteListeResponse(params: ListenService.ListenDeleteListeParams): __Observable<__StrictHttpResponse<ResponseArgsOfBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/liste/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBoolean>;
      })
    );
  }
  /**
   * Delete
   * @param params The `ListenService.ListenDeleteListeParams` containing the following parameters:
   *
   * - `id`: Liste Id
   *
   * - `user`: User Id oder Token
   */
  ListenDeleteListe(params: ListenService.ListenDeleteListeParams): __Observable<ResponseArgsOfBoolean> {
    return this.ListenDeleteListeResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfBoolean)
    );
  }
}

module ListenService {

  /**
   * Parameters for ListenQueryListe
   */
  export interface ListenQueryListeParams {
    queryToken: ExtendedQueryTokenDTO;
    eagerLoading?: number;
  }

  /**
   * Parameters for ListenCreateListe
   */
  export interface ListenCreateListeParams {

    /**
     * ListeDto
     */
    payload: ListeDto;

    /**
     * User Id oder Token
     */
    user?: null | string;
  }

  /**
   * Parameters for ListenUpdateListe
   */
  export interface ListenUpdateListeParams {
    payload: ListeDto;
    id: number;
    user?: null | string;
  }

  /**
   * Parameters for ListenDeleteListe
   */
  export interface ListenDeleteListeParams {

    /**
     * Liste Id
     */
    id: number;

    /**
     * User Id oder Token
     */
    user?: null | string;
  }
}

export { ListenService }
