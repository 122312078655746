import { ListOverviewState, InitialListOverviewState } from './state';
import { ListOverViewActions, ListOverviewActionType, GetListDoneAction, SetLoadingAction } from './actions';

export function reducer(state: ListOverviewState = InitialListOverviewState, action: ListOverViewActions): ListOverviewState {

    switch (action.type) {
        case ListOverviewActionType.GetListsDone:
            return GetListsDoneReducer(state, action);
        case ListOverviewActionType.SetLoading:
            return SetLoadingReducer(state, action);
    }

    return state;
}

export function GetListsDoneReducer(state: ListOverviewState = InitialListOverviewState, action: GetListDoneAction): ListOverviewState {
    console.log('GetListsDone Reducer');
    console.log('Payload:', action);
    return {
        ...state,
        lists: action.payload.items,
        loading: false
    };

}

export function SetLoadingReducer(state: ListOverviewState = InitialListOverviewState, action: SetLoadingAction): ListOverviewState {
    return {
        ...state,
        loading: action.payload.isLoading
    };
}
