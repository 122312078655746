import { ValidatorFn } from '@angular/forms';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { HeaderFilter } from '../noviProcess.model';
import { LipoClassData, LipoStyleData, LipoStyleDataBase, LookupValue } from './columnLookup.model';
import { NovitätTableCol, WebapiType } from './NovitätenTableCol';
import { LazyLoadEvent, MenuItem, FilterMetadata } from 'primeng/api';
import { Calendar } from 'primeng/calendar';
import { SimpleResultError } from '../simpleResult.model';
import { ValidationColumnModel, ValidationError } from '../validation.model';
import * as lodash from 'lodash';
import { Signal, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AutoCompleteSelectEvent, AutoCompleteUnselectEvent } from 'primeng/autocomplete';


export class MetaDataCellFatory {



  static getInstance(editable: boolean, col: NoviColumn, id: any,
    validationSubject: Subject<{ [key: string]: ValidationColumnModel }>,
    stylesSubject: Subject< { [key: string]: LipoStyleDataBase } >,
    lazyclassStyle: Subject<LipoClassData[]>,
    lazyCellLookupSubject : Subject< { [key: string]: LookupValue[] | any[]  } >
  ): MetaDataCell<any> {
    switch (col.type) {


      case WupColumnType.Text:
        return new MetaDataCell<NoviColumn>(editable, col.field, id, validationSubject, stylesSubject, lazyclassStyle, lazyCellLookupSubject);


      default:
        return new MetaDataCell<NoviColumn>(editable, col.field, id, validationSubject, stylesSubject, lazyclassStyle, lazyCellLookupSubject);
      // throw Error("Metadata für Spalte " + WupColumnType[col.type] + " nicht konfiguriert")
    }

  }

}

export class LipoPTableData {
  metadata: { [key: string]: MetaDataCell<any> };
  data: any[];
}


export class DataCell<T>
{
  value: WritableSignal<T>;
  readonlyValue: Signal<string>;
}

/**Metadata für die Zelle */
export class MetaDataCell<T>
{
  readonly: boolean;
  columnpart: T;
  field: string;
  image: string;
  validationSubject: Subject<{ [key: string]: ValidationColumnModel }> = new Subject();
  validation: ValidationColumnModel;
  id: any;
  lazystylesSubject: Subject<{ [key: string]: LipoStyleDataBase; }>;
  cellstyle: LipoStyleDataBase = <LipoStyleDataBase>{};
  lazyclassStyle: Subject<LipoClassData[]> = new Subject<LipoClassData[]>();
  classStyle: string;
  OutputId: string;
  InputId: string;
  lookupModel: Observable<LookupValue[] | any[]> = of([]);

  lazyCellLookupSubject: Subject<{ [key: string]: LookupValue[] | any[]  }>;
  cellLookupSubject :  BehaviorSubject<LookupValue[] | any []>   = undefined;



  constructor(editable: boolean, col: string, id: any, validationSubject: Subject<{ [key: string]: ValidationColumnModel }>,
    lazystylesSubject: Subject< { [key: string]: LipoStyleDataBase } >,
    lazyclassStyle: Subject<LipoClassData[]>,
    lazyCellLookupSubject : Subject< { [key: string]: LookupValue[] | any[]  } >
    ) {
    this.id = id,
    this.readonly = !editable;
    this.field = col;
    this.validation = new ValidationColumnModel(col, id);
    this.validationSubject = validationSubject;
    this.lazystylesSubject = lazystylesSubject;
    this.lazyclassStyle = lazyclassStyle;
    this.lazyCellLookupSubject = lazyCellLookupSubject;


    this.lazyCellLookupSubject.subscribe(changedlookups=>
      {
        var thislookup = changedlookups[this.field + '-' + this.id];
        if (thislookup && !lodash.isEqual(this.lookupModel, changedlookups)  ) {
            //Zum Zellen Lookup wechsel (Default ist col.lookupModel)
            if (this.cellLookupSubject == undefined )
            {
              this.cellLookupSubject = new BehaviorSubject<LookupValue[] | any []> ([]);
              this.lookupModel = this.cellLookupSubject.asObservable();
            }
            this.cellLookupSubject.next(thislookup);
        }
    })

    this.validationSubject.subscribe(f => {
      var thisvalidations = f[this.field + '-' + this.id];
      if (thisvalidations && !lodash.isEqual(this.validation, thisvalidations)  ) {
        this.validation = thisvalidations;
      }
    })
    this.lazystylesSubject.subscribe(styles => {
      var shiststyle = styles[this.field + '-' + this.id];
      if (shiststyle && !lodash.isEqual(this.cellstyle, shiststyle)  ) {
        this.cellstyle = shiststyle;
      }
    })
    this.lazyclassStyle.subscribe(clstyle=> {
      if (clstyle)
      {
          this.classStyle = clstyle.find(f=> f.rowid == this.id && this.field == f.column.field)?.class;
      }
    } )
  }

}

export class PTableChanges {
  field: any;
  data: any;
  index: any;
}

export interface LipoTableEmitterObject {

  col: NoviColumn;
  data: any;
  event: any;
  /**Key striked */
  code?: any;
}

export class LipoValidator {
  notallowed: any[];
  lipoValidator: LipoValidators;

  constructor(lipoValidator: LipoValidators, notallowd?: any[]) {
    this.lipoValidator = lipoValidator;
    this.notallowed = notallowd;
  }

}


export enum LipoValidators {
  required = 'required',
  nodublicates = 'nodublicates'
}



export interface NoviColumnFilter extends NoviColumn {
  filterProperties: FilterProperties;
}

export interface WupFilterColumn extends NoviColumn {

}

/**Typ der Tabelle / store relevant */

export enum TableType {
  SoMa = 'SoMa',
  WuP = 'WuP',
  WuPMassEdit = 'WuPMassEdit',
  SortimentMatrixOptionen = "SortimentMatrixOptionen",
  FilialMatrixModule = "FilialMatrixModule",
  SortimentMatrixParameter = "SortimentMatrixParameter",
  SortimentMatrixDispoliste = "SortimentMatrixDispoliste",
  SortimentMatrixParameterDetails = "SortimentMatrixParameterDetails",
  SortimentMatrixModule = "SortimentMatrixModule",
  SortimentMatrixModuleDetails = "SortimentMatrixModuleDetails"
}

export class GroupedHeaderColumn {

  header: string;
  //nicht verwendet
  rowspan?: number;
  colspan: number;
}

export class NoviColumnBase {
  field: string;
  header: string;
  part?: string;
  /**spezielles Styling */
  style?: string;
  headerStyle?: string;
  styleType?: ColumnStyleType = ColumnStyleType.Normal;
  //Resultierendes Spalten Styling
  //spezeiller Style des Controls z.B. des <input/>
  controlStyle?: any;
  validations?: LipoValidators[];
}


export enum ColumnStyleType
{
    Normal = 0,
    /**wenn aus config heraus */
    FixedWidth = 1
}


















export enum WupColumnGroup {
  Default = "Default",
  Wkz = "Wkz",
  Konditionen = "Konditionen",

}




export enum NoviColumnGroup {
  Default = "Default",
  Platzierung = "Platzierung",
  Wkz = "Wkz",

  Sets = "VPE/VP/MBM",

}


export class FrozenReadonlyCulumnContainer
{
  columnsbody: NoviColumn[];
  frozen: FrozenReadonlyColumns;

}

export class FrozenReadonlyColumns
{
  isvalid: boolean = false;
  color: NoviColumn;
  bobble: NoviColumn;
  delete: NoviColumn;
  edit: NoviColumn;
  sel: NoviColumn;
  menu: NoviColumn;
}



export class NoviColumn extends NoviColumnBase {
  width: number;
  type?: WupColumnType;
  canOrder?: boolean;
  canOverlay?: boolean;
  dropDown?: DropdownColumn;
  multiSelect?: MultiSelectColumn;
  chips?: ChipColumn;
  autocomplete?: AutoCompleteColumn;
  klammerDropDown?: Sortiment;
  action?: ActionColumn;
  frozenCol?: boolean = false;

  labelColumn?: LabelColumn;
  bobbelData?: BobbelColumn;
  numberData?: NumberColumn;
  integerData?: IntegerColumn;
  textarea?: TextAreaColumn;
  imageColumn?: ImageColumn;
  subTable?: SubTableColumn;
  tooltip?: string;

  readonlyFunction?($event, data: any): boolean;
  blurFunction?($event, data: any): boolean;
  //Keypress Navigation aktivieren, default true
  useForNavigation?: boolean = true;
  startstop?: StartStopColumn;
  dateColumn?: DateColumn;
  /**Column gehört zu einer Gruppe z.B. Platzierung */
  groups?: NoviColumnGroup[] | WupColumnGroup[];
  //Informationen zum ApiAufruf und Mappen zum Server
  webApiType?: NovitätTableCol;
  contextMenu?: ContextMenuColumn;

}

export class NoviColumnStructure extends NoviColumn
{
    children?: NoviColumn[];
}

export class NoviColumnFilterGrid extends NoviColumn {
  filterProperties: FilterProperties;
  datagridFilter: { [s: string]: FilterMetadata | FilterMetadata[] } = {};
}

export class MassEditField {
  label: string;
  columnFieldname: string;
  showMultiselect: boolean = false;
  showDelete: boolean = false;
}

export class BobbelColumn {
  tableType: string;
}

export class EventActionValue {
  event: any;
  data: any;
  eventName: string;
  column: NoviColumn;
}


export class EventBackValue {
  event: any;
  data: any;
  eventName: string;
}

export class ActionToggleButton {
  offLabel: string;
  onLabel: string;
  offIcon: string;
  onIcon: string;
  value?: any;
  actionFunction?($event, data: any): void;
}

/**Start Stop Zeitraum */
export class StartStopColumn {
  start: NoviColumnStartstopBase;
  stop: NoviColumnStartstopBase;
}
export class NoviColumnStartstopBase extends NoviColumnBase {
  yearRange?: string;
  minDate?($event, col: NoviColumn, data: any): Observable<Date>;
  maxDate?($event, col: NoviColumn, data: any): Observable<Date>;
  onTodayClick?($event, col: NoviColumnBase, data: any, ele: Calendar): void;
  showButtonBar?: boolean;
  required?: boolean;
}

export class ActionColumn {
  name: string;
  icon?: string;
  checkBox?: boolean = false;
  headerButton?: ActionToggleButton = null;
  actionFunction?($event, data: any): void;
  style: any;
}

export class ContextMenuColumn {
  menu: MenuItem[];
}

export class LabelColumn {
  additionalText?: string;
  commandName?: string;
  actionFunction?($event, data: any): void;
}

export class DateColumn {
  format?: Intl.DateTimeFormatOptions;
  yearRange?: string;
  minDate?($event, col: NoviColumn, data: any): Observable<Date>;
  maxDate?($event, col: NoviColumn, data: any): Observable<Date>;
  onTodayClick?($event, col: NoviColumnBase, data: any, ele: Calendar): void;
  showButtonBar?: boolean
}

export class IntegerColumn {
  size: number;
  min?: number;
  max?: number;
  step?: number;
  suffix: string = null;

  constructor(size: number, min: number, max: number, step: number)
     {
    this.size = size;
    this.min = min;
    this.max = max;
    this.step = step;
  }

}
export class NumberColumn extends IntegerColumn {
  minFractionDigits: number;
  maxFractionDigits: number;


  constructor(  minFractionDigits: number, maxFractionDigits : number, suffix : string = undefined, size: number = undefined, min: number = undefined, max: number= undefined) {
    super(size,min,max,1);
    this.minFractionDigits = minFractionDigits;
    this.maxFractionDigits = maxFractionDigits;
    this.suffix = suffix;
  }



}



/**Simple Untertabelle */
export class SubTableColumn {
  columns: NoviColumn[];
  getIconStyle?  (row: any) : string;
}

export class DropdownColumn {
  optionLabel: string;
  optionValue: string;
  lookupModel: Observable<LookupValue[] | any[] >;
  lookupList?: Observable<LookupValue[] | any[]>;
  lookupQueryList?: Observable<LookupValue[] | any[]>;
  showClear: boolean = false;
  filter: boolean = false;
  onFilter?(col : NoviColumn, rowData: any, event: any): void;
  showOverlayVisible: boolean = true;
  outputEditFunction?(data: any, list: Observable<LookupValue[] | any[]>, dropdownOptions: DropdownColumn): Observable<string>;
  outputFunction?(data: any, dropdownOptions: DropdownColumn): Observable<string>;
  dataKey?: string;

  constructor() {
    this.filter = false;
    this.showClear = false;
  }

}

export class ChipColumn {
  id?: string;
  field?: string; // wenn object
  separator?: string;
  addOnTab?: boolean;
  addOnBlur?: boolean;
}

export class AutoCompleteColumn
{
  field?: string;
  //id Name
  id: string = "id";
  minLength: number = 2;
  placeholder: string;
  multiple: boolean = false;
  lookups? (rowData: any): Observable<any[]>;
  searchAutoComplete?(event: any, col: NoviColumn, data: any): void;
  removeAutocompleteItem?($event: AutoCompleteUnselectEvent, col, rowData) : void;
  onSelectAutocompleteItem?($event: AutoCompleteSelectEvent, col, rowData): void;
  createAutocompleteItem?(ele, $event,col, rowdata) : void;
  emptyMessage?: string;
}

export class ImageColumn {
  srcFunction?(col: NoviColumn, data: any, event: any): Observable<string>;
}

export class TextAreaColumn {
  rows: number;
  maxLength: number;
  blurAction?(col: NoviColumn, data: any, event: any): void;
}

export class Sortiment extends DropdownColumn {
  sortimentType: SortimentType = SortimentType.Klammer;
  selectedKlammer: string;
}
export interface MassEdit {
  showDelete: boolean;
  showReplacement: boolean;
}

export class MultiSelectColumn {

  showOverlayVisible: boolean;
  filter: boolean = false;
  showClear: boolean = false;
  optionLabel: string;
  optionValue: string | undefined;
  lookupModel: Observable<any[]>;
  lookupList?: Observable<LookupValue[] | any[]>;
  lookupQueryList?: Observable<LookupValue[] | any[]>;
  maxSelectedLabels?: number;
  selectionLimit?: number;
  outputEditFunction?(data: any[], list: Observable<LookupValue[] | any[]>, multiselect: MultiSelectColumn): Observable<string[] | any[]>;
  outputFunction?(data: any, multiselectOptions: MultiSelectColumn): Observable<string[] | any[]>;
  dataKey?: string;
  //TODO: Bug in primeng, nicht verwenden oder prüfen
  displaySelectedLabel?: boolean;
  selectedItemsLabel : string;
  placeholder?: string;
  showToggleAll?: boolean;

  constructor() {
    this.filter = false;
    this.showClear = false;
    this.maxSelectedLabels = 3;
  }

}

export enum SortimentType {
  Klammer = 'Klammer',
  Sortiment = 'Sortiment'
}












export enum WupColumnType {
  Text = "Text",
  TextArea = "TextArea",
  Number = "Number",
  MultiSelect = "MultiSelect",
  DropDown = "DropDown",
  CheckBox = "CheckBox",
  Date = "Date",
  Keyword = "Keyword",
  Chips = "Chips",
  Sortiment = "Sortiment",
  Action = "Action",
  Label = "Label",
  Bobbel = "Bobbel",
  Image = "Image",
  Color = "Color",
  /**Feld Module für Filialmatrix */
  ModuleDropdown = "ModuleDropdown",
  /**Sortimentsmatrix -> Parameter, Drag and drop Control */
  Modulator = "Modulator",
  Dummy = "Dummy",
  RowToggle = 'RowToggle',
  StartStop = "StartStop",
  Button = "Button",
  Integer = "Integer",
  ContextMenu = "ContextMenu",
  /**nur in Novi componente zurzeit !!!! */
  Tree = "Tree",
  AutoComplete = "AutoComplete",
  SubTable = "SubTable"
}



export interface WupColumn {
  field: string;
  header: string;
  width: number;
  /**Raus ???? */
  part: string;
  type: WupColumnType;
}

/**Nötige Values für die Filterung */
export class FilterProperties {
  canClear: boolean;
  /**angezigt in Zeile */
  filterRow: number;
  /**angezeigt in Spalte */
  filterColumn: number

  optionLabel: string;
  type: string;
  placeholder: string;
  lookupsSub: BehaviorSubject<HeaderFilter[]> = new BehaviorSubject<HeaderFilter[]>(new Array<HeaderFilter>());
  lookups: Observable<HeaderFilter[]> = this.lookupsSub.asObservable();multiple: boolean;
;

  errorSub: BehaviorSubject<SimpleResultError> = new BehaviorSubject<SimpleResultError>(null);

  reloadLookups: boolean;


  optionValue: string;
  columnfilterType: string;
  showFilterMenu: boolean;

  /**Date Behavior */
  fieldFrom: string;
  fieldTo: string;

  /**welcher Aufruf muss hier verwendet werden ? */
  webapitype?: NovitätTableCol;
  /**autocomplete min Länge des Textes */
  minLength?: number;

  required: boolean;

}


