<div class="grid">
  <div class="col-12">
    <div class="grid p-fluid">
      <div class="field col-7">
        <label for="transferTaskName">Name:</label>
        <input id="transferTaskName" type="text" pInputText [(ngModel)]="taskName" />
      </div>
      <div class="field col-5">
        <label for="transferUserName">Lieferant:</label>
        <input pInputText readonly class=""   id="transferUserName" [ngModel]="userOrganisation" />
      </div>
      <div class="field col-7">
        <label for="transferSelectedMaster" >Sortiment:</label>
        <p-dropdown filter="true" id="transferSelectedSortiment" [options]="sortimente$ | async" optionLabel="name" [(ngModel)]="selectedSortiment"
        (ngModelChange)="selectedSortimentChange()" placeholder="Sortiment wählen" appendTo="body">
        </p-dropdown>
      </div>
      <div class="field col-5">
        <label for="transferExpirationDate" >Verfallsdatum:</label>
        <p-calendar id="transferExpirationDate" [(ngModel)]="expirationDate" [firstDayOfWeek]="1" dateFormat="dd.mm.yy"
            showButtonBar="true" [showIcon]="true" appendTo="body"
            [minDate]="minDateValue" [maxDate]="maxDateValue"></p-calendar>
      </div>
    </div>
  </div>
</div>

<div class="grid formAreaSeparatorTop">
    <div class="field col-7"></div>
    <div class="field col-3">
      <button pButton [disabled]="!canSaveAndTransfer()" label="Speichern + Versenden" class="p-button-info" (click)="saveAndTransfer()"></button>
    </div>
    <div class="field col-1">
      <button pButton [disabled]="!canCancel()" label="Abbrechen" class="p-button-info" (click)="cancel()"></button>
    </div>
</div>
