
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { ISettingValue } from '../models/setting.models';
import { Observable } from 'rxjs';
import { ResultObjectModel } from '../models';
import { CustomSettingsModulEnum, CustomSettingsTypeEnum, CustomSetting } from '../models/custom.settings.models';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';



/**Service die User Settings z.B. Spaltenspeicherung    */
@Injectable()
export class CustomSettingsService {



  UpdateLastAccessConfig(p: CustomSetting<any>): Observable<ResultObjectModel<number>> {
    const url = `${environment.connections.SettingService}/customsettings/UpdateLastaccess`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    return this.httpClient.post<ResultObjectModel<number>>(url,p, httpOptions);
  }


  deleteCustomSettingsbyName(name: string, modul: CustomSettingsModulEnum, type: CustomSettingsTypeEnum) : Observable<ResultObjectModel<number>> {
    const url = `${environment.connections.SettingService}/customsettings/deleteCustomSettingsbyName`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { name: name, modul : modul.toString(), type: type.toString()  }
    };
    return this.httpClient.get<ResultObjectModel<number>>(url,httpOptions);
  }

  deleteCustomSettings(id: number): Observable<ResultObjectModel<number>> {

    const url = `${environment.connections.SettingService}/customsettings/deleteCustomSetting`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { settingid: id.toString()  }
    };
    return this.httpClient.get<ResultObjectModel<number>>(url,httpOptions);
  }

  insertCustomSettings(p: CustomSetting<any>): Observable<ResultObjectModel<CustomSetting<any>>> {

    const url = `${environment.connections.SettingService}/customsettings/insertCustomSetting`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    return this.httpClient.post<ResultObjectModel<CustomSetting<any>>>(url,p, httpOptions);
  }

  updateCustomSettings(p: CustomSetting<any>): Observable<ResultObjectModel<CustomSetting<any>>> {

    const url = `${environment.connections.SettingService}/customsettings/updateCustomSetting`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    return this.httpClient.post<ResultObjectModel<CustomSetting<any>>>(url,p, httpOptions);
  }


  constructor(private authService: AuthService, private httpClient: HttpClient) {

  }


  GetCustomSettings(userid: string, modul: CustomSettingsModulEnum,  type: CustomSettingsTypeEnum ): Observable<ResultObjectModel<CustomSetting<any>[]>> {
    const url = `${environment.connections.SettingService}/customsettings/GetCustomSettings`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { userid: userid, modul: modul.toString(), type: type.toString()  }
    };
    const result = this.httpClient.get<ResultObjectModel<CustomSetting<any>[]>>(url, httpOptions).pipe(map(x => {
      return x;
    }));
    return result;
  }


  deleteSettingValue(id: number): Observable<ResultObjectModel<number>> {
    const url = `${environment.connections.SettingService}/setting/deleteSettingValue`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { settingId: id.toString() }
    };
    return this.httpClient.get<ResultObjectModel<number>>(url, httpOptions);
  }


  insertSettingValue(newsetting: ISettingValue): Observable<ResultObjectModel<ISettingValue>> {
    const url = `${environment.connections.SettingService}/setting/insertSettingValue`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    return this.httpClient.post<ResultObjectModel<ISettingValue>>(url, newsetting, httpOptions);

  }



  updateSettingValue(setting: ISettingValue): Observable<ResultObjectModel<ISettingValue>> {
    const url = `${environment.connections.SettingService}/setting/updateSettingvalue`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    return this.httpClient.post<ResultObjectModel<ISettingValue>>(url, setting, httpOptions);
  }



}

