import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ListOverviewService } from '@app/common/services/list-overview.service';
import { selectListOverviewState } from './state';
import { ListOverviewActionType, ListOverViewActions, GetListDoneAction } from './actions';
import { withLatestFrom, switchMap, map, tap } from 'rxjs/operators';

@Injectable()
export class ListOverViewEffects {
    private state$ = this.store.pipe(select(selectListOverviewState));

    constructor(
        private store: Store<any>,
        private service: ListOverviewService,
        private actions: Actions
        ) { }


    getListOverview$ = createEffect(() => { return  this.actions.pipe(
        ofType<ListOverViewActions>(ListOverviewActionType.GetListsForUser),
        withLatestFrom(this.state$),
        switchMap(([_, state]) => this.service.getListsForUser()),
        tap(console.log.bind(window)),
        map(response => new GetListDoneAction({ items: response }))
        )})




}
