import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { DynamicDialogRef, DynamicDialogConfig, DialogService } from 'primeng/dynamicdialog';
import { Observable, Subscription, of } from 'rxjs';
import { ActionStates, AufgabenArt, AufgabenartValidListe, LieferantTypeEnum, TaskUserModel } from '@app/common/models/task-user.model';

import { ValidationList } from '@app/common/models/validationList.model';

import { CmfUserDataModel } from '@app/common/models/cmfUserData.model';
import { debounceTime, filter, first, map, take } from 'rxjs/operators';
import { environment } from '@env/environment';
import * as momentLib from 'moment';
import { CreateMasterTaskAction, UpdateTaskAction, UpdateTaskDoneAction, TaskListActionType, DeleteUploadAction } from './store';
import * as cloneDeep from 'lodash/fp/cloneDeep';
import { UploadFileModel, UploadFileDto, FileDescriptionModel, ResultObjectModel } from '@app/common/models';
import { Actions, ofType } from '@ngrx/effects';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { CreateMasterTaskDoneAction } from '@app/+task-editor/store';
import { TaskStructureModel } from '@app/common/models/taskStructure.model';
import { FileUpload } from 'primeng/fileupload';

import { EinkaufDto, KlammerDto, MandantDto, SortimentDto, TypDto } from '@app/api/filialmatrix/models';
import { SortimentDtoClient } from '@app/common/models/sortimente/SortimentDtoClient';
import { KlammerDtoClient } from '@app/common/models/sortimente/KlammerDtoClient';
import { TypDtoClient } from '@app/common/models/sortimente/SortimentstypenDtoClient';
import { CommonCheckService } from '@app/common/services/common.validation.service';
import { KeyValue } from '@angular/common';
import { LookupValueBase } from '@app/common/models/novi/columnLookup.model';
import { NovitaetenLookupService } from '@app/common-data-module/services/NovitaetenLookupService';
import { NovitätEnum } from '@app/common/models/novi/NovitätenTableCol';
import { EinkaufDtoClient } from '@app/common/models/novi/einkauf.dto.client';
import { MandantenService } from '@app/common-data-module/services/MandantenService';
import { C } from '@fullcalendar/core/internal-common';

@Component({
  selector: 'app-task-edit-dialog',
  templateUrl: './task-edit-dialog.component.html',
  styleUrls: ['./task-edit-dialog.component.scss'],
  providers: [DialogService]
})
export class TaskEditDialogComponent implements OnInit, OnDestroy {

  @ViewChild('fubauto') fileUpload: FileUpload;
  @ViewChild('fubautoNew') fileUploadNew: FileUpload;

  taskModel: TaskUserModel;
  targetType: string; //master = mastertask, supplier = Lieferantentask
  editMode: string; //new = neuer Datensatz, edit = Datensatz bearbeiten
  sortimentsKlammern$: Observable<KlammerDtoClient[]>;
  sortiments$: Observable<SortimentDtoClient[]>;
  sortimentsToView$: Observable<SortimentDtoClient[]>;
  validationProfiles$: Observable<ValidationList[]>;
  sortimentsTypen$: Observable<TypDtoClient[]>;
  supplierList$: Observable<CmfUserDataModel[]>;

  selectedType: TypDtoClient;
  selectedKlammer: KlammerDtoClient;
  selectedSortiment: SortimentDtoClient;
  selectedSortimentsForMaster: SortimentDtoClient[];
  selectedList: ValidationList;
  taskValidRange: Date[];
  taskActiveRange: Date[];
  actionSubscription: Subscription;
  uploadedFiles: UploadFileModel = { userId: null, files: [] };


  taskInfoTexts = environment.taskTexts;
  children: TaskStructureModel<TaskUserModel>[];
  refdialog: ConfirmationService;
  onSave = new EventEmitter<TaskUserModel>();
  onError = new EventEmitter<Message>();
  private _canSave: boolean = true;
  actionCreateSubscription: Subscription;

  aufgabenArten: Array<LookupValueBase> = [];
  selectedAufgabenart: LookupValueBase = { id: AufgabenArt.Artikelliste, value: AufgabenArt[AufgabenArt.Artikelliste] };
  einkaeufe$: Observable<EinkaufDtoClient[]> = of([]);
  selectedEinkauf: EinkaufDtoClient;


  constructor(public ref: DynamicDialogRef, public _actions$: Actions,
    private luipocheck: CommonCheckService,
    private mandantenservice: MandantenService,
    private novitaetenlookupservice: NovitaetenLookupService,
    public config: DynamicDialogConfig, public store: Store<any>, private msgService: MessageService, private confirmationsService: ConfirmationService)
    {
      this.einkaeufe$ = this.novitaetenlookupservice.selectzukuenftigeEinkaeufe$;
     }

  ngOnDestroy(): void {
    if (this.refdialog) {

      this.refdialog.close();
      this.refdialog = null;
    }

  }

  /**Kann die Liste ausgewählt werden ?
   * bei externen Bedarfen muss
   * ansonst wenn Sortiment gewählt
   */
  canSelectList(): boolean {
    if (this.selectedAufgabenart == undefined || this.selectedAufgabenart.id != AufgabenArt.WeitereBedarfe) {
      return this.selectedSortiment != undefined ||  ( this.selectedSortimentsForMaster != undefined &&  this.selectedSortimentsForMaster.length > 0)  ;
    }
    return this.selectedAufgabenart != undefined && this.selectedAufgabenart.id == AufgabenArt.WeitereBedarfe
  }

  canKlammer(): boolean {
    return this.selectedAufgabenart != undefined && this.selectedAufgabenart.id != AufgabenArt.WeitereBedarfe
  }

  canSortimentsTypen(): boolean {
    return this.selectedAufgabenart != undefined && this.selectedAufgabenart.id != AufgabenArt.WeitereBedarfe
  }

  canSortiment(): boolean {
    return this.selectedAufgabenart != undefined && this.selectedAufgabenart.id != AufgabenArt.WeitereBedarfe
  }

  ngOnInit() {

    this.aufgabenArten =  AufgabenartValidListe;
    this.taskModel = cloneDeep(this.config.data.taskModel) as TaskUserModel;
    this.children = cloneDeep(this.config.data.children) as TaskStructureModel<TaskUserModel>[];
    this.selectedAufgabenart = this.aufgabenArten.find(g => this.taskModel.aufgabenArt && g.id == this.taskModel.aufgabenArt);



    this.sortimentsKlammern$ = this.config.data.sortimentsKlammern;
    this.sortiments$ = this.config.data.sortiments;

    this.validationProfiles$ = this.config.data.validationProfiles;
    this.sortimentsTypen$ = this.config.data.sortimentsTypen;

    this.sortimentsToView$ = this.config.data.sortimentsToView;
    this.targetType = this.config.data.targetType;
    this.editMode = this.config.data.editMode;
    this.onSave = this.config.data.onSave;
    this.onError = this.config.data.onError;
    this.msgService.clear();

    if (this.taskModel?.aufgabenArt == AufgabenArt.WeitereBedarfe) {

        this.taskModel.includeUpload = true;
        if (this.taskModel.selectedValidationId) {
          this.validationProfiles$.subscribe(liste => {
            this.selectedList = liste.find(f => f.id == this.taskModel.selectedValidationId);
          }).unsubscribe()
        }

      this.novitaetenlookupservice.loadZukuenftigeEinkaeufe(false);
      this.novitaetenlookupservice.selectzukuenftigeEinkaeufeisLoading$.pipe(debounceTime(200), filter(f => f == false)).subscribe(notlaoding => {
        this.novitaetenlookupservice.selectzukuenftigeEinkaeufe$.pipe(filter(e => e && e.length > 0), take(1)).subscribe(einkaufe => {
          var searchedeinkauf = einkaufe.find(e => e.einkaufLongId == this.taskModel.einkaufId);
          if (searchedeinkauf) {
            this.selectedEinkauf = searchedeinkauf;
          } else {
            this.novitaetenlookupservice.loadEditLookupFilterData(NovitätEnum.einkauf, true);
            this.novitaetenlookupservice.selectallEinkaeufe$.pipe(filter(f => f && f.length > 0), take(1)).subscribe(einkaufge => {
              var e = einkaufge.find(e => e.einkaufLongId == this.taskModel.einkaufId);
              if (e) {
                const oldeinkauf = { ...e } as EinkaufDtoClient;
                oldeinkauf.readonly = true;
                this.selectedEinkauf = oldeinkauf;
              } else {
                this.selectedEinkauf = null;

                this.onError.emit({ key: 'errorMessage', severity: 'warn', summary: 'Einkauf wurde nicht gefunden', detail: 'Wählen Sie einen aktuellen Einkauf aus. ' });
              }
              this.loadEinkäufe(this.selectedEinkauf);
            })
          }
        })

      })

    }

    if (this.taskModel != null) {
      if (this.taskModel.typeInfoId) {
        this.sortimentsTypen$.subscribe(typen => {
          this.selectedType = typen.find(f => f.assortmenttypeId == this.taskModel.typeInfoId);
        }).unsubscribe()
      }
      let sub = this.sortimentsKlammern$.subscribe(x => {
        let temp = x.filter(y => y.assortmentmasterId == this.taskModel.klammerId);
        if (temp.length > 0) {
          this.selectedKlammer = temp[0];
        }
      });
      sub.unsubscribe();
      if (this.taskModel.sortimentIds != null && this.taskModel.sortimentIds.length > 0) {
        sub = this.sortiments$.subscribe(x => {
          const temp = x.filter(y => y.assortmentId == this.taskModel.sortimentIds[0]);
          if (temp.length > 0) {
            this.selectedSortiment = temp[0];
          }
        });
        sub.unsubscribe();
      }
      if (this.taskModel.selectedValidationId != null) {
        this.validationProfiles$.pipe((take(1))).subscribe(x => {
          const temp = x.filter(y => y.id == this.taskModel.selectedValidationId);
          if (temp.length > 0) {
            this.selectedList = temp[0];
          } else if ( this.taskModel.selectedValidationId > 0)
          {
            this.onError.emit({ key: 'errorMessage', severity: 'error', summary: 'Der Listentyp wurde nicht gefunden', detail: 'Bitte wählen Sie eine aktuelle Liste aus.' });
              this.taskModel.selectedValidationId = null;
          }
        });

      }
    }
    this.taskModel.taskValidRange = null;
    this.taskModel.taskActiveRange = null;
    if (this.taskModel.taskValidRangeFrom != null && this.taskModel.taskValidRangeTo != null) {
      this.taskModel.taskValidRange = [];
      this.taskModel.taskValidRange.push(momentLib(this.taskModel.taskValidRangeFrom).toDate());
      this.taskModel.taskValidRange.push(momentLib(this.taskModel.taskValidRangeTo).toDate());
    }
    if (this.taskModel.taskActiveRangeFrom != null && this.taskModel.taskActiveRangeUntil != null) {
      this.taskModel.taskActiveRange = [];
      this.taskModel.taskActiveRange.push(momentLib(this.taskModel.taskActiveRangeFrom).toDate());
      this.taskModel.taskActiveRange.push(momentLib(this.taskModel.taskActiveRangeUntil).toDate());
    }
    if (this.taskModel.invitationDate != null) {
      this.taskModel.invitationDate = momentLib(this.taskModel.invitationDate).toDate();
    }
    if (this.taskModel.dateInviteOne != null) {
      this.taskModel.dateInviteOne = momentLib(this.taskModel.dateInviteOne).toDate();
    }
    if (this.taskModel.dateInviteTwo != null) {
      this.taskModel.dateInviteTwo = momentLib(this.taskModel.dateInviteTwo).toDate();
    }
    if (this.taskModel.dateInviteThree != null) {
      this.taskModel.dateInviteThree = momentLib(this.taskModel.dateInviteThree).toDate();
    }
    if (this.taskModel.uploadFiles == null) {
      this.taskModel.uploadFiles = { parentId: this.taskModel.id, userId: this.taskModel.userId, files: [] };
    }
  }

  private loadEinkäufe(selectedEinkauf: EinkaufDtoClient) {
    this.einkaeufe$ = this.novitaetenlookupservice.selectzukuenftigeEinkaeufe$.pipe(map(zukuenftige => {
      if (selectedEinkauf && selectedEinkauf.id) {
        if (zukuenftige.find(e => e.einkaufLongId == this.selectedEinkauf.einkaufLongId) == undefined) {
          zukuenftige = this.luipocheck.getClone(zukuenftige);
          var copy = { ...this.selectedEinkauf } as EinkaufDtoClient;
          copy.readonly = true; // der ist nicht korrekt, weil nicht in den zufünftigen
          zukuenftige.push(copy);
        }
      }
      return zukuenftige;
    }));
  }

  canselectEinkauf(aufgabenArt: LookupValueBase) {
    if (aufgabenArt == undefined) return false;
    return aufgabenArt.id == AufgabenArt.WeitereBedarfe;
  }

  hasValidEinkauf(e: EinkaufDtoClient) {
    if (e == undefined) return true;
    return e.readonly == false;
  }


  canSelectAufgabenArt() {
    return this.editMode == 'new';
  }


  changedEinkauf() {
    if (this.selectedAufgabenart.id == AufgabenArt.WeitereBedarfe) {
      this.taskModel.taskActiveRange = [];
      this.taskModel.taskActiveRange.push(new Date());
      this.taskModel.taskActiveRange.push(momentLib(this.selectedEinkauf.stop).toDate());
      this.taskModel.einkaufId = this.selectedEinkauf.einkaufLongId;
      if (this.taskModel.taskValidRange && this.taskModel.taskValidRange.length == 2) {
        if (this.luipocheck.isDate(this.taskModel.taskValidRange[0]) && this.taskModel.taskValidRange[0] < momentLib(this.selectedEinkauf.stop).toDate()) {
          this.taskModel.taskValidRange = []; // zu alt
        }
      }
      this.loadEinkäufe(this.selectedEinkauf);
    }

  }


  changedAufgabenart() {
    this.taskModel.aufgabenArt = this.selectedAufgabenart.id;
    if (this.selectedAufgabenart.id == AufgabenArt.WeitereBedarfe) {
      this.novitaetenlookupservice.loadZukuenftigeEinkaeufe(true);
      this.taskModel.includeUpload = true;
    } else {
      this.taskModel.includeUpload = false;
      this.taskModel.einkaufId = null;
    }
  }

  filterSortimentByMaster() {
    if (this.selectedKlammer == undefined) return;
    this.taskModel.klammerId = this.selectedKlammer.assortmentmasterId;
    this.selectedSortimentsForMaster = [];
    this.sortimentsToView$ = this.sortiments$.pipe(map(x => x.filter(y => y.klammer.assortmentmasterId == this.taskModel.klammerId)));
    this.sortimentsToView$.subscribe(filtered => {
      this.selectedSortiment = filtered.find(f => this.selectedSortiment && f.id == this.selectedSortiment.id); // u.U leer
    })
  }

  setValidationList() {
    this.taskModel.selectedValidationId = this.selectedList.id;
    this.taskModel.selectedValidation = this.selectedList.listName;
  }

  setSortimentType() {
    if (this.selectedType == undefined) {
      this.taskModel.typeInfoId = undefined;
      this.sortimentsToView$ = this.sortiments$.pipe(map(x => {
        return x.filter(y => y.klammer.assortmentmasterId == this.taskModel.klammerId)
      }
      ));
    } else {
      this.taskModel.typeInfoId = this.selectedType.assortmenttypeId;
      this.sortimentsToView$ = this.sortiments$.pipe(map(x => {
        return x.filter(y => y.klammer.assortmentmasterId == this.taskModel.klammerId && y.typ.assortmenttypeId == this.taskModel.typeInfoId)
      }
      ));
    }
  }

  onSelectValidRange(value: Date) {
    console.log('onSelectValidRange', value);
    this.changeValidRange();
  }

  onInputValidRange(event: InputEvent) {
    console.log('onInputValidRange', event);
    this.changeValidRange();
  }

  changeValidRange() {
    // Änderung auch dann, wenn bereits ein Eintrag vorhanden ist #6055
    /* if (this.taskModel.taskValidRange !== undefined && this.taskModel.taskValidRange !== null && this.taskModel.taskValidRange.length > 1) {
      return;
    } */
    if (this.taskModel.taskActiveRange === undefined || this.taskModel.taskActiveRange === null || this.taskModel.taskActiveRange.length < 2) {
      return;
    }

    let start = momentLib(this.taskModel.taskActiveRange[0]);
    const startDate = start.clone().subtract(8, 'week').toDate();
    // startDate.setDate(this.taskActivityRange[0].getDate() - 30);
    this.taskModel.taskValidRange = [];
    this.taskModel.taskValidRange.push(startDate);
    const endDate = start.clone().subtract(30, 'day').toDate();
    // endDate.setDate(this.taskActivityRange[1].getDate() - 1);
    this.taskModel.taskValidRange.push(endDate);

    start = momentLib(this.taskModel.taskValidRange[0]);
    this.taskModel.invitationDate = start.clone().subtract(environment.inviteDiff, 'day').toDate();
    let end = momentLib(endDate);
    this.taskModel.dateInviteOne = end.clone().subtract(environment.inviteOneDiff, 'day').toDate();

    //Erinnerung 2 und 3 sollen leer bleiben bzw. bei Änderung geleert werden #6055
    this.taskModel.dateInviteTwo = null;
    this.taskModel.sendInviteTwo = false;
    this.taskModel.dateInviteThree = null;
    this.taskModel.sendInviteThree = false;

  }

  uploadFiles(event) {
    if (event.files.length > 5) {
      alert('Es dürfen maximal 5 Dateien für den Upload angegeben werden');
      return;
    }
    this.uploadedFiles.userId = this.taskModel.userId;
    this.uploadedFiles.files = [];
    var warntext = "";
    for (const file of event.files) {
      if (this.taskModel.uploadFiles != undefined && this.taskModel.uploadFiles.files.filter(t => t.fileName == file.name).length > 0) {
        warntext += "Datei " + file.name + " wurde bereits gespeichert\n";
        continue;
      }
      if (this.uploadedFiles.files != undefined && this.uploadedFiles.files.filter(t => t.fileName == file.name).length > 0) {
        warntext += "Datei " + file.name + " wurde bereits gespeichert\n";
        continue;
      }

      this.uploadedFiles.files.push(file);
    }

    if (warntext.length > 0) {
      this.clearMessages();
      this.msgService.add({ key: 'errorMessage', severity: 'warn', summary: 'Hinweise zum Hochladen', detail: warntext });
    }
  }

  createUploadFile(uploadItem: UploadFileModel, user: any): Promise<UploadFileDto> {
    return new Promise(async (resolve, reject) => {
      const upload: UploadFileDto = {
        parentId: this.taskModel.id,
        userId: this.taskModel.userId,
        files: []
      };
      for (const uploadFile of uploadItem.files) {
        const fileDesc = await this.getFileFromReader(uploadFile);
        if (upload.files.length == 0 || upload.files.filter(f => f && f.fileName == fileDesc.fileName).length == 0) {
          upload.files.push(fileDesc);
        }
      }
      resolve(upload);
    });
  }

  getFileFromReader(uploadFile: any): Promise<FileDescriptionModel> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const fileDesc: FileDescriptionModel = { id: null, fileName: '', fileType: '', fileBase64: '', initialLoad: false, fileExtension: '' };
      reader.readAsDataURL(uploadFile);
      reader.onload = () => {
        fileDesc.fileName = uploadFile.name;
        fileDesc.fileType = uploadFile.type;
        fileDesc.fileBase64 = reader.result.toString().split(',')[1];
        resolve(fileDesc);
      };
    });
  }

  /**Hinzugefügte Dateien anzeigen */
  getVorhandeneDateien(): any[] {

    if (this.taskModel != undefined && this.taskModel.uploadFiles != undefined && this.taskModel.uploadFiles.files != undefined) {
      let sorted = [...this.taskModel.uploadFiles.files].sort((a, b) => a.fileName > b.fileName ? 1 : -1);
      return sorted;
    }
    return [];
  }


  /**Entfernen existierender files */
  removeExistingFile(rowData: any) {
    if (rowData == undefined) return
    if (rowData.hasOwnProperty("id")) {
      if (rowData.id != undefined) {
        this.store.dispatch(new DeleteUploadAction({ id: rowData.id }));

        this.taskModel.uploadFiles.files = [...this.taskModel.uploadFiles.files.filter(t => t.id != rowData.id)];
      } else {
        this.clearMessages();
        this.msgService.add({ key: 'errorMessage', severity: 'error', summary: 'Löschen nicht möglich', detail: 'Datei konnte nicht ermittelt werden. ' });
      }
    }
  }

  /**
   * Entfernen entweder neuer Files
   * @param rowData
   */

  removeFile(rowData: any) {

    if (rowData instanceof File) // File Object
    {
      if (this.fileUpload != undefined) {
        this.fileUpload.files = this.fileUpload.files.filter(t => t.name != rowData.name);
      }
      if (this.fileUploadNew != undefined) {
        this.fileUploadNew.files = this.fileUploadNew.files.filter(t => t.name != rowData.name);
      }

      if (this.uploadedFiles.files != undefined) {
        this.uploadedFiles.files = this.uploadedFiles.files.filter(t => t.name != rowData.name);
      }
    } else if (rowData.hasOwnProperty("fileName")) {
      if (this.fileUpload != undefined) {
        this.fileUpload.files = this.fileUpload.files.filter(t => t.name != rowData.fileName);
      }
      if (this.fileUploadNew != undefined) {
        this.fileUploadNew.files = this.fileUploadNew.files.filter(t => t.name != rowData.fileName);
      }

      if (this.uploadedFiles.files != undefined) {
        this.uploadedFiles.files = this.uploadedFiles.files.filter(t => t.name != rowData.fileName);
      }
      this.taskModel.uploadFiles.files = this.taskModel.uploadFiles.files.filter(t => t.fileName != rowData.fileName);
    }
  }

  canSave(): boolean {
    return this._canSave;
  }
  close() {
    this.msgService.clear();
    this.ref.close(null);
  }

  /**Nur sichern wenn auch editierbare Lieferanten vorhanden */
  canSavewithLieferanten(): boolean {
    return (this.editMode == 'edit' && this.children && this.children.filter(g => g.data.taskState < ActionStates.New).length > 0);
  }


  canLoadLieferantType(taskModel: TaskUserModel) {
    return this.canLoadSnapshot(taskModel) && taskModel.loadSnapshot == true  ;
  }


  canLoadSnapshot(taskModel: TaskUserModel) {
    return  taskModel && taskModel.includeUpload &&  taskModel.aufgabenArt != AufgabenArt.WeitereBedarfe;
  }

  disableCanSnapshot(taskModel: TaskUserModel) : boolean {
     if (taskModel.aufgabenArt != AufgabenArt.WeitereBedarfe  && this.children instanceof Array && this.children.length > 0)
     {
        return true;
     }
      return  (taskModel.taskParentId != null  );
  }


  saveTaskwithChildren() {
    if (this.children && this.children.filter(t => t.data != undefined && t.data.taskState < ActionStates.New).length > 0) {
      this.refdialog = this.confirmationsService.confirm({
        message: "Aufgabe auch bei den Lieferanten speichern ?",
        acceptLabel: 'OK',
        reject: () => {
          this.refdialog.close();
        },
        accept: () => {
          this.saveTask(true);
        }
      });


    } else {
      this.saveTask(false);
    }

  }
  async saveTask(updateChildren: boolean) {

    try {


      this._canSave = false;


      if (this.taskModel.aufgabenArt == AufgabenArt.Artikelliste && this.taskModel.sendInvite && this.taskModel.sendInviteOne && !this.taskModel.dateInviteOne) {
        this.clearMessages();
        this.msgService.add({ key: 'errorMessage', severity: 'warn', summary: 'Speichern nicht möglich', detail: 'Bitte wählen Sie einen Erinnerung 1 aus.' });
        this._canSave = true;
        return;
      }
      if (this.taskModel.aufgabenArt == AufgabenArt.Artikelliste && this.taskModel.sendInvite && this.taskModel.sendInviteTwo && !this.taskModel.dateInviteTwo) {
        this.clearMessages();
        this.msgService.add({ key: 'errorMessage', severity: 'warn', summary: 'Speichern nicht möglich', detail: 'Bitte wählen Sie einen Erinnerung 2 aus.' });
        this._canSave = true;
        return;
      }

      if (this.taskModel.aufgabenArt == AufgabenArt.Artikelliste && this.taskModel.sendInvite && this.taskModel.sendInviteThree && !this.taskModel.dateInviteThree) {
        this.clearMessages();
        this.msgService.add({ key: 'errorMessage', severity: 'warn', summary: 'Speichern nicht möglich', detail: 'Bitte wählen Sie einen Erinnerung 3 aus.' });
        this._canSave = true;
        return;
      }

      if (this.taskModel.aufgabenArt == AufgabenArt.WeitereBedarfe && this.taskModel.einkaufId == null) {
        this.clearMessages();
        this.msgService.add({ key: 'errorMessage', severity: 'warn', summary: 'Speichern nicht möglich', detail: 'Bitte wählen Sie einen Einkauf aus.' });
        this._canSave = true;
        return;
      }


      if (this.taskModel.aufgabenArt == AufgabenArt.WeitereBedarfe && this.selectedList == null) {
        this.clearMessages();
        this.msgService.add({ key: 'errorMessage', severity: 'warn', summary: 'Speichern nicht möglich', detail: 'Bitte wählen Sie eine Liste aus.' });
        this._canSave = true;
        return;
      }

      if (this.taskModel.includeUpload && this.selectedList == null) {
        this.clearMessages();
        this.msgService.add({ key: 'errorMessage', severity: 'error', summary: 'Speichern nicht möglich', detail: 'Bitte wählen Sie eine Listenart aus' });
        this._canSave = true;
        return;
      }
      if ((this.selectedSortiment == null && (this.selectedSortimentsForMaster == null || this.selectedSortimentsForMaster.length == 0) && (this.taskModel.sortimentIds == null || this.taskModel.sortimentIds.length == 0)) && (this.taskModel.aufgabenArt == AufgabenArt.Novität || this.taskModel.aufgabenArt == AufgabenArt.Artikelliste)) {
        this.clearMessages();
        this.msgService.add({ key: 'errorMessage', severity: 'error', summary: 'Speichern nicht möglich', detail: 'Bitte wählen Sie ein Sortiment aus' });
        this._canSave = true;
        return;
      }
      if (this.taskModel.name == null || this.taskModel.name.length < 3) {
        this.clearMessages();
        this.msgService.add({ key: 'errorMessage', severity: 'error', summary: 'Speichern nicht möglich', detail: 'Bitte geben Sie einen Aufgabentext ein' });
        this._canSave = true;
        return;
      }
      if (this.taskModel.taskActiveRange == null || this.taskModel.taskActiveRange.length != 2 || !this.luipocheck.isDate(this.taskModel.taskActiveRange[1]) ) {
        this.clearMessages();
        this.msgService.add({ key: 'errorMessage', severity: 'error', summary: 'Speichern nicht möglich', detail: 'Bitte geben Sie einen gültigen Zeitraum für die Laufzeit an' });
        this._canSave = true;
        return;
      }
      if (this.taskModel.taskValidRange == null || this.taskModel.taskValidRange.length != 2 || !this.luipocheck.isDate(this.taskModel.taskValidRange[1])  ) {
        this.clearMessages();
        this.msgService.add({ key: 'errorMessage', severity: 'error', summary: 'Speichern nicht möglich', detail: 'Bitte geben Sie einen gültigen Zeitraum für die Bearbeitung an' });
        this._canSave = true;
        return;
      }
      this.taskModel.sortimentIds = [];
      if (this.targetType == 'supplier') {
        if (this.selectedSortiment) this.taskModel.sortimentIds.push(this.selectedSortiment.assortmentId);
      }
      if (this.targetType == 'master' && this.editMode == 'new') {
        if (this.selectedSortimentsForMaster && this.selectedSortimentsForMaster.length > 0) {
          this.selectedSortimentsForMaster.forEach(x => this.taskModel.sortimentIds.push(x.assortmentId));
        }
      }
      if (this.targetType == 'master' && this.editMode != 'new') {
        if (this.selectedSortiment) this.taskModel.sortimentIds.push(this.selectedSortiment.assortmentId);
      }
      // Handle all the date shit
      if (this.taskModel.taskValidRange != null && this.taskModel.taskValidRange.length == 2) {
        this.taskModel.taskValidRangeFrom = JSON.parse(JSON.stringify(this.taskModel.taskValidRange[0]));
        this.taskModel.taskValidRangeTo = JSON.parse(JSON.stringify(this.taskModel.taskValidRange[1]));
      }
      if (this.taskModel.taskActiveRange != null && this.taskModel.taskActiveRange.length == 2) {
        this.taskModel.taskActiveRangeFrom = JSON.parse(JSON.stringify(this.taskModel.taskActiveRange[0]));
        this.taskModel.taskActiveRangeUntil = JSON.parse(JSON.stringify(this.taskModel.taskActiveRange[1]));
      }
      if (this.taskModel.invitationDate != null) {
        this.taskModel.invitationDate = JSON.parse(JSON.stringify(this.taskModel.invitationDate));
      }
      if (this.taskModel.dateInviteOne != null) {
        this.taskModel.dateInviteOne = JSON.parse(JSON.stringify(this.taskModel.dateInviteOne));
      }
      if (this.taskModel.dateInviteTwo != null) {
        this.taskModel.dateInviteTwo = JSON.parse(JSON.stringify(this.taskModel.dateInviteTwo));
      }
      if (this.taskModel.dateInviteThree != null) {
        this.taskModel.dateInviteThree = JSON.parse(JSON.stringify(this.taskModel.dateInviteThree));
      }

      if (this.editMode == 'new' && this.targetType == 'master') {
        // Handle file uploads for new Tasks
        if (this.uploadedFiles != null) {

            const newFileDto = await this.createUploadFile(cloneDeep(this.uploadedFiles), this.taskModel.userId);
            this.taskModel.uploadFiles = newFileDto;

        }
        this.store.dispatch(new CreateMasterTaskAction({ item: this.taskModel }));

        if (this.actionCreateSubscription) this.actionCreateSubscription.unsubscribe();


        this.actionCreateSubscription = this._actions$.pipe(ofType<CreateMasterTaskDoneAction>(TaskListActionType.CreateMasterTaskDone)).pipe(filter(g => g != undefined && g.payload.item != undefined), first()).subscribe((data: any) => {
          try {
            this.clearMessages();
            this._canSave = true;
            this.msgService.add({ key: 'successMessage', severity: 'success', summary: 'Daten für Aufgabe "' + this.taskModel.name + '" wurden gespeichert' });
            if (this.fileUpload != undefined) {
              this.fileUpload.clear();
            }
            this.uploadedFiles = { userId: null, files: [] };
            if (data.payload.item) {
              var it = data.payload.item as ResultObjectModel<any>;
              this.taskModel.uploadFiles = cloneDeep(it.data[0].uploadFiles); // Neue Ids aktualisieren hier
            }

            if (this.taskModel.invitationDate != null) {
              this.taskModel.invitationDate = momentLib(this.taskModel.invitationDate).toDate();
            }

            if (this.taskModel.dateInviteOne != null) {
              this.taskModel.dateInviteOne = momentLib(this.taskModel.dateInviteOne).toDate();
            }
            if (this.taskModel.dateInviteTwo != null) {
              this.taskModel.dateInviteTwo = momentLib(this.taskModel.dateInviteTwo).toDate();
            }
            if (this.taskModel.dateInviteThree != null) {
              this.taskModel.dateInviteThree = momentLib(this.taskModel.dateInviteThree).toDate();
            }

            this.onSave.emit(this.taskModel);

            if (this.taskModel.sortimentIds.length > 1) {
              this.msgService.clear();
              this.ref.close(null);
            }
          } catch (e) {
            this._canSave = true;
          }
        }, e => {
          this._canSave = true;
        });
      }
      if (this.editMode == 'edit') {
        // Handle file uploads for new Tasks
        if (this.uploadedFiles != null) {

            const newFileDto = await this.createUploadFile(this.uploadedFiles, this.taskModel.userId);
            newFileDto.files.forEach(x => this.taskModel.uploadFiles.files.push(x));

        }
        if (this.actionSubscription) this.actionSubscription.unsubscribe();


        this.store.dispatch(new UpdateTaskAction({ item: this.taskModel, updateChildren: updateChildren }));

        this.actionSubscription = this._actions$.pipe(ofType<UpdateTaskDoneAction>(TaskListActionType.UpdateTaskDone)).pipe(filter(f => f.payload && f.payload.items != undefined), first()).subscribe((data: any) => {
          try {
            this._canSave = true;
            this.clearMessages();
            this.msgService.add({ key: 'successMessage', severity: 'success', summary: 'Daten für Aufgabe "' + this.taskModel.name + '" wurden gespeichert' });
            if (this.fileUpload != undefined) {
              this.fileUpload.clear();
            }
            this.uploadedFiles = { userId: null, files: [] };

            if (data.payload.items.length > 0) {
              this.taskModel.uploadFiles = cloneDeep(data.payload.items[0].uploadFiles); // Geänderte Ids aktualisieren hier
            } else {
              this.taskModel.uploadFiles = <UploadFileDto>{};
            }

            if (this.taskModel.invitationDate != null) {
              this.taskModel.invitationDate = momentLib(this.taskModel.invitationDate).toDate();
            }
            if (this.taskModel.dateInviteOne != null) {
              this.taskModel.dateInviteOne = momentLib(this.taskModel.dateInviteOne).toDate();
            }
            if (this.taskModel.dateInviteTwo != null) {
              this.taskModel.dateInviteTwo = momentLib(this.taskModel.dateInviteTwo).toDate();
            }
            if (this.taskModel.dateInviteThree != null) {
              this.taskModel.dateInviteThree = momentLib(this.taskModel.dateInviteThree).toDate();
            }

            this.onSave.emit(this.taskModel);
          } catch (e) {
            this._canSave = true;
          }
        }, e => {
          this._canSave = true;
        });


      }

      this.editMode = 'edit';
      this.taskModel = cloneDeep(this.taskModel);



    } catch (e) {
      this._canSave = true;
      this.clearMessages();
      this.msgService.add({ key: 'errorMessage', severity: 'error', summary: 'Fehler beim Speichern ', detail: e.toString() });
    }
  }

  clearMessages() {
    this.msgService.clear('successMessage');
    this.msgService.clear('errorMessage');
  }

}
