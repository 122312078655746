import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskEditorComponent } from './task-editor.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';

import { TaskEditorStateModule } from './task-editor.state.module';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToastModule } from 'primeng/toast';
import { ListboxModule } from 'primeng/listbox';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {InputTextModule} from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import {InputSwitchModule} from 'primeng/inputswitch';
import {SpinnerModule} from 'primeng/spinner';
import {FileUploadModule} from 'primeng/fileupload';
import { SidebarModule } from 'primeng/sidebar';
import { ToolbarModule } from 'primeng/toolbar';
import { MultiSelectModule } from 'primeng/multiselect';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MessageService } from 'primeng/api';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DropdownModule,
        CheckboxModule,
        TaskEditorStateModule,
        ButtonModule,
        DynamicDialogModule,
        CalendarModule,
        InputTextareaModule,
        ToastModule,
        ListboxModule,
        TableModule,
        TooltipModule,
        ProgressSpinnerModule,
        OverlayPanelModule,
        InputTextModule,
        DialogModule,
        InputSwitchModule,
        SpinnerModule,
        FileUploadModule,
        SidebarModule,
        ToolbarModule,
        MultiSelectModule,
        NgxChartsModule
    ],
    providers: [MessageService],
    exports: [TaskEditorComponent],
    declarations: [TaskEditorComponent]
})

export class TaskEditorModule { }
