<span>
    <hr>
    <div class="ui-g ui-fluid">
        <div class="ui-g-12 ui-md-12">

            <h5>{{ breadcrumb$ | async }}</h5>
            <hr />
            <h4>{{ taskToView.name }}</h4>

            <br>

            <b>Von:</b> {{ taskToView.creatorFriendlyName }} <b>An:</b> {{ taskToView.fullName }}<br><br>
            <b>Beschreibung:</b> <div [innerHTML] = "convertCarriageReturn(taskToView.taskDescription)"></div>
            <hr>
            <b>Sortiment:</b> {{taskToView.sortimentName}} <br>
            <b>Bearbeitungszeitraum:</b> {{ taskToView.taskValidRange[0] | date }} bis {{ taskToView.taskValidRange[1] | date }}<br>
            <b>Laufzeit:</b> {{ taskToView.taskActiveRange[0] | date }} bis {{ taskToView.taskActiveRange[1] | date }}<br>
            <hr>
            <div *ngIf="taskToView.fileUrls !== null && taskToView.fileUrls.length > 0">
                <table>
                    <tr *ngFor="let fileUrl of taskToView.fileUrls">
                        <td><a href="#" (click)="downloadFile(fileUrl.url, fileUrl.fileType, fileUrl.fileName)">{{fileUrl.fileName}}</a></td>
                    </tr>
                </table>
            </div>
            <div><a href="#" (click)="downloadListForTask()">Excel-Vorlage laden</a></div>
            <!-- <b>Status:</b> not yet implemented<br> -->
            <!-- <span *ngIf="taskModel.hasMessage"> Der Empfänger wurde Benachrichtigt.</span>
            <span *ngIf="!taskModel.hasMessage"><em>Eine Benachrichtigung wird noch versendet</em></span> -->
            <br>
            <!-- <span *ngIf="taskToView.includeUpload && !isManager"><button pButton label="Keine Liste abgeben"
                pTooltip="Die Aufgabe wird übersprungen. Sie erklären damit, dass von Ihnen keine Vorschläge zur Aufgabe eingereicht werden." tooltipPosition="top"
                class="p-button-warning" (onClick)="skip(taskToView.id)"></button> </span> -->
        </div>
    </div>
</span>
