/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfTitelKorridorDto } from '../models/list-response-args-of-titel-korridor-dto';
import { ExtendedQueryTokenDTO } from '../models/extended-query-token-dto';
import { ResponseArgsOfTitelKorridorDto } from '../models/response-args-of-titel-korridor-dto';
import { TitelKorridorDto } from '../models/titel-korridor-dto';
import { ResponseArgsOfBoolean } from '../models/response-args-of-boolean';
@Injectable({
  providedIn: 'root',
})
class TitelKorridorService extends __BaseService {
  static readonly TitelKorridorQueryPath = '/korridor/s';
  static readonly TitelKorridorCreatePath = '/korridor';
  static readonly TitelKorridorUpdatePath = '/korridor/{id}';
  static readonly TitelKorridorDeletePath = '/korridor/{id}';
  static readonly TitelKorridorCopyPath = '/korridor/{einkaufIdFrom}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Liste Liste
   * @param params The `TitelKorridorService.TitelKorridorQueryParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `eagerLoading`:
   */
  TitelKorridorQueryResponse(params: TitelKorridorService.TitelKorridorQueryParams): __Observable<__StrictHttpResponse<ListResponseArgsOfTitelKorridorDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/korridor/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfTitelKorridorDto>;
      })
    );
  }
  /**
   * Liste Liste
   * @param params The `TitelKorridorService.TitelKorridorQueryParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `eagerLoading`:
   */
  TitelKorridorQuery(params: TitelKorridorService.TitelKorridorQueryParams): __Observable<ListResponseArgsOfTitelKorridorDto> {
    return this.TitelKorridorQueryResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfTitelKorridorDto)
    );
  }

  /**
   * Create
   * @param params The `TitelKorridorService.TitelKorridorCreateParams` containing the following parameters:
   *
   * - `payload`: TitelKorridorDto
   *
   * - `user`: User Id oder Token
   */
  TitelKorridorCreateResponse(params: TitelKorridorService.TitelKorridorCreateParams): __Observable<__StrictHttpResponse<ResponseArgsOfTitelKorridorDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/korridor`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfTitelKorridorDto>;
      })
    );
  }
  /**
   * Create
   * @param params The `TitelKorridorService.TitelKorridorCreateParams` containing the following parameters:
   *
   * - `payload`: TitelKorridorDto
   *
   * - `user`: User Id oder Token
   */
  TitelKorridorCreate(params: TitelKorridorService.TitelKorridorCreateParams): __Observable<ResponseArgsOfTitelKorridorDto> {
    return this.TitelKorridorCreateResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfTitelKorridorDto)
    );
  }

  /**
   * Update
   * @param params The `TitelKorridorService.TitelKorridorUpdateParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `id`:
   *
   * - `user`:
   */
  TitelKorridorUpdateResponse(params: TitelKorridorService.TitelKorridorUpdateParams): __Observable<__StrictHttpResponse<ResponseArgsOfTitelKorridorDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/korridor/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfTitelKorridorDto>;
      })
    );
  }
  /**
   * Update
   * @param params The `TitelKorridorService.TitelKorridorUpdateParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `id`:
   *
   * - `user`:
   */
  TitelKorridorUpdate(params: TitelKorridorService.TitelKorridorUpdateParams): __Observable<ResponseArgsOfTitelKorridorDto> {
    return this.TitelKorridorUpdateResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfTitelKorridorDto)
    );
  }

  /**
   * Delete
   * @param params The `TitelKorridorService.TitelKorridorDeleteParams` containing the following parameters:
   *
   * - `id`: Liste Id
   *
   * - `user`: User Id oder Token
   */
  TitelKorridorDeleteResponse(params: TitelKorridorService.TitelKorridorDeleteParams): __Observable<__StrictHttpResponse<ResponseArgsOfBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/korridor/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBoolean>;
      })
    );
  }
  /**
   * Delete
   * @param params The `TitelKorridorService.TitelKorridorDeleteParams` containing the following parameters:
   *
   * - `id`: Liste Id
   *
   * - `user`: User Id oder Token
   */
  TitelKorridorDelete(params: TitelKorridorService.TitelKorridorDeleteParams): __Observable<ResponseArgsOfBoolean> {
    return this.TitelKorridorDeleteResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfBoolean)
    );
  }

  /**
   * Update
   * @param params The `TitelKorridorService.TitelKorridorCopyParams` containing the following parameters:
   *
   * - `einkaufIdFrom`:
   *
   * - `user`:
   *
   * - `overwrite`:
   *
   * - `einkaufIdTo`:
   */
  TitelKorridorCopyResponse(params: TitelKorridorService.TitelKorridorCopyParams): __Observable<__StrictHttpResponse<ListResponseArgsOfTitelKorridorDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    if (params.overwrite != null) __params = __params.set('overwrite', params.overwrite.toString());
    if (params.einkaufIdTo != null) __params = __params.set('einkaufIdTo', params.einkaufIdTo.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/korridor/${encodeURIComponent(String(params.einkaufIdFrom))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfTitelKorridorDto>;
      })
    );
  }
  /**
   * Update
   * @param params The `TitelKorridorService.TitelKorridorCopyParams` containing the following parameters:
   *
   * - `einkaufIdFrom`:
   *
   * - `user`:
   *
   * - `overwrite`:
   *
   * - `einkaufIdTo`:
   */
  TitelKorridorCopy(params: TitelKorridorService.TitelKorridorCopyParams): __Observable<ListResponseArgsOfTitelKorridorDto> {
    return this.TitelKorridorCopyResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfTitelKorridorDto)
    );
  }
}

module TitelKorridorService {

  /**
   * Parameters for TitelKorridorQuery
   */
  export interface TitelKorridorQueryParams {
    queryToken: ExtendedQueryTokenDTO;
    eagerLoading?: number;
  }

  /**
   * Parameters for TitelKorridorCreate
   */
  export interface TitelKorridorCreateParams {

    /**
     * TitelKorridorDto
     */
    payload: TitelKorridorDto;

    /**
     * User Id oder Token
     */
    user?: null | string;
  }

  /**
   * Parameters for TitelKorridorUpdate
   */
  export interface TitelKorridorUpdateParams {
    payload: TitelKorridorDto;
    id: number;
    user?: null | string;
  }

  /**
   * Parameters for TitelKorridorDelete
   */
  export interface TitelKorridorDeleteParams {

    /**
     * Liste Id
     */
    id: number;

    /**
     * User Id oder Token
     */
    user?: null | string;
  }

  /**
   * Parameters for TitelKorridorCopy
   */
  export interface TitelKorridorCopyParams {
    einkaufIdFrom: number;
    user?: null | string;
    overwrite?: boolean;
    einkaufIdTo?: number;
  }
}

export { TitelKorridorService }
