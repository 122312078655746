import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    TaskListActionType,
    GetTasksDoneAction,

    GetTasksForCurrentUserAction,
    CreateUploadAction,
    CreateUploadDoneAction,

    CreateSupplierTasksAction,
    CreateSupplierTasksDoneAction,
    CreateMasterTaskAction,
    CreateMasterTaskDoneAction,
    GetValidationsDoneAction,



    GetValidationsAction,
    UpdateTaskAction,
    UpdateTaskDoneAction,
    DeleteUploadAction,
    DeleteUploadDoneAction,
    DuplicateSupplierAction,
    DuplicateSupplierDoneAction,
    DuplicateMasterAction,
    DuplicateMasterDoneAction,
    DeleteTaskDoneAction,
    DeleteTaskAction,
    ApproveTaskAction,
    ApproveTaskDoneAction,
    SetSingleTaskStateAction,
    SetSingleTaskStateDoneAction,
    GetSuppliergroupsAction,
    GetSuppliergroupsDoneAction,
    GetSuppliersForGroupAction,
    GetSuppliersForGroupDoneAction,
    TasklistErrorAction,
    GetTaskbyIdAction,
    GetTaskbyIdActionDone,
    GetMandantenAction,
    GetMandantenActionDone,
    CreateMandantenTasksAction,
    CreateMandantenTasksActionDone,
    GetTaskUsersAktion,
    GetTaskUsersAktionDone,
    GetLieferantenUserActionDone,
    GetLieferantenUserAction
} from './actions';
import { withLatestFrom, switchMap, tap, map, catchError, mergeMap } from 'rxjs/operators';
import { DialogService } from 'primeng/dynamicdialog';
import { selectTaskListState } from './state';
import { TaskService } from '@app/common/services/task.service';
import { SettingService } from '@app/common/services/setting.service';
import { ValidationEditService } from '@app/common/services/validation-edit.service';
import { UploadStagingService } from '@app/common/services/upload-staging.service';
import { Router } from '@angular/router';
import { AddNavigationTabAction } from '@app/store/navigation/actions';
import { TaskViewComponent } from '../task-view.component';
import { GeneralResultEnum } from '@app/common/models';
import { SimpleResult } from '@app/common/models/simpleResult.model';
import { of } from 'rxjs/internal/observable/of';
import { Action } from 'rxjs/internal/scheduler/Action';

@Injectable()
export class TasklistEffects {
 //@ts-ignore
  state$ = this.store.pipe(select(selectTaskListState));
    constructor(
        private store: Store<any>,
        private actions: Actions,
        private service: TaskService,
        private settingService: SettingService,
        private validationService: ValidationEditService,
        private dialogService: DialogService,
        private uploadService: UploadStagingService,
        private router: Router
    ) {


     }


    getTasks$ = createEffect(() => { return  this.actions.pipe(
        ofType<GetTasksForCurrentUserAction>(TaskListActionType.GetTasksForCurrentUser),
        tap(console.log.bind(window)),
        withLatestFrom(this.state$),
        switchMap(([action, state]) => this.service.getAllTasks(action.payload.params).pipe(
            map(response => new GetTasksDoneAction({ item: response }))
        ))

    )})





    getLieferantenUser$ = createEffect(() => { return  this.actions.pipe(
      ofType<GetLieferantenUserAction>(TaskListActionType.GetLieferantenUserAction),
      switchMap((action) => this.service.getLieferantenUser(action.payload.headerTaskId, action.payload.categoryManager)),
      tap(console.log.bind(window)),
      map(response => new GetLieferantenUserActionDone( { lieferanten: response }))
      )})



    getMandanten$ = createEffect(() => { return  this.actions.pipe(
      ofType<GetMandantenAction>(TaskListActionType.GetMandantenAction),
      switchMap((action) => this.service.getMandanten(action.payload.headerTaskId)),
      tap(console.log.bind(window)),
      map(response => new GetMandantenActionDone( { mandanten: response }))
      )})





    GetTaskbyId$ = createEffect(() => { return  this.actions.pipe(
        ofType<GetTaskbyIdAction>(TaskListActionType.GetTaskbyIdAction),
        withLatestFrom(this.state$),
        switchMap(([action, state]) => this.service.getTaskbyId(action.payload.taskid )),
        tap(console.log.bind(window)),
        map(response => new GetTaskbyIdActionDone({ task: response }))
        )})






    createUpload$ = createEffect(() => { return  this.actions.pipe(
        ofType<CreateUploadAction>(TaskListActionType.CreateUpload),
        switchMap((action) =>
            this.uploadService.create({ attributeId: action.payload.taskId, task: action.payload.task })
                .pipe(
                    map(response => new CreateUploadDoneAction({ item: response, taskId: action.payload.taskId }))
                )
        )
        )})


    createUploadDone$ = createEffect(() => { return  this.actions.pipe(
        ofType<CreateUploadDoneAction>(TaskListActionType.CreateUploadDone),
        tap((action) => {
            if (action.payload.item.generalResult === null || action.payload.item.generalResult === 0 ) {
                this.store.dispatch(new AddNavigationTabAction({ displayName: 'Neuer Upload', url: `list-editor/${action.payload.item.data}/task` }));
                this.router.navigate([`list-editor/${action.payload.item.data}/task`]);
            }
        }
        )
        )})

    setTaskApproval = createEffect(() => { return  this.actions.pipe(
        ofType<ApproveTaskAction>(TaskListActionType.ApproveTask),
        switchMap((action) =>
            this.service.setTaskState(action.payload.taskId, action.payload.taskState).pipe(
                map(response => new ApproveTaskDoneAction({ taskId: response }))
            )
        )
        )})



    setSingleTaskStateAction = createEffect(() => { return  this.actions.pipe(
        ofType<SetSingleTaskStateAction>(TaskListActionType.SetSingleTaskStateAction),
        switchMap((action) =>
            this.service.setSingleTaskStateAction(action.payload.taskId, action.payload.taskState).pipe(
                map(response => new SetSingleTaskStateDoneAction({ taskId: response, taskState: action.payload.taskState }))
            )
        )
        )})



    createSupplierTasks$ = createEffect(() => { return  this.actions.pipe(
        ofType<CreateSupplierTasksAction>(TaskListActionType.CreateSupplierTasks),
        withLatestFrom(this.state$),
        switchMap(([action, state]) => this.service.createSupplierTasks(action.payload.params)
        .pipe( map(response => new CreateSupplierTasksDoneAction({ taskModels: response.tasks, parentId: action.payload.params.parentId })))
        ))})


        createMandantenTasks$ = createEffect(() => { return  this.actions.pipe(
          ofType<CreateMandantenTasksAction>(TaskListActionType.CreateMandantenTasksAction),
          withLatestFrom(this.state$),
          switchMap(([action, state]) => this.service.createMandantenTasks(action.payload.taskModel, action.payload.mandanten, action.payload.parentId)),
          map(response => new CreateMandantenTasksActionDone({ taskModels: response.tasks, parentId: response.parentId }))
          )})


    createTask$ = createEffect(() => { return  this.actions.pipe(
        ofType<CreateMasterTaskAction>(TaskListActionType.CreateMasterTask),
        switchMap((action) =>
            this.service.createMasterTask(action.payload.item).pipe(
                    map(response => new CreateMasterTaskDoneAction({ item: response }) )
                )
        )
        )})


    updateTask$ = createEffect(() => { return  this.actions.pipe(
        ofType<UpdateTaskAction>(TaskListActionType.UpdateTask),
        withLatestFrom(this.state$),
        switchMap(([action, state]) => this.service.updateTask(action.payload.item, action.payload.updateChildren)),
        map(response => new UpdateTaskDoneAction({ items: response.data }))
        )})


    deleteUpload = createEffect(() => { return  this.actions.pipe(
        ofType<DeleteUploadAction>(TaskListActionType.DeleteUpload),
        withLatestFrom(this.state$),
        switchMap(([action, state]) => this.service.deleteUpload(action.payload.id)),
        map(response => new DeleteUploadDoneAction({ id: response.data }))
        )})


    loadValidations$ = createEffect(() => { return  this.actions.pipe(
        ofType<GetValidationsAction>(TaskListActionType.GetValidations),
        withLatestFrom(this.state$),
        switchMap(([action, state]) =>
            this.validationService.getValidationProfiles()),
            map(response => new GetValidationsDoneAction({validations: response}))
            )})


    duplicateSupplierTask = createEffect(() => { return  this.actions.pipe(
        ofType<DuplicateSupplierAction>(TaskListActionType.DuplicateSupplier),
        withLatestFrom(this.state$),
        switchMap(([action, state]) =>
            this.service.duplicateSupplierTask(action.payload.taskId).pipe(
        map(response => new DuplicateSupplierDoneAction({ item: response.data }))
    )))
  })



    duplicateMasterTask = createEffect(() => { return  this.actions.pipe(
        ofType<DuplicateMasterAction>(TaskListActionType.DuplicateMaster),
        withLatestFrom(this.state$),
        switchMap(([action]) =>
            this.service.duplicateMasterTask(action.payload.taskId).pipe(
        map(response => new DuplicateMasterDoneAction({ oldtaskId:action.payload.taskId, item: response.data}))
    )))})


    deleteTask = createEffect(() => { return  this.actions.pipe(
        ofType<DeleteTaskAction>(TaskListActionType.DeleteTask),
        withLatestFrom(this.state$),
        switchMap(([action, state]) =>
            this.service.deleteTask(action.payload.taskId)),
        map(response => { return      new DeleteTaskDoneAction({ taskId: response.data })        })        )})


    getSupplierGroups$ = createEffect(() => { return  this.actions.pipe(
        ofType<GetSuppliergroupsAction>(TaskListActionType.GetSuppliergroups),
        withLatestFrom(this.state$),
        switchMap((action) =>
            this.settingService.getSuppliergroups().pipe(
                map(response => {
                    if (response.generalResult != GeneralResultEnum.Success) {
                        return new TasklistErrorAction({ error: <SimpleResult>response });
                    } else {
                        return new GetSuppliergroupsDoneAction( {  groups:  response.data.sort((a, b) => (a.label < b.label ? -1 : 1)) });
                    }
                })
            )
        ),  catchError(error => of(new TasklistErrorAction({ error: error } )))
        )})


    getSuppliersForGroup$ = createEffect(() => { return  this.actions.pipe(
        ofType<GetSuppliersForGroupAction>(TaskListActionType.GetSuppliersForGroup),
        withLatestFrom(this.state$),
        switchMap(([action, state]) =>
            this.settingService.getSuppliers(action.payload.group.id).pipe(
                map(response => {
                    if (response.generalResult != GeneralResultEnum.Success) {
                        return new TasklistErrorAction({ error: <SimpleResult>response });
                    } else {
                        return new GetSuppliersForGroupDoneAction({ suppliers: response.data.sort((a, b) => (a.suppliername < b.suppliername ? -1 : 1)) });
                    }
                })
            )
        ),  catchError(error => of(new TasklistErrorAction({ error: error } )))
        )})

        getTaskUsersAktion$ = createEffect(() => { return  this.actions.pipe(
          ofType<GetTaskUsersAktion>(TaskListActionType.GetTaskUsersAktion),
          withLatestFrom(this.state$),
          mergeMap(([action, state]) =>
              this.service.getTaskUsers(action.payload.taskId).pipe(
                  map(response => {
                      if (response.generalResult != GeneralResultEnum.Success) {
                          return new TasklistErrorAction({ error: <SimpleResult>response });
                      } else {
                          return new GetTaskUsersAktionDone({ result: response });
                      }
                  })
              )
          ),  catchError(error => of(new TasklistErrorAction({ error: error } )))
          )})









}
