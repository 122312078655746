import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { SendMessageTypeContainer, SignalMessageContentModel } from '@app/common/models/signal.model';

@Component({
    selector: 'app-notification',
    templateUrl: 'notification.component.html'
})

export class NotificationComponent implements OnInit {

    @Input()
    notification: SendMessageTypeContainer<SignalMessageContentModel>;
    @Input()
    title: string;
    constructor(private store: Store<any>) { }

    ngOnInit() {
      console.debug(this.notification);
    }




}
