/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfLieferantDto } from '../models/list-response-args-of-lieferant-dto';
import { ExtendedQueryTokenDTO } from '../models/extended-query-token-dto';
import { ListResponseArgsOfUsersApiDTO } from '../models/list-response-args-of-users-api-dto';
import { ResponseArgsOfLieferantDto } from '../models/response-args-of-lieferant-dto';
import { LieferantDto } from '../models/lieferant-dto';
import { ResponseArgsOfBoolean } from '../models/response-args-of-boolean';
@Injectable({
  providedIn: 'root',
})
class LieferantService extends __BaseService {
  static readonly LieferantQueryPath = '/lieferant/s';
  static readonly LieferantSearchUsersPath = '/lieferant/users';
  static readonly LieferantCreatePath = '/lieferant';
  static readonly LieferantUpdatePath = '/lieferant/{id}';
  static readonly LieferantDeletePath = '/lieferant/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Query
   * @param params The `LieferantService.LieferantQueryParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `eagerLoading`:
   */
  LieferantQueryResponse(params: LieferantService.LieferantQueryParams): __Observable<__StrictHttpResponse<ListResponseArgsOfLieferantDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/lieferant/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfLieferantDto>;
      })
    );
  }
  /**
   * Query
   * @param params The `LieferantService.LieferantQueryParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `eagerLoading`:
   */
  LieferantQuery(params: LieferantService.LieferantQueryParams): __Observable<ListResponseArgsOfLieferantDto> {
    return this.LieferantQueryResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfLieferantDto)
    );
  }

  /**
   * Query
   * @param term undefined
   */
  LieferantSearchUsersResponse(term?: null | string): __Observable<__StrictHttpResponse<ListResponseArgsOfUsersApiDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (term != null) __params = __params.set('term', term.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/lieferant/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfUsersApiDTO>;
      })
    );
  }
  /**
   * Query
   * @param term undefined
   */
  LieferantSearchUsers(term?: null | string): __Observable<ListResponseArgsOfUsersApiDTO> {
    return this.LieferantSearchUsersResponse(term).pipe(
      __map(_r => _r.body as ListResponseArgsOfUsersApiDTO)
    );
  }

  /**
   * Create
   * @param payload LieferantDto
   */
  LieferantCreateResponse(payload: LieferantDto): __Observable<__StrictHttpResponse<ResponseArgsOfLieferantDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/lieferant`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfLieferantDto>;
      })
    );
  }
  /**
   * Create
   * @param payload LieferantDto
   */
  LieferantCreate(payload: LieferantDto): __Observable<ResponseArgsOfLieferantDto> {
    return this.LieferantCreateResponse(payload).pipe(
      __map(_r => _r.body as ResponseArgsOfLieferantDto)
    );
  }

  /**
   * Update
   * @param params The `LieferantService.LieferantUpdateParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `id`:
   */
  LieferantUpdateResponse(params: LieferantService.LieferantUpdateParams): __Observable<__StrictHttpResponse<ResponseArgsOfLieferantDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/lieferant/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfLieferantDto>;
      })
    );
  }
  /**
   * Update
   * @param params The `LieferantService.LieferantUpdateParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `id`:
   */
  LieferantUpdate(params: LieferantService.LieferantUpdateParams): __Observable<ResponseArgsOfLieferantDto> {
    return this.LieferantUpdateResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfLieferantDto)
    );
  }

  /**
   * Delete
   * @param params The `LieferantService.LieferantDeleteParams` containing the following parameters:
   *
   * - `id`: Id der Entity
   *
   * - `user`: User Id oder Token
   */
  LieferantDeleteResponse(params: LieferantService.LieferantDeleteParams): __Observable<__StrictHttpResponse<ResponseArgsOfBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/lieferant/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBoolean>;
      })
    );
  }
  /**
   * Delete
   * @param params The `LieferantService.LieferantDeleteParams` containing the following parameters:
   *
   * - `id`: Id der Entity
   *
   * - `user`: User Id oder Token
   */
  LieferantDelete(params: LieferantService.LieferantDeleteParams): __Observable<ResponseArgsOfBoolean> {
    return this.LieferantDeleteResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfBoolean)
    );
  }
}

module LieferantService {

  /**
   * Parameters for LieferantQuery
   */
  export interface LieferantQueryParams {
    queryToken: ExtendedQueryTokenDTO;
    eagerLoading?: number;
  }

  /**
   * Parameters for LieferantUpdate
   */
  export interface LieferantUpdateParams {
    payload: LieferantDto;
    id: number;
  }

  /**
   * Parameters for LieferantDelete
   */
  export interface LieferantDeleteParams {

    /**
     * Id der Entity
     */
    id: number;

    /**
     * User Id oder Token
     */
    user?: null | string;
  }
}

export { LieferantService }
