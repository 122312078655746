<div style="margin-left: 25px; margin-right: 25px; margin-top: 25px;">
  <div class="grid p-justify-between">
    <div class="col-2" style="font-weight: 100; font-size: var(--fontSizeBigger );">Upload Editor</div>
    <div class="col-3">Bezeichnung: {{ validationName$ | async }}</div>
    <div class="col-2">Listentyp: {{ listName$ | async }}</div>
    <div class="col-2">Geändert: {{ (lastChangeDate$ | async) | date: 'dd.MM.yyyy'}}</div>

    <div class="col-1"><button [disabled]="(rowValues$ | async).length < 1" style="float: right;" pButton type="button"
        icon="pi pi-file-excel" [pTooltip]="getExportExcelTooltip()" class="ui-button-info"
        (click)="exportnachExcel($event)"></button></div>
    <div class="col-1" *ngIf="canFoxxyImportAsync() | async"><button pButton type="button" icon="pi pi-sign-out"
        pTooltip="Foxxy-Import" class="ui-button-success" style="margin-left: 15px;" (click)="foxxyImport()"></button>
    </div>
    <div class="col-1"><button style="float: right;" pButton type="button" icon="pi pi-times"
        pTooltip="Liste schliessen" class="ui-button-info" (click)="closeList()"></button></div>
    <p-toast></p-toast>
  </div>
  <div *ngIf="(isReadOnly$ | async)">{{isReadOnlyMessage$ | async }}</div>

  <p-toolbar>
    <div class="p-toolbar-group-left" *ngIf="canLeftMenue() | async">
      <button *ngIf="canExcelExportImport()" [disabled]="!(canVorlageExport() | async)" [ngClass]="'rightMargin'" style="float: right;" pButton
        type="button" icon="pi pi-angle-double-down" pTooltip="Excel-Vorlage laden" class="ui-button-info"
        (click)="downloadListForTask($event)"></button>
      <p-fileUpload [disabled]="!(canExcelHochladen() | async) " *ngIf="canExcelExportImport()"
        pTooltip="Excel Datei hochladen (vorhandene Daten werden ersetzt)" #fubauto mode="basic" auto="true"
        name="excelFile"
        url="{{ uploadServiceAddress + (uploadData$ | async).id + '&taskid=' + taskId + '&listName=' + (uploadData$ |async).attributeValidation }}"
        invalidFileTypeMessageSummary="{0} Datei nicht erlaubt" chooseLabel="Neu..."
        (onBeforeUpload)="onBeforeUpload($event)" (onUpload)="onUploadFinished($event, fubauto)"
        (onError)="uploadError($event, fubauto)" accept=".xlsx" maxFileSize="1000000">
      </p-fileUpload>
      <p-fileUpload [disabled]="!(canExcelHochladen() | async) " *ngIf="canExcelExportImport()"
        pTooltip="Excel Datei hochladen (vorhandene Daten bleiben erhalten)" [ngClass]="'leftMargin'" #fubmerge
        mode="basic" auto="true" name="excelFile"
        url="{{ mergeServiceAddress + (uploadData$ | async).id + '&taskid=' + taskId + '&listName=' + (uploadData$ |async).attributeValidation }}"
        invalidFileTypeMessageSummary="{0} Datei nicht erlaubt" chooseLabel="Zusammenführen..."
        (onBeforeUpload)="onBeforeMergeUpload($event)" chooseIcon="pi pi-file-excel"
        (onUpload)="onMergeUploadFinished($event, fubmerge)" (onError)="uploadMergeError($event, fubmerge)"
        accept=".xlsx" maxFileSize="1000000">
      </p-fileUpload>
      <span class="menuSeperator" *ngIf="canExcelExportImport()" ></span>
      <button id="addnewrow" [disabled]=" !(canNewRow() | async) " pButton type="button" icon="pi pi-plus"
        pTooltip="Neue Zeile" class="ui-button-info" (click)="addNewRow()"></button>

      <span class="menuSeperator" *ngIf="(uploadData$ | async).uploadState < 20"></span>
      <div class="p-buttonset">
        <button pTooltip="Bearbeiten" [disabled]="(isReadOnly$ | async)  || (isManager && taskType == 'articledata')"
          pButton type="button" icon="pi pi-pencil" *ngIf="isRowsEditing() == false"
          style="margin-left:10px;margin-right:5px;" class="p-button-info" (click)="onEditInit()"></button>
        <button [disabled]="(isReadOnly$ | async) " *ngIf="isRowsEditing() == true " pButton type="button"
          icon="pi pi-check" class="p-button-success" [pTooltip]="'Bestätigen'"
          style="margin-left:10px;margin-right:5px;" (click)="onRowsEditSave()"></button>
        <button [disabled]="(isReadOnly$ | async)" pButton type="button" *ngIf="isRowsEditing() == true "
          [pTooltip]="'Abbrechen'" icon="pi pi-times" style="margin-left:10px;margin-right:5px;" class="p-button-danger"
          (click)="onRowsEditCancel()"></button>
      </div>
      <span *ngIf="selectedRows.length > 0" class="menuSeperator"></span>
      <button [disabled]="(isReadOnly$ | async)" *ngIf="selectedRows.length > 0" pButton type="button"
        icon="pi pi-trash" pTooltip="Markierte Löschen" class="ui-button-danger" (click)="deleteRows()"></button>
      <span class="menuSeperator"></span>
      <button [disabled]="!(canImportAllBubs() | async)" pButton type="button" icon="pi pi-download"
        pTooltip="Alle Daten mit Bub-Informationen erweitern" class="ui-button-info"
        (click)="importAllFromBub()"></button>

      <span *ngIf="this.taskType != 'articledata' && !isManager " class="menuSeperator"></span>
      <button *ngIf="this.taskType != 'articledata' && !isManager" [disabled]="(isReadOnly$ | async)" pButton
        label="Ohne Input abschließen" type="button" pTooltip="" (click)="closewithOutAction()"></button>
      <span class="menuSeperator" *ngIf="canFreigabeAbschliessen() | async "></span>
      <span *ngIf="canFreigabeAbschliessen() | async ">
        <button [disabled]="(isReadOnly$ | async)" pButton type="button" icon="pi pi-sign-out"
          pTooltip="Freigeben / Abschliessen" class="ui-button-success" style="margin-left: 15px;"
          (click)="finishUpload()"></button>
      </span>
      <span *ngIf="selectedRows.length > 0" class="menuSeperator"></span>
      <span *ngIf="selectedRows.length > 0">
        <button [disabled]=" !(canImportSelectedBubs() | async) " pButton type="button" icon="pi pi-arrow-circle-down"
          pTooltip="Importieren von Bub-Daten für ausgewählte Artikel" class="ui-button-info"
          (click)="importSelectedFromBub()"></button>
      </span>
      <!--   <span *ngIf="selectedRows.length > 0" class="menuSeperator"></span>

      <button [disabled]="(isReadOnly$ | async)" *ngIf="selectedRows.length > 0" pButton type="button"
        icon="pi pi-trash" pTooltip="Markierte Löschen" class="ui-button-danger" (click)="deleteRows()"></button>
 -->
    </div>
    <div class="p-toolbar-group-right">
      <div style="margin-top: 10px" *ngIf="this.taskType == 'task'">

        <span style="margin-top: 10px;"><strong>Anzahl Zeilen Minimum: {{ minimum$ | async }} - Maximum: {{ maximum$ |
            async }}</strong> - Aktuell: {{ (rowValues$ | async).length }} - Markiert: {{ selectedRows.length }}</span>

        <span style="margin-left: 15px;"><strong>Gesamtstatus:</strong></span>
        <span *ngIf="!(isValid$ | async)"
          style="color:red; font-size: smaller; margin-left: 10px; margin-right: 5px;"><i
            style="font-size: x-small !important; margin-right: 5px;" class="pi pi-circle-fill"></i>&nbsp;Fehlerhafte
          Zeilen!</span>
        <span *ngIf="!(((rowValues$ | async).length >= (minimum$ | async)) &&
                ((rowValues$ | async).length <= (maximum$ | async))) "
          style="color:red; font-weight: bold; font-size: smaller; margin-left: 10px;"><i style="margin-right: 5px;"
            class="pi pi-circle-fill" style="font-size: x-small !important;"></i>&nbsp;Anzahl Zeilen!</span>
        <span *ngIf="(isValid$ | async) &&
                (((rowValues$ | async).length >= (minimum$ | async))  &&
                (((rowValues$ | async).length <= (maximum$ | async) || ((maximum$ | async) == 0))))"
          style="color:darkgray; font-size: smaller; margin-left: 10px;"><i style="margin-right: 5px;"
            class="pi pi-circle"></i>Freigabe möglich</span>

      </div>
      <div style="margin-top: 10px" *ngIf="this.taskType == 'articledata' && !isManager">

        <span style="margin-left: 15px;"><strong>Gesamtstatus:</strong></span>
        <span *ngIf="!(isValid$ | async)"
          style="color:red; font-size: smaller; margin-left: 10px; margin-right: 5px;"><i
            style="font-size: x-small !important;" class="pi pi-circle-fill"></i>&nbsp;Fehlerhafte Zeilen!</span>
        <span *ngIf="(selectSingleTask$ | async)?.data?.taskState < 400 "
          style="color:darkgray; font-size: smaller; margin-left: 10px; "><i style="margin-right: 5px;"
            class="pi pi-circle"></i>Freigabe möglich</span>
        <span *ngIf="(selectSingleTask$ | async)?.data?.taskState > 400 "
          style="color:green; font-size: smaller; margin-left: 10px;"><i style="margin-right: 5px;"
            class="pi pi-check"></i></span>
      </div>
    </div>
  </p-toolbar>


  <div *ngIf="(rowValues$ | async).length > 0 && columnHeaders().length > 0">
    <p-table [columns]="columnHeaders()" [value]="rowValues$ | async" scrollHeight="70vH" scrollable="true"
      editMode="row"
      [resizableColumns]="true" dataKey="id" [paginator]="true"
      [reorderableColumns]="true"
      stateStorage="session"
      [stateKey]="lStateKey"
      (onStateSave)="onStateSave($event)"
      styleClass="p-datatable-sm p-datatable-gridlines" columnResizeMode="expand" [paginator]="true"
      [rowsPerPageOptions]="[10, 20, 50, 100]" paginatorDropdownAppendTo="body" [rows]="10" [scrollable]="true"
      [currentPageReportTemplate]="'{first} bis {last} von {totalRecords}'" [showCurrentPageReport]="true"
      [loading]="contentListisLoading$ | async" #ptlist>
      <ng-template pTemplate="header" let-columns="columns">
        <tr style="height: 3rem;">
          <th *ngIf="hasValidationColumn()" pFrozenColumn [frozen]="true" pResizableColumnDisabled style="min-width:50px !important; max-width:50px !important"><i
              class="pi pi-ellipsis-v"></i></th>

              @if (hasAllCheckBox()) {
                <th  pFrozenColumn [frozen]="true"
                pResizableColumnDisabled style="width: 50px; min-width: 50px !important ; max-width: 50px  !important; ">
                <p-checkbox   binary="true" [(ngModel)]="selectallRows"
                  (onChange)="selectallRowsForActions($event)"
                ></p-checkbox>
                  </th>
              } @else {
                <th *ngIf="hasCheckColumn()" #singlecheckbox  pFrozenColumn [frozen]="true"
                pResizableColumnDisabled style="width: 50px; min-width: 50px !important; max-width: 50px  !important; "><i
                    class="pi pi-check"></i></th>
              }




          <th *ngFor="let col of columnHeaders()" [style]="getColWidth(col, {})" pReorderableColumn  pResizableColumn >
            <div *ngIf="!col.ismandatory" pTooltip="{{ getToolTip(col.field, col.columndesc) }}"
              tooltipPosition="bottom">{{ col.field  }}</div>
            <div *ngIf="col.ismandatory" pTooltip="{{ getToolTip(col.field, col.columndesc) }}"
              tooltipPosition="bottom">* {{ col.field  }}</div>
            <!-- Simple Text Filter -->
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-rowIndex="rowIndex">
        <tr style="height: 3rem">
          <td style="min-width:50px !important; max-width:50px !important" *ngIf="hasValidationColumn()" pFrozenColumn pResizableColumnDisabled >

            <div style="color:red" *ngIf="rowDataIsValid(rowData) == false" pTooltip="{{rowData.validationInfo}}">
              <i class="pi pi-circle-fill"></i>
            </div>
            <div style="width:3rem; color:lightgray" *ngIf="rowDataIsValid(rowData) == true"><i
                class="pi pi-circle"></i></div>
          </td>

          <td  style="width: 50px;  min-width: 50px !important; max-width: 50px !important; overflow: visible;" *ngIf="hasCheckColumn() || hasAllCheckBox()"      pFrozenColumn >
            <p-checkbox [disabled]="!(canCheckBoxSelection() | async)" *ngIf="isUploadStateOpenCheckBox() | async "
              binary="true" [(ngModel)]="rowData.selected" (onChange)="selectForActions(rowData)"></p-checkbox>
            <p-checkbox [disabled]="!(canCheckBoxSelection() | async)" *ngIf="isUploadStateFinshedCheckBox() | async"
              binary="true" [(ngModel)]="rowData.selected" (onChange)="selectForActions(rowData)"
              [readonly]="isCheckboxReadOnly(rowData)"></p-checkbox>
          </td>
          <td *ngFor="let col of columnHeaders() " [style]="getColWidth(col, {'overflow': 'visible','white-space' : 'no-wrap', 'overflow-x':'hidden'})" [pEditableColumn]="rowData"
            [pEditableColumnField]="col.field" [pEditableColumnRowIndex]="rowIndex"
            [pEditableColumnDisabled]="!this.isRowsEditing()"
            pTooltip="{{ getToolTip(col.field, col.columndesc) }}"

            [ngClass]="gettdStyle(col)" >

              <!-- EAN / ISBN -->
            <p-cellEditor *ngIf="col.columncelltype == 9">
              <ng-template pTemplate="input">
                <input pInputText [id]="col.usedcolumnid+col.field" [disabled]="(isReadOnly$ | async)" type="text"
                (ngModelChange)="onTextChanged($event, rowData, col)"
                  [(ngModel)]="rowData.cols[col.usedcolumnid].value" [style]="{'width':'100%'}" ngClass="inputText">
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.cols[col.usedcolumnid].value }}
              </ng-template>
            </p-cellEditor>

            <!-- Simple TextCell -->
            <p-cellEditor *ngIf="col.columncelltype == 0">
              <ng-template pTemplate="input">
                <input pInputText [id]="col.usedcolumnid+col.field" [disabled]="(isReadOnly$ | async)" type="text"
                (ngModelChange)="onTextChanged($event, rowData, col)"
                  [(ngModel)]="rowData.cols[col.usedcolumnid].value" [style]="{'width':'100%'}" ngClass="inputText">
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.cols[col.usedcolumnid].value }}
              </ng-template>
            </p-cellEditor>
            <!-- Number -->
            <p-cellEditor *ngIf="col.columncelltype == 1">
              <ng-template pTemplate="input">
                <p-inputNumber [inputId]="col.usedcolumnid+col.field" [disabled]="(isReadOnly$ | async)"
                  (ngModelChange)="onNumberChanged($event, rowData, col)"
                  [mode]="rowData.cols[col.usedcolumnid].isNumberOnly ? null : 'decimal'" [useGrouping]="false" [ngStyle]="{'width': '100%'}"
                  [min]="rowData.cols[col.usedcolumnid].isNumberOnly ? 0 : null"
                  [step]="0"
                  [showButtons]="false"
                  [minFractionDigits]="rowData.cols[col.usedcolumnid].maxDigits ? rowData.cols[col.usedcolumnid].maxDigits :  0"
                  [maxFractionDigits]="rowData.cols[col.usedcolumnid].maxDigits ? rowData.cols[col.usedcolumnid].maxDigits :  2"
                  [(ngModel)]="rowData.cols[col.usedcolumnid].value"></p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.cols[col.usedcolumnid].value | number:'1.0-2':'de' }}
              </ng-template>
            </p-cellEditor>
            <!-- Checkbox -->
            @if(col.columncelltype == 2 ) {
              <p-checkbox [inputId]="col.usedcolumnid+col.field" [disabled]="(isReadOnly$ | async) == 1 || isRowsEditing() == false " appendTo="body"
                [binary]="true"
                (onChange)="oncheckboxChanged($event, rowData, col)"
                [ngModel]="rowData.cols[col.usedcolumnid].booleanValue"></p-checkbox>
            }
            <!-- List -->
            <p-cellEditor *ngIf="col.columncelltype == 3">
              <ng-template pTemplate="input">
                <p-dropdown [inputId]="col.usedcolumnid+'-'+rowIndex"  optionLabel="label"
                  [disabled]="(isReadOnly$ | async)" [autoDisplayFirst]="false" [showClear]="true"
                  (onFocus)="this.createStaticComboList(rowData.cols[col.usedcolumnid], col);"
                  [options]="staticOptions()[col.usedcolumnid]"
                  [style]="{'width':'100%', 'overflow': 'visible'}"
                  (onChange)="dropdownChanged($event, rowData, col)" class="tdDropdownStyle"
                  [ngModel]="getStaticModel(rowData.cols[col.usedcolumnid], col)" appendTo="body"></p-dropdown>
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.cols[col.usedcolumnid].value  }}
              </ng-template>
            </p-cellEditor>
            <!-- Dynamic List , wird zur Laufzeit ermittelt-->
            <p-cellEditor *ngIf="col.columncelltype == 8">
              <ng-template pTemplate="input">
                <p-dropdown [inputId]="col.usedcolumnid+'-'+rowIndex" optionLabel="label"
                  [disabled]="(isReadOnly$ | async)" [autoDisplayFirst]="false" [showClear]="true"
                  [style]="{'width':'100%', 'overflow': 'visible'}"
                  [showClear]="true"
                  (onClear)="ondyndropdownClear($event, rowData, col)"
                  (onFocus)="createDynComboList(rowData.cols[col.usedcolumnid], col)"
                  [options]="dynOptions()[col.usedcolumnid]"
                  (onChange)="dyndropdownChanged($event, rowData, col)" class="tdDropdownStyle"
                  [ngModel]="getDynModel(rowData.cols[col.usedcolumnid], col)" appendTo="body"></p-dropdown>
              </ng-template>
              <ng-template pTemplate="output">
                {{ getDynModel(rowData.cols[col.usedcolumnid], col)?.label }}
              </ng-template>
            </p-cellEditor>

            <!-- Date -->
            <p-cellEditor *ngIf="col.columncelltype == 6">
              <ng-template pTemplate="input">
                <p-calendar [inputId]="col.usedcolumnid+col.field" [disabled]="(isReadOnly$ | async)"
                (ngModelChange)="onDateChanged($event, rowData, col)"
                  [(ngModel)]="rowData.cols[col.usedcolumnid].datevalue" dataType="date" [firstDayOfWeek]=1
                  dateFormat="dd.mm.yy" [firstDayOfWeek]="1" appendTo="body"></p-calendar>
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.cols[col.usedcolumnid].datevalue | date: 'dd.MM.yyyy' : 'de' }}
              </ng-template>
            </p-cellEditor>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <div class="row h4" *ngIf="(rowValues$ |async).length === 0">
      <div style="text-align: center;">Bitte Dokument hochladen oder Plus drücken für ein neues Dokument</div>
    </div>
  </div>

  <p-confirmDialog header="Bitte bestätigen:" icon="pi pi-exclamation-triangle"
    message="{{ selectedRows.length }} Zeilen werden gelöscht." rejectLabel="Abbrechen"></p-confirmDialog>
