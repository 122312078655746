import { createSelector } from '@ngrx/store';
import { selectValidationEditorState } from './state';

export const selectAllAvailableColumns = createSelector(selectValidationEditorState, x => x && x.availableColumns);
export const selectAllValidationLists = createSelector(selectValidationEditorState, x => x && x.validationLists);
export const selectValidationColumnsForList = createSelector(selectValidationEditorState, x => x && x.validationListColumns);

export const selectStaticUsedlistValues = createSelector(selectValidationEditorState, x => x && x.staticUsedlistValues);



