import { NotificationsState, InitialNotificationState } from './state';
import { NotificationsAction, NotificationsActionType } from './actions';
import { LogUsersClientErrorAction } from '@app/store/user/actions';
import { IClientError } from '@app/common/interfaces/Interfaces';
import { SimpleResultError } from '@app/common/models/simpleResult.model';

export function reducer(state: NotificationsState = InitialNotificationState, action: NotificationsAction): NotificationsState {

  switch (action.type) {

    case NotificationsActionType.NotificationErrorAction:
      return {
        ...state,
        notificationError: SimpleResultError.getSimpleResult(action.payload.error)
      };

    case NotificationsActionType.RemoveNotificationAction:
      var newmessages =  [...state.notifications];
      var id = newmessages.findIndex(x => x.id == action.payload.signal.id );
      if (id > -1) {
        newmessages = [...newmessages.slice(0, id), ...newmessages.slice(id + 1)];
      }
        return {
          ...state,
          notifications: newmessages
        };

        
    case NotificationsActionType.AddNotificationAction:
      if (action.payload.signal != undefined) {
        var existsindex = state.notifications.findIndex(t => t.id == action.payload.signal.id);
        if (existsindex > -1) {
          try {
            var oldContainer = { ...state.notifications[existsindex] };
            var newMessages = [...oldContainer.messages];
            action.payload.signal.messages.forEach(m => {
              var index = newMessages.findIndex(g => g.id == m.id);
              if (index > -1) {
                newMessages.push(m);
              } else {
                newMessages[index] = m;
              }
            }
            )
            oldContainer.messages = newMessages;
            var messagecontainer = [...state.notifications.slice(0, existsindex), oldContainer, ...state.notifications.slice(existsindex + 1)];
            return {
              ...state,
              notifications: messagecontainer
            };
          } catch (e) {
            this.store.dispatch(new LogUsersClientErrorAction({ error: <IClientError>{ userid: this.authuser.id, modul: 'NotificationAction', error: e } }));
            return {
              ...state,
              notifications: [...state.notifications, action.payload.signal]
            };
          }
        } else {
          return {
            ...state,
            notifications: [...state.notifications, action.payload.signal]
          };
        }

      }
      return state;

    case NotificationsActionType.GetAllNotificationsActionDone:
      return {
        ...state,
        notifications: action.payload.signals
      };

    case NotificationsActionType.DeleteAllMessagesActionDone:
      return {
        ...state,
      };


    case NotificationsActionType.DeleteSingleMessagesActionDone:
      if (state.notifications)
      {
      var existsindex = [...state.notifications].findIndex(t => t.messageType == action.payload.messageType && t.parentId == Number(action.payload.id));
      if (existsindex > -1) {
        var deletedmessagecontainer = [...state.notifications.slice(0, existsindex), ...state.notifications.slice(existsindex + 1)];
        return {
          ...state,
          notifications: deletedmessagecontainer
        };
      }}
      return state;


  }

  return state;
}




