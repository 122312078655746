import { Injectable } from "@angular/core";
import { GeneralResultEnum } from "@app/common/models";
import { SimpleResult } from "@app/common/models/simpleResult.model";
import { AritcleDataService } from "@app/common/services/articledata-service";
import { AuthService } from "@app/common/services/auth.service";
import { SignalMessageService } from "@app/common/services/signalmessage.service";
import { ValidationEditService } from "@app/common/services/validation-edit.service";
import { environment } from "@env/environment";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, filter, map, switchMap, withLatestFrom } from "rxjs/operators";
import { ArticleDataActionType, ArticleDataErrorAction, DeleteArticleDataAction, DeleteArticleDataActionDone, GetArticleDataAction, GetArticleDataActionDone,
  GetArticleDataValidationsAction, GetArticleDataValidationsActionDone, GetArticleDataSoMaContactsAction,
  InsertArticleDataAction, InsertArticleDataActionDone, UpdateArticleDataAction, UpdateArticleDataActionDone,
  GetArticleDataSoMaContactsActionDone } from "./actions";
import { selectarticleState } from "./state";


@Injectable()
export class ArticleDataEffects {
    state$ = this.store.pipe(select(selectarticleState));
    isManagerUser$ = this.authService.claims$
        .pipe(
            filter(f => !!f.member_of),
            map(userInfo => environment.managers.some(x => userInfo.member_of.includes(x))));
    ismanager: boolean;
    constructor(
        private store: Store<any>,
        private service: AritcleDataService,
        private signalmessageService: SignalMessageService,
        private validationeditService : ValidationEditService,
        private authService: AuthService,
        private actions: Actions) {
        this.isManagerUser$.subscribe(m => this.ismanager = m);
    }



    GetArticleData$ = createEffect(() => { return this.actions.pipe(
      ofType<GetArticleDataAction>(ArticleDataActionType.GetArticleData),
        withLatestFrom(this.state$),
        switchMap(([action]) =>
            this.service.GetArticleData(action.payload.filterOptions, action.payload.sortoptions, action.payload.somaAlle, action.payload.skip, action.payload.take).pipe(
                map(response => {
                    if (response.generalResult != GeneralResultEnum.Success) {
                        return new ArticleDataErrorAction({ error: <SimpleResult>response });
                    } else {
                        return new GetArticleDataActionDone({ items: response.data });
                    }
                }
                )
            )), catchError(error => of(new ArticleDataErrorAction({ error: error })))
    )})




    deleteArticleData$ = createEffect(() => { return this.actions.pipe(
      ofType<DeleteArticleDataAction>(ArticleDataActionType.DeleteArticleDataAction),
        withLatestFrom(this.state$),
        switchMap(([action]) =>
            this.service.deleteArticleData(action.payload.id, action.payload.isManager).pipe(
                map(response => {
                    if (response.generalResult != GeneralResultEnum.Success) {
                        return new ArticleDataErrorAction({ error: <SimpleResult>response });
                    } else {
                        return new DeleteArticleDataActionDone({ id: action.payload.id });
                    }
                }
                )
            )), catchError(error => of(new ArticleDataErrorAction({ error: error })))
    )})



    insertArticleData$ = createEffect(() => { return this.actions.pipe(
      ofType<InsertArticleDataAction>(ArticleDataActionType.InsertArticleDataAction),
        withLatestFrom(this.state$),
        switchMap(([action]) =>
            this.service.insertArticleData(action.payload.item).pipe(
                map(response => {
                    if (response.generalResult != GeneralResultEnum.Success) {
                        return new ArticleDataErrorAction({ error: <SimpleResult>response });
                    } else {
                        return new InsertArticleDataActionDone({ newitem: response.data });
                    }
                }
                )
            )), catchError(error => of(new ArticleDataErrorAction({ error: <SimpleResult> { generalResult: GeneralResultEnum.OnlyDebug, userMessage: "Meldungen konnten nicht geladen werden", serverMessage: error?.message }})))
    )})



    GetArticleDataValidationProfiles$ = createEffect(() => { return  this.actions.pipe(
        ofType<GetArticleDataValidationsAction>(ArticleDataActionType.GetArticleDataValidationsAction),
        withLatestFrom(this.state$),
        switchMap(([action]) =>
            this.validationeditService.GetArticleDataValidations().pipe(
                map(response => {
                    if (response.generalResult != GeneralResultEnum.Success) {
                        return new ArticleDataErrorAction({ error: <SimpleResult>response });
                    } else {
                        return new GetArticleDataValidationsActionDone({ validationlist: response.data });
                    }
                }
                )
            )), catchError(error => of(new ArticleDataErrorAction({ error: error })))
    )})



    updateArticleData$ = createEffect(() => { return   this.actions.pipe(
      ofType<UpdateArticleDataAction>(ArticleDataActionType.UpdateArticleDataAction),
        withLatestFrom(this.state$),
        switchMap(([action]) =>
            this.service.updateArticleData(action.payload.item).pipe(
                map(response => {
                    if (response.generalResult != GeneralResultEnum.Success) {
                        return new ArticleDataErrorAction({ error: <SimpleResult>response });
                    } else {
                        return new UpdateArticleDataActionDone({ changeditem: response.data, successmessage: action.payload.successmessage  });
                    }
                }
                )
            )), catchError(error => of(new ArticleDataErrorAction({ error: error })))
    )})




    getSoMAContacts$ = createEffect(() => {  return this.actions.pipe(
        ofType<GetArticleDataSoMaContactsAction>(ArticleDataActionType.GetSoMaContactsAction),
        withLatestFrom(this.state$),
        switchMap(([action]) =>
            this.service.getSomaContacts().pipe(
                map(response => {
                        return new GetArticleDataSoMaContactsActionDone({
                            somaContacts: response.data });
                        }
            ))
        ), catchError(error => of(new ArticleDataErrorAction({ error: error })))
    )})





}
