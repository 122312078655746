import { ApplicationRef, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotificationsComponent } from './+notifications/notifications.component';


import { TaskListComponent } from './+task-list/task-list.component';
import { TaskListModule } from './+task-list/task-list.module';
import { ValidationEditorComponent } from './+validationEditor/validation-editor.component';
import { ValidationEditorModule } from './+validationEditor/validation-editor.module';
import { IsAuthenticatedGuard } from './guards/is-authenticated.guard';
import { UserViewComponent } from './+userTasks/userTasks.component';
import { UserTasksModule } from './+userTasks/userTasks.module';
import { CalendarModule } from 'primeng/calendar';
import { CalendarComponent } from './+calendar/calendar.component';
import { ArticledataListComponent } from './+articledata/pages/articledata-list/articledata-list.component';
import { ArticledataModule } from './+articledata/articledata.module';
import { ListEditorComponent } from './+list-editor/pages/list-editor.component';




const routes: Routes = [
  { path: '', loadChildren: () => import('./+dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [IsAuthenticatedGuard]},
  { path: 'download', loadChildren: () => import('./+download/download.module').then(m => m.DownloadModule), canActivate: [IsAuthenticatedGuard]},
  { path: 'notifications', component: NotificationsComponent, canActivate: [IsAuthenticatedGuard] },
  { path: 'list-editor/:itemId/:type', component: ListEditorComponent , canActivate: [IsAuthenticatedGuard],  runGuardsAndResolvers: "always"},
  { path: 'tasks', component: TaskListComponent, canActivate: [IsAuthenticatedGuard]},
  { path: 'articledata', component: ArticledataListComponent, canActivate: [IsAuthenticatedGuard]},
  { path: 'userTasks', component: UserViewComponent, canActivate: [IsAuthenticatedGuard]},
  { path: 'calendar',  component: CalendarComponent, canActivate: [IsAuthenticatedGuard]},
  { path: 'validationedit', component: ValidationEditorComponent, canActivate: [IsAuthenticatedGuard]},
  { path: 'settings', loadChildren: () => import('./+settings/settings.module').then(m => m.SettingsModule), canActivate: [IsAuthenticatedGuard]},
  { path: 'listmediatips', data: { preload: false }, loadChildren: () => import('./+mediatips/mediatips.module').then(m => m.MediatipsModule), canActivate: [IsAuthenticatedGuard]},
  { path: 'novi', data: { preload: false }, loadChildren: () => import('./+noviprocess/noviprocess.module').then(m => m.NoviprocessModule), canActivate: [IsAuthenticatedGuard]},
  { path: 'filialmatrix', data: { preload: false }, loadChildren: () => import('./+filialmatrix/filialmatrix.module').then(m => m.FilialmatrixModule), canActivate: [IsAuthenticatedGuard]},
  { path: 'filialmatrixsettings', data: { preload: false }, loadChildren: () => import('./+filialmatrix/settings/filial-matrix-settings.module').then(m => m.FilialMatrixSettingsModule), canActivate: [IsAuthenticatedGuard]},
  { path: 'sortimentieren', data: { preload: false }, loadChildren: () => import('./+sortimentsmatrix/sortimentsmatrix.module').then(m => m.SortimentsmatrixModule), canActivate: [IsAuthenticatedGuard]},
  { path: 'sortimentmatrixsettings', data: { preload: false }, loadChildren: () => import('./+sortimentsmatrix/sortimentmatrixsettings/sortimentmatrixsettings.module').then(m => m.SortimentmatrixSettingsModule), canActivate: [IsAuthenticatedGuard]},
  { path: 'wup', data: { preload: false }, loadChildren: () => import('./+wupprocess/wupProcess.module').then(m => m.WupProcessModule), canActivate: [IsAuthenticatedGuard]},
  { path: 'feedback', data: { preload: false }, loadChildren: () => import('./+feedback/feedback.module').then(m => m.FeedbackModule), canActivate: [IsAuthenticatedGuard]},
  { path: 'usermanagement', data: { preload: false }, loadChildren: () => import('./+usermanagement/usermanagement.module').then(m => m.UserManagementModule), canActivate: [IsAuthenticatedGuard]},

   //H-KATALOG
  { path: 'katalog', data: { preload: false }, loadChildren: () => import('./+katalog/app/katalog/katalog.module').then(mod => {   return  mod.KatalogModule;  }) , canActivate: [IsAuthenticatedGuard]},
  //Remissionen
  { path: 'remissionen', data: { preload: false }, loadChildren: () => import('./+katalog/app/remissionen/remissionen.module').then(mod => {   return  mod.RemissionenModule;  }) , canActivate: [IsAuthenticatedGuard]},




];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'}),

    CalendarModule,
    ArticledataModule,
    TaskListModule,
    ValidationEditorModule,
    UserTasksModule
  ],
  exports: [
    RouterModule,

    TaskListModule,
    ValidationEditorModule,
    ArticledataModule,
    UserTasksModule
  ]
})

export class AppRoutingModule {

}
