import { ListenTypEnum } from '@app/common/models/novi/NovitätenTableCol';
import { createSelector } from '@ngrx/store';
import { selectCommonDataProcessState } from './state';

export const selectNovitaetenAllLookups = createSelector(selectCommonDataProcessState, x => x.idNameList);

export const selectNovitaetenLookupsbyKey = (key: string) => createSelector(selectCommonDataProcessState, s => {
  var result = s.idNameList[key];
  return result;
});


export const selectListenTypenbyKey = (key: ListenTypEnum) => createSelector(selectCommonDataProcessState, s => {
  var result = s.listendtos[key];
  return result;
});



export const selectpreparedUsedColorsofUser = createSelector(selectCommonDataProcessState, x => x.preparedusersColors);

export const selectUsedColorsofUser = createSelector(selectCommonDataProcessState, x => x.usersColors);

export const selectlastkeywordError = createSelector(selectCommonDataProcessState, x => x.keywordErrors);

export const selectallRegionen = createSelector(selectCommonDataProcessState, x => x.allRegionen);
export const selectallEinkaeufe = createSelector(selectCommonDataProcessState, x => x.allEinkaeufe);
export const selectallSaisons = createSelector(selectCommonDataProcessState, x => x.allSaisons);
export const selectallMarketingereignisse = createSelector(selectCommonDataProcessState, x => x.allEreignisse);
export const selectallSortimente = createSelector(selectCommonDataProcessState, x => x.allSortimente);
export const selectallKlammern = createSelector(selectCommonDataProcessState, x => x.allKlammern);
export const selectallKategorien = createSelector(selectCommonDataProcessState, x => x.allKategorien);
export const selectallTypen = createSelector(selectCommonDataProcessState, x => x.allsortimentsTypen);

export const selectallBranches = createSelector(selectCommonDataProcessState, x => x.allBranches);

export const selectallBranchGroups= createSelector(selectCommonDataProcessState, x => x.allBranchGroups);

export const selectallWarengruppen = createSelector(selectCommonDataProcessState, x => x.allWarenGroup);
export const selectNovitaetBubDataList = createSelector(selectCommonDataProcessState, x => x.bubDataList);
export const selectFailedBubDataList = createSelector(selectCommonDataProcessState, x => x.failedbubDataList);
export const selectNovitaetBubDataLoading = createSelector(selectCommonDataProcessState, x => x.isLoadingBubData);

export const selectAktuellerEinkauf = createSelector(selectCommonDataProcessState, x => x.aktuellerEinkauf);
export const selectAktuellerEinkaufisLoading = createSelector(selectCommonDataProcessState, x => x.aktuellerEinkaufisLoading);


export const selectzukuenftigeEinkaeufe = createSelector(selectCommonDataProcessState, x => x.zukuenftigeEinkaeufe);
export const selectzukuenftigeEinkaeufeisLoading = createSelector(selectCommonDataProcessState, x => x.zukuenftigeEinkaufisLoading);



export const selectedLipoContactsSOMA = createSelector(selectCommonDataProcessState, x => x.lipoContacts);

export const selectStichwortStrukturBaumAll = createSelector(selectCommonDataProcessState, s => s.stichwortstructureListAll);

export const selectStichwortStrukturBaumDictionary = createSelector(selectCommonDataProcessState, s => s.stichwortstructureList);

export const selectStichwortStrukturBaumisLoaded = createSelector(selectCommonDataProcessState, s => s.stichwortstructureisLoaded);

export const selectStichwortStrukturBaum = (id: number) => createSelector(selectCommonDataProcessState, s => {
  var result = s.stichwortstructureList[id];
  return result ?? [];
});


export const selectSettingValues = createSelector(selectCommonDataProcessState, x => x.settingValues);

export const selectSettingChildrenValues = createSelector(selectCommonDataProcessState, x => x.settingChildrenValues);





export const selectcolumnConfigs = createSelector(selectCommonDataProcessState, x => x.selectedColumnConfigs);

export const selectlastConfigSuccess = createSelector(selectCommonDataProcessState, x => x.configSuccess);


export const selectUseddistinctColorsofUser = createSelector(selectCommonDataProcessState, x => x.distinctusersColors);

export const selectIsLoading = createSelector(selectCommonDataProcessState, x => x.isLoading);

export const selectCommondataError = createSelector(selectCommonDataProcessState, x => x.commondataError);

export const selectCompareTitles = createSelector(selectCommonDataProcessState, x => x.compareTitles);


export const selectSearchedVerlage = createSelector(selectCommonDataProcessState, x => x.searchedVerlage);

export const selectverlagewithChildren = createSelector(selectCommonDataProcessState, x => x.verlagewithChildren);

export const selectKategorienausVorabplatzierungen = createSelector(selectCommonDataProcessState, x => x.katgegorienausVorabplatzierungen);

/**Sortierte Kategorien aus Vorabplatzierungen für das Sortiment
 * kann null sein
*/
export const selectKategorienausVorabplatzierungenbySortiment = (id: number) => createSelector(selectCommonDataProcessState, s => {
  if (id == undefined) return [];
  var result = s.katgegorienausVorabplatzierungen[id];
  return result ? result : undefined;  // nullable
});





/**Statistik auf Cockpitbereich */
export const selecthasSOMADiagramm = createSelector(selectCommonDataProcessState, x => (x.dashboardSum && Object.keys(x.dashboardSum).length > 0) || (x.novitaetenHits && Object.keys(x.novitaetenHits).length > 0));
export const selectNovitaetenhits = createSelector(selectCommonDataProcessState, x => x.novitaetenHits);
export const selectDashboardSum = createSelector(selectCommonDataProcessState, x => x.dashboardSum);



export const selectLieferanten = createSelector(selectCommonDataProcessState, x => x.lieferanten);
export const selectLieferant = createSelector(selectCommonDataProcessState, x => x.lieferant);

export const selectisLoadingLieferanten = createSelector(selectCommonDataProcessState, x => x.isLoadinglieferanten);

export const selectMandanten = createSelector(selectCommonDataProcessState, x => x.mandanten);
export const selectMandant = createSelector(selectCommonDataProcessState, x => x.mandant);
export const selectisLoadingMandanten = createSelector(selectCommonDataProcessState, x => x.isLoadingMandanten);

export const selectallLager = createSelector(selectCommonDataProcessState, x => x.allLager);


export const selectMoebel = createSelector(selectCommonDataProcessState, x => x.moebel);
export const selectMoebelIsLoading = createSelector(selectCommonDataProcessState, x => x.isMoebelLoading);
