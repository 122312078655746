

export enum MWSTEnum
{
  /**Z */
  Ohne = 0,
  /**S */
  Voll = 2,
  /**R */
  Reduziert = 8
}

export enum TimeUnitEnum {
  Jahr = 0x80,
  Monat = 0x40
}
