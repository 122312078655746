import {FieldModel} from './field.model';
import { ColumnValue } from './columnValue.model';

export interface RowContentModel {
    id: number;
    selected: boolean;
    isValid: boolean;
    validationInfo: string;
    fieldModel: FieldModel[];
    columnValues: ColumnValue[];
    rowtype: string;
}