import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ShellNavigationComponent } from './navigation.component';
import { RouterModule } from '@angular/router';
import { TooltipModule} from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import {MenubarModule } from 'primeng/menubar';
import {MatSidenavContainer, MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatBadgeModule} from '@angular/material/badge';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatExpansionModule} from '@angular/material/expansion';
import { SwitchThemeService } from '@app/common/services/switch-theme.service';
import { SelectButtonModule } from 'primeng/selectbutton';
import {InputSwitchModule} from 'primeng/inputswitch';
import { NotificationsModule } from '@app/+notifications/notifications.module';
import {AnimateModule} from 'primeng/animate';






@NgModule({
    providers:[SwitchThemeService, MatSidenavContainer],
    imports: [
        CommonModule,
        FormsModule,
        AnimateModule,
        RouterModule,
        SelectButtonModule,
        InputSwitchModule,
        TooltipModule,
        MatExpansionModule,
        MenubarModule,
        MatSidenavModule,
        MatToolbarModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatMenuModule,
        MatIconModule,
        MatBadgeModule,
        MatListModule,


    ],
    exports: [
        ShellNavigationComponent, TooltipModule, ButtonModule
    ],
    declarations: [
        ShellNavigationComponent
    ]
})
export class ShellModule { }
