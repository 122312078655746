import { createSelector } from '@ngrx/store';
import { selectUserTasksState } from './state';


export const selectAllTasks = createSelector(selectUserTasksState, x => x.tasks);
export const selectAllTasksHits = createSelector(selectUserTasksState, x => x.taskHits);


export const selectUserTaskError = createSelector(selectUserTasksState, x => x.currenterror);


