import { Component, OnInit, signal, WritableSignal } from '@angular/core';
import { AufgabenArt as AufgabenArtEnum, LieferantTypeEnum } from '@app/common/models';
import { Store, select } from '@ngrx/store';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { filter, Observable, of, take } from 'rxjs';
import { CmfUserDataModel } from '@app/common/models/cmfUserData.model';
import { ISuppliergroup, ISupplier } from '@app/common/models/suppliergroup.model';
import { selectSuppliersForGroup, selectSuppliergroups } from './store/selectors';
import { GetSuppliergroupsAction, GetSuppliersForGroupAction } from './store/actions';
import { MandantUserDTO } from '@app/common/models/mandant-user-dto';
import {  LieferantUserDTO } from '@app/common-data-module/models/LieferantUserDTO';


@Component({
  selector: 'app-supplier-dialog',
  templateUrl: 'supplierSelectDialog.component.html'
})

export class SupplierSelectDialogComponent implements OnInit {

  supplierList: WritableSignal<{ value: MandantUserDTO | LieferantUserDTO, selected: boolean }[]> = signal([]);
  showLieferantengruppenDropdown: boolean = false;
  suppliergroupList$: Observable<any[]>;
  selectedLSuppliersForGrroupList$: Observable<ISupplier[]>;

  userCols: any[];
  selectedSuppliers: LieferantUserDTO[] = [];
  selectedMandanten: MandantUserDTO[] = [];
  selectedSuppliergroup: ISuppliergroup;
  title = 'Lieferanten Liste';
  aufgabenArt: AufgabenArtEnum = AufgabenArtEnum.Artikelliste;
  globalfilter: string[];
  existingUserinTask$: Observable<string[]> = of([]);
  lieferantType: LieferantTypeEnum;
  hint: WritableSignal<string> = signal('');



  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private store: Store<any>
  ) { }

  ngOnInit() {
    this.aufgabenArt = this.config.data.aufgabenArt;
    this.lieferantType = this.config.data.lieferantType;
    this.title = this.aufgabenArt && this.aufgabenArt == AufgabenArtEnum.WeitereBedarfe ? 'Mandanten Liste' : 'Lieferanten Liste';

    this.config.data.supplierList.subscribe((suppliers: MandantUserDTO[] | LieferantUserDTO[]) => {
      if (this.lieferantType == LieferantTypeEnum.CategoryManager) {

        var distinctcategorymanger = new Array<LieferantUserDTO>();
        suppliers.forEach(f => {
          var user = <LieferantUserDTO>{
            lieferantId: f.lieferantId, lieferantName: f.lieferantName,
            lieferantUserEmail: f.lieferantUserEmail, lieferantUserId:
              f.lieferantUserId, lieferantUserName:
              f.lieferantUserName,
            lieferantUserFirstname: f.lieferantUserFirstname, lieferantUserLastname: f.lieferantUserLastname, lieferantUserShortId: f.lieferantUserShortId
          };
          if (distinctcategorymanger.find(u => u.lieferantUserId == user.lieferantUserId) == undefined) {
            distinctcategorymanger.push(user)
          }
        }
        )
        distinctcategorymanger = distinctcategorymanger.sort((a, b) => a.lieferantUserName.localeCompare(b.lieferantUserName));
        this.supplierList.set(distinctcategorymanger.map(item => ({ value: item, selected: false })));
      } else {
        this.supplierList.set(suppliers.map(item => ({ value: item, selected: false })));
      }
    })

    if (this.config.data.showLieferantengruppenDropdown) {
      this.showLieferantengruppenDropdown = this.config.data.showLieferantengruppenDropdown;
      this.supplierList.update(u => {
        u.forEach(x => x.selected = false);
        return [...u];
      })
    }

    if (this.aufgabenArt == AufgabenArtEnum.WeitereBedarfe) {
      this.showLieferantengruppenDropdown = false;
      this.userCols = [
        { part: 'value', field: 'mandantName', header: 'Mandant' },
        { part: 'value', field: 'mandantUserName', header: 'Kontakt' },
        { part: 'value', field: 'mandantUserEmail', header: 'Mail' }
      ]
      this.globalfilter = ['value.mandantName', 'value.mandantUserName', 'value.mandantUserEmail'];
    } else {
      if (this.lieferantType == LieferantTypeEnum.CategoryManager) {
        this.showLieferantengruppenDropdown = false;
        this.userCols = [
          { part: 'value', field: 'lieferantUserName', header: 'Category Captain' },
          { part: 'value', field: 'lieferantUserEmail', header: 'Mail' }
        ]
      } else {
        this.userCols = [
          { part: 'value', field: 'lieferantName', header: 'Lieferant' },
          { part: 'value', field: 'lieferantUserName', header: 'Kontakt' },
          { part: 'value', field: 'lieferantUserEmail', header: 'Mail' }
        ]
      }
      this.globalfilter = ['value.lieferantName', 'value.lieferantUserFirstname', 'value.lieferantUserLastname', 'value.lieferantUserName', 'value.lieferantUserEmail'];
    }

    if (this.showLieferantengruppenDropdown) {
      this.suppliergroupList$ = this.store.pipe(select(selectSuppliergroups));
      this.store.dispatch(new GetSuppliergroupsAction());
    }
  }

  setRecipients($event, rowData) {

    this.hint.set('');
    if (this.aufgabenArt == AufgabenArtEnum.WeitereBedarfe) {
      if ($event.checked) {
        const existing = this.selectedMandanten.find(x => x.mandantUserId == rowData.mandantUserId && rowData.mandantId == x.mandantId);
        if (existing == undefined) {
          this.selectedMandanten.push(rowData);
        }
      } else {
        const idx = this.selectedMandanten.findIndex(x => x.mandantUserId == rowData.mandantUserId && rowData.mandantId == x.mandantId);
        if (idx >= 0) {
          this.selectedMandanten = [...this.selectedMandanten.slice(0, idx), ...this.selectedMandanten.slice(idx + 1)];
        }
      }
    }
    else {
      if ($event.checked) {
        if (this.lieferantType == LieferantTypeEnum.CategoryManager && this.selectedSuppliers.length == 1) {
          this.hint.set('Es kann nur ein Category Captain ausgewählt werden');
          return;
        }
        const existing = this.selectedSuppliers.find(x => x.lieferantUserId == rowData.lieferantUserId && rowData.lieferantId == x.lieferantId);
        if (existing == undefined) {
          this.selectedSuppliers.push(rowData);
        }
      } else {
        const idx = this.selectedSuppliers.findIndex(x => x.lieferantUserId == rowData.lieferantUserId && rowData.lieferantId == x.lieferantId);
        if (idx >= 0) {
          this.selectedSuppliers = [...this.selectedSuppliers.slice(0, idx), ...this.selectedSuppliers.slice(idx + 1)];
        }
      }
    }
  }

  close() {
    if (this.aufgabenArt == AufgabenArtEnum.WeitereBedarfe) {
      this.ref.close(this.selectedMandanten);
    }
    else {
      this.ref.close(this.selectedSuppliers);
    }

  }

  onChangeSuppliergroup(grp: ISuppliergroup) {
    this.selectedLSuppliersForGrroupList$ = this.store.pipe(select(selectSuppliersForGroup));
    this.store.dispatch(new GetSuppliersForGroupAction({ group: grp }));
    this.selectedLSuppliersForGrroupList$.pipe(filter(f => f.length > 0)).subscribe(grouplistSuppliers => {
      this.supplierList.update(supps => {
        supps = [...supps];
        grouplistSuppliers.forEach(groupsupplier => {

          supps.forEach(dialogSupplier => {
            var supplier = dialogSupplier.value as LieferantUserDTO;
            if ((supplier.lieferantUserId == groupsupplier.cmfUserId) && groupsupplier.isActive) {
              const existsInSelectedSuppliers = this.selectedSuppliers.find(x => x.lieferantUserId == supplier.lieferantUserId);
              if (existsInSelectedSuppliers == undefined) {
                this.selectedSuppliers.push(supplier);
              }
              dialogSupplier.selected = true;
            }
          });
        });
        return supps;
      })

    })


  }

}
