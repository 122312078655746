<div class="container" *ngIf="(getAllNotification() | async).length > 0">
  
  <p-panel>
    <p-header>Benachrichtigungen</p-header>
    <div class="grid" *ngFor="let notification of getAllNotification() | async">
      <div class="col-2">
        <button pButton (click)="gotoMenueItem($event, notification)"
          style="border-color: transparent; background-color: transparent; color: var(--color-text); ">
          <mat-icon [ngClass]="notification.iconClass" [title]="notification.tooltip">
            {{notification.icon}}
          </mat-icon>
        </button>
      </div>
      <div class="col">
        <label style="padding-top: 1%;">
          {{notification.message}}
        </label>
      </div>
      <div class="col-1">
        <button pButton (click)="gotoNotifications($event, notification)"
          style="border-color: transparent; background-color: transparent; color: var(--color-text); ">
          <mat-icon [ngClass]="notification.iconClass" [title]="'Anzeigen...'">
            open_in_new
          </mat-icon>
        </button>
      </div>
      <div class="col-1">
        <button *ngIf="canClear(notification)" (click)="clearNotifications($event, notification)"
          style="border-color: transparent; background-color: transparent; color: red; font-weight: bold; ">
          <mat-icon [ngClass]="notification.iconClass" [title]="'Entfernen...'">
            clear
          </mat-icon>
        </button>
      </div>
    </div>
  </p-panel>
</div>
