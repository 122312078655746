/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class HolidayCalendarService extends __BaseService {
  static readonly HolidayCalendarGetHolidayCalendarPath = '/holidaycalendar/branch/{branchId}';
  static readonly HolidayCalendarGetHolidayCalendar2Path = '/holidaycalendar/{regionKey}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Feiertage einer Filial
   * @param params The `HolidayCalendarService.HolidayCalendarGetHolidayCalendarParams` containing the following parameters:
   *
   * - `branchId`:
   *
   * - `stop`:
   *
   * - `start`:
   */
  HolidayCalendarGetHolidayCalendarResponse(params: HolidayCalendarService.HolidayCalendarGetHolidayCalendarParams): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.stop != null) __params = __params.set('stop', params.stop.toString());
    if (params.start != null) __params = __params.set('start', params.start.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/holidaycalendar/branch/${encodeURIComponent(String(params.branchId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Feiertage einer Filial
   * @param params The `HolidayCalendarService.HolidayCalendarGetHolidayCalendarParams` containing the following parameters:
   *
   * - `branchId`:
   *
   * - `stop`:
   *
   * - `start`:
   */
  HolidayCalendarGetHolidayCalendar(params: HolidayCalendarService.HolidayCalendarGetHolidayCalendarParams): __Observable<{[key: string]: string}> {
    return this.HolidayCalendarGetHolidayCalendarResponse(params).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }

  /**
   * Feiertage einer Region
   * @param params The `HolidayCalendarService.HolidayCalendarGetHolidayCalendar2Params` containing the following parameters:
   *
   * - `regionKey`:
   *
   * - `stop`:
   *
   * - `start`:
   */
  HolidayCalendarGetHolidayCalendar2Response(params: HolidayCalendarService.HolidayCalendarGetHolidayCalendar2Params): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.stop != null) __params = __params.set('stop', params.stop.toString());
    if (params.start != null) __params = __params.set('start', params.start.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/holidaycalendar/${encodeURIComponent(String(params.regionKey))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Feiertage einer Region
   * @param params The `HolidayCalendarService.HolidayCalendarGetHolidayCalendar2Params` containing the following parameters:
   *
   * - `regionKey`:
   *
   * - `stop`:
   *
   * - `start`:
   */
  HolidayCalendarGetHolidayCalendar2(params: HolidayCalendarService.HolidayCalendarGetHolidayCalendar2Params): __Observable<{[key: string]: string}> {
    return this.HolidayCalendarGetHolidayCalendar2Response(params).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }
}

module HolidayCalendarService {

  /**
   * Parameters for HolidayCalendarGetHolidayCalendar
   */
  export interface HolidayCalendarGetHolidayCalendarParams {
    branchId: number;
    stop?: string;
    start?: string;
  }

  /**
   * Parameters for HolidayCalendarGetHolidayCalendar2
   */
  export interface HolidayCalendarGetHolidayCalendar2Params {
    regionKey: null | string;
    stop?: string;
    start?: string;
  }
}

export { HolidayCalendarService }
