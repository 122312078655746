
import { AttributeStatusModel, AttributeTypeModel } from '.';
import { AttributeUserModel } from './attribute-user.model';

export class AttributeMainModel {
    id: number;
    attributeChildren: AttributeMainModel[];
    attributeName: string;
    attributeDescription: string;
    attributeValidFrom: Date;
    attributeValidUntil: Date;
    attributeType: AttributeTypeModel; 
    attributeState: AttributeStatusModel;
    attributeActive: boolean;
    sourceId: number;
    creatorId: number;
    attributeUsers: AttributeUserModel[];
    attributeTargets: any;


}
