import { ActivatedRouteSnapshot, UrlTree, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '@app/common/services/auth.service';
import { tap } from 'rxjs/operators';


@Injectable()
export class IsAuthenticatedGuard  {

    constructor(private authService: AuthService) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.authService.isAuthenticated$.pipe(tap(console.log.bind(window, 'IsAuthenticatedGuard')));
    }
}
