import { ValidationEditorState, InitialValidationEditorState } from './state';
import { ValidationEditorActions,
    ValidationEditorActionType,
    GetAllAvailableColumnsDoneAction,
    GetAllValidationColumnsDoneAction,
    GetAllValidationListsDoneAction,
    AddNewValidationListDoneAction,
    SaveValidationListDoneAction,
    AddAvailableColumnDoneAction,
    SaveAvailableColumnDoneAction,
    AddValidationColumnDoneAction,
    SaveValidationColumnDoneAction,
    CreateValidationColFromAvailableDoneAction,
    DeleteValidationListColumnDoneAction,
    DeleteAvailableColumnDoneAction,
    DeleteValidationListDoneAction,
    DeleteValidationListColumnAction,
    DeleteValidationListAction,
    SaveValidationColumnsSortOrderAction,
    SaveValidationColumnOrderDoneAction,
    GetStaticListValuesActionDone,
    GetValidationColumnsForListIdActionDone} from './actions';
import { ValidateRowAction } from '@app/+list-editor/store';
import { ValidationList, ValidationListColumn } from '@app/common/models';

export function reducer(state: ValidationEditorState = InitialValidationEditorState, action: ValidationEditorActions): ValidationEditorState {
    switch (action.type) {
        case ValidationEditorActionType.GetAllAvailableColumnsDone:
            return GetAllAvailableColumnsDone(state, action);
        case ValidationEditorActionType.GetAllValidationColumnsDone:
            return GetAllValidationColumnsDone(state, action);
        case ValidationEditorActionType.GetAllValidationListsDone:
            return GetAllValidationListsDone(state, action);
        case ValidationEditorActionType.AddNewValidationListDone:
            return AddNewValidationListDone(state, action);
        case ValidationEditorActionType.SaveValidationListDone:
            return SaveChangedValidationList(state, action);
        case ValidationEditorActionType.AddAvailableColumnDone:
            return AddAvailableColumnDone(state, action);
            case ValidationEditorActionType.GetStaticListValuesActionDone:
              return GetStaticValuesDone(state, action);
        case ValidationEditorActionType.SaveAvailableColumnDone:
            return SaveAvailableColumnDone(state, action);
        case ValidationEditorActionType.AddValidationColumnDone:
            return AddValidationColumnDone(state, action);
        case ValidationEditorActionType.SaveValidationColumnDone:
            return SaveValidationColumnDone(state, action);
        case ValidationEditorActionType.CreateValidationColFromAvailableDone:
            return CreateValidationColFromAvailableDone(state, action);
        case ValidationEditorActionType.DeleteValidationListColumnDone:
            return DeleteValidationListColumnDone(state, action);
        case ValidationEditorActionType.DeleteAvailableColumnDone:
            return DeleteAvailableColumnDone(state, action);
        case ValidationEditorActionType.DeleteValidationListDone:
            return DeleteValidationListDone(state, action);
        case ValidationEditorActionType.SaveValidationColumnOrderDone:
            return SaveValidationColumnOrderDone(state, action);
        case ValidationEditorActionType.GetValidationColumnsForListIdAction:
            return {
                ...state,
                validationListselectedColumnsisLoading: true
            };
        case ValidationEditorActionType.GetValidationColumnsForListIdActionDone:
              return getValidationColumnsForListIdActionDone(state, action);

    }
    return state;
}

export function GetAllAvailableColumnsDone(state: ValidationEditorState,
    action: GetAllAvailableColumnsDoneAction): ValidationEditorState {

    return {
        ...state,
        availableColumns: action.payload.items
    };
}

export function GetAllValidationColumnsDone(state: ValidationEditorState,
    action: GetAllValidationColumnsDoneAction): ValidationEditorState {

    return {
        ...state,
        validationListColumns: action.payload.items
    };
}

export function GetAllValidationListsDone(state: ValidationEditorState,
    action: GetAllValidationListsDoneAction): ValidationEditorState {

    return {
        ...state,
        validationLists: action.payload.items
    };
}

export function AddNewValidationListDone(state: ValidationEditorState,
    action: AddNewValidationListDoneAction): ValidationEditorState {
    return {
        ...state,
        validationLists: [...state.validationLists, action.payload.newValidationList]
    };
}

export function SaveChangedValidationList(state: ValidationEditorState, action: SaveValidationListDoneAction): ValidationEditorState {
    const idx = state.validationLists.findIndex(x => x.id == action.payload.validationList.id);
    return {
        ...state,
        validationLists: [...state.validationLists.slice(0, idx), action.payload.validationList, ...state.validationLists.slice(idx + 1)]
    }
}

export function AddAvailableColumnDone(state: ValidationEditorState = InitialValidationEditorState,
    action: AddAvailableColumnDoneAction): ValidationEditorState {
        return {
            ...state,
            availableColumns: [...state.availableColumns, action.payload.availableColumn]
        };
    }

   export function GetStaticValuesDone(state: ValidationEditorState = InitialValidationEditorState,
      action: GetStaticListValuesActionDone): ValidationEditorState {

          var obj =  {...state.staticUsedlistValues};
          obj[action.payload.usedcolumnlistid] = action.payload.items;
          return {
              ...state,
              staticUsedlistValues: obj
          };
      }

export function SaveAvailableColumnDone(state: ValidationEditorState = InitialValidationEditorState,
action: SaveAvailableColumnDoneAction): ValidationEditorState {
    const idx = state.availableColumns.findIndex(x => x.id == action.payload.availableColumn.id);
    return {
        ...state,
        availableColumns: [...state.availableColumns.slice(0, idx), action.payload.availableColumn, ...state.availableColumns.slice(idx + 1)]
    };
}

export function AddValidationColumnDone(state: ValidationEditorState = InitialValidationEditorState,
    action: AddValidationColumnDoneAction): ValidationEditorState {
    return {
        ...state,
        validationListselectedColumns: [...state.validationListselectedColumns, action.payload.validationColumn]
    };
}


export function SaveValidationColumnDone(state: ValidationEditorState = InitialValidationEditorState,
    action: SaveValidationColumnDoneAction): ValidationEditorState {
    const idx = state.validationListselectedColumns.findIndex(x => x.id == action.payload.validationColumn.id);
    return {
        ...state,
        validationListselectedColumns: [...state.validationListselectedColumns.slice(0, idx), action.payload.validationColumn, ...state.validationListselectedColumns.slice(idx + 1)]
    };
}

export function SaveValidationColumnOrderDone(state: ValidationEditorState = InitialValidationEditorState,
    action: SaveValidationColumnOrderDoneAction): ValidationEditorState {

        let validationCols: ValidationListColumn[] = [];
        state.validationListselectedColumns.forEach(x => validationCols.push(Object.assign({}, x)));
        for (let i = 0; i < action.payload.columnOrder.length; i++) {
            const idx = validationCols.findIndex(x => x.id == action.payload.columnOrder[i].columnId);
            if (idx > -1)
            {
                validationCols[idx].sortOrder = action.payload.columnOrder[i].orderNumber;
            }
        }
        validationCols = validationCols.sort((a, b) => a.sortOrder - b.sortOrder);
        return {
            ...state,
            validationListselectedColumns: validationCols
        }
}

export function CreateValidationColFromAvailableDone(state: ValidationEditorState = InitialValidationEditorState,
    action: CreateValidationColFromAvailableDoneAction): ValidationEditorState {
    return {
        ...state,
        validationListselectedColumns: [...state.validationListselectedColumns, action.payload.validationColumn]
    };
}

export function DeleteValidationListColumnDone(state: ValidationEditorState = InitialValidationEditorState,
    action: DeleteValidationListColumnDoneAction): ValidationEditorState {

    const idx = state.validationListselectedColumns.findIndex(x => x.id == action.payload.columnId);
    return {
        ...state,
        validationListselectedColumns: [...state.validationListselectedColumns.slice(0,idx), ...state.validationListselectedColumns.slice(idx + 1)]
    };
}

export function getValidationColumnsForListIdActionDone(state: ValidationEditorState, action: GetValidationColumnsForListIdActionDone): ValidationEditorState {

    return {
        ...state,
        validationListselectedColumns: action.payload.items,
        validationListselectedColumnsisLoading: false
    };

}

export function DeleteAvailableColumnDone(state: ValidationEditorState = InitialValidationEditorState,
    action: DeleteAvailableColumnDoneAction): ValidationEditorState {

    const idx = state.availableColumns.findIndex(x => x.id == action.payload.colId);
    return {
        ...state,
        availableColumns: [...state.availableColumns.slice(0,idx), ...state.availableColumns.slice(idx + 1)]
    };
}

export function DeleteValidationListDone(state: ValidationEditorState = InitialValidationEditorState,
    action: DeleteValidationListDoneAction): ValidationEditorState {
    let validationCols: ValidationListColumn[] = [];
    state.validationListselectedColumns.forEach(x => validationCols.push(Object.assign({}, x)));
    validationCols.splice(validationCols.findIndex(x => x.validationListId == action.payload.listId));

    const idx = state.validationLists.findIndex(x => x.id == action.payload.listId);
    return {
        ...state,
        validationListselectedColumns: validationCols,
        validationLists: [...state.validationLists.slice(0,idx), ...state.validationLists.slice(idx + 1)]
    };
}


