import { AuthConfig } from 'angular-oauth2-oidc';

export const authConfig: AuthConfig = {

  // Url of the Identity Provider
  issuer: 'none',

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin,

  // URL of the SPA to redirect the user after silent refresh
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',

  useSilentRefresh: true,

  // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: 'liepo',

  responseType: 'id_token token',

  oidc: true,

  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: 'openid profile cmf_user isa-user-webapi adv-webapi liepo-SettingsService liepo-SignalService liepo-MediatipService liepo-LiPoCalendarService liepo-AttributeManagementService liepo-AttributeReaderService liepo-LiPoTaskService liepo-LiPoUploadService liepo-LiPoUploadStagingService liepo-LiPoValidationService liepo-ValidationEditService  ',
};
