import { EntityStatus, HerstellerDto, LagerDto, LieferantDto, UserDto } from "@app/api/filialmatrix/models";


export class LieferantDtoClient implements LieferantDto
{


  benutzer: Array<UserDto>;
  
  hersteller: HerstellerDto;
  name: string;

  id: number;
  changed?: string;
  created?: string;
  pId?: string;
  status?: EntityStatus;
  version?: number;

   constructor(dto: LieferantDto)
  {

    this.id = dto.id;
    this.benutzer = dto.benutzer;
    this.hersteller = dto.hersteller,

    this.name = dto.name;

    this.pId = dto.pId;
    this.status = dto.status;
    this.version = dto.version;
    this.changed = dto.changed;
    this.created = dto.created;

  }


  get lieferantLongId() : string {
    if (this.id == undefined) return null;
    const min = "8290000000000000000";
    if (Number(this.id) < Number(min))
    {
      var result =  min.substring(0,min.length -this.id.toString().length) + this.id.toString();
      return result;
    }
    return this.id.toString();
}


}
