import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { featureName, reducer, NotificationsEffects } from './store';
import { EffectsModule } from '@ngrx/effects';
import { SignalMessageService } from '@app/common/services/signalmessage.service';


@NgModule({
  providers: [SignalMessageService],
    imports: [
        StoreModule.forFeature(featureName, reducer),
        EffectsModule.forFeature([ NotificationsEffects ])
    ]
})
export class NotificationsStateModule { }
