import { NgModule } from '@angular/core';
import { StoreModule, MetaReducer } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers, AppState } from './store';
import { environment } from '@env/environment';
import { storeFreeze } from 'ngrx-store-freeze';
import { UserEffects } from './store/user/effect';

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [storeFreeze] : [];

@NgModule({
    imports: [
        StoreModule.forRoot(reducers, { metaReducers }),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production
        }),
        EffectsModule.forRoot([UserEffects])
    ]
})
export class AppStoreModule {

}
