import { createSelector } from '@ngrx/store';
import { selectTaskEditorState } from './state';

export const selectValidationProfiles = createSelector(selectTaskEditorState, s => s.validationProfiles.map(item => ({
    label: item.listName,
    value: item.id
})));



export const selectBreadcrumb = createSelector(selectTaskEditorState, s => s.breadcrumb);

export const selectSortMastersForDropDown = createSelector (selectTaskEditorState, s => s.sortmasters.map(item => ({
    label: item.name,
    value: item.assortmentmasterId
})));

export const selectSortimentsForDropDown = createSelector (selectTaskEditorState, s => s.sortiments.map(item => ({
    label: item.name,
    value: item.assortmentId
})));



export const selectSortMasterAny = createSelector(selectTaskEditorState, s => s.sortmasters.length > 0);

export const selectSortimentsAny = createSelector(selectTaskEditorState, s => s.sortiments.length > 0);



export const selectCurrentUser = createSelector(selectTaskEditorState, s => s.currentUser);

export const selectTypeInfos = createSelector(selectTaskEditorState, s => s.typeInfos);

export const selectableUsers = createSelector(selectTaskEditorState, s => s.selectableUsers.map(item => ({
    label: item.name,
    value: item
})));

export const selectedUsers = createSelector(selectTaskEditorState, s => s.selectedUsers);

export const selectResult = createSelector(selectTaskEditorState, s => s.lastResult);

export const isLoading = createSelector(selectTaskEditorState, s => s.loading);

export const usersLoading = createSelector(selectTaskEditorState, s => s.usersLoading);

export const taskSaving = createSelector(selectTaskEditorState, s => s.savingTask);

