<div class="grid">
  <div class="col-12">
    <div class="grid p-fluid">
      <div class="field col-7">
        <label for="transferTaskName">Name:</label>
        <input id="transferTaskName" type="text" pInputText [(ngModel)]="taskName" />
      </div>
      <div class="field col-5">
        <label for="transferUserName">Lieferant:</label>
        <input pInputText readonly class=""   id="transferUserName" [ngModel]="userOrganisation" />
      </div>
      <div class="field col-7">
        <label for="transferAufgabenTyp" >Ziel:</label>
        <p-dropdown [style]="{'width': '100%'}" showClear="true"  filter="false" id="transferAufgabenTyp" [options]="aufgabenArten()" optionLabel="value" optionValue="id" [ngModel]="selectedAufgabenArt()"
        (onChange)="selectAufgabenArtChange($event)"
         placeholder="Typ wählen" appendTo="body">
        </p-dropdown>
      </div>
      <div class="field col-5">&ensp;</div>
      <div class="field col-7">
        <label for="SelectedSortimentsgruppe" >Sortimentsgruppen:</label>
        <p-dropdown   [style]="{'width': '100%'}" showClear="true"  filter="true" id="SelectedSortimentsgruppe" [options]="sortimentGruppen$ | async" optionLabel="name"
        [(ngModel)]="selectedSortimentGruppe"
        [disabled]="!canSelectSortiment()"
        (ngModelChange)="selectedSortimentGruppeChange()" placeholder="nach Sortimentsgruppe filtern" appendTo="body">
        </p-dropdown>
      </div>
      <div class="field col-5">&ensp;</div>
      <div class="field col-7">
        <label for="transferSelectedSortiment" >Sortiment:</label>
        <p-dropdown   [style]="{'width': '100%'}" showClear="true"  filter="true" id="transferSelectedSortiment" [options]="sortimente()" optionLabel="name" [(ngModel)]="selectedSortiment"
        [disabled]="!canSelectSortiment()"
        (ngModelChange)="selectedSortimentChange()" placeholder="Sortiment wählen" appendTo="body">
        </p-dropdown>
      </div>
      <div class="field col-5">
        <label for="transferExpirationDate" >Verfallsdatum:</label>
        <p-calendar id="transferExpirationDate" [(ngModel)]="expirationDate" [firstDayOfWeek]="1" dateFormat="dd.mm.yy"
            [disabled]="!canSelectVerfallsdatum()"
            showButtonBar="true" [showIcon]="true" appendTo="body"
            [minDate]="minDateValue" [maxDate]="maxDateValue"></p-calendar>
      </div>

    </div>
  </div>
</div>

<div class="grid formAreaSeparatorTop">
    <div class="field col-7"></div>
    <div class="field col-3">
      <button [style]="{'width': '100%'}" pButton [disabled]="!canSaveAndTransfer()" label="Speichern + Versenden" class="p-button-info" (click)="saveAndTransfer()"></button>
    </div>
    <div class="field col-2">
      <button [style]="{'width': '100%'}" pButton [disabled]="!canCancel()" label="Abbrechen" class="p-button-info" (click)="cancel()"></button>
    </div>
</div>
