import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TaskListComponent } from './task-list.component';
import { TaskViewComponent } from './task-view.component';
import { SupplierSelectDialogComponent } from './supplierSelectDialog.component';
import { TaskListStateModule } from './task-list-state.module';
import { PaginatorModule } from 'primeng/paginator';
import { TooltipModule } from 'primeng/tooltip';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import {CheckboxModule} from 'primeng/checkbox';
import { NgStringPipesModule } from 'ngx-pipes';
import {InputTextModule} from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { TreeTableModule } from 'primeng/treetable';
import {ListboxModule} from 'primeng/listbox';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {ContextMenuModule} from 'primeng/contextmenu';
import { MultiSelectModule } from 'primeng/multiselect';
import {SpinnerModule} from 'primeng/spinner';
import { TaskEditDialogComponent } from './task-edit-dialog.component';
import {FileUploadModule} from 'primeng/fileupload';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { CommonCheckService } from '@app/common/services/common.validation.service';
import { MatIconModule } from '@angular/material/icon';
import { InputTextareaModule } from 'primeng/inputtextarea';

@NgModule({ exports: [TaskListComponent, TaskViewComponent, SupplierSelectDialogComponent, TaskEditDialogComponent],
    declarations: [TaskListComponent, TaskViewComponent, SupplierSelectDialogComponent, TaskEditDialogComponent], imports: [CommonModule, TaskListStateModule, PaginatorModule, TooltipModule, ProgressSpinnerModule, ButtonModule,
        NgStringPipesModule, RadioButtonModule, DropdownModule, TableModule, TreeTableModule, CheckboxModule, NgxChartsModule, PanelModule, PanelMenuModule,
        ContextMenuModule, InputTextModule, ListboxModule, MultiSelectModule, SpinnerModule, FileUploadModule, MatIconModule,
        InputTextareaModule,
        CalendarModule, ToastModule, ConfirmDialogModule, InputNumberModule], providers: [ConfirmationService, CommonCheckService, provideHttpClient(withInterceptorsFromDi())] })
export class TaskListModule { }
