import { Injectable, WritableSignal, signal } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { QueryTokenDTO } from "@app/api/filialmatrix/models";
import { LieferantService } from "@app/api/filialmatrix/services";
import { GetLieferantAction, GetLieferantenAction, GetVerlagChildrenAction, selectLieferant, selectLieferanten, selectisLoadingLieferanten, selectverlagewithChildren } from "../store";
import { BehaviorSubject, filter, map, Observable, of, take } from "rxjs";
import { VerlagDto } from "@app/common/models/verlag.model";
import { IAuthUser } from "@app/common/interfaces/Interfaces";
import { LieferantUserDTO } from "../models/LieferantUserDTO";
import { LieferantDtoClient } from "@app/common/models/lieferant/lieferant.dto.client";


@Injectable()
export class LieferantenService {

  allHerstellerdesUsers: BehaviorSubject<number[]> = new BehaviorSubject(undefined);


  selectLieferantenFlat$: Observable<LieferantUserDTO[]> = of([])
  selectLieferanten$: Observable<LieferantDtoClient[]> = of([]);
  selectLieferant$: Observable<LieferantDtoClient>;
  selectisLoadingLieferanten$: Observable<boolean>;
  selectverlagewithChildren$: Observable<{ [key: number]: VerlagDto[]; }> = of({});
  cacheLieferantenQueryName: string;


  constructor(private store: Store<any>) {


    this.selectLieferanten$ = this.store.pipe(select(selectLieferanten));
    this.selectLieferant$ = this.store.pipe(select(selectLieferant));
    this.selectisLoadingLieferanten$ = this.store.pipe(select(selectisLoadingLieferanten));


    this.selectverlagewithChildren$ = this.store.pipe(select(selectverlagewithChildren));



    this.selectLieferantenFlat$ = this.store.pipe(select(selectLieferanten)).pipe(map(m => {
      {
        var result = new Array<LieferantUserDTO>();
        m.forEach(l => {
          l.benutzer.forEach(b => {
            result.push(new LieferantUserDTO(l, b));
          })
        })
        return result.sort((a, b) => a.lieferantName.localeCompare(b.lieferantName));
      }
    }))
  }

  loadLieferanten(params: LieferantService.LieferantQueryParams, cache: boolean ) {
    if (cache == true) {
      this.selectLieferanten$.pipe(take(1)).subscribe(lieferanten => {
        if (lieferanten.length > 1 && params && params?.queryToken?.friendlyName && this.cacheLieferantenQueryName == params.queryToken.friendlyName ) {
          return;
        }
        this.store.dispatch(new GetLieferantenAction({ params: params }));
        this.cacheLieferantenQueryName = params.queryToken.friendlyName;
      })
    } else {
      this.store.dispatch(new GetLieferantenAction({ params: params }));
    }


  }
  loadLieferantenofUser(params: LieferantService.LieferantQueryParams, authUser: IAuthUser, cache: boolean) {
    this.allHerstellerdesUsers.pipe(take(1)).subscribe(currenthersteller => {
      if (cache == true && currenthersteller != undefined && currenthersteller.length > 0) return;
      this.store.dispatch(new GetLieferantenAction({ params: params }));
      this.selectisLoadingLieferanten$.pipe(filter(f => f == false), take(1)).subscribe(loaded => {
        this.selectLieferanten$.pipe(filter(f => f != undefined), take(1)).subscribe(lieferantendesUsers => {
          var groupidstoCheck = new Array<number>();
          lieferantendesUsers.forEach(g => {
            if (g.hersteller && g.hersteller.id) {
              this.store.dispatch(new GetVerlagChildrenAction({ id: g.hersteller.id }))
              groupidstoCheck.push(g.hersteller.id)
            }
          })
          this.selectverlagewithChildren$.pipe(filter(f => this.checkifChildrenLoaded(f, groupidstoCheck)), take(1)).subscribe(verlagaemitkinder => {
            var herstellerids = lieferantendesUsers.filter(g => g.benutzer.find(u => u.id == authUser.shortId)).map(f => f.hersteller.id);
            groupidstoCheck.forEach(d => {
              var data = verlagaemitkinder[d];
              if (data != undefined) {
                data.forEach(child => {
                  if (herstellerids.find(h => h == child.id) == undefined) {
                    herstellerids.push(child.id);
                  }
                })
              }
            })
            this.allHerstellerdesUsers.next(herstellerids);
          })
        })
      })
    })
  }


  //**Wurden die Verlage geladen ?*/
  checkifChildrenLoaded(verlagemitkindern: { [key: number]: VerlagDto[]; }, groupidstoCheck: number[]): boolean {
    if (groupidstoCheck.length == 0) return true;
    const keys = Object.keys(verlagemitkindern);
    if (keys == undefined) return false;
    // alle ids wurden geladen
    const result = keys.filter(r => groupidstoCheck.find(g => g == (Number(r)) != undefined)).length == groupidstoCheck.length;
    return result;
  }

  loadLieferant(userid: string) {
    if (userid == undefined) return;
    this.store.dispatch(new GetLieferantAction({
      params: <LieferantService.LieferantQueryParams>
        {
          queryToken: <QueryTokenDTO>{
            filter: { "benutzer.cmfuser.id.equals": userid }
          }
        }
    }))
  }





}
