
// https://stackblitz.com/edit/material-sidenav-example?file=app%2Fsidenav-autosize-example.html
//
import { Component, OnInit, ChangeDetectionStrategy, ViewChild, Output, Inject, WritableSignal, signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectNavMenues } from '@app/store/navigation/selectors';
import { InitNavMenueAction, ChangeNavMenueAction } from '@app/store/navigation/actions';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { map, filter, single } from 'rxjs/operators';
import { AuthService } from '@app/common/services/auth.service';
import { MenuItem, MessageService } from 'primeng/api';
import { MatSidenav } from '@angular/material/sidenav';
import { NavMenuItem, NavMenuItemTypeEnum } from '@app/common/models';
import { environment } from '@env/environment';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { IAuthUser, IClientError } from '@app/common/interfaces/Interfaces';
import { MessageTypeEnum } from '@app/common/models/signal.model';


import { combineLatest, Observable, of } from 'rxjs';
import * as cloneDeep from 'lodash/fp/cloneDeep';
import {  LogUsersClientErrorAction } from '@app/store/user/actions';
import { ISchemaOption, SwitchThemeService } from '@app/common/services/switch-theme.service';
import { DOCUMENT } from '@angular/common';

import { GetAllNotificationsAction } from '@app/+notifications/store';
import { selectallNotifications } from '@app/+notifications/store/selectors';


@Component({
  selector: 'app-shell-navigation',
  templateUrl: 'navigation.component.html',
  styleUrls: ['navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShellNavigationComponent implements OnInit {




  fontsizeitems: MenuItem[] = [];
  styleitems: MenuItem[] = [];

  contextMenuPosition = { x: '0rem', y: '0rem' };
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;


  // @ts-ignore
  userInfo$ = this.authService.claims$.pipe(
    map(claims => claims.preferred_username)
  );


  userName: string;
  items: MenuItem[]; // login
  selectedStyle: MenuItem;
  // @ts-ignore
  navitemsBase$ = this.store.select(selectNavMenues)
  // @ts-ignore
  selectallNotifications$ = this.store.select(selectallNotifications);

  @ViewChild('matmenu', { static: false }) matmenu: MatMenu;
  opened = false; // initial nur Icons
  childrenExpanded: boolean;
  authUser: any;
  sessionMessage$: Observable<string> = of("");
  isAdmin: boolean;
  isMandant: boolean;
  isLieferant: boolean;
  isSoma: boolean;
  headerHided: boolean = false;
  headerToggledIcon: WritableSignal<string> = signal("expand_less")

  constructor(
    private store: Store<any>,
    private router: Router,
    private authService: AuthService,
    private messageService: MessageService,
    private switschLayoutService: SwitchThemeService,
    @Inject(DOCUMENT) private document

  ) {

    this.authService.getLipoUser().subscribe(user=>{
      this.isMandant =  user.ismandant;
      this.isAdmin = user.isadmin;
      this.isLieferant = user.isLieferant;
      this.isSoma = user.ismanager;
    }).unsubscribe()

  }

  getLogoPath(): string {
    if (this.switschLayoutService.selectedTheme?.name == 'dark') return "./assets/Logo_weiss.png";
    return "./assets/Logo.png";
  }

  /**Header nach manager oder nicht unterscheiden #6289 */
  getHeader(): Observable<string> {

      if (this.isSoma == undefined || this.isAdmin == undefined) return of("");
      if (this.isSoma == true || this.isAdmin == true) return of("");
      return of("Lieferantenportal");

  }

  /**Navigation mit Counting belegen
   * zur Zeit Medientipps/Artikeldaten
   */
  navitems$ = combineLatest([this.navitemsBase$,  this.selectallNotifications$]).pipe(
    map(([n, notifications]) => {
      try {
        if (notifications == undefined) return n;
        if (this.authUser == undefined) return n;
        var navs = cloneDeep(n);
        for (var i = 0; i < navs.length; i++) {

          if (navs[i].label == "Sortimentieren") {
            var parentcnt = notifications.filter(a => a.messageType == MessageTypeEnum.Sortiment &&  a.targetUserId == this.authUser.id).length;
            navs[i].notificationCount = parentcnt;
          } else    if (navs[i].label == "Filialmatrix") {
            var parentcnt = notifications.filter(a => a.messageType == MessageTypeEnum.ModulSortiment &&  a.targetUserId == this.authUser.id).length;
            navs[i].notificationCount = parentcnt;
          } else if (navs[i].label == "Artikeldaten") {
            var parentcnt = notifications.filter(a => a.messageType == MessageTypeEnum.Artikeldaten &&  a.targetUserId == this.authUser.id).length;
            navs[i].notificationCount = parentcnt;
          } else if (navs[i].label == "Medientipps") {
            var parentcnt = notifications.filter(a => a.messageType == MessageTypeEnum.Medientipp && a.targetUserId == this.authUser.id).length;
            navs[i].notificationCount = parentcnt;
          }
          if (navs[i].children && navs[i].children.length > 0) {
            navs[i].children.forEach(c => {
              if (c.label == "Filialmatrix") {
                var childcnt = notifications.filter(a => a.messageType == MessageTypeEnum.ModulSortiment && a.targetUserId == this.authUser.id).length;
                c.notificationCount = childcnt;
              } else
                if (c.label == "Artikeldaten") {
                  var childcnt = notifications.filter(a => a.messageType == MessageTypeEnum.Artikeldaten &&  a.targetUserId == this.authUser.id).length;
                  c.notificationCount = childcnt;
                } else if (c.label == "Medientipps") {
                  var childcnt = notifications.filter(a => a.messageType == MessageTypeEnum.Medientipp && a.targetUserId == this.authUser.id).length;
                  c.notificationCount = childcnt;
                }
            })
          }
          // keine weiteren z.Z.
        }
        return navs;
      } catch (e) {
        this.store.dispatch(new LogUsersClientErrorAction({ error: <IClientError>{ userid: this.authUser.id, modul: 'Notification to Navigation', error: e } }));
        return n;
      }
    }));

  /**Alle Notifications zusammengezählt
   * Medientipps und Artikeldaten liefern Notifications
   */
  notificationCountAll$ = combineLatest([this.navitems$, this.selectallNotifications$]).pipe(
    map(([navs, notifications]) => {
      var sum = 0;
      if (this.authUser == undefined) return sum;
      if (notifications == undefined || notifications.length ==0) return sum;
      for (var i = 0; i < navs.length; i++) {
        if (navs[i].label == "Sortimentieren") {
          var parentcnt = notifications.filter(a => a.messageType == MessageTypeEnum.Sortiment && a.targetUserId == this.authUser.id).length;
          sum += parentcnt;
        } else       if (navs[i].label == "Filialmatrix") {
          var parentcnt = notifications.filter(a => a.messageType == MessageTypeEnum.ModulSortiment && a.targetUserId == this.authUser.id).length;
          sum += parentcnt;
        } else if (navs[i].label == "Artikeldaten") {
          var parentcnt = notifications.filter(a => a.messageType == MessageTypeEnum.Artikeldaten && a.targetUserId == this.authUser.id).length;
          sum += parentcnt;
        } else if (navs[i].label == "Medientipps") {
          var parentcnt = notifications.filter(a => a.messageType == MessageTypeEnum.Medientipp && a.targetUserId == this.authUser.id).length;
          sum += parentcnt;
        }
        if (navs[i].children && navs[i].children.length > 0) {
          navs[i].children.forEach(c => {
            if (c.label == "Filialmatrix") {
              var childcnt = notifications.filter(a =>  a.messageType == MessageTypeEnum.ModulSortiment && a.targetUserId == this.authUser.id).length;
              sum += childcnt;
            } else if (c.label == "Artikeldaten") {
              var childcnt = notifications.filter(a =>  a.messageType == MessageTypeEnum.Artikeldaten && a.targetUserId == this.authUser.id).length;
              sum += childcnt;
            } else if (c.label == "Medientipps") {
              var childcnt = notifications.filter(a => a.messageType == MessageTypeEnum.Medientipp && a.targetUserId == this.authUser.id).length;
              sum += childcnt;
            }
          })
        }
        // keine weiteren z.Z.
      }
      return sum;
    }));

  /**Zählen der badges */
  getItemParentCount(item: NavMenuItem): number {
    try {
      if (item.notificationCount == undefined) return 0;
      if (item.children != undefined && item.children.length > 0) {
        var count = 0;
        item.children.forEach(c => { count += c.notificationCount; });
        return count;
      }
      return item.notificationCount;
    } catch (e) {
      this.store.dispatch(new LogUsersClientErrorAction({ error: <IClientError>{ userid: this.authUser.id, modul: 'Navigation getItemParentCount', error: e } }));
      return 0;
    }
  }

  canNotification(): boolean {
    return true;
  }

  gotoDashboard(): void {
    this.router.navigate(["/"]);
  }

  getSideNavClass (opened: boolean) {
    return opened ? "sidenav_open" : " sidenav_closed ";
  }


  /**Setzen der Css klasse codesesitig, da mode= 'side' nicht automatisch opened setzt */
  getContentClass(opened: boolean) {
    return opened ? "sidenav-content sidenav-content_open" : " sidenav-content sidenav-content_close ";
  }

  ngOnInit() {
    this.switschLayoutService.load();
    this.GetStyleMenus();

    this.document.body.classList.add(this.switschLayoutService.getThemeClass());
    this.document.body.classList.add(this.switschLayoutService.getMaterialClass());

    const sub = this.userInfo$.subscribe(x => this.userName = x);
    sub.unsubscribe();
    this.items = [
      {
        label: this.userName,
        items: [
          { label: "Abmelden", command: (event => this.logout()) }
        ]
      }
    ]


      const proto = `${environment.protoTyping}` == "true";
      var navitems = new Array<NavMenuItem>();
      navitems.push(<NavMenuItem>{ id:'startseite', label: "Startseite", routerlink: "/", icon: "dashboard", iconClass: "defaultIcon material-icons-outlined", tooltip: "Startseite", active: "active", seperator: true, type: NavMenuItemTypeEnum.default, notificationCount: 0 });

      let taskmenu: NavMenuItem;
      if (this.isSoma) {
        taskmenu = <NavMenuItem>{ id: 'aufgaben-soma', label: "Aufgaben", routerlink: "/tasks", icon: "list", iconClass: "defaultIcon", tooltip: "Aufgaben", type: NavMenuItemTypeEnum.default, notificationCount: 0, active: '' };
      } else {
        taskmenu = <NavMenuItem>{  id: 'aufgaben-lieferant-mandant', label: "Aufgaben", routerlink: "/userTasks", icon: "list", iconClass: "defaultIcon", tooltip: "Aufgaben", type: NavMenuItemTypeEnum.default, notificationCount: 0, active: '' };
      }


      let artikeldatenmenu = <NavMenuItem>{ id:'artikeldaten-soma',  messageTypes: [MessageTypeEnum.Artikeldaten], label: "Artikeldaten", routerlink: "/articledata", icon: "library_books", iconClass: "defaultIcon material-icons-outlined", tooltip: "Artikeldaten", type: NavMenuItemTypeEnum.default, notificationCount: 0, active: '' };


      let medientippmenu = <NavMenuItem>{ id:'medientipps-soma',  messageTypes: [MessageTypeEnum.Medientipp], label: "Medientipps", routerlink: "/listmediatips", icon: "event_available", iconClass: "defaultIcon material-icons-outlined", tooltip: "Medientipps", type: NavMenuItemTypeEnum.default, notificationCount: 0, active: '' };

      if (this.isSoma) {

        var lieferantenportalmenu = <NavMenuItem>{ id:'lieferantenportal-group', label: "Lieferantenportal", routerlink: "", icon: "groups", iconClass: "defaultIcon material-icons-outlined", tooltip: "Lieferantenportal", type: NavMenuItemTypeEnum.default, notificationCount: 0, children: new Array<NavMenuItem>(), active: '' };

        lieferantenportalmenu.children.push(taskmenu);

        lieferantenportalmenu.children.push(artikeldatenmenu);
        lieferantenportalmenu.children.push(medientippmenu);
        navitems.push(lieferantenportalmenu);
      } else {
        navitems.push(taskmenu);
        if (this.isLieferant == true) // mindestens lieferant / kein Mandant hier reinlassen
        {
          navitems.push(artikeldatenmenu);
          navitems.push(<NavMenuItem>{ id: 'medientipps-lieferant',  messageTypes: [MessageTypeEnum.Medientipp], label: "Medientipps", routerlink: "/listmediatips", icon: "event_available", iconClass: "defaultIcon material-icons-outlined", tooltip: "Medientipps", type: NavMenuItemTypeEnum.default, notificationCount: 0, active: '' });
        }
      }

      if (!proto && this.isSoma) {
        navitems.push(<NavMenuItem>{ id:'novitäten-soma', messageTypes: [MessageTypeEnum.Aenderungshistorie], label: "Novitäteneinkauf SoMa", routerlink: "/novi", icon: "shopping_cart", iconClass: "defaultIcon material-icons-outlined", seperator: false, tooltip: "Noviprozess", type: NavMenuItemTypeEnum.default, notificationCount: 0, active: '' });
        navitems.push(<NavMenuItem>{ id: 'novitaeten-wup', label: "Novitäteneinkauf WuP", routerlink: "/wup", icon: "local_shipping", iconClass: "defaultIcon material-icons-outlined", seperator: false, tooltip: "Novitäteneinkauf WuP", type: NavMenuItemTypeEnum.default, notificationCount: 0, active: '' });
      }
      if (this.isLieferant == true || this.isAdmin == true || this.isSoma == true ) // mindestens lieferant / kein Mandant hier reinlassen
      {
          navitems.push(<NavMenuItem>{ id: 'kalender',  label: "Kalender", routerlink: "/calendar", icon: "today", iconClass: "defaultIcon material-icons-outlined", seperator: false, tooltip: "Kalender", type: NavMenuItemTypeEnum.default, notificationCount: 0, active: '' });
      }
      if (this.isAdmin) {
        navitems.push(<NavMenuItem>{ id: 'listenverwaltung',  label: "Listenverwaltung", routerlink: "/validationedit", icon: "folder_open", iconClass: "defaultIcon material-icons-outlined", seperator: false, tooltip: "Verwaltung Listen", type: NavMenuItemTypeEnum.default, notificationCount: 0, active: '' });
      }
      if (this.isAdmin && !proto) {
        navitems.push(<NavMenuItem>{ id: 'filialmatrix',  messageTypes: [MessageTypeEnum.ModulSortiment, MessageTypeEnum.Modulzuordnung], label: "Filialmatrix", routerlink: "/filialmatrix", icon: "store", iconClass: "defaultIcon material-icons-outlined", seperator: true, tooltip: "Filialmatrix", type: NavMenuItemTypeEnum.default, notificationCount: 0, active: '' });
      }

      if (this.isAdmin && !proto) {
         navitems.push(<NavMenuItem>{ id:'sortimentieren',  messageTypes: [MessageTypeEnum.Sortiment], label: "Sortimentieren", routerlink: "/sortimentieren", icon: "assignment", iconClass: "defaultIcon material-icons-outlined", seperator: true, tooltip: "Sortimentieren", type: NavMenuItemTypeEnum.default, notificationCount: 0, active: '' });
      }

      if (this.isLieferant == true || this.isMandant == true)
      {
        navitems.push(<NavMenuItem>{ id: 'listungen', label: "Listungen", routerlink: "/feedback", icon: "shopping_cart", iconClass: "defaultIcon material-icons-outlined", seperator: true, tooltip: "Listungen", type: NavMenuItemTypeEnum.default, notificationCount: 0, active: '' });
      }

      navitems.push(<NavMenuItem> { id: 'dokumente',  label: "Dokumente", routerlink: "/download", icon: "content_copy", iconClass: "defaultIcon material-icons-outlined", tooltip: "Download Bereich", type: NavMenuItemTypeEnum.default, notificationCount: 0, active: '' });

      if (this.isAdmin == true || this.isSoma == true)
        {
            var katalogmenu = <NavMenuItem>{ id:'katalog',  label: "Katalog", routerlink: "", icon: "update", iconClass: "defaultIcon material-icons-outlined", tooltip: "Katalog", type: NavMenuItemTypeEnum.default, notificationCount: 0, children: new Array<NavMenuItem>(), active: '' };
            katalogmenu.children.push(<NavMenuItem>{ id:'katalog-artikel', label: "Artikel", routerlink: "/katalog/artikel", icon: "book", iconClass: "defaultIcon material-icons-outlined", tooltip: "Katalog Artikel Bereich", type: NavMenuItemTypeEnum.default, notificationCount: 0, active: '' });
            katalogmenu.children.push(<NavMenuItem>{ id:'katalog-stuecklisten', label: "Stücklisten", routerlink: "/katalog/stuecklisten", icon: "list_alt", iconClass: "defaultIcon material-icons-outlined", tooltip: "Katalog Stücklisten", type: NavMenuItemTypeEnum.default, notificationCount: 0, active: '' });
            navitems.push(katalogmenu);
        }

      if (this.isAdmin) {
        var settingmenu = <NavMenuItem>{ id: 'einstellungen-allgemein',  label: "Einstellungen", routerlink: "", icon: "settings", iconClass: "defaultIcon material-icons-outlined", tooltip: "Einstellungen", type: NavMenuItemTypeEnum.default, notificationCount: 0, active: '', children: new Array<NavMenuItem>() };
        var liposettings = <NavMenuItem>{ id: 'einstellungen-lieferantenportal', label: "Lieferantenportal", routerlink: "settings", icon: "settings", iconClass: "defaultIcon material-icons-outlined", tooltip: "Einstellungen Lieferantenportal", type: NavMenuItemTypeEnum.default, notificationCount: 0, active: '', children: null };
        //var filialmatrixsettings = <NavMenuItem>{ label: "FilialMatrix", routerlink: "filialmatrixsettings", icon: "store", iconClass: "defaultIcon material-icons-outlined", tooltip: "Einstellungen Filialmatrix", type: NavMenuItemTypeEnum.default, notificationCount: 0, active: '', children: null };
        var sortimentmatrixsettings = <NavMenuItem>{ id: 'einstellungen-sortimentsmatrix', label: "Sortimentsmatrix", routerlink: "sortimentmatrixsettings", icon: "store", iconClass: "defaultIcon material-icons-outlined", tooltip: "Einstellungen Sortimentmatrix", type: NavMenuItemTypeEnum.default, notificationCount: 0, active: '', children: null };
        settingmenu.children.push(liposettings);
        //settingmenu.children.push(filialmatrixsettings);
        settingmenu.children.push(sortimentmatrixsettings);
        var novitaetensettings = <NavMenuItem>{ id: 'einstellungen-novitaeteneinkauf', label: "Novitäteneinkauf", routerlink: "novi/settings", icon: "shopping_cart", iconClass: "defaultIcon material-icons-outlined", tooltip: "Einstellungen Novitäteneinkauf", type: NavMenuItemTypeEnum.default, notificationCount: 0, active: '', children: null };
        settingmenu.children.push(novitaetensettings);
        var novitaetensettings = <NavMenuItem>{ id: 'usermanagement',  label: "Lieferanten-/Mandanten", routerlink: "usermanagement", icon: "people_alt", iconClass: "defaultIcon material-icons-outlined", tooltip: "Lieferanten-/Mandantenverwaltung", type: NavMenuItemTypeEnum.default, notificationCount: 0, active: '', children: null };
        settingmenu.children.push(novitaetensettings);
        var remisetting = <NavMenuItem>{ label: "Remissionen", routerlink: "remissionen", icon: "assignment_return", iconClass: "defaultIcon material-icons-outlined", tooltip: "Lieferanten-/Mandantenverwaltung", type: NavMenuItemTypeEnum.default, notificationCount: 0, active: '', children: null };
        settingmenu.children.push(remisetting);


        navitems.push(settingmenu);
      }
      this.store.dispatch(new InitNavMenueAction({ entries: navitems }));

      this.authService.claims$.pipe(filter(t => t.client_id), map(f => {
        return <IAuthUser>{ name: f.name, id: f.sub, client_id: f.client_id, member_of: f.member_of, }
      })).subscribe(user => {
        this.authUser = user
        //initialer NotificationCount
        this.store.dispatch(new GetAllNotificationsAction({ targetUserId: user.id }));

      });

    this.sessionMessage$ = this.authService.OAuthService.events.pipe(map(element => {
      if (element.type === 'token_expires') {
        return "Die Anmeldung am Lieferantenportal läuft bald ab.";
      } else if (element.type === 'discovery_document_loaded'
        || element.type === 'silently_refreshed'
        || element.type === 'token_received'
      ) {
        return "Die Anmeldung wurde aktualisiert";
      }
      return "";
    }));
    this.router.events.pipe(filter(r => r instanceof NavigationEnd )).subscribe((n: any) => {
      {
        if ( n && n.url &&  (n.url.startsWith("/novi") || n.url.startsWith("/wup")) && !n.url.startsWith("/novi/settings") )
        {
           if (!this.headerHided)
           {
            this.headerHided = true;
           }
        } else
        {
          this.headerHided = false;
        }
        // aus Test Anastasia #7654
        if ( this.headerHided == true )  {   this.headerToggledIcon.set("expand_more") } else {  this.headerToggledIcon.set("expand_less")};
      }})
  }

  /**theme ändern */
  changetheme(e: any): void {
    try {
      var theme = this.switschLayoutService.possibleStyles[e.checked ? 1 : 0];
      this.switschLayoutService.loadTheme(theme);
      this.document.body.classList.replace(this.document.body.classList[0], this.switschLayoutService.getThemeClass());
      this.document.body.classList.replace(this.document.body.classList[1], this.switschLayoutService.getMaterialClass());
    } catch (e) {
      this.messageService.add({ severity: 'warn', summary: 'Fehler beim Wechseln der Ansicht ', detail: 'Style konnte nicht hinzugefügt werden.' });
      this.document.body.className = "size_1rem_light lightMode";
    }

  }

  getSelectedstyleitem(): ISchemaOption {
    return this.switschLayoutService.selectedTheme;
  }

  getSelectedFontsize(): ISchemaOption {
    return this.switschLayoutService.selectedFontSize;
  }

  /**Styles und fontsize aus Menu auswählbar */
  private GetStyleMenus() {
    this.fontsizeitems = [];
    this.fontsizeitems.push(<MenuItem>{
      label: this.switschLayoutService.selectedFontSize.label, items: this.switschLayoutService.possibleFontSizes.map(m => {
        return <MenuItem>{
          label: m.label,

          command: () => {
            this.switschLayoutService.loadFontSize(m);
            // reload paddings
            this.switschLayoutService.loadTheme(this.switschLayoutService.selectedTheme);
            this.document.body.classList.replace(this.document.body.classList[0], this.switschLayoutService.getThemeClass());
            this.document.body.classList.replace(this.document.body.classList[1], this.switschLayoutService.getMaterialClass());
            this.GetStyleMenus();
          }
        };
      })
    });



    this.styleitems = this.switschLayoutService.possibleStyles.map(m => {
      return <MenuItem>{
        label: m.label
      };
    });

  }


  /**Pfeil drehen */
  getIconInputOut(): string {
    return this.opened ? "arrow_back_ios" : "arrow_forward_ios";
  }

  /**Rein / Raus des SideMenue
   * opened wird hier gesetzt um eine besseres Verhalten zu erzielen (ruckfrei)
  */
  toggle(): void {
    this.opened = !this.opened;
  }

  headerToggle(e)
  {
    this.headerHided = !this.headerHided;
    this.headerToggledIcon = this.headerHided ?  signal("expand_more") :  signal("expand_less");
  }


  /**Selektieren des Naventries
   * hier: auc hKlasse setzen für CSS, eventuell schliessen
   */
  select(event: any, item: NavMenuItem) {
    try {
      if (item == undefined) return;
      var newitem = { ...item };
      if (newitem.children && newitem.children.length > 0) {
        this.childrenExpanded = true;
        event.preventDefault();
        this.contextMenuPosition.x = (this.opened ? 18.75 : 3.75) + 'rem'; //navbar widths !
        this.contextMenuPosition.y = (event.clientY - 5) + 'px';
        this.contextMenu.menuData = { 'item': item };
        this.contextMenu.openMenu();

      } else if (newitem.routerlink) {
        newitem.active = 'active';
        this.store.dispatch(new ChangeNavMenueAction({ entry: newitem }));
        
        this.router.navigate([item.routerlink],{ state: { token: this.authService.getAccessToken() } });
        if (this.opened) {
          this.toggle(); // schliessen wenn offen
        }
      } else if (this.opened == false) {
        this.toggle();
      }

    } catch (e) {

      this.messageService.add({ severity: 'warn', summary: 'Fehler beim Selektieren', detail: 'Fehler beim Menuepunkt auswählen.' });
    }
  }


  logout() {
    this.authService.logoff();
    // this.oauthService.initImplicitFlow();
  }


}

