import {
  ListResponseArgsOfBedarfDto, ListResponseArgsOfKlammerSortimentStichwort, ListResponseArgsOfNovitaetDto,
  KeyValueDto, ListResponseArgsOfSortimentDto, ListResponseArgsOfKlammerDto, ListResponseArgsOfRegionDto, ListResponseArgsOfSaisonDto, ListResponseArgsOfMarketingEreignissDto, ListResponseArgsOfEinkaufDto, ListResponseArgsOfKeyValuePairOfNullableLongAndString, ListResponseArgsOfString, ListResponseArgsOfNullableInteger, NovitaetDto, ListResponseArgsOfListeDto, TypDto, KlammerSortimentStichwort, QueryTokenDTO, ResponseArgsOfIDictionaryOfStringAndInteger, MandantDto, LagerDto, LieferantDto,
  SortimentPlatzierungDto,
  MoebelDto,
  GruppeDto
} from "@app/api/filialmatrix/models";
import { SortimentsstrukturService, NovitaetService, SortimentService, KlammerService, LagerService, RegionService, SaisonService, EreignisService, EinkaufService, ListenService, MandantService, LieferantService, DashboardService, GruppeService } from "@app/api/filialmatrix/services";
import { IBubData } from "@app/common/models/bubdata.model";
import { CustomSetting, CustomSettingsModulEnum, CustomSettingsTypeEnum } from "@app/common/models/custom.settings.models";
import { LiPoContact } from "@app/common/models/lipocontact.model";
import { ColorUserModel, ColorUserModelHist } from "@app/common/models/novi/Column.UserModel";
import { CompareTitleFis } from "@app/common/models/novi/compareTitel.model";
import { ListenTypEnum } from "@app/common/models/novi/NovitätenTableCol";
import { WarengroupDto } from "@app/common/models/warengroupdto";
import { StoreColumnSetting } from '../models/storecolumnsetting';
import { VerlagDto } from "@app/common/models/verlag.model";
import { ISettingChildrenValue, ISettingValue, SettingValueEnum } from "@app/common/models/setting.models";
import { BranchService } from "@app/api/commonWebAPI/services";
import { BranchDTO } from "@app/api/commonWebAPI/models";


export enum CommonDataActionType {


  GetColumnConfigs = '[COMMONDATA] GetColumnConfigs',
  GetColumnConfigsDone = '[COMMONDATA] GetColumnConfigsDone',
  InsertColumnConfigDone = "[COMMONDATA] InsertColumnConfigDone",
  InsertColumnConfig = "[COMMONDATA] InsertColumnConfig",
  UpdateColumnConfig = "[COMMONDATA] UpdateColumnConfig",
  UpdateColumnConfigDone = "[COMMONDATA] UpdateColumnConfigDone",
  DeleteColumnConfig = "[COMMONDATA] DeleteColumnConfig",
  DeleteColumnConfigDone = "[COMMONDATA] DeleteColumnConfigDone",
  CommonDataErrorAction = "[COMMONDATA] CommonDataErrorAction",
  UpdateLastAccessConfig = "[COMMONDATA] UpdateLastAccessConfig",
  UpdateLastAccessConfigDone = "[COMMONDATA] UpdateLastAccessConfigDone",


  DeleteColorsofId = "[COMMONDATA] DeleteColorsofId",
  DeleteColorsofIdDone = "[COMMONDATA] DeleteColorsofIdDone",

  UpdateorAddColorofUser = "[COMMONDATA] UpdateorAddColorofUser",
  PrepareUpdateorAddColorofUser = "[COMMONDATA] PrepareUpdateorAddColorofUser",
  UpdateorAddColorofUserDone = "[COMMONDATA] UpdateorAddColorofUserDone",
  GetDistinctColorsofUser = "[COMMONDATA] GetDistinctColorsofUser",
  GetDistinctColorsofUserDone = "[COMMONDATA] GetDistinctColorsofUserDone",

  GetRegionenAction = "[COMMONDATA] GetRegionenAction",
  GetRegionenActionDone = "[COMMONDATA] GetRegionenActionDone",
  GetSaisonsAction = "[COMMONDATA] GetSaisonsAction",
  GetSaisonsActionDone = "[COMMONDATA] GetSaisonsActionDone",
  GetMarketingEreignisseAction = "[COMMONDATA] GetEreignisseAction",
  GetMarketingEreignisseActionDone = "[COMMONDATA] GetEreignisseActionDone",
  GetEinkaeufeAction = "[COMMONDATA] GetEinkaeufeAction",
  GetEinkaeufeActionDone = "[COMMONDATA] GetEinkaeufeActionDone",

  GetNovitaetenSoMaContactsAction = "[COMMONDATA] GetNovitaetenSoMaContactsAction",
  GetNovitaetenSoMaContactsActionDone = "[COMMONDATA] GetNovitaetenSoMaContactsActionDone",
  GetSortimenteAction = "[COMMONDATA] GetSortimenteAction",
  GetSortimenteActionDone = "[COMMONDATA] GetSortimenteActionDone",
  GetKlammernActionDone = "[COMMONDATA] GetNovitaetenKlammernActionDone",
  GetKlammernAction = "[COMMONDATA] GetNovitaetenKlammernAction",
  GetNovitaetenLookupsIntAction = "[COMMONDATA] GetNovitaetenLookupsIntAction",
  GetNovitaetenLookupsIntActionDone = "[COMMONDATA] GetNovitaetenLookupsIntActionDone",
  GetNovitaetenKategorienAction = "[COMMONDATA] GetNovitaetenKategorienAction",
  GetNovitaetenKategorienActionDone = "[COMMONDATA] GetNovitaetenKategorienActionDone",
  GetNovitaetenWarengruppenActionDone = "[COMMONDATA] GetNovitaetenWarengruppenActionDone",
  GetNovitaetenWarengruppenAction = "[COMMONDATA] GetNovitaetenWarengruppenAction",

  GetStichwortBaumbyKlammer = "[COMMONDATA] GetStichwortBaumbyKlammer",
  GetStichwortBaumbyKlammerDone = "[COMMONDATA] GetStichwortBaumbyKlammerDone",
  GetStichwortBaum = "[COMMONDATA] GetStichwortBaum",
  GetStichwortBaumDone = "[COMMONDATA] GetStichwortBaumDone",
  GetStichwortBaumbyKlammerSortiment = "[COMMONDATA] GetStichwortBaumbyKlammerSortiment",
  GetStichwortBaumbyKlammerSortimentDone = "[COMMONDATA] GetStichwortBaumbyKlammerSortimentDone",
  GetStichwortBaumbyKlammerSortimentStichwort = "[COMMONDATA] GetStichwortBaumbyKlammerSortimentStichwort",
  GetStichwortBaumbyKlammerSortimentStichwortDone = "[COMMONDATA] GetStichwortBaumbyKlammerSortimentStichwortDone",
  GetStichwortBaumbyKlammerExpand = "[COMMONDATA] GetStichwortBaumbyKlammerExpand",
  GetStichwortBaumbyKlammerSortimentExpand = "[COMMONDATA] GetStichwortBaumbyKlammerSortimentExpand",
  GetStichwortBaumbyKlammerExpandDone = "[COMMONDATA] GetStichwortBaumbyKlammerExpandDone",
  GetStichwortBaumbyKlammerSortimentExpandDone = "[COMMONDATA] GetStichwortBaumbyKlammerSortimentExpandDone",
  GetStichwortBaumbyKlammerSortimentStichwortExpand = "[COMMONDATA] GetStichwortBaumbyKlammerSortimentStichwortExpand",
  GetStichwortBaumbyKlammerSortimentStichwortExpandDone = "[COMMONDATA] GetStichwortBaumbyKlammerSortimentStichwortExpandDone",

  GetNovitaetBubDataByEansAction = "[COMMONDATA] GetNovitaetBubDataByEansAction",
  GetNovitaetBubDataByEansActionDone = "[COMMONDATA] GetNovitaetBubDataByEansActionDone",
  GetNovitaetBedarfAction = "[COMMONDATA] GetNovitaetBedarfAction",
  GetNovitaetBedarfActionDone = "[COMMONDATA] GetNovitaetBedarfActionDone",

  GetFilteredEinkaeufeActionDone = "[COMMONDATA] GetFilteredEinkaeufeActionDone",
  GetFilteredEinkaeufeAction = "[COMMONDATA] GetFilteredEinkaeufeAction",
  GetNovitaetenLookupsStringAction = "[COMMONDATA] GetNovitaetenLookupsStringAction",
  GetNovitaetenLookupsStringActionDone = "[COMMONDATA] GetNovitaetenLookupsStringActionDone",
  GetNovitaetenLookupsIdNameAction = "[COMMONDATA] GetNovitaetenLookupsIdNameAction",
  GetNovitaetenLookupsIdNameActionDone = "[COMMONDATA] GetNovitaetenLookupsIdNameActionDone",
  KeywordErrorAction = "[COMMONDATA] KeywordErrorAction",

  GetAktuellenEinkaufAction = "[COMMONDATA] GetAktuellenEinkaufAction",
  GetAktuellenEinkaufActionDone = "[COMMONDATA] GetAktuellenEinkaufActionDone",
  ClearPrepareUpdateorAddColorofUser = "[COMMONDATA] ClearPrepareUpdateorAddColorofUser",
  ClearCommonDataErrorAction = "[COMMONDATA] ClearCommonDataErrorAction",
  GetCompareListForActionDone = "[COMMONDATA] GetCompareListForActionDone",
  GetCompareListAction = "[COMMONDATA] GetCompareListForAction",
  GetNovitaetenListenActionDone = "[COMMONDATA] GetNovitaetenListenActionDone",
  GetNovitaetenListenAction = "[COMMONDATA] GetNovitaetenListenAction",
  GetSortimentstypenAction = "[COMMONDATA] GetSortimentstypenAction",
  GetSortimentstypenActionDone = "[COMMONDATA] GetSortimentstypenActionDone",
  RemovePrepareUpdateorAddColorofUser = "[COMMONDATA] RemovePrepareUpdateorAddColorofUser",
  GetVerlagebySearchAction = "[COMMONDATA] GetVerlagebySearchAction",
  GetVerlagebySearchActionDone = "[COMMONDATA] GetVerlagebySearchActionDone",
  AddGetStichwortBaum = "[COMMONDATA] AddGetStichwortBaum",
  ClearCommonDataCacheAction = "[COMMONDATA] ClearCacheAction",
  QueryAlsExcelExportierenAction = "[COMMONDATA] QueryAlsExcelExportierenAction",
  QueryAlsExcelExportierenActionDone = "[COMMONDATA] QueryAlsExcelExportierenActionDone",
  GetKategorienderVorabplatzierungen = "[COMMONDATA] GetKategorienderVorabplatzierungen",
  GetKategorienderVorabplatzierungenDone = "[COMMONDATA] GetKategorienderVorabplatzierungenDone",
  GetNoviHitsActionDone = "[COMMONDATA] GetNoviHitsActionDone",
  GetNoviHitsAction = "[COMMONDATA] GetNoviHitsAction",
  GetDashboardSumAction = "[COMMONDATA] GetDashboardSumAction",
  GetDashboardSumActionDone = "[COMMONDATA] GetDashboardSumActionDone",
  ClearConfigSuccess = "[COMMONDATA] ClearConfigSuccess",
  GetNovitaetBubDataByEanAction = "[COMMONDATA] GetNovitaetBubDataByEanAction",
  GetNovitaetBubDataByEanActionDone = "[COMMONDATA] GetNovitaetBubDataByEanActionDone",
  GetZukuenftigeEinkaufActionDone = "[COMMONDATA] GetZukuenftigeEinkaufActionDone",
  GetZukuenftigeEinkaufAction = "[COMMONDATA] GetZukuenftigeEinkaufAction",
  GetMandantenActionDone = "[COMMONDATA] GetMandantenActionDone",
  GetMandantenAction = "[COMMONDATA] GetMandantenAction",
  GetMandantAction = "[COMMONDATA] GetMandantAction",
  GetMandantActionDone = "[COMMONDATA] GetMandantActionDone",
  GetLagerAction = "[COMMONDATA] GetLagerAction",
  GetLagerActionDone = "[COMMONDATA] GetLagerActionDone",
  GetLieferantenActionDone = "[COMMONDATA] GetLieferantenActionDone",
  GetLieferantenAction = "[COMMONDATA] GetLieferantenAction",
  GetLieferantAction = "[COMMONDATA] GetLieferantAction",
  GetLieferantActionDone = "[COMMONDATA] GetLieferantActionDone",
  GetVerlagChildrenActionDone = "[COMMONDATA] GetVerlagChildrenActionDone",
  GetVerlagChildrenAction = "[COMMONDATA] GetVerlagChildrenAction",
  GetSettings = "[COMMONDATA] GetSettings",
  GetSettingsDone = "[COMMONDATA] GetSettingsDone",
  GetSettingsChildrenAction = "[COMMONDATA] GetSettingsChildrenAction",
  GetSettingsChildrenActionDone = "[COMMONDATA] GetSettingsChildrenActionDone",

  DeleteCustomConfigbyName = "[COMMONDATA] DeleteCustomConfigbyName",
  DeleteCustomConfigbyNameDone = "[COMMONDATA] DeleteCustomConfigbyNameDone",
  GetBranchesAction = "[COMMONDATA] GetBranchesAction",
  GetBranchesActionDone = "[COMMONDATA] GetBranchesActionDone",
  GetBranchGroupsAction = "[COMMONDATA] GetBranchGroupsAction",
  GetBranchGroupsActionDone = "[COMMONDATA] GetBranchGroupsActionDone",
  GetMoebelAction = "[COMMONDATA] GetMoebelAction",
  GetMoebelActionDone = "[COMMONDATA] GetMoebelActionDone",
  ClearCompareListAction = "ClearCompareListAction",
  RemoveCompareListAction = "DeleteCompareListAction",
  GetSortimentsGruppenAction = "GetSortimentsGruppenAction",
  GetSortimentsGruppenActiondone = "GetSortimentsGruppenActiondone"
}

export class GetMoebelAction {
  readonly type =  CommonDataActionType.GetMoebelAction ;
  constructor( public payload: { param: SortimentService.SortimentGetMoebelParams  } ) {}
}

export class GetMoebelActionDone {
  readonly type =  CommonDataActionType.GetMoebelActionDone ;
  constructor( public payload: { moebel: MoebelDto[], params: SortimentService.SortimentGetMoebelParams  } ) {}
}

export class GetNoviHitsAction {
  readonly type = CommonDataActionType.GetNoviHitsAction;
  constructor(public payload: { params: { [key: string]: QueryTokenDTO } }) { }

}

export class GetNoviHitsActionDone {
  readonly type = CommonDataActionType.GetNoviHitsActionDone;
  constructor(public payload: { result: ResponseArgsOfIDictionaryOfStringAndInteger }) { }
}


export class GetDashboardSumAction {
  readonly type = CommonDataActionType.GetDashboardSumAction;
  constructor(public payload: { param: DashboardService.DashboardGetDashboardSumParams }) { }

}

export class GetDashboardSumActionDone {
  readonly type = CommonDataActionType.GetDashboardSumActionDone;
  constructor(public payload: { result: ResponseArgsOfIDictionaryOfStringAndInteger }) { }
}

export class GetSortimentsGruppenAction {
  readonly type = CommonDataActionType.GetSortimentsGruppenAction;
  constructor(public payload: { params: GruppeService.GruppeQueryGruppenParams} ) {}
}

export class GetSortimentsGruppenActionDone {
  readonly type = CommonDataActionType.GetSortimentsGruppenActiondone;
  constructor(public payload: { items: GruppeDto[]} ) {}
}


export class RemoveCompareListAction {
  readonly type = CommonDataActionType.RemoveCompareListAction;
  constructor(public payload: { id: number, ean: string }) { }

}
export class ClearCompareListAction {
  readonly type = CommonDataActionType.ClearCompareListAction;
  constructor(public payload: { id: number }) { }

}


export class GetCompareListAction {
  readonly type = CommonDataActionType.GetCompareListAction;
  constructor(public payload: { novitaetid: number, eans: string[] }) { }

}

export class GetCompareListActionDone {
  readonly type = CommonDataActionType.GetCompareListForActionDone;
  constructor(public payload: { noviwupId: any, compareTitles: CompareTitleFis[] }) { }
}


export class GetAktuellenEinkaufAction {
  readonly type = CommonDataActionType.GetAktuellenEinkaufAction;
  constructor(public payload: { params: EinkaufService.EinkaufQueryEinkaufParams }) { }
}

export class GetAktuellenEinkaufActionDone {
  readonly type = CommonDataActionType.GetAktuellenEinkaufActionDone;
  constructor(public payload: { list: ListResponseArgsOfEinkaufDto }) { }
}


export class GetZukuenftigeEinkaufAction {
  readonly type = CommonDataActionType.GetZukuenftigeEinkaufAction;
  constructor(public payload: { params: EinkaufService.EinkaufQueryEinkaufParams }) { }
}

export class GetZukuenftigeEinkaufActionDone {
  readonly type = CommonDataActionType.GetZukuenftigeEinkaufActionDone;
  constructor(public payload: { list: ListResponseArgsOfEinkaufDto }) { }
}


export class GetNovitaetenLookupsIdNameAction {
  readonly type = CommonDataActionType.GetNovitaetenLookupsIdNameAction;
  constructor(public payload: { params: NovitaetService.NovitaetQueryNovitaetenIdnameLookupParams }) { }
}

export class GetNovitaetenLookupsIdNameActionDone {
  readonly type = CommonDataActionType.GetNovitaetenLookupsIdNameActionDone;
  constructor(public payload: {
    key: string,
    list: ListResponseArgsOfKeyValuePairOfNullableLongAndString
  }) { }
}


export class GetNovitaetenLookupsStringAction {
  readonly type = CommonDataActionType.GetNovitaetenLookupsStringAction;
  constructor(public payload: { params: NovitaetService.NovitaetQueryNovitaetenStringLookupParams }) { }
}

export class GetNovitaetenLookupsStringActionDone {
  readonly type = CommonDataActionType.GetNovitaetenLookupsStringActionDone;
  constructor(public payload: {
    key: string,
    list: ListResponseArgsOfString
  }) { }
}

export class ClearCommonDataErrorAction {
  readonly type = CommonDataActionType.ClearCommonDataErrorAction;
  constructor() { }
}

export class ClearCommonaDataCacheAction {
  readonly type = CommonDataActionType.ClearCommonDataCacheAction;
  constructor(public payload: { clearstichwortList: boolean, clearsortimentStruktur: boolean }) { }
}

export class CommonDataErrorAction {
  readonly type = CommonDataActionType.CommonDataErrorAction;
  constructor(public payload: { type: CommonDataActionType, message: string, error: any }) { }
}

export class KeywordErrorAction {
  readonly type = CommonDataActionType.KeywordErrorAction;
  constructor(public payload: { type: CommonDataActionType, message: string, error: any, id: number, params: any }) { }
}

export class DeleteColorsofId {
  readonly type = CommonDataActionType.DeleteColorsofId;
  constructor(public payload: { id: number }) { }
}

export class DeleteColorsofIdDone {
  readonly type = CommonDataActionType.DeleteColorsofIdDone;
  constructor(public payload: { id: number }) { }
}


export class ClearPrepareUpdateorAddColorofUser {
  readonly type = CommonDataActionType.ClearPrepareUpdateorAddColorofUser;
  constructor() { }
}

export class RemovePrepareUpdateorAddColorofUser {
  readonly type = CommonDataActionType.RemovePrepareUpdateorAddColorofUser;
  constructor(public payload: { id: number }) { }
}


export class PrepareUpdateorAddColorofUser {
  readonly type = CommonDataActionType.PrepareUpdateorAddColorofUser;
  constructor(public payload: { colorofuser: ColorUserModelHist }) { }
}

export class UpdateorAddColorofUser {
  readonly type = CommonDataActionType.UpdateorAddColorofUser;
  constructor(public payload: { colorofuser: ColorUserModel }) { }
}

export class UpdateorAddColorofUserDone {
  readonly type = CommonDataActionType.UpdateorAddColorofUserDone;
  constructor(public payload: { colorofuser: ColorUserModel }) { }
}

export class GetDistinctColorsofUser {
  readonly type = CommonDataActionType.GetDistinctColorsofUser;
  constructor(public payload: { userid: string }) { }
}

export class GetDistinctColorsofUserDone {
  readonly type = CommonDataActionType.GetDistinctColorsofUserDone;
  constructor(public payload: { colorsofUser: ColorUserModel[] }) { }
}


export class GetNovitaetBedarfAction {
  readonly type = CommonDataActionType.GetNovitaetBedarfAction;
  constructor(public payload: { novitaetenId: number }) { }
}


export class GetNovitaetBedarfActionDone {
  readonly type = CommonDataActionType.GetNovitaetBedarfActionDone;
  constructor(public payload: { id: number, data: ListResponseArgsOfBedarfDto }) { }
}


export class GetNovitaetBubDataByEanAction {
  readonly type = CommonDataActionType.GetNovitaetBubDataByEanAction;
  constructor(public payload: { ean: string }) { }
}


export class GetNovitaetBubDataByEanActionDone {
  readonly type = CommonDataActionType.GetNovitaetBubDataByEanActionDone;
  constructor(public payload: { bubData: IBubData, searchedean: string }) { }
}


export class GetNovitaetBubDataByEansAction {
  readonly type = CommonDataActionType.GetNovitaetBubDataByEansAction;
  constructor(public payload: { eans: string[] }) { }
}


export class GetNovitaetBubDataByEansActionDone {
  readonly type = CommonDataActionType.GetNovitaetBubDataByEansActionDone;
  constructor(public payload: { bubDataList: IBubData[], searchedeans: string[] }) { }
}


export class GetStichwortBaum {
  readonly type = CommonDataActionType.GetStichwortBaum;
  constructor(public payload: { id: number }) { }
}

export class AddGetStichwortBaum {
  readonly type = CommonDataActionType.AddGetStichwortBaum;
  constructor(public payload: { id: number, data: KlammerSortimentStichwort[] }) { }
}



export class GetStichwortBaumDone {
  readonly type = CommonDataActionType.GetStichwortBaumDone;
  constructor(public payload: { id: number, data: ListResponseArgsOfKlammerSortimentStichwort }) { }
}

export class GetStichwortBaumbyKlammer {
  readonly type = CommonDataActionType.GetStichwortBaumbyKlammer;
  constructor(public payload: { id: number, klammerid: number }) { }
}

export class GetStichwortBaumbyKlammerDone {
  readonly type = CommonDataActionType.GetStichwortBaumbyKlammerDone;
  constructor(public payload: { id: number, klammerid: number, data: ListResponseArgsOfKlammerSortimentStichwort }) { }
}

export class GetStichwortBaumbyKlammerSortiment {
  readonly type = CommonDataActionType.GetStichwortBaumbyKlammerSortiment;
  constructor(public payload: { id: number, params: SortimentsstrukturService.SortimentsstrukturGetKlammerSortimentStichwort3Params }) { }
}

export class GetStichwortBaumbyKlammerSortimentDone {
  readonly type = CommonDataActionType.GetStichwortBaumbyKlammerSortimentDone;
  constructor(public payload: { id: number, params: SortimentsstrukturService.SortimentsstrukturGetKlammerSortimentStichwort3Params, data: ListResponseArgsOfKlammerSortimentStichwort }) { }
}

export class GetStichwortBaumbyKlammerSortimentStichwort {
  readonly type = CommonDataActionType.GetStichwortBaumbyKlammerSortimentStichwort;
  constructor(public payload: { id: number, params: SortimentsstrukturService.SortimentsstrukturGetKlammerSortimentStichwort4Params }) { }
}

export class GetStichwortBaumbyKlammerSortimentStichwortDone {
  readonly type = CommonDataActionType.GetStichwortBaumbyKlammerSortimentStichwortDone;
  constructor(public payload: { id: number, params: SortimentsstrukturService.SortimentsstrukturGetKlammerSortimentStichwort4Params, data: ListResponseArgsOfKlammerSortimentStichwort }) { }
}

//expand

export class GetStichwortBaumbyKlammerExpand {
  readonly type = CommonDataActionType.GetStichwortBaumbyKlammerExpand;
  constructor(public payload: { id: number, klammerid: number }) { }
}

export class GetStichwortBaumbyKlammerExpandDone {
  readonly type = CommonDataActionType.GetStichwortBaumbyKlammerExpandDone;
  constructor(public payload: { id: number, klammerid: number, data: ListResponseArgsOfKlammerSortimentStichwort }) { }
}

export class GetStichwortBaumbyKlammerSortimentExpand {
  readonly type = CommonDataActionType.GetStichwortBaumbyKlammerSortimentExpand;
  constructor(public payload: { id: number, params: SortimentsstrukturService.SortimentsstrukturGetKlammerSortimentStichwort3Params }) { }
}

export class GetStichwortBaumbyKlammerSortimentExpandDone {
  readonly type = CommonDataActionType.GetStichwortBaumbyKlammerSortimentExpandDone;
  constructor(public payload: { id: number, params: SortimentsstrukturService.SortimentsstrukturGetKlammerSortimentStichwort3Params, data: ListResponseArgsOfKlammerSortimentStichwort }) { }
}

export class GetStichwortBaumbyKlammerSortimentStichwortExpand {
  readonly type = CommonDataActionType.GetStichwortBaumbyKlammerSortimentStichwortExpand;
  constructor(public payload: { id: number, params: SortimentsstrukturService.SortimentsstrukturGetExpandedKlammerSortimentStichwortParams }) { }
}

export class GetStichwortBaumbyKlammerSortimentStichwortExpandDone {
  readonly type = CommonDataActionType.GetStichwortBaumbyKlammerSortimentStichwortExpandDone;
  constructor(public payload: { id: number, params: SortimentsstrukturService.SortimentsstrukturGetKlammerSortimentStichwort4Params, data: ListResponseArgsOfKlammerSortimentStichwort }) { }
}

//ende




export class GetVerlagChildrenAction {
  readonly type = CommonDataActionType.GetVerlagChildrenAction;
  constructor(public payload: { id: number }) { }
}

export class GetVerlagChildrenActionDone {
  readonly type = CommonDataActionType.GetVerlagChildrenActionDone;
  constructor(public payload: { id: number,
    result: VerlagDto[]
  }) { }
}



export class GetVerlagebySearchAction {
  readonly type = CommonDataActionType.GetVerlagebySearchAction;
  constructor(public payload: { srch: string }) { }
}

export class GetVerlagebySearchActionDone {
  readonly type = CommonDataActionType.GetVerlagebySearchActionDone;
  constructor(public payload: {
    result: VerlagDto[]
  }) { }
}



export class GetNovitaetenWarengruppenAction {
  readonly type = CommonDataActionType.GetNovitaetenWarengruppenAction;
  constructor(public payload: {}) { }
}

export class GetNovitaetenWarengruppenActionDone {
  readonly type = CommonDataActionType.GetNovitaetenWarengruppenActionDone;
  constructor(public payload: {
    list: WarengroupDto[]
  }) { }
}

export class GetNovitaetenSoMaContactsAction {
  readonly type = CommonDataActionType.GetNovitaetenSoMaContactsAction;
  constructor(public payload: {}) { }
}

export class GetNovitaetenSoMaContactsActionDone {
  readonly type = CommonDataActionType.GetNovitaetenSoMaContactsActionDone;
  constructor(public payload: {
    list: LiPoContact[]
  }) { }
}

export class GetNovitaetenKategorienAction {
  readonly type = CommonDataActionType.GetNovitaetenKategorienAction;
  constructor(public payload: {}) { }
}

export class GetNovitaetenKategorienActionDone {
  readonly type = CommonDataActionType.GetNovitaetenKategorienActionDone;
  constructor(public payload: { list: KeyValueDto[] }) { }
}

export class GetSortimenteAction {
  readonly type = CommonDataActionType.GetSortimenteAction;
  constructor(public payload: { param: SortimentService.SortimentQuerySortimenteParams }) { }
}

export class GetSortimenteActionDone {
  readonly type = CommonDataActionType.GetSortimenteActionDone;
  constructor(public payload: { sortimente: ListResponseArgsOfSortimentDto, oneperAssignment: boolean }) { }
}

export class GetKlammernAction {
  readonly type = CommonDataActionType.GetKlammernAction;
  constructor(public payload: { param: KlammerService.KlammerQueryKlammernParams }) { }
}

export class GetKlammernActionDone {
  readonly type = CommonDataActionType.GetKlammernActionDone;
  constructor(public payload: { klammern: ListResponseArgsOfKlammerDto }) { }
}

export class GetRegionenAction {
  readonly type = CommonDataActionType.GetRegionenAction;
  constructor(public payload: { params: RegionService.RegionQueryRegionenParams }) { }
}

export class GetRegionenActionDone {
  readonly type = CommonDataActionType.GetRegionenActionDone;
  constructor(public payload: {
    list: ListResponseArgsOfRegionDto
  }) { }
}

export class GetSaisonsAction {
  readonly type = CommonDataActionType.GetSaisonsAction;
  constructor(public payload: { params: SaisonService.SaisonQuerySaisonParams }) { }
}

export class GetSaisonsActionDone {
  readonly type = CommonDataActionType.GetSaisonsActionDone;
  constructor(public payload: {
    list: ListResponseArgsOfSaisonDto
  }) { }
}

export class GetMarketingEreignisseAction {
  readonly type = CommonDataActionType.GetMarketingEreignisseAction;
  constructor(public payload: { params: EreignisService.EreignisQueryEreignisParams }) { }
}

export class GetMarketingEreignisseActionDone {
  readonly type = CommonDataActionType.GetMarketingEreignisseActionDone;
  constructor(public payload: {
    list: ListResponseArgsOfMarketingEreignissDto
  }) { }
}

export class GetEinkaeufeAction {
  readonly type = CommonDataActionType.GetEinkaeufeAction;
  constructor(public payload: { params: EinkaufService.EinkaufQueryEinkaufParams }) { }
}

export class GetEinkaeufeActionDone {
  readonly type = CommonDataActionType.GetEinkaeufeActionDone;
  constructor(public payload: {
    list: ListResponseArgsOfEinkaufDto
  }) { }
}


export class GetNovitaetenLookupsIntAction {
  readonly type = CommonDataActionType.GetNovitaetenLookupsIntAction;
  constructor(public payload: { params: NovitaetService.NovitaetQueryNovitaetenIntLookupParams }) { }
}

export class GetNovitaetenLookupsIntActionDone {
  readonly type = CommonDataActionType.GetNovitaetenLookupsIntActionDone;
  constructor(public payload: {
    key: string,
    list: ListResponseArgsOfNullableInteger
  }) { }
}


export class ClearConfigSuccess {
  readonly type = CommonDataActionType.ClearConfigSuccess;
  constructor() { }
}




export class UpdateLastAccessConfig {
  readonly type = CommonDataActionType.UpdateLastAccessConfig;
  constructor(public payload: { customsetting: CustomSetting<StoreColumnSetting> }) { }
}

export class UpdateLastAccessConfigDone {
  readonly type = CommonDataActionType.UpdateLastAccessConfigDone;
  constructor(public payload: { id: number }) { }
}



export class DeleteCustomConfigbyName {
  readonly type = CommonDataActionType.DeleteCustomConfigbyName;
  constructor(public payload: { name: string, modul: CustomSettingsModulEnum, type: CustomSettingsTypeEnum }) {}
}


export class DeleteCustomConfigbyNameDone {
  readonly type = CommonDataActionType.DeleteCustomConfigbyNameDone;
  constructor(public payload: { id: number}) {}
}



export class DeleteColumnConfig {
  readonly type = CommonDataActionType.DeleteColumnConfig;
  constructor(public payload: { id: number }) { }
}

export class DeleteColumnConfigDone {
  readonly type = CommonDataActionType.DeleteColumnConfigDone;
  constructor(public payload: { id: number }) { }
}

export class UpdateColumnConfig {
  readonly type = CommonDataActionType.UpdateColumnConfig;
  constructor(public payload: { customsetting: CustomSetting<StoreColumnSetting> }) { }
}

export class UpdateColumnConfigDone {
  readonly type = CommonDataActionType.UpdateColumnConfigDone;
  constructor(public payload: { customsetting: CustomSetting<StoreColumnSetting> }) { }
}

export class InsertColumnConfig {
  readonly type = CommonDataActionType.InsertColumnConfig;
  constructor(public payload: { customsetting: CustomSetting<StoreColumnSetting> }) { }
}

export class InsertColumnConfigDone {
  readonly type = CommonDataActionType.InsertColumnConfigDone;
  constructor(public payload: { customsetting: CustomSetting<StoreColumnSetting> }) { }
}

export class GetColumnConfigs {
  readonly type = CommonDataActionType.GetColumnConfigs;
  constructor(public payload: { userid: string, modul: CustomSettingsModulEnum, type: CustomSettingsTypeEnum }) { }
}


export class GetColumnConfigsDone {
  readonly type = CommonDataActionType.GetColumnConfigsDone;
  constructor(public payload: { columnConfigs: Array<CustomSetting<StoreColumnSetting>> }) { }
}



export class GetNovitaetenListenAction {
  readonly type = CommonDataActionType.GetNovitaetenListenAction;
  constructor(public payload: { typ: ListenTypEnum, param: ListenService.ListenQueryListeParams }) { }
}

export class GetNovitaetenListenActionDone {
  readonly type = CommonDataActionType.GetNovitaetenListenActionDone;
  constructor(public payload: {
    typ: ListenTypEnum,
    list: ListResponseArgsOfListeDto
  }) { }
}

export class GetSortimentstypenAction {
  readonly type = CommonDataActionType.GetSortimentstypenAction;
  constructor(public payload: { params: SortimentService.SortimentQuerySortimentsTypenParams }) { }
}


export class GetSortimentstypenActionDone {
  readonly type = CommonDataActionType.GetSortimentstypenActionDone;
  constructor(public payload: { typen: TypDto[] }) { }
}


export class QueryAlsExcelExportierenAction {
  readonly type = CommonDataActionType.QueryAlsExcelExportierenAction;
  constructor(public payload: { queryToken: QueryTokenDTO }) {
  }
}

export class QueryAlsExcelExportierenActionDone {
  readonly type = CommonDataActionType.QueryAlsExcelExportierenActionDone;
  constructor(public payload: { file: Blob }) {
  }
}


export class GetKategorienderVorabplatzierungen {
  readonly type = CommonDataActionType.GetKategorienderVorabplatzierungen;
  constructor(public payload: { sortimentid: number }) { }
}

export class GetKategorienderVorabplatzierungenDone {
  readonly type = CommonDataActionType.GetKategorienderVorabplatzierungenDone;
  constructor(public payload: { key: number, result: any }) { }
}

export class GetMandantenAction {
  readonly type = CommonDataActionType.GetMandantenAction;
  constructor(public payload: { params: MandantService.MandantQueryParams }) { }
}

export class GetMandantenActionDone {
  readonly type = CommonDataActionType.GetMandantenActionDone;
  constructor(public payload: { result: MandantDto[] }) { }
}


export class GetMandantAction {
  readonly type = CommonDataActionType.GetMandantAction;
  constructor(public payload: { params: MandantService.MandantQueryParams }) { }
}

export class GetMandantActionDone {
  readonly type = CommonDataActionType.GetMandantActionDone;
  constructor(public payload: { result: MandantDto[] }) { }
}


export class GetLieferantenAction {
  readonly type = CommonDataActionType.GetLieferantenAction;
  constructor(public payload: { params: LieferantService.LieferantQueryParams }) { }
}

export class GetLieferantenActionDone {
  readonly type = CommonDataActionType.GetLieferantenActionDone;
  constructor(public payload: { result: LieferantDto[] }) { }
}


export class GetLieferantAction {
  readonly type = CommonDataActionType.GetLieferantAction;
  constructor(public payload: { params: LieferantService.LieferantQueryParams }) { }
}

export class GetLieferantActionDone {
  readonly type = CommonDataActionType.GetLieferantActionDone;
  constructor(public payload: { result: LieferantDto[] }) { }
}


export class GetLagerAction {
  readonly type = CommonDataActionType.GetLagerAction;
  constructor(public payload: { params: LagerService.LagerQueryLagerParams }) { }
}

export class GetLagerActionDone {
  readonly type = CommonDataActionType.GetLagerActionDone;
  constructor(public payload: { lager: LagerDto[] }) { }
}


export class GetSettingsStateAction {
  readonly type =  CommonDataActionType.GetSettings ;
  constructor( public payload: {  settingid :  SettingValueEnum, onlyActive: boolean}) {}
}


export class GetSettingsStateActionDone {
  readonly type =  CommonDataActionType.GetSettingsDone ;
  constructor( public payload: {  settingValues : ISettingValue[]}) {}
}




export class GetBranchesAction {
  readonly type = CommonDataActionType.GetBranchesAction;
  constructor( public payload: { params: BranchService.BranchQueryBranchParams}) {}
}

export class GetBranchesActionDone {
    readonly type = CommonDataActionType.GetBranchesActionDone;
    constructor(public payload: { items: BranchDTO[] }) {}
}


export class GetBranchGroupsAction {
  readonly type = CommonDataActionType.GetBranchGroupsAction;
  constructor( public payload: { params: BranchService.BranchQueryBranchGroupParams}) {}
}

export class GetBranchGroupsActionDone {
    readonly type = CommonDataActionType.GetBranchGroupsActionDone;
    constructor(public payload: { items: any[] }) {}
}

export class GetSettingsChildrenAction {
  readonly type =  CommonDataActionType.GetSettingsChildrenAction ;
  constructor( public payload: {  settingvalueId : number}) {}
}

export class GetSettingsChildrenActionDone {
  readonly type =  CommonDataActionType.GetSettingsChildrenActionDone ;
  constructor( public payload: { settingvalueId: number,  settingChildrenValues : ISettingChildrenValue[]}) {}
}


export type DataCommonActions =

  GetNovitaetenListenAction | GetNovitaetenListenActionDone
  | GetColumnConfigs | GetColumnConfigsDone
  | InsertColumnConfig | InsertColumnConfigDone
  | UpdateColumnConfig | UpdateColumnConfigDone
  | DeleteColumnConfig | DeleteColumnConfigDone
  | UpdateLastAccessConfig | UpdateLastAccessConfigDone

  | UpdateorAddColorofUser | UpdateorAddColorofUserDone | PrepareUpdateorAddColorofUser | ClearPrepareUpdateorAddColorofUser
  | GetDistinctColorsofUser | GetDistinctColorsofUserDone

  | GetRegionenActionDone | GetRegionenAction | GetSaisonsAction | GetSaisonsActionDone | GetEinkaeufeAction | GetEinkaeufeActionDone | GetMarketingEreignisseAction | GetMarketingEreignisseActionDone
  | GetNovitaetenSoMaContactsActionDone | GetNovitaetenSoMaContactsAction
  | GetSortimenteAction | GetSortimenteActionDone | GetKlammernActionDone | GetKlammernAction
  | GetNovitaetenLookupsIntAction | GetNovitaetenLookupsIntActionDone
  | GetNovitaetenKategorienAction | GetNovitaetenKategorienActionDone
  | GetNovitaetenWarengruppenActionDone | GetNovitaetenWarengruppenAction
  | DeleteColorsofId | DeleteColorsofIdDone
  | GetStichwortBaum | GetStichwortBaumDone
  | GetStichwortBaumbyKlammer | GetStichwortBaumbyKlammerDone
  | GetStichwortBaumbyKlammerSortiment | GetStichwortBaumbyKlammerSortimentDone
  | GetStichwortBaumbyKlammerSortimentStichwort | GetStichwortBaumbyKlammerSortimentStichwortDone
  | GetStichwortBaumbyKlammerSortimentExpand | GetStichwortBaumbyKlammerSortimentExpandDone
  | GetStichwortBaumbyKlammerExpand | GetStichwortBaumbyKlammerExpandDone
  | GetStichwortBaumbyKlammerSortimentStichwortExpand | GetStichwortBaumbyKlammerSortimentStichwortExpandDone
  | GetNovitaetBubDataByEansActionDone | GetNovitaetBubDataByEansAction
  | GetNovitaetBedarfAction | GetNovitaetBedarfActionDone
  | GetNovitaetenLookupsStringActionDone | GetNovitaetenLookupsStringAction | CommonDataErrorAction | ClearCommonDataErrorAction
  | GetNovitaetenLookupsIdNameAction | GetNovitaetenLookupsIdNameActionDone
  | GetAktuellenEinkaufAction | GetAktuellenEinkaufActionDone
  | GetZukuenftigeEinkaufAction | GetZukuenftigeEinkaufActionDone
  | GetCompareListAction | GetCompareListActionDone
  | GetSortimentstypenAction | GetSortimentstypenActionDone | RemovePrepareUpdateorAddColorofUser
  | GetVerlagebySearchAction | GetVerlagebySearchActionDone | AddGetStichwortBaum | ClearCommonaDataCacheAction
  | QueryAlsExcelExportierenAction | QueryAlsExcelExportierenActionDone
  | GetKategorienderVorabplatzierungen | GetKategorienderVorabplatzierungenDone | ClearConfigSuccess
  | GetNoviHitsAction | GetNoviHitsActionDone | GetDashboardSumAction | GetDashboardSumActionDone
  | GetNovitaetBubDataByEanActionDone | GetNovitaetBubDataByEanAction | KeywordErrorAction
  | GetMandantenAction | GetMandantenActionDone | GetMandantAction | GetMandantActionDone
  | GetLagerAction | GetLagerActionDone | GetLieferantenActionDone | GetLieferantenAction | GetLieferantAction | GetLieferantActionDone
  | GetVerlagChildrenAction |GetVerlagChildrenActionDone | GetSettingsStateActionDone | GetSettingsStateAction
  | GetSettingsChildrenAction | GetSettingsChildrenActionDone

  | DeleteCustomConfigbyName | DeleteCustomConfigbyNameDone
  | GetBranchesActionDone | GetBranchesAction | GetBranchGroupsAction | GetBranchGroupsActionDone
  | GetMoebelAction | GetMoebelActionDone | ClearCompareListAction | RemoveCompareListAction
  | GetSortimentsGruppenAction | GetSortimentsGruppenActionDone
  ;

