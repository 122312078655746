import { TaskModel } from './task.model';

import { KlammerDto, SortimentDto, TypDto } from '@app/api/filialmatrix/models';

export interface NewTaskModel {
    sortimentMaster: KlammerDto;
    sortiment: SortimentDto;
    activity: TypDto;
    startMaster: Date;
    endMaster: Date;
    startSortiment: Date;
    endSortiment: Date;
    startActivity: Date;
    endActivity: Date;
    attribute: TaskModel;
}
