import { UserTasksState, InitialUserTasksState } from './state';
import { TaskUserModel } from '@app/common/models/task-user.model';
import * as cloneDeep from 'lodash/fp/cloneDeep';
import { UserViewActionType, GetTasksForUserDoneAction, UserViewActions, SetTaskStateAction, UserTaskErrorAction } from './actions';
import { SettingErrorAction } from '@app/+settings/store/actions';
import { SimpleResult, SimpleResultError } from '@app/common/models/simpleResult.model';

export function reducer(state: UserTasksState = InitialUserTasksState, action: UserViewActions): UserTasksState {

  switch (action.type) {
    case UserViewActionType.GetTasksForUserDone:
      return GetUserTasksDone(state, action);
    case UserViewActionType.SetTaskState:
      return SetTaskState(state, action);
    case UserViewActionType.UserTaskErrorAction:
      return SetErrorState(state, action);
    case UserViewActionType.UserTaskClearErrorAction:
      return {
        ...state,
        currenterror: undefined
      }


  }

  return state;
}

export function GetUserTasksDone(state: UserTasksState, action: GetTasksForUserDoneAction): UserTasksState {

  return {
    ...state,
    tasks: action.payload.tasks,
    taskHits: action.payload.tasks && action.payload.tasks.length > 0 ? action.payload.tasks[0].hits : 0,
  }
}

export function SetErrorState(state: UserTasksState, action: UserTaskErrorAction): UserTasksState {

  return {
    ...state,
    currenterror: SimpleResultError.getSimpleResultError(action.payload.error, action.payload.message)
  }

}


export function SetTaskState(state: UserTasksState, action: SetTaskStateAction): UserTasksState {
  const idx = state.tasks.findIndex(x => x.id == action.payload.taskId);
  let task = cloneDeep(state.tasks.find(x => x.id == action.payload.taskId));
  task.taskState = action.payload.newTaskState;
  return {
    ...state,
    tasks: [...state.tasks.slice(0, idx), task, ...state.tasks.slice(idx + 1)]
  }
}
