<div class="container-fluid">
  <div class="grid" style="padding-top:0.5rem">
    <mat-icon class="pageTitleIcon material-icons-outlined">library_books</mat-icon>
    <h2 class="pageTitleHeader">Artikeldaten</h2>

    <i name="newarticledata" *ngIf="isLieferant()" class="pi pi-plus pageAddButtonIcon"
      (click)="openArticleDataInsertDialog()" pTooltip="Artikeldaten erstellen"></i>

  </div>




  <div class="contentSeperator"></div>
  <ng-container *ngIf="isLieferant() ; else managerFilter">
    <div class="row filterPanel grid">
      <div class="col-1">Filter:</div>
      <div class="col-2">
        <p-checkbox name="selectedFilters" value="0" label="Alle" [(ngModel)]="selectedCategories"
          (onChange)="selectCategories('0')"></p-checkbox>
      </div>
      <div class="col-2">
        <p-checkbox name="selectedFilters" value="200" label="Neu" [(ngModel)]="selectedCategories"
          (onChange)="selectCategories('200')"></p-checkbox>
      </div>
      <div class="col-2">
        <p-checkbox name="selectedFilters" value="300" label="In Arbeit" [(ngModel)]="selectedCategories"
          (onChange)="selectCategories('300')"></p-checkbox>
      </div>
      <div class="col-fixed" style="width: 10%">
        <p-checkbox name="selectedFilters" value="450" label="Verschickt" [(ngModel)]="selectedCategories"
          (onChange)="selectCategories('450')"></p-checkbox>
      </div>
    </div>
  </ng-container>
  <ng-template #managerFilter>
    <div class="row filterPanel grid">
      <div class="col-1">Filter:</div>
      <div class="col-2">
        <p-checkbox name="selectedFilters" value="1" label="Alle" [(ngModel)]="selectedalleoderEigene"
          (onChange)="selectAlleoderEigene('1')"></p-checkbox>
      </div>
      <div class="col-2">
        <p-checkbox name="selectedFilterseigene" value="0" label="Eigene" [(ngModel)]="selectedalleoderEigene"
          (onChange)="selectAlleoderEigene('0')"></p-checkbox>
      </div>
      <div class="col-fixed" style="width: 10%">
        <p-checkbox name="deletedManager" value="250" label="Gelöschte" [(ngModel)]="selectedCategories"
          (onChange)="selectCategories('250')"></p-checkbox>
      </div>

    </div>
  </ng-template>
  <div class="contentSeperator"></div>
  <p-table [value]="articledata$ | async" [rows]="pageSorting.rowsToLoad" [paginator]="true" [pageLinks]="3"
    [lazy]="true" (onLazyLoad)="loadArticleData($event)" [totalRecords]="selectArticleHits$ | async"
    [first]="pageSorting.skipRows" [ngClass]="'p-datatable p-datatable-striped'"
     paginatorDropdownAppendTo="body"
    [rowsPerPageOptions]="[20,50,100]" [showCurrentPageReport]="true"
    [currentPageReportTemplate]="'{first} bis {last} von {totalRecords} Aufgaben'">

    <ng-template pTemplate="header">
      <tr>
        <th style="width: 5%"></th>
        <th style="width: 5%"></th>

        <th style="width: 5%"></th>
        <th style="width: 30%">Name</th>
        <th style="width: 10%"> {{isManager ? 'Erhalten' : 'Aktualisiert' }} </th>
        <th style="width: 25%">Listentyp</th>
        <th *ngIf="!isLieferant()" style="width: 15%">Lieferant</th>
        <th style="width: 15%">EmpfängerIn</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td [ngClass]="'kb-cell'" style="width: 5%; padding-right: 0.5rem;">
          <button pButton type="button" icon="pi pi-search" [class]="'p-button-info'"
            (click)='openArticledataInfo(rowData)' [pTooltip]="getInfotooltip(rowData)" tooltipPosition="top"
            showDelay="1000" hideDelay="1000"></button>
        </td>
        <td [ngClass]="'kb-cell'" style="width: 5%; padding-right: 0.5rem;">
          <button pButton *ngIf="rowData.selectedValidationId && rowData.taskState <= 300 && !rowData.isReadOnly"
            type="button" icon="pi pi-paperclip" [class]="'p-button-info'" (click)='openUpload(rowData)'
            [pTooltip]="getTooltipUploadEdit(rowData)" tooltipPosition="top" showDelay="1000" hideDelay="1000"></button>
          <button pButton
            *ngIf="rowData.selectedValidationId && (rowData.taskState > 300 || rowData.taskState == 250 ) "
            type="button" [icon]="getIconUpload(rowData)" [class]="getUploadClass(rowData)"
            (click)='openUpload(rowData)' [pTooltip]="getUploadtooltip(rowData)" tooltipPosition="top" showDelay="1000"
            hideDelay="1000"></button>
        </td>

        <td [ngClass]="'kb-cell'" style="width: 5%">

          <button pButton *ngIf="canDelete(rowData)" type="button" icon="pi pi-trash" [class]="'p-button-warning'"
            (click)="deleteArticleData(rowData.id)" pTooltip="Artikeldaten löschen" tooltipPosition="top"
            showDelay="1000" hideDelay="1000"></button>

          <button pButton *ngIf="canReaktivieren(rowData) " type="button" icon="pi pi-replay" styleClass="p-button-info"
            (click)="reactivateArticleData(rowData.id)" pTooltip="Artikeldaten reaktivieren" tooltipPosition="top"
            showDelay="1000" hideDelay="1000"></button>

        </td>

        <td [ngClass]="'kb-cell'" style="width: 30%"><span [style]="getNotificationStyle(rowData) | async"> {{
            rowData.name }}</span></td>

        <td [ngClass]="'kb-cell'" style="width: 10%">{{ (isManager ? rowData.articledatatransfered :
          rowData.articledatachange) | date }}</td>
        <td [ngClass]="'kb-cell'" style="width: 25%">{{ rowData.selectedValidation
          }}</td>
        <td *ngIf="!isLieferant()" [ngClass]="'kb-cell'" style="width: 15%"> {{ getLieferantName(rowData) | async }}
        </td>
        <td [ngClass]="'kb-cell'" style="width: 15%"> {{ getManagerName(rowData) | async }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>


<p-confirmDialog header="Bitte bestätigen:" icon="pi pi-exclamation-triangle" message="Keine Zeilen werden gelöscht."
  rejectLabel="Abbrechen"></p-confirmDialog>
