import { AvailableColumn, ValidationList, ValidationListColumn, ValidationResult, ResultObjectModel } from '@app/common/models';
import { ItemValueListTypeTable, ItemValueModel } from '@app/common/models/columnBase.model';
import { ColumnOrderModel } from '@app/common/models/columnOrder.model';
import { SimpleResult } from '@app/common/models/simpleResult.model';

export enum ValidationEditorActionType {
    GetAllAvailableColumns = '[VALIDATION-EDITOR] GetAllAvailableColumns',
    GetAllAvailableColumnsDone = '[VALIDATION-EDITOR] GetAllAvailableColumnsDone',
    GetAvailableColumnById = '[VALIDATION-EDITOR] GetAvailableColumnById',
    GetAllValidationColumns = '[VALIDATION-EDITOR] GetAllValidationColumns',
    GetAllValidationColumnsDone = '[VALIDATION-EDITOR] GetAllValidationColumnsDone',
    GetValidationColumnById = '[VALIDATION-EDITOR] GetValidationColumnById',
    GetValidationColumnsForListId = '[VALIDATION-EDITOR] GetValidationColumnsForListId',
    GetAllValidationLists = '[VALIDATION-EDITOR] GetAllValidationLists',
    GetAllValidationListsDone = '[VALIDATION-EDITOR] GetAllValidationListsDone',
    GetValidationListById = '[VALIDATION-EDITOR] GetValidationListById',
    CreateNewValidationColumn = '[VALIDATION-EDITOR] CreateNewValidationColumn',
    CreateValidationColFromAvailable = '[VALIDATIOM-EDITOR] CreateValidationColFromAvailable',
    CreateValidationColFromAvailableDone = '[VALIDATION-EDITOR] CreateValidationColFromAvailableDone',
    DeleteAvalaibleColumn = '[VALIDATION-EDITOR] DeleteAvailableColumn',
    DeleteAvailableColumnDone = '[VALIDATION-EDITOR] DeleteAvailableColumnDone',
    DeleteValidationList = '[VALIDATION-EDITOR] DeleteValidationList',
    DeleteValidationListDone = '[VALIDATION-EDITOR] DeleteValidationListDone',
    DeleteValidationListColumn = '[VALIDATION-EDITOR] DeleteValidationListColumn',
    DeleteValidationListColumnDone = '[VALIDATION-EDITOR] DeleteValidationListColumnDone',
    GetAllValidationListsWithColumn = '[VALIDATION-EDITOR] GetAllValidationListsWithColumn',
    GetValidationListWithColumnsById = '[VALIDATION-EDITOR] GetValidationListWithColumnsById',
    SaveAvailableColumn = '[VALIDATION-EDITOR] SaveAvailableColumn',
    SaveAvailableColumnDone = '[VALIDATION-EDITOR] SaveAvailableColumnDone',
    SaveValidationColumn = '[VALIDATION-EDITOR] SaveValidationColumn',
    SaveValidationColumnDone = '[VALIDATION-EDITOR] SaveValidationColumnDone',
    SaveValidationList = '[VALIDATION-EDITOR] SaveValidationList',
    SaveValidationListDone = '[VALIDATION-EDITOR] SaveValidationListDone',
    MoveAvailableToList = '[VALIDATION-EDITOR] MoveAvailableToList',
    MoveAvailableToListDone = '[VALIDATION-EDITOR] MoveAvailableToListDone',
    AddNewValidationList = '[VALIDATION-EDITOR] AddNewValidationList',
    AddNewValidationListDone = '[VALIDATION-EDITOR] AddNewValidationListDone',
    AddAvailableColumn = '[VALIDATION-EDITOR] AddAvailableColumn',
    AddAvailableColumnDone = '[VALIDATION-EDITOR] AddAvailableColumnDone',
    AddValidationColumn = '[VALIDATION-EDITOR] AddValidationColumn',
    AddValidationColumnDone = '[VALIDATION-EDITOR] AddValidationColumnDone',
    SaveValidationColumnsSortOrder = '[VALIDATION-EDITOR] SaveValidationColumnsSortOrder',
    SaveValidationColumnOrderDone = '[VALIDATION-EDITOR] SaveValidationColumnOrderDone',
    GetStaticListValuesAction = "GetStaticListValuesAction",
    GetStaticListValuesActionDone = "GetStaticListValuesActionDone",
    UpdateStaticListValuesAction = "UpdateStaticListValuesAction",
    UpdateStaticListValuesActionDone = "UpdateStaticListValuesActionDone",
    GetValidationColumnsForListIdAction = "GetValidationColumnsForListIdAction",
    GetValidationColumnsForListIdActionDone = "GetValidationColumnsForListIdActionDone"
}

export class GetAllAvailableColumnsAction {
    readonly type = ValidationEditorActionType.GetAllAvailableColumns;
    constructor() {}
}

export class GetAllAvailableColumnsDoneAction {
    readonly type = ValidationEditorActionType.GetAllAvailableColumnsDone;
    constructor(public payload: { items: AvailableColumn[] }) {}
}

export class GetAvailableColumnByIdAction {
    readonly type = ValidationEditorActionType.GetAvailableColumnById;
    constructor(public payload: { colId: number }) {}
}

//TODO: Entfernen, wird nicht mehr verwendet, siehe GetValidationColumnsForListIdAction in validationeditor.ts Komponente
export class GetAllValidationColumnsAction {
    readonly type = ValidationEditorActionType.GetAllValidationColumns;
    constructor() {}
}

export class GetAllValidationColumnsDoneAction {
    readonly type = ValidationEditorActionType.GetAllValidationColumnsDone;
    constructor(public payload: { items: ValidationListColumn[] }) {}
}



export class GetValidationColumnByIdAction {
    readonly type = ValidationEditorActionType.GetValidationColumnById;
    constructor(public payload: { colId: number }) {}
}

export class GetValidationColumnsForListIdAction {
    readonly type = ValidationEditorActionType.GetValidationColumnsForListIdAction;
    constructor(public payload: { listId: number }) {}
}

export class GetValidationColumnsForListIdActionDone {
  readonly type = ValidationEditorActionType.GetValidationColumnsForListIdActionDone;
  constructor(public payload: { items: ValidationListColumn[] }) {}
}


export class GetAllValidationListsAction {
    readonly type = ValidationEditorActionType.GetAllValidationLists;
    constructor() {}
}

export class GetAllValidationListsDoneAction {
    readonly type = ValidationEditorActionType.GetAllValidationListsDone;
    constructor(public payload: { items: ValidationList[]}) {}
}

//TODO: Entfernen wird nicht verwendet
export class GetValidationListByIdAction {
    readonly type = ValidationEditorActionType.GetValidationListById;
    constructor(public payload: { listId: number }) {}
}

export class CreateNewValidationColumnAction {
    readonly type = ValidationEditorActionType.CreateNewValidationColumn;
    constructor(public payload: { validationColumn: ValidationListColumn }) {}
}

export class CreateValidationColFromAvailableAction {
    readonly type = ValidationEditorActionType.CreateValidationColFromAvailable;
    constructor(public payload: { availableColumnId: number, validationListId: number}) {}
}

export class CreateValidationColFromAvailableDoneAction {
    readonly type = ValidationEditorActionType.CreateValidationColFromAvailableDone;
    constructor(public payload: { validationColumn: ValidationListColumn }) {}
}

export class DeleteAvailableColumnAction {
    readonly type = ValidationEditorActionType.DeleteAvalaibleColumn;
    constructor(public payload: { colId: number }) {}
}

export class DeleteAvailableColumnDoneAction {
    readonly type = ValidationEditorActionType.DeleteAvailableColumnDone;
    constructor(public payload: { colId: number }) {}
}

export class DeleteValidationListAction {
    readonly type = ValidationEditorActionType.DeleteValidationList;
    constructor(public payload: { listId: number }) {}
}

export class DeleteValidationListDoneAction {
    readonly type = ValidationEditorActionType.DeleteValidationListDone;
    constructor(public payload: { listId: number }) {}
}

export class DeleteValidationListColumnAction {
    readonly type = ValidationEditorActionType.DeleteValidationListColumn;
    constructor(public payload: { colId: number }) {}
}

export class DeleteValidationListColumnDoneAction {
    readonly type = ValidationEditorActionType.DeleteValidationListColumnDone;
    constructor(public payload: { columnId: number }) {}
}

export class GetAllValidationListsWithColumnAction {
    readonly type = ValidationEditorActionType.GetAllValidationListsWithColumn;
    constructor() {}
}

export class GetValidationListWithColumnsByIdAction {
    readonly type = ValidationEditorActionType.GetValidationListWithColumnsById;
    constructor() {}
}

export class SaveAvailableColumnAction {
    readonly type = ValidationEditorActionType.SaveAvailableColumn;
    constructor(public payload: { column: AvailableColumn }) {}
}

export class SaveAvailableColumnDoneAction {
    readonly type = ValidationEditorActionType.SaveAvailableColumnDone;
    constructor(public payload: { availableColumn: AvailableColumn }) {}
}

export class SaveValidationColumnAction {
    readonly type = ValidationEditorActionType.SaveValidationColumn;
    constructor(public payload: { column: ValidationListColumn}) {}
}

export class SaveValidationColumnDoneAction {
    readonly type = ValidationEditorActionType.SaveValidationColumnDone;
    constructor(public payload: { validationColumn: ValidationListColumn }) {}
}

export class SaveValidationListAction {
    readonly type = ValidationEditorActionType.SaveValidationList;
    constructor(public payload: { validationList: ValidationList }) {}
}

export class SaveValidationListDoneAction {
    readonly type = ValidationEditorActionType.SaveValidationListDone;
    constructor(public payload: { validationList: ValidationList }) {}
}

export class MoveAvailableToListAction {
    readonly type = ValidationEditorActionType.MoveAvailableToList;
    constructor(public payload: { listId: number, availableId: number }) {}
}

export class MoveAvailableToListDoneAction {
    readonly type = ValidationEditorActionType.MoveAvailableToListDone;
    constructor(public payload: { newValidationItem: ValidationListColumn }) {}
}

export class AddNewValidationListAction {
    readonly type = ValidationEditorActionType.AddNewValidationList;
    constructor(public payload: { newValidationList: ValidationList }) {}
}

export class AddNewValidationListDoneAction {
    readonly type = ValidationEditorActionType.AddNewValidationListDone;
    constructor(public payload: { newValidationList: ValidationList }) {}
}

export class AddAvailableColumnAction {
    readonly type = ValidationEditorActionType.AddAvailableColumn;
    constructor(public payload: { availableColumn: AvailableColumn }) {}
}

export class AddAvailableColumnDoneAction {
    readonly type = ValidationEditorActionType.AddAvailableColumnDone;
    constructor(public payload: { availableColumn: AvailableColumn }) {}
}

export class AddValidationColumnAction {
    readonly type = ValidationEditorActionType.AddValidationColumn;
    constructor(public payload: { validationColumn: ValidationListColumn }) {}
}

export class AddValidationColumnDoneAction {
    readonly type = ValidationEditorActionType.AddValidationColumnDone;
    constructor(public payload: { validationColumn: ValidationListColumn }) {}
}

export class SaveValidationColumnsSortOrderAction {
    readonly type = ValidationEditorActionType.SaveValidationColumnsSortOrder;
    constructor(public payload: { columnOrder: ColumnOrderModel[] }) {}
}

export class SaveValidationColumnOrderDoneAction {
    readonly type = ValidationEditorActionType.SaveValidationColumnOrderDone;
    constructor(public payload: { columnOrder: ColumnOrderModel[] }) {}
}


export class GetStaticListValuesAction {
  readonly type = ValidationEditorActionType.GetStaticListValuesAction;
  constructor(public payload: { columnlistid: number, listtabletype: ItemValueListTypeTable  }) {}
}

export class GetStaticListValuesActionDone {
  readonly type = ValidationEditorActionType.GetStaticListValuesActionDone;
  constructor(public payload: { usedcolumnlistid: number; items: ItemValueModel[] }) {}
}


export class UpdateStaticListValuesAction {
  readonly type = ValidationEditorActionType.UpdateStaticListValuesAction;
  constructor(public payload: { columnlistid: number, items: ItemValueModel[], listtabletype: ItemValueListTypeTable }) {}
}

export class UpdateStaticListValuesActionDone {
  readonly type = ValidationEditorActionType.UpdateStaticListValuesActionDone;
  constructor(public payload: { usedcolumnlistid: number; items: ItemValueModel[] }) {}
}


export type ValidationEditorActions = GetAllAvailableColumnsAction
                                    | GetAllAvailableColumnsDoneAction
                                    | GetAvailableColumnByIdAction
                                    | GetAllValidationColumnsAction
                                    | GetAllValidationColumnsDoneAction
                                    | GetValidationColumnByIdAction
                                    | GetValidationColumnsForListIdAction
                                    | GetAllValidationListsAction
                                    | GetAllValidationListsDoneAction
                                    | GetValidationListByIdAction
                                    | CreateNewValidationColumnAction
                                    | CreateValidationColFromAvailableAction
                                    | CreateValidationColFromAvailableDoneAction
                                    | DeleteAvailableColumnAction
                                    | DeleteAvailableColumnDoneAction
                                    | DeleteValidationListAction
                                    | DeleteValidationListDoneAction
                                    | DeleteValidationListColumnAction
                                    | DeleteValidationListColumnDoneAction
                                    | GetAllValidationListsWithColumnAction
                                    | GetValidationListWithColumnsByIdAction
                                    | SaveAvailableColumnAction
                                    | SaveAvailableColumnDoneAction
                                    | SaveValidationColumnAction
                                    | SaveValidationColumnDoneAction
                                    | SaveValidationListAction
                                    | SaveValidationListDoneAction
                                    | MoveAvailableToListAction
                                    | MoveAvailableToListDoneAction
                                    | AddNewValidationListAction
                                    | AddNewValidationListDoneAction
                                    | AddAvailableColumnAction
                                    | AddAvailableColumnDoneAction
                                    | AddValidationColumnAction
                                    | AddValidationColumnDoneAction
                                    | SaveValidationColumnsSortOrderAction
                                    | SaveValidationColumnOrderDoneAction
                                    | GetStaticListValuesAction
                                    | GetStaticListValuesActionDone
                                    | UpdateStaticListValuesAction
                                    | UpdateStaticListValuesActionDone
                                    | GetValidationColumnsForListIdActionDone
                                    ;
