import { Message as mess, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

export enum LipoMessageType {
  ColorChange = 1,

  SortimentsFilter = 3,
  VorabplatzierungsFilter = 4,
  SortimentstrukturCopy = 5,
  NoviEditControl = 6,

}


export class LipoMessageParameter<T>
{
    object: T;
    type: LipoMessageType;
}


/**Vermittelt z.b.  zwischen Fehlern und dem  MessageService in Dialogen
    toast auf die main App Componente umleiten wie in EIS
 */
@Injectable()
export class LipoMessageService<T> {

    public SubMessage: Subject<T> = new Subject<T>();

    onErrorSubscrition: any;
    constructor(private messageServie : MessageService)
    {

    }

    public Clear()
    {
        this.messageServie.clear();
    }

    public Publish(message: T): void {
        this.SubMessage.next(message);
    }

}
