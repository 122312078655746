/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfKlammerSortimentStichwort } from '../models/list-response-args-of-klammer-sortiment-stichwort';
import { ListResponseArgsOfSortimentKnoten } from '../models/list-response-args-of-sortiment-knoten';
import { SortimentsstrukturRequest } from '../models/sortimentsstruktur-request';
import { ResponseArgsOfLong } from '../models/response-args-of-long';
import { Knoten } from '../models/knoten';
import { ResponseArgsOfKnoten } from '../models/response-args-of-knoten';
import { Target } from '../models/target';
import { CopyArgs } from '../models/copy-args';
@Injectable({
  providedIn: 'root',
})
class SortimentsstrukturService extends __BaseService {
  static readonly SortimentsstrukturGetKlammerSortimentStichwortPath = '/klammersortimentstichwort';
  static readonly SortimentsstrukturGetKlammerSortimentStichwort2Path = '/klammersortimentstichwort/{klammerId}';
  static readonly SortimentsstrukturGetKlammerSortimentStichwort3Path = '/klammersortimentstichwort/{klammerId}/{sortimentId}';
  static readonly SortimentsstrukturGetKlammerSortimentStichwort4Path = '/klammersortimentstichwort/{klammerId}/{sortimentId}/{stichwortId}';
  static readonly SortimentsstrukturGetExpandedKlammerSortimentStichwortPath = '/klammersortimentstichwortbaum/{klammerId}/{sortimentId}/{stichwortId}';
  static readonly SortimentsstrukturGetExpandedKlammerSortimentStichwort2Path = '/klammersortimentstichwortbaum/{klammerId}/{sortimentId}';
  static readonly SortimentsstrukturGetExpandedKlammerSortimentStichwort3Path = '/klammersortimentstichwortbaum/{klammerId}';
  static readonly SortimentsstrukturGetExpandedKlammerSortimentStichwort4Path = '/klammersortimentstichwortbaum';
  static readonly SortimentsstrukturGetSortimentsstrukturPath = '/sortimentsstruktur';
  static readonly SortimentsstrukturCreateKnotenPath = '/sortimentsstruktur/{sortimentId}';
  static readonly SortimentsstrukturDeleteKnotenPath = '/sortimentsstruktur/{sortimentId}/{knotenId}';
  static readonly SortimentsstrukturUpdateKnotenPath = '/sortimentsstruktur/{sortimentId}/{knotenId}';
  static readonly SortimentsstrukturDeleteKnoten2Path = '/sortimentsstruktur/{sortimentId}/{knotenId}/{moduleNo}';
  static readonly SortimentsstrukturMoveKnotenPath = '/sortimentsstruktur/{sortimentId}/{sourceID}';
  static readonly SortimentsstrukturMoveKnoten2Path = '/sortimentsstruktur/{sortimentId}/{sourceID}/{sourceModulNo}';
  static readonly SortimentsstrukturCopyPath = '/sortimentsstruktur/{sourceSortimentId}/copy';
  static readonly SortimentsstrukturExportXlsxPath = '/sortimentsstruktur/xlsx';
  static readonly SortimentsstrukturExportKnotenXlsxPath = '/sortimentsstruktur/knoten/xlsx';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * GetKlammerSortimentStichwort
   */
  SortimentsstrukturGetKlammerSortimentStichwortResponse(): __Observable<__StrictHttpResponse<ListResponseArgsOfKlammerSortimentStichwort>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/klammersortimentstichwort`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfKlammerSortimentStichwort>;
      })
    );
  }
  /**
   * GetKlammerSortimentStichwort
   */
  SortimentsstrukturGetKlammerSortimentStichwort(): __Observable<ListResponseArgsOfKlammerSortimentStichwort> {
    return this.SortimentsstrukturGetKlammerSortimentStichwortResponse().pipe(
      __map(_r => _r.body as ListResponseArgsOfKlammerSortimentStichwort)
    );
  }

  /**
   * GetKlammerSortimentStichwort
   * @param klammerId undefined
   */
  SortimentsstrukturGetKlammerSortimentStichwort2Response(klammerId: number): __Observable<__StrictHttpResponse<ListResponseArgsOfKlammerSortimentStichwort>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/klammersortimentstichwort/${encodeURIComponent(String(klammerId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfKlammerSortimentStichwort>;
      })
    );
  }
  /**
   * GetKlammerSortimentStichwort
   * @param klammerId undefined
   */
  SortimentsstrukturGetKlammerSortimentStichwort2(klammerId: number): __Observable<ListResponseArgsOfKlammerSortimentStichwort> {
    return this.SortimentsstrukturGetKlammerSortimentStichwort2Response(klammerId).pipe(
      __map(_r => _r.body as ListResponseArgsOfKlammerSortimentStichwort)
    );
  }

  /**
   * GetKlammerSortimentStichwort
   * @param params The `SortimentsstrukturService.SortimentsstrukturGetKlammerSortimentStichwort3Params` containing the following parameters:
   *
   * - `sortimentId`:
   *
   * - `klammerId`:
   */
  SortimentsstrukturGetKlammerSortimentStichwort3Response(params: SortimentsstrukturService.SortimentsstrukturGetKlammerSortimentStichwort3Params): __Observable<__StrictHttpResponse<ListResponseArgsOfKlammerSortimentStichwort>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/klammersortimentstichwort/${encodeURIComponent(String(params.klammerId))}/${encodeURIComponent(String(params.sortimentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfKlammerSortimentStichwort>;
      })
    );
  }
  /**
   * GetKlammerSortimentStichwort
   * @param params The `SortimentsstrukturService.SortimentsstrukturGetKlammerSortimentStichwort3Params` containing the following parameters:
   *
   * - `sortimentId`:
   *
   * - `klammerId`:
   */
  SortimentsstrukturGetKlammerSortimentStichwort3(params: SortimentsstrukturService.SortimentsstrukturGetKlammerSortimentStichwort3Params): __Observable<ListResponseArgsOfKlammerSortimentStichwort> {
    return this.SortimentsstrukturGetKlammerSortimentStichwort3Response(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfKlammerSortimentStichwort)
    );
  }

  /**
   * GetKlammerSortimentStichwort
   * @param params The `SortimentsstrukturService.SortimentsstrukturGetKlammerSortimentStichwort4Params` containing the following parameters:
   *
   * - `stichwortId`:
   *
   * - `sortimentId`:
   *
   * - `klammerId`:
   */
  SortimentsstrukturGetKlammerSortimentStichwort4Response(params: SortimentsstrukturService.SortimentsstrukturGetKlammerSortimentStichwort4Params): __Observable<__StrictHttpResponse<ListResponseArgsOfKlammerSortimentStichwort>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/klammersortimentstichwort/${encodeURIComponent(String(params.klammerId))}/${encodeURIComponent(String(params.sortimentId))}/${encodeURIComponent(String(params.stichwortId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfKlammerSortimentStichwort>;
      })
    );
  }
  /**
   * GetKlammerSortimentStichwort
   * @param params The `SortimentsstrukturService.SortimentsstrukturGetKlammerSortimentStichwort4Params` containing the following parameters:
   *
   * - `stichwortId`:
   *
   * - `sortimentId`:
   *
   * - `klammerId`:
   */
  SortimentsstrukturGetKlammerSortimentStichwort4(params: SortimentsstrukturService.SortimentsstrukturGetKlammerSortimentStichwort4Params): __Observable<ListResponseArgsOfKlammerSortimentStichwort> {
    return this.SortimentsstrukturGetKlammerSortimentStichwort4Response(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfKlammerSortimentStichwort)
    );
  }

  /**
   * GetExpandedKlammerSortimentStichwort
   * @param params The `SortimentsstrukturService.SortimentsstrukturGetExpandedKlammerSortimentStichwortParams` containing the following parameters:
   *
   * - `stichwortId`:
   *
   * - `sortimentId`:
   *
   * - `klammerId`:
   */
  SortimentsstrukturGetExpandedKlammerSortimentStichwortResponse(params: SortimentsstrukturService.SortimentsstrukturGetExpandedKlammerSortimentStichwortParams): __Observable<__StrictHttpResponse<ListResponseArgsOfKlammerSortimentStichwort>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/klammersortimentstichwortbaum/${encodeURIComponent(String(params.klammerId))}/${encodeURIComponent(String(params.sortimentId))}/${encodeURIComponent(String(params.stichwortId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfKlammerSortimentStichwort>;
      })
    );
  }
  /**
   * GetExpandedKlammerSortimentStichwort
   * @param params The `SortimentsstrukturService.SortimentsstrukturGetExpandedKlammerSortimentStichwortParams` containing the following parameters:
   *
   * - `stichwortId`:
   *
   * - `sortimentId`:
   *
   * - `klammerId`:
   */
  SortimentsstrukturGetExpandedKlammerSortimentStichwort(params: SortimentsstrukturService.SortimentsstrukturGetExpandedKlammerSortimentStichwortParams): __Observable<ListResponseArgsOfKlammerSortimentStichwort> {
    return this.SortimentsstrukturGetExpandedKlammerSortimentStichwortResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfKlammerSortimentStichwort)
    );
  }

  /**
   * GetExpandedKlammerSortimentStichwort
   * @param params The `SortimentsstrukturService.SortimentsstrukturGetExpandedKlammerSortimentStichwort2Params` containing the following parameters:
   *
   * - `sortimentId`:
   *
   * - `klammerId`:
   */
  SortimentsstrukturGetExpandedKlammerSortimentStichwort2Response(params: SortimentsstrukturService.SortimentsstrukturGetExpandedKlammerSortimentStichwort2Params): __Observable<__StrictHttpResponse<ListResponseArgsOfKlammerSortimentStichwort>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/klammersortimentstichwortbaum/${encodeURIComponent(String(params.klammerId))}/${encodeURIComponent(String(params.sortimentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfKlammerSortimentStichwort>;
      })
    );
  }
  /**
   * GetExpandedKlammerSortimentStichwort
   * @param params The `SortimentsstrukturService.SortimentsstrukturGetExpandedKlammerSortimentStichwort2Params` containing the following parameters:
   *
   * - `sortimentId`:
   *
   * - `klammerId`:
   */
  SortimentsstrukturGetExpandedKlammerSortimentStichwort2(params: SortimentsstrukturService.SortimentsstrukturGetExpandedKlammerSortimentStichwort2Params): __Observable<ListResponseArgsOfKlammerSortimentStichwort> {
    return this.SortimentsstrukturGetExpandedKlammerSortimentStichwort2Response(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfKlammerSortimentStichwort)
    );
  }

  /**
   * GetExpandedKlammerSortimentStichwort
   * @param klammerId undefined
   */
  SortimentsstrukturGetExpandedKlammerSortimentStichwort3Response(klammerId: number): __Observable<__StrictHttpResponse<ListResponseArgsOfKlammerSortimentStichwort>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/klammersortimentstichwortbaum/${encodeURIComponent(String(klammerId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfKlammerSortimentStichwort>;
      })
    );
  }
  /**
   * GetExpandedKlammerSortimentStichwort
   * @param klammerId undefined
   */
  SortimentsstrukturGetExpandedKlammerSortimentStichwort3(klammerId: number): __Observable<ListResponseArgsOfKlammerSortimentStichwort> {
    return this.SortimentsstrukturGetExpandedKlammerSortimentStichwort3Response(klammerId).pipe(
      __map(_r => _r.body as ListResponseArgsOfKlammerSortimentStichwort)
    );
  }

  /**
   * GetExpandedKlammerSortimentStichwort
   */
  SortimentsstrukturGetExpandedKlammerSortimentStichwort4Response(): __Observable<__StrictHttpResponse<ListResponseArgsOfKlammerSortimentStichwort>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/klammersortimentstichwortbaum`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfKlammerSortimentStichwort>;
      })
    );
  }
  /**
   * GetExpandedKlammerSortimentStichwort
   */
  SortimentsstrukturGetExpandedKlammerSortimentStichwort4(): __Observable<ListResponseArgsOfKlammerSortimentStichwort> {
    return this.SortimentsstrukturGetExpandedKlammerSortimentStichwort4Response().pipe(
      __map(_r => _r.body as ListResponseArgsOfKlammerSortimentStichwort)
    );
  }

  /**
   * GetSortimentsstruktur
   * @param payload undefined
   */
  SortimentsstrukturGetSortimentsstrukturResponse(payload: SortimentsstrukturRequest): __Observable<__StrictHttpResponse<ListResponseArgsOfSortimentKnoten>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sortimentsstruktur`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfSortimentKnoten>;
      })
    );
  }
  /**
   * GetSortimentsstruktur
   * @param payload undefined
   */
  SortimentsstrukturGetSortimentsstruktur(payload: SortimentsstrukturRequest): __Observable<ListResponseArgsOfSortimentKnoten> {
    return this.SortimentsstrukturGetSortimentsstrukturResponse(payload).pipe(
      __map(_r => _r.body as ListResponseArgsOfSortimentKnoten)
    );
  }

  /**
   * Kntoten erzeugen
   * @param params The `SortimentsstrukturService.SortimentsstrukturCreateKnotenParams` containing the following parameters:
   *
   * - `sortimentId`:
   *
   * - `payload`:
   *
   * - `user`:
   */
  SortimentsstrukturCreateKnotenResponse(params: SortimentsstrukturService.SortimentsstrukturCreateKnotenParams): __Observable<__StrictHttpResponse<ResponseArgsOfLong>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sortimentsstruktur/${encodeURIComponent(String(params.sortimentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfLong>;
      })
    );
  }
  /**
   * Kntoten erzeugen
   * @param params The `SortimentsstrukturService.SortimentsstrukturCreateKnotenParams` containing the following parameters:
   *
   * - `sortimentId`:
   *
   * - `payload`:
   *
   * - `user`:
   */
  SortimentsstrukturCreateKnoten(params: SortimentsstrukturService.SortimentsstrukturCreateKnotenParams): __Observable<ResponseArgsOfLong> {
    return this.SortimentsstrukturCreateKnotenResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfLong)
    );
  }

  /**
   * Knoten löschen
   * @param params The `SortimentsstrukturService.SortimentsstrukturDeleteKnotenParams` containing the following parameters:
   *
   * - `sortimentId`:
   *
   * - `knotenId`:
   *
   * - `user`:
   */
  SortimentsstrukturDeleteKnotenResponse(params: SortimentsstrukturService.SortimentsstrukturDeleteKnotenParams): __Observable<__StrictHttpResponse<ResponseArgsOfLong>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/sortimentsstruktur/${encodeURIComponent(String(params.sortimentId))}/${encodeURIComponent(String(params.knotenId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfLong>;
      })
    );
  }
  /**
   * Knoten löschen
   * @param params The `SortimentsstrukturService.SortimentsstrukturDeleteKnotenParams` containing the following parameters:
   *
   * - `sortimentId`:
   *
   * - `knotenId`:
   *
   * - `user`:
   */
  SortimentsstrukturDeleteKnoten(params: SortimentsstrukturService.SortimentsstrukturDeleteKnotenParams): __Observable<ResponseArgsOfLong> {
    return this.SortimentsstrukturDeleteKnotenResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfLong)
    );
  }

  /**
   * Knoten löschen
   * @param params The `SortimentsstrukturService.SortimentsstrukturUpdateKnotenParams` containing the following parameters:
   *
   * - `sortimentId`:
   *
   * - `payload`:
   *
   * - `knotenId`:
   *
   * - `user`:
   */
  SortimentsstrukturUpdateKnotenResponse(params: SortimentsstrukturService.SortimentsstrukturUpdateKnotenParams): __Observable<__StrictHttpResponse<ResponseArgsOfKnoten>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/sortimentsstruktur/${encodeURIComponent(String(params.sortimentId))}/${encodeURIComponent(String(params.knotenId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfKnoten>;
      })
    );
  }
  /**
   * Knoten löschen
   * @param params The `SortimentsstrukturService.SortimentsstrukturUpdateKnotenParams` containing the following parameters:
   *
   * - `sortimentId`:
   *
   * - `payload`:
   *
   * - `knotenId`:
   *
   * - `user`:
   */
  SortimentsstrukturUpdateKnoten(params: SortimentsstrukturService.SortimentsstrukturUpdateKnotenParams): __Observable<ResponseArgsOfKnoten> {
    return this.SortimentsstrukturUpdateKnotenResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfKnoten)
    );
  }

  /**
   * Knoten löschen
   * @param params The `SortimentsstrukturService.SortimentsstrukturDeleteKnoten2Params` containing the following parameters:
   *
   * - `sortimentId`:
   *
   * - `moduleNo`:
   *
   * - `knotenId`:
   *
   * - `user`:
   */
  SortimentsstrukturDeleteKnoten2Response(params: SortimentsstrukturService.SortimentsstrukturDeleteKnoten2Params): __Observable<__StrictHttpResponse<ResponseArgsOfLong>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/sortimentsstruktur/${encodeURIComponent(String(params.sortimentId))}/${encodeURIComponent(String(params.knotenId))}/${encodeURIComponent(String(params.moduleNo))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfLong>;
      })
    );
  }
  /**
   * Knoten löschen
   * @param params The `SortimentsstrukturService.SortimentsstrukturDeleteKnoten2Params` containing the following parameters:
   *
   * - `sortimentId`:
   *
   * - `moduleNo`:
   *
   * - `knotenId`:
   *
   * - `user`:
   */
  SortimentsstrukturDeleteKnoten2(params: SortimentsstrukturService.SortimentsstrukturDeleteKnoten2Params): __Observable<ResponseArgsOfLong> {
    return this.SortimentsstrukturDeleteKnoten2Response(params).pipe(
      __map(_r => _r.body as ResponseArgsOfLong)
    );
  }

  /**
   * Knoten verschieben
   * @param params The `SortimentsstrukturService.SortimentsstrukturMoveKnotenParams` containing the following parameters:
   *
   * - `sourceID`:
   *
   * - `sortimentId`:
   *
   * - `user`:
   *
   * - `target`:
   */
  SortimentsstrukturMoveKnotenResponse(params: SortimentsstrukturService.SortimentsstrukturMoveKnotenParams): __Observable<__StrictHttpResponse<ResponseArgsOfKnoten>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.user != null) __params = __params.set('user', params.user.toString());
    __body = params.target;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sortimentsstruktur/${encodeURIComponent(String(params.sortimentId))}/${encodeURIComponent(String(params.sourceID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfKnoten>;
      })
    );
  }
  /**
   * Knoten verschieben
   * @param params The `SortimentsstrukturService.SortimentsstrukturMoveKnotenParams` containing the following parameters:
   *
   * - `sourceID`:
   *
   * - `sortimentId`:
   *
   * - `user`:
   *
   * - `target`:
   */
  SortimentsstrukturMoveKnoten(params: SortimentsstrukturService.SortimentsstrukturMoveKnotenParams): __Observable<ResponseArgsOfKnoten> {
    return this.SortimentsstrukturMoveKnotenResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfKnoten)
    );
  }

  /**
   * Knoten verschieben
   * @param params The `SortimentsstrukturService.SortimentsstrukturMoveKnoten2Params` containing the following parameters:
   *
   * - `sourceModulNo`:
   *
   * - `sourceID`:
   *
   * - `sortimentId`:
   *
   * - `user`:
   *
   * - `target`:
   */
  SortimentsstrukturMoveKnoten2Response(params: SortimentsstrukturService.SortimentsstrukturMoveKnoten2Params): __Observable<__StrictHttpResponse<ResponseArgsOfKnoten>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.user != null) __params = __params.set('user', params.user.toString());
    __body = params.target;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sortimentsstruktur/${encodeURIComponent(String(params.sortimentId))}/${encodeURIComponent(String(params.sourceID))}/${encodeURIComponent(String(params.sourceModulNo))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfKnoten>;
      })
    );
  }
  /**
   * Knoten verschieben
   * @param params The `SortimentsstrukturService.SortimentsstrukturMoveKnoten2Params` containing the following parameters:
   *
   * - `sourceModulNo`:
   *
   * - `sourceID`:
   *
   * - `sortimentId`:
   *
   * - `user`:
   *
   * - `target`:
   */
  SortimentsstrukturMoveKnoten2(params: SortimentsstrukturService.SortimentsstrukturMoveKnoten2Params): __Observable<ResponseArgsOfKnoten> {
    return this.SortimentsstrukturMoveKnoten2Response(params).pipe(
      __map(_r => _r.body as ResponseArgsOfKnoten)
    );
  }

  /**
   * Sortimentsstruktur eines Sortimentes in ein anderes kopieren
   * @param params The `SortimentsstrukturService.SortimentsstrukturCopyParams` containing the following parameters:
   *
   * - `sourceSortimentId`:
   *
   * - `copyArgs`:
   */
  SortimentsstrukturCopyResponse(params: SortimentsstrukturService.SortimentsstrukturCopyParams): __Observable<__StrictHttpResponse<ResponseArgsOfLong>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.copyArgs;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sortimentsstruktur/${encodeURIComponent(String(params.sourceSortimentId))}/copy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfLong>;
      })
    );
  }
  /**
   * Sortimentsstruktur eines Sortimentes in ein anderes kopieren
   * @param params The `SortimentsstrukturService.SortimentsstrukturCopyParams` containing the following parameters:
   *
   * - `sourceSortimentId`:
   *
   * - `copyArgs`:
   */
  SortimentsstrukturCopy(params: SortimentsstrukturService.SortimentsstrukturCopyParams): __Observable<ResponseArgsOfLong> {
    return this.SortimentsstrukturCopyResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfLong)
    );
  }

  /**
   * Export Sortimentsstruktur - Ohne Module, Robs (XLSX)
   * @param payload undefined
   */
  SortimentsstrukturExportXlsxResponse(payload: SortimentsstrukturRequest): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sortimentsstruktur/xlsx`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Export Sortimentsstruktur - Ohne Module, Robs (XLSX)
   * @param payload undefined
   */
  SortimentsstrukturExportXlsx(payload: SortimentsstrukturRequest): __Observable<Blob> {
    return this.SortimentsstrukturExportXlsxResponse(payload).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Export Sortimentsstruktur - Ohne Module, Robs (XLSX)
   * @param params The `SortimentsstrukturService.SortimentsstrukturExportKnotenXlsxParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `onlyIsVisibleToSuppliers`:
   */
  SortimentsstrukturExportKnotenXlsxResponse(params: SortimentsstrukturService.SortimentsstrukturExportKnotenXlsxParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.onlyIsVisibleToSuppliers != null) __params = __params.set('onlyIsVisibleToSuppliers', params.onlyIsVisibleToSuppliers.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sortimentsstruktur/knoten/xlsx`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Export Sortimentsstruktur - Ohne Module, Robs (XLSX)
   * @param params The `SortimentsstrukturService.SortimentsstrukturExportKnotenXlsxParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `onlyIsVisibleToSuppliers`:
   */
  SortimentsstrukturExportKnotenXlsx(params: SortimentsstrukturService.SortimentsstrukturExportKnotenXlsxParams): __Observable<Blob> {
    return this.SortimentsstrukturExportKnotenXlsxResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }
}

module SortimentsstrukturService {

  /**
   * Parameters for SortimentsstrukturGetKlammerSortimentStichwort3
   */
  export interface SortimentsstrukturGetKlammerSortimentStichwort3Params {
    sortimentId: number;
    klammerId: number;
  }

  /**
   * Parameters for SortimentsstrukturGetKlammerSortimentStichwort4
   */
  export interface SortimentsstrukturGetKlammerSortimentStichwort4Params {
    stichwortId: number;
    sortimentId: number;
    klammerId: number;
  }

  /**
   * Parameters for SortimentsstrukturGetExpandedKlammerSortimentStichwort
   */
  export interface SortimentsstrukturGetExpandedKlammerSortimentStichwortParams {
    stichwortId: number;
    sortimentId: number;
    klammerId: number;
  }

  /**
   * Parameters for SortimentsstrukturGetExpandedKlammerSortimentStichwort2
   */
  export interface SortimentsstrukturGetExpandedKlammerSortimentStichwort2Params {
    sortimentId: number;
    klammerId: number;
  }

  /**
   * Parameters for SortimentsstrukturCreateKnoten
   */
  export interface SortimentsstrukturCreateKnotenParams {
    sortimentId: number;
    payload: Knoten;
    user?: null | string;
  }

  /**
   * Parameters for SortimentsstrukturDeleteKnoten
   */
  export interface SortimentsstrukturDeleteKnotenParams {
    sortimentId: number;
    knotenId: number;
    user?: null | string;
  }

  /**
   * Parameters for SortimentsstrukturUpdateKnoten
   */
  export interface SortimentsstrukturUpdateKnotenParams {
    sortimentId: number;
    payload: Knoten;
    knotenId: number;
    user?: null | string;
  }

  /**
   * Parameters for SortimentsstrukturDeleteKnoten2
   */
  export interface SortimentsstrukturDeleteKnoten2Params {
    sortimentId: number;
    moduleNo: number;
    knotenId: number;
    user?: null | string;
  }

  /**
   * Parameters for SortimentsstrukturMoveKnoten
   */
  export interface SortimentsstrukturMoveKnotenParams {
    sourceID: number;
    sortimentId: number;
    user?: null | string;
    target?: Target;
  }

  /**
   * Parameters for SortimentsstrukturMoveKnoten2
   */
  export interface SortimentsstrukturMoveKnoten2Params {
    sourceModulNo: number;
    sourceID: number;
    sortimentId: number;
    user?: null | string;
    target?: Target;
  }

  /**
   * Parameters for SortimentsstrukturCopy
   */
  export interface SortimentsstrukturCopyParams {
    sourceSortimentId: number;
    copyArgs: CopyArgs;
  }

  /**
   * Parameters for SortimentsstrukturExportKnotenXlsx
   */
  export interface SortimentsstrukturExportKnotenXlsxParams {
    payload: SortimentsstrukturRequest;
    onlyIsVisibleToSuppliers?: boolean;
  }
}

export { SortimentsstrukturService }
