import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService } from '@app/common/services/auth.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { StatistikService } from '@app/common-data-module/services/StatistikService';

export interface IDashBoardInfo
{
  text: string;
  islieferant: boolean;
}
@Component({
    selector: 'app-dashboard',
    templateUrl: 'dashboard.component.html',
    styleUrls:  ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {


  ismanager: boolean = false;
  selecthasSOMADiagramm$: Observable<boolean>;

    constructor(private store: Store<any>,
      private statistikService : StatistikService,
      private authService: AuthService) {

        this.authService.getLipoUser().subscribe(user=> this.ismanager = user.ismanager);
        this.selecthasSOMADiagramm$ =  this.statistikService.selecthasSOMADiagramm$;
    }

    /**Vorläufigen Text anzeigen */
    getDashBoardInfos() : Observable<IDashBoardInfo[]>
    {
      return this.authService.getLipoUser().pipe(map(user=>
        {
            if (user.isadmin == true || user.ismanager == true)
            {
                return [<IDashBoardInfo> { islieferant: false, text: 'Cockpit Lieferantenportal' }];
            } else
            {
              return [<IDashBoardInfo> { islieferant: true, text:  'Herzlich Willkommen im Lieferantenportal<br>der Buchhandlung Hugendubel!'}        ];
            }
        }))
    }

    ngOnInit() {

      this.statistikService.loadDashboardStatistik();

    }

}
