import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ListEditorComponent } from './list-editor.component';
import { CommonModule } from '@angular/common';

import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule} from 'primeng/dropdown';
import { TooltipModule} from 'primeng/tooltip';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import {SpinnerModule} from 'primeng/spinner';
import {CalendarModule} from 'primeng/calendar';
import { NgStringPipesModule } from 'ngx-pipes';
import {SplitButtonModule} from 'primeng/splitbutton';
import {InputMaskModule} from 'primeng/inputmask';
import { MessageService } from 'primeng/api';
import { CommonCheckService } from '@app/common/services/common.validation.service';

import { TransferDialogComponent } from '../components/transfer-dialog/transfer-dialog.component';
import { CommonDataModule } from '@app/common-data-module/common-data-module.module';
import { MoebelService } from '@app/common-data-module/services/MoebelService';

@NgModule({
    imports: [CommonModule,  TableModule, FormsModule, ButtonModule, InputTextModule, TooltipModule,
        CalendarModule, CommonDataModule,
        CheckboxModule, FileUploadModule, ToastModule, NgStringPipesModule, ToolbarModule, ToggleButtonModule, ConfirmDialogModule, SplitButtonModule,
        DropdownModule, SpinnerModule, CalendarModule, InputMaskModule, InputNumberModule ],
    exports: [ListEditorComponent],
    declarations: [ListEditorComponent, TransferDialogComponent],
    providers: [MessageService, CommonCheckService, MoebelService],
})
export class ListEditorModule { }
