/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfBranchDTO } from '../models/list-response-args-of-branch-dto';
import { QueryTokenDTO } from '../models/query-token-dto';
import { ListResponseArgsOfBranchGroupDTO } from '../models/list-response-args-of-branch-group-dto';
import { ResponseArgsOfBranchGroupDTO } from '../models/response-args-of-branch-group-dto';
import { BranchGroupDTO } from '../models/branch-group-dto';
import { BranchDTO } from '../models/branch-dto';
import { ResponseArgsOfBranchCommentDTO } from '../models/response-args-of-branch-comment-dto';
import { ListResponseArgsOfBranchCommentDTO } from '../models/list-response-args-of-branch-comment-dto';
import { BranchCommentDTO } from '../models/branch-comment-dto';
@Injectable({
  providedIn: 'root',
})
class BranchService extends __BaseService {
  static readonly BranchQueryBranchPath = '/branch/s';
  static readonly BranchQueryBranchGroupPath = '/branch/group/s';
  static readonly BranchGetGroupPath = '/branch/group/{groupId}';
  static readonly BranchUpdateGroupPath = '/branch/group/{groupId}';
  static readonly BranchDeleteGroupPath = '/branch/group/{groupId}';
  static readonly BranchCreateGroupPath = '/branch/group';
  static readonly BranchAddBranchesToGroupPath = '/branch/group/{groupId}/add';
  static readonly BranchRemoveBranchesFromGroupPath = '/branch/group/{groupId}/remove';
  static readonly BranchGetCurrentCommentPath = '/branch/{branchId}/comment';
  static readonly BranchGetCommentPath = '/branch/comment/{commentId}';
  static readonly BranchGetCurrentCommentsPath = '/branch/comment';
  static readonly BranchGetCommentHistoryPath = '/branch/{branchId}/comment/history';
  static readonly BranchCreateBranchCommentPath = '/branch/{branchId}/comment/add';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Filialen
   * @param params The `BranchService.BranchQueryBranchParams` containing the following parameters:
   *
   * - `queryToken`: Suchparameter
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  BranchQueryBranchResponse(params: BranchService.BranchQueryBranchParams): __Observable<__StrictHttpResponse<ListResponseArgsOfBranchDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/branch/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfBranchDTO>;
      })
    );
  }
  /**
   * Filialen
   * @param params The `BranchService.BranchQueryBranchParams` containing the following parameters:
   *
   * - `queryToken`: Suchparameter
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  BranchQueryBranch(params: BranchService.BranchQueryBranchParams): __Observable<ListResponseArgsOfBranchDTO> {
    return this.BranchQueryBranchResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfBranchDTO)
    );
  }

  /**
   * Filialgruppen
   * @param params The `BranchService.BranchQueryBranchGroupParams` containing the following parameters:
   *
   * - `queryToken`: Suchparameter
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  BranchQueryBranchGroupResponse(params: BranchService.BranchQueryBranchGroupParams): __Observable<__StrictHttpResponse<ListResponseArgsOfBranchGroupDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/branch/group/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfBranchGroupDTO>;
      })
    );
  }
  /**
   * Filialgruppen
   * @param params The `BranchService.BranchQueryBranchGroupParams` containing the following parameters:
   *
   * - `queryToken`: Suchparameter
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  BranchQueryBranchGroup(params: BranchService.BranchQueryBranchGroupParams): __Observable<ListResponseArgsOfBranchGroupDTO> {
    return this.BranchQueryBranchGroupResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfBranchGroupDTO)
    );
  }

  /**
   * Filialgruppe
   * @param params The `BranchService.BranchGetGroupParams` containing the following parameters:
   *
   * - `groupId`: Gruppe PK
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  BranchGetGroupResponse(params: BranchService.BranchGetGroupParams): __Observable<__StrictHttpResponse<ResponseArgsOfBranchGroupDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/branch/group/${encodeURIComponent(String(params.groupId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBranchGroupDTO>;
      })
    );
  }
  /**
   * Filialgruppe
   * @param params The `BranchService.BranchGetGroupParams` containing the following parameters:
   *
   * - `groupId`: Gruppe PK
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  BranchGetGroup(params: BranchService.BranchGetGroupParams): __Observable<ResponseArgsOfBranchGroupDTO> {
    return this.BranchGetGroupResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfBranchGroupDTO)
    );
  }

  /**
   * Filialgruppe aktualiseren
   * @param params The `BranchService.BranchUpdateGroupParams` containing the following parameters:
   *
   * - `payload`: Filialgruppendaten
   *
   * - `groupId`: FilialGruppe PK
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  BranchUpdateGroupResponse(params: BranchService.BranchUpdateGroupParams): __Observable<__StrictHttpResponse<ResponseArgsOfBranchGroupDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/branch/group/${encodeURIComponent(String(params.groupId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBranchGroupDTO>;
      })
    );
  }
  /**
   * Filialgruppe aktualiseren
   * @param params The `BranchService.BranchUpdateGroupParams` containing the following parameters:
   *
   * - `payload`: Filialgruppendaten
   *
   * - `groupId`: FilialGruppe PK
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  BranchUpdateGroup(params: BranchService.BranchUpdateGroupParams): __Observable<ResponseArgsOfBranchGroupDTO> {
    return this.BranchUpdateGroupResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfBranchGroupDTO)
    );
  }

  /**
   * Filialgruppe löschen
   * @param params The `BranchService.BranchDeleteGroupParams` containing the following parameters:
   *
   * - `groupId`: FilialGruppe PK
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  BranchDeleteGroupResponse(params: BranchService.BranchDeleteGroupParams): __Observable<__StrictHttpResponse<ResponseArgsOfBranchGroupDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/branch/group/${encodeURIComponent(String(params.groupId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBranchGroupDTO>;
      })
    );
  }
  /**
   * Filialgruppe löschen
   * @param params The `BranchService.BranchDeleteGroupParams` containing the following parameters:
   *
   * - `groupId`: FilialGruppe PK
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  BranchDeleteGroup(params: BranchService.BranchDeleteGroupParams): __Observable<ResponseArgsOfBranchGroupDTO> {
    return this.BranchDeleteGroupResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfBranchGroupDTO)
    );
  }

  /**
   * Benutzer anlegen
   * @param params The `BranchService.BranchCreateGroupParams` containing the following parameters:
   *
   * - `payload`: Benutzerdaten
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  BranchCreateGroupResponse(params: BranchService.BranchCreateGroupParams): __Observable<__StrictHttpResponse<ResponseArgsOfBranchGroupDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/branch/group`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBranchGroupDTO>;
      })
    );
  }
  /**
   * Benutzer anlegen
   * @param params The `BranchService.BranchCreateGroupParams` containing the following parameters:
   *
   * - `payload`: Benutzerdaten
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  BranchCreateGroup(params: BranchService.BranchCreateGroupParams): __Observable<ResponseArgsOfBranchGroupDTO> {
    return this.BranchCreateGroupResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfBranchGroupDTO)
    );
  }

  /**
   * Filialen zu Filialgruppe hinzufügen
   * @param params The `BranchService.BranchAddBranchesToGroupParams` containing the following parameters:
   *
   * - `payload`: Filialen
   *
   * - `groupId`: FilialGruppe PK
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  BranchAddBranchesToGroupResponse(params: BranchService.BranchAddBranchesToGroupParams): __Observable<__StrictHttpResponse<ResponseArgsOfBranchGroupDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/branch/group/${encodeURIComponent(String(params.groupId))}/add`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBranchGroupDTO>;
      })
    );
  }
  /**
   * Filialen zu Filialgruppe hinzufügen
   * @param params The `BranchService.BranchAddBranchesToGroupParams` containing the following parameters:
   *
   * - `payload`: Filialen
   *
   * - `groupId`: FilialGruppe PK
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  BranchAddBranchesToGroup(params: BranchService.BranchAddBranchesToGroupParams): __Observable<ResponseArgsOfBranchGroupDTO> {
    return this.BranchAddBranchesToGroupResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfBranchGroupDTO)
    );
  }

  /**
   * Filialen aus Filialgruppe entfernen
   * @param params The `BranchService.BranchRemoveBranchesFromGroupParams` containing the following parameters:
   *
   * - `payload`: Filialen
   *
   * - `groupId`: FilialGruppe PK
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  BranchRemoveBranchesFromGroupResponse(params: BranchService.BranchRemoveBranchesFromGroupParams): __Observable<__StrictHttpResponse<ResponseArgsOfBranchGroupDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/branch/group/${encodeURIComponent(String(params.groupId))}/remove`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBranchGroupDTO>;
      })
    );
  }
  /**
   * Filialen aus Filialgruppe entfernen
   * @param params The `BranchService.BranchRemoveBranchesFromGroupParams` containing the following parameters:
   *
   * - `payload`: Filialen
   *
   * - `groupId`: FilialGruppe PK
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  BranchRemoveBranchesFromGroup(params: BranchService.BranchRemoveBranchesFromGroupParams): __Observable<ResponseArgsOfBranchGroupDTO> {
    return this.BranchRemoveBranchesFromGroupResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfBranchGroupDTO)
    );
  }

  /**
   * Aktueller Filialkommentar
   * @param params The `BranchService.BranchGetCurrentCommentParams` containing the following parameters:
   *
   * - `branchId`: Filiale PK
   *
   * - `type`: Typ
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  BranchGetCurrentCommentResponse(params: BranchService.BranchGetCurrentCommentParams): __Observable<__StrictHttpResponse<ResponseArgsOfBranchCommentDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/branch/${encodeURIComponent(String(params.branchId))}/comment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBranchCommentDTO>;
      })
    );
  }
  /**
   * Aktueller Filialkommentar
   * @param params The `BranchService.BranchGetCurrentCommentParams` containing the following parameters:
   *
   * - `branchId`: Filiale PK
   *
   * - `type`: Typ
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  BranchGetCurrentComment(params: BranchService.BranchGetCurrentCommentParams): __Observable<ResponseArgsOfBranchCommentDTO> {
    return this.BranchGetCurrentCommentResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfBranchCommentDTO)
    );
  }

  /**
   * Filialkommentar
   * @param params The `BranchService.BranchGetCommentParams` containing the following parameters:
   *
   * - `commentId`: Kommentar PK
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  BranchGetCommentResponse(params: BranchService.BranchGetCommentParams): __Observable<__StrictHttpResponse<ResponseArgsOfBranchCommentDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/branch/comment/${encodeURIComponent(String(params.commentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBranchCommentDTO>;
      })
    );
  }
  /**
   * Filialkommentar
   * @param params The `BranchService.BranchGetCommentParams` containing the following parameters:
   *
   * - `commentId`: Kommentar PK
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  BranchGetComment(params: BranchService.BranchGetCommentParams): __Observable<ResponseArgsOfBranchCommentDTO> {
    return this.BranchGetCommentResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfBranchCommentDTO)
    );
  }

  /**
   * Aktueller Kommentare
   * @param params The `BranchService.BranchGetCurrentCommentsParams` containing the following parameters:
   *
   * - `branchIds`: Filialen PKs
   *
   * - `type`: Typ
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  BranchGetCurrentCommentsResponse(params: BranchService.BranchGetCurrentCommentsParams): __Observable<__StrictHttpResponse<ListResponseArgsOfBranchCommentDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.branchIds;
    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/branch/comment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfBranchCommentDTO>;
      })
    );
  }
  /**
   * Aktueller Kommentare
   * @param params The `BranchService.BranchGetCurrentCommentsParams` containing the following parameters:
   *
   * - `branchIds`: Filialen PKs
   *
   * - `type`: Typ
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  BranchGetCurrentComments(params: BranchService.BranchGetCurrentCommentsParams): __Observable<ListResponseArgsOfBranchCommentDTO> {
    return this.BranchGetCurrentCommentsResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfBranchCommentDTO)
    );
  }

  /**
   * Filialkommentar Historie
   * @param params The `BranchService.BranchGetCommentHistoryParams` containing the following parameters:
   *
   * - `branchId`: Filiale PK
   *
   * - `type`: Typ
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  BranchGetCommentHistoryResponse(params: BranchService.BranchGetCommentHistoryParams): __Observable<__StrictHttpResponse<ListResponseArgsOfBranchCommentDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/branch/${encodeURIComponent(String(params.branchId))}/comment/history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfBranchCommentDTO>;
      })
    );
  }
  /**
   * Filialkommentar Historie
   * @param params The `BranchService.BranchGetCommentHistoryParams` containing the following parameters:
   *
   * - `branchId`: Filiale PK
   *
   * - `type`: Typ
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  BranchGetCommentHistory(params: BranchService.BranchGetCommentHistoryParams): __Observable<ListResponseArgsOfBranchCommentDTO> {
    return this.BranchGetCommentHistoryResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfBranchCommentDTO)
    );
  }

  /**
   * Filialkommentar speichern
   * @param params The `BranchService.BranchCreateBranchCommentParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `branchId`:
   *
   * - `locale`:
   *
   * - `eagerLoading`:
   */
  BranchCreateBranchCommentResponse(params: BranchService.BranchCreateBranchCommentParams): __Observable<__StrictHttpResponse<ResponseArgsOfBranchCommentDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/branch/${encodeURIComponent(String(params.branchId))}/comment/add`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBranchCommentDTO>;
      })
    );
  }
  /**
   * Filialkommentar speichern
   * @param params The `BranchService.BranchCreateBranchCommentParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `branchId`:
   *
   * - `locale`:
   *
   * - `eagerLoading`:
   */
  BranchCreateBranchComment(params: BranchService.BranchCreateBranchCommentParams): __Observable<ResponseArgsOfBranchCommentDTO> {
    return this.BranchCreateBranchCommentResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfBranchCommentDTO)
    );
  }
}

module BranchService {

  /**
   * Parameters for BranchQueryBranch
   */
  export interface BranchQueryBranchParams {

    /**
     * Suchparameter
     */
    queryToken: QueryTokenDTO;

    /**
     * Lokalisierung
     */
    locale?: null | string;

    /**
     * Objekt-Tiefe (optional, default: 1)
     */
    eagerLoading?: number;
  }

  /**
   * Parameters for BranchQueryBranchGroup
   */
  export interface BranchQueryBranchGroupParams {

    /**
     * Suchparameter
     */
    queryToken: QueryTokenDTO;

    /**
     * Lokalisierung
     */
    locale?: null | string;

    /**
     * Objekt-Tiefe (optional, default: 1)
     */
    eagerLoading?: number;
  }

  /**
   * Parameters for BranchGetGroup
   */
  export interface BranchGetGroupParams {

    /**
     * Gruppe PK
     */
    groupId: number;

    /**
     * Lokalisierung
     */
    locale?: null | string;

    /**
     * Objekt-Tiefe (optional, default: 1)
     */
    eagerLoading?: number;
  }

  /**
   * Parameters for BranchUpdateGroup
   */
  export interface BranchUpdateGroupParams {

    /**
     * Filialgruppendaten
     */
    payload: BranchGroupDTO;

    /**
     * FilialGruppe PK
     */
    groupId: number;

    /**
     * Lokalisierung
     */
    locale?: null | string;

    /**
     * Objekt-Tiefe (optional, default: 1)
     */
    eagerLoading?: number;
  }

  /**
   * Parameters for BranchDeleteGroup
   */
  export interface BranchDeleteGroupParams {

    /**
     * FilialGruppe PK
     */
    groupId: number;

    /**
     * Lokalisierung
     */
    locale?: null | string;

    /**
     * Objekt-Tiefe (optional, default: 1)
     */
    eagerLoading?: number;
  }

  /**
   * Parameters for BranchCreateGroup
   */
  export interface BranchCreateGroupParams {

    /**
     * Benutzerdaten
     */
    payload: BranchGroupDTO;

    /**
     * Lokalisierung
     */
    locale?: null | string;

    /**
     * Objekt-Tiefe (optional, default: 1)
     */
    eagerLoading?: number;
  }

  /**
   * Parameters for BranchAddBranchesToGroup
   */
  export interface BranchAddBranchesToGroupParams {

    /**
     * Filialen
     */
    payload: Array<BranchDTO>;

    /**
     * FilialGruppe PK
     */
    groupId: number;

    /**
     * Lokalisierung
     */
    locale?: null | string;

    /**
     * Objekt-Tiefe (optional, default: 1)
     */
    eagerLoading?: number;
  }

  /**
   * Parameters for BranchRemoveBranchesFromGroup
   */
  export interface BranchRemoveBranchesFromGroupParams {

    /**
     * Filialen
     */
    payload: Array<BranchDTO>;

    /**
     * FilialGruppe PK
     */
    groupId: number;

    /**
     * Lokalisierung
     */
    locale?: null | string;

    /**
     * Objekt-Tiefe (optional, default: 1)
     */
    eagerLoading?: number;
  }

  /**
   * Parameters for BranchGetCurrentComment
   */
  export interface BranchGetCurrentCommentParams {

    /**
     * Filiale PK
     */
    branchId: number;

    /**
     * Typ
     */
    type?: null | string;

    /**
     * Lokalisierung
     */
    locale?: null | string;

    /**
     * Objekt-Tiefe (optional, default: 1)
     */
    eagerLoading?: number;
  }

  /**
   * Parameters for BranchGetComment
   */
  export interface BranchGetCommentParams {

    /**
     * Kommentar PK
     */
    commentId: number;

    /**
     * Lokalisierung
     */
    locale?: null | string;

    /**
     * Objekt-Tiefe (optional, default: 1)
     */
    eagerLoading?: number;
  }

  /**
   * Parameters for BranchGetCurrentComments
   */
  export interface BranchGetCurrentCommentsParams {

    /**
     * Filialen PKs
     */
    branchIds: Array<number>;

    /**
     * Typ
     */
    type?: null | string;

    /**
     * Lokalisierung
     */
    locale?: null | string;

    /**
     * Objekt-Tiefe (optional, default: 1)
     */
    eagerLoading?: number;
  }

  /**
   * Parameters for BranchGetCommentHistory
   */
  export interface BranchGetCommentHistoryParams {

    /**
     * Filiale PK
     */
    branchId: number;

    /**
     * Typ
     */
    type?: null | string;

    /**
     * Lokalisierung
     */
    locale?: null | string;

    /**
     * Objekt-Tiefe (optional, default: 1)
     */
    eagerLoading?: number;
  }

  /**
   * Parameters for BranchCreateBranchComment
   */
  export interface BranchCreateBranchCommentParams {
    payload: BranchCommentDTO;
    branchId: number;
    locale?: null | string;
    eagerLoading?: number;
  }
}

export { BranchService }
