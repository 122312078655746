import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ResultObjectModel, TaskUserModel } from '../models';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LiPoContact } from '../models/lipocontact.model';
import { AuthService } from './auth.service';

@Injectable()
export class AritcleDataService {



  deleteArticleData(id: number, ismanager: boolean ) : Observable<ResultObjectModel<number>> {
    const url = `${environment.connections.TaskListService}/api/articledata/deleteArticleData`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authServcie.getAccessToken()
      }
      ), params: { id: id.toString(), isManager: ismanager.toString()  }
    };
    return  this.httpClient.get<ResultObjectModel<number>>(url, httpOptions);
  }


  constructor(private httpClient: HttpClient, private authServcie: AuthService) { }


  getSomaContacts() : Observable<ResultObjectModel<LiPoContact[]>>
  {
    const url = `${environment.connections.TaskListService}/api/articledata/getManagers`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authServcie.getAccessToken()
      }
      )
    };
    return  this.httpClient.get<ResultObjectModel<LiPoContact[]>>(url, httpOptions);
  }


  getSupplierContacts() : Observable<ResultObjectModel<LiPoContact[]>>
  {
    const url = `${environment.connections.TaskListService}/api/articledata/getSupplierContacts`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authServcie.getAccessToken()
      }
      )
    };
    return  this.httpClient.get<ResultObjectModel<LiPoContact[]>>(url, httpOptions);
  }

  GetArticleData(filterOptions: string, orderOption: string, somaAlle: boolean, skip: number ,take: number): Observable<ResultObjectModel<TaskUserModel[]>> {
    const url = `${environment.connections.TaskListService}/api/articledata/GetArticleData`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authServcie.getAccessToken()
      }
      ), params: { filterOptions: filterOptions, orderOption : orderOption, somaAlle: somaAlle.toString(), skip: skip.toString(), take: take.toString() }
    };
    return  this.httpClient.get<ResultObjectModel<TaskUserModel[]>>(url, httpOptions);
  }


  insertArticleData(item: TaskUserModel): Observable<ResultObjectModel<TaskUserModel>> {
    const url = `${environment.connections.TaskListService}/api/articledata/insertArticleData`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authServcie.getAccessToken()
      }
      )
    };
    return  this.httpClient.post<ResultObjectModel<TaskUserModel>>(url, item, httpOptions);
  }

  updateArticleData(item: TaskUserModel): Observable<ResultObjectModel<TaskUserModel>> {
    const url = `${environment.connections.TaskListService}/api/articledata/updateArticleData`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authServcie.getAccessToken()
      }
      )
    };
    return  this.httpClient.post<ResultObjectModel<TaskUserModel>>(url, item, httpOptions);
  }


}
