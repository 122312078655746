import { selectedCalendarState } from './state';
import { createSelector } from '@ngrx/store';
import { CalendarDataModel, CalendarExternalDataModel } from '@app/common/models/CalendarData.model';
import { SortimentDtoClient } from '@app/common/models/sortimente/SortimentDtoClient';

export const selectAllAttributes = createSelector(selectedCalendarState, s => s.calendarData);
export const selectAllEvents = createSelector(selectedCalendarState, s => s.calendarData);
export const selectFilterStructure = createSelector(selectedCalendarState, s => s.structureData);



export const selectSingleTaskHeader = createSelector(selectedCalendarState, s => s.singleTask.data );
export const selectSingleTaskTasks = createSelector(selectedCalendarState, s => s.singleTask.children);
export const selectSingleTask = createSelector(selectedCalendarState, s => s.singleTask);
export const selectWithSortimentFilter = ( selectedSortiments: SortimentDtoClient[]) => createSelector(selectedCalendarState, x => {
    let newEvents: CalendarDataModel[] = [];
    x.calendarData.forEach((value, index) => {
        if (value.extendedProps.calendarEventType == 0) {
            const extProps = value.extendedProps as CalendarExternalDataModel;
            const eventIdx = selectedSortiments.findIndex(x => x.assortmentId == extProps.sortimentId);
            if (eventIdx > -1) newEvents.push(value);
        }
    });
    return newEvents;
});
export const selectWithKlammerFilter = (id: string) => createSelector(selectedCalendarState, x => {
    let newEvents: CalendarDataModel[] = [];
    x.calendarData.forEach((value, index) => {
        if (value.extendedProps.calendarEventType == 0) {
            const extProps = value.extendedProps as CalendarExternalDataModel;
            if ( extProps.sortimentsKlammerId != undefined && extProps.sortimentsKlammerId == id) {
                newEvents.push(value);
            }
        }
    });
    return newEvents;
})

export const selectuserscalendarSortimente = createSelector(selectedCalendarState, s => s.usersCalendarSortimente);
