import { Component, OnInit } from '@angular/core';
import { AuthService } from './common/services/auth.service';
import { SignalRService } from './common/services/signalr.service';
import { MessageService, Message } from 'primeng/api';
import { LipoMessageService } from './common/services/LipoMessageService';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import timelinePlugin from '@fullcalendar/timeline';
import listPlugin from '@fullcalendar/list';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService, LipoMessageService]

})
export class AppComponent implements OnInit {


  calendarOptions: CalendarOptions = {
    plugins: [
      dayGridPlugin,
      timeGridPlugin,
      interactionPlugin,
      resourceTimelinePlugin,
      timelinePlugin,
      listPlugin
    ],
     initialView: 'dayGridMonth',
   }


  title = 'LiPoFrontend';
  /**CSS Klassen an die komplette App über geben
   * body damit versorgen
   */
  mainCSSClasses = "size_1_rem_light";
  isLoggedIn$ = this.authService.isAuthenticated$;
  signalservice: SignalRService;

  changeCSSClasses($event: string) : void
  {
    this.mainCSSClasses = $event;
  }

  get claims() {
    return this.authService.claims;
  }

  constructor( private authService: AuthService,
    private signlarService : SignalRService,
    private messageService : MessageService,
    private lipomessageService : LipoMessageService<Message>,
    private primeConfig: PrimeNGConfig,
    private translateService: TranslateService

    ) {

  }


  ngOnInit() {

    this.lipomessageService.SubMessage.pipe(filter(f=> f.severity != undefined && f.summary != undefined)).subscribe(message =>
      {
          this.messageService.add(message);
      });
      this.primeConfig.setTranslation({
            dayNames: [ 'Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag' ],
            dayNamesShort: [ 'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa' ],
            dayNamesMin: [ 'S', 'M', 'D', 'M', 'D', 'F', 'S' ],
            monthNames: [ 'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember' ],
            monthNamesShort: [ 'Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez' ],
            today: 'Heute',
            clear: 'Löschen',
            noFilter: 'Filter entfernen'
      });
      this.translateService.setDefaultLang('de');
      this.signlarService.init(this.authService);
   }

   translate(lang: string) {
     this.translateService.use(lang);
     this.translateService.get('primeng').subscribe(res => this.primeConfig.setTranslation(res));
   }

}
