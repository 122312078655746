/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfMandantDto } from '../models/list-response-args-of-mandant-dto';
import { ExtendedQueryTokenDTO } from '../models/extended-query-token-dto';
import { ResponseArgsOfMandantDto } from '../models/response-args-of-mandant-dto';
import { MandantDto } from '../models/mandant-dto';
import { ResponseArgsOfBoolean } from '../models/response-args-of-boolean';
@Injectable({
  providedIn: 'root',
})
class MandantService extends __BaseService {
  static readonly MandantQueryPath = '/mandant/s';
  static readonly MandantCreatePath = '/mandant';
  static readonly MandantUpdatePath = '/mandant/{id}';
  static readonly MandantDeletePath = '/mandant/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Query
   * @param params The `MandantService.MandantQueryParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `eagerLoading`:
   */
  MandantQueryResponse(params: MandantService.MandantQueryParams): __Observable<__StrictHttpResponse<ListResponseArgsOfMandantDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mandant/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfMandantDto>;
      })
    );
  }
  /**
   * Query
   * @param params The `MandantService.MandantQueryParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `eagerLoading`:
   */
  MandantQuery(params: MandantService.MandantQueryParams): __Observable<ListResponseArgsOfMandantDto> {
    return this.MandantQueryResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfMandantDto)
    );
  }

  /**
   * Create
   * @param payload MandantDto
   */
  MandantCreateResponse(payload: MandantDto): __Observable<__StrictHttpResponse<ResponseArgsOfMandantDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mandant`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfMandantDto>;
      })
    );
  }
  /**
   * Create
   * @param payload MandantDto
   */
  MandantCreate(payload: MandantDto): __Observable<ResponseArgsOfMandantDto> {
    return this.MandantCreateResponse(payload).pipe(
      __map(_r => _r.body as ResponseArgsOfMandantDto)
    );
  }

  /**
   * Update
   * @param params The `MandantService.MandantUpdateParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `id`:
   */
  MandantUpdateResponse(params: MandantService.MandantUpdateParams): __Observable<__StrictHttpResponse<ResponseArgsOfMandantDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/mandant/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfMandantDto>;
      })
    );
  }
  /**
   * Update
   * @param params The `MandantService.MandantUpdateParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `id`:
   */
  MandantUpdate(params: MandantService.MandantUpdateParams): __Observable<ResponseArgsOfMandantDto> {
    return this.MandantUpdateResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfMandantDto)
    );
  }

  /**
   * Delete
   * @param id Id der Entity
   */
  MandantDeleteResponse(id: number): __Observable<__StrictHttpResponse<ResponseArgsOfBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/mandant/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBoolean>;
      })
    );
  }
  /**
   * Delete
   * @param id Id der Entity
   */
  MandantDelete(id: number): __Observable<ResponseArgsOfBoolean> {
    return this.MandantDeleteResponse(id).pipe(
      __map(_r => _r.body as ResponseArgsOfBoolean)
    );
  }
}

module MandantService {

  /**
   * Parameters for MandantQuery
   */
  export interface MandantQueryParams {
    queryToken: ExtendedQueryTokenDTO;
    eagerLoading?: number;
  }

  /**
   * Parameters for MandantUpdate
   */
  export interface MandantUpdateParams {
    payload: MandantDto;
    id: number;
  }
}

export { MandantService }
