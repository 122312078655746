import { NgModule } from '@angular/core';



import { TaskEditorModule } from './+task-editor/task-editor.module';
import { ValidationEditorModule } from './+validationEditor/validation-editor.module';

@NgModule({
    imports: [  TaskEditorModule, ValidationEditorModule],
    exports: [ TaskEditorModule, ValidationEditorModule],
})
export class AppDialogsModule { }
