import { Component, OnInit } from '@angular/core';
import { AufgabenArt, TaskUserModel } from '@app/common/models';
import { Store, select } from '@ngrx/store';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ConfirmationService } from 'primeng/api';
import { GetBreadcrumbAction, selectBreadcrumb } from '@app/+task-editor/store';
import { Color } from '@swimlane/ngx-charts';

@Component({
  selector: 'app-soma-task-view-dialog',
  templateUrl: './soma-task-view-dialog.component.html',
  styleUrls: ['./soma-task-view-dialog.component.scss']
})
export class SomaTaskViewDialogComponent implements OnInit {

  taskToView: number;
  headerTask: TaskUserModel;
  childrenTasks: TaskUserModel[];
  breadcrumb$: any;

  view: [number,number] = [800, 200];

  // options
  showLegend: boolean = true;
  showLabels: boolean = true;
  label: string = 'Gesamt';
  chartValues: { name: string, value: number }[];

  colorScheme = <Color>  {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#aae3f5']
  };

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public store: Store<any>,
    private confirmationService: ConfirmationService) {

  }


  /**Bezeichnung des Kunden z.b. Lieferant */
  getCustomName() {
    if (this.headerTask  == undefined ) return "";
    if (this.headerTask.aufgabenArt == AufgabenArt.WeitereBedarfe) {
      return "Mandant";
    }
    return "Lieferant";
  }

  ngOnInit() {
    this.headerTask = this.config.data.header as TaskUserModel;
    this.childrenTasks = this.config.data.children as TaskUserModel[];
    this.getChartResults();
    // this.store.dispatch(new GetBreadcrumbAction( { id: this.headerTask.id } ));
    // this.breadcrumb$ = this.store.select(selectBreadcrumb);
  }

  getChildrenCount() {
    return this.childrenTasks.length;
  }

  getChartResults() {
    const created = this.childrenTasks.filter(x => x.taskState == 100).length as number;
    const newState = this.childrenTasks.filter(x => x.taskState == 200).length as number;
    const onGoing = this.childrenTasks.filter(x => x.taskState == 300).length as number;
    const doneByClient = this.childrenTasks.filter(x => x.taskState == 400).length as number;
    const transfered = this.childrenTasks.filter(x => x.taskState == 500 || x.taskState == 550 /**ohne input */).length as number;
    const createdObj = { name: 'Erstellt', value: created };
    const newObj = { name: 'Freigegeben', value: newState };
    const onGoingObj = { name: 'In Arbeit', value: onGoing };
    // const doneObj = { name: 'Fertiggestellt', value: doneByClient };
    const transObj = { name: 'Übermittelt', value: transfered };
    this.chartValues = [createdObj, newObj, onGoingObj, transObj];
  }

  getViewResults(): { name: string, value: number }[] {
    return this.chartValues;
  }

  getTaskStatus(rowData: TaskUserModel): string {
    switch (rowData.taskState) {
      case 100:
        return 'Erstellt';
      case 200:
        return 'Freigegeben';
      case 300:
        return 'In Arbeit';
      // case 400:
      //     return 'Fertiggestellt';
      case 500:
        return 'Übermittelt';
      case 550:
        return 'Ohne Input';
    }
  }

  onSelect($event) {

  }

}
