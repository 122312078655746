/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfKeyValueDto } from '../models/list-response-args-of-key-value-dto';
import { ResponseArgsOfKeyValueDto } from '../models/response-args-of-key-value-dto';
import { KeyValueDto } from '../models/key-value-dto';
@Injectable({
  providedIn: 'root',
})
class KeyValueService extends __BaseService {
  static readonly KeyValueGetKeyValuesPath = '/keyvalue/{locale}/{target}';
  static readonly KeyValueCreateKeyValuePath = '/keyvalue/{locale}/{target}';
  static readonly KeyValueUpdateKeyValuesPath = '/keyvalue/{locale}/{target}';
  static readonly KeyValueDeleteKeyValuePath = '/keyvalue/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Query
   * @param params The `KeyValueService.KeyValueGetKeyValuesParams` containing the following parameters:
   *
   * - `target`:
   *
   * - `locale`:
   */
  KeyValueGetKeyValuesResponse(params: KeyValueService.KeyValueGetKeyValuesParams): __Observable<__StrictHttpResponse<ListResponseArgsOfKeyValueDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/keyvalue/${encodeURIComponent(String(params.locale))}/${encodeURIComponent(String(params.target))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfKeyValueDto>;
      })
    );
  }
  /**
   * Query
   * @param params The `KeyValueService.KeyValueGetKeyValuesParams` containing the following parameters:
   *
   * - `target`:
   *
   * - `locale`:
   */
  KeyValueGetKeyValues(params: KeyValueService.KeyValueGetKeyValuesParams): __Observable<ListResponseArgsOfKeyValueDto> {
    return this.KeyValueGetKeyValuesResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfKeyValueDto)
    );
  }

  /**
   * Neuer KeyValue
   * @param params The `KeyValueService.KeyValueCreateKeyValueParams` containing the following parameters:
   *
   * - `target`:
   *
   * - `payload`:
   *
   * - `locale`:
   */
  KeyValueCreateKeyValueResponse(params: KeyValueService.KeyValueCreateKeyValueParams): __Observable<__StrictHttpResponse<ResponseArgsOfKeyValueDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/keyvalue/${encodeURIComponent(String(params.locale))}/${encodeURIComponent(String(params.target))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfKeyValueDto>;
      })
    );
  }
  /**
   * Neuer KeyValue
   * @param params The `KeyValueService.KeyValueCreateKeyValueParams` containing the following parameters:
   *
   * - `target`:
   *
   * - `payload`:
   *
   * - `locale`:
   */
  KeyValueCreateKeyValue(params: KeyValueService.KeyValueCreateKeyValueParams): __Observable<ResponseArgsOfKeyValueDto> {
    return this.KeyValueCreateKeyValueResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfKeyValueDto)
    );
  }

  /**
   * Update KeyValue
   * @param params The `KeyValueService.KeyValueUpdateKeyValuesParams` containing the following parameters:
   *
   * - `target`:
   *
   * - `payload`:
   *
   * - `locale`:
   */
  KeyValueUpdateKeyValuesResponse(params: KeyValueService.KeyValueUpdateKeyValuesParams): __Observable<__StrictHttpResponse<ListResponseArgsOfKeyValueDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/keyvalue/${encodeURIComponent(String(params.locale))}/${encodeURIComponent(String(params.target))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfKeyValueDto>;
      })
    );
  }
  /**
   * Update KeyValue
   * @param params The `KeyValueService.KeyValueUpdateKeyValuesParams` containing the following parameters:
   *
   * - `target`:
   *
   * - `payload`:
   *
   * - `locale`:
   */
  KeyValueUpdateKeyValues(params: KeyValueService.KeyValueUpdateKeyValuesParams): __Observable<ListResponseArgsOfKeyValueDto> {
    return this.KeyValueUpdateKeyValuesResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfKeyValueDto)
    );
  }

  /**
   * KeyValue Löschen
   * @param id undefined
   */
  KeyValueDeleteKeyValueResponse(id: number): __Observable<__StrictHttpResponse<ResponseArgsOfKeyValueDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/keyvalue/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfKeyValueDto>;
      })
    );
  }
  /**
   * KeyValue Löschen
   * @param id undefined
   */
  KeyValueDeleteKeyValue(id: number): __Observable<ResponseArgsOfKeyValueDto> {
    return this.KeyValueDeleteKeyValueResponse(id).pipe(
      __map(_r => _r.body as ResponseArgsOfKeyValueDto)
    );
  }
}

module KeyValueService {

  /**
   * Parameters for KeyValueGetKeyValues
   */
  export interface KeyValueGetKeyValuesParams {
    target: string;
    locale: string;
  }

  /**
   * Parameters for KeyValueCreateKeyValue
   */
  export interface KeyValueCreateKeyValueParams {
    target: string;
    payload: KeyValueDto;
    locale: string;
  }

  /**
   * Parameters for KeyValueUpdateKeyValues
   */
  export interface KeyValueUpdateKeyValuesParams {
    target: string;
    payload: Array<KeyValueDto>;
    locale: string;
  }
}

export { KeyValueService }
