import { ListEditorState, InitialListEditorState } from './state';
import {
  ListEditorActions, ListEditorActionType, GetRowListDoneAction, AddEmptyContentRowAction, UpdateRowStoreAction,
  InsertRowDoneAction, SaveUploadListItemDoneAction,
  GetUploadFieldsDoneAction, CloseEditorAction, DeleteRowsAction, BubImportSelectedRowsDoneAction, CreateUploadActionDone, GetValidationsDoneAction, GetListWithIdAction, CreateUploadAction,
  UpdateUploadMultiRowsDoneAction
} from './action';
import { RowContentModel, FieldModel, UploadStagingModel, GeneralResultEnum, UploadUserModel } from '@app/common/models';
import { ColumnValue } from '@app/common/models/columnValue.model';
import * as moment from 'moment';
import * as cloneDeep from 'lodash/fp/cloneDeep';


export function reducer(state: ListEditorState = InitialListEditorState, action: ListEditorActions):
  ListEditorState {
  switch (action.type) {

    case ListEditorActionType.GetValidationsDone:
      return ValidationsLoaded(state, action);

    case ListEditorActionType.CreateUploadAction:
      return CreateUploadReducer(state, action);
    case ListEditorActionType.CreateUploadActionDone:
      return CreateUploadDoneReducer(state, action);

    case ListEditorActionType.GetListWithId:
      return GetRowListsReducer(state, action);

    case ListEditorActionType.GetRowListDone:
      return GetRowListsDoneReducer(state, action);
    case ListEditorActionType.AddEmptyContentRow:
      return AddEmptyContentRowReducer(state, action);
    case ListEditorActionType.UpdateRowStore:
      return UpdateRowStoreReducer(state, action);
    case ListEditorActionType.InsertRowDone:
      return InsertRowDoneReducer(state, action);

    case ListEditorActionType.GetUploadFieldsDone:
      return GetUploadFieldsDoneReducer(state, action);
    case ListEditorActionType.CloseEditor:
      return CloseEditorReducer(state, action);
    case ListEditorActionType.DeleteRows:
      return DeleteRowsReducer(state, action);
    case ListEditorActionType.SaveUploadListItemDone:
      return SaveUploadListItemReducer(state, action);
    case ListEditorActionType.UpdateUploadMultiRowsDoneAction:
      return SaveUploadMultiRowReducer(state, action);
    case ListEditorActionType.BubImportSelectedRowsDone:
      return BubImportSelectedRowsReducer(state, action);
    case ListEditorActionType.GetUsedValidationListByIdDone:
      var obj = { ...state.validationsbyUsedColumnId };
      obj[action.payload.id] = action.payload.validations;
      return {
        ...state,
        validationsbyUsedColumnId: obj
      }

    case ListEditorActionType.BubImportSelectedRows:
      return {
        ...state,
        errorResult: undefined,
        isLoading: true
      }
    case ListEditorActionType.ClearUploadErrorAction:
      return {
        ...state,
        errorResult: undefined,
        isLoading: false
      }

  }
  return state;
}


export function ValidationsLoaded(state: ListEditorState, action: GetValidationsDoneAction): ListEditorState {

  return {
    ...state,
    validationProfiles: action.payload.validations
  }
}



export function GetRowListsReducer(state: ListEditorState = InitialListEditorState, action: GetListWithIdAction) {
  return {
    ...state,
    errorResult: null,
    isLoading: true
  }
}

export function GetRowListsDoneReducer(state: ListEditorState = InitialListEditorState, action: GetRowListDoneAction):
  ListEditorState {
  const rowContentList: RowContentModel[] = [];
  const uploadStaging = {} as UploadStagingModel;
  if (action.payload.result.generalResult != GeneralResultEnum.Success) {
    return {
      ...state,
      errorResult: action.payload.result,
      isLoading: false
    }
  }
  if (action.payload.result.data.stagingDetails != undefined && action.payload.result.data.stagingDetails != null && action.payload.result.data.stagingDetails.length > 0) {
    for (let item of action.payload.result.data.stagingDetails) {
      const itemId = JSON.parse(item.id.toString()) as number;

      let colValues = item.columnValues
      const itemFieldModel: FieldModel[] = [];
      const rtype = item.rowType;
      const valid = true;
      const info = 'test';
      colValues = cloneDeep(colValues);
      for (let column of colValues) {
        if (column.columncelltype == 6) {
          if (column.value && column.value.length > 0) {
            if (column.value.length == 10) {
              const newDate = moment(column.value, 'DD.MM.YYYY');
              column.datevalue = newDate.toDate();
            } else if (column.value.length == 19) {
              const newDate = moment(column.value, 'DD.MM.YYYY HH:mm:ss');
              column.datevalue = newDate.toDate();
            } else if (column.value.length == 8) {
              const newDate = moment(column.value, 'DD.MM.YY');
              column.datevalue = newDate.toDate();
            } else if (column.value) {
              column.datevalue = new Date(column.value);
            }
          }
        }
        if (column.columncelltype == 2) {
          column.booleanValue = column.value ? column.value === '1' : false;
        }
      }
      rowContentList.push({
        id: itemId, fieldModel: itemFieldModel, columnValues: colValues, selected: false, isValid: item.isValid,
        validationInfo: item.validationInfo, rowtype: rtype
      });
    }
  }
  uploadStaging.changeDate = action.payload.result.data.changeDate;
  uploadStaging.createDate = action.payload.result.data.createDate;
  uploadStaging.id = action.payload.result.data.id;
  uploadStaging.uploadComment = action.payload.result.data.uploadComment;
  uploadStaging.uploadingUser = action.payload.result.data.uploadingUser;
  uploadStaging.maximum = action.payload.result.data.maximum;
  uploadStaging.minimum = action.payload.result.data.minimum;
  uploadStaging.isEditExpired = action.payload.result.data.isEditExpired;
  uploadStaging.isEditExpiredMessage = action.payload.result.data.isEditExpiredMessage;
  uploadStaging.isReadOnly = action.payload.result.data.isReadOnly;
  uploadStaging.isReadOnlyMessage = action.payload.result.data.isReadOnlyMessage;
  uploadStaging.attributeValidation = action.payload.result.data.attributeValidation;
  uploadStaging.attributeText = action.payload.result.data.attributeText;
  uploadStaging.usedValidationListName = action.payload.result.data.usedValidationListName;
  uploadStaging.uploadState = action.payload.result.data.uploadState;
  return {
    ...state,
    rowList: action.payload.result.data.stagingDetails,
    rowContentList,
    uploadStaging,
    isLoading: false
  };
}

export function AddEmptyContentRowReducer(state: ListEditorState = InitialListEditorState, action: AddEmptyContentRowAction):
  ListEditorState {
  let newColumnValues: ColumnValue[] = [];
  const headerRow = state.rowContentList.filter(x => x.rowtype == 'header')[0];


  for (const item of headerRow.columnValues) {

    newColumnValues = [...newColumnValues, {
      usedcolumnid: item.usedcolumnid, columncelltype: item.columncelltype, columndesc: item.columndesc, columnname: item.columnname, value: null, items: item.items, ismandatory: item.ismandatory, datevalue: null,
      booleanValue: null, options: item.options
    }];

  }

  const newId = (((new Date().getTime() * 10000) + 621355968000000000) + (action.payload.currentValues.length + 1)) * -1;
  return {
    ...state,
    isLoading: false,
    rowContentList: [{
      id: newId, columnValues: newColumnValues, fieldModel: null, selected: false,
      isValid: false, validationInfo: 'Neue Zeile - bitte bearbeiten um eine Prüfung der Daten durchzuführen', rowtype: 'content'
    }, ...action.payload.currentValues]
  }
}

export function UpdateRowStoreReducer(state: ListEditorState = InitialListEditorState, action: UpdateRowStoreAction): ListEditorState {

  const newColumnValues: ColumnValue[] = [];
  for (const key in action.payload.cols) {
    if (action.payload.cols.hasOwnProperty(key)) {
      const colValue = new ColumnValue();
      colValue.usedcolumnid = action.payload.cols[key].usedcolumnid;
      colValue.value = action.payload.cols[key].value;
      colValue.columnname = action.payload.cols[key].columnname;
      colValue.columncelltype = action.payload.cols[key].columncelltype;
      colValue.columndesc = action.payload.cols[key].columndesc;
      colValue.items = action.payload.cols[key].items;
      colValue.options = action.payload.cols[key].options;
      colValue.ismandatory = action.payload.cols[key].ismandatory;

      if (colValue.columncelltype == 6) {
        colValue.datevalue = action.payload.cols[key].datevalue;
        if (!colValue.datevalue && colValue.value && colValue.value.length > 0) {
          if (colValue.value.length == 10) {
            const newDate = moment(colValue.value, 'DD.MM.YYYY');
            colValue.datevalue = newDate.toDate();
          } else if (colValue.value.length == 8) {
            const newDate = moment(colValue.value, 'DD.MM.YY');
            colValue.datevalue = newDate.toDate();
          } else if (colValue.value) {
            colValue.datevalue = new Date(colValue.value);
          }

        }
      }

      if (colValue.columncelltype == 2) {
        if (action.payload.cols[key].booleanValue) {
          colValue.booleanValue = action.payload.cols[key].booleanValue;
        }
        if (!colValue.booleanValue) {
          colValue.booleanValue = colValue.value && colValue.value.length > 0 ? colValue.value == '1' : false;
        }
      }

      newColumnValues.push(colValue);

    }
  }
  const newRowContent: RowContentModel = {
    id: action.payload.id, fieldModel: [], selected: false,
    isValid: action.payload.isValid, validationInfo: action.payload.validationInfo, columnValues: newColumnValues, rowtype: 'content'
  };
  var newRows = cloneDeep(state.rowContentList) as RowContentModel[];
  const idx = newRows.findIndex(x => x.id === action.payload.id);
  newRows = [...newRows.slice(0, idx), newRowContent, ...newRows.slice(idx + 1)];
  newRows.forEach(x => {
    if (action.payload.selectedRows.findIndex(f => f == x.id) >= 0) x.selected = true;
  })


  return {
    ...state,
    rowContentList: newRows
  }

}

export function InsertRowDoneReducer(state: ListEditorState = InitialListEditorState, action: InsertRowDoneAction): ListEditorState {
  const affectedRow = <RowContentModel>{
    id: action.payload.result.newId, selected: false, isValid: action.payload.result?.validationResult == undefined, fieldModel: [],
    validationInfo: action.payload.result.validationResult, columnValues: [], rowtype: 'content'
  };
  var content = [...state.rowContentList];
  const idx = content.findIndex(x => x.id === action.payload.result.oldId);
  if (idx > -1) {
    content = content.filter(x => x.id !== action.payload.result.oldId);
  }

  const colValues: ColumnValue[] = [];
  for (let y = 0; y < action.payload.result.columns.length; y++) {
    const colValue = new ColumnValue();
    colValue.columncelltype = action.payload.result.columns[y]["columncelltype"];
    colValue.columnname = action.payload.result.columns[y]["columnname"];
    colValue.columndesc = action.payload.result.columns[y]["columndesc"],
      colValue.usedcolumnid = action.payload.result.columns[y]["usedcolumnid"];
    colValue.ismandatory = action.payload.result.columns[y]["ismandatory"];
    colValue.items = action.payload.result.columns[y]["items"];
    colValue.value = action.payload.result.columns[y]["value"];
    colValue.datevalue = null;
    colValue.options = action.payload.result.columns[y]["options"];

    if (affectedRow.rowtype != 'header' && colValue.columncelltype == 6 && colValue.value) {
      if (colValue.value.length == 10) {
        const newDate = moment(colValue.value, 'DD.MM.YYYY');
        colValue.datevalue = newDate.toDate();
      } else if (colValue.value.length == 8) {
        const newDate = moment(colValue.value, 'DD.MM.YY');
        colValue.datevalue = newDate.toDate();
      } else if (colValue.value) {
        colValue.datevalue = new Date(colValue.value);
      }
    }
    if (affectedRow.rowtype != 'header' && colValue.columncelltype == 2) {
      colValue.booleanValue = colValue.value != null ? colValue.value === '1' : false;
    }
    colValues.push(colValue);
  }
  affectedRow.columnValues = colValues;

  return {
    ...state,
    rowContentList: [affectedRow, ...content]
  };
}



export function SaveUploadMultiRowReducer(state: ListEditorState = InitialListEditorState, action: UpdateUploadMultiRowsDoneAction): ListEditorState {


  var newcontentlist = cloneDeep(state.rowContentList) as RowContentModel[];

  for (let index = 0; index < action.payload.result.length; index++) {
    const result = action.payload.result[index];


    const affectedRow = newcontentlist.find(x => x.id === result.id);

    affectedRow.isValid = result.validationResult.length === 0;
    affectedRow.validationInfo = result.validationResult;
    const colValues: ColumnValue[] = [];
    for (let y = 0; y < result.columns.length; y++) {
      const colValue = new ColumnValue();
      colValue.columncelltype = result.columns[y]["columncelltype"];
      colValue.columnname = result.columns[y]["columnname"];
      colValue.columndesc = result.columns[y]["columndesc"],
        colValue.usedcolumnid = result.columns[y]["usedcolumnid"];
      colValue.ismandatory = result.columns[y]["ismandatory"];
      colValue.items = result.columns[y]["items"];
      colValue.value = result.columns[y]["value"];
      colValue.options = result.columns[y]["options"];

      colValue.datevalue = null;
      if (affectedRow.rowtype != 'header' && colValue.columncelltype == 6 && colValue.value) {
        colValue.datevalue = moment(colValue.value, 'DD.MM.YY').toDate();
      }
      if (affectedRow.rowtype != 'header' && colValue.columncelltype == 2) {
        colValue.booleanValue = colValue.value ? colValue.value == '1' : false;
      }
      colValues.push(colValue);
    }
    affectedRow.columnValues = [...colValues];
    affectedRow.selected = false;
    newcontentlist.forEach(row => {
      var selIdx = action.payload.selectedRows.findIndex(f => f == row.id);
      if (selIdx >= 0) row.selected = true;
      if (affectedRow && affectedRow.id == row.id) {
        row.validationInfo = affectedRow.validationInfo;
      }
    });
    const idx = newcontentlist.findIndex(x => x.id === result.id);
    if (idx > -1) {
      newcontentlist = [...newcontentlist.slice(0, idx), affectedRow, ...newcontentlist.slice(idx + 1)]
    }
  }
  return {
    ...state,
    rowContentList: newcontentlist
  }
}

export function SaveUploadListItemReducer(state: ListEditorState = InitialListEditorState, action: SaveUploadListItemDoneAction): ListEditorState {
  const affectedRow = Object.assign({}, state.rowContentList.find(x => x.id === action.payload.result.id));

  affectedRow.isValid = action.payload.result.validationResult.length === 0;
  affectedRow.validationInfo = action.payload.result.validationResult;
  const colValues: ColumnValue[] = [];
  for (let y = 0; y < action.payload.result.columns.length; y++) {
    const colValue = new ColumnValue();
    colValue.columncelltype = action.payload.result.columns[y]["columncelltype"];
    colValue.columnname = action.payload.result.columns[y]["columnname"];
    colValue.columndesc = action.payload.result.columns[y]["columndesc"],
      colValue.usedcolumnid = action.payload.result.columns[y]["usedcolumnid"];
    colValue.ismandatory = action.payload.result.columns[y]["ismandatory"];
    colValue.items = action.payload.result.columns[y]["items"];
    colValue.value = action.payload.result.columns[y]["value"];
    colValue.options = action.payload.result.columns[y]["options"];

    colValue.datevalue = null;
    if (affectedRow.rowtype != 'header' && colValue.columncelltype == 6 && colValue.value) {
      colValue.datevalue = moment(colValue.value, 'DD.MM.YY').toDate();
    }
    if (affectedRow.rowtype != 'header' && colValue.columncelltype == 2) {
      colValue.booleanValue = colValue.value ? colValue.value == '1' : false;
    }
    colValues.push(colValue);
  }
  affectedRow.columnValues = [...colValues];
  affectedRow.selected = false;
  var contentList = cloneDeep(state.rowContentList) as RowContentModel[];
  contentList.forEach(row => {
    var selIdx = action.payload.selectedRows.findIndex(f => f == row.id);
    if (selIdx >= 0) row.selected = true;
    if (affectedRow && affectedRow.id == row.id) {
      row.validationInfo = affectedRow.validationInfo;
    }
  });
  const idx = contentList.findIndex(x => x.id === action.payload.result.id);
  if (idx > -1) {
    contentList = [...contentList.slice(0, idx), affectedRow, ...contentList.slice(idx + 1)]
  }
  return {
    ...state,
    rowContentList: contentList
  }
}


export function GetUploadFieldsDoneReducer(state: ListEditorState = InitialListEditorState, action: GetUploadFieldsDoneAction): ListEditorState {
  const rowModel: RowContentModel = { id: -1, selected: false, isValid: false, fieldModel: [], validationInfo: null, columnValues: [], rowtype: 'content' };
  action.payload.fieldModels.forEach(x => rowModel.fieldModel.push({ key: x.key, value: x.value }));

  return {
    ...state,
    rowContentList: [rowModel]
  }
}

export function CreateUploadReducer(state: ListEditorState = InitialListEditorState, action: CreateUploadAction): ListEditorState {
  return {
    ...state,
    errorResult: undefined
  };

}
export function CreateUploadDoneReducer(state: ListEditorState = InitialListEditorState, action: CreateUploadActionDone): ListEditorState {


  const rowContentList: RowContentModel[] = [];

  action.payload.upload.data.stagingDetails.forEach(item => {
    const itemId = JSON.parse(item.id.toString()) as number;

    let colValues = item.columnValues
    const itemFieldModel: FieldModel[] = [];
    const rtype = item.rowType;
    colValues = cloneDeep(colValues);
    for (let column of colValues) {
      if (column.columncelltype == 6) {
        if (column.value && column.value.length > 0) {
          if (column.value.length == 10) {
            const newDate = moment(column.value, 'DD.MM.YYYY');
            column.datevalue = newDate.toDate();
          } else if (column.value.length == 19) {
            const newDate = moment(column.value, 'DD.MM.YYYY HH:mm:ss');
            column.datevalue = newDate.toDate();
          } else if (column.value.length == 8) {
            const newDate = moment(column.value, 'DD.MM.YY');
            column.datevalue = newDate.toDate();
          } else if (column.value) {
            column.datevalue = new Date(column.value);
          }
        }
      }
      if (column.columncelltype == 2) {
        column.booleanValue = column.value != null ? column.value === '1' : false;
      }
    }
    rowContentList.push({
      id: itemId, fieldModel: itemFieldModel, columnValues: colValues, selected: false, isValid: item.isValid,
      validationInfo: item.validationInfo, rowtype: rtype
    });
  })
  return {
    ...state,
    uploadStaging: { ...action.payload.upload.data },
    uploadId: action.payload.upload.data.id,
    rowList: action.payload.upload.data.stagingDetails,
    rowContentList,
  };
}


export function CloseEditorReducer(state: ListEditorState = InitialListEditorState, action: CloseEditorAction): ListEditorState {
  return {
    ...state,
    rowList: [],
    uploadStaging: {} as UploadStagingModel,
    uploadName: 'NN',
    uploadId: -1,
    rowContentList: [],
  };
}

export function DeleteRowsReducer(state: ListEditorState = InitialListEditorState, action: DeleteRowsAction)
  : ListEditorState {
  const ids = action.payload.ids;
  let rows = [];
  state.rowContentList.forEach(x => rows.push(Object.assign({}, x)));
  for (let i = 0; i < ids.length; i++) {
    const arrIndex = rows.findIndex(y => y.id == ids[i]);
    rows.splice(arrIndex, 1);
  }
  return {
    ...state,
    rowContentList: rows
  };
}

export function BubImportSelectedRowsReducer(state: ListEditorState = InitialListEditorState, action: BubImportSelectedRowsDoneAction): ListEditorState {
  const rowContentModels: RowContentModel[] = [];
  for (let i = 0; i < action.payload.stagingDetails.length; i++) {
    const row: RowContentModel = {
      id: action.payload.stagingDetails[i].id,
      selected: false,
      isValid: action.payload.stagingDetails[i].isValid,
      validationInfo: action.payload.stagingDetails[i].validationInfo,
      fieldModel: [],
      columnValues: action.payload.stagingDetails[i].columnValues && action.payload.stagingDetails[i].columnValues.length > 0 ? action.payload.stagingDetails[i].columnValues : [],
      rowtype: action.payload.stagingDetails[i].rowType
    };
    row.columnValues = cloneDeep(row.columnValues);
    for (let column of row.columnValues) {
      if (column.columncelltype == 6) {
        if (column.value && column.value.length > 0) {
          if (column.value.length == 10) {
            const newDate = moment(column.value, 'DD.MM.YYYY');
            column.datevalue = newDate.toDate();
          } else if (column.value.length == 19) {
            const newDate = moment(column.value, 'DD.MM.YYYY HH:mm:ss');
            column.datevalue = newDate.toDate();
          }
          else if (column.value.length == 8) {
            const newDate = moment(column.value, 'DD.MM.YY');
            column.datevalue = newDate.toDate();
          } else if (column.value) {
            column.datevalue = new Date(column.value);
          }
        }
      }
      if (column.columncelltype == 2) {
        if (column.value && column.value.length > 0) {
          column.booleanValue = column.value == '1';
        } else
          [
            column.booleanValue = false
          ]
      }
    }
    rowContentModels.push(row);
  }
  let existingList = Object.assign([], state.rowContentList);
  for (let i = 0; i < rowContentModels.length; i++) {
    const idx = existingList.findIndex(x => x.id == rowContentModels[i].id);
    if (idx > -1) {
      existingList = [...existingList.slice(0, idx), rowContentModels[i], ...existingList.slice(idx + 1)];
    }
  }
  return {
    ...state,
    rowContentList: existingList,
    isLoading: false
  }
}
