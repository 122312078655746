/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfPufferDto } from '../models/list-response-args-of-puffer-dto';
import { ExtendedQueryTokenDTO } from '../models/extended-query-token-dto';
import { ResponseArgsOfPufferDto } from '../models/response-args-of-puffer-dto';
import { PufferDto } from '../models/puffer-dto';
import { ResponseArgsOfBoolean } from '../models/response-args-of-boolean';
@Injectable({
  providedIn: 'root',
})
class PufferService extends __BaseService {
  static readonly PufferQueryPath = '/puffer/s';
  static readonly PufferCreatePath = '/puffer';
  static readonly PufferUpdatePath = '/puffer/{id}';
  static readonly PufferDeletePath = '/puffer/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Liste Liste
   * @param params The `PufferService.PufferQueryParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `eagerLoading`:
   */
  PufferQueryResponse(params: PufferService.PufferQueryParams): __Observable<__StrictHttpResponse<ListResponseArgsOfPufferDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/puffer/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfPufferDto>;
      })
    );
  }
  /**
   * Liste Liste
   * @param params The `PufferService.PufferQueryParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `eagerLoading`:
   */
  PufferQuery(params: PufferService.PufferQueryParams): __Observable<ListResponseArgsOfPufferDto> {
    return this.PufferQueryResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfPufferDto)
    );
  }

  /**
   * Create
   * @param params The `PufferService.PufferCreateParams` containing the following parameters:
   *
   * - `payload`: PufferDto
   *
   * - `user`: User Id oder Token
   */
  PufferCreateResponse(params: PufferService.PufferCreateParams): __Observable<__StrictHttpResponse<ResponseArgsOfPufferDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/puffer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfPufferDto>;
      })
    );
  }
  /**
   * Create
   * @param params The `PufferService.PufferCreateParams` containing the following parameters:
   *
   * - `payload`: PufferDto
   *
   * - `user`: User Id oder Token
   */
  PufferCreate(params: PufferService.PufferCreateParams): __Observable<ResponseArgsOfPufferDto> {
    return this.PufferCreateResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfPufferDto)
    );
  }

  /**
   * Update
   * @param params The `PufferService.PufferUpdateParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `id`:
   *
   * - `user`:
   */
  PufferUpdateResponse(params: PufferService.PufferUpdateParams): __Observable<__StrictHttpResponse<ResponseArgsOfPufferDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/puffer/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfPufferDto>;
      })
    );
  }
  /**
   * Update
   * @param params The `PufferService.PufferUpdateParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `id`:
   *
   * - `user`:
   */
  PufferUpdate(params: PufferService.PufferUpdateParams): __Observable<ResponseArgsOfPufferDto> {
    return this.PufferUpdateResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfPufferDto)
    );
  }

  /**
   * Delete
   * @param params The `PufferService.PufferDeleteParams` containing the following parameters:
   *
   * - `id`: Liste Id
   *
   * - `user`: User Id oder Token
   */
  PufferDeleteResponse(params: PufferService.PufferDeleteParams): __Observable<__StrictHttpResponse<ResponseArgsOfBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/puffer/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBoolean>;
      })
    );
  }
  /**
   * Delete
   * @param params The `PufferService.PufferDeleteParams` containing the following parameters:
   *
   * - `id`: Liste Id
   *
   * - `user`: User Id oder Token
   */
  PufferDelete(params: PufferService.PufferDeleteParams): __Observable<ResponseArgsOfBoolean> {
    return this.PufferDeleteResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfBoolean)
    );
  }
}

module PufferService {

  /**
   * Parameters for PufferQuery
   */
  export interface PufferQueryParams {
    queryToken: ExtendedQueryTokenDTO;
    eagerLoading?: number;
  }

  /**
   * Parameters for PufferCreate
   */
  export interface PufferCreateParams {

    /**
     * PufferDto
     */
    payload: PufferDto;

    /**
     * User Id oder Token
     */
    user?: null | string;
  }

  /**
   * Parameters for PufferUpdate
   */
  export interface PufferUpdateParams {
    payload: PufferDto;
    id: number;
    user?: null | string;
  }

  /**
   * Parameters for PufferDelete
   */
  export interface PufferDeleteParams {

    /**
     * Liste Id
     */
    id: number;

    /**
     * User Id oder Token
     */
    user?: null | string;
  }
}

export { PufferService }
