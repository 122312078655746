import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { HeaderFilter, ResultObjectModel } from '@app/common/models';
import { ColumnLookup, ColumnValidationLookup, LookupsColumnEditMappingParam } from '../models/novi/columnLookup.model';

import { ColorUserModel } from '../models/novi/Column.UserModel';
import { FilterEventParameter } from './FilterEventParameter';

import { IFilterHeaderDataService } from '../interfaces/FilterService/filterSerive.interfaces';
import { AuthService } from './auth.service';
import { NovitaetService } from '@app/api/filialmatrix/services';
import { ListResponseArgsOfString } from '@app/api/filialmatrix/models';
import { LiPoContact } from '../models/lipocontact.model';
import { WarengroupDto } from '../models/warengroupdto';
import { IBubData } from '../models/bubdata.model';
import { ResponseVerlagDto as ResponseDto, VerlagDto } from '../models/verlag.model';
import { ResponseArgs } from '@app/api/commonWebAPI/models';
import { id } from '@swimlane/ngx-charts';



@Injectable()
export class NoviProcessService implements IFilterHeaderDataService {


  constructor(private httpClient: HttpClient, private authService: AuthService,
    private notifier: NotificationService,
    public  novitaetService : NovitaetService

    ) {
    novitaetService.rootUrl = `${environment.connections.AdvWebapi}`;

  }



  getFilterValues(loadoptions: FilterEventParameter): Observable<HeaderFilter[]> {
    return of([]);
  }
  getLookupQueryValues(lookupParams: string[]): Observable<ColumnLookup[]> {
    return of([]);
  }
  getLookupValues(columnNames: LookupsColumnEditMappingParam[]): Observable<ColumnValidationLookup[]> {
    return of([]);
  }
  getLookupEditValues(cols: string[]): Observable<ColumnLookup[]> {
    return of([]);
  }

  getFilterLookupValuesAdvString(p: NovitaetService.NovitaetQueryNovitaetenStringLookupParams): Observable<ListResponseArgsOfString>
  {
      return this.novitaetService.NovitaetQueryNovitaetenStringLookup(p);
  }

  /**
   * LDV VErlage suchen
   * https://adv-test.kubernetes.paragon-systems.de/ldvservice/v1/ldv?searchterm=goldmann */
  GetLDVVerlage(srch: string) : Observable<ResponseDto<VerlagDto>> {

    const url = `${environment.connections.AdvWebapi}/ldv`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { searchterm: srch}
    };
    return  this.httpClient.get<ResponseDto<VerlagDto>>(url, httpOptions);


  }

  GetLDVAllVerlage(srch: string) : Observable<ResponseDto<VerlagDto>> {

    const url = `${environment.connections.AdvWebapi}/ldv/s`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { searchterm: srch}
    };
    return  this.httpClient.get<ResponseDto<VerlagDto>>(url, httpOptions);


  }

  GetLDVVerlageDeep(ldvverlagsid: number) : Observable<ResponseDto<VerlagDto>> {

    const url = `${environment.connections.AdvWebapi}/ldv/${ldvverlagsid}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    return  this.httpClient.get<ResponseDto<VerlagDto>>(url, httpOptions);
  }


  GetWarengruppen() : Observable<WarengroupDto[]> {

    const url = `${environment.connections.TaskListService}/api/articledata/getwarengruppen`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }
      )
    };
    return  this.httpClient.get<WarengroupDto[]>(url, httpOptions);


  }



  getSomaContacts() : Observable<ResultObjectModel<LiPoContact[]>>
  {
    const url = `${environment.connections.TaskListService}/api/articledata/getManagers`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }
      )
    };
    return  this.httpClient.get<ResultObjectModel<LiPoContact[]>>(url, httpOptions);
  }


  getBubDataByEan(ean: string): Observable<ResultObjectModel<IBubData>> {
    const url = `${environment.connections.MediatipService}/Mediatip/GetBubDataByEan`  ;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { ean: ean.toString()}
    };
    var x = this.httpClient.get<ResultObjectModel<IBubData>>(url, httpOptions);
    return x;
  }


  getBubDataByEans(eans: string[]): Observable<ResultObjectModel<IBubData[]>> {
    const url = `${environment.connections.MediatipService}/Mediatip/GetBubDataByEans`  ;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    var x = this.httpClient.post<ResultObjectModel<IBubData[]>>(url, eans, httpOptions);
    return x;
  }



  DeleteColorsofId(id: number) : Observable<number> {
    const url = `${environment.connections.SettingService}/customsettings/DeleteColorsofId?id=`+ id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
        //Authorization: 'Bearer ' + this.oauthService.getAccessToken()
      })
    };
    let result= 0;
    const httpResult = this.httpClient.get<ResultObjectModel<number>>(url, httpOptions).pipe(
      map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Fehler beim Löschen der Farbzuordnung: ' + x.userMessage);
          return result;
        }
        result = x.data;
        return result;
      }),
      catchError(x => {
        this.notifier.showError('Fehler beim Löschen der Farbzuordnung: ' + x.message);
        return of(0);
      })
    );
    return httpResult;
  }



  updateorAddColorofUser(colorofuser: ColorUserModel) {
    const url = `${environment.connections.SettingService}/customsettings/updateorAddColorofUser`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
        //Authorization: 'Bearer ' + this.oauthService.getAccessToken()
      })
    };
    let result: ColorUserModel[];
    const httpResult = this.httpClient.post<ResultObjectModel<ColorUserModel[]>>(url, colorofuser, httpOptions).pipe(
      map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Fehler beim Update der Farbzuordnung: ' + x.userMessage);
          return result;
        }
        result = x.data;
        return result;
      }),
      catchError(x => {
        this.notifier.showError('Fehler beim Update der Farbzuordnung: ' + x.message);
        return of([]);
      })
    );
    return httpResult;
  }




  /**Holt die aktuell verwendeten Farben des Users
   * für Auswahl /
  */
  getColorsofUser(userid: string): Observable<ColorUserModel[]> {
    const url = `${environment.connections.SettingService}/customsettings/getDistinctColorsofUser?userid=` + userid;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
        //Authorization: 'Bearer ' + this.oauthService.getAccessToken()
      })
    };
    let result: ColorUserModel[];
    const httpResult = this.httpClient.get<ResultObjectModel<ColorUserModel[]>>(url, httpOptions).pipe(
      map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Fehler beim Laden der Farben: ' + x.userMessage);
          return result;
        }
        result = x.data;
        return result;
      }),
      catchError(x => {
        this.notifier.showError('Fehler beim Laden der Farben: ' + x.message);
        return of([]);
      })
    );
    return httpResult;
  }







}
