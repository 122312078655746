import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { withLatestFrom, switchMap, map, catchError } from 'rxjs/operators';
import { selectUserTasksState } from './state';
import { TaskService } from '@app/common/services/task.service';
import { GetTasksForUserAction, UserViewActionType, GetTasksForUserDoneAction, GetBreadcrumbActionDone, GetBreadcrumbAction, UserTaskErrorAction } from './actions';
import { GeneralResultEnum } from '@app/common/models';
import { of } from 'rxjs';

@Injectable()
export class UserViewEffects {
  state$ = this.store.pipe(select(selectUserTasksState));

  constructor(
    private store: Store<any>,
    private actions: Actions,
    private service: TaskService
  ) { }


  getUserTasks$ = createEffect(() => { return  this.actions.pipe(
    ofType<GetTasksForUserAction>(UserViewActionType.GetTasksForUser),
    withLatestFrom(this.state$),
    switchMap(([action, state]) => this.service.getAllUserTasks(action.payload.param).pipe(
      map(response => {
        if (response.generalResult == GeneralResultEnum.Success) {
          return new GetTasksForUserDoneAction({ tasks: response.data })
        } else {
          return new UserTaskErrorAction({ error: response, message: "Fehler Lieferanten Aufgaben laden" });
        }
      }
      )
    )), catchError((error, caught) => {
            this.store.dispatch(new UserTaskErrorAction({ error: error, message: "Fehler Lieferanten Aufgaben laden" }));
            return caught;
    })
  )})

  getBreadcrumb$ = createEffect(() => { return  this.actions.pipe(
    ofType<GetBreadcrumbAction>(UserViewActionType.GetBreadcrumb),
    withLatestFrom(this.state$),
    switchMap(([action, state]) => this.service.getBreadcrumb(action.options.id)),
    map(response => new GetBreadcrumbActionDone({ item: response }))
  )})
}
