import { AppState as RootState } from '../../store';
import { NumberSymbol } from '@angular/common';
import { ListModel } from '@app/common/models';
import { createFeatureSelector } from '@ngrx/store';
import { ListOverviewModule } from '../list-overview.module';

export interface ListOverviewState {
    page: number;
    total: number;
    pagesize: number;
    lists: ListModel[];
    tabFlag: boolean;
    loading: boolean;
}


export interface AppState extends RootState {
    listoverview: ListOverviewState;
}

export const InitialListOverviewState: ListOverviewState = {
    lists: [],
    page: 1,
    pagesize: 10,
    total: 0,
    tabFlag: false,
    loading: true
}

export const featureName = 'listoverview';

export const selectListOverviewState = createFeatureSelector<ListOverviewState>(featureName);
