/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfUserDTO } from '../models/list-response-args-of-user-dto';
import { QueryTokenDTO } from '../models/query-token-dto';
import { ResponseArgsOfUserDTO } from '../models/response-args-of-user-dto';
import { UserDTO } from '../models/user-dto';
import { ResponseArgs } from '../models/response-args';
import { NewUserValues } from '../models/new-user-values';
import { ResponseArgsOfIEnumerableOfUserSettingsDTO } from '../models/response-args-of-ienumerable-of-user-settings-dto';
import { ResponseArgsOfUserSettingsDTO } from '../models/response-args-of-user-settings-dto';
import { UserSettingsDTO } from '../models/user-settings-dto';
@Injectable({
  providedIn: 'root',
})
class UserService extends __BaseService {
  static readonly UserQueryUserPath = '/user/s';
  static readonly UserGetUserPath = '/user/{userId}';
  static readonly UserUpdateUserPath = '/user/{userId}';
  static readonly UserDeleteUserPath = '/user/{userId}';
  static readonly UserCreateUserPath = '/user';
  static readonly UserChangePasswordPath = '/user/{userId}/password';
  static readonly UserGetUserSettingsPath = '/user/{userId}/settings';
  static readonly UserCreateUserSettingsPath = '/user/{userId}/settings';
  static readonly UserUpdateUserSettingsPath = '/user/{userId}/settings/{userSettingsId}';
  static readonly UserDeleteUserSettingsPath = '/user/{userId}/settings/{userSettingsId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Benutzer
   * @param params The `UserService.UserQueryUserParams` containing the following parameters:
   *
   * - `queryToken`: Suchparameter
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  UserQueryUserResponse(params: UserService.UserQueryUserParams): __Observable<__StrictHttpResponse<ListResponseArgsOfUserDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/user/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfUserDTO>;
      })
    );
  }
  /**
   * Benutzer
   * @param params The `UserService.UserQueryUserParams` containing the following parameters:
   *
   * - `queryToken`: Suchparameter
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  UserQueryUser(params: UserService.UserQueryUserParams): __Observable<ListResponseArgsOfUserDTO> {
    return this.UserQueryUserResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfUserDTO)
    );
  }

  /**
   * Benutzer
   * @param params The `UserService.UserGetUserParams` containing the following parameters:
   *
   * - `userId`: Benutzer PK
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  UserGetUserResponse(params: UserService.UserGetUserParams): __Observable<__StrictHttpResponse<ResponseArgsOfUserDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfUserDTO>;
      })
    );
  }
  /**
   * Benutzer
   * @param params The `UserService.UserGetUserParams` containing the following parameters:
   *
   * - `userId`: Benutzer PK
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  UserGetUser(params: UserService.UserGetUserParams): __Observable<ResponseArgsOfUserDTO> {
    return this.UserGetUserResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfUserDTO)
    );
  }

  /**
   * Benutzer aktualiseren
   * @param params The `UserService.UserUpdateUserParams` containing the following parameters:
   *
   * - `userId`: Benutzer PK
   *
   * - `payload`: Benutzerdaten
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  UserUpdateUserResponse(params: UserService.UserUpdateUserParams): __Observable<__StrictHttpResponse<ResponseArgsOfUserDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/user/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfUserDTO>;
      })
    );
  }
  /**
   * Benutzer aktualiseren
   * @param params The `UserService.UserUpdateUserParams` containing the following parameters:
   *
   * - `userId`: Benutzer PK
   *
   * - `payload`: Benutzerdaten
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  UserUpdateUser(params: UserService.UserUpdateUserParams): __Observable<ResponseArgsOfUserDTO> {
    return this.UserUpdateUserResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfUserDTO)
    );
  }

  /**
   * Benutzer löschen
   * @param params The `UserService.UserDeleteUserParams` containing the following parameters:
   *
   * - `userId`: Benutzer PK
   *
   * - `locale`: Lokalisierung
   */
  UserDeleteUserResponse(params: UserService.UserDeleteUserParams): __Observable<__StrictHttpResponse<ResponseArgs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/user/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgs>;
      })
    );
  }
  /**
   * Benutzer löschen
   * @param params The `UserService.UserDeleteUserParams` containing the following parameters:
   *
   * - `userId`: Benutzer PK
   *
   * - `locale`: Lokalisierung
   */
  UserDeleteUser(params: UserService.UserDeleteUserParams): __Observable<ResponseArgs> {
    return this.UserDeleteUserResponse(params).pipe(
      __map(_r => _r.body as ResponseArgs)
    );
  }

  /**
   * Benutzer anlegen
   * @param params The `UserService.UserCreateUserParams` containing the following parameters:
   *
   * - `payload`: Benutzerdaten
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  UserCreateUserResponse(params: UserService.UserCreateUserParams): __Observable<__StrictHttpResponse<ResponseArgsOfUserDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/user`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfUserDTO>;
      })
    );
  }
  /**
   * Benutzer anlegen
   * @param params The `UserService.UserCreateUserParams` containing the following parameters:
   *
   * - `payload`: Benutzerdaten
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  UserCreateUser(params: UserService.UserCreateUserParams): __Observable<ResponseArgsOfUserDTO> {
    return this.UserCreateUserResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfUserDTO)
    );
  }

  /**
   * Password aktualiseren
   * @param params The `UserService.UserChangePasswordParams` containing the following parameters:
   *
   * - `userId`: Benutzer PK
   *
   * - `password`: Neues Passwort
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  UserChangePasswordResponse(params: UserService.UserChangePasswordParams): __Observable<__StrictHttpResponse<ResponseArgsOfUserDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.password;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/user/${encodeURIComponent(String(params.userId))}/password`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfUserDTO>;
      })
    );
  }
  /**
   * Password aktualiseren
   * @param params The `UserService.UserChangePasswordParams` containing the following parameters:
   *
   * - `userId`: Benutzer PK
   *
   * - `password`: Neues Passwort
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  UserChangePassword(params: UserService.UserChangePasswordParams): __Observable<ResponseArgsOfUserDTO> {
    return this.UserChangePasswordResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfUserDTO)
    );
  }

  /**
   * Benutzereinstellungen
   * @param params The `UserService.UserGetUserSettingsParams` containing the following parameters:
   *
   * - `userId`: Benutzer PK
   *
   * - `locale`: Lokalisierung
   */
  UserGetUserSettingsResponse(params: UserService.UserGetUserSettingsParams): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfUserSettingsDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user/${encodeURIComponent(String(params.userId))}/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfUserSettingsDTO>;
      })
    );
  }
  /**
   * Benutzereinstellungen
   * @param params The `UserService.UserGetUserSettingsParams` containing the following parameters:
   *
   * - `userId`: Benutzer PK
   *
   * - `locale`: Lokalisierung
   */
  UserGetUserSettings(params: UserService.UserGetUserSettingsParams): __Observable<ResponseArgsOfIEnumerableOfUserSettingsDTO> {
    return this.UserGetUserSettingsResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfUserSettingsDTO)
    );
  }

  /**
   * Benutzereinstellung hinzufügen
   * @param params The `UserService.UserCreateUserSettingsParams` containing the following parameters:
   *
   * - `userId`: Benutzer PK
   *
   * - `payload`: Benutzereinstellungen
   *
   * - `locale`: Lokalisierung
   */
  UserCreateUserSettingsResponse(params: UserService.UserCreateUserSettingsParams): __Observable<__StrictHttpResponse<ResponseArgsOfUserSettingsDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/user/${encodeURIComponent(String(params.userId))}/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfUserSettingsDTO>;
      })
    );
  }
  /**
   * Benutzereinstellung hinzufügen
   * @param params The `UserService.UserCreateUserSettingsParams` containing the following parameters:
   *
   * - `userId`: Benutzer PK
   *
   * - `payload`: Benutzereinstellungen
   *
   * - `locale`: Lokalisierung
   */
  UserCreateUserSettings(params: UserService.UserCreateUserSettingsParams): __Observable<ResponseArgsOfUserSettingsDTO> {
    return this.UserCreateUserSettingsResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfUserSettingsDTO)
    );
  }

  /**
   * Benutzereinstellung aktualisieren
   * @param params The `UserService.UserUpdateUserSettingsParams` containing the following parameters:
   *
   * - `userSettingsId`: Benutzereinstellungen PK
   *
   * - `userId`: Benutzer PK
   *
   * - `payload`: Benutzereinstellungen
   *
   * - `locale`: Lokalisierung
   */
  UserUpdateUserSettingsResponse(params: UserService.UserUpdateUserSettingsParams): __Observable<__StrictHttpResponse<ResponseArgsOfUserSettingsDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.payload;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/user/${encodeURIComponent(String(params.userId))}/settings/${encodeURIComponent(String(params.userSettingsId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfUserSettingsDTO>;
      })
    );
  }
  /**
   * Benutzereinstellung aktualisieren
   * @param params The `UserService.UserUpdateUserSettingsParams` containing the following parameters:
   *
   * - `userSettingsId`: Benutzereinstellungen PK
   *
   * - `userId`: Benutzer PK
   *
   * - `payload`: Benutzereinstellungen
   *
   * - `locale`: Lokalisierung
   */
  UserUpdateUserSettings(params: UserService.UserUpdateUserSettingsParams): __Observable<ResponseArgsOfUserSettingsDTO> {
    return this.UserUpdateUserSettingsResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfUserSettingsDTO)
    );
  }

  /**
   * Benutzereinstellung löschen
   * @param params The `UserService.UserDeleteUserSettingsParams` containing the following parameters:
   *
   * - `userSettingsId`: Benutzereinstellungen PK
   *
   * - `userId`: Benutzer PK
   *
   * - `locale`: Lokalisierung
   */
  UserDeleteUserSettingsResponse(params: UserService.UserDeleteUserSettingsParams): __Observable<__StrictHttpResponse<ResponseArgs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/user/${encodeURIComponent(String(params.userId))}/settings/${encodeURIComponent(String(params.userSettingsId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgs>;
      })
    );
  }
  /**
   * Benutzereinstellung löschen
   * @param params The `UserService.UserDeleteUserSettingsParams` containing the following parameters:
   *
   * - `userSettingsId`: Benutzereinstellungen PK
   *
   * - `userId`: Benutzer PK
   *
   * - `locale`: Lokalisierung
   */
  UserDeleteUserSettings(params: UserService.UserDeleteUserSettingsParams): __Observable<ResponseArgs> {
    return this.UserDeleteUserSettingsResponse(params).pipe(
      __map(_r => _r.body as ResponseArgs)
    );
  }
}

module UserService {

  /**
   * Parameters for UserQueryUser
   */
  export interface UserQueryUserParams {

    /**
     * Suchparameter
     */
    queryToken: QueryTokenDTO;

    /**
     * Lokalisierung
     */
    locale?: null | string;

    /**
     * Objekt-Tiefe (optional, default: 1)
     */
    eagerLoading?: number;
  }

  /**
   * Parameters for UserGetUser
   */
  export interface UserGetUserParams {

    /**
     * Benutzer PK
     */
    userId: number;

    /**
     * Lokalisierung
     */
    locale?: null | string;

    /**
     * Objekt-Tiefe (optional, default: 1)
     */
    eagerLoading?: number;
  }

  /**
   * Parameters for UserUpdateUser
   */
  export interface UserUpdateUserParams {

    /**
     * Benutzer PK
     */
    userId: number;

    /**
     * Benutzerdaten
     */
    payload: UserDTO;

    /**
     * Lokalisierung
     */
    locale?: null | string;

    /**
     * Objekt-Tiefe (optional, default: 1)
     */
    eagerLoading?: number;
  }

  /**
   * Parameters for UserDeleteUser
   */
  export interface UserDeleteUserParams {

    /**
     * Benutzer PK
     */
    userId: number;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }

  /**
   * Parameters for UserCreateUser
   */
  export interface UserCreateUserParams {

    /**
     * Benutzerdaten
     */
    payload: NewUserValues;

    /**
     * Lokalisierung
     */
    locale?: null | string;

    /**
     * Objekt-Tiefe (optional, default: 1)
     */
    eagerLoading?: number;
  }

  /**
   * Parameters for UserChangePassword
   */
  export interface UserChangePasswordParams {

    /**
     * Benutzer PK
     */
    userId: number;

    /**
     * Neues Passwort
     */
    password: string;

    /**
     * Lokalisierung
     */
    locale?: null | string;

    /**
     * Objekt-Tiefe (optional, default: 1)
     */
    eagerLoading?: number;
  }

  /**
   * Parameters for UserGetUserSettings
   */
  export interface UserGetUserSettingsParams {

    /**
     * Benutzer PK
     */
    userId: number;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }

  /**
   * Parameters for UserCreateUserSettings
   */
  export interface UserCreateUserSettingsParams {

    /**
     * Benutzer PK
     */
    userId: number;

    /**
     * Benutzereinstellungen
     */
    payload: UserSettingsDTO;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }

  /**
   * Parameters for UserUpdateUserSettings
   */
  export interface UserUpdateUserSettingsParams {

    /**
     * Benutzereinstellungen PK
     */
    userSettingsId: number;

    /**
     * Benutzer PK
     */
    userId: number;

    /**
     * Benutzereinstellungen
     */
    payload: UserSettingsDTO;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }

  /**
   * Parameters for UserDeleteUserSettings
   */
  export interface UserDeleteUserSettingsParams {

    /**
     * Benutzereinstellungen PK
     */
    userSettingsId: number;

    /**
     * Benutzer PK
     */
    userId: number;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }
}

export { UserService }
