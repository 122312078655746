import { Component, OnInit, ChangeDetectionStrategy, ViewChild, EventEmitter, OnDestroy, signal, WritableSignal } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  selectTasks, selectPageSize, selectPage, selectCount, selectLoading,
  selectValidationProfiles, selectAllMandanten, selectLastlisttaskparmeter,
  selectTaskerror,
  selectSuppliersofTask,
  selectSuppliersofTaskLoading,
  selectLieferantenUser
} from './store/selectors';
import { AddNavigationTabAction } from '@app/store/navigation/actions';
import { GetTasksForCurrentUserAction,  CreateSupplierTasksAction, GetValidationsAction, DuplicateMasterAction, DuplicateSupplierAction, ApproveTaskAction, DeleteTaskDoneAction, ApproveTaskDoneAction, TaskListActionType, GetMandantenAction, CreateMandantenTasksAction, ClearTaskErrorAktion, GetLieferantenUserAction  } from './store';
import { TaskUserModel, ResultObjectModel, ActionStates, AufgabenArt, AufgabenartValidListe, GeneralResultEnum, LieferantTypeEnum, TaskLieferantParams } from '@app/common/models';
import { Observable, of, Subscription } from 'rxjs';
import { map, filter, take } from 'rxjs/operators';
import { NavigationExtras, Router } from '@angular/router';
import { environment } from '@env/environment';
import { AuthService } from '@app/common/services/auth.service';
import * as cloneDeep from 'lodash/fp/cloneDeep';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { TaskStructureModel } from '@app/common/models/taskStructure.model';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService } from 'primeng/api';
import { SomaTaskViewDialogComponent } from '@app/dialogs/taskViewDialogs/soma-task-view-dialog/soma-task-view-dialog.component';
import { SupplierSelectDialogComponent } from './supplierSelectDialog.component';

import { TreeTable } from 'primeng/treetable';
import { NotificationService } from '@app/common/services/notification.service';
import { TaskEditDialogComponent } from './task-edit-dialog.component';
import { CreateMasterTaskDoneAction } from '@app/+task-editor/store';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Actions, ofType } from '@ngrx/effects';
import { ContextMenu } from 'primeng/contextmenu';
import { RowGroupHeader, Table, TableModule } from 'primeng/table';
import { ClearCommonDataErrorAction, CommonDataError, GetKlammernAction, GetSortimenteAction, GetSortimentstypenAction, selectallKlammern, selectallSortimente, selectallTypen, selectCommondataError } from '@app/common-data-module/store';
import { QueryTokenDTO } from '@app/api/filialmatrix/models';
import { KlammerService, LieferantService, SortimentService } from '@app/api/filialmatrix/services';
import { SortimentDtoClient } from '@app/common/models/sortimente/SortimentDtoClient';
import { PageSorting } from '@app/common/models/PagingSort-model';
import { TaskQueryParameter } from '@app/common/services/task.service';
import { CommonCheckService } from '@app/common/services/common.validation.service';
import { LookupValueBase } from '@app/common/models/novi/columnLookup.model';
import { MandantenService } from '@app/common-data-module/services/MandantenService';
import { MandantUserDTO } from '@app/common/models/mandant-user-dto';
import { IClientError } from '@app/common/interfaces/Interfaces';
import { LogUsersClientErrorAction } from '@app/store/user/actions';
import {  LieferantUserDTO } from '@app/common-data-module/models/LieferantUserDTO';
import { LieferantenService } from '@app/common-data-module/services/LieferantenService';



export interface CategoryTaskSelection {
  selectedCategories: ActionStates[];
  task: TaskUserModel;
  onlyUser?: boolean;
}

export enum ActionMenu {
  Bearbeiten = "Bearbeiten",
  Löschen = "Löschen",
  Duplizieren = "Duplizieren",
  Übersicht = "Übersicht",
  Neuer_Lieferant = "Neuer_Lieferant",
  Freigeben = "Freigeben",
  UploadDatenLieferant = "UploadDatenLieferant",
  Neuer_Mandant = "Neuer_Mandant",
  UploadDatenMandant = "UploadDatenMandant"
}

@Component({
  selector: 'app-task-list',
  templateUrl: 'task-list.component.html',
  styleUrls: ['task-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TaskListComponent implements OnInit, OnDestroy {

  @ViewChild('taskList', { static: false }) listTable: TreeTable;

  pageSorting = new PageSorting(20)

  showUpload: boolean = false;
  selectedFilter = 'all';

  // @ts-ignore
  selectTaskerror$ = this.store.pipe(select(selectTaskerror));

  selectSuppliersofTaskLoading$ = this.store.pipe(select(selectSuppliersofTaskLoading));
  // @ts-ignore
  selectLastlisttaskparmeter$ = this.store.pipe(select(selectLastlisttaskparmeter));
  // @ts-ignore
  tasks$ = this.store.pipe(select(selectTasks), filter(l => l != undefined), map(cloneDeep));
  searchTimeOut: any;
  // @ts-ignore
  total$ = this.store.pipe(select(selectCount));
  // @ts-ignore
  skip$ = this.store.pipe(select(selectPage));
  // @ts-ignore
  take$ = this.store.pipe(select(selectPageSize));
  // @ts-ignore
  isLoading$ = this.store.pipe(select(selectLoading));

  sortimentsToView$: Observable<SortimentDtoClient[]>;
  // @ts-ignore
  isManagerUser$ = this.authService.claims$
    .pipe(
      filter(f => !!f.member_of),
      map(userInfo => environment.managers.some(x => userInfo.member_of.includes(x))));
  // @ts-ignore
  isAdminUser$ = this.authService.claims$
    .pipe(
      filter(f => !!f.member_of),
      map(userInfo => environment.admins.some(x => userInfo.member_of.includes(x))));
  userCols: any[];
  isManager: boolean;
  isAdmin: boolean;
  userId: string;
  showOwnTasksOnly = false;
  filterActivityRange = false;
  lastSelectedTask: TaskUserModel;
  lastSelectedNode: TaskStructureModel<TaskUserModel>;
  actionSubscription: Subscription;
  lastAction: number; //0 = None, 1 = New, 2 = Edit, 3 = Delete

  orderTaskOptions = [{ key: 'editStart', value: 'Bearbeitungsstart', descending: true }, { key: 'editStop', value: 'Bearbeitungsende', descending: true }, { key: 'runtimeStart', value: 'Laufzeitstart', descending: true }, { key: 'runtimeStop', value: 'Laufzeitende', descending: true }, { key: 'assortmentName', value: 'Sortiment', descending: false }];
  selectedOrderTaskOption = this.orderTaskOptions[1].key;

  view: any[] = [70, 70];
  colorScheme = {
    domain: ['red', 'yellow', 'green', 'blue']
  };
  testStyle = 'margin-left: -12px !important; margin-top: -15px !important; margin-bottom: -19px !important;';

  mainMenu: MenuItem[] = [];
  currentmainMenu: WritableSignal<MenuItem[]> = signal([]);
  openMenues: ContextMenu[] = [];
  childMenu: MenuItem[] = [];
  currentchildMenu: WritableSignal<MenuItem[]> = signal([]);

  selectedCategories: ActionStates[] = [ActionStates.Unknown];


  tasks: WritableSignal<TaskStructureModel<TaskUserModel>[]> = signal([]);
  aufgabenArten: LookupValueBase[];
  selectedAufgabenart: LookupValueBase;
  refSupplier: DynamicDialogRef<SupplierSelectDialogComponent>;
  selectLieferantenUser$: Observable<LieferantUserDTO[]> = of([]);
  selectselectCommondataError$ : Observable<CommonDataError> = of(null);

  constructor(private store: Store<any>, private router: Router, private authService: AuthService, private dialogService: DialogService,
    private messageService: MessageService, private confirmationService: ConfirmationService, private httpClient: HttpClient,
    private luipocheck: CommonCheckService,
    private mandantenservice: MandantenService,
    private lieferantenService: LieferantenService,
    private notifier: NotificationService, public _actions$: Actions) {

    this.selectselectCommondataError$ = this.store.pipe(select(selectCommondataError));
    this.pageSorting.sortOrder = -1;  // absteigend default


    var filterCategories = this.router.getCurrentNavigation()?.extras?.state?.filterCategories as CategoryTaskSelection; // params reverse aus listeditor
    if (filterCategories != undefined) {
      if (filterCategories.selectedCategories) {
        this.selectedCategories = filterCategories.selectedCategories;
      }
      if (filterCategories.onlyUser != undefined) {
        this.showOwnTasksOnly = filterCategories.onlyUser;
      }
    }



    let sub = this.isManagerUser$.subscribe(x => {
      this.isManager = x;

      if (this.isManager) {
        this.userCols = [
          { field: 'name', header: 'Name', canOrder: true },
          { field: 'aufgabenArt', header: 'Aufgabenart', canOrder: true },
          { field: 'sortimentName', header: 'Sortiment', canOrder: true },
          { field: 'taskValidRangeFrom', header: 'Start', canOrder: true },
          { field: 'taskValidRangeTo', header: 'Ende', canOrder: true },
          { field: 'organisation', header: 'Lieferant/Mandant', canOrder: false },
          { field: 'fullName', header: 'Kontakt', canOrder: false }
        ];
      } else {
        this.userCols = [
          { field: 'name', header: 'Name', canOrder: true },
          { field: 'sortimentName', header: 'Sortiment', canOrder: true },
          { field: 'taskValidRangeFrom', header: 'Start', canOrder: true },
          { field: 'taskValidRangeTo', header: 'Ende', canOrder: true },
          { field: 'creatorFriendlyName', header: 'Kontakt', canOrder: false },
          { field: 'creatorMail', header: 'Mail', canOrder: false }
        ];
      }
    });
    sub.unsubscribe();
    sub = this.isAdminUser$.subscribe(x => { this.isAdmin = x; });
    sub.unsubscribe();
    sub = this.authService.claims$.subscribe(x => {
      this.userId = x.id;

    });
    sub.unsubscribe();

    this.actionSubscription = this._actions$.pipe(ofType<CreateMasterTaskDoneAction>(TaskListActionType.CreateMasterTaskDone)).subscribe((data: any) => {
      if (this.listTable) {
        this.listTable.first = 0;
      }
    });

    this.selectLieferantenUser$ = this.store.pipe(select(selectLieferantenUser));
  }

  ngOnDestroy(): void {


  }

  toggle(e, cm): void {
    e.preventDefault();
    e.stopPropagation();
    cm.show(e);
  }

  /**Gültige Main Menu Action ? */
  canMainMenuAction(action: ActionMenu, row: TaskUserModel): boolean {
    if (!this.tasks()) return false;
    var children = this.tasks().filter(g => g.data && row.id && g.data.id && g.data.id == row.id)[0]?.children;
    if (!children) return false;
    switch (action) {
      case ActionMenu.Duplizieren:
        return row.aufgabenArt != AufgabenArt.WeitereBedarfe;
      case ActionMenu.Neuer_Lieferant:
        //CategoryManager nur einmalig
        if (row.lieferantType == LieferantTypeEnum.CategoryManager && children.length > 0) return false;
        return row.aufgabenArt != AufgabenArt.WeitereBedarfe;
      case ActionMenu.Neuer_Mandant:
        return row.aufgabenArt == AufgabenArt.WeitereBedarfe;
      case ActionMenu.Bearbeiten:
        return (!children || children.length == 0) ||
          (children && children.filter(r => r.data.taskState == ActionStates.Created).length > 0);
      case ActionMenu.Löschen:
        return (!children || children.length == 0) ||
          (children && children.filter(r => r.data.taskState == ActionStates.Created || r.data.taskState == ActionStates.TransferedNoInput ).length == children.length  );
      case ActionMenu.Freigeben:
        return children && children.filter(r => r.data.taskState == ActionStates.Created).length > 0;
      default:
        return true;
    }

  }

  canChildMenuAction(action: ActionMenu, row: TaskUserModel): boolean {
    switch (action) {
      case ActionMenu.Bearbeiten:
        return row.taskState == ActionStates.Created;
      case ActionMenu.Löschen:
        return row.taskState == ActionStates.Created;
      case ActionMenu.Duplizieren:
        return row.taskState == ActionStates.Created && row.aufgabenArt != AufgabenArt.WeitereBedarfe;
      case ActionMenu.UploadDatenLieferant:
        return row.aufgabenArt != AufgabenArt.WeitereBedarfe && row.selectedValidationId > 0 && !(row.taskState < ActionStates.Transfered);
      case ActionMenu.UploadDatenMandant:
        return row.aufgabenArt == AufgabenArt.WeitereBedarfe && row.selectedValidationId > 0 && !(row.taskState < ActionStates.Transfered);
      default:
        return true;
    }
  }

  getAufgabenArtText(aufgabenArt: AufgabenArt) {
    if (aufgabenArt == undefined) return AufgabenArt.unbekannt;
    return this.aufgabenArten.find(f => f.id == aufgabenArt)?.value;

  }

  getMainMenu(row: TaskUserModel): MenuItem[] {
    return this.mainMenu.filter(f => this.canMainMenuAction(f.id as ActionMenu, row));
  }

  hasChildMenus(row: TaskUserModel): boolean {
    var childmenus = this.getChildMenu(row);
    var menuswithoutseperators = childmenus.filter(g => g.separator == undefined);
    return menuswithoutseperators.length > 0;
  }

  getChildMenu(row: TaskUserModel): MenuItem[] {
    return this.childMenu.filter(f => this.canChildMenuAction(f.id as ActionMenu, row));
  }

  myOnFilter(event: any) {
    console.log(event);
  }

  ngOnInit() {

    this.aufgabenArten = AufgabenartValidListe;
    this.selectedAufgabenart = undefined,
      this.mainMenu = [
        { id: ActionMenu.Übersicht, label: 'Übersicht', command: (event) => { this.showSomaOverview(event); } },
        { id: ActionMenu.Bearbeiten, label: 'Bearbeiten', command: (event) => { this.openTaskEditDialog('master', 'edit'); } },
        { id: ActionMenu.Duplizieren + "sep", separator: true },
        { id: ActionMenu.Duplizieren, label: 'Duplizieren', command: (event) => { this.duplicateMaster(); } },
        { id: ActionMenu.Neuer_Lieferant, label: 'Neuer Lieferant', command: (event) => { this.createTasksForSuppliers(); } },
        { id: ActionMenu.Neuer_Mandant, label: 'Neuer Mandant', command: (event) => { this.createTasksForMandanten(); } },
        { id: ActionMenu.Freigeben, label: 'Freigeben', command: (event) => { this.approveTaskForSupplier(); } },
        { id: ActionMenu.Löschen + "sep", separator: true },
        { id: ActionMenu.Löschen, label: 'Löschen', command: (event) => { this.deleteMasterTask(); } }
      ];
    this.childMenu = [
      { id: ActionMenu.Bearbeiten, label: 'Bearbeiten', command: (event) => { this.openTaskEditDialog('supplier', 'edit'); } },
      { id: ActionMenu.Bearbeiten, separator: true },
      { id: ActionMenu.Duplizieren, label: 'Duplizieren', command: (event) => { this.duplicateSupplierTask(); } },
      { id: ActionMenu.Duplizieren, separator: true },
      { id: ActionMenu.Löschen, label: 'Löschen', command: (event) => { this.deleteSupplierTask(); } },
      { id: ActionMenu.UploadDatenLieferant + "sep", separator: true },
      { id: ActionMenu.UploadDatenLieferant, label: 'Input Lieferant', command: (event) => { this.gotoUploadEditorReadonly(); } },
      { id: ActionMenu.UploadDatenMandant, label: 'Input Mandant', command: (event) => { this.gotoUploadEditorReadonly(); } }
    ];

    this.store.dispatch(new GetValidationsAction());

    this.store.dispatch(new GetSortimentstypenAction({
      params: <SortimentService.SortimentQuerySortimentsTypenParams>{
        eagerLoading: 2, queryToken: <QueryTokenDTO>{
          take: null, skip: null,
          orderBy: [{ by: "name", desc: false }]
        }
      }
    }));




    var pk = <KlammerService.KlammerQueryKlammernParams>{};
    pk.eagerLoading = 2;
    pk.queryToken = <QueryTokenDTO>{
      take: null, skip: null,
      orderBy: [{ by: "sortNumber", desc: false }]
    }
    this.store.dispatch(new GetKlammernAction({ param: pk }));


    var ps = <SortimentService.SortimentQuerySortimenteParams>{}
    ps.eagerLoading = 2;
    ps.queryToken = <QueryTokenDTO>{
      take: null, skip: null,
      orderBy: [{ by: "sortNumber", desc: false }]
    }
    this.store.dispatch(new GetSortimenteAction({ param: ps }));



    this.sortimentsToView$ = this.store.pipe(select(selectallSortimente)).pipe(map(x => x.filter(y => y.klammer && y.klammer.assortmentmasterId == this.lastSelectedTask.klammerId)));

    this.tasks$.subscribe((t: TaskStructureModel<TaskUserModel>[]) => {
      this.tasks.set(t);


      this.lieferantenService.selectLieferanten$.subscribe(lieferanten => {
        if (lieferanten.length > 0) {
          this.tasks.update(t => {
            t = [...t];
            for (let index = 0; index < t.length; index++) {
              const task = t[index];
              if (task.children != undefined) {
                for (let index = 0; index < task.children.length; index++) {
                  const child = task.children[index];
                  if (task.data.lieferantId) {
                    child.data.organization = lieferanten.find(f => f.id.toString() == task.data.lieferantId)?.name;
                  }
                }
              }
            }
            return t;
          })
        }
      })


    });






    this.selectLastlisttaskparmeter$.subscribe(params => {
      if (params != undefined && params.take) {
        this.store.dispatch(new GetTasksForCurrentUserAction({ params: params }));
      } else {
        this.load(null);
      }
    }).unsubscribe()

    this.selectTaskerror$.subscribe(e => {
      if (e && e.userMessage) {
        this.notifier.showError(e.userMessage);
        this.store.dispatch(new ClearTaskErrorAktion());
      }

    }
    )


    this.lieferantenService.loadLieferanten(<LieferantService.LieferantQueryParams>{ eagerLoading: 2, queryToken: { friendlyName: 'Tasklist', filter: null } }, true);
      this.selectselectCommondataError$.subscribe(f => {


        if (f.type) {
          if (f.error && f.error.generalResult == GeneralResultEnum.OnlyDebug)
          {
            this.store.dispatch(new LogUsersClientErrorAction({ error: <IClientError>{ userid: null, modul: 'Aufgaben CommonData', error: f.error } }));
          } else
          {
            this.notifier.showError(f.error.message ?? f.error.userMessage);
          }
          this.store.dispatch(new ClearCommonDataErrorAction());
        }
      })

  }


  createTasksForMandanten() {


    this.store.dispatch(new GetMandantenAction({ headerTaskId: this.lastSelectedNode.data.id }));

    const ref = this.dialogService.open(SupplierSelectDialogComponent,
      {
        header: 'Mandanten auswählen',
        width: '64rem',
        height: 'auto',
        data: { aufgabenArt: this.lastSelectedNode.data.aufgabenArt,
          supplierList: this.store.pipe(select(selectAllMandanten)), showLieferantengruppenDropdown: false,
          lieferantType: LieferantTypeEnum.unbekannt
        }
      });

    ref.onClose.subscribe((selectedMandanten: MandantUserDTO[]) => {
      if (selectedMandanten != undefined && selectedMandanten.length > 0) {
        console.log(this.lastSelectedNode);
        // Lieferantentask entsprechen auch Mandaten tasks
        this.store.dispatch(new CreateMandantenTasksAction({ taskModel: this.lastSelectedNode.data, mandanten: selectedMandanten, parentId: this.lastSelectedNode.data.id.toString() }));
      }
    });

  }

  onSelectedOrderTaskOptionChanged(value: string) {
    console.log('onSelectedOrderTaskOptionChanged', value, this.selectedOrderTaskOption);
    this.pageSorting.sortOrder = this.orderTaskOptions.find(g => g.key.toLowerCase() == value.toLowerCase())?.descending ? -1 : 1;
    this.pageSorting.skipRows = 0;
    var p = <TaskQueryParameter>{
      skip: this.pageSorting.skipRows,
      take: this.pageSorting.rowsToLoad,
      onlyUser: this.showOwnTasksOnly,
      orderOption: value,
      descending: this.pageSorting.sortOrder == -1 ? true : false,
      categories: this.selectedCategories
    }
    this.store.dispatch(new GetTasksForCurrentUserAction({ params: p }));
  }

  hideChildMenu(dataRow: TaskUserModel, data: TaskUserModel, childmenu: ContextMenu) {
    this.currentchildMenu.set([]);

  }

  setSelectedTreeItem(dataRow: TaskUserModel, data: TaskUserModel, childmenu: ContextMenu) {
    this.closeOtherOpenMenues(childmenu)
    this.currentchildMenu.set(this.getChildMenu(dataRow));
    this.lastSelectedTask = data;
  }

  hideMainMenue(rowDate: TaskUserModel, data: TaskStructureModel<TaskUserModel>, menu: ContextMenu) {
    this.currentmainMenu.set([]);
  }


  setSelectedTreeNode(rowDate: TaskUserModel, data: TaskStructureModel<TaskUserModel>, menu: ContextMenu) {
    this.closeOtherOpenMenues(menu)
    this.currentmainMenu.set(this.getMainMenu(rowDate));
    this.lastSelectedNode = data;
  }
  /**Nur das aktuell Menur offen lassen
   * alle anderen schliessen
   */
  closeOtherOpenMenues(menu: ContextMenu) {
    if (menu instanceof ContextMenu) {
      if (!menu.el.nativeElement["inputId"]) return;
      if (this.openMenues.filter(f => f.el.nativeElement["inputId"] == menu.el.nativeElement["inputId"]).length == 0)
        this.openMenues.push(menu);

      this.openMenues.forEach(f => {
        if (f.el.nativeElement["inputId"] != menu.el.nativeElement["inputId"]) {
          f.hide();
        }
      });
    }
  }

  createTasksForSuppliersShort(rowDate: any, data: TaskStructureModel<TaskUserModel>) {
    this.setSelectedTreeNode(rowDate, data, null);
    if (data.data?.aufgabenArt && Number(data.data.aufgabenArt) == AufgabenArt.WeitereBedarfe) {
      this.createTasksForMandanten();
    } else {
      this.createTasksForSuppliers();
    }
  }

  duplicateMaster() {
    this.store.pipe(select(selectallSortimente)).pipe(take(1)).subscribe(allsortimtente => {
      var currentsortiment = allsortimtente.find(s => this.lastSelectedNode.data && this.lastSelectedNode.data.sortimentIds && this.lastSelectedNode.data.sortimentIds.length > 0 && s.assortmentId == this.lastSelectedNode.data.sortimentIds[0]);
      if (currentsortiment && this.lastSelectedNode.data.sortimentIds && this.lastSelectedNode.data.sortimentIds.length > 0 && this.lastSelectedNode.data.klammerId && currentsortiment.klammer.assortmentmasterId != this.lastSelectedNode.data.klammerId) {
        this.notifier.showError('Aufgabe "' + this.lastSelectedNode.data.name + '" kann nicht kopiert werden. Das Sortiment passt nicht zur Klammer');
      } else {
        this.store.dispatch(new DuplicateMasterAction({ taskId: this.lastSelectedNode.data.id }))
      }

    }
    )

  }

  duplicateSupplierTask() {
    this.store.pipe(select(selectallSortimente)).pipe(take(1)).subscribe(allsortimtente => {
      var currentsortiment = allsortimtente.find(s => this.lastSelectedTask && this.lastSelectedTask.sortimentIds && this.lastSelectedTask.sortimentIds.length > 0 && s.assortmentId == this.lastSelectedTask.sortimentIds[0]);
      if (currentsortiment && this.lastSelectedTask.sortimentIds && this.lastSelectedTask.sortimentIds.length > 0 && this.lastSelectedTask.klammerId && currentsortiment.klammer.assortmentmasterId != this.lastSelectedTask.klammerId) {
        this.notifier.showError('Aufgabe "' + this.lastSelectedTask.name + '" kann nicht kopiert werden. Das Sortiment passt nicht zur Klammer');
      } else {
        this.store.dispatch(new DuplicateSupplierAction({ taskId: this.lastSelectedTask.id }))
      }
    })
  }

  deleteMasterTask() {
    var sub = this.store.pipe(select(selectTasks), filter(l => l && l.length > 0), map(cloneDeep)).subscribe((c: TaskStructureModel<TaskUserModel>[]) => {
      var childinfo = c.filter(g => g.data.id == this.lastSelectedNode.data.id)[0]?.children?.length;
      this.confirmationService.confirm({
        message: 'Aufgabe "' + this.lastSelectedNode.data.name + '" wirklich löschen?',
        acceptLabel: (Number(childinfo) ? 'Aufgabe und ' + childinfo + ' Lieferanten löschen' : 'Aufgabe löschen'),
        accept: () => { this.deleteTask(this.lastSelectedNode.data); }
      });
    });
    sub.unsubscribe();
  }

  gotoUploadEditorReadonly() {

    this.store.dispatch(new AddNavigationTabAction({ displayName: 'Upload ansehen', url: `list-editor/${this.lastSelectedTask.id}/task` }));
    var filter = <CategoryTaskSelection>{ selectedCategories: this.selectedCategories, onlyUser: this.showOwnTasksOnly, task: this.lastSelectedTask }
    const navigationExtras: NavigationExtras = { state: { filterCategories: filter } };
    this.router.navigate([`list-editor/${this.lastSelectedTask.id}/task`], navigationExtras);


  }
  deleteSupplierTask() {
    this.confirmationService.confirm({
      message: 'Aufgabe "' + this.lastSelectedTask.name + '" wirklich löschen?',
      acceptLabel: 'Aufgabe löschen',
      accept: () => {
        this.lastAction = 3;
        this.deleteTask(this.lastSelectedTask);
      }
    });
  }

  deleteTask(taskModel: TaskUserModel) {
    const url = `${environment.connections.TaskListService}/api/task/DeleteTasks?taskId=` + taskModel.id.toString();
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      }),
      params: { taskId: taskModel.id.toString() }
    };
    let result: ResultObjectModel<number>;

    const sub = this.httpClient.get<ResultObjectModel<number>>(url, { responseType: 'json' }).subscribe(x => {

      if (x.generalResult == 1) //Database Error - nothing worked
      {
        this.notifier.showError('Aufgabe "' + taskModel.name + '" kann nicht gelöscht werden - Datenbankfehler');
        return;
      }

      if (x.generalResult == 3)  //Logical warning - zeigen wir einfach mal so an
      {
        this.notifier.showError(x.userMessage);
        return;
      }
      //Aufgeklappte Nodes holen
      // let expIds: number[] = [];
      // this.tree.value.forEach(x => {
      //   if (x.expanded) expIds.push(x.data.id);
      // });
      this.store.dispatch(new DeleteTaskDoneAction({ taskId: taskModel.id }));

    });
  }

  showSomaOverview(event) {
    const taskHeader = this.lastSelectedNode.data;
    const children = this.lastSelectedNode.children;
    let taskChildren: TaskUserModel[] = [];
    children.forEach(x => taskChildren.push(x.data as TaskUserModel));
    let dlgHeader = 'Aufgaben Details';
    const ref = this.dialogService.open(SomaTaskViewDialogComponent,
      {
        header: dlgHeader,
        width: '1024px',
        height: 'auto',
        data: { header: taskHeader, children: taskChildren }
      });
  }

  createTasksForSuppliers() {

    this.notifier.snackBar.dismiss();

    if (this.lastSelectedNode.data.lieferantType == LieferantTypeEnum.CategoryManager && this.lastSelectedNode.children && this.lastSelectedNode.children.length > 0) {
      this.notifier.showError('Category Captain wurde bereits hinzugefügt');
      return;
    }

    this.store.dispatch(new GetLieferantenUserAction({ headerTaskId: this.lastSelectedNode.data.id, categoryManager: this.lastSelectedNode.data.lieferantType == LieferantTypeEnum.CategoryManager }));


      this.refSupplier = this.dialogService.open(SupplierSelectDialogComponent,
        {
          header: (this.lastSelectedNode.data.lieferantType == LieferantTypeEnum.CategoryManager ?  'Category Captain auswählen' : 'Lieferanten auswählen'),
          width: '64rem',
          height: 'auto',
          data: {
            aufgabenArt: this.lastSelectedNode.data.aufgabenArt,
            supplierList: this.selectLieferantenUser$,
            showLieferantengruppenDropdown: true,
            lieferantType: this.lastSelectedNode.data.lieferantType
          }
        });
      this.refSupplier.onClose.subscribe((selectedSuppliers: LieferantUserDTO[] ) => {
        if (selectedSuppliers != undefined && selectedSuppliers.length > 0) {

          var param = <TaskLieferantParams>
            {
              parentId: this.lastSelectedNode.data.id.toString(),
              lieferantType: this.lastSelectedNode.data.lieferantType,
              taskUserModel: this.lastSelectedNode.data,
              lieferantUsers: selectedSuppliers
            }
          this.store.dispatch(new CreateSupplierTasksAction({ params: param }));
        }
      });

  }

  approveTaskForSupplier() {
    if (this.actionSubscription) this.actionSubscription.unsubscribe();
    this.messageService.clear('successMessage');

    this.store.dispatch(new ApproveTaskAction({ taskId: this.lastSelectedNode.data.id, taskState: 200 }));

    this.actionSubscription = this._actions$.pipe(ofType<ApproveTaskDoneAction>(TaskListActionType.ApproveTaskDone)).subscribe((data: any) => {
      this.messageService.clear('successMessage');
      this.messageService.add({ key: 'successMessage', severity: 'success', summary: 'Aufgabe "' + this.lastSelectedNode.data.name + '" wurde für die Lieferanten freigegeben' });
    });
  }

  selectedAllCategories() {

    if (this.selectedCategories.length == 0 && this.showOwnTasksOnly == false) {
      this.selectedCategories.push(ActionStates.Unknown)
    }


    var p = <TaskQueryParameter>{
      skip: this.pageSorting.skipRows,
      take: this.pageSorting.rowsToLoad,
      onlyUser: this.showOwnTasksOnly,
      orderOption: this.selectedOrderTaskOption,
      categories: this.selectedCategories,
      aufgabenArt: this.selectedAufgabenart?.id,
      descending: false,
    }
    this.store.dispatch(new GetTasksForCurrentUserAction({ params: p }));

  }

  selectSpecificCategory() {

    if (this.selectedCategories.length == 0 && this.showOwnTasksOnly == false) {
      this.selectedCategories.push(ActionStates.Unknown)
    }

    var p = <TaskQueryParameter>{
      skip: this.pageSorting.skipRows,
      take: this.pageSorting.rowsToLoad,
      onlyUser: this.showOwnTasksOnly,
      orderOption: this.selectedOrderTaskOption,
      categories: this.selectedCategories,
      aufgabenArt: this.selectedAufgabenart?.id,
      descending: this.pageSorting.sortOrder == -1 ? true : false,
    }
    this.store.dispatch(new GetTasksForCurrentUserAction({ params: p }));
  }

  openTaskEditDialog(target: string, edit: string) {
    let taskModel: TaskUserModel;
    let children: TaskStructureModel<TaskUserModel>[] = [];
    let dialogHeader: string;
    if (edit == 'new' && target == 'master') {
      taskModel = this.getNewTaskUserModel(this.userId);

      dialogHeader = 'Neue Hauptaufgabe anlegen'
    }
    if (edit == 'edit' && target == 'master') {
      taskModel = this.lastSelectedNode.data;
      children = this.lastSelectedNode.children;
      dialogHeader = 'Hauptaufgabe "' + taskModel.name + '" bearbeiten';
    }
    if (edit == 'edit' && target == 'supplier') {
      taskModel = this.lastSelectedTask;
      children = [];
      dialogHeader = 'Aufgabe "' + taskModel.name + '" für "' + taskModel.organization + '" bearbeiten';
    }

    var filteredSortimente = this.store.pipe(select(selectallSortimente));
    this.store.pipe(select(selectallSortimente)).pipe(take(1)).subscribe(allesortimente => {
      var currtensortiment = allesortimente.find(t => taskModel.sortimentIds && taskModel.sortimentIds.length == 1 && t.assortmentId == taskModel.sortimentIds[0]);
      if (currtensortiment != undefined && currtensortiment.klammer.assortmentmasterId == taskModel.klammerId) {
        filteredSortimente = this.store.pipe(select(selectallSortimente)).pipe(map(x => x.filter(y => y.klammer.assortmentmasterId == taskModel.klammerId)));
      } else  // Klammer und sortiment passen nicht zusammen
      {
        if (taskModel.klammerId && taskModel.sortimentIds && taskModel.sortimentIds.length > 0) {
          this.notifier.showError('Sortiment und Klammer passen nicht zueinander. ');
        }
      }

      var onSaveEmitter = new EventEmitter<any>();


      const ref = this.dialogService.open(TaskEditDialogComponent,
        {
          header: dialogHeader,
          width: '64rem',
          height: 'auto',
          dismissableMask: false,
          data: {
            taskModel: taskModel,
            children: children,
            sortimentsKlammern: this.store.pipe(select(selectallKlammern)),
            sortiments: this.store.pipe(select(selectallSortimente)),
            validationProfiles: this.store.pipe(select(selectValidationProfiles)),
            sortimentsTypen: this.store.pipe(select(selectallTypen)),
            sortimentsToView: filteredSortimente,
            targetType: target,
            editMode: edit,
            onSave: onSaveEmitter,
          }
        });

      ref.onClose.subscribe(() => {
        //nix
        this.notifier.snackBar.dismiss();
      });


      /**Update der Infos, Files etc nach Bearbeitung */
      onSaveEmitter.subscribe((g: TaskUserModel) => {
        if (g) {
          if (this.lastSelectedNode && this.lastSelectedNode.data && this.lastSelectedNode.data.id == g.id) {
            this.lastSelectedNode = cloneDeep(this.lastSelectedNode);
            this.lastSelectedNode.data.uploadFiles = g.uploadFiles;
          }

        }
      })

    })


  }

  addSupplier(rowData: TaskUserModel) {

  }

  loadTaskData(e: LazyLoadEvent) {
    this.pageSorting.skipRows = e.first;
    this.pageSorting.rowsToLoad = e.rows;

    var orderoption = null;
    if (e.sortField) {
      switch (e.sortField) {
        case "name":
          orderoption = "Name";
          this.pageSorting.sortOrder = e.sortOrder;
          break;
        case "aufgabenArt":
          orderoption = "AufgabenArt";
          this.pageSorting.sortOrder = e.sortOrder;
          break;
        case "taskValidRangeFrom":
          orderoption = "Start";
          this.pageSorting.sortOrder = e.sortOrder;
          break;
        case "taskValidRangeTo":
          orderoption = "Ende";
          this.pageSorting.sortOrder = e.sortOrder;
          break;
        case "sortimentName":
          orderoption = "sortimentName";
          this.pageSorting.sortOrder = e.sortOrder;
          break;
        default:
          this.pageSorting.sortOrder = this.pageSorting.sortOrder;
      }
    }
    this.load(orderoption);
  }


  load(orderoption: string) {
    var p = <TaskQueryParameter>{
      skip: this.pageSorting.skipRows,
      take: this.pageSorting.rowsToLoad,
      onlyUser: this.showOwnTasksOnly,
      orderOption: orderoption ?? this.selectedOrderTaskOption,
      categories: this.selectedCategories,
      aufgabenArt: this.selectedAufgabenart?.id,
      descending: this.pageSorting.sortOrder == -1 ? true : false,
    }
    this.store.dispatch(new GetTasksForCurrentUserAction({ params: p }));
  }


  selectAufgabenart() {
    if (this.selectedCategories.length == 0 && this.showOwnTasksOnly == false) {
      this.selectedCategories.push(ActionStates.Unknown)
    }

    var p = <TaskQueryParameter>{
      skip: this.pageSorting.skipRows,
      take: this.pageSorting.rowsToLoad,
      onlyUser: this.showOwnTasksOnly,
      orderOption: this.selectedOrderTaskOption,
      categories: this.selectedCategories,
      aufgabenArt: this.selectedAufgabenart?.id,
      descending: this.pageSorting.sortOrder == -1 ? true : false,
    }
    this.store.dispatch(new GetTasksForCurrentUserAction({ params: p }));
  }

  selectOwnTasks() {
    if (this.selectedCategories.length == 0 && this.showOwnTasksOnly == false) {
      this.selectedCategories.push(ActionStates.Unknown)
    }


    var p = <TaskQueryParameter>{
      skip: this.pageSorting.skipRows,
      take: this.pageSorting.rowsToLoad,
      onlyUser: this.showOwnTasksOnly,
      orderOption: this.selectedOrderTaskOption,
      categories: this.selectedCategories,
      aufgabenArt: this.selectedAufgabenart?.id,
      descending: this.pageSorting.sortOrder == -1 ? true : false,
    }
    this.store.dispatch(new GetTasksForCurrentUserAction({ params: p }));

  }

  openUpload(task: TaskUserModel) {
    if (task.selectedValidationId > 0) {
      this.store.dispatch(new AddNavigationTabAction({ displayName: 'Upload bearbeiten', url: `list-editor/${task.id}/task` }));
      var filter = <CategoryTaskSelection>{ selectedCategories: this.selectedCategories, onlyUser: this.showOwnTasksOnly, task: task }
      const navigationExtras: NavigationExtras = { state: { filterCategories: filter } };
      this.router.navigate([`list-editor/${task.id}/task`], navigationExtras);
    }
  }



  getPartner(lastname: string, firstname: string): string {
    let result = '';
    if (lastname != null) result = lastname + ' ';
    if (firstname != null) result = result + firstname;
    return result;
  }

  getUploadState(childList): { name: string, value: number }[] {
    const noUpload = childList.filter(x => x.data.uploadState == null).length as number;
    const onGoing = childList.filter(x => x.data.uploadState == 0).length as number;
    const doneByClient = childList.filter(x => x.data.uploadState == 20).length as number;
    const transfered = childList.filter(x => x.data.uploadState == 30).length as number;
    const noUploadObj = { name: 'Nicht begonnen', value: noUpload };
    const onGoingObj = { name: 'Erstellung', value: onGoing };
    const doneObj = { name: 'Fertiggestellt', value: doneByClient };
    const transObj = { name: 'Übermittelt', value: transfered };
    return [noUploadObj, onGoingObj, doneObj, transObj];
  }

  getTaskCount(childList, state): number {
    if (childList == undefined) return 0;
    switch (state) {
      case "created":
        return childList.filter(x => x.data.taskState == 100).length as number;
        break;
      case "new":
        return childList.filter(x => x.data.taskState == 200).length as number;
        break;
      case "ongoing":
        return childList.filter(x => x.data.taskState == 300).length as number;
        break;
      case "done":
        return childList.filter(x => x.data.taskState == 400).length as number;
        break;
      case "transfered":
        return childList.filter(x => x.data.taskState == 500).length as number;
    }
  }

  getNewTaskUserModel(userid: string): TaskUserModel {
    const result: TaskUserModel = {
      name: '',
      email: '',
      organization: '',
      position: '',
      id: -1,
      groupId: -1,
      userId: null,
      isReadOnly: 0,
      isReadOnlyMessage: null,
      isEditExpired: 0,
      isEditExpiredMessage: null,
      min: 1,
      max: 1000,
      taskInfo: '',
      taskDescription: '',
      taskValidRange: Date[2],
      taskValidRangeFrom: null,
      taskValidRangeTo: null,
      taskActiveRange: Date[2],
      taskActiveRangeFrom: null,
      taskActiveRangeUntil: null,
      sendInvite: false,
      invitationDate: null,
      sendInviteOne: false,
      sendInviteTwo: false,
      sendInviteThree: false,
      dateInviteOne: null,
      dateInviteThree: null,
      dateInviteTwo: null,
      selectedValidation: '',
      selectedValidationId: null,
      includeUpload: false,
      loadSnapshot: false,
      selected: false,
      taskParentId: null,
      creatorFriendlyName: null,
      creatorMail: null,
      creatorToken: userid,
      uploadStagingId: null,
      uploadState: 0,
      uploadFiles: { parentId: 0, userId: '', files: [] },
      fileUrls: [],
      firstName: null,
      lastName: null,
      fullName: null,
      gender: null,
      sortimentName: null,
      sortimentIds: [],
      typeInfoId: null,

      targetUserId: null,
      color: null,
      klammerName: null,
      klammerId: null,
      taskState: 100,
      taskType: 0,
      einkaufId: null,
      aufgabenArt: AufgabenArt.Artikelliste,
      mandantId: null,
      lieferantId: null,
      lieferantType: LieferantTypeEnum.Lieferant
    };
    return result;
  }

  getUserTaskClass(pre: string, rowData: TaskUserModel) {
    if (rowData.isEditExpired === 1) {
      return `${pre}-disabled`;
    } else {
      return pre;
    }
  }

  getUserTaskButtonClass(def: string, rowData: TaskUserModel) {
    if (rowData.isEditExpired === 1) {
      return 'p-button-disabled';
    } else {
      return def;
    }
  }
}
