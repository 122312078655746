import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { featureName, reducer, ListOverViewEffects} from '@app/+list-overview/store';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
    imports: [
        StoreModule.forFeature(featureName, reducer),
        EffectsModule.forFeature([ListOverViewEffects])
    ]
})
export class ListOverviewStateModule { }
