import { Component, OnInit } from '@angular/core';
import { ValidationList } from '@app/common/models';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-editvalidationlist-view',
    templateUrl: 'editValidationList.dialog.component.html',
    styleUrls:  ['./dialogs.component.scss'],
})

//obsolet 
export class EditValidationListDialogComponent implements OnInit {

    validationListToView: ValidationList;

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig
    ) {}

    ngOnInit() {

        this.validationListToView = this.config.data.validationListToView;

    }

    close() {
      this.validationListToView.changedAt = new Date();
      this.ref.close(this.validationListToView);
    }

    cancel() {
        this.ref.close();
    }

}
