
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HubConnectionBuilder, LogLevel, HubConnection, IHttpConnectionOptions } from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/internal/operators/filter';
import { IAuthUser } from '../interfaces/Interfaces';
import { map } from 'rxjs/operators';
import * as signalR from '@microsoft/signalr';
import { SignalModel, SendMessageTypeContainer, SignalMessageContentModel } from '../models/signal.model';
import { environment } from '@env/environment';
import { AddNotificationAction, RemoveNotificationAction } from '@app/+notifications/store';




export enum SignalSendCommand {
  //Signalservice startet
  SignalServiceStart = "SignalServiceStart",
  ///Benachrichtung wegen Medientipp
  SendMediatipMessage = "SendMediatipMessage",
  ///Benachrichtung wegen Artikeldaten
  SendArtikeldatenMessage = "SendArtikeldatenMessage",

  RemoveMediatipMessage = "RemoveMediatipMessage",
  RemoveArtikeldatenMessage = "RemoveArtikeldatenMessage",
  /**filialmatrix Modul für Sortiment */
  SendModulSortimentMessage = "SendModulSortimentMessage",
  RemoveModulSortimentMessage = "RemoveModulSortimentMessage",

  SendModulZuordnungMessage = "SendModulZuordnungMessage",
  RemoveModulZuordnungMessage = "RemoveModulZuordnungMessage",


  SendSortimentMessage = "SendSortimentMessage",
  RemoveSortimentMessage = "RemoveSortimentMessage",

  SendAenderungshistorieMessage = "SendAenderungshistorieMessage",
  RemoveAenderungshistorieMessage = "RemoveAenderungshistorieMessage"


}

/**Service fr das weiterleiten vom Server    */
@Injectable()
export class SignalRService {

  /**Connection zum Server zu lipohub */
  hubConnection: HubConnection;
  user: IAuthUser;

  /**init signalR Hub */
  init(authservice: AuthService) {

    authservice.getLipoUser().subscribe(user => {
      this.user = user;
      var url = `${environment.connections.SignalService}/lipohub`;
      var options = {
        // transport: signalR.HttpTransportType.WebSockets | signalR.HttpTransportType.LongPolling     ,
        accessTokenFactory: () => this.getBearerToken(),
      } as IHttpConnectionOptions

      // Default //.withAutomaticReconnect([0, 2000, 10000, 30000])
      let members: string = user.member_of instanceof Array ? user.member_of.join(";") : user.member_of;
      this.hubConnection = new HubConnectionBuilder()
        .withHubProtocol(new signalR.JsonHubProtocol())
        .withUrl(`${url}?userid=${user.id}&member_of=${members}`, options)
        .withAutomaticReconnect([60000])
        .configureLogging(environment.production ? LogLevel.None : LogLevel.Debug)
        .build();

      this.hubConnection.keepAliveIntervalInMilliseconds = 2000 * 120 * 3;
      this.hubConnection.serverTimeoutInMilliseconds = 2000 * 120 * 6;

      var start = this.hubConnection.start();
      start.then(g => {
        console.info("hub starts");
        this.hookEvents(this.hubConnection);
      }).catch(e => {
        console.error("hub start error " + JSON.stringify(e));
      })
    })
  }

  /**Token des Users */
  getBearerToken(): string {
    return this.authservice.getAccessToken();
  }

  /** Events die durch signal weitergereicht werden oder in den store geschrieben werden. */
  hookEvents(connection: HubConnection) {
    try {
      connection.on(SignalSendCommand.SignalServiceStart, (details: SignalModel<string>) => {
        console.debug(JSON.stringify(details));
      });

      connection.on(SignalSendCommand.SendMediatipMessage, (signal: SignalModel<SendMessageTypeContainer<SignalMessageContentModel>>) => {
        this.store.dispatch(new AddNotificationAction({ signal: signal.data }));
      });

      connection.on(SignalSendCommand.SendArtikeldatenMessage, (signal: SignalModel<SendMessageTypeContainer<SignalMessageContentModel>>) => {
        this.store.dispatch(new AddNotificationAction({ signal: signal.data }));
      });

      connection.on(SignalSendCommand.RemoveMediatipMessage, (signal: SignalModel<SendMessageTypeContainer<SignalMessageContentModel>>) => {
        this.store.dispatch(new RemoveNotificationAction({ signal: signal.data }));
      });

      connection.on(SignalSendCommand.RemoveArtikeldatenMessage, (signal: SignalModel<SendMessageTypeContainer<SignalMessageContentModel>>) => {
        this.store.dispatch(new RemoveNotificationAction({ signal: signal.data }));
      });

      connection.on(SignalSendCommand.SendModulSortimentMessage, (signal: SignalModel<SendMessageTypeContainer<SignalMessageContentModel>>) => {
        this.store.dispatch(new AddNotificationAction({ signal: signal.data }));
      });

      connection.on(SignalSendCommand.RemoveModulSortimentMessage, (signal: SignalModel<SendMessageTypeContainer<SignalMessageContentModel>>) => {
        this.store.dispatch(new RemoveNotificationAction({ signal: signal.data }));
      });

      connection.on(SignalSendCommand.SendModulZuordnungMessage, (signal: SignalModel<SendMessageTypeContainer<SignalMessageContentModel>>) => {
        this.store.dispatch(new AddNotificationAction({ signal: signal.data }));
      });

      connection.on(SignalSendCommand.RemoveModulZuordnungMessage, (signal: SignalModel<SendMessageTypeContainer<SignalMessageContentModel>>) => {
        this.store.dispatch(new RemoveNotificationAction({ signal: signal.data }));
      });

      connection.on(SignalSendCommand.SendSortimentMessage, (signal: SignalModel<SendMessageTypeContainer<SignalMessageContentModel>>) => {
        this.store.dispatch(new AddNotificationAction({ signal: signal.data }));
      });

      connection.on(SignalSendCommand.RemoveSortimentMessage, (signal: SignalModel<SendMessageTypeContainer<SignalMessageContentModel>>) => {
        this.store.dispatch(new RemoveNotificationAction({ signal: signal.data }));
      });

      connection.on(SignalSendCommand.SendAenderungshistorieMessage, (signal: SignalModel<SendMessageTypeContainer<SignalMessageContentModel>>) => {
        this.store.dispatch(new AddNotificationAction({ signal: signal.data }));
      });

      connection.on(SignalSendCommand.RemoveAenderungshistorieMessage, (signal: SignalModel<SendMessageTypeContainer<SignalMessageContentModel>>) => {
        this.store.dispatch(new RemoveNotificationAction({ signal: signal.data }));
      });



    } catch (e) {
      console.debug("Signal could not hook on Events -> " + e);
    }
  }

  constructor(private authservice: AuthService, private store: Store<any>) {
  }

}

