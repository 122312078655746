import { selectListOverviewState } from './state';
import { createSelector } from '@ngrx/store';

export const selectLists = createSelector(selectListOverviewState, s => s.lists);

export const selectCount = createSelector(selectListOverviewState, x => x.total);

export const selectPageSize = createSelector(selectListOverviewState, x => x.pagesize);

export const selectTabFlag = createSelector(selectListOverviewState, x => x.tabFlag);

export const selectLoading = createSelector(selectListOverviewState, s => s.loading);
