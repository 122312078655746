import { StatusModel } from './status.model';
import { TaskUserModel } from './task-user.model';



export interface TaskModel {
    id?: number;
    name: string;
    dateFrom: Date;
    dateUntil: Date;
    status: StatusModel;
    from: string;
    to: string[];
    users: TaskUserModel[];
    category: string;
    description: string;
    validationProfile: string;
    messageId?: number;
    hasMessage?: boolean;
    hasUploads: boolean;
    uploadId: number;
    creatorId: number;
    minimum: number;
    maximum: number;
    taskParentId: number;
}
