<span>
    <hr>
    <div class="ui-g ui-fluid">
        <div class="ui-g-12 ui-md-12">

            <h5>{{ breadcrumb$ | async }}</h5>
            <hr />
            <h4>{{ taskToView.name }}</h4>

            <br>

            <b>Von:</b> {{ taskToView.creatorFriendlyName }} <b>An:</b> {{ taskToView.fullName }}<br><br>
            <b>Beschreibung:</b> <div [innerHTML] = "convertCarriageReturn(taskToView.taskDescription)"></div>
            <hr>
            <b>Sortiment:</b> {{taskToView.sortimentName}} <br>
            <b>Bearbeitungszeitraum:</b> {{ taskToView.taskValidRangeFrom | date }} bis {{ taskToView.taskValidRangeTo | date }}<br>
            <b>Laufzeit:</b> {{ taskToView.taskActiveRangeFrom | date }} bis {{ taskToView.taskActiveRangeUntil | date }}<br>
            <hr>
            <div *ngIf="taskToView.fileUrls !== null && taskToView.fileUrls.length > 0">
                <table>
                    <tr *ngFor="let fileUrl of taskToView.fileUrls">
                        <td><a href="#" (click)="downloadFile(fileUrl.url, fileUrl.fileType, fileUrl.fileName)">{{fileUrl.fileName}}</a></td>
                    </tr>
                </table>
            </div>
            <div><a href="#" (click)="downloadListForTask()">Excel-Vorlage laden</a></div>
        </div>
    </div>
</span>
<div #tempFile>&emsp;</div>
