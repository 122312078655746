import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonDataStateModule } from './common-data-state.module';

import { NovitaetenLookupService } from './services/NovitaetenLookupService';
import { StichwortTreeService } from './services/StichwortTreeService';
import { NoviProcessService } from '@app/common/services/noviProcess.service';
import { CustomSettingsService } from '@app/common/services/custom.settings.service';
import { StatistikService } from './services/StatistikService';
import { MandantenService } from './services/MandantenService';
import { LieferantenService } from './services/LieferantenService';
import { SettingService } from '@app/common/services/setting.service';

/**Dieses Module ist gedacht für statische Daten, die sich nicht ändern und in Dropdowns angezeigt werden
 * oder nur sehr selten z.b. sortimentsliste, User-listen, Warengruppen etc.
 * bzw für die Novitäten Lookups
 * keine Componenten hier vorgesehen !
  */
@NgModule({
  providers:[NovitaetenLookupService, StichwortTreeService, NoviProcessService, CustomSettingsService, StatistikService,
    MandantenService, LieferantenService, SettingService ],
  imports: [
    CommonModule,
    CommonDataStateModule
   ],
})
export class CommonDataModule { }
