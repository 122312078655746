import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { featureName, reducer, ListEditorEffects} from '@app/+list-editor/store';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
    imports: [
        StoreModule.forFeature(featureName, reducer),
        EffectsModule.forFeature([ListEditorEffects])
    ]
})
export class ListEditorStateModule { }