import { Component, OnInit, OnDestroy } from '@angular/core';
import { SignalMessageService } from '@app/common/services/signalmessage.service';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Message } from 'primeng/api';
import { Store, select } from '@ngrx/store';
import { MessageTypeEnum, MessageParamter, SignalMessageContentModel, SendMessageTypeContainer, SendMessageObject, MessageStatusEnum } from '@app/common/models/signal.model';

import { LipoMessageService } from '@app/common/services/LipoMessageService';
import { DatePipe } from '@angular/common';
import {  LogUsersClientErrorAction } from '@app/store/user/actions';
import { IClientError } from '@app/common/interfaces/Interfaces';
import { GetNotificationsAction } from '@app/+notifications/store';
import { selectallNotifications } from '@app/+notifications/store/selectors';


export interface INewMessage { id: number, message: string; }
/**Nachrichten Dialog */
@Component({
  selector: 'app-message-edit-dialog',
  templateUrl: './message-edit-dialog.component.html',
  styleUrls: ['./message-edit-dialog.component.scss'],
  providers: [SignalMessageService]
})
export class MessageEditDialogComponent implements OnInit, OnDestroy {
  messageparamter: MessageParamter;
  // @ts-ignore
  selectMessages$ = this.store.pipe(select(selectallNotifications));
  notifications = new Array<SendMessageTypeContainer<SignalMessageContentModel>>();
  newMessages = new Array<INewMessage>();
  firstMessage = "";
  datepipe = new DatePipe("DE");
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public store: Store<any>,
    private signalmessageService: SignalMessageService,
    private lipomessageService: LipoMessageService<Message>
  ) {
  }
  ngOnDestroy(): void {
    this.notifications.forEach(item => {
      if (item.messages.filter(t => t.messageStatus == MessageStatusEnum.New).length > 0) {
        //TODO: SH Als Store aufruf
        this.signalmessageService.updateMessageStatus(this.messageparamter.targetUserId,
          item.id, 2).subscribe(t => {
            //ok
            // this.store.dispatch(new GetMediatipNotificationsAction({ targetUserId: this.messageparamter.targetUserId.toString(), messageType: this.messageparamter.type }));
          }, e => {
            console.debug(e);
          });
      }
    });
  }

  cancel(): void {

    this.ref.close();
  }

  hasValidNotifications(): boolean {
    if (!this.notifications) return false;
    return this.notifications && this.notifications.length > 0 && this.notifications.filter(t => t.messages.length > 0).length > 0;
  }

  GetSenderName(item: SendMessageTypeContainer<SignalMessageContentModel>): string {
    var corresponduser = this.messageparamter.correspondUserName;
    if (corresponduser) return corresponduser;
    return "Chat";
  }

  /**Sendedatum holen */
  getSendDate(message: SendMessageObject<SignalMessageContentModel> ) : any
  {
    if (!message.sendDate)  return "";
    var today = new Date();
    today.setHours(0,0,0,0);
    var messdate = new Date(message.sendDate);
    messdate.setHours(0,0,0,0);
    if (messdate.getDate() == today.getDate()) return   this.datepipe.transform(message.sendDate, 'HH:mm'  );
    return   this.datepipe.transform(message.sendDate, 'dd.MM.yyyy HH:mm'  );
  }

  getBoxClass(item: SendMessageObject<SignalMessageContentModel>) : string
  {
    var classstyle = (item.targetUserId == this.messageparamter.targetUserId ? "leftBoxClass" : "rightBoxClass");
    if (item.messageStatus == MessageStatusEnum.New && item.targetUserId == this.messageparamter.targetUserId )
    {
        classstyle+= " newClass ";
    }
    classstyle+= " container ";
    return classstyle;
  }



  /**Style des textes je nach Empfänger oder Sender */
  getTextClass(item: SendMessageObject<SignalMessageContentModel>): string {
    var classstyle = (item.targetUserId == this.messageparamter.targetUserId ? "leftTextClass" : "rightTextClass");
    return classstyle;

  }

  /**Neues Feld erweitern bei Input */
  autoGrow(oField) {
    try {
      if (oField.srcElement.scrollHeight > oField.srcElement.clientHeight) {
        oField.srcElement.style.height = oField.srcElement.scrollHeight + "px";
      }
    } catch (e) {
      this.store.dispatch(new LogUsersClientErrorAction({ error: <IClientError>{ userid: "", modul: 'EditMessage', error: e } }));
    }
  }

  /**Row Anzahl berechnen für neue Nachricht */
  getMessageRows(mess : string): number {

    if (!mess) return 2;
    var linecount = 0;
    linecount = mess.split("\n").length
    return linecount + 1;
  }

  getNewMessageTitle(item: any): string {
    return "Neue Nachricht";
  }

  //TODO: Title
  getMessageTitle(item: SendMessageObject<SignalMessageContentModel>): string {
    return "Nachricht";
  }


  getMessage(item: any): string {
    return this.newMessages.filter(t => t.id == item.id)[0].message
  }
  setMessage(item: any, event: any): void {
    var index = this.newMessages.findIndex(t => t.id == item.id);
    if (index > -1) {
      this.newMessages[index].message = event.target.value;
    }
  }

  canSend(item: any): boolean {
    var mess = this.newMessages.filter(t => t.id == item.id)[0];
    return mess && mess.message.length > 0;
  }
  send(item: SendMessageTypeContainer<SignalMessageContentModel>): void {
    var newMessage = this.newMessages.filter(t => t.id == item.id)[0];
    if (newMessage == undefined) return;
    var target = item.creatingUserId == this.messageparamter.targetUserId ? item.targetUserId : item.creatingUserId; // an den anderen
    var source = this.messageparamter.targetUserId; // this User

    var messageobject = new Array<SendMessageObject<SignalMessageContentModel>>();
    messageobject.push(<SendMessageObject<SignalMessageContentModel>>{
      id: 0,
      messageTypeId: 0,
      targetUserId: target,
      sendDate: new Date(),
      messageStatus: MessageStatusEnum.New,
      sourceUserId: source,
      data: <SignalMessageContentModel>{ message: newMessage.message }
    });

    var messcontainer = <SendMessageTypeContainer<SignalMessageContentModel>>{
      id: 0,
      messageType: MessageTypeEnum.Medientipp,
      parentId: this.messageparamter.parentId,
      targetUserId: target,
      creatingUserId: source,           // initialer User
      messages: messageobject,
    }
    this.signalmessageService.SendMessage<SignalMessageContentModel>(messcontainer).subscribe(() => {
      this.store.dispatch(new GetNotificationsAction({ targetUserId: source.toString(), messageType: this.messageparamter.type }));
      this.newMessages = new Array<INewMessage>();
    }, e => {
      this.store.dispatch(new LogUsersClientErrorAction({ error: <IClientError>{ userid: item?.creatingUserId, modul: 'Message-Edit-Dialog', error: e } }));
    })

  }

  sendFirstMessage(first: string): void {
    var target = this.messageparamter.correspondUserId;
    var source = this.messageparamter.targetUserId; // this User

    var messageobject = new Array<SendMessageObject<SignalMessageContentModel>>();
    messageobject.push(<SendMessageObject<SignalMessageContentModel>>{
      id: 0,
      messageTypeId: 0,
      targetUserId: target,
      sendDate: new Date(),
      messageStatus: MessageStatusEnum.New,
      sourceUserId: source,
      data: <SignalMessageContentModel>{ message: first }
    });

    var messcontainer = <SendMessageTypeContainer<SignalMessageContentModel>>{
      id: 0,
      messageType: MessageTypeEnum.Medientipp,
      parentId: this.messageparamter.parentId,
      targetUserId: target,
      creatingUserId: source,           // initialer User
      messages: messageobject,
    }
    this.signalmessageService.SendMessage<SignalMessageContentModel>(messcontainer).subscribe(() => {
      this.store.dispatch(new GetNotificationsAction({ targetUserId: target.toString(), messageType: this.messageparamter.type }));
      this.firstMessage = "";
    }, e => {
      this.lipomessageService.Publish({ key: 'mediatiptoast', severity: "error", summary: "Fehler Nachricht schicken ", detail: JSON.stringify(e) });
    })

  }

  ngOnInit(): void {
    try {
      this.messageparamter = this.config.data.p as MessageParamter;
    //  this.store.dispatch(new GetMediatipNotificationsAction({ targetUserId: this.messageparamter.targetUserId, messageType: this.messageparamter.type }));
      this.selectMessages$.subscribe(messages => {
        this.notifications = (this.messageparamter.parentId != undefined ? messages.filter(t => t.parentId == this.messageparamter.parentId) :
        messages.filter(t => t.messageType == MessageTypeEnum.Unabhängig)
        );
        this.newMessages = new Array<INewMessage>();

         this.notifications.forEach(n => {
           if (this.newMessages.filter(t=> t.id == n.id).length == 0)
           {
            this.newMessages.push(<INewMessage>{ id: n.id, message: "" });
           }
          })

      });
    } catch (e) {
      this.store.dispatch(new LogUsersClientErrorAction({ error: <IClientError>{ userid: "unkonwn", modul: 'MessageEditDialog', error: e } }));
    }
  }

}
