import { GruppeDto, KlammerDto, SortimentDto, SortimentsStatus, TypDto, UserDto, ZuordnungDto } from "@app/api/filialmatrix/models";
import { LiPoContact } from "../lipocontact.model";
import { KlammerDtoClient } from "./KlammerDtoClient";
import { TypDtoClient } from "./SortimentstypenDtoClient";
import { NoviProcessRoutingModule } from "@app/+noviprocess/noviprocess.routing.module";

export class SortimentDtoClient   {



  static toServerDTO(item: SortimentDtoClient): SortimentDto {
    var sortimentserver = <SortimentDto>{};
    sortimentserver.id = item.id ? Number(item.id) : -1;
    sortimentserver.children = item.children;
    sortimentserver.group = item.group;
    sortimentserver.isVisible = item.isVisible;
    sortimentserver.isVisibleToSuppliers = item.isVisibleToSuppliers;
    sortimentserver.klammer = item.klammer;
    sortimentserver.hauptkategorie = item.hauptkategorie;

    sortimentserver.user = item.managers.map(g=> <UserDto> { id: Number(g.id), friendlyName: g.friendlyName });

    sortimentserver.module = item.module;
    sortimentserver.name = item.name;
    sortimentserver.start = item.start != undefined ? item.start.getFullYear() + "-" + (item.start.getMonth() + 1).toString() + "-" + item.start.getDate() : null;
    sortimentserver.stop = item.stop != undefined ? item.stop.getFullYear() + "-" + (item.stop.getMonth() + 1).toString() + "-" + item.stop.getDate() : null;
    sortimentserver.status = item.status;
    sortimentserver.sortNumber = item.sortNumber;
    sortimentserver.typ = item.typ;
    sortimentserver.assignment = item.assignment;


    return sortimentserver;


  }


  assignment: ZuordnungDto;
  children?: Array<SortimentDto>;
  group: GruppeDto;
  id: number;
  isVisible: boolean;
  isVisibleToSuppliers: boolean;
  klammer: KlammerDtoClient;
  managers: LiPoContact[];
  module?: Array<number>;
  name: string;
  sortNumber: number;
  start: Date;
  status: SortimentsStatus;
  stop: Date;
  typ?: TypDtoClient;
  isDeleted: boolean = false;

  user: UserDto[];
  hauptkategorie: boolean;

  constructor(d: SortimentDto) {

    this.id = d.id;
    this.children = d.children;
    this.group = d.group;
    this.isVisible = d.isVisible;
    this.isVisibleToSuppliers = d.isVisibleToSuppliers;
    this.klammer = new KlammerDtoClient(d.klammer);
    this.managers = d.user.map(g=> <LiPoContact>{ id: g.id.toString(), friendlyName: g.friendlyName });
    this.module = d.module;
    this.name = d.name;
    this.start = d.start != undefined ? new Date(d.start) : null;
    this.stop = d.stop != undefined ? new Date(d.stop) : null;
    this.status = d.status;
    this.sortNumber = d.sortNumber;
    this.typ = new TypDtoClient(d.typ);
    this.assignment = d.assignment;

    if (this.klammer == undefined) {
      this.klammer = <KlammerDtoClient>{ id: null, name: '-' };
    }
    if (this.group == undefined) {
      this.group = <GruppeDto>{ id: null, name: '-' };
    }
    if (this.assignment == undefined) {
      this.assignment = <ZuordnungDto>{ id: null, name: '-' };
    }
    if (this.typ == undefined) {
      this.typ = <TypDtoClient>{ id: null, name: '' };
    }
    if (this.managers == undefined) {
      this.managers = new Array<LiPoContact>();
    }
    this.user = d.user;
    this.isDeleted = false;
    this.hauptkategorie = d.hauptkategorie;
  }

  /**Original Id */
  get assortmentId() : string {
      if (this.id == undefined) return null;
      const min = "9030000000000000000";
      if (Number(this.id) < Number(min))
    {
      var result =   min.substring(0,min.length -this.id.toString().length) + this.id.toString();
      return result;
    }
      return this.id.toString();
  }
}
