/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfSortimentModuleDto } from '../models/list-response-args-of-sortiment-module-dto';
import { QueryTokenDTO } from '../models/query-token-dto';
import { SortimentModuleDto } from '../models/sortiment-module-dto';
@Injectable({
  providedIn: 'root',
})
class SortimentModulService extends __BaseService {
  static readonly SortimentModulQuerySortimentModulePath = '/sortiment/module/s';
  static readonly SortimentModulCreateSortimentModulePath = '/sortiment/{sortimentId}/module';
  static readonly SortimentModulCreateZuordnungModulePath = '/zuordnung/{zuordnungId}/module';
  static readonly SortimentModulDeleteSortimentModulePath = '/sortiment/module/{id}';
  static readonly SortimentModulUpdateSortimentModulePath = '/sortiment/module/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Sortiment/Zuordnung Module Verknüpfung
   * @param params The `SortimentModulService.SortimentModulQuerySortimentModuleParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `onePerAssignment`: False: Alle per Sortimentszuordnung verknüpfte Sortimente sind einzeln in der Ergebnisliste enthalten.
   *   True: Je Sortimentszordnung nur ein Eintrag in der Ergebnisliste.
   *
   * - `eagerLoading`:
   */
  SortimentModulQuerySortimentModuleResponse(params: SortimentModulService.SortimentModulQuerySortimentModuleParams): __Observable<__StrictHttpResponse<ListResponseArgsOfSortimentModuleDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.onePerAssignment != null) __params = __params.set('onePerAssignment', params.onePerAssignment.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sortiment/module/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfSortimentModuleDto>;
      })
    );
  }
  /**
   * Sortiment/Zuordnung Module Verknüpfung
   * @param params The `SortimentModulService.SortimentModulQuerySortimentModuleParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `onePerAssignment`: False: Alle per Sortimentszuordnung verknüpfte Sortimente sind einzeln in der Ergebnisliste enthalten.
   *   True: Je Sortimentszordnung nur ein Eintrag in der Ergebnisliste.
   *
   * - `eagerLoading`:
   */
  SortimentModulQuerySortimentModule(params: SortimentModulService.SortimentModulQuerySortimentModuleParams): __Observable<ListResponseArgsOfSortimentModuleDto> {
    return this.SortimentModulQuerySortimentModuleResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfSortimentModuleDto)
    );
  }

  /**
   * Create Sortiment/Zuordnung Module Verknüpfung
   * @param params The `SortimentModulService.SortimentModulCreateSortimentModuleParams` containing the following parameters:
   *
   * - `sortimentId`: Sortiment Id
   *
   * - `payload`:
   *
   * - `user`: User Id oder Token
   */
  SortimentModulCreateSortimentModuleResponse(params: SortimentModulService.SortimentModulCreateSortimentModuleParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sortiment/${encodeURIComponent(String(params.sortimentId))}/module`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Create Sortiment/Zuordnung Module Verknüpfung
   * @param params The `SortimentModulService.SortimentModulCreateSortimentModuleParams` containing the following parameters:
   *
   * - `sortimentId`: Sortiment Id
   *
   * - `payload`:
   *
   * - `user`: User Id oder Token
   */
  SortimentModulCreateSortimentModule(params: SortimentModulService.SortimentModulCreateSortimentModuleParams): __Observable<null> {
    return this.SortimentModulCreateSortimentModuleResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Create Sortiment/Zuordnung Module Verknüpfung
   * @param params The `SortimentModulService.SortimentModulCreateZuordnungModuleParams` containing the following parameters:
   *
   * - `zuordnungId`: Sortiment Id
   *
   * - `payload`:
   *
   * - `user`: User Id oder Token
   */
  SortimentModulCreateZuordnungModuleResponse(params: SortimentModulService.SortimentModulCreateZuordnungModuleParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/zuordnung/${encodeURIComponent(String(params.zuordnungId))}/module`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Create Sortiment/Zuordnung Module Verknüpfung
   * @param params The `SortimentModulService.SortimentModulCreateZuordnungModuleParams` containing the following parameters:
   *
   * - `zuordnungId`: Sortiment Id
   *
   * - `payload`:
   *
   * - `user`: User Id oder Token
   */
  SortimentModulCreateZuordnungModule(params: SortimentModulService.SortimentModulCreateZuordnungModuleParams): __Observable<null> {
    return this.SortimentModulCreateZuordnungModuleResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Delete Sortiment/Zuordnung Module Verknüpfung
   * @param params The `SortimentModulService.SortimentModulDeleteSortimentModuleParams` containing the following parameters:
   *
   * - `id`: Parameter Id
   *
   * - `user`: User Id oder Token
   */
  SortimentModulDeleteSortimentModuleResponse(params: SortimentModulService.SortimentModulDeleteSortimentModuleParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/sortiment/module/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete Sortiment/Zuordnung Module Verknüpfung
   * @param params The `SortimentModulService.SortimentModulDeleteSortimentModuleParams` containing the following parameters:
   *
   * - `id`: Parameter Id
   *
   * - `user`: User Id oder Token
   */
  SortimentModulDeleteSortimentModule(params: SortimentModulService.SortimentModulDeleteSortimentModuleParams): __Observable<null> {
    return this.SortimentModulDeleteSortimentModuleResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update Sortiment/Zuordnung Module Verknüpfung
   * @param params The `SortimentModulService.SortimentModulUpdateSortimentModuleParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `id`:
   *
   * - `user`:
   */
  SortimentModulUpdateSortimentModuleResponse(params: SortimentModulService.SortimentModulUpdateSortimentModuleParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/sortiment/module/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update Sortiment/Zuordnung Module Verknüpfung
   * @param params The `SortimentModulService.SortimentModulUpdateSortimentModuleParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `id`:
   *
   * - `user`:
   */
  SortimentModulUpdateSortimentModule(params: SortimentModulService.SortimentModulUpdateSortimentModuleParams): __Observable<null> {
    return this.SortimentModulUpdateSortimentModuleResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module SortimentModulService {

  /**
   * Parameters for SortimentModulQuerySortimentModule
   */
  export interface SortimentModulQuerySortimentModuleParams {
    queryToken: QueryTokenDTO;

    /**
     * False: Alle per Sortimentszuordnung verknüpfte Sortimente sind einzeln in der Ergebnisliste enthalten.
     * True: Je Sortimentszordnung nur ein Eintrag in der Ergebnisliste.
     */
    onePerAssignment?: boolean;
    eagerLoading?: number;
  }

  /**
   * Parameters for SortimentModulCreateSortimentModule
   */
  export interface SortimentModulCreateSortimentModuleParams {

    /**
     * Sortiment Id
     */
    sortimentId: number;
    payload: SortimentModuleDto;

    /**
     * User Id oder Token
     */
    user?: null | string;
  }

  /**
   * Parameters for SortimentModulCreateZuordnungModule
   */
  export interface SortimentModulCreateZuordnungModuleParams {

    /**
     * Sortiment Id
     */
    zuordnungId: number;
    payload: SortimentModuleDto;

    /**
     * User Id oder Token
     */
    user?: null | string;
  }

  /**
   * Parameters for SortimentModulDeleteSortimentModule
   */
  export interface SortimentModulDeleteSortimentModuleParams {

    /**
     * Parameter Id
     */
    id: number;

    /**
     * User Id oder Token
     */
    user?: null | string;
  }

  /**
   * Parameters for SortimentModulUpdateSortimentModule
   */
  export interface SortimentModulUpdateSortimentModuleParams {
    payload: SortimentModuleDto;
    id: number;
    user?: null | string;
  }
}

export { SortimentModulService }
