<div class="container-fluid">
  <div class="grid" style="padding-top:0.5rem">
      <mat-icon class="pageTitleIcon material-icons-outlined">today</mat-icon>
      <h2 class="pageTitleHeader">Kalender</h2>
  </div>


 <div class="wrapper">
  <div class="overlay" *ngIf="isLoading()">
    <div class='parent'>
      <div class="child">
        <p-progressSpinner  [style]="{width: '100px', height: '100px'}" stroke="grey" strokeWidth="2" animationDuration="1s"></p-progressSpinner>
      </div>
    </div>
  </div>
  <div>
    <p-toolbar>
      <div class="p-toolbar-group-left">
        <button #filterButton pButton type="button" icon="pi pi-filter" (click)="filterPanel.toggle($event)"></button>
        <span style="margin-left: 5px;"><label>{{filterlabel}}</label></span>
      </div>

      <div class="p-toolbar-group-right">
        <span style="margin-left: 20px;"><label>Max. Aufgaben/Zeile</label></span>
        <span style="margin-left: 5px;"><p-inputNumber min="1" [size]="2" [showButtons]="true" [(ngModel)]="eventLineLimit" (ngModelChange)="changeLineLimit()"></p-inputNumber></span>
        <span style="margin-left: 20px;"><p-radioButton name="calendarArea" label="Laufzeit" [value]=0 [(ngModel)]="eventRangeTime" (onClick)="changedEventRange($event)"></p-radioButton></span>
        <span style="margin-left: 10px;"><p-radioButton name="calendarArea" value=1 label="Bearbeitungszeit" [(ngModel)]="eventRangeTime" (onClick)="changedEventRange($event)"></p-radioButton></span>
        <span style="margin-left: 20px;"><button pButton type="button" icon="pi pi-times" (click)="ResetFiltering()"></button></span>
      </div>
    </p-toolbar>

  </div>
  <div class="calendarContainer">
    <full-calendar [style]="{width: '100%'}" #fc id='fc'  [options]="options"></full-calendar>
  </div>

  <p-overlayPanel #filterPanel [showCloseIcon]="true" [dismissable]="false" [style]="{width: '100vH', background: 'lightgray'}"  (onHide)="clearfilter($event)" >
    <div class="row" style="margin-left: 10px;"><h5>Filter für Kalender</h5></div><hr>
    <div class="row" style="margin-top: 10px;">
      <label for="klammerFilter" style="width: 150px;margin-right: 2px;">&nbsp;&nbsp;Sortimentsklammer:&nbsp;&nbsp;</label>
      <p-dropdown id="klammerFilter" [options]="(isManager$ | async) ? (sortimentsKlammern$ | async)  : (getSupplierKlammern(this.usersCalendarSortimentKlammern$) | async)" optionLabel="name"
        [(ngModel)]="selectedMaster" [showClear]="true"
              [disabled]="(sortimentsKlammern$ | async).length === 0" (ngModelChange)="loadSortiment()" placeholder="Sortimentsklammer wählen">
      </p-dropdown>
    </div>
    <div *ngIf="isManager$ |async" class="row" style="margin-top: 10px;">
      <label for="typfilter" style="width: 150px;;margin-right: 2px;">&nbsp;&nbsp;Typ:&nbsp;&nbsp;</label>
      <p-dropdown id="typFilter"  [options]="sortimentstypen | async" [showClear]="true" [filter]="false"
      [disabled]="(canSelectTypen() | async) == false"
      [(ngModel)]="selectedType" (ngModelChange)="activateTypeFilter(selectedType)" optionLabel="name" placeholder="Typfilter wählen">>
      </p-dropdown>
    </div>
    <div class="row" style="margin-top: 10px;">
      <label for="sortimentFilter" style="width: 150px;;margin-right: 2px;">&nbsp;&nbsp;Sortiment:&nbsp;&nbsp;</label>
      <p-multiSelect [maxSelectedLabels]="1" id="sortimentFilter" [options]="(isManager$ | async) ? (sortiments$ | async) : (getSupplierSortiments(usersCalendarSortimentKlammern$, selectedMaster) | async)"  selectedItemsLabel="{0} Sortimente ausgewählt"
              [disabled]="((sortiments$ | async) === null || (sortiments$ |async).length === 0)" placeholder="Sortiment wählen"
              [(ngModel)]="selectedSortiments" optionLabel="name" (ngModelChange)="activateFilter()">
      </p-multiSelect>
    </div>
  </p-overlayPanel>
</div>
</div>
