import { AppState as RootState } from '../../store';
import { Notification } from '@app/common/models';
import { createFeatureSelector } from '@ngrx/store';
import { SendMessageTypeContainer } from '@app/common/models/signal.model';
import { SimpleResult } from '@app/common/models/simpleResult.model';


export interface NotificationsState {
    notifications: SendMessageTypeContainer<any>[];
    notificationError: SimpleResult;
}

export interface AppState extends RootState {
    notifications: NotificationsState;
}

export const InitialNotificationState: NotificationsState = {
    notifications: [],
    notificationError: <SimpleResult>{}
}

export const featureName = 'notifications';

export const selectNotificationsState = createFeatureSelector<NotificationsState>(featureName);
