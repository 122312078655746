import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import {UserViewComponent} from './userTasks.component';
import { UserViewStateModule } from './userTasks-state.module';

import { TaskViewComponent } from './task-view.component';

import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import {CheckboxModule} from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';

import { NgStringPipesModule } from 'ngx-pipes';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { CommonCheckService } from '@app/common/services/common.validation.service';

@NgModule({
    imports: [FormsModule, CommonModule, UserViewStateModule, TableModule, DropdownModule, CheckboxModule, ButtonModule, NgStringPipesModule, ConfirmDialogModule, TooltipModule, MatIconModule],
    exports: [UserViewComponent, TaskViewComponent],
    declarations: [UserViewComponent, TaskViewComponent],
    providers: [CommonCheckService]
})
export class UserTasksModule { }
