import { ValidationResults, ValidResult } from './validResult.model'


export class FileModel {
  desciption: FileDescriptionModel = <FileDescriptionModel>{};
  validation: ValidResult;
  blob: any;

  constructor(desciption: FileDescriptionModel, blob: any) {
    this.desciption = desciption;
    this.blob = blob;
    if (this.desciption.fileType == undefined) {
      this.desciption = {...this.desciption};
      this.desciption.fileType = this.blob.type;
    }
  }
}

export interface FileDescriptionModel {
  id: number;
  fileName: string;
  fileExtension?: string;
  fileType: string;
  fileBase64: string;
  initialLoad: boolean;
}

export interface FileDescriptionValidationModel extends FileDescriptionModel {
  validation: ValidResult;
}
