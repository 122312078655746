import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidationEditorComponent } from './validation-editor.component';
import { ValidationEditorStateModule } from './validation-editor-state.module';
import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SpinnerModule } from 'primeng/spinner';
import {CalendarModule} from 'primeng/calendar';
import { EditValidationColumnDialogComponent } from './pages/editValidationColumn.dialog.component';
import { EditValidationListDialogComponent } from './pages/editValidationList.dialog.component';
import { EditAvailableColumnDialogComponent } from './pages/editAvailableColumn.dialog.component';
import { NgStringPipesModule } from 'ngx-pipes';
import { TooltipModule } from 'primeng/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { CommonDataModule } from '@app/common-data-module/common-data-module.module';
import { MessageModule } from 'primeng/message';

@NgModule({
    imports: [
        CommonModule,
        ValidationEditorStateModule,
        CommonDataModule,
        ToolbarModule,
        ButtonModule,
        TableModule,
        CheckboxModule,        
        FormsModule,
        ConfirmDialogModule,
        InputTextModule,
        InputTextareaModule,
        DropdownModule,
        SpinnerModule,
        CalendarModule,
        NgStringPipesModule,
        MessageModule,
        TooltipModule, MatIconModule
    ],
    exports: [ValidationEditorComponent, EditValidationColumnDialogComponent, EditValidationListDialogComponent, EditAvailableColumnDialogComponent],
    declarations: [ValidationEditorComponent, EditValidationColumnDialogComponent, EditValidationListDialogComponent, EditAvailableColumnDialogComponent],
    providers: []
})

export class ValidationEditorModule { }

