import { Component, OnInit, Output } from '@angular/core';
import { StatistikService } from '@app/common-data-module/services/StatistikService';
import { BehaviorSubject, Subject, of } from 'rxjs';

@Component({
  selector: 'app-novitaetendiagramm-soma',
  templateUrl: './novitaetendiagramm-soma.component.html',
  styleUrls: ['./novitaetendiagramm-soma.component.scss']
})
export class NovitaetendiagrammSomaComponent implements OnInit {


  selectSOMADiagramm$ = this.statistikService.selectSOMADiagramm$;

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Novitäten Statistik';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = '';
  animations: boolean = true;

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#aae3f5']
  };



  onSelect(event) {
    console.log(event);
  }

  constructor(private statistikService : StatistikService)
  {

  }

  selectTitel$ = this.statistikService.selectTitel$;


  ngOnInit(): void {



  }




}
