<div class="container-fluid">
  <div class="grid" style="padding-top:0.5rem">
    <mat-icon class="pageTitleIcon">list</mat-icon>
    <h2 class="pageTitleHeader">Aufgaben</h2>
  </div>
  <div class="contentSeperator"></div>
  <div class="row filterPanel grid">
    <div class="col-1">Filter:</div>
    <div class="col-1">
      <p-checkbox name="selectedFilters" [value]="0" label="Alle" [(ngModel)]="selectedCategories"
        (onChange)="selectAllCategories()"></p-checkbox>
    </div>
    <div class="col-1">
      <p-checkbox name="selectedFilters" [value]="200" label="Neu" [(ngModel)]="selectedCategories"
        (onChange)="selectSpecificCategory()"></p-checkbox>
    </div>
    <div class="col-1">
      <p-checkbox name="selectedFilters" [value]="300" label="In Arbeit" [(ngModel)]="selectedCategories"
        (onChange)="selectSpecificCategory()"></p-checkbox>
    </div>
    <div class="col-fixed" style="width: 10%">
      <p-checkbox name="selectedFilters" [value]="400" label="Verschickt" [(ngModel)]="selectedCategories"
        (onChange)="selectSpecificCategory()"></p-checkbox>
    </div>

  </div>
  <div class="contentSeperator"></div>
  <p-table [value]="tasks$ | async" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[20,50,100]"
    [rows]="this.pageSorting.rowsToLoad" [lazy]="true" (onLazyLoad)="loadTaskData($event)"
    [totalRecords]="total$ | async" [first]="pageSorting.skipRows" [showCurrentPageReport]="true"
    paginatorDropdownAppendTo="body"
    [currentPageReportTemplate]="'{first} bis {last} von {totalRecords} Aufgaben'">
    <colgroup>
      <col style="width:5%">
      <col style="width:5%">
      <col style="width:5%">
      <col style="width:20%">
      <col style="width:20%">
      <col style="width:10%">
      <col style="width:10%">
      <col style="width:10%">
      <col style="width:10%">
      <col style="width:10%">
    </colgroup>
    <ng-template pTemplate="header">
      <tr [ngClass]="'kb-row'">
        <th [ngClass]="'kb-header-cell-small'" style="width: 4em;"></th>
        <th [ngClass]="'kb-header-cell-small'" style="width: 4em;"></th>
        <th [ngClass]="'kb-header-cell-small'" style="width: 4em;"></th>
        <!-- <th [ngClass]="'kb-header-cell-small'" style="min-width: 12em; width: auto;">Name</th> -->
        <th [ngClass]="'kb-header-cell-small'" style="width: 24em;">Name</th>
        <th [ngClass]="'kb-header-cell-small'" style="width: 24em;">Sortiment</th>
        <th [ngClass]="'kb-header-cell-small'" style="width: 8em;">von</th>
        <th [ngClass]="'kb-header-cell-small'" style="width: 8em;">bis</th>
        <th [ngClass]="'kb-header-cell-small'" style="width: 8em;">Laufzeit von</th>
        <th [ngClass]="'kb-header-cell-small'" style="width: 8em;">Laufzeit bis</th>
        <th [ngClass]="'kb-header-cell-small'" style="width: 12em;">Von</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td [ngClass]="getUserTaskClass('kb-cell-small', rowData)" style="width: 3em;">
          <button pButton type="button" icon="pi pi-search"
            [class]="getUserTaskButtonClass('p-button-info', rowData)" (click)='openTaskInfo(rowData)'
            pTooltip="Aufgaben-Infos" tooltipPosition="top" showDelay="1000" hideDelay="1000"></button>
        </td>
        <td [ngClass]="getUserTaskClass('kb-cell-small', rowData)" style="width: 3em;">
          <button pButton *ngIf="rowData.selectedValidationId && rowData.taskState <= 300" type="button"
            [icon]="getIconUpload(rowData)" [class]="getUserTaskButtonClass('p-button-info', rowData)"
            (click)='openUpload(rowData)' [pTooltip]="getTooltipUploadEdit(rowData)" tooltipPosition="top"
            showDelay="1000" hideDelay="1000"></button>
          <button pButton *ngIf="rowData.selectedValidationId && rowData.taskState > 300" type="button"
            [icon]="getIconUpload(rowData)"
            [class]="getUserTaskButtonClass('ui-button-outlined p-button-secondary', rowData)"
            (click)='openUpload(rowData)' [pTooltip]="getUploadtooltip(rowData)" tooltipPosition="top" showDelay="1000"
            hideDelay="1000"></button>
        </td>
        <td [ngClass]="getUserTaskClass('kb-cell-small', rowData)" style="width: 3em;">
          <button pButton
            *ngIf="rowData.selectedValidationId && rowData.taskState < 500 && rowData.isReadOnly!=1 && rowData.isEditExpired != 1"
            type="button" icon="pi pi-times" [class]="getUserTaskButtonClass('p-button-info', rowData)"
            (click)="closeTaskwithouAction(rowData.id)" pTooltip="Ohne Input abschließen" tooltipPosition="top"
            showDelay="1000" hideDelay="1000"></button>
        </td>
        <td [ngClass]="getUserTaskClass('kb-cell-default', rowData)" style="width: 24em;">{{ rowData.name }}</td>
        <td *ngIf="rowData.sortimentName" [ngClass]="getUserTaskClass('kb-cell-default', rowData)" style="width: 24em;">
          {{ rowData.sortimentName }}</td>
        <td *ngIf="rowData.sortimentName == null" [ngClass]="getUserTaskClass('kb-cell-default', rowData)"
          style="width: 24em;">{{ rowData.klammerName }}</td>
        <td [ngClass]="getUserTaskClass('kb-cell-default', rowData)">{{ rowData.taskValidRangeFrom | date }}</td>
        <td [ngClass]="getUserTaskClass('kb-cell-default', rowData)">{{ rowData.taskValidRangeTo | date }}</td>
        <td [ngClass]="getUserTaskClass('kb-cell-default', rowData)">{{ rowData.taskActiveRangeFrom | date }}</td>
        <td [ngClass]="getUserTaskClass('kb-cell-default', rowData)">{{ rowData.taskActiveRangeUntil | date }}</td>
        <td [ngClass]="getUserTaskClass('kb-cell-default', rowData)">{{ rowData.creatorFriendlyName | shorten: 15: '...'
          }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>


<p-confirmDialog header="Bitte bestätigen:" icon="pi pi-exclamation-triangle" message="Keine Zeilen werden gelöscht."
  rejectLabel="Abbrechen"></p-confirmDialog>
