import { Injectable } from "@angular/core";
import { selectCommonDataProcessState } from './state';
import { Store, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NoviProcessService } from '@app/common/services/noviProcess.service';
import  { BranchService} from '@app/api/commonWebAPI/services';

import {
  InsertColumnConfig, InsertColumnConfigDone, GetColumnConfigs,
  GetColumnConfigsDone, UpdateColumnConfig, UpdateColumnConfigDone, DeleteColumnConfig,
  DeleteColumnConfigDone, UpdateLastAccessConfig, UpdateLastAccessConfigDone,
  UpdateorAddColorofUser, UpdateorAddColorofUserDone, GetDistinctColorsofUserDone, GetDistinctColorsofUser,
  GetNovitaetenLookupsStringActionDone, GetNovitaetenLookupsStringAction, GetRegionenAction, GetRegionenActionDone,
  GetMarketingEreignisseAction, GetMarketingEreignisseActionDone, GetEinkaeufeAction, GetEinkaeufeActionDone, GetSaisonsAction, GetSaisonsActionDone, GetNovitaetenSoMaContactsAction, GetNovitaetenSoMaContactsActionDone, GetSortimenteAction, GetSortimenteActionDone, GetKlammernAction, GetKlammernActionDone, GetNovitaetenLookupsIntAction, GetNovitaetenLookupsIntActionDone, GetNovitaetenKategorienAction, GetNovitaetenKategorienActionDone, GetNovitaetenWarengruppenAction, GetNovitaetenWarengruppenActionDone, DeleteColorsofId, DeleteColorsofIdDone, GetStichwortBaumbyKlammer, GetStichwortBaumDone, GetStichwortBaum, GetStichwortBaumbyKlammerDone, GetStichwortBaumbyKlammerSortiment, GetStichwortBaumbyKlammerSortimentDone, GetStichwortBaumbyKlammerSortimentStichwort, GetStichwortBaumbyKlammerSortimentStichwortDone, GetStichwortBaumbyKlammerExpand, GetStichwortBaumbyKlammerExpandDone, GetStichwortBaumbyKlammerSortimentExpand, GetStichwortBaumbyKlammerSortimentExpandDone,
  GetStichwortBaumbyKlammerSortimentStichwortExpand, GetStichwortBaumbyKlammerSortimentStichwortExpandDone,
  KeywordErrorAction, GetNovitaetBubDataByEansAction, GetNovitaetBubDataByEansActionDone, GetNovitaetBedarfAction, GetNovitaetBedarfActionDone,
  CommonDataActionType,
  CommonDataErrorAction,
  GetNovitaetenLookupsIdNameAction,
  GetNovitaetenLookupsIdNameActionDone,
  GetAktuellenEinkaufActionDone,
  GetCompareListAction,
  GetCompareListActionDone,
  GetNovitaetenListenAction,
  GetNovitaetenListenActionDone,
  GetSortimentstypenAction,
  GetSortimentstypenActionDone,
  GetVerlagebySearchAction,
  GetVerlagebySearchActionDone,
  QueryAlsExcelExportierenAction,
  QueryAlsExcelExportierenActionDone,
  GetKategorienderVorabplatzierungen,
  GetKategorienderVorabplatzierungenDone,
  GetNoviHitsAction,
  GetNoviHitsActionDone,
  GetDashboardSumAction,
  GetDashboardSumActionDone,
  GetNovitaetBubDataByEanAction,
  GetNovitaetBubDataByEanActionDone,
  GetZukuenftigeEinkaufAction,
  GetZukuenftigeEinkaufActionDone,
  GetAktuellenEinkaufAction,
  GetMandantenAction,
  GetMandantenActionDone,
  GetMandantAction,
  GetMandantActionDone,
  GetLagerAction,
  GetLagerActionDone,
  GetLieferantenAction,
  GetLieferantenActionDone,
  GetLieferantAction,
  GetLieferantActionDone,
  GetVerlagChildrenAction,
  GetVerlagChildrenActionDone,
  GetSettingsStateAction,
  GetSettingsStateActionDone,
  DeleteCustomConfigbyName,
  DeleteCustomConfigbyNameDone,

  GetBranchesAction,
  GetBranchesActionDone,
  GetBranchGroupsAction,
  GetBranchGroupsActionDone,
  GetSettingsChildrenAction,
  GetSettingsChildrenActionDone,
  GetMoebelAction,
  GetMoebelActionDone
} from './actions';
import { withLatestFrom, switchMap, map, catchError, tap, mergeMap, flatMap } from 'rxjs/operators';
import { CustomSettingsService } from '@app/common/services/custom.settings.service';

import { GeneralResultEnum } from '@app/common/models';
import { SimpleResult } from '@app/common/models/simpleResult.model';
import { of, throwError } from 'rxjs';
import { _ROOT_EFFECTS_GUARD } from '@ngrx/effects/src/tokens';

import { BedarfService, DashboardService, EinkaufService, EreignisService, KeyValueService, KlammerService, LagerService, LieferantService, ListenService, MandantService, NovitaetService, RegionService, SaisonService, SortimentPlatzierungService, SortimentService, SortimentsstrukturService } from "@app/api/filialmatrix/services";
import { environment } from "@env/environment";
import { SimpleNameType } from "@app/common/models/filialmatrix/SimpleKeyValues";
import { UploadStagingService } from "@app/common/services/upload-staging.service";
import { SettingService } from "@app/common/services/setting.service";
import { BranchgroupService } from "@app/+settings/lipo-settings/controls/branchgroup-control/services/branchgroup.service";
import { MoebelDto } from "@app/api/filialmatrix/models";



@Injectable()
export class CommonDataEffects {
  //@ts-ignore
  state$ = this.store.pipe(select(selectCommonDataProcessState));

  constructor(
    private store: Store<any>,
    private actions: Actions,
    private noviService: NoviProcessService,
    private customsettingService: CustomSettingsService,
    private novitaetService: NovitaetService,
    private regionService: RegionService,
    private saisonService: SaisonService,
    private ereignisService: EreignisService,
    private einkaufService: EinkaufService,
    private sortimentService: SortimentService,
    private sortimentPlatzierungsService: SortimentPlatzierungService,
    private klammerService: KlammerService,
    private keyValueService: KeyValueService,
    private sortimentsstrukturService: SortimentsstrukturService,
    private bedarfService: BedarfService,
    private uploadStagingService: UploadStagingService,
    private listenservice: ListenService,
    private dashboadService : DashboardService,
    private mandantenService: MandantService,
    private lieferantenService: LieferantService,
    private lagerService: LagerService,
    private settingservice: SettingService,
    private branchService : BranchService,

  ) {

    novitaetService.rootUrl = `${environment.connections.AdvWebapi}`;
    regionService.rootUrl = `${environment.connections.AdvWebapi}`;
    saisonService.rootUrl = `${environment.connections.AdvWebapi}`;
    ereignisService.rootUrl = `${environment.connections.AdvWebapi}`;
    einkaufService.rootUrl = `${environment.connections.AdvWebapi}`;
    sortimentService.rootUrl = `${environment.connections.AdvWebapi}`;
    klammerService.rootUrl = `${environment.connections.AdvWebapi}`;
    keyValueService.rootUrl = `${environment.connections.AdvWebapi}`;
    sortimentsstrukturService.rootUrl = `${environment.connections.AdvWebapi}`;
    bedarfService.rootUrl = `${environment.connections.AdvWebapi}`;
    listenservice.rootUrl = `${environment.connections.AdvWebapi}`;
    sortimentPlatzierungsService.rootUrl = `${environment.connections.AdvWebapi}`;
    dashboadService.rootUrl = `${environment.connections.AdvWebapi}`;
    mandantenService.rootUrl = `${environment.connections.AdvWebapi}`;
    lagerService.rootUrl = `${environment.connections.AdvWebapi}`;

    lieferantenService.rootUrl = `${environment.connections.AdvWebapi}`;
    branchService.rootUrl = `${environment.connections.CommonWebApi}`;
  }

  showFile(blob: Blob, mime: string, extension: string, fileName: string) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([blob], { type: mime })

    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    link.href = data;
    link.download = `${fileName}.${extension}`;
    link.click();
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  }


  getNoviHitsAction$ = createEffect(() => {
    return this.actions.pipe(
      ofType<GetNoviHitsAction>(CommonDataActionType.GetNoviHitsAction),
      mergeMap((action) =>
        this.novitaetService.NovitaetQueryNovitaetenHitsResponse(action.payload.params).pipe(
          map(response => {
            if ((<any>response).ok == false || (<any>response).body?.error == true) {
              return new CommonDataErrorAction({ type: CommonDataActionType.GetNoviHitsActionDone, message: 'Fehler Ermitteln der Anzahl der Novitäten', error: response });
            } else {
              return new GetNoviHitsActionDone({ result: response.body });
            }
          })
        )
      )
      , catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetNoviHitsActionDone, message: "Fehler beim Laden der Anzahl der Novitäten", error: error }));
        return throwError(() => error);
      })
    )
  })

  getDashboardSumAction$ = createEffect(() => {
    return this.actions.pipe(
      ofType<GetDashboardSumAction>(CommonDataActionType.GetDashboardSumAction),
      switchMap((action) =>
        this.dashboadService.DashboardGetDashboardSumResponse(action.payload.param).pipe(
          map(response => {
            if ((<any>response).ok == false || (<any>response).body?.error == true) {
              return new CommonDataErrorAction({ type: CommonDataActionType.GetDashboardSumActionDone, message: 'Fehler Ermitteln der Summen Dashboard', error: response });
            } else {
              return new GetDashboardSumActionDone({ result: response.body });
            }
          })
        )
      )
      , catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetDashboardSumActionDone, message: "Fehler beim Summen Dashboard", error: error }));
        return throwError(() => error);
      })
    )
  })



  getCompareTitles$ = createEffect(() => {
    return this.actions.pipe(
      ofType<GetCompareListAction>(CommonDataActionType.GetCompareListAction),
      withLatestFrom(this.state$),
      mergeMap(([action, state]) =>
        this.uploadStagingService.getCompareDataForEansNovi(action.payload.novitaet && action.payload.novitaet.vergleichsartikel ? action.payload.novitaet.vergleichsartikel.map(e => e.product.ean) : []).pipe(map(response => {
          if (response.generalResult == GeneralResultEnum.GeneralError) {
            return new CommonDataErrorAction({ type: CommonDataActionType.GetCompareListForActionDone, message: "Fehler beim Laden der Vergleichstitel.", error: response });
          } else {
            if (response.generalResult == GeneralResultEnum.LogicWarning) {
              response.generalResult = GeneralResultEnum.OnlyDebug;
              this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetCompareListForActionDone, message: "Warnung: Laden der Vergleichstitel.", error: response }));
            }
            return new GetCompareListActionDone({ noviwupId: action.payload.novitaet.id, compareTitles: response.data });
          }
        }))
      )
      , catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetCompareListForActionDone, message: "Fehler beim Laden der Vergleichstitel", error: error }));
        return throwError(() => error);
      })
    )
  })


  getListenDtos = createEffect(() => {
    return this.actions.pipe(
      ofType<GetNovitaetenListenAction>(CommonDataActionType.GetNovitaetenListenAction),
      switchMap((action) =>
        this.listenservice.ListenQueryListeResponse(action.payload.param).pipe(
          map(response => {
            if ((<any>response).ok == false || (<any>response).body?.error == true) {
              return new CommonDataErrorAction({ type: CommonDataActionType.GetAktuellenEinkaufAction, message: 'Fehler beim Laden der Listen ', error: response });
            } else {
              return new GetNovitaetenListenActionDone({ typ: action.payload.typ, list: response.body });
            }
          })
        )
      ), catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetAktuellenEinkaufAction, message: 'Fehler beim Laden der Listen', error: error }));
        return caught;;
      })
    );
  })


  getAktuellenEinkaufAction = createEffect(() => {
    return this.actions.pipe(
      ofType<GetAktuellenEinkaufAction>(CommonDataActionType.GetAktuellenEinkaufAction),
      mergeMap((action) =>
        this.einkaufService.EinkaufQueryEinkaufResponse(action.payload.params).pipe(
          map(response => {
            if ((<any>response).ok == false || (<any>response).body?.error == true) {
              return new CommonDataErrorAction({ type: CommonDataActionType.GetAktuellenEinkaufAction, message: 'Fehler beim Laden des aktuellen Einkaufzeitraums', error: response });
            } else {
              return new GetAktuellenEinkaufActionDone({ list: response.body });
            }
          })
        )
      ), catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetAktuellenEinkaufAction, message: 'Fehler beim Laden des aktuellen Einkaufzeitraums', error: error }));
        return caught;;
      })
    );
  })


  getZukuenftigeEinkaufAction = createEffect(() => {
    return this.actions.pipe(
      ofType<GetZukuenftigeEinkaufAction>(CommonDataActionType.GetZukuenftigeEinkaufAction),
      mergeMap((action) =>
        this.einkaufService.EinkaufQueryEinkaufResponse(action.payload.params).pipe(
          map(response => {
            if ((<any>response).ok == false || (<any>response).body?.error == true) {
              return new CommonDataErrorAction({ type: CommonDataActionType.GetAktuellenEinkaufAction, message: 'Fehler beim Laden des zukünfitgen Einkäufe', error: response });
            } else {
              return new GetZukuenftigeEinkaufActionDone({ list: response.body });
            }
          })
        )
      ), catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetAktuellenEinkaufAction, message: 'Fehler beim Laden des zukünfige Einkäufe', error: error }));
        return caught;;
      })
    );
  })


  getNovitaetBedarf$ = createEffect(() => {
    return this.actions.pipe(
      ofType<GetNovitaetBedarfAction>(CommonDataActionType.GetNovitaetBedarfAction),
      withLatestFrom(this.state$),
      switchMap(([action]) =>
        this.bedarfService.BedarfSimulierteBestellmengeResponse(action.payload.novitaetenId).pipe(
          map(response => {
            if (response.ok != true || (<any>response).body?.error == true) {
              return new CommonDataErrorAction({ type: CommonDataActionType.GetNovitaetBedarfActionDone, message: "Fehler Laden der Simulierten Bestellmengen.", error: response });
            } else {
              return new GetNovitaetBedarfActionDone({ id: action.payload.novitaetenId, data: response.body });
            }
          }),
          catchError((error, caught) => {
            this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetNovitaetBedarfActionDone, message: "Fehler Laden der Simulierten Bestellmengen. ", error: error }));
            return  throwError(() => error);
          })
        )
      )
    )
  })

  getNovitaetBubDataByEans$ = createEffect(() => {
    return this.actions.pipe(
      ofType<GetNovitaetBubDataByEansAction>(CommonDataActionType.GetNovitaetBubDataByEansAction),
      withLatestFrom(this.state$),
      switchMap(([action]) =>
        this.noviService.getBubDataByEans(action.payload.eans).pipe(
          map(response => {
            if (response.generalResult != GeneralResultEnum.Success) {
              return new CommonDataErrorAction({ type: CommonDataActionType.GetNovitaetBubDataByEansActionDone, message: "Fehler beim Laden von Bub Daten: " + action.payload.eans.join(";"), error: response });
            } else {
              return new GetNovitaetBubDataByEansActionDone({ bubDataList: response.data, searchedeans: action.payload.eans });
            }
          }),
          catchError((error, caught) => {
            this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetNovitaetBubDataByEansActionDone, message: "Fehler beim Laden von Bub Daten: " + action.payload.eans.join(";"), error: error }));
            return throwError(() => error);
          })
        )
      )
    )
  })

  getNovitaetBubDataByEan$ = createEffect(() => {
    return this.actions.pipe(
      ofType<GetNovitaetBubDataByEanAction>(CommonDataActionType.GetNovitaetBubDataByEanAction),
      withLatestFrom(this.state$),
      mergeMap(([action]) =>
        this.noviService.getBubDataByEan(action.payload.ean).pipe(
          map(response => {
            if (response.generalResult != GeneralResultEnum.Success) {
              return new CommonDataErrorAction({ type: CommonDataActionType.GetNovitaetBubDataByEansActionDone, message: "Fehler beim Laden von Bub Daten Ean: " + action.payload.ean, error: response });
            } else {
              return new GetNovitaetBubDataByEanActionDone({ bubData: response.data, searchedean: action.payload.ean });
            }
          }),
          catchError((error, caught) => {
            this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetNovitaetBubDataByEansActionDone, message: "Fehler beim Laden von Bub Daten Ean: " + action.payload.ean, error: error }));
            return throwError(() => error);
          })
        )
      )
    )
  })


  getStichwortBaum$ = createEffect(() => {
    return this.actions.pipe(
      ofType<GetStichwortBaum>(CommonDataActionType.GetStichwortBaum),
      mergeMap((action) =>
        this.sortimentsstrukturService.SortimentsstrukturGetKlammerSortimentStichwortResponse()
          .pipe(map(response => {
            if (response.ok != true || (<any>response).body?.error == true) {
              return new KeywordErrorAction({
                id: action.payload.id, type: CommonDataActionType.GetStichwortBaumDone, message: "Fehler beim Laden des Baums.",
                error: response, params: action.payload.id
              });
            } else {
              return new GetStichwortBaumDone({ id: action.payload.id, data: response.body });
            }
          }),
            catchError((error, caught) => {
              this.store.dispatch(new KeywordErrorAction({ id: action.payload.id, params: null, type: CommonDataActionType.GetStichwortBaumDone, message: "Fehler beim Laden des Baums.", error: error }));
              return caught;;
            })
          )
      ),
      catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetStichwortBaumDone, message: "Fehler beim Laden des Baums.", error: error }));
        return caught;;
      })
    )
  })


  getStichwortBaumbyKlammer$ = createEffect(() => {
    return this.actions.pipe(
      ofType<GetStichwortBaumbyKlammer>(CommonDataActionType.GetStichwortBaumbyKlammer),
      mergeMap((action) =>
        this.sortimentsstrukturService.SortimentsstrukturGetKlammerSortimentStichwort2Response(action.payload.klammerid)
          .pipe(
            map(response => {
              if (response.ok != true || (<any>response).body?.error == true) {
                return new KeywordErrorAction({ id: action.payload.id, params: action.payload.klammerid, type: CommonDataActionType.GetStichwortBaumDone, message: "Fehler beim Laden des Baums.", error: response });
              } else {
                return new GetStichwortBaumbyKlammerDone({ id: action.payload.id, klammerid: action.payload.klammerid, data: response.body });
              }
            }),
            catchError((error, caught) => {
              this.store.dispatch(new KeywordErrorAction({ id: action.payload.id, params: action.payload.klammerid, type: CommonDataActionType.GetStichwortBaumDone, message: "Fehler beim Laden des Baums.", error: error }));
              return caught;;
            })
          )
      ),
      catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetStichwortBaumDone, message: "Fehler beim Laden des Baums.", error: error }));
        return caught;;
      })
    )
  })

  getStichwortBaumbyKlammerSortiment$ = createEffect(() => {
    return this.actions.pipe(
      ofType<GetStichwortBaumbyKlammerSortiment>(CommonDataActionType.GetStichwortBaumbyKlammerSortiment),
      mergeMap((action) =>
        this.sortimentsstrukturService.SortimentsstrukturGetKlammerSortimentStichwort3Response(action.payload.params)
          .pipe(
            map(response => {
              if (response.ok != true || (<any>response).body?.error == true) {
                return new KeywordErrorAction({ id: action.payload.id, params: action.payload.params, type: CommonDataActionType.GetStichwortBaumbyKlammerSortimentDone, message: "Fehler beim Laden des Baums.", error: response });
              } else {
                return new GetStichwortBaumbyKlammerSortimentDone({ id: action.payload.id, data: response.body, params: action.payload.params });
              }
            }),
            catchError((error, caught) => {
              this.store.dispatch(new KeywordErrorAction({
                id: action.payload.id, params: action.payload.params,
                type: CommonDataActionType.GetStichwortBaumbyKlammerSortimentDone, message: "Fehler beim Laden des Baums.", error: error
              }));
              return caught;;
            })

          )
      ),
      catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetStichwortBaumbyKlammerSortimentDone, message: "Fehler beim Laden des Baums.", error: error }));
        return caught;;
      })
    )
  })

  getStichwortBaumbyKlammerSortimentStichwort$ = createEffect(() => {
    return this.actions.pipe(
      ofType<GetStichwortBaumbyKlammerSortimentStichwort>(CommonDataActionType.GetStichwortBaumbyKlammerSortimentStichwort),
      mergeMap((action) =>
        this.sortimentsstrukturService.SortimentsstrukturGetKlammerSortimentStichwort4Response(action.payload.params)
          .pipe(
            map(response => {
              if (response.ok != true || (<any>response).body?.error == true) {
                return new KeywordErrorAction({ id: action.payload.id, params: action.payload.params, type: CommonDataActionType.GetStichwortBaumbyKlammerSortimentStichwortDone, message: "Fehler beim Laden des Baums.", error: response });
              } else {
                return new GetStichwortBaumbyKlammerSortimentStichwortDone({ id: action.payload.id, data: response.body, params: action.payload.params });
              }
            }),
            catchError((error, caught) => {
              this.store.dispatch(new KeywordErrorAction({
                id: action.payload.id, params: action.payload.params,
                type: CommonDataActionType.GetStichwortBaumbyKlammerSortimentStichwortDone, message: "Fehler beim Laden des Baums.", error: error
              }));
              return caught;;
            })
          )
      ),
      catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetStichwortBaumbyKlammerSortimentStichwortDone, message: "Fehler beim Laden des Baums.", error: error }));
        return caught;;
      })
    )
  })



  getStichwortBaumbyKlammerExpand$ = createEffect(() => {
    return this.actions.pipe(
      ofType<GetStichwortBaumbyKlammerExpand>(CommonDataActionType.GetStichwortBaumbyKlammerExpand),
      mergeMap((action) =>
        this.sortimentsstrukturService.SortimentsstrukturGetExpandedKlammerSortimentStichwort3Response(action.payload.klammerid)
          .pipe(
            map(response => {
              if (response.ok != true || (<any>response).body?.error == true) {
                return new KeywordErrorAction({ id: action.payload.id, params: action.payload.klammerid, type: CommonDataActionType.GetStichwortBaumbyKlammerExpandDone, message: "Fehler beim Laden des Baums.", error: response });
              } else {
                return new GetStichwortBaumbyKlammerExpandDone({ id: action.payload.id, klammerid: action.payload.klammerid, data: response.body });
              }
            }),
            catchError((error, caught) => {
              this.store.dispatch(new KeywordErrorAction({
                id: action.payload.id, params: action.payload.klammerid,
                type: CommonDataActionType.GetStichwortBaumbyKlammerExpandDone, message: "Fehler beim Laden des Baums.", error: error
              }));
              return caught;;
            })
          )
      ),
      catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetStichwortBaumbyKlammerExpandDone, message: "Fehler beim Laden des Baums.", error: error }));
        return caught;;
      })
    )
  })

  getStichwortBaumbyKlammerSortimentExpand$ = createEffect(() => {
    return this.actions.pipe(
      ofType<GetStichwortBaumbyKlammerSortimentExpand>(CommonDataActionType.GetStichwortBaumbyKlammerSortimentExpand),
      mergeMap((action) =>
        this.sortimentsstrukturService.SortimentsstrukturGetExpandedKlammerSortimentStichwort2Response(action.payload.params)
          .pipe(
            map(response => {
              if (response.ok != true || (<any>response).body?.error == true) {
                return new KeywordErrorAction({ id: action.payload.id, params: action.payload.params, type: CommonDataActionType.GetStichwortBaumbyKlammerSortimentExpandDone, message: "Fehler beim Laden des Baums.", error: response });
              } else {
                return new GetStichwortBaumbyKlammerSortimentExpandDone({ id: action.payload.id, data: response.body, params: action.payload.params });
              }
            }),
            catchError((error, caught) => {
              this.store.dispatch(new KeywordErrorAction({
                id: action.payload.id, params: action.payload.params,
                type: CommonDataActionType.GetStichwortBaumbyKlammerSortimentExpandDone, message: "Fehler beim Laden des Baums.", error: error
              }));
              return caught;;
            })
          )
      ),
      catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetStichwortBaumbyKlammerSortimentExpandDone, message: "Fehler beim Laden des Baums.", error: error }));
        return caught;;
      })
    )
  })

  getStichwortBaumbyKlammerSortimentStichwortExpand$ = createEffect(() => {
    return this.actions.pipe(
      ofType<GetStichwortBaumbyKlammerSortimentStichwortExpand>(CommonDataActionType.GetStichwortBaumbyKlammerSortimentStichwortExpand),
      mergeMap((action) =>
        this.sortimentsstrukturService.SortimentsstrukturGetExpandedKlammerSortimentStichwortResponse(action.payload.params)
          .pipe(
            map(response => {
              if (response.ok != true || (<any>response).body?.error == true) {
                return new KeywordErrorAction({ id: action.payload.id, params: action.payload.params, type: CommonDataActionType.GetStichwortBaumbyKlammerSortimentStichwortExpandDone, message: "Fehler beim Laden des Baums.", error: response });
              } else {
                return new GetStichwortBaumbyKlammerSortimentStichwortExpandDone({ id: action.payload.id, data: response.body, params: action.payload.params });
              }
            }),
            catchError((error, caught) => {
              this.store.dispatch(new KeywordErrorAction({
                id: action.payload.id, params: action.payload.params,
                type: CommonDataActionType.GetStichwortBaumbyKlammerSortimentStichwortExpandDone, message: "Fehler beim Laden des Baums.", error: error
              }));
              return caught;;
            })
          )
      ),
      catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetStichwortBaumbyKlammerSortimentStichwortExpandDone, message: "Fehler beim Laden des Baums.", error: error }));
        return caught;;
      })
    )
  })

  getVerlageActionbySearch = createEffect(() => {
    return this.actions.pipe(
      ofType<GetVerlagebySearchAction>(CommonDataActionType.GetVerlagebySearchAction),
      mergeMap((action) =>
        this.noviService.GetLDVVerlage(action.payload.srch).pipe(
          map(response => {
            if (response.error == true) {
              return new CommonDataErrorAction({ type: CommonDataActionType.GetVerlagebySearchActionDone, message: 'Fehler beim Laden der Verlage ', error: response });
            } else {
              return new GetVerlagebySearchActionDone({ result: response.result });
            }
          })
        )
      ), catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetRegionenActionDone, message: 'Fehler beim Suchen nach Verlagen', error: error }));
        return caught;;
      })
    );
  })

  getVerlageDeepActionbySearch = createEffect(() => {
    return this.actions.pipe(
      ofType<GetVerlagChildrenAction>(CommonDataActionType.GetVerlagChildrenAction),
      mergeMap((action) =>
        this.noviService.GetLDVVerlageDeep(action.payload.id).pipe(
          map(response => {
            if (response.error == true) {
              return new CommonDataErrorAction({ type: CommonDataActionType.GetVerlagChildrenActionDone, message: 'Fehler beim Laden der Verlage ', error: response });
            } else {
              return new GetVerlagChildrenActionDone({ result: response.result, id: action.payload.id });
            }
          })
        )
      ), catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetVerlagChildrenActionDone, message: 'Fehler beim Suchen nach Verlagen', error: error }));
        return caught;;
      })
    );
  })


  getWarengruppenAction = createEffect(() => {
    return this.actions.pipe(
      ofType<GetNovitaetenWarengruppenAction>(CommonDataActionType.GetNovitaetenWarengruppenAction),
      switchMap((action) =>
        this.noviService.GetWarengruppen().pipe(
          map(response => {
            return new GetNovitaetenWarengruppenActionDone({ list: response });
          })
        )
      ), catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetRegionenActionDone, message: 'Fehler beim Laden der Warengruppen', error: error }));
        return caught;;
      })
    );
  })



  getSettingKategorien$ = createEffect(() => {
    return this.actions.pipe(
      ofType<GetNovitaetenKategorienAction>(CommonDataActionType.GetNovitaetenKategorienAction),
      mergeMap((action) => //flatmap weil mehrfach aufruf möglich sesin muss
        this.keyValueService.KeyValueGetKeyValuesResponse(<KeyValueService.KeyValueGetKeyValuesParams>{
          target: SimpleNameType.Sortimentskategorie, locale: 'de-DE'
        }).pipe(
          map(response => {
            if (response.ok != true || (<any>response).body?.error == true) {
              return new CommonDataErrorAction({ type: CommonDataActionType.GetNovitaetenKategorienActionDone, message: 'Fehler beim Laden der Kategorien', error: response });
            } else {
              return new GetNovitaetenKategorienActionDone({ list: response.body.result });
            }
          }, catchError((error, caught) => {
            this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetNovitaetenKategorienActionDone, message: 'Fehler beim Laden der Kategorien', error: error }));
            return caught;;
          })
          ))));
  })




  getSortimentstypenAction$ = createEffect(() => {
    return this.actions.pipe(
      ofType<GetSortimentstypenAction>(CommonDataActionType.GetSortimentstypenAction),
      withLatestFrom(this.state$),
      switchMap(([action, state]) =>
        this.sortimentService.SortimentQuerySortimentsTypenResponse(action.payload.params)
          .pipe(
            map(response => {
              if (response.body.error) {
                return new CommonDataErrorAction({ type: CommonDataActionType.GetSortimentstypenActionDone, message: "Fehler Typen laden", error: response.body });
              } else {
                return new GetSortimentstypenActionDone({ typen: response.body.result });
              }
            })
          )
      ), catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetSortimentstypenActionDone, message: "Fehler  Typen laden", error: error }))
        return caught;;
      })
    )
  })


  getKlammernNovitäten$ = createEffect(() => {
    return this.actions.pipe(
      ofType<GetKlammernAction>(CommonDataActionType.GetKlammernAction),
      withLatestFrom(this.state$),
      switchMap(([action, state]) =>
        this.klammerService.KlammerQueryKlammernResponse(action.payload.param).pipe(
          map(response => {
            if (response.ok != true || (<any>response).body?.error == true) {
              return new CommonDataErrorAction({ type: CommonDataActionType.GetKlammernActionDone, message: 'Fehler beim Laden der Klammern', error: response });
            } else {
              return new GetKlammernActionDone({ klammern: response.body });
            }
          })
        )
      ), catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetKlammernActionDone, message: 'Fehler beim Laden der Klammern', error: error }));
        return caught;;
      }
      )
    )
  })




  getSortimente$ = createEffect(() => {
    return this.actions.pipe(
      ofType<GetSortimenteAction>(CommonDataActionType.GetSortimenteAction),
      withLatestFrom(this.state$),
      switchMap(([action, state]) =>
        this.sortimentService.SortimentQuerySortimenteResponse(action.payload.param).pipe(
          map(response => {
            if (response.body.error == true) {
              return new CommonDataErrorAction({ type: CommonDataActionType.GetSortimenteActionDone, message: 'Fehler beim Laden der Sortimente', error: response });
            } else {
              return new GetSortimenteActionDone({ sortimente: response.body, oneperAssignment: action.payload.param.onePerAssignment });
            }
          })
        )
      ), catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetSortimenteActionDone, message: 'Fehler beim Laden der Sortimente', error: error }));
        return caught;;
      })
    )
  })




  getSoMAContacts$ = createEffect(() => {
    return this.actions.pipe(
      ofType<GetNovitaetenSoMaContactsAction>(CommonDataActionType.GetNovitaetenSoMaContactsAction),
      withLatestFrom(this.state$),
      switchMap(([action]) =>
        this.noviService.getSomaContacts().pipe(
          map(response => {
            if (response.generalResult != GeneralResultEnum.Success) {
              return new CommonDataErrorAction({ type: CommonDataActionType.GetNovitaetenSoMaContactsActionDone, message: "Fehler User laden", error: response });
            } else {
              return new GetNovitaetenSoMaContactsActionDone({
                list: response.data
              });
            }
          }
          ))
      ), catchError(error => of(new CommonDataErrorAction({ message: "Fehler User laden", type: CommonDataActionType.GetNovitaetenSoMaContactsAction, error: error })))
    )
  })




  getRegionenAction = createEffect(() => {
    return this.actions.pipe(
      ofType<GetRegionenAction>(CommonDataActionType.GetRegionenAction),
      mergeMap((action) =>
        this.regionService.RegionQueryRegionenResponse(action.payload.params).pipe(
          map(response => {
            if ((<any>response).ok == false || (<any>response).body?.error == true) {
              return new CommonDataErrorAction({ type: CommonDataActionType.GetRegionenActionDone, message: 'Fehler beim Laden der Regionen', error: response });
            } else {
              return new GetRegionenActionDone({ list: response.body });
            }
          })
        )
      ), catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetRegionenActionDone, message: 'Fehler beim Laden der Regionen', error: error }));
        return caught;;
      })
    );
  })


  getMarketingEreignisseAction = createEffect(() => {
    return this.actions.pipe(
      ofType<GetMarketingEreignisseAction>(CommonDataActionType.GetMarketingEreignisseAction),
      mergeMap((action) =>
        this.ereignisService.EreignisQueryEreignisResponse(action.payload.params).pipe(
          map(response => {
            if ((<any>response).ok == false || (<any>response).body?.error == true) {
              return new CommonDataErrorAction({ type: CommonDataActionType.GetMarketingEreignisseActionDone, message: 'Fehler beim Laden der Marketingereignisse', error: response });
            } else {
              return new GetMarketingEreignisseActionDone({ list: response.body });
            }
          })
        )
      ), catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetMarketingEreignisseActionDone, message: 'Fehler beim Laden der Marketingereignisse', error: error }));
        return caught;;
      })
    );
  })




  getEinkaeufeAction = createEffect(() => {
    return this.actions.pipe(
      ofType<GetEinkaeufeAction>(CommonDataActionType.GetEinkaeufeAction),
      mergeMap((action) =>
        this.einkaufService.EinkaufQueryEinkaufResponse(action.payload.params).pipe(
          map(response => {
            if ((<any>response).ok == false || (<any>response).body?.error == true) {
              return new CommonDataErrorAction({ type: CommonDataActionType.GetEinkaeufeActionDone, message: 'Fehler beim Laden der Einkäufe', error: response });
            } else {
              return new GetEinkaeufeActionDone({ list: response.body });
            }
          })
        )
      ), catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetEinkaeufeActionDone, message: 'Fehler beim Laden der Einkäufe', error: error }));
        return caught;;
      })
    );
  })


  getSaisonsAction = createEffect(() => {
    return this.actions.pipe(
      ofType<GetSaisonsAction>(CommonDataActionType.GetSaisonsAction),
      mergeMap((action) =>
        this.saisonService.SaisonQuerySaisonResponse(action.payload.params).pipe(
          map(response => {
            if ((<any>response).ok == false || (<any>response).body?.error == true) {
              return new CommonDataErrorAction({ type: CommonDataActionType.GetSaisonsActionDone, message: 'Fehler beim Laden der Saisons', error: response });
            } else {
              return new GetSaisonsActionDone({ list: response.body });
            }
          })
        )
      ), catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetSaisonsActionDone, message: 'Fehler beim Laden der Saisons', error: error }));
        return caught;;
      })
    );
  })

  getNovitaetenLookupsIntAction = createEffect(() => {
    return this.actions.pipe(
      ofType<GetNovitaetenLookupsIntAction>(CommonDataActionType.GetNovitaetenLookupsIntAction),
      mergeMap((action) =>
        this.novitaetService.NovitaetQueryNovitaetenIntLookupResponse(action.payload.params).pipe(
          map(response => {
            if ((<any>response).ok == false || (<any>response).body?.error == true) {
              return new CommonDataErrorAction({ type: CommonDataActionType.GetNovitaetenLookupsIntActionDone, message: 'Fehler beim Laden der Integer Lookups', error: response });
            } else {
              return new GetNovitaetenLookupsIntActionDone({ key: action.payload.params.key, list: response.body });
            }
          })
        )
      ), catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetNovitaetenLookupsIntActionDone, message: 'Fehler beim Laden der Integer Lookups', error: error }));
        return caught;;
      })
    );
  })





  getNovitaetenLookupsStringAction = createEffect(() => {
    return this.actions.pipe(
      ofType<GetNovitaetenLookupsStringAction>(CommonDataActionType.GetNovitaetenLookupsStringAction),
      mergeMap((action) =>
        this.novitaetService.NovitaetQueryNovitaetenStringLookupResponse(action.payload.params).pipe(
          map(response => {
            if ((<any>response).ok == false || (<any>response).body?.error == true) {
              return new CommonDataErrorAction({ type: CommonDataActionType.GetNovitaetenLookupsStringActionDone, message: 'Fehler beim Laden der Lookups', error: response });
            } else {
              return new GetNovitaetenLookupsStringActionDone({ key: action.payload.params.key, list: response.body });
            }
          })
        )
      ), catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetNovitaetenLookupsStringActionDone, message: 'Fehler beim Laden der Lookups', error: error }));
        return caught;;
      })
    );
  })





  getNovitaetenLookupsIdNameAction = createEffect(() => {
    return this.actions.pipe(
      ofType<GetNovitaetenLookupsIdNameAction>(CommonDataActionType.GetNovitaetenLookupsIdNameAction),
      mergeMap((action) =>
        this.novitaetService.NovitaetQueryNovitaetenIdnameLookupResponse(action.payload.params).pipe(
          map(response => {
            if ((<any>response).ok == false || (<any>response).body?.error == true) {
              return new CommonDataErrorAction({ type: CommonDataActionType.GetNovitaetenLookupsIdNameActionDone, message: 'Fehler beim Laden der Lookups', error: response });
            } else {
              return new GetNovitaetenLookupsIdNameActionDone({ key: action.payload.params.key, list: response.body });
            }
          })
        )
      ), catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetNovitaetenLookupsIdNameActionDone, message: 'Fehler beim Laden der Lookups', error: error }));
        return caught;;
      })
    );
  })


  deleteColorsofId$ = createEffect(() => {
    return this.actions.pipe(
      ofType<DeleteColorsofId>(CommonDataActionType.DeleteColorsofId),
      mergeMap((action) =>
        this.noviService.DeleteColorsofId(action.payload.id).pipe(
          map(response => {
            if ((<any>response).ok == false || (<any>response).body?.error == true) {
              return new CommonDataErrorAction({ type: CommonDataActionType.DeleteColorsofIdDone, message: 'Fehler beim Entfernen Farbmarkierung ', error: response });
            } else {
              return new DeleteColorsofIdDone({ id: action.payload.id });
            }
          })
        )
      ), catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.DeleteColorsofIdDone, message: 'Fehler beim Updaten Farbmarkierung', error: error }));
        return caught;;
      })
    );
  })


  updateorAddColorofUser$ = createEffect(() => {
    return this.actions.pipe(
      ofType<UpdateorAddColorofUser>(CommonDataActionType.UpdateorAddColorofUser),
      mergeMap((action) =>
        this.noviService.updateorAddColorofUser(action.payload.colorofuser).pipe(
          map(response => {
            if ((<any>response).ok == false || (<any>response).body?.error == true) {
              return new CommonDataErrorAction({ type: CommonDataActionType.UpdateorAddColorofUserDone, message: 'Fehler beim Updaten Farbmarkierung ', error: response });
            } else {
              return new UpdateorAddColorofUserDone({ colorofuser: action.payload.colorofuser });
            }
          })
        )
      ), catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.UpdateorAddColorofUserDone, message: 'Fehler beim Updaten Farbmarkierung', error: error }));
        return caught;;
      })
    );
  })


  updateLastAccessConfigs$ = createEffect(() => {
    return this.actions.pipe(
      ofType<UpdateLastAccessConfig>(CommonDataActionType.UpdateLastAccessConfig),
      tap(console.log.bind(window)),
      withLatestFrom(this.state$),
      switchMap(([action, state]) =>
        this.customsettingService.UpdateLastAccessConfig(action.payload.customsetting)
          .pipe(
            map(response => {
              if (response.generalResult != GeneralResultEnum.Success) {
                return new CommonDataErrorAction({ type: CommonDataActionType.UpdateLastAccessConfigDone, message: "Update der Config schlug fehl", error: <SimpleResult>response });
              } else {
                return new UpdateLastAccessConfigDone({ id: action.payload.customsetting.id });
              }
            })
          )
      ), catchError(error => of(new CommonDataErrorAction({ type: CommonDataActionType.UpdateLastAccessConfigDone, message: "Update der Config schlug fehl", error: error }))))
  })


  insertColumnConfigs$ = createEffect(() => {
    return this.actions.pipe(
      ofType<InsertColumnConfig>(CommonDataActionType.InsertColumnConfig),
      tap(console.log.bind(window)),
      withLatestFrom(this.state$),
      switchMap(([action, state]) =>
        this.customsettingService.insertCustomSettings(action.payload.customsetting)
          .pipe(
            map(response => {
              if (response.generalResult != GeneralResultEnum.Success) {
                return new CommonDataErrorAction({ type: CommonDataActionType.InsertColumnConfigDone, message: "Fehler Neue Spalten-Config anlegen", error: <SimpleResult>response });
              } else {
                this.store.dispatch(new UpdateLastAccessConfig({ customsetting: response.data }));
                return new InsertColumnConfigDone({ customsetting: response.data });
              }
            })
          )
      ), catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.InsertColumnConfigDone, message: "Fehler Neue Spalten-Config anlegen", error: error }));
        return caught;;
      }
      ))
  })



  updateColumnConfigs$ = createEffect(() => {
    return this.actions.pipe(
      ofType<UpdateColumnConfig>(CommonDataActionType.UpdateColumnConfig),
      tap(console.log.bind(window)),
      withLatestFrom(this.state$),
      switchMap(([action, state]) =>
        this.customsettingService.updateCustomSettings(action.payload.customsetting)
          .pipe(
            map(response => {
              if (response.generalResult != GeneralResultEnum.Success) {
                return new CommonDataErrorAction({ type: CommonDataActionType.UpdateColumnConfigDone, message: "Fehler Neue Spalten-Config aktualisieren", error: <SimpleResult>response });
              } else {
                this.store.dispatch(new UpdateLastAccessConfig({ customsetting: response.data }));
                return new UpdateColumnConfigDone({ customsetting: response.data });
              }
            })
          )
      ), catchError(error => of(new CommonDataErrorAction({ type: CommonDataActionType.UpdateColumnConfigDone, message: "Fehler Neue Spalten-Config aktualisieren", error: error }))))
  })


  deleteCustomConfigsbyName$ = createEffect(() => {
    return this.actions.pipe(
      ofType<DeleteCustomConfigbyName>(CommonDataActionType.DeleteCustomConfigbyName),
      tap(console.log.bind(window)),
      withLatestFrom(this.state$),
      switchMap(([action, state]) =>
        this.customsettingService.deleteCustomSettingsbyName(action.payload.name, action.payload.modul, action.payload.type)
          .pipe(
            map(response => {
              if (response.generalResult != GeneralResultEnum.Success) {
                return new CommonDataErrorAction({ type: CommonDataActionType.DeleteColumnConfigDone, message: "Fehler  Custom-Configs löschen", error: <SimpleResult>response });
              } else {
                return new DeleteCustomConfigbyNameDone({ id: response.data });
              }
            })
          )
      ), catchError(error => of(new CommonDataErrorAction({ type: CommonDataActionType.DeleteColumnConfigDone, message: "Fehler Custom-Configs löschen", error: error }))))
  })


  deleteColumnConfigs$ = createEffect(() => {
    return this.actions.pipe(
      ofType<DeleteColumnConfig>(CommonDataActionType.DeleteColumnConfig),
      tap(console.log.bind(window)),
      withLatestFrom(this.state$),
      switchMap(([action, state]) =>
        this.customsettingService.deleteCustomSettings(action.payload.id)
          .pipe(
            map(response => {
              if (response.generalResult != GeneralResultEnum.Success) {
                return new CommonDataErrorAction({ type: CommonDataActionType.DeleteColumnConfigDone, message: "Fehler Neue Spalten-Config löschen", error: <SimpleResult>response });
              } else {
                return new DeleteColumnConfigDone({ id: action.payload.id });
              }
            })
          )
      ), catchError(error => of(new CommonDataErrorAction({ type: CommonDataActionType.DeleteColumnConfigDone, message: "Fehler Neue Spalten-Config löschen", error: error }))))
  })


  getColumnConfigs$ = createEffect(() => {
    return this.actions.pipe(
      ofType<GetColumnConfigs>(CommonDataActionType.GetColumnConfigs),
      tap(console.log.bind(window)),
      withLatestFrom(this.state$),
      switchMap(([action, state]) =>
        this.customsettingService.GetCustomSettings(action.payload.userid, action.payload.modul, action.payload.type)
          .pipe(
            map(response => {
              if (response.generalResult != GeneralResultEnum.Success) {
                return new CommonDataErrorAction({ type: CommonDataActionType.GetColumnConfigsDone, message: "Fehler Spalten-Config laden", error: <SimpleResult>response });
              } else {
                return new GetColumnConfigsDone({ columnConfigs: response.data });
              }
            })
          )
      ), catchError(error => of(new CommonDataErrorAction({ type: CommonDataActionType.GetColumnConfigsDone, message: "Fehler Spalten-Config laden", error: error }))))
  })


  getDistinctColorsofUser$ = createEffect(() => {
    return this.actions.pipe(
      ofType<GetDistinctColorsofUser>(CommonDataActionType.GetDistinctColorsofUser),
      tap(console.log.bind(window)),
      withLatestFrom(this.state$),
      switchMap(([action, state]) => {
        return this.noviService.getColorsofUser(action.payload.userid);
      }),
      map(response => new GetDistinctColorsofUserDone({ colorsofUser: response }))
    )
  })

  QueryAlsExcelExportierenAction$ = createEffect(() => {
    return this.actions.pipe(
      ofType<QueryAlsExcelExportierenAction>(CommonDataActionType.QueryAlsExcelExportierenAction),
      withLatestFrom(this.state$),
      switchMap(([action, state]) =>
        this.novitaetService.NovitaetExportXlsxResponse({ queryToken: action.payload.queryToken }).pipe(map(response => {
          if (response.ok == false || (<any>response).body?.error == true) {
            return new CommonDataErrorAction({ type: CommonDataActionType.QueryAlsExcelExportierenAction, message: "Fehler beim Exportieren der Filter-Query als Excel", error: response.status });
          } else {
            this.showFile(response.body, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'xlsx', `Noviexport`);
            return new QueryAlsExcelExportierenActionDone({ file: response.body });
          }
        })
        )),
      catchError((error, caught) => {
        catchError(error => of(new CommonDataErrorAction({ type: CommonDataActionType.QueryAlsExcelExportierenAction, message: "Fehler beim Exportieren der Filter-Query als Excel", error: error })));
        return caught;
      })
    )
  })


  getKategorienderVorabplatzierungen = createEffect(() => {
    return this.actions.pipe(
      ofType<GetKategorienderVorabplatzierungen>(CommonDataActionType.GetKategorienderVorabplatzierungen),
      switchMap((action) =>
        this.sortimentPlatzierungsService.SortimentPlatzierungGetKategorienResponse([action.payload.sortimentid]).pipe(
          map(response => {
            if ((<any>response).ok == false || (<any>response).body?.error == true) {
              return new CommonDataErrorAction({ type: CommonDataActionType.GetKategorienderVorabplatzierungenDone, message: 'Fehler beim Laden der Kategorien aus Vorabplatzierungen', error: response });
            } else {
              return new GetKategorienderVorabplatzierungenDone({ key: action.payload.sortimentid, result: response.body.result });
            }
          })
        )
      ), catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetKategorienderVorabplatzierungenDone, message: 'Fehler beim Laden der Kategorien aus Vorabplatzierrungen', error: error }));
        return caught;;
      })
    );
  })



  getLagerConfigAction$ = createEffect(() => { return  this.actions.pipe(
    ofType<GetLagerAction>(CommonDataActionType.GetLagerAction),
    withLatestFrom(this.state$),
    switchMap(([action, state]) =>
      this.lagerService.LagerQueryLagerResponse(action.payload.params)
        .pipe(
          map(response => {
            if (response.ok != true || (<any>response).body?.error == true) {
              this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetLagerActionDone,  message: "Fehler  Lager laden", error: response }))
            } else {
              return new GetLagerActionDone({ lager: response.body.result });
            }
          })
        )
    ), catchError((error, caught) => {
      this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetLagerActionDone,  message: "Fehler  Lager laden", error: error }))
      return caught;
    })
    )})


getLieferant = createEffect(() => {
  return this.actions.pipe(
    ofType<GetLieferantAction>(CommonDataActionType.GetLieferantAction),
    switchMap((action) =>
      this.lieferantenService.LieferantQueryResponse(action.payload.params).pipe(
        map(response => {
          if ((<any>response).ok == false || (<any>response).body?.error == true) {
            return new CommonDataErrorAction({ type: CommonDataActionType.GetLieferantenActionDone, message: 'Fehler beim Laden eines Lieferanten', error: response });
          } else {
            return new GetLieferantActionDone({  result: response.body.result });
          }
        })
      )
    ), catchError((error, caught) => {
      this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetLieferantenActionDone, message: 'Fehler beim Laden eines Lieferanten', error: error }));
      return throwError(() => error);
    })
  );
})

    getLieferanten = createEffect(() => {
      return this.actions.pipe(
        ofType<GetLieferantenAction>(CommonDataActionType.GetLieferantenAction),
        switchMap((action) =>
          this.lieferantenService.LieferantQueryResponse(action.payload.params).pipe(
            map(response => {
              if ((<any>response).ok == false || (<any>response).body?.error == true) {
                return new CommonDataErrorAction({ type: CommonDataActionType.GetLieferantenActionDone, message: 'Fehler beim Laden der Lieferanten', error: response });
              } else {
                return new GetLieferantenActionDone({  result: response.body.result });
              }
            })
          )
        ), catchError((error, caught) => {
          this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetLieferantenActionDone, message: 'Fehler beim Laden der Lieferanten', error: error }));
          return throwError(() => error);
        })
      );
    })


  getMandanten = createEffect(() => {
    return this.actions.pipe(
      ofType<GetMandantenAction>(CommonDataActionType.GetMandantenAction),
      switchMap((action) =>
        this.mandantenService.MandantQueryResponse(action.payload.params).pipe(
          map(response => {
            if ((<any>response).ok == false || (<any>response).body?.error == true) {
              return new CommonDataErrorAction({ type: CommonDataActionType.GetMandantenActionDone, message: 'Fehler beim Laden der Mandanten', error: response });
            } else {
              return new GetMandantenActionDone({  result: response.body.result });
            }
          })
        )
      ), catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetMandantenActionDone, message: 'Fehler beim Laden der Mandaten', error: error }));
        return throwError(() => error);
      })
    );
  })

  getMandant = createEffect(() => {
    return this.actions.pipe(
      ofType<GetMandantAction>(CommonDataActionType.GetMandantAction),
      switchMap((action) =>
        this.mandantenService.MandantQueryResponse(action.payload.params).pipe(
          map(response => {
            if ((<any>response).ok == false || (<any>response).body?.error == true) {
              return new CommonDataErrorAction({ type: CommonDataActionType.GetMandantenActionDone, message: 'Fehler beim Laden der Mandanten', error: response });
            } else {
              return new GetMandantActionDone({  result: response.body.result });
            }
          })
        )
      ), catchError((error, caught) => {
        this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetMandantenActionDone, message: 'Fehler beim Laden der Mandaten', error: error }));
        return throwError(() => error);
      })
    );
  })


  getSettingsChildrenAction$ = createEffect(() => { return  this.actions.pipe(
    ofType<GetSettingsChildrenAction>(CommonDataActionType.GetSettingsChildrenAction),
    withLatestFrom(this.state$),
    switchMap(([action, state]) =>
        this.settingservice.getSettingsChildren(action.payload.settingvalueId ).pipe(
            map(response => {
                if (response.generalResult != GeneralResultEnum.Success) {
                    return new CommonDataErrorAction( { type: CommonDataActionType.GetSettingsChildrenActionDone, message: "Fehler DetailSettings laden",  error: <SimpleResult>response });
                } else {
                    return new GetSettingsChildrenActionDone({ settingvalueId: action.payload.settingvalueId, settingChildrenValues: response.data });
                }
            })
        )
    ),  catchError(error => of(new CommonDataErrorAction({ type: CommonDataActionType.GetSettingsChildrenActionDone, message: "Fehler DetailSettings laden", error: error } )))
    )})



  getSettings$ = createEffect(() => { return  this.actions.pipe(
    ofType<GetSettingsStateAction>(CommonDataActionType.GetSettings),
    withLatestFrom(this.state$),
    switchMap(([action, state]) =>
        this.settingservice.getSettings(action.payload.settingid, action.payload.onlyActive ).pipe(
            map(response => {
                if (response.generalResult != GeneralResultEnum.Success) {
                  this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetSettingsDone, message: 'Fehler beim Laden von Settings', error: response }));
                } else {
                    return new GetSettingsStateActionDone({ settingValues: response.data });
                }
            })
        )
    ),  catchError(error => of(new CommonDataErrorAction({ type: CommonDataActionType.GetSettingsDone, message: 'Fehler beim Laden von Settings', error: error })))
    )})


    getBranches$ = createEffect(() => { return  this.actions.pipe(
      ofType<GetBranchesAction>(CommonDataActionType.GetBranchesAction),
      withLatestFrom(this.state$),
      switchMap(([action, state]) =>
          this.branchService.BranchQueryBranchResponse(action.payload.params).pipe(
              map(response => {
                  if (!response.ok || (<any>response).body?.error == true) {
                      return new CommonDataErrorAction({ type: CommonDataActionType.GetBranchesActionDone,  message : "Filialen ermitteln",  error: response.body });
                  } else {
                      return new GetBranchesActionDone({ items  : response.body.result });
                  }
              })
          )
      ),  catchError(error => of(new CommonDataErrorAction({ type: CommonDataActionType.GetBranchesActionDone, message : "Filialen ermitteln",   error: error } )))
      )})



    getBrancheGroups$ = createEffect(() => { return  this.actions.pipe(
      ofType<GetBranchGroupsAction>(CommonDataActionType.GetBranchGroupsAction),
      withLatestFrom(this.state$),
      switchMap(([action, state]) =>
          this.branchService.BranchQueryBranchGroupResponse(action.payload.params).pipe(
              map(response => {
                  if (!response.ok || (<any>response).body?.error == true) {
                      return new CommonDataErrorAction({ type: CommonDataActionType.GetBranchGroupsActionDone,  message : "Filiagruppenn ermitteln",  error: response.body });
                  } else {
                      return new GetBranchGroupsActionDone({ items  : response.body.result });
                  }
              })
          )
      ),  catchError(error => of(new CommonDataErrorAction({ type: CommonDataActionType.GetBranchGroupsActionDone, message : "Filialgruppen ermitteln",   error: error } )))
      )})


      getMoebelAction$ = createEffect(() => { return  this.actions.pipe(
        ofType<GetMoebelAction>(CommonDataActionType.GetMoebelAction),
        mergeMap((action) =>
          this.sortimentService.SortimentGetMoebelResponse(action.payload.param).pipe(
            map(response => {
              if (response.body.error == true) {
                return new CommonDataErrorAction({ type: CommonDataActionType.GetMoebelActionDone, message: 'Fehler beim Möbel laden', error: response });
              } else {
                return new GetMoebelActionDone({  moebel: <any>response.body.result as MoebelDto[], params: action.payload.param });
              }
            })
          )
        ), catchError(( error, caught) =>
        {
          this.store.dispatch(new CommonDataErrorAction({ type: CommonDataActionType.GetMoebelActionDone, message: 'Fehler beim Möbel laden', error: error }));
          return throwError(() => error);
        })
        )})



}
