/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfGruppeDto } from '../models/list-response-args-of-gruppe-dto';
import { QueryTokenDTO } from '../models/query-token-dto';
import { ResponseArgsOfGruppeDto } from '../models/response-args-of-gruppe-dto';
import { GruppeDto } from '../models/gruppe-dto';
@Injectable({
  providedIn: 'root',
})
class GruppeService extends __BaseService {
  static readonly GruppeQueryGruppenPath = '/gruppe/s';
  static readonly GruppeDeleteGruppePath = '/gruppe/{id}';
  static readonly GruppeCreateGruppePath = '/gruppe';
  static readonly GruppeUpdateGruppePath = '/gruppe';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Sortimente
   * @param params The `GruppeService.GruppeQueryGruppenParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `eagerLoading`:
   */
  GruppeQueryGruppenResponse(params: GruppeService.GruppeQueryGruppenParams): __Observable<__StrictHttpResponse<ListResponseArgsOfGruppeDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/gruppe/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfGruppeDto>;
      })
    );
  }
  /**
   * Sortimente
   * @param params The `GruppeService.GruppeQueryGruppenParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `eagerLoading`:
   */
  GruppeQueryGruppen(params: GruppeService.GruppeQueryGruppenParams): __Observable<ListResponseArgsOfGruppeDto> {
    return this.GruppeQueryGruppenResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfGruppeDto)
    );
  }

  /**
   * Gruppe Löschen
   * @param id undefined
   */
  GruppeDeleteGruppeResponse(id: number): __Observable<__StrictHttpResponse<ResponseArgsOfGruppeDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/gruppe/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfGruppeDto>;
      })
    );
  }
  /**
   * Gruppe Löschen
   * @param id undefined
   */
  GruppeDeleteGruppe(id: number): __Observable<ResponseArgsOfGruppeDto> {
    return this.GruppeDeleteGruppeResponse(id).pipe(
      __map(_r => _r.body as ResponseArgsOfGruppeDto)
    );
  }

  /**
   * Neue Gruppe
   * @param payload undefined
   */
  GruppeCreateGruppeResponse(payload: GruppeDto): __Observable<__StrictHttpResponse<ResponseArgsOfGruppeDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/gruppe`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfGruppeDto>;
      })
    );
  }
  /**
   * Neue Gruppe
   * @param payload undefined
   */
  GruppeCreateGruppe(payload: GruppeDto): __Observable<ResponseArgsOfGruppeDto> {
    return this.GruppeCreateGruppeResponse(payload).pipe(
      __map(_r => _r.body as ResponseArgsOfGruppeDto)
    );
  }

  /**
   * Update Gruppe
   * @param payload undefined
   */
  GruppeUpdateGruppeResponse(payload: Array<GruppeDto>): __Observable<__StrictHttpResponse<ListResponseArgsOfGruppeDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/gruppe`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfGruppeDto>;
      })
    );
  }
  /**
   * Update Gruppe
   * @param payload undefined
   */
  GruppeUpdateGruppe(payload: Array<GruppeDto>): __Observable<ListResponseArgsOfGruppeDto> {
    return this.GruppeUpdateGruppeResponse(payload).pipe(
      __map(_r => _r.body as ListResponseArgsOfGruppeDto)
    );
  }
}

module GruppeService {

  /**
   * Parameters for GruppeQueryGruppen
   */
  export interface GruppeQueryGruppenParams {
    queryToken: QueryTokenDTO;
    eagerLoading?: number;
  }
}

export { GruppeService }
