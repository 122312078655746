import { CanNotBeDeletedReason, KlammerDto } from "@app/api/filialmatrix/models";


export class KlammerDtoClient implements  KlammerDto
{
   constructor(dto: KlammerDto)
  {
    this.assortments = dto.assortments;
    this.canBeDeleted = dto.canBeDeleted;
    this.canNotBeDeletedReason = dto.canNotBeDeletedReason;
    this.id = dto.id;
    this.isVisible = dto.isVisible;
    this.name  = dto.name;
    this.sortNumber = dto.sortNumber;
  }
  assortments?: number;
  canBeDeleted?: boolean;
  canNotBeDeletedReason: CanNotBeDeletedReason;
  id: number;
  isVisible: boolean;
  name?: string;
  sortNumber?: number;
  

  get assortmentmasterId() : string {
    if (this.id == undefined) return null;
    const min = "9070000000000000000";
    if (Number(this.id) < Number(min))
    {
      var result =  min.substring(0,min.length -this.id.toString().length) + this.id.toString();
      return result;
    }
    return this.id.toString();
}


}
