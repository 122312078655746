import { AppState as RootState } from '../../store';
import { createFeatureSelector } from '@ngrx/store';
import { TaskUserModel, ResultObjectModel,  ValidationList } from '@app/common/models';
import { TypDto } from '@app/api/filialmatrix/models';
import { SortimentDtoClient } from '@app/common/models/sortimente/SortimentDtoClient';
import { KlammerDtoClient } from '@app/common/models/sortimente/KlammerDtoClient';

export interface TaskEditorState {
    sortmasters: KlammerDtoClient[];
    sortiments: SortimentDtoClient[];

    typeInfos: TypDto[];
    taskname: string;
    selectedValidationProfile: string;
    validationProfiles: ValidationList[];
    breadcrumb: string;
    currentUser: Partial<TaskUserModel>;
    selectableUsers: TaskUserModel[];
    lastResult: ResultObjectModel<any>;
    selectedUsers: TaskUserModel[];
    loading: boolean;
    usersLoading: boolean;
    savingTask: boolean;

}


export interface AppState extends RootState {
    taskeditor: TaskEditorState;
}

export const InitialTaskEditorState: TaskEditorState = {
    taskname: '',
    selectedValidationProfile: '',
    validationProfiles: [],
    sortmasters: [],
    sortiments: [],
    
    typeInfos: [],
    breadcrumb: '',
    currentUser: {},
    selectableUsers: [],
    lastResult: null,
    selectedUsers: [],
    loading: false,
    usersLoading: false,
    savingTask: false,

};

export const featureName = 'taskeditor';

export const selectTaskEditorState = createFeatureSelector<TaskEditorState>(featureName);

