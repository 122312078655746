import { Component, computed, OnInit, signal, WritableSignal } from '@angular/core';
import { FinishUploadByManagerAction, ListEditorActionType, FinishUploadByManagerDoneAction } from '@app/+list-editor/store';


import { LipoMessageService } from '@app/common/services/LipoMessageService';
import { select, Store } from '@ngrx/store';
import { Message } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import * as momentLib from 'moment';
import { Subscription } from 'rxjs/internal/Subscription';
import { Actions, ofType } from '@ngrx/effects';
import { GruppeDto, KlammerDto } from '@app/api/filialmatrix/models';
import { GetKlammernAction, GetSortimenteAction, selectallKlammern, selectallSortimente } from '@app/common-data-module/store';
import { KlammerService, SortimentService } from '@app/api/filialmatrix/services';
import { QueryTokenDTO } from '@app/api/commonWebAPI/models';
import { KlammerDtoClient } from '@app/common/models/sortimente/KlammerDtoClient';
import { SortimentDtoClient } from '@app/common/models/sortimente/SortimentDtoClient';

import { LookupValueBase } from '@app/common/models/novi/columnLookup.model';
import { CommonCheckService } from '@app/common/services/common.validation.service';
import { AufgabenArt } from '@app/common/models';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { FinishUploadParam } from '@app/+list-editor/models/updateparam';
import { Observable, of, take } from 'rxjs';
import { NovitaetenLookupService } from '@app/common-data-module/services/NovitaetenLookupService';
import { NovitätEnum } from '@app/common/models/novi/NovitätenTableCol';




@Component({
  selector: 'app-transfer-dialog',
  templateUrl: './transfer-dialog.component.html',
  styleUrls: ['./transfer-dialog.component.scss']
})
export class TransferDialogComponent implements OnInit {
  taskId: number;
  taskName: string;
  sortiment: SortimentDtoClient;
  sortimentName: string;
  selectedRows: number[];
  userId: number;
  userOrganisation: string;
  ignoreIds: number[];

  expirationDate?: Date;


  minDateValue: Date;
  maxDateValue: Date;

  actionSubscription: Subscription;

  selectedSortiment: SortimentDtoClient;




  aufgabenArten: WritableSignal<LookupValueBase[]> = signal([]);
  selectedAufgabenArt: WritableSignal<AufgabenArt> = signal(AufgabenArt.Artikelliste);
  sortimente$: Observable<SortimentDtoClient[]> = of ([]);
  sortimente: WritableSignal<SortimentDtoClient[]> = signal([]);
  sortimentGruppen$: Observable<GruppeDto[]> = of([]);
  selectedSortimentGruppe: GruppeDto;


  constructor(public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public store: Store<any>,
    private msgService: LipoMessageService<Message>,
    private commoncheck: CommonCheckService,
    private lookupService: NovitaetenLookupService,
    public _actions$: Actions) {

    let start = momentLib(new Date()).startOf('day');
    this.minDateValue = start.clone().toDate();
    this.maxDateValue = start.clone().add(2, 'month').toDate();
    this.aufgabenArten.set(
      [<LookupValueBase>{ id: AufgabenArt.Artikelliste, value: "Sortimentieren" },
      <LookupValueBase>{ id: AufgabenArt.Novität, value: "Novitäteneinkauf" }]
    );


    this.sortimente$ = this.store.pipe(select(selectallSortimente));
    this.sortimentGruppen$ = this.lookupService.selectallSortimentsGruppen$;
    this.sortimente$.subscribe(s=> this.sortimente.set([...s]));

  }

  ngOnInit(): void {


    this.taskId = this.config.data.taskId as number;
    this.taskName = this.config.data.taskName as string;
    this.sortiment = this.config.data.sortiment as SortimentDtoClient;
    this.sortimentName = this.config.data.sortimentName as string;
    this.selectedRows = this.config.data.selectedRows as number[];
    this.userId = this.config.data.userId as number;
    this.userOrganisation = this.config.data.userOrganisation as string;

    this.expirationDate = undefined; // initial leer #6344


    var pk = <SortimentService.SortimentQuerySortimenteParams>{}
    pk.eagerLoading = 2;
    pk.queryToken = <QueryTokenDTO>{
      take: null, skip: null,
      orderBy: [{ by: "name", desc: false }]
    }
    this.store.dispatch(new GetSortimenteAction({ param: pk }));

    this.lookupService.loadEditLookupFilterData(NovitätEnum.sortimentsgruppe, false)


  }


  canSelectVerfallsdatum = computed(() => {
    return  this.selectedAufgabenArt() == AufgabenArt.Artikelliste;
  })


  canSelectSortiment = computed(() => {
    return true;  //  this.selectedAufgabenArt() == AufgabenArt.Artikelliste;
  })

  selectAufgabenArtChange(e: DropdownChangeEvent) {
    this.selectedAufgabenArt.set(e.value);
    if (this.selectedAufgabenArt() == AufgabenArt.Novität) {
      this.selectedSortiment = null;
      this.expirationDate = null;
      this.selectedSortimentGruppe = null;
    }
  }

  /** Speicher + Übetragen möglich */
  canSaveAndTransfer(): boolean {
    if (this.selectedAufgabenArt() == AufgabenArt.Novität) {
      return this.taskName != undefined
        && this.taskName.length > 1
        && this.taskId > 0
    } else {
      return this.taskName != undefined
        && this.taskName.length > 1
        && this.expirationDate != undefined
        && this.taskId > 0
        && this.sortiment != undefined;
    }
  }

  /** Speicher + Übertragen */
  saveAndTransfer() {
    var param = <FinishUploadParam>{
      sortimentId: this.sortiment?.assortmentId,
      klammerId: this.sortiment?.klammer?.assortmentmasterId,
      name: this.taskName,
      expirationDate: this.expirationDate,
      selectedRows: this.selectedRows,
      aufgabenArt: this.selectedAufgabenArt()
    }
    this.store.dispatch(new FinishUploadByManagerAction({ taskId: this.taskId,  params: param    }));

    this.actionSubscription = this._actions$.pipe(ofType<FinishUploadByManagerDoneAction>(ListEditorActionType.FinishUploadByManagerDone)).subscribe((data: any) => {
      this.cancel();
    });
  }

  /** Abbrechen möglich */
  canCancel(): boolean {
    return true;
  }

  /** Abbrechen */
  cancel(): void {

    this.msgService.Clear();
    this.ref.close(null);
  }

  /**Selektieren der Sortimente anhand der Sortimentsgruppe */
  selectedSortimentGruppeChange()
  {
      this.sortimente$.pipe(take(1)).subscribe(s=> {
      if (this.selectedSortimentGruppe && this.selectedSortimentGruppe.id )
      {
        this.sortimente.set([...s.filter(f=> f.group && f.group.id == this.selectedSortimentGruppe.id )]);
        if (  this.selectedSortiment &&  this.selectedSortiment.id  && this.sortimente().find(s=> s.id == this.selectedSortiment.id) == undefined)
        {
            this.selectedSortiment = null;
        }
      } else {
        this.sortimente.set([...s])
      }
    }
    );
  }
  selectedSortimentChange() {
    this.sortiment = this.selectedSortiment;
    this.sortimentName = this.selectedSortiment?.name;


  }
}
