import { TaskUserModel } from '@app/common/models';
import { IAuthUser, IClientError } from '@app/common/interfaces/Interfaces';



export enum UserActionType {
  // GetUserDetails = '[USER] GetUserDetails',
  GetUserDetailsDone = '[USER] GetUserDetailsDone',
  SetUserLoggedInData = '[USER] SetUserLoggedInData',
  GetLoggedInUserDetails = '[USER] GetLoggedInUserDetails',
  GetAuthUserAction = "[USER] GetAuthUserAction",
  GetAuthUserActionDone = "[USER] GetAuthUserActionDone",
  GetAuthUserErrorAction = "[User] GetAuthUserErrorAction",
  LogUsersClientErrorAction = "[User] LogUsersClientErrorAction",
  LogUsersClientErrorActionDone = "LogUsersClientErrorActionDone",
}

/**User hat clientseitig einen Fehler aber offene Verbindung zu Services
 * Error aus catch (e)
 */
export class LogUsersClientErrorAction {
  readonly type = UserActionType.LogUsersClientErrorAction;
  constructor(public payload: { error: IClientError }) {}
}

export class LogUsersClientErrorActionDone {
  readonly type = UserActionType.LogUsersClientErrorActionDone;
  constructor(public payload: { error: IClientError }) {}
}


export class GetAuthUserAction {
    readonly type = UserActionType.GetAuthUserAction;
    constructor(public payload: {}) {}
}

export class GetAuthUserActionDone {
    readonly type = UserActionType.GetAuthUserActionDone;
    constructor(public payload: {user: IAuthUser}) {}
}


export class GetAuthUserErrorAction {
    readonly type = UserActionType.GetAuthUserErrorAction;
    constructor(public payload: {error: any}) {}
}


export class GetUserDetailsDoneAction {
    readonly type = UserActionType.GetUserDetailsDone;
    constructor(public payload: {user: TaskUserModel}) {}
}


export class SetUserLoggedInDataAction {
    readonly type = UserActionType.SetUserLoggedInData;
    constructor(public payload: { userInfo: object }) {}
}

export class GetLoggedInUserDetailsAction {
    readonly type = UserActionType.GetLoggedInUserDetails;
}


export type UserAction = GetUserDetailsDoneAction | SetUserLoggedInDataAction | GetAuthUserAction
| GetAuthUserActionDone | GetAuthUserErrorAction | LogUsersClientErrorAction

;
