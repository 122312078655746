export interface Notification {
    from: string;
    to: string;
    subject: string;
    message: string;
    activity: string;
    timestamp: Date;
    id: number;
    responded: boolean;
}
