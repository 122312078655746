
import { createFeatureSelector } from '@ngrx/store';
import { IMediaTip, MediaTipTask, MediaTipActionDone } from '@app/common/models/mediatips.models';
import { SimpleResult, SimpleResultError } from '@app/common/models/simpleResult.model';
import { IBubData, BubDataResult } from '@app/common/models/bubdata.model';
import { LiPoContact } from '@app/common/models/lipocontact.model';

export interface MediaTipState {
    isLoading: boolean,
    currenterror: SimpleResult,
    mediatips: IMediaTip[],
    mediatipsHits: number;
    mediatipActionDone: MediaTipActionDone,
    mediatipsTasks: MediaTipTask[],
    bubData: BubDataResult,
    bubDataList: IBubData[],
    isLoadingbubData: boolean,
    SoMaContacts: LiPoContact[],
}

export const InitialMediaTipState: MediaTipState = {
    isLoading: false,
    mediatips: new Array <IMediaTip>(),
    mediatipsTasks: new Array <MediaTipTask>(),
    currenterror: <SimpleResult>{},
    mediatipActionDone: <MediaTipActionDone>{},
    isLoadingbubData: false,
    bubData: <BubDataResult>{},
    bubDataList: [],
    SoMaContacts:  new Array <LiPoContact>(),
    mediatipsHits: 0
};


export const mediatipFeatureName = 'mediatipView';
export const selectmediatipState = createFeatureSelector<MediaTipState>(mediatipFeatureName);
