import {  EinkaufDto, EntityStatus } from "@app/api/filialmatrix/models";


export class EinkaufDtoClient implements  EinkaufDto
{
   constructor(dto: EinkaufDto)
  {
      this.name = dto.name;
      this.start = dto.start;
      this.stop = dto.stop;
      this.changed = dto.changed;
      this.created = dto.created
      this.id = dto.id;
      this.pId = dto.pId;
      this.status = dto.status;
      this.version = dto.version;


  }
  name?: string;
  start: string;
  stop: string;
  changed?: string;
  created?: string;
  id?: number;
  pId?: string;
  status?: EntityStatus;
  version?: number;

  readonly: boolean = false;
  unknown: boolean = false;


  get einkaufLongId() : string {
    if (this.id == undefined) return null;
    const min = "8160000000000000000";
    if (Number(this.id) < Number(min))
    {
      var result =  min.substring(0,min.length -this.id.toString().length) + this.id.toString();
      return result;
    }
    return this.id.toString();
}


}
