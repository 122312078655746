
export interface IDxColumn {
  name: string;
}

export class CustomSetting<T> {
  id: number;
  userId: string;
  name: string = '';
  lastAccess: number;
  modul: CustomSettingsModulEnum;
  type: CustomSettingsTypeEnum;
  objectType: string;
  settingValue: string;
  ///eventuelles object wenn möglich
  data: T;


  static isDefault(sett : CustomSetting<any>) : boolean {
    if (sett == undefined) return false;
    return sett.name && sett.name == 'Standard';
  }

}

export enum CustomSettingsTypeEnum {
  unknown = 0,
  Columns = 1,
  Filter = 2,
  WupZeitraum = 3,
  WupZielliste = 4

}

export enum CustomSettingsModulEnum {
  unknown = 0,
  NoviList = 1,
  WuPList =2,
  GeneralSetting = 3,
  UploadEditor = 4,

}


