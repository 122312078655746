import { NgModule } from '@angular/core';
import { ListEditorRoutingModule } from './list-edit-routing.module';
import { ListEditorStateModule } from './list-edit-state.module';
import { ListEditorModule } from './pages/list-editor.module';
import { FormsModule } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { CommonDataModule } from '@app/common-data-module/common-data-module.module';



@NgModule({
    imports: [
        ListEditorRoutingModule, ListEditorStateModule, ListEditorModule, FormsModule,
        CommonDataModule
    ],
    exports: [],
    declarations: [],
    providers:[DecimalPipe]
})

export class ListEditModule { }
