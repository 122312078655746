import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { UserService } from '@app/common/services/user.service';
import { UserActionType, GetAuthUserAction, GetAuthUserErrorAction, GetAuthUserActionDone, LogUsersClientErrorAction, LogUsersClientErrorActionDone } from './actions';
import { switchMap, map, withLatestFrom, catchError, tap } from 'rxjs/operators';
import { AuthService } from '@app/common/services/auth.service';
import { Store, select } from '@ngrx/store';

import { IAuthUser, IClientError } from '@app/common/interfaces/Interfaces';
import { of } from 'rxjs';
import { SettingService } from '@app/common/services/setting.service';
import { SignalMessageService } from '@app/common/services/signalmessage.service';
import { GeneralResultEnum } from '@app/common/models';
import { CommonCheckService } from '@app/common/services/common.validation.service';

@Injectable()
export class UserEffects {
  state$ = this.store.select(state => state.AppState); // this.store.pipe(select(selectuserState));
  constructor(
    private store: Store<any>,
    private actions: Actions,
    private userservice: UserService,
    private settingservice: SettingService,
    private authService: AuthService,
    private lipocheck: CommonCheckService,
    private  convertlipo : CommonCheckService
  ) { }


  getAuthUserEffect$ = createEffect(() => { return  this.actions.pipe(
    ofType<GetAuthUserAction>(UserActionType.GetAuthUserAction),
    withLatestFrom(this.state$),
    switchMap(([action]) =>
      this.authService.claims$.pipe(
        map(f => {
          var usermodel = <IAuthUser>{ name: f.name, id: f.sub, client_id: f.client_id, member_of: f.member_of, shortId: this.lipocheck.getShortUserid(f.sub) }
          return new GetAuthUserActionDone({ user: usermodel });
        })
      )
    ), catchError(error => of(new GetAuthUserErrorAction({ error: error })))
  )})





  logClientUserErrorEffect$ = createEffect(() => { return  this.actions.pipe(
    ofType<LogUsersClientErrorAction>(UserActionType.LogUsersClientErrorAction),
    withLatestFrom(this.state$),
    switchMap(([action]) =>
      this.settingservice.logClientError(<IClientError>{
        error: this.convertlipo.stringify(action.payload.error.error),
        modul: action.payload.error.modul, userid: action.payload.error.userid
      }
      ).pipe(
        map(f => {
          return new LogUsersClientErrorActionDone({ error: action.payload.error });
        })
        , catchError(error => {
          console.debug("While Loggging getting Error: " + JSON.stringify(error));
          return of();
        }
        )
      )
    )
  )})



}



