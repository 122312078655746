import { TypDto } from "@app/api/filialmatrix/models";

export class TypDtoClient implements TypDto
{

  constructor(dto: TypDto)
  {
    this.id = dto.id;
    this.key= dto.key;
    this.name = dto.name;
  }


  id: number;
  key?: string;
  name?: string;



  get assortmenttypeId() : string {
    if (this.id == undefined) return null;
    const min = "9200000000000000000";
    if (Number(this.id) < Number(min))
    {
      return min.substring(0,min.length -this.id.toString().length) + this.id.toString();
    }
    return this.id.toString();
}

}
