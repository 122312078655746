import { LookupValue } from "../novi/columnLookup.model";

/**
  * Default: Bei Saison "Weihnachten" -> 61  bei Saison "Ostern" 62; wenn "Ostern" und "Weihnachten" entahlten in Saison, dann 0; sonst 0

  */
export enum JWWSPflegestatusEnum {
  automatischerstellt = 0,
  //Saison angeblich
  ATitel = 60,
  AdventWeihnacht = 61,
  Ostern = 62,

}



export class StatusPflegeDefaults {

  static list: JWWSPflegestatusDef[] = [
    <JWWSPflegestatusDef>{ value: JWWSPflegestatusEnum.automatischerstellt, text: "Nicht gesetzt", iconstyle: "color: gray;" },




    <JWWSPflegestatusDef>{ value: JWWSPflegestatusEnum.ATitel, text: "Saison", iconstyle: "" },

    <JWWSPflegestatusDef>{ value: JWWSPflegestatusEnum.AdventWeihnacht, text: "Advent / Weihnachten", iconstyle: "" },

    <JWWSPflegestatusDef>{ value: JWWSPflegestatusEnum.Ostern, text: "Ostern", iconstyle: "" },

  ];




  static getValidList(): JWWSPflegestatusDef[] {
    return StatusPflegeDefaults.list.filter(l=>
      l.value == JWWSPflegestatusEnum.automatischerstellt ||  l.value == JWWSPflegestatusEnum.AdventWeihnacht || l.value == JWWSPflegestatusEnum.Ostern      || l.value == JWWSPflegestatusEnum.ATitel        )  }
}

export class JWWSPflegestatusDef {
  value: JWWSPflegestatusEnum;
  text: string;
  iconstyle: string;

}
