import { NgModule } from '@angular/core';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { CommonModule } from '@angular/common';
import { NotificationsModule } from '@app/+notifications/notifications.module';
import { ListOverviewModule } from '@app/+list-overview/list-overview.module';
import { TaskListModule } from '@app/+task-list/task-list.module';
import { ListEditorModule } from '@app/+list-editor/pages/list-editor.module';
import { CalendarModule } from '@app/+calendar/calendar.module';
import { AngularSplitModule } from 'angular-split';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {TableModule} from 'primeng/table';
import {MatIconModule} from '@angular/material/icon';

import {ProgressBarModule} from 'primeng/progressbar';


import { ArticledataModule } from '@app/+articledata/articledata.module';

import { MatButtonModule } from '@angular/material/button';
import { NovitaetendiagrammSomaComponent } from './components/novitaetendiagramm-soma/novitaetendiagramm-soma.component';
import { PanelModule } from 'primeng/panel';


@NgModule({
    imports: [NgxChartsModule, TableModule,ProgressBarModule,MatIconModule,  ArticledataModule, MatButtonModule,
          DashboardRoutingModule, CommonModule, NotificationsModule, ListOverviewModule,  ListEditorModule, CalendarModule, AngularSplitModule, PanelModule
    ],
    exports: [],
    declarations: [
        DashboardComponent,
        NovitaetendiagrammSomaComponent
    ],
    providers: [],
})
export class DashboardModule { }
