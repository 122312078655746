export interface DownloadItem {
    //Setting ID
    id: number;
    name: string;
    summary?: string;
    size: number;
    date: Date;
    url: string;
    kategorie: string;
}
