import { Injectable } from '@angular/core';
import { Observable, of, EMPTY } from 'rxjs';
import { environment } from '@env/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ValidationList, ResultObjectModel } from '@app/common/models';
import { OAuthService } from 'angular-oauth2-oidc';
import { NotificationService } from './notification.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ValidationService {

  constructor(private httpClient: HttpClient, private oauthService: OAuthService, private notifier: NotificationService) {}

  getValidationProfiles(): Observable<ValidationList[]> {
    console.log('Getting Validation-Profiles...');

    const url = `${environment.connections.ValidationEditService}/ValidationProcess/GetActiveValidationLists`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.oauthService.getAccessToken()
      })
    };
    let result: ValidationList[];
    const httpResult = this.httpClient.get<ResultObjectModel<ValidationList[]>>(url, httpOptions).pipe(
      map(x => {
          if (x.generalResult != 0) {
              this.notifier.showError('ServiceError: Fehler beim Laden der aktiven Validierungslisten: ' + x.userMessage);
              return result;
          }
          result = x.data;
          return result;
      }),
      catchError(x => {
          this.notifier.showError('Fehler beim Laden der aktiven Validierungslisten: ' + x.message);
          return of ([]);
      })
  );
  return httpResult;
  }
}
