import { MenuItem } from 'primeng/api/menuitem';
import { MessageTypeEnum } from './signal.model';


export interface NavigationTab{
    displayName: string;
    url: string;
}

export enum NavMenuItemTypeEnum
{
    default = 0,
    flexible = 1
}

export class NavMenuItem
{
    /**genaue Kennung für Testing*/
    id: string;
    label: string;
    tooltip: string;
    routerlink: string;
    icon: string;
    iconClass: string;
    seperator: boolean;
    active: string;
    type: NavMenuItemTypeEnum;
    notificationCount: number;
    //Medientipps etc..
    children: NavMenuItem[];
    messageTypes?: MessageTypeEnum[];
}
