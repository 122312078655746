import { ValidationListColumn } from './validationListColumn.model';

export interface ValidationList {
    id: number;
    listName: string;
    isActive: boolean;
    isSupplier: boolean;
    listColums: ValidationListColumn[];
    createdAt: Date;
    changedAt: Date;
}
