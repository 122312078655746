/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfDispolistParameterDto } from '../models/list-response-args-of-dispolist-parameter-dto';
import { QueryTokenDTO } from '../models/query-token-dto';
import { DispolistParameterDto } from '../models/dispolist-parameter-dto';
@Injectable({
  providedIn: 'root',
})
class SortimentDispolistParameterService extends __BaseService {
  static readonly SortimentDispolistParameterQueryDispolistParameterPath = '/sortiment/dispo/s';
  static readonly SortimentDispolistParameterCreatePath = '/sortiment/{sortimentId}/dispo';
  static readonly SortimentDispolistParameterDeleteSortimentDispolistParameterPath = '/sortiment/{sortimentId}/dispo/{id}';
  static readonly SortimentDispolistParameterUpdateSortimentDispolistParameterPath = '/sortiment/{sortimentId}/dispo/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Dispoliste Parameter
   * @param params The `SortimentDispolistParameterService.SortimentDispolistParameterQueryDispolistParameterParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `onePerAssignment`: False: Alle per Sortimentszuordnung verknüpfte Sortimente sind einzeln in der Ergebnisliste enthalten.
   *   True: Je Sortimentszordnung nur ein Eintrag in der Ergebnisliste.
   *
   * - `eagerLoading`:
   */
  SortimentDispolistParameterQueryDispolistParameterResponse(params: SortimentDispolistParameterService.SortimentDispolistParameterQueryDispolistParameterParams): __Observable<__StrictHttpResponse<ListResponseArgsOfDispolistParameterDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.onePerAssignment != null) __params = __params.set('onePerAssignment', params.onePerAssignment.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sortiment/dispo/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfDispolistParameterDto>;
      })
    );
  }
  /**
   * Dispoliste Parameter
   * @param params The `SortimentDispolistParameterService.SortimentDispolistParameterQueryDispolistParameterParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `onePerAssignment`: False: Alle per Sortimentszuordnung verknüpfte Sortimente sind einzeln in der Ergebnisliste enthalten.
   *   True: Je Sortimentszordnung nur ein Eintrag in der Ergebnisliste.
   *
   * - `eagerLoading`:
   */
  SortimentDispolistParameterQueryDispolistParameter(params: SortimentDispolistParameterService.SortimentDispolistParameterQueryDispolistParameterParams): __Observable<ListResponseArgsOfDispolistParameterDto> {
    return this.SortimentDispolistParameterQueryDispolistParameterResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfDispolistParameterDto)
    );
  }

  /**
   * Create Parameter
   * @param params The `SortimentDispolistParameterService.SortimentDispolistParameterCreateParams` containing the following parameters:
   *
   * - `sortimentId`: Sortiment Id
   *
   * - `payload`:
   *
   * - `user`: User Id oder Token
   */
  SortimentDispolistParameterCreateResponse(params: SortimentDispolistParameterService.SortimentDispolistParameterCreateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sortiment/${encodeURIComponent(String(params.sortimentId))}/dispo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Create Parameter
   * @param params The `SortimentDispolistParameterService.SortimentDispolistParameterCreateParams` containing the following parameters:
   *
   * - `sortimentId`: Sortiment Id
   *
   * - `payload`:
   *
   * - `user`: User Id oder Token
   */
  SortimentDispolistParameterCreate(params: SortimentDispolistParameterService.SortimentDispolistParameterCreateParams): __Observable<null> {
    return this.SortimentDispolistParameterCreateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Delete Parameter
   * @param params The `SortimentDispolistParameterService.SortimentDispolistParameterDeleteSortimentDispolistParameterParams` containing the following parameters:
   *
   * - `sortimentId`: Sortiment Id
   *
   * - `id`: Parameter Id
   *
   * - `user`: User Id oder Token
   */
  SortimentDispolistParameterDeleteSortimentDispolistParameterResponse(params: SortimentDispolistParameterService.SortimentDispolistParameterDeleteSortimentDispolistParameterParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/sortiment/${encodeURIComponent(String(params.sortimentId))}/dispo/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete Parameter
   * @param params The `SortimentDispolistParameterService.SortimentDispolistParameterDeleteSortimentDispolistParameterParams` containing the following parameters:
   *
   * - `sortimentId`: Sortiment Id
   *
   * - `id`: Parameter Id
   *
   * - `user`: User Id oder Token
   */
  SortimentDispolistParameterDeleteSortimentDispolistParameter(params: SortimentDispolistParameterService.SortimentDispolistParameterDeleteSortimentDispolistParameterParams): __Observable<null> {
    return this.SortimentDispolistParameterDeleteSortimentDispolistParameterResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update Sortimente
   * @param params The `SortimentDispolistParameterService.SortimentDispolistParameterUpdateSortimentDispolistParameterParams` containing the following parameters:
   *
   * - `sortimentId`:
   *
   * - `payload`:
   *
   * - `id`:
   *
   * - `user`:
   */
  SortimentDispolistParameterUpdateSortimentDispolistParameterResponse(params: SortimentDispolistParameterService.SortimentDispolistParameterUpdateSortimentDispolistParameterParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/sortiment/${encodeURIComponent(String(params.sortimentId))}/dispo/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update Sortimente
   * @param params The `SortimentDispolistParameterService.SortimentDispolistParameterUpdateSortimentDispolistParameterParams` containing the following parameters:
   *
   * - `sortimentId`:
   *
   * - `payload`:
   *
   * - `id`:
   *
   * - `user`:
   */
  SortimentDispolistParameterUpdateSortimentDispolistParameter(params: SortimentDispolistParameterService.SortimentDispolistParameterUpdateSortimentDispolistParameterParams): __Observable<null> {
    return this.SortimentDispolistParameterUpdateSortimentDispolistParameterResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module SortimentDispolistParameterService {

  /**
   * Parameters for SortimentDispolistParameterQueryDispolistParameter
   */
  export interface SortimentDispolistParameterQueryDispolistParameterParams {
    queryToken: QueryTokenDTO;

    /**
     * False: Alle per Sortimentszuordnung verknüpfte Sortimente sind einzeln in der Ergebnisliste enthalten.
     * True: Je Sortimentszordnung nur ein Eintrag in der Ergebnisliste.
     */
    onePerAssignment?: boolean;
    eagerLoading?: number;
  }

  /**
   * Parameters for SortimentDispolistParameterCreate
   */
  export interface SortimentDispolistParameterCreateParams {

    /**
     * Sortiment Id
     */
    sortimentId: number;
    payload: DispolistParameterDto;

    /**
     * User Id oder Token
     */
    user?: null | string;
  }

  /**
   * Parameters for SortimentDispolistParameterDeleteSortimentDispolistParameter
   */
  export interface SortimentDispolistParameterDeleteSortimentDispolistParameterParams {

    /**
     * Sortiment Id
     */
    sortimentId: number;

    /**
     * Parameter Id
     */
    id: number;

    /**
     * User Id oder Token
     */
    user?: null | string;
  }

  /**
   * Parameters for SortimentDispolistParameterUpdateSortimentDispolistParameter
   */
  export interface SortimentDispolistParameterUpdateSortimentDispolistParameterParams {
    sortimentId: number;
    payload: DispolistParameterDto;
    id: number;
    user?: null | string;
  }
}

export { SortimentDispolistParameterService }
