import { ViewEncapsulation } from "@angular/core";
import { JWWSTransferType } from "@app/api/filialmatrix/models";

export enum JWWSTransferTypeEnum {
   NotSet       = 0,
   voll         = 1,
   Info         = 2,
   Bedarfsmenge = 4,
   Pflegestatus = 8,
   Kategorie    = 16,
   Region       = 32,
   WG           = 64,
   Hersteller   = 128,
   MS           = 256,
   EKnetto      = 512,
   VK           = 1024,
   Lieferart    = 2048,
   MinBestand   = 4096,
   Bestellmenge = 8192

}



export class StatusTransferTypeDefaults {



  static list: JWWSTransferTypeDef[] = [
    <JWWSTransferTypeDef>{ id: JWWSTransferTypeEnum.NotSet, text: "Nicht gesetzt", iconstyle: "color: gray;" },
    <JWWSTransferTypeDef>{ id: JWWSTransferTypeEnum.voll, text: "Voll", iconstyle: "" },
    <JWWSTransferTypeDef>{ id: JWWSTransferTypeEnum.Info, text: "Info", iconstyle: "" },
    <JWWSTransferTypeDef>{ id: JWWSTransferTypeEnum.Bedarfsmenge, text: "Bedarfsmenge", iconstyle: "" },
    <JWWSTransferTypeDef>{ id: JWWSTransferTypeEnum.Pflegestatus, text: "Pflegestatus", iconstyle: "" },
    <JWWSTransferTypeDef>{ id: JWWSTransferTypeEnum.Kategorie, text: "Kategorie", iconstyle: "" },

    <JWWSTransferTypeDef>{ id: JWWSTransferTypeEnum.Region, text: "Region", iconstyle: "" },

    <JWWSTransferTypeDef>{ id: JWWSTransferTypeEnum.WG, text: "Warengruppe", iconstyle: "" },

    <JWWSTransferTypeDef>{ id: JWWSTransferTypeEnum.Hersteller, text: "Hersteller", iconstyle: "" },

    <JWWSTransferTypeDef>{ id: JWWSTransferTypeEnum.MS, text: "MS", iconstyle: "" },

    <JWWSTransferTypeDef>{ id: JWWSTransferTypeEnum.EKnetto, text: "EK netto", iconstyle: "" },

    <JWWSTransferTypeDef>{ id: JWWSTransferTypeEnum.VK, text: "VK", iconstyle: "" },

    <JWWSTransferTypeDef>{ id: JWWSTransferTypeEnum.Lieferart, text: "Lieferart", iconstyle: "" },

    <JWWSTransferTypeDef>{ id: JWWSTransferTypeEnum.MinBestand, text: "MinBestand", iconstyle: "" },

    <JWWSTransferTypeDef>{ id: JWWSTransferTypeEnum.Bestellmenge, text: "Bestellmenge", iconstyle: "" },


  ];

  static getValidList() : JWWSTransferTypeDef[] {
  return StatusTransferTypeDefaults.list.filter(f => f.id > 0)
  }


  static getListbyBitflags(artikel_jwws_transfertype: JWWSTransferTypeEnum): JWWSTransferTypeDef[] {
    var result = new Array<JWWSTransferTypeDef>();
    StatusTransferTypeDefaults.list.forEach(f=>{

      if (artikel_jwws_transfertype & f.id)
      {
          result.push(f);
      }

    })
      return result;
  }


  static toBitFlag(artikel_jwws_transfertype: JWWSTransferTypeDef[]): JWWSTransferType {
    var sum = 0;
    if (artikel_jwws_transfertype instanceof Array)
    {
        for (let index = 0; index < artikel_jwws_transfertype.length; index++) {
          const f = artikel_jwws_transfertype[index];
          if (f.id == JWWSTransferTypeEnum.voll )
          {
                // dann nur voll, enthält alle
                sum =  f.id as JWWSTransferType;
                break;
          }
          sum = (sum as JWWSTransferType) | (f.id as JWWSTransferType);

        }
    }
    return  sum as JWWSTransferType;
  }



}

export class JWWSTransferTypeDef {
  id: JWWSTransferTypeEnum;
  text: string;
  iconstyle: string;

}
