<div class="container-fluid">
  <div class="p-fluid grid">
    <div class="field col">
      <label for="manager">Name des Inputs</label>
      <div class="p-inputgroup">
        <input inputid="manager"  maxlength="200" [readonly]="this.isReadOnly" style="width: 100%;"   [(ngModel)]="taskModel.name" type="text" pInputText />
        <button [disabled]="this.isReadOnly" (click)="taskModel.name = '';" type="button" pButton pRipple icon="pi pi-times"
          styleClass="p-button-info"></button>
      </div>
    </div>
    <div class="field col">
      <label for="liste">Liste</label>
      <p-dropdown appendTo="body" [disabled]="!canEditList()" id="liste" [options]="validationProfiles$ | async" optionLabel="listName"
      placeholder="Liste angeben"
      optionValue="id"  [(ngModel)]="taskModel.selectedValidationId" (ngModelChange)="setValidationList(taskModel.selectedValidationId)"></p-dropdown>
    </div>
    <div class="field col">
      <label for="manager">EmpfängerIn</label>
      <p-dropdown appendTo="body" [disabled]="this.isReadOnly" id="manager"  [options]="selectSomaContactData$ | async"
        [(ngModel)]="taskModel.userId" optionLabel="friendlyName"
        optionValue="id"
        placeholder="EmpfängerIn wählen" appendTo="body"></p-dropdown>
    </div>
  </div>
  <div class="p-fluid p-formgrid grid">
    <div class="field col-12">
      <label for="beschreibung">Beschreibung</label>
      <textarea [disabled]="this.isReadOnly" id="beschreibung" rows="3" maxlength="2000" pInputTextarea
        [(ngModel)]="taskModel.taskDescription"></textarea>
    </div>
  </div>




<div class="grid formAreaSeparatorTop">
  <div class="field col-2">
    <button pButton [disabled]="!canSave()" label="Speichern" class="p-button-info" (click)="saveTask()"></button>
  </div>

  <div class="field col-2">
    <button pButton  [disabled]="!canClose()" label="Beenden" class="p-button-info" (click)="close()"></button>
  </div>
</div>


</div>

<p-confirmDialog appendTo="body" header="Bitte bestätigen:" icon="pi pi-exclamation-triangle" message="nix"
  rejectLabel="Abbrechen">
</p-confirmDialog>
