import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListEditorComponent } from './pages/list-editor.component';
import { ListEditorModule } from './pages/list-editor.module';

const routes: Routes = [
    {path: 'list-editor/:itemId/:type', component: ListEditorComponent }
]

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        ListEditorModule
    ],
    exports: [
        RouterModule,
        ListEditorModule
    ],
    declarations: [],
})

export class ListEditorRoutingModule { }
