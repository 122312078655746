
import { ActionStates, TaskUserModel, ValidationList } from '@app/common/models';
import { LiPoContact } from '@app/common/models/lipocontact.model';
import { SendMessageTypeContainer, SignalMessageContentModel } from '@app/common/models/signal.model';
import { SimpleResult } from '@app/common/models/simpleResult.model';
import { createFeatureSelector } from '@ngrx/store';
import { ArticleDataActionType, ArticleDataAction } from './actions';

/**Erfolgreichees Update, Insert */
export interface ArticleDataActionDone {
  result: TaskUserModel;
  actionResult: ArticleDataActionType;
  statechangedTo: ActionStates;
}

export interface ArticleDataState {

    SoMaContacts: LiPoContact[],

    isLoading: boolean,
    items: TaskUserModel[],
    currenterror: SimpleResult,
    successResult: ArticleDataActionDone,
    validationList: ValidationList[],
    itemsHits: number

}

export const InitialArticleDataState: ArticleDataState = {
    isLoading: false,
    items: new Array<any>() ,
    currenterror: undefined,
    successResult: undefined,
    SoMaContacts: [],
    validationList: [],
    
    itemsHits: 0
};


export const articledataFeatureName = 'articledataState';
export const selectarticleState = createFeatureSelector<ArticleDataState>(articledataFeatureName);
