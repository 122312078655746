import { SimpleResult } from "./simpleResult.model";

export class ValidationModel {
  constructor()
  { }
  id: any = -1;
  errors: ValidationError[] = [];
  message: string;

}



export enum SeverityType {
  //nicht gesetzt
  unknown = -1,
  //
  // Summary:
  //     Error
  Error = 0,
  //
  // Summary:
  //     Warning
  Warning = 1,
  //
  // Summary:
  //     Info
  Info = 2
}


export class ValidationError {

  propertyName: string;

  errorMessage: string;

  attemptedValue: any;

  customState: any;

  severity: SeverityType;

  errorCode: string;

  formattedMessagePlaceholderValues: any;

  constructor()
  {
    this.errorMessage = '';
    this.severity = SeverityType.unknown;
  }


}

export class ValidationColumnModel extends ValidationModel {

  field: string;
  style: string = '';
  styleClass: string  = "";
  constructor(fld:string, id: any = null)
  {
    super();
    this.field = fld;
    this.id = id;
    this.message = '';
  }



}
