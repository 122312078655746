import {  BedarfDetails, BedarfDto, KlammerDto, KlammerSortimentStichwort, PlatzierungsDto,
   SortimentDto, StatusSoma, StatusWup, StichwortDto, UserDto } from "@app/api/filialmatrix/models";


export class KeywordNode
{
    klammerid: number;
    sortimentid?: number;
    stichwortid?: number;

}


export enum StichworthintEnum
{
    unknown = "",
    keine_weiteren_Stichwörter = "Keine weiteren Stichwörter"
}

export class PlatzierungsClientDto implements PlatzierungsDto {


  inValidProperties?: { [key: string]: string; };
  isValid: boolean;

  bedarf?: BedarfDetails;
  klammerSortimentStichwort?: KlammerSortimentStichwort;

  //wird in Novitäten  nicht verwendet
  statusWup: StatusWup ;

  dummyId: any;

  istHauptplatzierung: boolean;

  kategorie?: string;


  kommentar?: string;

  statusSoma: StatusSoma;

  sortiment?: SortimentDto;
  sortimentname: string;
  //HUG/HBV
  groupname: string;


  sortimentsklammer?: KlammerDto;
  sortimentsklammername: string;


  source?: string;


  stichwort?: StichwortDto;


  user?: UserDto;

  simuliertebestellmenge: number;

  /**Webapi Platzierungsgesamt Titel Validierung */
  static platzierungsValidierungTitel = "Platzierungen";


  static findSimBestellMenge(findplatzierung: PlatzierungsDto, bedarfs: BedarfDto): number {
    if (findplatzierung.sortiment == undefined) return 0;
    if (bedarfs == undefined) return 0;
    if (bedarfs.children == undefined) return 0;
    var exemplare = 0;
    for (let index = 0; index < bedarfs.children.length; index++) {
      const b = bedarfs.children[index];
      if (b.platzierung != undefined
        && b.platzierung.sortiment != undefined
        && b.platzierung.kategorie != undefined
        && b.platzierung.sortiment.id == findplatzierung.sortiment.id
        && b.platzierung.kategorie == findplatzierung.kategorie) {
        exemplare = b.exemplare;
        break;
      }
    }
    return exemplare;
  }


  static toServerDto(dto: PlatzierungsClientDto): PlatzierungsDto {
    let dtoServer = <PlatzierungsDto>{
      isValid: dto.isValid,
      inValidProperties: dto.inValidProperties,
      dummyId: dto.dummyId,
      istHauptplatzierung: dto.istHauptplatzierung,
      stichwort: dto.stichwort,
      sortiment: dto.sortiment,
      sortimentsklammer: dto.sortimentsklammer,
      source: dto.source,
      kategorie: dto.kategorie,
      kommentar: dto.kommentar,
      statusSoma: dto.statusSoma,
      statusWup: dto.statusWup,
      user: dto.user,
      bedarf : dto.bedarf,
      klammerSortimentStichwort: dto.klammerSortimentStichwort
    };
    return dtoServer;
  }

  static toClientDto(dto: PlatzierungsDto): PlatzierungsClientDto {

    return <PlatzierungsClientDto>{
      isValid: dto.isValid,
      inValidProperties: dto.inValidProperties,
      dummyId: dto.dummyId, // künstlich, leider keine entity
      istHauptplatzierung: dto.istHauptplatzierung,
      stichwort: dto.stichwort ,
      sortiment: dto.sortiment,
      sortimentname: dto.sortiment?.name,
      groupname: dto.sortiment && dto.sortiment.group ? dto.sortiment.group.name: '',
      sortimentsklammer: dto.sortimentsklammer ,
      sortimentsklammername: dto.sortimentsklammer?.name,
      user: dto.user ,
      source: dto.source,
      statusSoma: dto.statusSoma,
      statusWup: dto.statusWup,
      kategorie: dto.kategorie,
      kommentar: dto.kommentar,
      bedarf: dto.bedarf,
      simuliertebestellmenge: dto.bedarf ?  dto.bedarf.exemplare : 0,
      klammerSortimentStichwort : dto.klammerSortimentStichwort,

    }

  }



}
