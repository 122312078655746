/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfSaisonDto } from '../models/list-response-args-of-saison-dto';
import { ExtendedQueryTokenDTO } from '../models/extended-query-token-dto';
import { ResponseArgsOfSaisonDto } from '../models/response-args-of-saison-dto';
import { SaisonDto } from '../models/saison-dto';
import { ResponseArgsOfBoolean } from '../models/response-args-of-boolean';
@Injectable({
  providedIn: 'root',
})
class SaisonService extends __BaseService {
  static readonly SaisonQuerySaisonPath = '/saison/s';
  static readonly SaisonCreateSaisonPath = '/saison';
  static readonly SaisonUpdateSaisonPath = '/saison/{id}';
  static readonly SaisonDeleteSaisonPath = '/saison/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Saison Liste
   * @param params The `SaisonService.SaisonQuerySaisonParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `eagerLoading`:
   */
  SaisonQuerySaisonResponse(params: SaisonService.SaisonQuerySaisonParams): __Observable<__StrictHttpResponse<ListResponseArgsOfSaisonDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/saison/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfSaisonDto>;
      })
    );
  }
  /**
   * Saison Liste
   * @param params The `SaisonService.SaisonQuerySaisonParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `eagerLoading`:
   */
  SaisonQuerySaison(params: SaisonService.SaisonQuerySaisonParams): __Observable<ListResponseArgsOfSaisonDto> {
    return this.SaisonQuerySaisonResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfSaisonDto)
    );
  }

  /**
   * Create
   * @param params The `SaisonService.SaisonCreateSaisonParams` containing the following parameters:
   *
   * - `payload`: SaisonDto
   *
   * - `user`: User Id oder Token
   */
  SaisonCreateSaisonResponse(params: SaisonService.SaisonCreateSaisonParams): __Observable<__StrictHttpResponse<ResponseArgsOfSaisonDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/saison`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfSaisonDto>;
      })
    );
  }
  /**
   * Create
   * @param params The `SaisonService.SaisonCreateSaisonParams` containing the following parameters:
   *
   * - `payload`: SaisonDto
   *
   * - `user`: User Id oder Token
   */
  SaisonCreateSaison(params: SaisonService.SaisonCreateSaisonParams): __Observable<ResponseArgsOfSaisonDto> {
    return this.SaisonCreateSaisonResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfSaisonDto)
    );
  }

  /**
   * Update
   * @param params The `SaisonService.SaisonUpdateSaisonParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `id`:
   *
   * - `user`:
   */
  SaisonUpdateSaisonResponse(params: SaisonService.SaisonUpdateSaisonParams): __Observable<__StrictHttpResponse<ResponseArgsOfSaisonDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/saison/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfSaisonDto>;
      })
    );
  }
  /**
   * Update
   * @param params The `SaisonService.SaisonUpdateSaisonParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `id`:
   *
   * - `user`:
   */
  SaisonUpdateSaison(params: SaisonService.SaisonUpdateSaisonParams): __Observable<ResponseArgsOfSaisonDto> {
    return this.SaisonUpdateSaisonResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfSaisonDto)
    );
  }

  /**
   * Delete
   * @param params The `SaisonService.SaisonDeleteSaisonParams` containing the following parameters:
   *
   * - `id`: Saison Id
   *
   * - `user`: User Id oder Token
   */
  SaisonDeleteSaisonResponse(params: SaisonService.SaisonDeleteSaisonParams): __Observable<__StrictHttpResponse<ResponseArgsOfBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/saison/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBoolean>;
      })
    );
  }
  /**
   * Delete
   * @param params The `SaisonService.SaisonDeleteSaisonParams` containing the following parameters:
   *
   * - `id`: Saison Id
   *
   * - `user`: User Id oder Token
   */
  SaisonDeleteSaison(params: SaisonService.SaisonDeleteSaisonParams): __Observable<ResponseArgsOfBoolean> {
    return this.SaisonDeleteSaisonResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfBoolean)
    );
  }
}

module SaisonService {

  /**
   * Parameters for SaisonQuerySaison
   */
  export interface SaisonQuerySaisonParams {
    queryToken: ExtendedQueryTokenDTO;
    eagerLoading?: number;
  }

  /**
   * Parameters for SaisonCreateSaison
   */
  export interface SaisonCreateSaisonParams {

    /**
     * SaisonDto
     */
    payload: SaisonDto;

    /**
     * User Id oder Token
     */
    user?: null | string;
  }

  /**
   * Parameters for SaisonUpdateSaison
   */
  export interface SaisonUpdateSaisonParams {
    payload: SaisonDto;
    id: number;
    user?: null | string;
  }

  /**
   * Parameters for SaisonDeleteSaison
   */
  export interface SaisonDeleteSaisonParams {

    /**
     * Saison Id
     */
    id: number;

    /**
     * User Id oder Token
     */
    user?: null | string;
  }
}

export { SaisonService }
