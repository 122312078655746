import { ListModel, StatusModel, FieldModel } from '../models';
import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { all } from 'q';
import { environment } from '@env/environment';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({ providedIn: 'root' })
export class ListOverviewService {

    constructor(private httpClient: HttpClient, private oauthService: OAuthService) { }

    getListsForUser(): Observable<ListModel[]> {
        const httpOptions = {
            headers: new HttpHeaders({
              'Access-Control-Allow-Origin': '*',
              Authorization: 'Bearer ' + this.oauthService.getAccessToken()
            })
          };
        const url = `${environment.connections.ListOverviewService}/GetUploadLists`;
        const result = this.httpClient.get<ListModel[]>(url, httpOptions);
        return result;
    }
}
