import { Component, OnInit } from '@angular/core';
import { FinishUploadByManagerAction, ListEditorActionType, FinishUploadByManagerDoneAction } from '@app/+list-editor/store';


import { LipoMessageService } from '@app/common/services/LipoMessageService';
import { select, Store } from '@ngrx/store';
import { Message } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import * as momentLib from 'moment';
import { Subscription } from 'rxjs/internal/Subscription';
import { Actions, ofType } from '@ngrx/effects';
import { KlammerDto } from '@app/api/filialmatrix/models';
import { GetKlammernAction, GetSortimenteAction, selectallKlammern, selectallSortimente } from '@app/common-data-module/store';
import { KlammerService, SortimentService } from '@app/api/filialmatrix/services';
import { QueryTokenDTO } from '@app/api/commonWebAPI/models';
import { KlammerDtoClient } from '@app/common/models/sortimente/KlammerDtoClient';
import { SortimentDtoClient } from '@app/common/models/sortimente/SortimentDtoClient';

@Component({
  selector: 'app-transfer-dialog',
  templateUrl: './transfer-dialog.component.html',
  styleUrls: ['./transfer-dialog.component.scss']
})
export class TransferDialogComponent implements OnInit {
  taskId: number;
  taskName: string;
  sortiment: SortimentDtoClient;
  sortimentName: string;
  selectedRows: number[];
  userId: number;
  userOrganisation: string;
  ignoreIds: number[];

  expirationDate: Date;


  minDateValue: Date;
  maxDateValue: Date;

  actionSubscription: Subscription;

  selectedSortiment: SortimentDtoClient;

  sortimente$ = this.store.pipe(select(selectallSortimente));

  constructor(public ref: DynamicDialogRef,
              public config: DynamicDialogConfig,
              public store: Store<any>,
              private msgService: LipoMessageService<Message>,
              public _actions$: Actions) {

    let start         = momentLib(new Date()).startOf('day');
    this.minDateValue = start.clone().toDate();
    this.maxDateValue = start.clone().add(2, 'month').toDate();
  }

  ngOnInit(): void {


    this.taskId       = this.config.data.taskId as number;
    this.taskName     = this.config.data.taskName as string;
    this.sortiment    = this.config.data.sortiment as  SortimentDtoClient;
    this.sortimentName  = this.config.data.sortimentName as string;
    this.selectedRows = this.config.data.selectedRows as number[];
    this.userId       = this.config.data.userId as number;
    this.userOrganisation = this.config.data.userOrganisation as string;

    this.expirationDate = undefined; // initial leer #6344


    var pk = <SortimentService.SortimentQuerySortimenteParams>{}
    pk.eagerLoading = 2;
    pk.queryToken = <QueryTokenDTO>{
      take: null, skip: null,
      orderBy: [{ by: "name", desc: false }]
    }
    this.store.dispatch(new GetSortimenteAction({ param: pk }));


  }

  /** Speicher + Übetragen möglich */
  canSaveAndTransfer(): boolean {
    return this.taskName != undefined
    && this.taskName.length > 1
    && this.expirationDate != undefined
    && this.taskId > 0
    && this.sortiment != undefined;
  }

  /** Speicher + Übertragen */
  saveAndTransfer() {
    this.store.dispatch(new   FinishUploadByManagerAction({ taskId: this.taskId, sortimentId: this.sortiment.assortmentId , klammerid: this.sortiment?.klammer?.assortmentmasterId,  name: this.taskName, expirationDate: this.expirationDate, selectedRows: this.selectedRows }));

    this.actionSubscription = this._actions$.pipe(ofType<FinishUploadByManagerDoneAction>(ListEditorActionType.FinishUploadByManagerDone)).subscribe((data: any) => {
      this.cancel();
    });
  }

  /** Abbrechen möglich */
  canCancel(): boolean {
    return true;
  }

  /** Abbrechen */
  cancel(): void {

    this.msgService.Clear();
    this.ref.close(null);
  }

  selectedSortimentChange() {

    this.sortiment = this.selectedSortiment;
    this.sortimentName = this.selectedSortiment?.name;
  }
}
