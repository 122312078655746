import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { EffectsModule } from '@ngrx/effects';
import { CommonDataEffects, featureName, reducer } from './store';


@NgModule({
    providers:[],
    imports: [
        StoreModule.forFeature(featureName, reducer),
        EffectsModule.forFeature([CommonDataEffects])
    ]
})

export class CommonDataStateModule {}
