import { createSelector } from '@ngrx/store';
import { selectmediatipState } from './state';

export const selectisLoading = createSelector(selectmediatipState, x => x.isLoading);
export const selectMediatipsTasks = createSelector(selectmediatipState, x => x.mediatipsTasks);
export const selectMediatips = createSelector(selectmediatipState, x => x.mediatips);
export const selectmediatipsHits = createSelector(selectmediatipState, x => x.mediatipsHits);


export const selectMediatipActiondone = createSelector(selectmediatipState, x => x.mediatipActionDone);


export const selectcurrentMediatipError = createSelector(selectmediatipState, x => x.currenterror);


export const selectBubData = createSelector(selectmediatipState, x => x.bubData);
export const selectBubDataList = createSelector(selectmediatipState, x => x.bubDataList);
export const selectisLoadingBubData = createSelector(selectmediatipState, x => x.isLoadingbubData);
export const selectSomaContactData = createSelector(selectmediatipState, x => x.SoMaContacts);

