import { ColumnValue } from './columnValue.model';
import {UploadUserModel} from './uploaduser.model';

export interface UploadRowModel {
    id: number;
    created: Date;
    changed: Date;
    editingUser: UploadUserModel;
    detailRowId: string;
    detailComment: string;
    isValid: boolean;
    validationInfo: string;
    jsonContent: string;
    columnValues: ColumnValue[];
    rowType: string;
}
