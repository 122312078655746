import { Component, OnDestroy, OnInit } from '@angular/core';
import { ArticleDataActionType, GetArticleDataValidationsAction, GetArticleDataSoMaContactsAction, InsertArticleDataAction, UpdateArticleDataAction, ClearArticleDataSuccessRsults, ClearArticleErrors } from '@app/+articledata/store/actions';
import { selectArticleData, selectArticleDataValidationList, selectSomaContactData, selectArticleDataSuccess, selectcurrentError } from '@app/+articledata/store/selectors';
import { ArticleDataActionDone } from '@app/+articledata/store/state';
import { IClientError } from '@app/common/interfaces/Interfaces';
import { ActionStates, AufgabenArt, GeneralResultEnum, LieferantTypeEnum, TaskUserModel } from '@app/common/models';
import { LiPoContact } from '@app/common/models/lipocontact.model';
import { AuthService } from '@app/common/services/auth.service';
import { LipoMessageService } from '@app/common/services/LipoMessageService';
import { LogUsersClientErrorAction } from '@app/store/user/actions';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ConfirmationService, Message } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-articledata-edit',
  templateUrl: './articledata-edit.component.html',
  styleUrls: ['./articledata-edit.component.scss'],
  providers: [DialogService]
})
export class ArticledataEditComponent implements OnInit, OnDestroy {

  taskId: number;
  taskModel: TaskUserModel;
  editMode: string = "edit";

  selectSomaContactData$ = this.store.pipe(select(selectSomaContactData));
  selectSuccess$ = this.store.pipe(select(selectArticleDataSuccess));
  errorHandle$ = this.store.pipe(select(selectcurrentError));
  validationProfiles$ = this.store.pipe(select(selectArticleDataValidationList));


  isReadOnly: boolean = false;
  actionSubscription: any;
  isManager: boolean;

  constructor(public ref: DynamicDialogRef,
    public config: DynamicDialogConfig, public store: Store<any>, public _actions$: Actions,
    private msgService: LipoMessageService<Message>, private authService: AuthService) { }

  /**Kann gespeichert werden */
  canSave(): boolean {
    if (this.isManager)  return false;
    if (this.taskModel == undefined) return false;
    if (this.isReadOnly) return false;
    return this.taskModel.name != undefined && this.taskModel.name.length > 1 &&
      this.taskModel.selectedValidationId != undefined && this.taskModel.userId != undefined;
  }
  /**Kann der Dialog geschlossen werden ? */
  canClose(): boolean {
    return true;
  }

  ngOnDestroy(): void {
  }

  setValidationList(id: number): void {
    this.validationProfiles$.subscribe(list => {
      this.taskModel.selectedValidation = list.filter(g => g.id == id)[0].listName;
    });
  }


  setLiPoContact(contact: LiPoContact): void {
    this.taskModel.userId = contact.id; // -> creatingUserid, wie bei Tasks !!!!
  }

  saveTask(): void {
    if (this.taskModel.id < 1) {

      this.store.dispatch(new InsertArticleDataAction({ item: this.taskModel }));
    } else {
      this.store.dispatch(new UpdateArticleDataAction({ item: this.taskModel, successmessage: true }));
    }


    this.errorHandle$.pipe(filter(t => t != undefined && ( t.userMessage != undefined  || t.serverMessage != undefined )  )).subscribe(e => {
      this.msgService.Clear();

      if (e.generalResult == GeneralResultEnum.HttpError) {
        this.msgService.Publish({ severity: "error", summary: e.userMessage ? e.userMessage : e.userMessage, detail: e?.serverMessage });
      } else   if (e.generalResult == GeneralResultEnum.AuthError) {
        this.msgService.Publish({ severity: "error", summary:  (e.userMessage ? e.userMessage :  "Die Anmeldung ist abgelaufen"), detail: "Die Daten können nicht gespeichert werden. Sie werden neu angemeldet" });

        this.authService.login();

      } else if (e.generalResult == GeneralResultEnum.OnlyDebug) {
        this.store.dispatch(new LogUsersClientErrorAction({ error: <IClientError>{ userid: null, modul: 'Articledata-Edit', error: e.userMessage + " "+  e?.serverMessage } }));
      }
      else    if (e.generalResult == GeneralResultEnum.LogicWarning) {
        this.msgService.Publish({ severity: "warn", summary: e.userMessage ? e.userMessage : "Fehler Artikeldaten bearbeiten", detail: e.userMessage });
      }

      this.store.dispatch(new ClearArticleErrors());

    });



    this.actionSubscription = this.selectSuccess$.subscribe((data: ArticleDataActionDone) => {
      if (data && data.result) {
        if (data.actionResult == ArticleDataActionType.InsertArticleDataActionDone) {
          this.taskModel = { ...data.result };

        } else  if (       data.actionResult == ArticleDataActionType.UpdateArticleDataActionDone          )
        {
        this.msgService.Clear();
        this.msgService.Publish({ severity: 'success',
        summary: 'Artikeldaten "' + this.taskModel.name + '" wurden gespeichert' });
        this.taskModel = {...data.result};
        this.actionSubscription.unsubscribe();
        this.store.dispatch(new ClearArticleDataSuccessRsults());
        }
      }
    });

  }





  /**Kann die Liste eingegeben und verändert werden ? */

  canEditList() : boolean
  {
      if (this.isManager)  return false;
      if (this.isReadOnly) return false;
      return this.editMode == "new";

  }


  close(): void {

    this.msgService.Clear();
    this.ref.close(null);
  }

  ngOnInit(): void {
    this.store.dispatch(new GetArticleDataSoMaContactsAction());
    this.store.dispatch(new GetArticleDataValidationsAction());
    this.taskId = this.config.data.id as number;
    this.isManager = this.config.data.isManager as boolean;
    if (this.taskId < 1) {
      this.taskModel = this.getNewTaskUserModel();
      this.editMode = "new";
    } else {
      var artikelsub = this.store.pipe(select(selectArticleData));
      var sub = artikelsub.subscribe(articles => {
        var model = articles.filter(t => t.id == this.taskId)[0];
        if (model) {
          this.editMode = "edit";
          this.isReadOnly = (( model.taskState == ActionStates.Deleted_SOMA || model.taskState > ActionStates.OnGoing) || (model.isReadOnly == 1) || this.isManager) ;
          this.taskModel = {...model};

        }


      });
      sub.unsubscribe();
    }


  }


  getNewTaskUserModel(): TaskUserModel {
    const result: TaskUserModel = {
      name: '',
      email: '',
      organization: '',
      position: '',
      id: -1,
      groupId: -1,
      userId: null, // creating
      isReadOnly: 0,
      isReadOnlyMessage: null,
      isEditExpired: 0,
      isEditExpiredMessage: null,
      min: 1,
      max: 1000,
      taskInfo: '',
      taskDescription: '',
      taskValidRange: Date[2],
      taskValidRangeFrom: null,
      taskValidRangeTo: null,
      taskActiveRange: Date[2],
      taskActiveRangeFrom: null,
      taskActiveRangeUntil: null,
      sendInvite: false,
      invitationDate: null,
      sendInviteOne: false,
      sendInviteTwo: false,
      sendInviteThree: false,
      dateInviteOne: null,
      dateInviteThree: null,
      dateInviteTwo: null,
      selectedValidation: '',
      selectedValidationId: null,
      includeUpload: false,
      loadSnapshot: false,
      selected: false,
      taskParentId: null,
      creatorFriendlyName: null,
      creatorMail: null,
      creatorToken: null,
      uploadStagingId: null,
      uploadState: 0,
      uploadFiles: { parentId: 0, userId: '', files: [] },
      fileUrls: [],
      firstName: null,
      lastName: null,
      fullName: null,
      gender: null,
      sortimentName: null,
      sortimentIds: [],
      typeInfoId: null,

      targetUserId: null,
      color: null,
      klammerName: null,
      klammerId: null,
      taskState: 100,
      taskType: 1,
      articledatachange: new Date(),
      einkaufId: null,
      aufgabenArt: AufgabenArt.Artikelliste,
      mandantId: null,
      lieferantId: null,
      lieferantType: LieferantTypeEnum.Lieferant
    };
    return result;
  }


}


