<p-header>
</p-header>
<p-toast id="taskedittoaster" ></p-toast>

<div class="grid">
    <div class="col-6">
        <div class="grid">
            <div class="col-12">
                <div class="grid" style="margin-bottom: 5px;">
                    <div class="row">Sortimentsklammer</div>
                    <div class="col-10" *ngIf="!isMasterEditing" style="padding-right: 10px;">
                        <p-dropdown id="chooseMaster" [options]="sortimentsKlammern$ | async" optionLabel="name" [(ngModel)]="selectedMaster"
                            [disabled]="recipients.length > 0" (ngModelChange)="loadSortiment()" placeholder="Sortimentsklammer wählen">
                        </p-dropdown>
                    </div>
                </div>
                <div class="grid" style="margin-bottom: 5px;">
                    <div class="row">Sortimentstyp</div>
                    <div class="col-10" style="padding-right: 10px;">
                        <p-dropdown  id="chooseType" [options]="typeInfos$ | async" [showClear]="true"
                            [disabled]="((typeInfos$ | async) === null || (typeInfos$ | async).length === 0) || recipients.length > 0"
                            [(ngModel)]="selectedType" (ngModelChange)="activateTypeFilter()" optionLabel="name" placeholder="Typ wählen">
                        </p-dropdown>
                    </div>
                </div>
                <div class="grid" style="margin-bottom: 5px;">
                    <div class="row">Sortiment</div>
                    <div class="col-10" style="padding-right: 10px;">
                        <p-multiSelect id="chooseSortiment" [options]="sortiments$ | async"  selectedItemsLabel="{0} Sortimente ausgewählt"
                            [disabled]="((sortiments$ | async) === null || (sortiments$ |async).length === 0) || recipients.length > 0" placeholder="Sortiment wählen"
                            [(ngModel)]="selectedSortiments" optionLabel="attributeName">
                        </p-multiSelect>
                    </div>
                </div>

                <div class="grid">
                    <hr>
                    <div class="col-2">
                        <p-checkbox id="withUpload" [(ngModel)]="includeUpload" binary="true" label="Liste" [disabled]="recipients.length > 0"></p-checkbox>
                    </div>
                    <div class="col-1" *ngIf="includeUpload">
                        Art:
                    </div>
                    <div class="col-5" *ngIf="includeUpload">
                        <p-dropdown id="validationSelection" [options]="validationProfiles$ | async" placeholder="Listenart angeben"
                            [(ngModel)]="selectedValidation" [disabled]="recipients.length > 0">
                        </p-dropdown>
                    </div>
                    <div class="col-3" style="margin-left: 20px;" *ngIf="includeUpload">
                        <p-checkbox id="withSnapshot" [(ngModel)]="loadSnapshot" binary="true" label="Snapshot" [disabled]="recipients.length > 0"></p-checkbox>
                    </div>
                </div>
                <div class="grid">
                    <hr>
                    <div class="col-2" *ngIf="includeUpload">
                        Minimum:
                    </div>
                    <div class="col-2" *ngIf="includeUpload">
                        <p-spinner [(ngModel)]="minListRows" [disabled]="recipients.length > 0"></p-spinner>
                    </div>
                    <div class="col-2" *ngIf="includeUpload" style="margin-left: 25px;">
                        Maximum:
                    </div>
                    <div class="col-2" *ngIf="includeUpload">
                        <p-spinner [(ngModel)]="maxListRows" [disabled]="recipients.length > 0"></p-spinner>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-6" style="border-left: solid; border-color: lightgray; border-width: 0.05em; padding-left: 10px;">
        <div class="grid">
            <div class="col-11">
                <div class="ui-grid-col-2">Aufgabentext</div>
                <!-- <div class="ui-grid-col-10" style="margin-left: 15 px;">
                    <input id="aufgabeInput" type="text" size="200" style="width: 100%;" [(ngModel)]="taskname" pInputText />
                </div> -->
                <div class="col-10" style="margin-left: 15px;">
                    <p-dropdown [options]="taskInfoTexts" [(ngModel)]="taskname" placeholder="Wählen Sie einen Aufgabentext" editable="true" [disabled]="recipients.length > 0"></p-dropdown>
                </div>
            </div>
            <div class="col-11" style="margin-top: 10px;">
                    <div class="col-2">Information</div>
                    <div class="col-10">
                            <textarea id="desc" maxlength="3000" rows="4" cols="30" pInputTextarea [(ngModel)]="description" [disabled]="recipients.length > 0"></textarea>
                    </div>
            </div>
            <div class="col-11">
                <hr>
                <div class="col-2">Laufzeit</div>
                <div class="col-4">
                    <p-calendar [(ngModel)]="taskActivityRange" [firstDayOfWeek]="1" dateFormat="dd.mm.yy" selectionMode="range"
                    showButtonBar="true" [showIcon]="true" [disabled]="recipients.length > 0" (onSelect)="changeValidRange()" (onInput)="changeValidRange()"></p-calendar>
                </div>
                <div class="col-2" style="padding-left: 35px;">Bearbeitung</div>
                <div class="col-4">
                    <p-calendar [(ngModel)]="taskValidRange" [firstDayOfWeek]="1" dateFormat="dd.mm.yy" selectionMode="range"
                    showButtonBar="true" [showIcon]="true" [disabled]="recipients.length > 0"></p-calendar>
                </div>
            </div>
            <div class="col-11">
                <hr>
                <div class="col-3" style="padding-left: 10px;">
                    <p-checkbox id="withInvite" [(ngModel)]="sendInvite" binary="true" label="Einladen" [disabled]="recipients.length > 0"></p-checkbox>
                    <div *ngIf="sendInvite"  style="margin-top: 5px;">
                        <p-calendar [(ngModel)]="invitationDate" [firstDayOfWeek]="1" dateFormat="dd.mm.yy"
                        showButtonBar="true" [showIcon]="true" [disabled]="!sendInvite || recipients.length > 0"></p-calendar>
                    </div>
                </div>
                <div class="col-3" style="padding-left: 10px;">
                    <p-checkbox [(ngModel)]="sendReminderOne" binary="true" label="Erinnerung 1" [disabled]="recipients.length > 0"></p-checkbox>
                    <div *ngIf="sendReminderOne" style="margin-top: 5px;">
                        <p-calendar [(ngModel)]="remindDateOne" [firstDayOfWeek]="1" dateFormat="dd.mm.yy"
                            showButtonBar="true" [showIcon]="true" [disabled]="recipients.length > 0"></p-calendar>
                    </div>
                </div>
                <div class="col-3" style="padding-left: 10px;">
                    <p-checkbox [(ngModel)]="sendReminderTwo" binary="true" label="Erinnerung 2" [disabled]="recipients.length > 0"></p-checkbox>
                    <div *ngIf="sendReminderTwo" style="margin-top: 5px;">
                        <p-calendar [(ngModel)]="remindDateTwo" [firstDayOfWeek]="1" dateFormat="dd.mm.yy"
                            showButtonBar="true" [showIcon]="true" [disabled]="recipients.length > 0"></p-calendar>
                    </div>
                </div>
                <div class="col-3" style="padding-left: 10px;">
                    <p-checkbox [(ngModel)]="sendReminderThree" binary="true" label="Erinnerung 3" [disabled]="recipients.length > 0"></p-checkbox>
                    <div *ngIf="sendReminderThree" style="margin-top: 5px;">
                        <p-calendar [(ngModel)]="remindDateThree" [firstDayOfWeek]="1" dateFormat="dd.mm.yy"
                        showButtonBar="true" [showIcon]="true" [disabled]="recipients.length > 0"></p-calendar>
                    </div>
                </div>
            </div>
            <div class="col-11">
                <hr>
                <div class="col-2">Anhang</div>
                <div class="col-8">
                    <p-fileUpload #fubauto name="uploadFiles[]" invalidFileTypeMessageSummary="{0} Datei nicht erlaubt" chooseLabel="Neu..."  multiple="multiple" fileLimit="5" invalidFileLimitMessageSummary="Maximal 5 Dateien erlaubt"
                        accept=".xlsx,.jpg,.png,.pdf" maxFileSize="1000000" [disabled]="recipients.length > 0" customUpload="true" (uploadHandler)="uploadFiles($event)" mode="advanced"
                        previewWidth="30" auto="true">
                    </p-fileUpload>
                </div>
            </div>
        </div>
    </div>
</div>

<hr>
<!-- <div>
    <div #userEdit class="userEditPanel" [(style.visibility)]="hideUserEdit" style="height: auto;">
        <p-toolbar>
            <div class="ui-toolbar-group-left">
                <button type="button" pButton icon="pi pi-check" (click)="saveUserEdit()"></button>&nbsp;
                <button type="button" pButton icon="pi pi-times" (click)="hideUserEdit='hidden'"></button>
            </div>
            <div class="ui-toolbar-group-right">
                    Bearbeiten Benutzer {{ selectedUser.name }}
            </div>
        </p-toolbar>

        <div class="ui-fluid" style="margin-top: 2px;">
            <div class="ui-g">
                <div class="ui-g-8 ui-g-nopad">
                    <div class="ui-g-2">
                        Aufgabentext
                    </div>
                    <div class="ui-g-10">
                        <input type="text" [(ngModel)]="selectedUser.taskInfo" style="width: 100%;"/>
                    </div>
                    <div class="ui-g-2">
                        Information
                    </div>
                    <div class="ui-g-10">
                            <textarea rows="4" maxlength="3000" cols="53" pInputTextarea [(ngModel)]="selectedUser.taskDescription"></textarea>
                    </div>
                    <div class="ui-g-12">
                        <div class="ui-g-4">
                            <p-checkbox [(ngModel)]="selectedUser.sendInviteOne" binary="true" label="Erinnerung 1"></p-checkbox>
                            <p-calendar [(ngModel)]="selectedUser.dateInviteOne" [firstDayOfWeek]="1" dateFormat="dd.mm.yy"
                                showButtonBar="true" [showIcon]="true" [disabled]="!selectedUser.sendInviteOne"></p-calendar>
                        </div>
                        <div class="ui-g-4">
                            <p-checkbox [(ngModel)]="selectedUser.sendInviteTwo" binary="true" label="Erinnerung 2"></p-checkbox>
                            <p-calendar [(ngModel)]="selectedUser.dateInviteTwo" [firstDayOfWeek]="1" dateFormat="dd.mm.yy"
                                showButtonBar="true" [showIcon]="true" [disabled]="!selectedUser.sendInviteTwo"></p-calendar>
                        </div>
                        <div class="ui-g-4">
                            <p-checkbox [(ngModel)]="selectedUser.sendInviteThree" binary="true" label="Erinnerung 2"></p-checkbox>
                            <p-calendar [(ngModel)]="selectedUser.dateInviteThree" [firstDayOfWeek]="1" dateFormat="dd.mm.yy"
                                showButtonBar="true" [showIcon]="true" [disabled]="!selectedUser.sendInviteThree"></p-calendar>
                        </div>
                    </div>
                    <div class="ui-g-12">
                        <div class="ui-g-3" style="margin-top: 5px;">
                            <p-checkbox [(ngModel)]="selectedUser.includeUpload" binary="true" label="Liste"></p-checkbox>
                        </div>
                        <div class="ui-g-6">
                            <div class="ui-g-2">
                                Art:
                            </div>
                            <div class="ui-g-10">
                                <p-dropdown [options]="validationProfiles$ | async"
                                    [(ngModel)]="selectedUser.selectedValidation" [disabled]="!selectedUser.includeUpload">
                                </p-dropdown>
                            </div>
                        </div>
                        <div class="ui-g-3" style="margin-top: 5px;">
                                <p-checkbox [(ngModel)]="selectedUser.loadSnapshot" binary="true" label="Snapshot" [disabled]="!selectedUser.includeUpload"></p-checkbox>
                        </div>
                    </div>
                    <div class="ui-g-12">
                        <div class="ui-g-3">
                            Anhang
                        </div>
                        <div class="ui-g-9">
                            <p-fileUpload #fupSingle name="uploadSingleFiles[]" invalidFileTypeMessageSummary="{0} Datei nicht erlaubt" chooseLabel="Neu..."  multiple="multiple" fileLimit="5" invalidFileLimitMessageSummary="Maximal 5 Dateien erlaubt"
                            accept=".xlsx,.jpg,.png,.pdf" maxFileSize="1000000" customUpload="true" (uploadHandler)="uploadUserFiles($event, selectedUser)" mode="advanced"
                            cancelLabel="Löschen" uploadLabel="Dateien übernehmen" previewWidth="30" auto="true">
                            </p-fileUpload>
                        </div>
                    </div>
                </div>
                <div class="ui-g-4">
                    <div class="ui-g-12">
                        <label for="taskValidDate">Bearbeitungszeitraum</label>
                        <p-calendar #taskValidDate [(ngModel)]="selectedUser.taskValidRange" [firstDayOfWeek]="1" dateFormat="dd.mm.yy" selectionMode="range"
                        showButtonBar="true" [showIcon]="true"></p-calendar>
                    </div>
                    <div class="ui-g-12">
                        <label for="taskValidDate">Laufzeit</label>
                        <p-calendar #taskValidDate [(ngModel)]="selectedUser.taskActiveRange" [firstDayOfWeek]="1" dateFormat="dd.mm.yy" selectionMode="range"
                        showButtonBar="true" [showIcon]="true"></p-calendar>
                    </div>
                    <div class="ui-g-12">
                        <p-checkbox id="withInvite" [(ngModel)]="selectedUser.sendInvite" binary="true" label="Einladen"></p-checkbox>
                        <p-calendar #taskValidDate [(ngModel)]="selectedUser.invitationDate" [firstDayOfWeek]="1" dateFormat="dd.mm.yy"
                        showButtonBar="true" [showIcon]="true" [disabled]="!selectedUser.sendInvite"></p-calendar>
                    </div>
                    <div class="ui-g-12">
                        <label for="userListMin">Minimum Einträge</label>
                        <p-spinner #userListMin [(ngModel)]="selectedUser.min" [disabled]="!selectedUser.includeUpload"></p-spinner>
                    </div>
                    <div class="ui-g-12">
                        <label for="userListMax">Maximum Einträge</label>
                        <p-spinner #userListMax [(ngModel)]="selectedUser.max" [disabled]="!selectedUser.includeUpload"></p-spinner>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p-table #userTable [columns]="userCols" [value]="selectableUsers$ | async" [paginator]="true" [rows]="4" dataKey="userId"
        columnResizeMode="fit" [lazy]="false" [globalFilterFields]="['value.organisation', 'value.fullName']" [loading]="isUsersLoading$ | async">
        <ng-template pTemplate="caption">
            <div style="text-align: right">
                <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                <input type="text" pInputText size="50" (input)="userTable.filterGlobal($event.target.value, 'contains')" style="width:auto">
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th style="width:3em"><i class="pi pi-check"></i></th>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" pResizableColumn pReorderableColumn>
                        {{col.header}}
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
                <th>Liste</th>
                <th>Min</th>
                <th>Max</th>
                <th style="width:7em; text-align: center;"><i class="pi pi-pencil"></i></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-ri="rowIndex">
            <tr [pSelectableRow]="rowData">
                <td style="width:3em;">
                    <p-checkbox binary="true" [(ngModel)]="rowData.value.selected" (onChange)="setRecipients($event, rowData.value)" [disabled]="!allowVendorEdit()"></p-checkbox>
                </td>
                <td>{{ rowData.value.organisation }}</td>
                <td>{{ rowData.value.fullName }}</td>
                <td>{{ rowData.value.email }}</td>
                <td>
                    <p-checkbox binary="true" [(ngModel)]="rowData.value.includeUpload"></p-checkbox>
                </td>
                <td>{{ rowData.value.min }}</td>
                <td>{{ rowData.value.max }}</td>
                <td style="text-align:center; width: 7em;">
                    <button pButton type="button" icon="pi pi-pencil"
                        class="p-button-info" (click)="openUserEditor(rowData.value, fupSingle)" [disabled]="!allowVendorEdit() || !rowData.value.selected"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>

</div> -->

<p-footer>
    <hr>
    <div class="ui-grid ui-grid-responsive">
        <div class="ui-grid-col-1">
                <button pButton *ngIf="editMode && taskname && taskValidRange && recipients"
                label="Speichern" class="p-button-success" (click)="createTask()" [disabled]="!(hideUserEdit=='hidden') || (isTaskSaving$ | async)"></button>
            <button pButton *ngIf="editMode && (!taskname || !taskValidRange || !taskActivityRange || !recipients)"
                label="Speichern" class="p-button-secondary" [disabled]="true"
                pTooltip="Geben sie Aufgabenname und Start/Ende ein. Wählen Sie min. einen Empfänger."
                tooltipPosition="top"></button>
        </div>
        <div class="ui-grid-col-2" *ngIf="isTaskSaving$ | async">
            <p-progressSpinner [style]="{width: '25px', height:'25px'}"></p-progressSpinner>
        </div>
        <div class="ui-grid-col-6">
        <button pButton  label="Beenden" class="p-button-info" (click)="close()" [disabled]="!(hideUserEdit=='hidden') || (isTaskSaving$ | async)"></button>

        </div>

    </div>
</p-footer>
