import { Notification } from '@app/common/models';
import { MessageStatusEnum, MessageTypeEnum, SendMessageTypeContainer, SendMessageTypeGroupsContainer, SignalMessageContentModel } from '@app/common/models/signal.model';




export enum NotificationsActionType {

  OpenResponseModal = '[NOTIFICATIONS] OpenResponseModal',
  ShowMessageModal = '[NOTIFICATIONS] ShowMessageModal',
  GetAllNotificationsAction = "GetAllNotificationsAction",
  GetAllNotificationsActionDone = "GetAllNotificationsActionDone",
  AddNotificationAction = "AddNotificationAction",
  RemoveNotificationAction = "RemoveNotificationAction",
  GetNotificationsAction = "GetNotificationsAction",
  GetNotificationsActionDone = "GetNotificationsActionDone",
  DeleteSingleMessagesAction = "DeleteSingleMessagesAction",
  DeleteAllMessagesActionDone = "DeleteAllMessagesActionDone",
  DeleteAllMessagesAction = "DeleteAllMessagesAction",
  DeleteSingleMessagesActionDone = "DeleteSingleMessagesActionDone",
  NotificationErrorAction = "NotificationErrorAction",
  UpdateNotificationStatusAction = "UpdateNotificationStatusAction",
  SendMessagetoGroups = "SendMessagetoGroups",
  SendMessagetoGroupsDone = "SendMessagetoGroupsDone"
}






export class GetAllNotificationsAction {
  readonly type = NotificationsActionType.GetAllNotificationsAction;
  constructor(public payload: {targetUserId: string}) {}
}

export class GetAllNotificationsActionDone<T> {
  readonly type = NotificationsActionType.GetAllNotificationsActionDone;
  constructor(public payload: {signals: SendMessageTypeContainer<T>[]}) {}
}



export class AddNotificationAction {
  readonly type =  NotificationsActionType.AddNotificationAction ;
  constructor( public payload: { signal: SendMessageTypeContainer<SignalMessageContentModel>  }) {}
}

export class AddNotificationActionDone {
  readonly type =  NotificationsActionType.AddNotificationAction ;
  constructor( public payload: { signal: SendMessageTypeContainer<SignalMessageContentModel>  }) {}
}

export class SendMessagetoGroups<SignalMessageContentModel> {
  readonly type =  NotificationsActionType.SendMessagetoGroups ;
  constructor( public payload: { messcontainergroup: SendMessageTypeGroupsContainer<SignalMessageContentModel>, checkifexists : boolean }) {}
}

export class SendMessagetoGroupsDone<SignalMessageContentModel> {
  readonly type =  NotificationsActionType.SendMessagetoGroupsDone ;
  constructor( public payload: { signals:  SendMessageTypeContainer<SignalMessageContentModel>[]  }) {}
}




export class RemoveNotificationAction {
  readonly type =  NotificationsActionType.RemoveNotificationAction ;
  constructor( public payload: { signal: SendMessageTypeContainer<SignalMessageContentModel>  }) {}
}


export class GetNotificationsAction {
  readonly type =  NotificationsActionType.GetNotificationsAction ;
  constructor( public payload: { targetUserId : string,  messageType: MessageTypeEnum  }) {}
}


export class GetNotificationsActionDone<T> {
  readonly type =  NotificationsActionType.GetNotificationsActionDone ;
  constructor( public payload: { signals: SendMessageTypeContainer<T>[]  }) {}
}

export class DeleteSingleMessagesAction {
  readonly type =  NotificationsActionType.DeleteSingleMessagesAction ;
  constructor( public payload: { targetUserId : string,  messageType: MessageTypeEnum, id: number  }) {}
}

//Dummy, wird von signal erledigt
export class DeleteSingleMessagesActionDone {
  readonly type =  NotificationsActionType.DeleteSingleMessagesActionDone ;
  constructor( public payload: {messageType: MessageTypeEnum, id: number,  count : number  }) {}
}

export class DeleteAllMessagesAction {
  readonly type =  NotificationsActionType.DeleteAllMessagesAction ;
  constructor( public payload: { targetUserId : string,  messageType: MessageTypeEnum  }) {}
}


export class DeleteAllMessagesActionDone {
  readonly type =    NotificationsActionType.DeleteAllMessagesActionDone ;
  constructor( public payload: { count : number , messageType: MessageTypeEnum }) {}
}


export class NotificationErrorAction {
  readonly type =  NotificationsActionType.NotificationErrorAction ;
  constructor( public payload: { error : any}) {}
}

export class UpdateNotificationStatusAction {
  readonly type =  NotificationsActionType.UpdateNotificationStatusAction ;
  constructor( public payload: { targetUserId: string, type: MessageTypeEnum,  status: MessageStatusEnum }) {}
}

export class UpdateNotificationStatusActionDone {
  readonly type =  NotificationsActionType.UpdateNotificationStatusAction ;
  constructor( public payload: { cnt: number }) {}
}


export type NotificationsAction =
  GetAllNotificationsAction | GetAllNotificationsActionDone<any> | AddNotificationAction | AddNotificationActionDone | RemoveNotificationAction
| NotificationErrorAction | DeleteAllMessagesActionDone | DeleteAllMessagesAction | DeleteSingleMessagesAction | DeleteSingleMessagesActionDone
| GetNotificationsActionDone<any>
| SendMessagetoGroupsDone<any> | SendMessagetoGroups<any>
;
