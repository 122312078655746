/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfNullableLong } from '../models/list-response-args-of-nullable-long';
import { QueryTokenDTO } from '../models/query-token-dto';
import { ListResponseArgsOfKeyValuePairOfNullableLongAndString } from '../models/list-response-args-of-key-value-pair-of-nullable-long-and-string';
import { ListResponseArgsOfTupleOfStringAndNullableLong } from '../models/list-response-args-of-tuple-of-string-and-nullable-long';
import { ListResponseArgsOfTupleOfNullableLongAndStringAndNullableLong } from '../models/list-response-args-of-tuple-of-nullable-long-and-string-and-nullable-long';
import { ListResponseArgsOfTupleOfNullableLongAndStringAndNullableLongAndNullableLong } from '../models/list-response-args-of-tuple-of-nullable-long-and-string-and-nullable-long-and-nullable-long';
import { ListResponseArgsOfTupleOfNullableLongAndStringAndNullableLongAndNullableLongAndBoolean } from '../models/list-response-args-of-tuple-of-nullable-long-and-string-and-nullable-long-and-nullable-long-and-boolean';
import { ListResponseArgsOfNullableInteger } from '../models/list-response-args-of-nullable-integer';
import { ListResponseArgsOfString } from '../models/list-response-args-of-string';
import { ListResponseArgsOfNullableDateTime } from '../models/list-response-args-of-nullable-date-time';
import { ListResponseArgsOfNullableDecimal } from '../models/list-response-args-of-nullable-decimal';
import { ListResponseArgsOfNovitaetDto } from '../models/list-response-args-of-novitaet-dto';
import { ExtendedQueryTokenDTO } from '../models/extended-query-token-dto';
import { ResponseArgsOfIDictionaryOfStringAndInteger } from '../models/response-args-of-idictionary-of-string-and-integer';
import { ResponseArgsOfNovitaetDto } from '../models/response-args-of-novitaet-dto';
import { NovitaetDto } from '../models/novitaet-dto';
import { ResponseArgsOfBoolean } from '../models/response-args-of-boolean';
import { ResponseArgsOfIDictionaryOfLongAndArtikelResultDto } from '../models/response-args-of-idictionary-of-long-and-artikel-result-dto';
import { ListResponseArgsOfWkzImportResultItemDto } from '../models/list-response-args-of-wkz-import-result-item-dto';
@Injectable({
  providedIn: 'root',
})
class NovitaetService extends __BaseService {
  static readonly NovitaetQueryNovitaetenLongLookupPath = '/novi/lookup/long/{key}';
  static readonly NovitaetQueryNovitaetenEnumLookupPath = '/novi/lookup/enum/{key}';
  static readonly NovitaetQueryNovitaetenIdnameLookupPath = '/novi/lookup/idname/{key}';
  static readonly NovitaetQueryNovitaetenNameTypTypNameLookupPath = '/novi/lookup/nametyp/{key}';
  static readonly NovitaetQueryNovitaetenIdnametypLookupPath = '/novi/lookup/idnametyp/{key}';
  static readonly NovitaetQueryNovitaetenIdnametypIdLookupPath = '/novi/lookup/idnametypid/{key}';
  static readonly NovitaetQueryNovitaetenListenLookupPath = '/novi/lookup/idnametypidbool/{key}';
  static readonly NovitaetQueryNovitaetenIntLookupPath = '/novi/lookup/int/{key}';
  static readonly NovitaetQueryNovitaetenStringLookupPath = '/novi/lookup/string/{key}';
  static readonly NovitaetQueryNovitaetenDateLookupPath = '/novi/lookup/date/{key}';
  static readonly NovitaetQueryNovitaetenDecimalLookupPath = '/novi/lookup/decimal/{key}';
  static readonly NovitaetQueryNovitaetenDoubleLookupPath = '/novi/lookup/double/{key}';
  static readonly NovitaetQueryNovitaetenPath = '/novi/s';
  static readonly NovitaetQueryNovitaetenHitsPath = '/novi/hits';
  static readonly NovitaetExportXlsxPath = '/novi/xlsx';
  static readonly NovitaetExportKatalogXlsxPath = '/novi/katalog/xlsx';
  static readonly NovitaetUpdateNovitaetPath = '/novi/{id}';
  static readonly NovitaetDeleteNovitaetPath = '/novi/{id}';
  static readonly NovitaetUpdateNovitaetenPath = '/novi';
  static readonly NovitaetCreateNovitaetPath = '/novi';
  static readonly NovitaetCreateNovitaetenPath = '/novi/c';
  static readonly NovitaetTransferToAssortmentsPath = '/novi/transfer';
  static readonly NovitaetTransferToAssortments2Path = '/novi/s/transfer';
  static readonly NovitaetExportWkzPath = '/novi/wkz';
  static readonly NovitaetImportWkzPath = '/novi/wkz';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Novitäten Lookups (Typ Long)
   * @param params The `NovitaetService.NovitaetQueryNovitaetenLongLookupParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `key`:
   */
  NovitaetQueryNovitaetenLongLookupResponse(params: NovitaetService.NovitaetQueryNovitaetenLongLookupParams): __Observable<__StrictHttpResponse<ListResponseArgsOfNullableLong>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/novi/lookup/long/${encodeURIComponent(String(params.key))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfNullableLong>;
      })
    );
  }
  /**
   * Novitäten Lookups (Typ Long)
   * @param params The `NovitaetService.NovitaetQueryNovitaetenLongLookupParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `key`:
   */
  NovitaetQueryNovitaetenLongLookup(params: NovitaetService.NovitaetQueryNovitaetenLongLookupParams): __Observable<ListResponseArgsOfNullableLong> {
    return this.NovitaetQueryNovitaetenLongLookupResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfNullableLong)
    );
  }

  /**
   * Novitäten Lookups (Typ Enum)
   * @param params The `NovitaetService.NovitaetQueryNovitaetenEnumLookupParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `key`:
   */
  NovitaetQueryNovitaetenEnumLookupResponse(params: NovitaetService.NovitaetQueryNovitaetenEnumLookupParams): __Observable<__StrictHttpResponse<ListResponseArgsOfNullableLong>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/novi/lookup/enum/${encodeURIComponent(String(params.key))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfNullableLong>;
      })
    );
  }
  /**
   * Novitäten Lookups (Typ Enum)
   * @param params The `NovitaetService.NovitaetQueryNovitaetenEnumLookupParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `key`:
   */
  NovitaetQueryNovitaetenEnumLookup(params: NovitaetService.NovitaetQueryNovitaetenEnumLookupParams): __Observable<ListResponseArgsOfNullableLong> {
    return this.NovitaetQueryNovitaetenEnumLookupResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfNullableLong)
    );
  }

  /**
   * Novitäten Lookups (Typ KeyValuePair{long?, string} => ID/Name)
   * @param params The `NovitaetService.NovitaetQueryNovitaetenIdnameLookupParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `key`:
   */
  NovitaetQueryNovitaetenIdnameLookupResponse(params: NovitaetService.NovitaetQueryNovitaetenIdnameLookupParams): __Observable<__StrictHttpResponse<ListResponseArgsOfKeyValuePairOfNullableLongAndString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/novi/lookup/idname/${encodeURIComponent(String(params.key))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfKeyValuePairOfNullableLongAndString>;
      })
    );
  }
  /**
   * Novitäten Lookups (Typ KeyValuePair{long?, string} => ID/Name)
   * @param params The `NovitaetService.NovitaetQueryNovitaetenIdnameLookupParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `key`:
   */
  NovitaetQueryNovitaetenIdnameLookup(params: NovitaetService.NovitaetQueryNovitaetenIdnameLookupParams): __Observable<ListResponseArgsOfKeyValuePairOfNullableLongAndString> {
    return this.NovitaetQueryNovitaetenIdnameLookupResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfKeyValuePairOfNullableLongAndString)
    );
  }

  /**
   * Novitäten Lookups (Typ KeyValuePair{long?, string} => ID/Name)
   * @param params The `NovitaetService.NovitaetQueryNovitaetenNameTypTypNameLookupParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `key`:
   */
  NovitaetQueryNovitaetenNameTypTypNameLookupResponse(params: NovitaetService.NovitaetQueryNovitaetenNameTypTypNameLookupParams): __Observable<__StrictHttpResponse<ListResponseArgsOfTupleOfStringAndNullableLong>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/novi/lookup/nametyp/${encodeURIComponent(String(params.key))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfTupleOfStringAndNullableLong>;
      })
    );
  }
  /**
   * Novitäten Lookups (Typ KeyValuePair{long?, string} => ID/Name)
   * @param params The `NovitaetService.NovitaetQueryNovitaetenNameTypTypNameLookupParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `key`:
   */
  NovitaetQueryNovitaetenNameTypTypNameLookup(params: NovitaetService.NovitaetQueryNovitaetenNameTypTypNameLookupParams): __Observable<ListResponseArgsOfTupleOfStringAndNullableLong> {
    return this.NovitaetQueryNovitaetenNameTypTypNameLookupResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfTupleOfStringAndNullableLong)
    );
  }

  /**
   * Novitäten Lookups (Typ KeyValuePair{long?, string} => ID/Name)
   * @param params The `NovitaetService.NovitaetQueryNovitaetenIdnametypLookupParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `key`:
   */
  NovitaetQueryNovitaetenIdnametypLookupResponse(params: NovitaetService.NovitaetQueryNovitaetenIdnametypLookupParams): __Observable<__StrictHttpResponse<ListResponseArgsOfTupleOfNullableLongAndStringAndNullableLong>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/novi/lookup/idnametyp/${encodeURIComponent(String(params.key))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfTupleOfNullableLongAndStringAndNullableLong>;
      })
    );
  }
  /**
   * Novitäten Lookups (Typ KeyValuePair{long?, string} => ID/Name)
   * @param params The `NovitaetService.NovitaetQueryNovitaetenIdnametypLookupParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `key`:
   */
  NovitaetQueryNovitaetenIdnametypLookup(params: NovitaetService.NovitaetQueryNovitaetenIdnametypLookupParams): __Observable<ListResponseArgsOfTupleOfNullableLongAndStringAndNullableLong> {
    return this.NovitaetQueryNovitaetenIdnametypLookupResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfTupleOfNullableLongAndStringAndNullableLong)
    );
  }

  /**
   * Novitäten Lookups (Typ Tuple{long?, string, long?, long?} => ID/Name)
   * @param params The `NovitaetService.NovitaetQueryNovitaetenIdnametypIdLookupParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `key`:
   */
  NovitaetQueryNovitaetenIdnametypIdLookupResponse(params: NovitaetService.NovitaetQueryNovitaetenIdnametypIdLookupParams): __Observable<__StrictHttpResponse<ListResponseArgsOfTupleOfNullableLongAndStringAndNullableLongAndNullableLong>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/novi/lookup/idnametypid/${encodeURIComponent(String(params.key))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfTupleOfNullableLongAndStringAndNullableLongAndNullableLong>;
      })
    );
  }
  /**
   * Novitäten Lookups (Typ Tuple{long?, string, long?, long?} => ID/Name)
   * @param params The `NovitaetService.NovitaetQueryNovitaetenIdnametypIdLookupParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `key`:
   */
  NovitaetQueryNovitaetenIdnametypIdLookup(params: NovitaetService.NovitaetQueryNovitaetenIdnametypIdLookupParams): __Observable<ListResponseArgsOfTupleOfNullableLongAndStringAndNullableLongAndNullableLong> {
    return this.NovitaetQueryNovitaetenIdnametypIdLookupResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfTupleOfNullableLongAndStringAndNullableLongAndNullableLong)
    );
  }

  /**
   * Novitäten Lookups (Typ Tuple{long?, string, long?, long?, bool} => ID/Name/Typ/ID/bool)
   * @param params The `NovitaetService.NovitaetQueryNovitaetenListenLookupParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `key`:
   */
  NovitaetQueryNovitaetenListenLookupResponse(params: NovitaetService.NovitaetQueryNovitaetenListenLookupParams): __Observable<__StrictHttpResponse<ListResponseArgsOfTupleOfNullableLongAndStringAndNullableLongAndNullableLongAndBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/novi/lookup/idnametypidbool/${encodeURIComponent(String(params.key))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfTupleOfNullableLongAndStringAndNullableLongAndNullableLongAndBoolean>;
      })
    );
  }
  /**
   * Novitäten Lookups (Typ Tuple{long?, string, long?, long?, bool} => ID/Name/Typ/ID/bool)
   * @param params The `NovitaetService.NovitaetQueryNovitaetenListenLookupParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `key`:
   */
  NovitaetQueryNovitaetenListenLookup(params: NovitaetService.NovitaetQueryNovitaetenListenLookupParams): __Observable<ListResponseArgsOfTupleOfNullableLongAndStringAndNullableLongAndNullableLongAndBoolean> {
    return this.NovitaetQueryNovitaetenListenLookupResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfTupleOfNullableLongAndStringAndNullableLongAndNullableLongAndBoolean)
    );
  }

  /**
   * Novitäten Lookups (Typ Int)
   * @param params The `NovitaetService.NovitaetQueryNovitaetenIntLookupParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `key`:
   */
  NovitaetQueryNovitaetenIntLookupResponse(params: NovitaetService.NovitaetQueryNovitaetenIntLookupParams): __Observable<__StrictHttpResponse<ListResponseArgsOfNullableInteger>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/novi/lookup/int/${encodeURIComponent(String(params.key))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfNullableInteger>;
      })
    );
  }
  /**
   * Novitäten Lookups (Typ Int)
   * @param params The `NovitaetService.NovitaetQueryNovitaetenIntLookupParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `key`:
   */
  NovitaetQueryNovitaetenIntLookup(params: NovitaetService.NovitaetQueryNovitaetenIntLookupParams): __Observable<ListResponseArgsOfNullableInteger> {
    return this.NovitaetQueryNovitaetenIntLookupResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfNullableInteger)
    );
  }

  /**
   * Novitäten Lookups (Typ String)
   * @param params The `NovitaetService.NovitaetQueryNovitaetenStringLookupParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `key`:
   */
  NovitaetQueryNovitaetenStringLookupResponse(params: NovitaetService.NovitaetQueryNovitaetenStringLookupParams): __Observable<__StrictHttpResponse<ListResponseArgsOfString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/novi/lookup/string/${encodeURIComponent(String(params.key))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfString>;
      })
    );
  }
  /**
   * Novitäten Lookups (Typ String)
   * @param params The `NovitaetService.NovitaetQueryNovitaetenStringLookupParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `key`:
   */
  NovitaetQueryNovitaetenStringLookup(params: NovitaetService.NovitaetQueryNovitaetenStringLookupParams): __Observable<ListResponseArgsOfString> {
    return this.NovitaetQueryNovitaetenStringLookupResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfString)
    );
  }

  /**
   * Novitäten Lookups (Typ Date)
   * @param params The `NovitaetService.NovitaetQueryNovitaetenDateLookupParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `key`:
   */
  NovitaetQueryNovitaetenDateLookupResponse(params: NovitaetService.NovitaetQueryNovitaetenDateLookupParams): __Observable<__StrictHttpResponse<ListResponseArgsOfNullableDateTime>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/novi/lookup/date/${encodeURIComponent(String(params.key))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfNullableDateTime>;
      })
    );
  }
  /**
   * Novitäten Lookups (Typ Date)
   * @param params The `NovitaetService.NovitaetQueryNovitaetenDateLookupParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `key`:
   */
  NovitaetQueryNovitaetenDateLookup(params: NovitaetService.NovitaetQueryNovitaetenDateLookupParams): __Observable<ListResponseArgsOfNullableDateTime> {
    return this.NovitaetQueryNovitaetenDateLookupResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfNullableDateTime)
    );
  }

  /**
   * Novitäten Lookups (Typ Decimal)
   * @param params The `NovitaetService.NovitaetQueryNovitaetenDecimalLookupParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `key`:
   */
  NovitaetQueryNovitaetenDecimalLookupResponse(params: NovitaetService.NovitaetQueryNovitaetenDecimalLookupParams): __Observable<__StrictHttpResponse<ListResponseArgsOfNullableDecimal>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/novi/lookup/decimal/${encodeURIComponent(String(params.key))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfNullableDecimal>;
      })
    );
  }
  /**
   * Novitäten Lookups (Typ Decimal)
   * @param params The `NovitaetService.NovitaetQueryNovitaetenDecimalLookupParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `key`:
   */
  NovitaetQueryNovitaetenDecimalLookup(params: NovitaetService.NovitaetQueryNovitaetenDecimalLookupParams): __Observable<ListResponseArgsOfNullableDecimal> {
    return this.NovitaetQueryNovitaetenDecimalLookupResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfNullableDecimal)
    );
  }

  /**
   * Novitäten Lookups (Typ Double)
   * @param params The `NovitaetService.NovitaetQueryNovitaetenDoubleLookupParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `key`:
   */
  NovitaetQueryNovitaetenDoubleLookupResponse(params: NovitaetService.NovitaetQueryNovitaetenDoubleLookupParams): __Observable<__StrictHttpResponse<ListResponseArgsOfNullableDecimal>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/novi/lookup/double/${encodeURIComponent(String(params.key))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfNullableDecimal>;
      })
    );
  }
  /**
   * Novitäten Lookups (Typ Double)
   * @param params The `NovitaetService.NovitaetQueryNovitaetenDoubleLookupParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `key`:
   */
  NovitaetQueryNovitaetenDoubleLookup(params: NovitaetService.NovitaetQueryNovitaetenDoubleLookupParams): __Observable<ListResponseArgsOfNullableDecimal> {
    return this.NovitaetQueryNovitaetenDoubleLookupResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfNullableDecimal)
    );
  }

  /**
   * Query Novitäten
   * @param params The `NovitaetService.NovitaetQueryNovitaetenParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `eagerLoading`:
   */
  NovitaetQueryNovitaetenResponse(params: NovitaetService.NovitaetQueryNovitaetenParams): __Observable<__StrictHttpResponse<ListResponseArgsOfNovitaetDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/novi/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfNovitaetDto>;
      })
    );
  }
  /**
   * Query Novitäten
   * @param params The `NovitaetService.NovitaetQueryNovitaetenParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `eagerLoading`:
   */
  NovitaetQueryNovitaeten(params: NovitaetService.NovitaetQueryNovitaetenParams): __Observable<ListResponseArgsOfNovitaetDto> {
    return this.NovitaetQueryNovitaetenResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfNovitaetDto)
    );
  }

  /**
   * Query Novitäten
   * @param queryToken undefined
   */
  NovitaetQueryNovitaetenHitsResponse(queryToken: {[key: string]: ExtendedQueryTokenDTO}): __Observable<__StrictHttpResponse<ResponseArgsOfIDictionaryOfStringAndInteger>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = queryToken;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/novi/hits`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIDictionaryOfStringAndInteger>;
      })
    );
  }
  /**
   * Query Novitäten
   * @param queryToken undefined
   */
  NovitaetQueryNovitaetenHits(queryToken: {[key: string]: ExtendedQueryTokenDTO}): __Observable<ResponseArgsOfIDictionaryOfStringAndInteger> {
    return this.NovitaetQueryNovitaetenHitsResponse(queryToken).pipe(
      __map(_r => _r.body as ResponseArgsOfIDictionaryOfStringAndInteger)
    );
  }

  /**
   * Export Novitäten (XLSX)
   * @param params The `NovitaetService.NovitaetExportXlsxParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `mandant`:
   *
   * - `lieferant`:
   *
   * - `eagerLoading`:
   */
  NovitaetExportXlsxResponse(params: NovitaetService.NovitaetExportXlsxParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.mandant != null) __params = __params.set('mandant', params.mandant.toString());
    if (params.lieferant != null) __params = __params.set('lieferant', params.lieferant.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/novi/xlsx`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Export Novitäten (XLSX)
   * @param params The `NovitaetService.NovitaetExportXlsxParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `mandant`:
   *
   * - `lieferant`:
   *
   * - `eagerLoading`:
   */
  NovitaetExportXlsx(params: NovitaetService.NovitaetExportXlsxParams): __Observable<Blob> {
    return this.NovitaetExportXlsxResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Export für den H-Katalog (XLSX)
   * @param params The `NovitaetService.NovitaetExportKatalogXlsxParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `mandant`:
   *
   * - `lieferant`:
   *
   * - `eagerLoading`:
   */
  NovitaetExportKatalogXlsxResponse(params: NovitaetService.NovitaetExportKatalogXlsxParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.mandant != null) __params = __params.set('mandant', params.mandant.toString());
    if (params.lieferant != null) __params = __params.set('lieferant', params.lieferant.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/novi/katalog/xlsx`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Export für den H-Katalog (XLSX)
   * @param params The `NovitaetService.NovitaetExportKatalogXlsxParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `mandant`:
   *
   * - `lieferant`:
   *
   * - `eagerLoading`:
   */
  NovitaetExportKatalogXlsx(params: NovitaetService.NovitaetExportKatalogXlsxParams): __Observable<Blob> {
    return this.NovitaetExportKatalogXlsxResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Novität ändern
   * @param params The `NovitaetService.NovitaetUpdateNovitaetParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `id`:
   *
   * - `user`:
   */
  NovitaetUpdateNovitaetResponse(params: NovitaetService.NovitaetUpdateNovitaetParams): __Observable<__StrictHttpResponse<ResponseArgsOfNovitaetDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/novi/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfNovitaetDto>;
      })
    );
  }
  /**
   * Novität ändern
   * @param params The `NovitaetService.NovitaetUpdateNovitaetParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `id`:
   *
   * - `user`:
   */
  NovitaetUpdateNovitaet(params: NovitaetService.NovitaetUpdateNovitaetParams): __Observable<ResponseArgsOfNovitaetDto> {
    return this.NovitaetUpdateNovitaetResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfNovitaetDto)
    );
  }

  /**
   * Novität löschen
   * @param params The `NovitaetService.NovitaetDeleteNovitaetParams` containing the following parameters:
   *
   * - `id`: Sortiment Id
   *
   * - `user`: User Id oder Token
   */
  NovitaetDeleteNovitaetResponse(params: NovitaetService.NovitaetDeleteNovitaetParams): __Observable<__StrictHttpResponse<ResponseArgsOfBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/novi/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBoolean>;
      })
    );
  }
  /**
   * Novität löschen
   * @param params The `NovitaetService.NovitaetDeleteNovitaetParams` containing the following parameters:
   *
   * - `id`: Sortiment Id
   *
   * - `user`: User Id oder Token
   */
  NovitaetDeleteNovitaet(params: NovitaetService.NovitaetDeleteNovitaetParams): __Observable<ResponseArgsOfBoolean> {
    return this.NovitaetDeleteNovitaetResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfBoolean)
    );
  }

  /**
   * Novitäten ändern
   * @param params The `NovitaetService.NovitaetUpdateNovitaetenParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `user`:
   */
  NovitaetUpdateNovitaetenResponse(params: NovitaetService.NovitaetUpdateNovitaetenParams): __Observable<__StrictHttpResponse<ListResponseArgsOfNovitaetDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/novi`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfNovitaetDto>;
      })
    );
  }
  /**
   * Novitäten ändern
   * @param params The `NovitaetService.NovitaetUpdateNovitaetenParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `user`:
   */
  NovitaetUpdateNovitaeten(params: NovitaetService.NovitaetUpdateNovitaetenParams): __Observable<ListResponseArgsOfNovitaetDto> {
    return this.NovitaetUpdateNovitaetenResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfNovitaetDto)
    );
  }

  /**
   * Novitaet erzeugen
   * @param params The `NovitaetService.NovitaetCreateNovitaetParams` containing the following parameters:
   *
   * - `payload`: SortimentDto
   *
   * - `user`: User Id oder Token
   */
  NovitaetCreateNovitaetResponse(params: NovitaetService.NovitaetCreateNovitaetParams): __Observable<__StrictHttpResponse<ResponseArgsOfNovitaetDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/novi`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfNovitaetDto>;
      })
    );
  }
  /**
   * Novitaet erzeugen
   * @param params The `NovitaetService.NovitaetCreateNovitaetParams` containing the following parameters:
   *
   * - `payload`: SortimentDto
   *
   * - `user`: User Id oder Token
   */
  NovitaetCreateNovitaet(params: NovitaetService.NovitaetCreateNovitaetParams): __Observable<ResponseArgsOfNovitaetDto> {
    return this.NovitaetCreateNovitaetResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfNovitaetDto)
    );
  }

  /**
   * Novitaeten erzeugen
   * @param params The `NovitaetService.NovitaetCreateNovitaetenParams` containing the following parameters:
   *
   * - `payload`: SortimentDto
   *
   * - `user`: User Id oder Token
   */
  NovitaetCreateNovitaetenResponse(params: NovitaetService.NovitaetCreateNovitaetenParams): __Observable<__StrictHttpResponse<ListResponseArgsOfNovitaetDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.user != null) __params = __params.set('user', params.user.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/novi/c`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfNovitaetDto>;
      })
    );
  }
  /**
   * Novitaeten erzeugen
   * @param params The `NovitaetService.NovitaetCreateNovitaetenParams` containing the following parameters:
   *
   * - `payload`: SortimentDto
   *
   * - `user`: User Id oder Token
   */
  NovitaetCreateNovitaeten(params: NovitaetService.NovitaetCreateNovitaetenParams): __Observable<ListResponseArgsOfNovitaetDto> {
    return this.NovitaetCreateNovitaetenResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfNovitaetDto)
    );
  }

  /**
   * In die Arbeitssortimente übernehmen
   * @param params The `NovitaetService.NovitaetTransferToAssortmentsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `user`:
   *
   * - `assortmentIds`:
   */
  NovitaetTransferToAssortmentsResponse(params: NovitaetService.NovitaetTransferToAssortmentsParams): __Observable<__StrictHttpResponse<ResponseArgsOfIDictionaryOfLongAndArtikelResultDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.user != null) __params = __params.set('user', params.user.toString());
    (params.assortmentIds || []).forEach(val => {if (val != null) __params = __params.append('assortmentIds', val.toString())});
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/novi/transfer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIDictionaryOfLongAndArtikelResultDto>;
      })
    );
  }
  /**
   * In die Arbeitssortimente übernehmen
   * @param params The `NovitaetService.NovitaetTransferToAssortmentsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `user`:
   *
   * - `assortmentIds`:
   */
  NovitaetTransferToAssortments(params: NovitaetService.NovitaetTransferToAssortmentsParams): __Observable<ResponseArgsOfIDictionaryOfLongAndArtikelResultDto> {
    return this.NovitaetTransferToAssortmentsResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIDictionaryOfLongAndArtikelResultDto)
    );
  }

  /**
   * In die Arbeitssortimente übernehmen
   * @param params The `NovitaetService.NovitaetTransferToAssortments2Params` containing the following parameters:
   *
   * - `payload`:
   *
   * - `user`:
   *
   * - `assortmentIds`:
   */
  NovitaetTransferToAssortments2Response(params: NovitaetService.NovitaetTransferToAssortments2Params): __Observable<__StrictHttpResponse<ResponseArgsOfIDictionaryOfLongAndArtikelResultDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.user != null) __params = __params.set('user', params.user.toString());
    (params.assortmentIds || []).forEach(val => {if (val != null) __params = __params.append('assortmentIds', val.toString())});
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/novi/s/transfer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIDictionaryOfLongAndArtikelResultDto>;
      })
    );
  }
  /**
   * In die Arbeitssortimente übernehmen
   * @param params The `NovitaetService.NovitaetTransferToAssortments2Params` containing the following parameters:
   *
   * - `payload`:
   *
   * - `user`:
   *
   * - `assortmentIds`:
   */
  NovitaetTransferToAssortments2(params: NovitaetService.NovitaetTransferToAssortments2Params): __Observable<ResponseArgsOfIDictionaryOfLongAndArtikelResultDto> {
    return this.NovitaetTransferToAssortments2Response(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIDictionaryOfLongAndArtikelResultDto)
    );
  }

  /**
   * Export der Marketingvorschläge
   * @param datum undefined
   */
  NovitaetExportWkzResponse(datum?: null | string): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (datum != null) __params = __params.set('datum', datum.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/novi/wkz`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Export der Marketingvorschläge
   * @param datum undefined
   */
  NovitaetExportWkz(datum?: null | string): __Observable<Blob> {
    return this.NovitaetExportWkzResponse(datum).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Import WKZ
   * @param params The `NovitaetService.NovitaetImportWkzParams` containing the following parameters:
   *
   * - `user`:
   *
   * - `cleanupAfterImport`:
   */
  NovitaetImportWkzResponse(params: NovitaetService.NovitaetImportWkzParams): __Observable<__StrictHttpResponse<ListResponseArgsOfWkzImportResultItemDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.user != null) __params = __params.set('user', params.user.toString());
    if (params.cleanupAfterImport != null) __params = __params.set('cleanupAfterImport', params.cleanupAfterImport.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/novi/wkz`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfWkzImportResultItemDto>;
      })
    );
  }
  /**
   * Import WKZ
   * @param params The `NovitaetService.NovitaetImportWkzParams` containing the following parameters:
   *
   * - `user`:
   *
   * - `cleanupAfterImport`:
   */
  NovitaetImportWkz(params: NovitaetService.NovitaetImportWkzParams): __Observable<ListResponseArgsOfWkzImportResultItemDto> {
    return this.NovitaetImportWkzResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfWkzImportResultItemDto)
    );
  }
}

module NovitaetService {

  /**
   * Parameters for NovitaetQueryNovitaetenLongLookup
   */
  export interface NovitaetQueryNovitaetenLongLookupParams {
    queryToken: QueryTokenDTO;
    key: string;
  }

  /**
   * Parameters for NovitaetQueryNovitaetenEnumLookup
   */
  export interface NovitaetQueryNovitaetenEnumLookupParams {
    queryToken: QueryTokenDTO;
    key: string;
  }

  /**
   * Parameters for NovitaetQueryNovitaetenIdnameLookup
   */
  export interface NovitaetQueryNovitaetenIdnameLookupParams {
    queryToken: QueryTokenDTO;
    key: string;
  }

  /**
   * Parameters for NovitaetQueryNovitaetenNameTypTypNameLookup
   */
  export interface NovitaetQueryNovitaetenNameTypTypNameLookupParams {
    queryToken: QueryTokenDTO;
    key: string;
  }

  /**
   * Parameters for NovitaetQueryNovitaetenIdnametypLookup
   */
  export interface NovitaetQueryNovitaetenIdnametypLookupParams {
    queryToken: QueryTokenDTO;
    key: string;
  }

  /**
   * Parameters for NovitaetQueryNovitaetenIdnametypIdLookup
   */
  export interface NovitaetQueryNovitaetenIdnametypIdLookupParams {
    queryToken: QueryTokenDTO;
    key: string;
  }

  /**
   * Parameters for NovitaetQueryNovitaetenListenLookup
   */
  export interface NovitaetQueryNovitaetenListenLookupParams {
    queryToken: QueryTokenDTO;
    key: string;
  }

  /**
   * Parameters for NovitaetQueryNovitaetenIntLookup
   */
  export interface NovitaetQueryNovitaetenIntLookupParams {
    queryToken: QueryTokenDTO;
    key: string;
  }

  /**
   * Parameters for NovitaetQueryNovitaetenStringLookup
   */
  export interface NovitaetQueryNovitaetenStringLookupParams {
    queryToken: QueryTokenDTO;
    key: string;
  }

  /**
   * Parameters for NovitaetQueryNovitaetenDateLookup
   */
  export interface NovitaetQueryNovitaetenDateLookupParams {
    queryToken: QueryTokenDTO;
    key: string;
  }

  /**
   * Parameters for NovitaetQueryNovitaetenDecimalLookup
   */
  export interface NovitaetQueryNovitaetenDecimalLookupParams {
    queryToken: QueryTokenDTO;
    key: string;
  }

  /**
   * Parameters for NovitaetQueryNovitaetenDoubleLookup
   */
  export interface NovitaetQueryNovitaetenDoubleLookupParams {
    queryToken: QueryTokenDTO;
    key: string;
  }

  /**
   * Parameters for NovitaetQueryNovitaeten
   */
  export interface NovitaetQueryNovitaetenParams {
    queryToken: ExtendedQueryTokenDTO;
    eagerLoading?: number;
  }

  /**
   * Parameters for NovitaetExportXlsx
   */
  export interface NovitaetExportXlsxParams {
    queryToken: ExtendedQueryTokenDTO;
    mandant?: boolean;
    lieferant?: boolean;
    eagerLoading?: number;
  }

  /**
   * Parameters for NovitaetExportKatalogXlsx
   */
  export interface NovitaetExportKatalogXlsxParams {
    queryToken: ExtendedQueryTokenDTO;
    mandant?: boolean;
    lieferant?: boolean;
    eagerLoading?: number;
  }

  /**
   * Parameters for NovitaetUpdateNovitaet
   */
  export interface NovitaetUpdateNovitaetParams {
    payload: NovitaetDto;
    id: number;
    user?: null | string;
  }

  /**
   * Parameters for NovitaetDeleteNovitaet
   */
  export interface NovitaetDeleteNovitaetParams {

    /**
     * Sortiment Id
     */
    id: number;

    /**
     * User Id oder Token
     */
    user?: null | string;
  }

  /**
   * Parameters for NovitaetUpdateNovitaeten
   */
  export interface NovitaetUpdateNovitaetenParams {
    payload: Array<NovitaetDto>;
    user?: null | string;
  }

  /**
   * Parameters for NovitaetCreateNovitaet
   */
  export interface NovitaetCreateNovitaetParams {

    /**
     * SortimentDto
     */
    payload: NovitaetDto;

    /**
     * User Id oder Token
     */
    user?: null | string;
  }

  /**
   * Parameters for NovitaetCreateNovitaeten
   */
  export interface NovitaetCreateNovitaetenParams {

    /**
     * SortimentDto
     */
    payload: Array<NovitaetDto>;

    /**
     * User Id oder Token
     */
    user?: null | string;
  }

  /**
   * Parameters for NovitaetTransferToAssortments
   */
  export interface NovitaetTransferToAssortmentsParams {
    payload: Array<number>;
    user?: null | string;
    assortmentIds?: null | Array<number>;
  }

  /**
   * Parameters for NovitaetTransferToAssortments2
   */
  export interface NovitaetTransferToAssortments2Params {
    payload: QueryTokenDTO;
    user?: null | string;
    assortmentIds?: null | Array<number>;
  }

  /**
   * Parameters for NovitaetImportWkz
   */
  export interface NovitaetImportWkzParams {
    user?: null | string;
    cleanupAfterImport?: boolean;
  }
}

export { NovitaetService }
