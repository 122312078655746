<div class="container">

  <div  class=".vertical-center" style="margin: 5rem;" *ngFor="let item of  getDashBoardInfos() | async">
    <h1 class="grid">
      <i style="font-size: var(--fontSizeBigger); color:cornflowerblue;  margin-right: 1.5rem; margin-bottom: auto; margin-top: auto; "
        *ngIf="!item.islieferant" class="pi pi-exclamation-circle"></i>
      <div [style]="item.islieferant ? 'margin-top: 10rem;'  : '' " [innerHTML]="item.text" > </div>
    </h1>
  </div>
  <div class="grid" >
    <div class="col-6" *ngIf="ismanager == true && (this.selecthasSOMADiagramm$ | async)   ">
        <app-novitaetendiagramm-soma ></app-novitaetendiagramm-soma>
    </div>
    <div [class]="ismanager == true && (this.selecthasSOMADiagramm$ | async)  ? 'col-6': 'col'" >
      <app-notifications></app-notifications>
    </div>
  </div>

</div>
