/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfZuordnungDto } from '../models/list-response-args-of-zuordnung-dto';
import { QueryTokenDTO } from '../models/query-token-dto';
import { ResponseArgsOfZuordnungDto } from '../models/response-args-of-zuordnung-dto';
import { ZuordnungDto } from '../models/zuordnung-dto';
@Injectable({
  providedIn: 'root',
})
class ZuordnungService extends __BaseService {
  static readonly ZuordnungQueryPath = '/zuordnung/s';
  static readonly ZuordnungDeleteZuordnungPath = '/zuordnung/{id}';
  static readonly ZuordnungCreateZuordnungPath = '/zuordnung';
  static readonly ZuordnungUpdateZuordnungPath = '/zuordnung';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Sortimente
   * @param params The `ZuordnungService.ZuordnungQueryParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `eagerLoading`:
   */
  ZuordnungQueryResponse(params: ZuordnungService.ZuordnungQueryParams): __Observable<__StrictHttpResponse<ListResponseArgsOfZuordnungDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/zuordnung/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfZuordnungDto>;
      })
    );
  }
  /**
   * Sortimente
   * @param params The `ZuordnungService.ZuordnungQueryParams` containing the following parameters:
   *
   * - `queryToken`:
   *
   * - `eagerLoading`:
   */
  ZuordnungQuery(params: ZuordnungService.ZuordnungQueryParams): __Observable<ListResponseArgsOfZuordnungDto> {
    return this.ZuordnungQueryResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfZuordnungDto)
    );
  }

  /**
   * Zuordnung Löschen
   * @param id undefined
   */
  ZuordnungDeleteZuordnungResponse(id: number): __Observable<__StrictHttpResponse<ResponseArgsOfZuordnungDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/zuordnung/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfZuordnungDto>;
      })
    );
  }
  /**
   * Zuordnung Löschen
   * @param id undefined
   */
  ZuordnungDeleteZuordnung(id: number): __Observable<ResponseArgsOfZuordnungDto> {
    return this.ZuordnungDeleteZuordnungResponse(id).pipe(
      __map(_r => _r.body as ResponseArgsOfZuordnungDto)
    );
  }

  /**
   * Neue Zuordnung
   * @param payload undefined
   */
  ZuordnungCreateZuordnungResponse(payload: ZuordnungDto): __Observable<__StrictHttpResponse<ResponseArgsOfZuordnungDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/zuordnung`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfZuordnungDto>;
      })
    );
  }
  /**
   * Neue Zuordnung
   * @param payload undefined
   */
  ZuordnungCreateZuordnung(payload: ZuordnungDto): __Observable<ResponseArgsOfZuordnungDto> {
    return this.ZuordnungCreateZuordnungResponse(payload).pipe(
      __map(_r => _r.body as ResponseArgsOfZuordnungDto)
    );
  }

  /**
   * Update Zuordnung
   * @param payload undefined
   */
  ZuordnungUpdateZuordnungResponse(payload: Array<ZuordnungDto>): __Observable<__StrictHttpResponse<ListResponseArgsOfZuordnungDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/zuordnung`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfZuordnungDto>;
      })
    );
  }
  /**
   * Update Zuordnung
   * @param payload undefined
   */
  ZuordnungUpdateZuordnung(payload: Array<ZuordnungDto>): __Observable<ListResponseArgsOfZuordnungDto> {
    return this.ZuordnungUpdateZuordnungResponse(payload).pipe(
      __map(_r => _r.body as ListResponseArgsOfZuordnungDto)
    );
  }
}

module ZuordnungService {

  /**
   * Parameters for ZuordnungQuery
   */
  export interface ZuordnungQueryParams {
    queryToken: QueryTokenDTO;
    eagerLoading?: number;
  }
}

export { ZuordnungService }
