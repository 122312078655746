import { CalendarState, InitialCalendarState } from './state';
import { DisplayAllAttributesAction, CalendarActionType, GetEventsByModelDoneAction, CalendarActions,
    GetStructureModelDoneAction } from './actions';

export function reducer(state: CalendarState = InitialCalendarState, action: CalendarActions): CalendarState {

    switch (action.type) {
        case CalendarActionType.DisplayAllAttributes:
            return DisplayAllAttributesReducer(state, action);
        case CalendarActionType.GetEventsByModelDone:
            return GetEventsReducer(state, action);
        case CalendarActionType.GetStructureModelDone:
            return GetStructureReducer(state, action);

    }

    return state;
}

export function DisplayAllAttributesReducer(state: CalendarState = InitialCalendarState, action: DisplayAllAttributesAction): CalendarState {
    console.log('DisplayAllAttributesReducer', state);
    console.log('Payload:', action);
    return {
        ...state
    };
}

export function GetEventsReducer(state: CalendarState = InitialCalendarState, action: GetEventsByModelDoneAction): CalendarState {
    return {
        ...state,
        calendarData: action.payload.dataModel
    };
}

export function GetStructureReducer(state: CalendarState = InitialCalendarState, action: GetStructureModelDoneAction): CalendarState {
    return {
        ...state,
        structureData: action.payload.structureModel
    };
}





