
import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable, EMPTY } from 'rxjs';
import { ResultObjectModel } from '../models';

import { IMediaTip, IMediatipSearchParameter } from '../models/mediatips.models';
import { AuthService } from './auth.service';
import { IBubData } from '../models/bubdata.model';
import { LiPoContact } from '../models/lipocontact.model';

import { map, catchError } from 'rxjs/operators';


/**Service die Medientipps*/
@Injectable()
export class MediatipService {

    constructor(private authService: AuthService, private httpClient: HttpClient)
    {

    }

    deleteMediatip(mediatipid: number) : Observable<ResultObjectModel<number>> {
      const url = `${environment.connections.MediatipService}/mediatip/deletemediatip`  ;
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + this.authService.getAccessToken()
        }),
        params: { mediatipId: mediatipid.toString() }
      };
      return this.httpClient.get<ResultObjectModel<number>>(url, httpOptions);
    }

    insertNewMediatip(mediatip: IMediaTip) : Observable<ResultObjectModel<IMediaTip>> {
      const url = `${environment.connections.MediatipService}/mediatip/insertmediatip`  ;
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + this.authService.getAccessToken()
        })
      };
      let result: ResultObjectModel<IMediaTip>;
      return this.httpClient.post<ResultObjectModel<IMediaTip>>(url, mediatip, httpOptions) ;

    }

    getLiPoContacts() : Observable<ResultObjectModel<LiPoContact[]>>
    {
      const url = `${environment.connections.MediatipService}/mediatip/getLiPoContacts`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + this.authService.getAccessToken()
        }
        )
      };
      return  this.httpClient.get<ResultObjectModel<LiPoContact[]>>(url, httpOptions);
    }


    updateMediatip(mediatip: IMediaTip) : Observable<ResultObjectModel<IMediaTip>> {
      const url = `${environment.connections.MediatipService}/mediatip/updatemediatip`  ;
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + this.authService.getAccessToken()
        })
      };
      return this.httpClient.post<ResultObjectModel<IMediaTip>>(url, mediatip, httpOptions);
    }

    /**Alle Mediatipps des angemeldeten Users oder Manager  */
    getMediatips(p: IMediatipSearchParameter): Observable<ResultObjectModel<IMediaTip[]>> {
      const url = `${environment.connections.MediatipService}/mediatip/getmediatips`  ;
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + this.authService.getAccessToken()
        })
      };
      return this.httpClient.post<ResultObjectModel<IMediaTip[]>>(url, p, httpOptions);
    }

      getMediatip(id: number, ismanager: boolean): Observable<ResultObjectModel<IMediaTip>> {
        const url = `${environment.connections.MediatipService}/mediatip/getmediatip`  ;
        const httpOptions = {
          headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + this.authService.getAccessToken()
          }),
          params: {id: id.toString(), ismanager: ismanager.toString()}
        };
        return this.httpClient.get<ResultObjectModel<IMediaTip>>(url, httpOptions);
      }

      getMediatipByIdReadonly(id: number): Observable<ResultObjectModel<IMediaTip>> {
        const url = `${environment.connections.MediatipService}/mediatip/getmediatip`  ;
        const httpOptions = {
          headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + this.authService.getAccessToken()
          }),
          params: {id: id.toString()}
        };
        return this.httpClient.get<ResultObjectModel<IMediaTip>>(url, httpOptions).pipe(map(x => {x.data.isReadonly=true; return x}));
      }

      getBubDataByEan(ean: string): Observable<ResultObjectModel<IBubData>> {
        const url = `${environment.connections.MediatipService}/Mediatip/GetBubDataByEan`  ;
        const httpOptions = {
          headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + this.authService.getAccessToken()
          }),
          params: {ean: ean}
        };
        var x = this.httpClient.get<ResultObjectModel<IBubData>>(url, httpOptions);
        return x;
      }

      getBubDataByEans(eans: string[]): Observable<ResultObjectModel<IBubData[]>> {
        const url = `${environment.connections.MediatipService}/Mediatip/GetBubDataByEans`  ;
        const httpOptions = {
          headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + this.authService.getAccessToken()
          })
        };
        var x = this.httpClient.post<ResultObjectModel<IBubData[]>>(url, eans, httpOptions);
        return x;
      }

}

