import { AppState as RootState } from '@app/store';
import { createFeatureSelector } from '@ngrx/store';

import { CalendarDataModel } from '@app/common/models/CalendarData.model';
import { FilterStructureModel } from '@app/common/models/filterStructure.model';


import { TaskStructureModel } from '@app/common/models/taskStructure.model';
import { KlammerDto, SortimentDto, TypDto } from '@app/api/filialmatrix/models';
import { SortimentDtoClient } from '@app/common/models/sortimente/SortimentDtoClient';
import { TaskUserModel } from '@app/common/models';

export interface CalendarState
{
    calendarData: CalendarDataModel[];
    structureData: FilterStructureModel[];
    sortmasters: KlammerDto[];
    sortiments: SortimentDto[];
    sortimentTypes: TypDto[];
    singleTask: TaskStructureModel<TaskUserModel>;
    usersCalendarSortimente: SortimentDtoClient[];
}

export interface AppState extends RootState {
    calendar: CalendarState;
}

export const InitialCalendarState: CalendarState = {
    calendarData: [],
    structureData: [],
    sortmasters: [],
    sortiments: [],

    sortimentTypes: [],
    singleTask: null,
    usersCalendarSortimente: [],
};

export const featureName = 'calendar';

export const selectedCalendarState = createFeatureSelector<CalendarState>(featureName);
