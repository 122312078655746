import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from "@angular/router";

/**Komponenten resuse  bei Routing
 * config z.B. => data: {     shouldReuseRoute: true,     reuseRoutesFrom: ['dashboard']
 */
export class LipoRouteReuseStrategy implements RouteReuseStrategy {

  private routeStore = new Map<string, DetachedRouteHandle>();
  private routeLeftFrom: string = '';

  constructor() {}



  shouldDetach(route: ActivatedRouteSnapshot): boolean {
     this.routeLeftFrom = route.routeConfig.path;
     return route.data.shouldReuseRoute || false;

  }
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    this.routeStore.set(route.routeConfig.path, handle);

  }
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    if ( route.url == undefined ) return false;
    var wasRoutePreviouslyDetached = !!this.routeStore.get(route.url.join('/') || (route.parent &&  route.parent.url.join('/')));
        if (wasRoutePreviouslyDetached ) {
            var reuseRouteFromVerified = route.data &&  route.data.reuseRoutesFrom &&  route.data.reuseRoutesFrom.indexOf(this.routeLeftFrom) > -1;

            if (reuseRouteFromVerified) {

                return true;
            }
        }
        return false;
  }
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    const path = route.routeConfig.path;
    return this.routeStore.get(path);
  }
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }
}
