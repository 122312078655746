import { AppState as RootState } from '../../store';
import { ValidationList, AvailableColumn, ValidationListColumn } from '@app/common/models';
import { ItemValueModel } from '@app/common/models/columnBase.model';
import { createFeatureSelector } from '@ngrx/store';

export interface ValidationEditorState
{
    validationLists: ValidationList[];
    validationListColumns: ValidationListColumn[];
    availableColumns: AvailableColumn[];
    staticUsedlistValues: {[key: number]: ItemValueModel[]};
}

export interface AppState extends RootState {
    validationEditorState: ValidationEditorState;
}

export const InitialValidationEditorState = {
    validationLists: [],
    validationListColumns: [],
    availableColumns: [],
    staticUsedlistValues: {}
}

export const featureName = 'validationEditorState';

export const selectValidationEditorState = createFeatureSelector<ValidationEditorState>(featureName);
