<p-panel >
  <p-header>{{selectTitel$ | async }}</p-header>
  <div #ContainerRef class="grid"   >
  <ngx-charts-bar-vertical-stacked
  [view]="[ContainerRef.offsetWidth, 300]"
  [scheme]="colorScheme"
  [results]="selectSOMADiagramm$ | async"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="showLegend"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [animations]="animations"
  (select)="onSelect($event)">
</ngx-charts-bar-vertical-stacked>
</div>
</p-panel>


