import { AppState as RootState } from '../../store';
import { createFeatureSelector } from '@ngrx/store';
import { CompareTitleFis } from '@app/common/models/novi/compareTitel.model';
import { LookupValue, LookupValueBase } from '@app/common/models/novi/columnLookup.model';
import { LiPoContact } from '@app/common/models/lipocontact.model';
import { CustomSetting } from '@app/common/models/custom.settings.models';
import { SimpleResultError } from '@app/common/models/simpleResult.model';
import { ColorUserModel, ColorUserModelHist } from '@app/common/models/novi/Column.UserModel';

import { BedarfDto, EinkaufDto, KeyValueDto, KlammerSortimentStichwort, LagerDto, LieferantDto, ListeDto, MandantDto, MarketingEreignissDto, MoebelDto, RegionDto, SaisonDto, SortimentDto, TypDto } from '@app/api/filialmatrix/models';
import { WarengroupDto } from '@app/common/models/warengroupdto';
import { IBubData } from '@app/common/models/bubdata.model';
import { CommonDataActionType } from './actions';
import { DataColumnConfigSuccess, StoreColumnSetting } from '../models/storecolumnsetting';
import { KlammerDtoClient } from '@app/common/models/sortimente/KlammerDtoClient';
import { SortimentDtoClient } from '@app/common/models/sortimente/SortimentDtoClient';
import { TypDtoClient } from '@app/common/models/sortimente/SortimentstypenDtoClient';
import { VerlagDto } from '@app/common/models/verlag.model';
import { EinkaufDtoClient } from '@app/common/models/novi/einkauf.dto.client';
import { MandantDtoClient } from '@app/common/models/mandant/mandant.dto.client';
import { LieferantDtoClient } from '@app/common/models/lieferant/lieferant.dto.client';
import { ISettingChildrenValue, ISettingValue } from '@app/common/models/setting.models';
import { BranchDTO, BranchGroupDTO } from '@app/api/commonWebAPI/models';

export class KeywordError
{
  //dummyid aus webapi
  id: number;
  params: any;
  simpleresult: SimpleResultError;
}


export enum KeyordEbene {
  Klammer, Sortiment, Stichwort,
  All
}

export class CommonDataError
{
  type: CommonDataActionType;
  error: SimpleResultError;
}


export interface CommonDataState
{

    bedarfe:  { [key: number] : BedarfDto[] } ,

    commondataError:  CommonDataError,
    isSaving: boolean,
    idNameList: { [key: string] : LookupValue[] }

    bubDataList: IBubData[],
    failedbubDataList: string[],
    isLoadingBubData: boolean,

    /**Real DTOS */
    allRegionen: RegionDto[],
    allSaisons: SaisonDto[],
    allEreignisse : MarketingEreignissDto[],
    allEinkaeufe : EinkaufDtoClient[],
    allSortimente:  SortimentDtoClient[],
    allKlammern : KlammerDtoClient[],
    allsortimentsTypen: TypDtoClient[],
    allKategorien: KeyValueDto[],
    allWarenGroup: WarengroupDto[],
    allBranches: BranchDTO[];
    allBranchGroups: BranchGroupDTO[];
    lipoContacts: LiPoContact[],
    aktuellerEinkauf: EinkaufDto;
    aktuellerEinkaufisLoading: boolean;
    zukuenftigeEinkaufisLoading: boolean;
    zukuenftigeEinkaeufe: EinkaufDtoClient[];

    stichwortstructureListAll:  KlammerSortimentStichwort[] ,
    stichwortstructureList: { [key: number] : KlammerSortimentStichwort[] },
    stichwortstructureisLoaded: { [key: number] : KeyordEbene },
    keywordErrors: { [key: number] : KeywordError } ,
    searchedVerlage: VerlagDto[],
    isLoadingVerlage: boolean,

    selectedColumnConfigs: Array<CustomSetting<StoreColumnSetting>>;

    usersColors: ColorUserModel[];
    preparedusersColors : ColorUserModelHist[]; //vormerken zum update
    distinctusersColors: string[];
    isLoading: boolean;

    configSuccess: DataColumnConfigSuccess;
    compareTitles: { [key: number] : CompareTitleFis[] }  ,

    listendtos: { [key: number] : ListeDto[] } ,
    katgegorienausVorabplatzierungen: { [key: number] : KeyValueDto[] },



    novitaetenHits: {[key: string]: number};
    dashboardSum : {[key: string]: number};
    mandanten: MandantDtoClient[];
    isLoadingMandanten: boolean;
    mandant: MandantDtoClient;
    lieferanten: LieferantDtoClient[];
    lieferant: LieferantDtoClient;
    isLoadinglieferanten: boolean;
    allLager: LagerDto[];

    verlagewithChildren:  { [key: number] : VerlagDto[] } ,
    settingValues: ISettingValue[],
    settingChildrenValues: { [key: number]: ISettingChildrenValue[] }
    moebel: { [key: number]: MoebelDto[] },
    isMoebelLoading: boolean,



}

export interface AppState extends RootState
{
    commonDataState: CommonDataState;
}

export const InitialCommonDataState = {

    commondataError: <CommonDataError>{},
    bedarfe: {},
    isSaving: false,
    idNameList: {},
    allRegionen: [],
    allSaisons: [],
    allEinkaeufe: [],
    allEreignisse: [],
    lipoContacts: [],
    allSortimente: [],
    allKlammern:[],
    allsortimentsTypen: [],
    allKategorien:[],
    allWarenGroup: [],
    allBranches: [],
    allBranchGroups: [],
    searchedVerlage: [],
    allNovitaeten:[],
    stichwortstructureList: {},
    stichwortstructureListAll: [],
    keywordErrors: {},
    bubDataList: [],
    failedbubDataList: [],
    aktuellerEinkauf:<EinkaufDto>{},
    aktuellerEinkaufisLoading: false,
    zukuenftigeEinkaufisLoading: false,
    zukuenftigeEinkaeufe: [],
    stichwortstructureisLoaded: {},
    settingValues:[],
    selectedColumnConfigs: [],
    selectednovilist: [],
    isLoadingBubData: false,

    usersColors: [],
    preparedusersColors: [],
    distinctusersColors: [],
    isLoading: false,
    configSuccess: null,
    compareTitles: {},
    listendtos: {},
    isLoadingVerlage: false,
    katgegorienausVorabplatzierungen: {},
    SomaDiagramm: [],
    novitaetenHits: {},
    dashboardSum: {},
    mandanten: [],
    mandant: undefined,
    allLager: [],
    lieferanten: [],
    lieferant: null,
    isLoadinglieferanten: false,
    isLoadingMandanten: false,
    verlagewithChildren: {},
    settingChildrenValues: {},
    moebel: {},
    isMoebelLoading: false


}

export const featureName = 'commondataState';

export const selectCommonDataProcessState = createFeatureSelector<CommonDataState>(featureName);
