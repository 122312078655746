import { TaskUserModel, ResultObjectModel, ValidationList } from '@app/common/models';

export enum TaskEditorActionType {
    GetValidations = '[TASK-EDITOR] GetValidations',
    GetValidationsDone = '[TASK-EDITOR] GetValidationsDone',
    GetBreadcrumb = '[TASK-EDITOR] GetBreadcrump',
    GetBreadcrumbDone =  '[TASK-EDITOR] GetBreadcrumpDone',
    GetAttributes = '[TASK-EDITOR] GetAttributes',
    GetAttributesDone = '[TASK-EDITOR] GetAttributesDone',
    ClearStore = '[TASK-EDITOR] ClearStore',
    ResetStore = '[TASK-EDITOR] ResetStore',
    CreateMasterTask = '[TASK-EDITOR] CreateTask',
    CreateMasterTaskDone = '[TASK-EDITOR] CreateTaskDone',
    GetCurrentUser = '[TASK-EDITOR] GetCurrentUser',
    GetCurrentUserDone = '[TASK-EDITOR] GetCurrentUserDone',
    GetUsers = '[TASK-EDITOR] GetUsers',
    GetUsersDone = '[TASK-EDITOR] GetUsersDone',
    SetUserData = '[TASK-EDITOR] SetUserData',
    ProgressTask = '[TASK-EDITOR] ProgressTask',
    ProgressTaskDone = '[TASK-EDITOR] ProgressTaskDone',
    SkipTask = '[TASK-EDITOR] SkipTask',
    SkipTaskDone = '[TASK-EDITOR] SkipTaskDone',
    LoadAttributeInfos = '[TASK-EDITOR] LoadAttributeInfos',
    LoadAttributeInfosDone = '[TASK-EDITOR] LoadAttributeInfosDone',
    LoadTypeInfos = '[TASK-EDITOR] LoadTypeInfos',
    LoadTypeInfosDone = '[TASK-EDITOR] LoadTypeInfosDone',
    ResetTasksDone = '[TASK-EDITOR] ResetTasksDone'
}



export class GetValidationsAction {
    readonly type = TaskEditorActionType.GetValidations;
}

export class GetValidationsActionDone {
    readonly type = TaskEditorActionType.GetValidationsDone;
    constructor(public payload: { items: ValidationList[]}) {}
}

export class GetBreadcrumbAction {
    readonly type = TaskEditorActionType.GetBreadcrumb;
    constructor(public options: { id: number}) {}
}

export class GetBreadcrumbActionDone {
    readonly type = TaskEditorActionType.GetBreadcrumbDone;
    constructor(public payload: { item: string}) {}
}



export class ResetStoreAction {
    readonly type = TaskEditorActionType.ResetStore;
}

export class ClearStoreAction {
    readonly type = TaskEditorActionType.ClearStore;
}

export class CreateMasterTaskAction {
    readonly type = TaskEditorActionType.CreateMasterTask;
    constructor(public payload: { item: TaskUserModel }) {}
}

export class CreateMasterTaskDoneAction {
    readonly type = TaskEditorActionType.CreateMasterTaskDone;
    constructor(public payload: { item: ResultObjectModel<TaskUserModel> }) {}
}

export class ResetTasksDoneAction {
    readonly type = TaskEditorActionType.ResetTasksDone;
}

export class GetUsersAction {
    readonly type = TaskEditorActionType.GetUsers;
    constructor() {}
}

export class GetUsersDoneAction {
    readonly type = TaskEditorActionType.GetUsersDone;
    constructor(public payload: { users: TaskUserModel[] }) {}

}

export class GetCurrentUserAction {
    readonly type = TaskEditorActionType.GetCurrentUser;
}

export class GetCurrentUserDoneAction {
    readonly type = TaskEditorActionType.GetCurrentUserDone;
    constructor(public payload: { user: TaskUserModel }) {}
}

export class SetUserDataAction {
    readonly type = TaskEditorActionType.SetUserData;
    constructor(public payload: { user: TaskUserModel }) {}
}

export class ProgressTaskAction {
    readonly type = TaskEditorActionType.ProgressTask;
    constructor(public options: { taskId: number}) {}
}

export class ProgressTaskDoneAction {
    readonly type = TaskEditorActionType.ProgressTaskDone;
    constructor(public payload: { item: ResultObjectModel<any> }) {}
}

export class SkipTaskAction {
    readonly type = TaskEditorActionType.SkipTask;
    constructor(public options: { taskId: number}) {}
}

export class SkipTaskDoneAction {
    readonly type = TaskEditorActionType.SkipTaskDone;
    constructor(public payload: { item: ResultObjectModel<any> }) {}
}

export type TaskEditorActions = GetValidationsAction
                                | GetValidationsActionDone
                                | GetBreadcrumbAction
                                | GetBreadcrumbActionDone

                                | ResetStoreAction
                                | GetUsersAction
                                | GetUsersDoneAction
                                | GetCurrentUserAction
                                | GetCurrentUserDoneAction
                                | CreateMasterTaskAction
                                | CreateMasterTaskDoneAction
                                | ClearStoreAction
                                | SkipTaskAction
                                | SkipTaskDoneAction
                                | ProgressTaskAction
                                | ProgressTaskDoneAction

                                | SetUserDataAction

                                | ResetTasksDoneAction;
