/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfApplicationDTO } from '../models/list-response-args-of-application-dto';
import { QueryTokenDTO } from '../models/query-token-dto';
import { ResponseArgsOfApplicationDTO } from '../models/response-args-of-application-dto';
import { ApplicationDTO } from '../models/application-dto';
@Injectable({
  providedIn: 'root',
})
class ApplicationService extends __BaseService {
  static readonly ApplicationQueryApplicationPath = '/application/s';
  static readonly ApplicationGetApplicationPath = '/application/{applicationId}';
  static readonly ApplicationUpdateApplicationPath = '/application/{applicationId}';
  static readonly ApplicationCreateApplicationPath = '/application';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Applikation(en) suchen
   * @param params The `ApplicationService.ApplicationQueryApplicationParams` containing the following parameters:
   *
   * - `queryToken`: Suchparameter
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  ApplicationQueryApplicationResponse(params: ApplicationService.ApplicationQueryApplicationParams): __Observable<__StrictHttpResponse<ListResponseArgsOfApplicationDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/application/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfApplicationDTO>;
      })
    );
  }
  /**
   * Applikation(en) suchen
   * @param params The `ApplicationService.ApplicationQueryApplicationParams` containing the following parameters:
   *
   * - `queryToken`: Suchparameter
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  ApplicationQueryApplication(params: ApplicationService.ApplicationQueryApplicationParams): __Observable<ListResponseArgsOfApplicationDTO> {
    return this.ApplicationQueryApplicationResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfApplicationDTO)
    );
  }

  /**
   * Applikation by Id
   * @param params The `ApplicationService.ApplicationGetApplicationParams` containing the following parameters:
   *
   * - `applicationId`: Application PK
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  ApplicationGetApplicationResponse(params: ApplicationService.ApplicationGetApplicationParams): __Observable<__StrictHttpResponse<ResponseArgsOfApplicationDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/application/${encodeURIComponent(String(params.applicationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfApplicationDTO>;
      })
    );
  }
  /**
   * Applikation by Id
   * @param params The `ApplicationService.ApplicationGetApplicationParams` containing the following parameters:
   *
   * - `applicationId`: Application PK
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  ApplicationGetApplication(params: ApplicationService.ApplicationGetApplicationParams): __Observable<ResponseArgsOfApplicationDTO> {
    return this.ApplicationGetApplicationResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfApplicationDTO)
    );
  }

  /**
   * Applikation aktualisieren
   * @param params The `ApplicationService.ApplicationUpdateApplicationParams` containing the following parameters:
   *
   * - `payload`: Daten
   *
   * - `applicationId`: Applikation PK
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  ApplicationUpdateApplicationResponse(params: ApplicationService.ApplicationUpdateApplicationParams): __Observable<__StrictHttpResponse<ResponseArgsOfApplicationDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/application/${encodeURIComponent(String(params.applicationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfApplicationDTO>;
      })
    );
  }
  /**
   * Applikation aktualisieren
   * @param params The `ApplicationService.ApplicationUpdateApplicationParams` containing the following parameters:
   *
   * - `payload`: Daten
   *
   * - `applicationId`: Applikation PK
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  ApplicationUpdateApplication(params: ApplicationService.ApplicationUpdateApplicationParams): __Observable<ResponseArgsOfApplicationDTO> {
    return this.ApplicationUpdateApplicationResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfApplicationDTO)
    );
  }

  /**
   * Applikation anlegen
   * @param params The `ApplicationService.ApplicationCreateApplicationParams` containing the following parameters:
   *
   * - `payload`: Daten
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  ApplicationCreateApplicationResponse(params: ApplicationService.ApplicationCreateApplicationParams): __Observable<__StrictHttpResponse<ResponseArgsOfApplicationDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/application`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfApplicationDTO>;
      })
    );
  }
  /**
   * Applikation anlegen
   * @param params The `ApplicationService.ApplicationCreateApplicationParams` containing the following parameters:
   *
   * - `payload`: Daten
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`: Objekt-Tiefe (optional, default: 1)
   */
  ApplicationCreateApplication(params: ApplicationService.ApplicationCreateApplicationParams): __Observable<ResponseArgsOfApplicationDTO> {
    return this.ApplicationCreateApplicationResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfApplicationDTO)
    );
  }
}

module ApplicationService {

  /**
   * Parameters for ApplicationQueryApplication
   */
  export interface ApplicationQueryApplicationParams {

    /**
     * Suchparameter
     */
    queryToken: QueryTokenDTO;

    /**
     * Lokalisierung
     */
    locale?: null | string;

    /**
     * Objekt-Tiefe (optional, default: 1)
     */
    eagerLoading?: number;
  }

  /**
   * Parameters for ApplicationGetApplication
   */
  export interface ApplicationGetApplicationParams {

    /**
     * Application PK
     */
    applicationId: number;

    /**
     * Lokalisierung
     */
    locale?: null | string;

    /**
     * Objekt-Tiefe (optional, default: 1)
     */
    eagerLoading?: number;
  }

  /**
   * Parameters for ApplicationUpdateApplication
   */
  export interface ApplicationUpdateApplicationParams {

    /**
     * Daten
     */
    payload: ApplicationDTO;

    /**
     * Applikation PK
     */
    applicationId: number;

    /**
     * Lokalisierung
     */
    locale?: null | string;

    /**
     * Objekt-Tiefe (optional, default: 1)
     */
    eagerLoading?: number;
  }

  /**
   * Parameters for ApplicationCreateApplication
   */
  export interface ApplicationCreateApplicationParams {

    /**
     * Daten
     */
    payload: ApplicationDTO;

    /**
     * Lokalisierung
     */
    locale?: null | string;

    /**
     * Objekt-Tiefe (optional, default: 1)
     */
    eagerLoading?: number;
  }
}

export { ApplicationService }
