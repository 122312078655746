import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { AuthService } from '@app/common/services/auth.service';

@Injectable()
export class NgSwaggerGenInterceptor implements HttpInterceptor {

    private CommonWebApiUrl = `${environment.connections.CommonWebApi}`;
    private authService: AuthService;
    constructor(
        private injector: Injector
      ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(req.url == this.CommonWebApiUrl) {
            req = req.clone();

            //     headers: new HttpHeaders({
            //     'Access-Control-Allow-Origin': '*',
            //     Authorization: 'Bearer ' + this.authService.getAccessToken()
            //     })

//          req.headers.set('TOKEN', 'yxc');

            this.authService = this.injector.get(AuthService);
            req.headers.set('Access-Control-Allow-Origin', '*');
            req.headers.set('Authorization', 'Bearer ' + this.authService.getAccessToken());

        }

        return next.handle(req);
    }
}
