import { NgModule } from '@angular/core';
import { NotificationsComponent } from './notifications.component';
import { NotificationsStateModule } from './notifications-state.module';
import { NotificationComponent} from './notification/notification.component';
import { CommonModule } from '@angular/common';
import { SignalMessageService } from '@app/common/services/signalmessage.service';
import { PanelModule } from 'primeng/panel';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MessageEditDialogComponent } from './dialogs/message-edit-dialog/message-edit-dialog.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NotificationsStateModule,
         PanelModule, MatIconModule, MatBadgeModule, MatButtonModule
    ],
    exports: [NotificationsComponent],
    declarations: [NotificationsComponent, NotificationComponent, MessageEditDialogComponent],
    providers:[SignalMessageService]
})
export class NotificationsModule { }
