/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfIEnumerableOfFilialmatrixDTO } from '../models/response-args-of-ienumerable-of-filialmatrix-dto';
import { ResponseArgsOfFilialmatrixDTO } from '../models/response-args-of-filialmatrix-dto';
import { FilialmatrixDTO } from '../models/filialmatrix-dto';
import { ListResponseArgsOfFilialmatrixItemDTO } from '../models/list-response-args-of-filialmatrix-item-dto';
import { QueryTokenDTO } from '../models/query-token-dto';
import { ListResponseArgsOfObject } from '../models/list-response-args-of-object';
import { ResponseArgsOfIEnumerableOfFilialmatrixItemDTO } from '../models/response-args-of-ienumerable-of-filialmatrix-item-dto';
import { FilialmatrixItemDTO } from '../models/filialmatrix-item-dto';
import { ResponseArgsOfFilialmatrixItemDTO } from '../models/response-args-of-filialmatrix-item-dto';
import { ResponseArgsOfIEnumerableOfKeyValueDTOOfIntegerAndInteger } from '../models/response-args-of-ienumerable-of-key-value-dtoof-integer-and-integer';
import { ResponseArgsOfIEnumerableOfKeyValueDTOOfFilialmatrixLevelAndFilialmatrixLevel } from '../models/response-args-of-ienumerable-of-key-value-dtoof-filialmatrix-level-and-filialmatrix-level';
import { ResponseArgsOfIEnumerableOfBranchDTO } from '../models/response-args-of-ienumerable-of-branch-dto';
import { ResponseArgsOfIEnumerableOfAssortmentReferenceDTO } from '../models/response-args-of-ienumerable-of-assortment-reference-dto';
import { ResponseArgsOfIEnumerableOfAssortmentMasterReferenceDTO } from '../models/response-args-of-ienumerable-of-assortment-master-reference-dto';
import { ResponseArgsOfIEnumerableOfPlacementTypeReferenceDTO } from '../models/response-args-of-ienumerable-of-placement-type-reference-dto';
@Injectable({
  providedIn: 'root',
})
class FilialmatrixService extends __BaseService {
  static readonly FilialmatrixListFilialmatricesPath = '/filialmatrix';
  static readonly FilialmatrixCreateFilialmatrixPath = '/filialmatrix';
  static readonly FilialmatrixUpdateFilialmatrixPath = '/filialmatrix/{filialmatrixId}';
  static readonly FilialmatrixQueryFilialmatrixItemsViewPath = '/filialmatrix/{filialmatrixId}/item/s';
  static readonly FilialmatrixQueryFilialmatrixItemsPath = '/filialmatrix/{filialmatrixId}/item/sm';
  static readonly FilialmatrixQueryFilialmatrixItemsDynamicResultXlxsPath = '/filialmatrix/{filialmatrixId}/item/d/xlxs';
  static readonly FilialmatrixQueryFilialmatrixItemsSumPath = '/filialmatrix/{filialmatrixId}/item/sum';
  static readonly FilialmatrixSaveFilialmatrixItemsPath = '/filialmatrix/{filialmatrixId}/item';
  static readonly FilialmatrixDeleteFilialmatrixItemPath = '/filialmatrix/{filialmatrixId}/item/{filialmatrixItemId}';
  static readonly FilialmatrixListModulesPath = '/filialmatrix/{filialmatrixId}/module';
  static readonly FilialmatrixListLevelsPath = '/filialmatrix/{filialmatrixId}/level';
  static readonly FilialmatrixListBranchesPath = '/filialmatrix/{filialmatrixId}/branch';
  static readonly FilialmatrixListBranches2Path = '/filialmatrix/branch';
  static readonly FilialmatrixListAssortmentsPath = '/filialmatrix/{filialmatrixId}/assortment';
  static readonly FilialmatrixListAssortmentMastersPath = '/filialmatrix/{filialmatrixId}/assortmentmaster';
  static readonly FilialmatrixListPlacementTypesPath = '/filialmatrix/{filialmatrixId}/placementtype';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Filialmatrix auflisten
   * @param locale Lokalisierung
   */
  FilialmatrixListFilialmatricesResponse(locale?: null | string): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfFilialmatrixDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (locale != null) __params = __params.set('locale', locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/filialmatrix`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfFilialmatrixDTO>;
      })
    );
  }
  /**
   * Filialmatrix auflisten
   * @param locale Lokalisierung
   */
  FilialmatrixListFilialmatrices(locale?: null | string): __Observable<ResponseArgsOfIEnumerableOfFilialmatrixDTO> {
    return this.FilialmatrixListFilialmatricesResponse(locale).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfFilialmatrixDTO)
    );
  }

  /**
   * Filialmatrix anlegen
   * @param params The `FilialmatrixService.FilialmatrixCreateFilialmatrixParams` containing the following parameters:
   *
   * - `payload`: Daten
   *
   * - `user`: Benutzer Id
   *
   * - `locale`: Lokalisiserung (optional)
   *
   * - `eagerLoading`:
   */
  FilialmatrixCreateFilialmatrixResponse(params: FilialmatrixService.FilialmatrixCreateFilialmatrixParams): __Observable<__StrictHttpResponse<ResponseArgsOfFilialmatrixDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.user != null) __params = __params.set('user', params.user.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/filialmatrix`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfFilialmatrixDTO>;
      })
    );
  }
  /**
   * Filialmatrix anlegen
   * @param params The `FilialmatrixService.FilialmatrixCreateFilialmatrixParams` containing the following parameters:
   *
   * - `payload`: Daten
   *
   * - `user`: Benutzer Id
   *
   * - `locale`: Lokalisiserung (optional)
   *
   * - `eagerLoading`:
   */
  FilialmatrixCreateFilialmatrix(params: FilialmatrixService.FilialmatrixCreateFilialmatrixParams): __Observable<ResponseArgsOfFilialmatrixDTO> {
    return this.FilialmatrixCreateFilialmatrixResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfFilialmatrixDTO)
    );
  }

  /**
   * Filialmatrix speichern
   * @param params The `FilialmatrixService.FilialmatrixUpdateFilialmatrixParams` containing the following parameters:
   *
   * - `payload`: Daten
   *
   * - `filialmatrixId`: Filialmatrix PK
   *
   * - `user`: Benutzer Id
   *
   * - `locale`: Lokalisiserung (optional)
   *
   * - `eagerLoading`:
   */
  FilialmatrixUpdateFilialmatrixResponse(params: FilialmatrixService.FilialmatrixUpdateFilialmatrixParams): __Observable<__StrictHttpResponse<ResponseArgsOfFilialmatrixDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/filialmatrix/${encodeURIComponent(String(params.filialmatrixId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfFilialmatrixDTO>;
      })
    );
  }
  /**
   * Filialmatrix speichern
   * @param params The `FilialmatrixService.FilialmatrixUpdateFilialmatrixParams` containing the following parameters:
   *
   * - `payload`: Daten
   *
   * - `filialmatrixId`: Filialmatrix PK
   *
   * - `user`: Benutzer Id
   *
   * - `locale`: Lokalisiserung (optional)
   *
   * - `eagerLoading`:
   */
  FilialmatrixUpdateFilialmatrix(params: FilialmatrixService.FilialmatrixUpdateFilialmatrixParams): __Observable<ResponseArgsOfFilialmatrixDTO> {
    return this.FilialmatrixUpdateFilialmatrixResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfFilialmatrixDTO)
    );
  }

  /**
   * Filialmatrix-Einträge auflisten / durchsuchen
   * @param params The `FilialmatrixService.FilialmatrixQueryFilialmatrixItemsViewParams` containing the following parameters:
   *
   * - `queryToken`: Abfrageparameter
   *
   * - `filialmatrixId`: Filialmatrix PK
   *
   * - `onePerAssignment`: False: Alle per Sortimentszuordnung verknüpfte Sortimente sind einzeln in der Ergebnisliste enthalten.
   *   True: Je Sortimentszordnung nur ein Eintrag in der Ergebnisliste.
   *
   * - `locale`: Lokalisiserung (optional)
   *
   * - `eagerLoading`:
   */
  FilialmatrixQueryFilialmatrixItemsViewResponse(params: FilialmatrixService.FilialmatrixQueryFilialmatrixItemsViewParams): __Observable<__StrictHttpResponse<ListResponseArgsOfFilialmatrixItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;

    if (params.onePerAssignment != null) __params = __params.set('onePerAssignment', params.onePerAssignment.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/filialmatrix/${encodeURIComponent(String(params.filialmatrixId))}/item/s`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfFilialmatrixItemDTO>;
      })
    );
  }
  /**
   * Filialmatrix-Einträge auflisten / durchsuchen
   * @param params The `FilialmatrixService.FilialmatrixQueryFilialmatrixItemsViewParams` containing the following parameters:
   *
   * - `queryToken`: Abfrageparameter
   *
   * - `filialmatrixId`: Filialmatrix PK
   *
   * - `onePerAssignment`: False: Alle per Sortimentszuordnung verknüpfte Sortimente sind einzeln in der Ergebnisliste enthalten.
   *   True: Je Sortimentszordnung nur ein Eintrag in der Ergebnisliste.
   *
   * - `locale`: Lokalisiserung (optional)
   *
   * - `eagerLoading`:
   */
  FilialmatrixQueryFilialmatrixItemsView(params: FilialmatrixService.FilialmatrixQueryFilialmatrixItemsViewParams): __Observable<ListResponseArgsOfFilialmatrixItemDTO> {
    return this.FilialmatrixQueryFilialmatrixItemsViewResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfFilialmatrixItemDTO)
    );
  }

  /**
   * Filialmatrix-Einträge auflisten / durchsuchen
   * @param params The `FilialmatrixService.FilialmatrixQueryFilialmatrixItemsParams` containing the following parameters:
   *
   * - `queryToken`: Abfrageparameter
   *
   * - `filialmatrixId`: Filialmatrix PK
   *
   * - `onePerAssignment`: False: Alle per Sortimentszuordnung verknüpfte Sortimente sind einzeln in der Ergebnisliste enthalten.
   *   True: Je Sortimentszordnung nur ein Eintrag in der Ergebnisliste.
   *
   * - `locale`: Lokalisiserung (optional)
   *
   * - `eagerLoading`:
   */
  FilialmatrixQueryFilialmatrixItemsResponse(params: FilialmatrixService.FilialmatrixQueryFilialmatrixItemsParams): __Observable<__StrictHttpResponse<ListResponseArgsOfFilialmatrixItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;

    if (params.onePerAssignment != null) __params = __params.set('onePerAssignment', params.onePerAssignment.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/filialmatrix/${encodeURIComponent(String(params.filialmatrixId))}/item/sm`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfFilialmatrixItemDTO>;
      })
    );
  }
  /**
   * Filialmatrix-Einträge auflisten / durchsuchen
   * @param params The `FilialmatrixService.FilialmatrixQueryFilialmatrixItemsParams` containing the following parameters:
   *
   * - `queryToken`: Abfrageparameter
   *
   * - `filialmatrixId`: Filialmatrix PK
   *
   * - `onePerAssignment`: False: Alle per Sortimentszuordnung verknüpfte Sortimente sind einzeln in der Ergebnisliste enthalten.
   *   True: Je Sortimentszordnung nur ein Eintrag in der Ergebnisliste.
   *
   * - `locale`: Lokalisiserung (optional)
   *
   * - `eagerLoading`:
   */
  FilialmatrixQueryFilialmatrixItems(params: FilialmatrixService.FilialmatrixQueryFilialmatrixItemsParams): __Observable<ListResponseArgsOfFilialmatrixItemDTO> {
    return this.FilialmatrixQueryFilialmatrixItemsResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfFilialmatrixItemDTO)
    );
  }

  /**
   * Filialmatrix-Einträge auflisten / durchsuchen
   * @param params The `FilialmatrixService.FilialmatrixQueryFilialmatrixItemsDynamicResultXlxsParams` containing the following parameters:
   *
   * - `queryToken`: Abfrageparameter
   *
   * - `filialmatrixId`: Filialmatrix PK
   *
   * - `onePerAssignment`: False: Alle per Sortimentszuordnung verknüpfte Sortimente sind einzeln in der Ergebnisliste enthalten.
   *   True: Je Sortimentszordnung nur ein Eintrag in der Ergebnisliste.
   *
   * - `locale`: Lokalisiserung (optional)
   *
   * - `eagerLoading`:
   */
  FilialmatrixQueryFilialmatrixItemsDynamicResultXlxsResponse(params: FilialmatrixService.FilialmatrixQueryFilialmatrixItemsDynamicResultXlxsParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;

    if (params.onePerAssignment != null) __params = __params.set('onePerAssignment', params.onePerAssignment.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/filialmatrix/${encodeURIComponent(String(params.filialmatrixId))}/item/d/xlxs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Filialmatrix-Einträge auflisten / durchsuchen
   * @param params The `FilialmatrixService.FilialmatrixQueryFilialmatrixItemsDynamicResultXlxsParams` containing the following parameters:
   *
   * - `queryToken`: Abfrageparameter
   *
   * - `filialmatrixId`: Filialmatrix PK
   *
   * - `onePerAssignment`: False: Alle per Sortimentszuordnung verknüpfte Sortimente sind einzeln in der Ergebnisliste enthalten.
   *   True: Je Sortimentszordnung nur ein Eintrag in der Ergebnisliste.
   *
   * - `locale`: Lokalisiserung (optional)
   *
   * - `eagerLoading`:
   */
  FilialmatrixQueryFilialmatrixItemsDynamicResultXlxs(params: FilialmatrixService.FilialmatrixQueryFilialmatrixItemsDynamicResultXlxsParams): __Observable<Blob> {
    return this.FilialmatrixQueryFilialmatrixItemsDynamicResultXlxsResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Filialmatrix-Einträge Summen Sortiment, Modul
   * @param params The `FilialmatrixService.FilialmatrixQueryFilialmatrixItemsSumParams` containing the following parameters:
   *
   * - `queryToken`: Abfrageparameter
   *
   * - `filialmatrixId`: Filialmatrix PK
   *
   * - `onePerAssignment`: False: Alle per Sortimentszuordnung verknüpfte Sortimente sind einzeln in der Ergebnisliste enthalten.
   *   True: Je Sortimentszordnung nur ein Eintrag in der Ergebnisliste.
   *
   * - `locale`: Lokalisiserung (optional)
   *
   * - `eagerLoading`:
   */
  FilialmatrixQueryFilialmatrixItemsSumResponse(params: FilialmatrixService.FilialmatrixQueryFilialmatrixItemsSumParams): __Observable<__StrictHttpResponse<ListResponseArgsOfObject>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;

    if (params.onePerAssignment != null) __params = __params.set('onePerAssignment', params.onePerAssignment.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/filialmatrix/${encodeURIComponent(String(params.filialmatrixId))}/item/sum`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfObject>;
      })
    );
  }
  /**
   * Filialmatrix-Einträge Summen Sortiment, Modul
   * @param params The `FilialmatrixService.FilialmatrixQueryFilialmatrixItemsSumParams` containing the following parameters:
   *
   * - `queryToken`: Abfrageparameter
   *
   * - `filialmatrixId`: Filialmatrix PK
   *
   * - `onePerAssignment`: False: Alle per Sortimentszuordnung verknüpfte Sortimente sind einzeln in der Ergebnisliste enthalten.
   *   True: Je Sortimentszordnung nur ein Eintrag in der Ergebnisliste.
   *
   * - `locale`: Lokalisiserung (optional)
   *
   * - `eagerLoading`:
   */
  FilialmatrixQueryFilialmatrixItemsSum(params: FilialmatrixService.FilialmatrixQueryFilialmatrixItemsSumParams): __Observable<ListResponseArgsOfObject> {
    return this.FilialmatrixQueryFilialmatrixItemsSumResponse(params).pipe(
      __map(_r => _r.body as ListResponseArgsOfObject)
    );
  }

  /**
   * Filialmatrix-Einträge anlegen / speichern
   * @param params The `FilialmatrixService.FilialmatrixSaveFilialmatrixItemsParams` containing the following parameters:
   *
   * - `payload`: Daten
   *
   * - `filialmatrixId`: Filialmatrix PK
   *
   * - `user`: Benutzer Id
   *
   * - `locale`: Lokalisiserung (optional)
   *
   * - `eagerLoading`:
   */
  FilialmatrixSaveFilialmatrixItemsResponse(params: FilialmatrixService.FilialmatrixSaveFilialmatrixItemsParams): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfFilialmatrixItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.user != null) __params = __params.set('user', params.user.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/filialmatrix/${encodeURIComponent(String(params.filialmatrixId))}/item`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfFilialmatrixItemDTO>;
      })
    );
  }
  /**
   * Filialmatrix-Einträge anlegen / speichern
   * @param params The `FilialmatrixService.FilialmatrixSaveFilialmatrixItemsParams` containing the following parameters:
   *
   * - `payload`: Daten
   *
   * - `filialmatrixId`: Filialmatrix PK
   *
   * - `user`: Benutzer Id
   *
   * - `locale`: Lokalisiserung (optional)
   *
   * - `eagerLoading`:
   */
  FilialmatrixSaveFilialmatrixItems(params: FilialmatrixService.FilialmatrixSaveFilialmatrixItemsParams): __Observable<ResponseArgsOfIEnumerableOfFilialmatrixItemDTO> {
    return this.FilialmatrixSaveFilialmatrixItemsResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfFilialmatrixItemDTO)
    );
  }

  /**
   * Filialmatrix-Eintrag löschen
   * @param params The `FilialmatrixService.FilialmatrixDeleteFilialmatrixItemParams` containing the following parameters:
   *
   * - `filialmatrixItemId`: Filialmatrix Eintrag PK
   *
   * - `filialmatrixId`: Filialmatrix PK
   *
   * - `user`: Benutzer Id
   *
   * - `locale`: Lokalisiserung (optional)
   */
  FilialmatrixDeleteFilialmatrixItemResponse(params: FilialmatrixService.FilialmatrixDeleteFilialmatrixItemParams): __Observable<__StrictHttpResponse<ResponseArgsOfFilialmatrixItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.user != null) __params = __params.set('user', params.user.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/filialmatrix/${encodeURIComponent(String(params.filialmatrixId))}/item/${encodeURIComponent(String(params.filialmatrixItemId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfFilialmatrixItemDTO>;
      })
    );
  }
  /**
   * Filialmatrix-Eintrag löschen
   * @param params The `FilialmatrixService.FilialmatrixDeleteFilialmatrixItemParams` containing the following parameters:
   *
   * - `filialmatrixItemId`: Filialmatrix Eintrag PK
   *
   * - `filialmatrixId`: Filialmatrix PK
   *
   * - `user`: Benutzer Id
   *
   * - `locale`: Lokalisiserung (optional)
   */
  FilialmatrixDeleteFilialmatrixItem(params: FilialmatrixService.FilialmatrixDeleteFilialmatrixItemParams): __Observable<ResponseArgsOfFilialmatrixItemDTO> {
    return this.FilialmatrixDeleteFilialmatrixItemResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfFilialmatrixItemDTO)
    );
  }

  /**
   * Module
   * @param params The `FilialmatrixService.FilialmatrixListModulesParams` containing the following parameters:
   *
   * - `filialmatrixId`: Filialmatrix PK
   *
   * - `locale`: Lokalisierung (optional)
   */
  FilialmatrixListModulesResponse(params: FilialmatrixService.FilialmatrixListModulesParams): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfKeyValueDTOOfIntegerAndInteger>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/filialmatrix/${encodeURIComponent(String(params.filialmatrixId))}/module`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfKeyValueDTOOfIntegerAndInteger>;
      })
    );
  }
  /**
   * Module
   * @param params The `FilialmatrixService.FilialmatrixListModulesParams` containing the following parameters:
   *
   * - `filialmatrixId`: Filialmatrix PK
   *
   * - `locale`: Lokalisierung (optional)
   */
  FilialmatrixListModules(params: FilialmatrixService.FilialmatrixListModulesParams): __Observable<ResponseArgsOfIEnumerableOfKeyValueDTOOfIntegerAndInteger> {
    return this.FilialmatrixListModulesResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfKeyValueDTOOfIntegerAndInteger)
    );
  }

  /**
   * Ebenen / Dimensionen
   * @param params The `FilialmatrixService.FilialmatrixListLevelsParams` containing the following parameters:
   *
   * - `filialmatrixId`: Filialmatrix PK
   *
   * - `locale`: Lokalisierung (optional)
   */
  FilialmatrixListLevelsResponse(params: FilialmatrixService.FilialmatrixListLevelsParams): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfKeyValueDTOOfFilialmatrixLevelAndFilialmatrixLevel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/filialmatrix/${encodeURIComponent(String(params.filialmatrixId))}/level`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfKeyValueDTOOfFilialmatrixLevelAndFilialmatrixLevel>;
      })
    );
  }
  /**
   * Ebenen / Dimensionen
   * @param params The `FilialmatrixService.FilialmatrixListLevelsParams` containing the following parameters:
   *
   * - `filialmatrixId`: Filialmatrix PK
   *
   * - `locale`: Lokalisierung (optional)
   */
  FilialmatrixListLevels(params: FilialmatrixService.FilialmatrixListLevelsParams): __Observable<ResponseArgsOfIEnumerableOfKeyValueDTOOfFilialmatrixLevelAndFilialmatrixLevel> {
    return this.FilialmatrixListLevelsResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfKeyValueDTOOfFilialmatrixLevelAndFilialmatrixLevel)
    );
  }

  /**
   * Filialen
   * @param params The `FilialmatrixService.FilialmatrixListBranchesParams` containing the following parameters:
   *
   * - `filialmatrixId`: Filialmatrix PK
   *
   * - `locale`: Lokalisierung (optional)
   *
   * - `labelKey`: Label (optional)
   */
  FilialmatrixListBranchesResponse(params: FilialmatrixService.FilialmatrixListBranchesParams): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfBranchDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.labelKey != null) __params = __params.set('labelKey', params.labelKey.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/filialmatrix/${encodeURIComponent(String(params.filialmatrixId))}/branch`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfBranchDTO>;
      })
    );
  }
  /**
   * Filialen
   * @param params The `FilialmatrixService.FilialmatrixListBranchesParams` containing the following parameters:
   *
   * - `filialmatrixId`: Filialmatrix PK
   *
   * - `locale`: Lokalisierung (optional)
   *
   * - `labelKey`: Label (optional)
   */
  FilialmatrixListBranches(params: FilialmatrixService.FilialmatrixListBranchesParams): __Observable<ResponseArgsOfIEnumerableOfBranchDTO> {
    return this.FilialmatrixListBranchesResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfBranchDTO)
    );
  }

  /**
   * Filialen
   * @param params The `FilialmatrixService.FilialmatrixListBranches2Params` containing the following parameters:
   *
   * - `locale`: Lokalisierung (optional)
   *
   * - `labelKey`: Label (optional)
   */
  FilialmatrixListBranches2Response(params: FilialmatrixService.FilialmatrixListBranches2Params): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfBranchDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    (params.labelKey || []).forEach(val => {if (val != null) __params = __params.append('labelKey', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/filialmatrix/branch`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfBranchDTO>;
      })
    );
  }
  /**
   * Filialen
   * @param params The `FilialmatrixService.FilialmatrixListBranches2Params` containing the following parameters:
   *
   * - `locale`: Lokalisierung (optional)
   *
   * - `labelKey`: Label (optional)
   */
  FilialmatrixListBranches2(params: FilialmatrixService.FilialmatrixListBranches2Params): __Observable<ResponseArgsOfIEnumerableOfBranchDTO> {
    return this.FilialmatrixListBranches2Response(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfBranchDTO)
    );
  }

  /**
   * Sortimente
   * @param params The `FilialmatrixService.FilialmatrixListAssortmentsParams` containing the following parameters:
   *
   * - `filialmatrixId`: Filialmatrix PK
   *
   * - `locale`: Lokalisierung (optional)
   */
  FilialmatrixListAssortmentsResponse(params: FilialmatrixService.FilialmatrixListAssortmentsParams): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfAssortmentReferenceDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/filialmatrix/${encodeURIComponent(String(params.filialmatrixId))}/assortment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfAssortmentReferenceDTO>;
      })
    );
  }
  /**
   * Sortimente
   * @param params The `FilialmatrixService.FilialmatrixListAssortmentsParams` containing the following parameters:
   *
   * - `filialmatrixId`: Filialmatrix PK
   *
   * - `locale`: Lokalisierung (optional)
   */
  FilialmatrixListAssortments(params: FilialmatrixService.FilialmatrixListAssortmentsParams): __Observable<ResponseArgsOfIEnumerableOfAssortmentReferenceDTO> {
    return this.FilialmatrixListAssortmentsResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfAssortmentReferenceDTO)
    );
  }

  /**
   * Sortimentsklammern
   * @param params The `FilialmatrixService.FilialmatrixListAssortmentMastersParams` containing the following parameters:
   *
   * - `filialmatrixId`: Filialmatrix PK
   *
   * - `locale`: Lokalisierung (optional)
   */
  FilialmatrixListAssortmentMastersResponse(params: FilialmatrixService.FilialmatrixListAssortmentMastersParams): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfAssortmentMasterReferenceDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/filialmatrix/${encodeURIComponent(String(params.filialmatrixId))}/assortmentmaster`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfAssortmentMasterReferenceDTO>;
      })
    );
  }
  /**
   * Sortimentsklammern
   * @param params The `FilialmatrixService.FilialmatrixListAssortmentMastersParams` containing the following parameters:
   *
   * - `filialmatrixId`: Filialmatrix PK
   *
   * - `locale`: Lokalisierung (optional)
   */
  FilialmatrixListAssortmentMasters(params: FilialmatrixService.FilialmatrixListAssortmentMastersParams): __Observable<ResponseArgsOfIEnumerableOfAssortmentMasterReferenceDTO> {
    return this.FilialmatrixListAssortmentMastersResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfAssortmentMasterReferenceDTO)
    );
  }

  /**
   * Platzierungsarten
   * @param params The `FilialmatrixService.FilialmatrixListPlacementTypesParams` containing the following parameters:
   *
   * - `filialmatrixId`: Filialmatrix PK
   *
   * - `locale`: Lokalisierung (optional)
   */
  FilialmatrixListPlacementTypesResponse(params: FilialmatrixService.FilialmatrixListPlacementTypesParams): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfPlacementTypeReferenceDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/filialmatrix/${encodeURIComponent(String(params.filialmatrixId))}/placementtype`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfPlacementTypeReferenceDTO>;
      })
    );
  }
  /**
   * Platzierungsarten
   * @param params The `FilialmatrixService.FilialmatrixListPlacementTypesParams` containing the following parameters:
   *
   * - `filialmatrixId`: Filialmatrix PK
   *
   * - `locale`: Lokalisierung (optional)
   */
  FilialmatrixListPlacementTypes(params: FilialmatrixService.FilialmatrixListPlacementTypesParams): __Observable<ResponseArgsOfIEnumerableOfPlacementTypeReferenceDTO> {
    return this.FilialmatrixListPlacementTypesResponse(params).pipe(
      __map(_r => _r.body as ResponseArgsOfIEnumerableOfPlacementTypeReferenceDTO)
    );
  }
}

module FilialmatrixService {

  /**
   * Parameters for FilialmatrixCreateFilialmatrix
   */
  export interface FilialmatrixCreateFilialmatrixParams {

    /**
     * Daten
     */
    payload: FilialmatrixDTO;

    /**
     * Benutzer Id
     */
    user?: null | string;

    /**
     * Lokalisiserung (optional)
     */
    locale?: null | string;
    eagerLoading?: number;
  }

  /**
   * Parameters for FilialmatrixUpdateFilialmatrix
   */
  export interface FilialmatrixUpdateFilialmatrixParams {

    /**
     * Daten
     */
    payload: FilialmatrixDTO;

    /**
     * Filialmatrix PK
     */
    filialmatrixId: number;

    /**
     * Benutzer Id
     */
    user?: null | string;

    /**
     * Lokalisiserung (optional)
     */
    locale?: null | string;
    eagerLoading?: number;
  }

  /**
   * Parameters for FilialmatrixQueryFilialmatrixItemsView
   */
  export interface FilialmatrixQueryFilialmatrixItemsViewParams {

    /**
     * Abfrageparameter
     */
    queryToken: QueryTokenDTO;

    /**
     * Filialmatrix PK
     */
    filialmatrixId: number;

    /**
     * False: Alle per Sortimentszuordnung verknüpfte Sortimente sind einzeln in der Ergebnisliste enthalten.
     * True: Je Sortimentszordnung nur ein Eintrag in der Ergebnisliste.
     */
    onePerAssignment?: boolean;

    /**
     * Lokalisiserung (optional)
     */
    locale?: null | string;
    eagerLoading?: number;
  }

  /**
   * Parameters for FilialmatrixQueryFilialmatrixItems
   */
  export interface FilialmatrixQueryFilialmatrixItemsParams {

    /**
     * Abfrageparameter
     */
    queryToken: QueryTokenDTO;

    /**
     * Filialmatrix PK
     */
    filialmatrixId: number;

    /**
     * False: Alle per Sortimentszuordnung verknüpfte Sortimente sind einzeln in der Ergebnisliste enthalten.
     * True: Je Sortimentszordnung nur ein Eintrag in der Ergebnisliste.
     */
    onePerAssignment?: boolean;

    /**
     * Lokalisiserung (optional)
     */
    locale?: null | string;
    eagerLoading?: number;
  }

  /**
   * Parameters for FilialmatrixQueryFilialmatrixItemsDynamicResultXlxs
   */
  export interface FilialmatrixQueryFilialmatrixItemsDynamicResultXlxsParams {

    /**
     * Abfrageparameter
     */
    queryToken: QueryTokenDTO;

    /**
     * Filialmatrix PK
     */
    filialmatrixId: number;

    /**
     * False: Alle per Sortimentszuordnung verknüpfte Sortimente sind einzeln in der Ergebnisliste enthalten.
     * True: Je Sortimentszordnung nur ein Eintrag in der Ergebnisliste.
     */
    onePerAssignment?: boolean;

    /**
     * Lokalisiserung (optional)
     */
    locale?: null | string;
    eagerLoading?: number;
  }

  /**
   * Parameters for FilialmatrixQueryFilialmatrixItemsSum
   */
  export interface FilialmatrixQueryFilialmatrixItemsSumParams {

    /**
     * Abfrageparameter
     */
    queryToken: QueryTokenDTO;

    /**
     * Filialmatrix PK
     */
    filialmatrixId: number;

    /**
     * False: Alle per Sortimentszuordnung verknüpfte Sortimente sind einzeln in der Ergebnisliste enthalten.
     * True: Je Sortimentszordnung nur ein Eintrag in der Ergebnisliste.
     */
    onePerAssignment?: boolean;

    /**
     * Lokalisiserung (optional)
     */
    locale?: null | string;
    eagerLoading?: number;
  }

  /**
   * Parameters for FilialmatrixSaveFilialmatrixItems
   */
  export interface FilialmatrixSaveFilialmatrixItemsParams {

    /**
     * Daten
     */
    payload: Array<FilialmatrixItemDTO>;

    /**
     * Filialmatrix PK
     */
    filialmatrixId: number;

    /**
     * Benutzer Id
     */
    user?: null | string;

    /**
     * Lokalisiserung (optional)
     */
    locale?: null | string;
    eagerLoading?: number;
  }

  /**
   * Parameters for FilialmatrixDeleteFilialmatrixItem
   */
  export interface FilialmatrixDeleteFilialmatrixItemParams {

    /**
     * Filialmatrix Eintrag PK
     */
    filialmatrixItemId: number;

    /**
     * Filialmatrix PK
     */
    filialmatrixId: number;

    /**
     * Benutzer Id
     */
    user?: null | string;

    /**
     * Lokalisiserung (optional)
     */
    locale?: null | string;
  }

  /**
   * Parameters for FilialmatrixListModules
   */
  export interface FilialmatrixListModulesParams {

    /**
     * Filialmatrix PK
     */
    filialmatrixId: number;

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;
  }

  /**
   * Parameters for FilialmatrixListLevels
   */
  export interface FilialmatrixListLevelsParams {

    /**
     * Filialmatrix PK
     */
    filialmatrixId: number;

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;
  }

  /**
   * Parameters for FilialmatrixListBranches
   */
  export interface FilialmatrixListBranchesParams {

    /**
     * Filialmatrix PK
     */
    filialmatrixId: number;

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;

    /**
     * Label (optional)
     */
    labelKey?: null | string;
  }

  /**
   * Parameters for FilialmatrixListBranches2
   */
  export interface FilialmatrixListBranches2Params {

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;

    /**
     * Label (optional)
     */
    labelKey?: null | Array<string>;
  }

  /**
   * Parameters for FilialmatrixListAssortments
   */
  export interface FilialmatrixListAssortmentsParams {

    /**
     * Filialmatrix PK
     */
    filialmatrixId: number;

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;
  }

  /**
   * Parameters for FilialmatrixListAssortmentMasters
   */
  export interface FilialmatrixListAssortmentMastersParams {

    /**
     * Filialmatrix PK
     */
    filialmatrixId: number;

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;
  }

  /**
   * Parameters for FilialmatrixListPlacementTypes
   */
  export interface FilialmatrixListPlacementTypesParams {

    /**
     * Filialmatrix PK
     */
    filialmatrixId: number;

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;
  }
}

export { FilialmatrixService }
