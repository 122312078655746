import { LieferantDto, UserDto } from "@app/api/filialmatrix/models";
import { LieferantTypeEnum } from "@app/common/models";


export  class UploadLieferantParam {

  lieferantType: LieferantTypeEnum;
  lieferantenIds : string[];
}
export class LieferantUserDTO {
  lieferantUserShortId : number;
  lieferantUserName: string;
  lieferantUserEmail : string;
  lieferantUserFirstname : string;
  lieferantUserLastname : string;
  lieferantId : number;
  lieferantName: string;
  lieferantUserId: string;
  lieferantUserFullname: string
  constructor(l: LieferantDto, b: UserDto) {
       this.lieferantUserShortId= b.id;
      this.lieferantUserName= b.friendlyName;
      this.lieferantUserEmail= b.mail;
      this.lieferantId= l.id;
      this.lieferantName= l.name;
      this.lieferantUserFirstname= b.firstName;
      this.lieferantUserLastname= b.lastName;
      this.lieferantUserId = this.getLieferantUserId();
  }

  /**Original Id für Lipo*/
  private getLieferantUserId() : string {
    if (this.lieferantUserShortId == undefined) return null;
    const min = "230000001000000000";
    if (Number(this.lieferantUserShortId) < Number(min))
  {
    var result =   min.substring(0,min.length -this.lieferantUserShortId.toString().length) + this.lieferantUserShortId.toString();
    return result;
  }
    return this.lieferantUserShortId.toString();
}



}
