import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { TaskModel, TaskUserModel } from '@app/common/models';
import { ValidationService } from '@app/common/services/validation.service';
import { Store, select } from '@ngrx/store';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ConfirmationService } from 'primeng/api';
import { Actions } from '@ngrx/effects';
import { TaskService } from '@app/common/services/task.service';
import { SkipTaskAction, GetBreadcrumbAction, selectBreadcrumb } from '@app/+task-editor/store';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { CommonCheckService } from '@app/common/services/common.validation.service';

@Component({
    selector: 'app-task-view',
    templateUrl: 'task-view.component.html'
  })

  export class TaskViewComponent implements OnInit {

    taskToView: TaskUserModel;
    breadcrumb$: any;
    isManager: boolean;

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public store: Store<any>,
        public actions: Actions,
        private confirmationService: ConfirmationService,
        private taskService: TaskService,
        private oauthService: OAuthService,
        private commonCheckService: CommonCheckService,
        private httpClient: HttpClient
      ) { }

    ngOnInit() {
        this.taskToView = this.config.data.taskToView;
        this.isManager = this.config.data.isManager;
        //this.store.dispatch(new GetBreadcrumbAction( { id: this.taskToView.id } ));
        //this.breadcrumb$ = this.store.select(selectBreadcrumb);
    }

    skip(taskId: number) {
        this.confirmationService.confirm({
          message: 'Wollen Sie wirklich keine Liste zu dieser Anforderung bereitstellen?',
          header: 'Achtung - Überspringen?',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
              this.store.dispatch(new SkipTaskAction({taskId}));
          }
      });
      }

    getTaskServiceLocation(): string {
      return `${environment.connections.TaskListService}` + '/api/task/';
    }

    downloadFile(fileUrl: string, mime: string, fileName: string, event: MouseEvent) {
      event.preventDefault();
      fileUrl = `${environment.connections.TaskListService}` + '/api/task/' + fileUrl;
      this.httpClient.get(fileUrl, {responseType: "arraybuffer"}).subscribe(x => {
        const file = new Blob([x], { type: mime })

        const url = window.URL.createObjectURL(file);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";
        a.href = url;
        a.download = fileName;
        a.click();
      });
    }

    downloadListForTask(event: MouseEvent) {
      event.preventDefault();
      const fileUrl = `${environment.connections.ValidationEditService}` + '/Validation/GetValidationList?taskId=' + this.taskToView.id;
      this.httpClient.get(fileUrl, {responseType: "arraybuffer"}).subscribe(x => {
        const file = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

        const url = window.URL.createObjectURL(file);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";
        a.target = "_self";
        a.href = url;
        a.download = "Vorlage_"+  this.commonCheckService.getValidFileName( this.taskToView.selectedValidation) + '.xlsx';
        a.click();
        a.remove();
      });
    }

    close() {
        this.ref.close();
    }

    convertCarriageReturn(value: string): string {
      if (!value || value == null) return null;
      return value.replace(/[\n\r]/g, '<br />');
    }
  }
