import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import {
  GetAllNotificationsAction,
  GetAllNotificationsActionDone,
  DeleteAllMessagesAction,
  DeleteAllMessagesActionDone,
  DeleteSingleMessagesAction,
  DeleteSingleMessagesActionDone,
  NotificationErrorAction,
  NotificationsActionType,
  SendMessagetoGroups,
  SendMessagetoGroupsDone,
} from './actions';
import { map, withLatestFrom, catchError, mergeMap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { selectNotificationsState } from './state';
import { GeneralResultEnum } from '@app/common/models';

import { of } from 'rxjs';
import { SignalMessageService } from '@app/common/services/signalmessage.service';
import { SimpleResult } from '@app/common/models/simpleResult.model';
import { UpdateNotificationStatusAction, UpdateNotificationStatusActionDone } from '.';
import { SignalMessageContentModel } from '@app/common/models/signal.model';

@Injectable()
export class NotificationsEffects {
  private state$ = this.store.pipe(select(selectNotificationsState));

  constructor(
    private actions: Actions,
    private store: Store<any>,
    private signalmessageService: SignalMessageService
  ) { }




  getAllNotificationMessages$ = createEffect(() => { return  this.actions.pipe(
    ofType<GetAllNotificationsAction>(NotificationsActionType.GetAllNotificationsAction),
    withLatestFrom(this.state$),
    mergeMap(([action]) =>
      this.signalmessageService.getAllMessages(action.payload.targetUserId).pipe(
        map(response => {
          if (response.generalResult != GeneralResultEnum.Success) {
            return new NotificationErrorAction({ error: <SimpleResult>response });
          } else {
            return new GetAllNotificationsActionDone({ signals: response.data });
          }
        })
      )
    ), catchError((error, caught) => {
      console.debug("Error getting messages: " + JSON.stringify(error));
      return caught;
    })


    )})



  updateNotificationState$ = createEffect(() => { return  this.actions.pipe(
    ofType<UpdateNotificationStatusAction>(NotificationsActionType.UpdateNotificationStatusAction),
    withLatestFrom(this.state$),
    mergeMap(([action]) =>
      this.signalmessageService.updateMessageStatus(action.payload.targetUserId, action.payload.type, action.payload.status).pipe(
        map(response => {
          if (response.generalResult != GeneralResultEnum.Success) {

            return new NotificationErrorAction({ error: <SimpleResult>response });
          } else {
            return new UpdateNotificationStatusActionDone({ cnt: response.data });
          }
        })
      )
    ),
    catchError((error, caught) => {
      console.debug("Error updating messages: " + JSON.stringify(error));
      return caught;
    })
    )})



  sendMessagetoGroups$ = createEffect(() => { return  this.actions.pipe(
    ofType<SendMessagetoGroups<SignalMessageContentModel>>(NotificationsActionType.SendMessagetoGroups),
    withLatestFrom(this.state$),
    mergeMap(([action]) =>
      this.signalmessageService.SendMessagetoGroups<SignalMessageContentModel>(action.payload.messcontainergroup, action.payload.checkifexists).pipe(
        map(response => {
          if (response.generalResult != GeneralResultEnum.Success) {
            return new NotificationErrorAction({ error: <SimpleResult>response });
          } else {
            return new SendMessagetoGroupsDone<SignalMessageContentModel>({ signals: response.data });
          }
        })
      )
    ),
    catchError((error, caught) => {
      console.debug("Error sending messages to group: " + JSON.stringify(error));
      return caught;
    })
    )})



  deleteAllMessages$ = createEffect(() => { return  this.actions.pipe(
    ofType<DeleteAllMessagesAction>(NotificationsActionType.DeleteAllMessagesAction),
    withLatestFrom(this.state$),
    mergeMap(([action]) =>
      this.signalmessageService.deleteAllMessages(action.payload.targetUserId, action.payload.messageType).pipe(
        map(response => {
          if (response.generalResult != GeneralResultEnum.Success) {
            return new NotificationErrorAction({ error: <SimpleResult>response });
          } else {
            return new DeleteAllMessagesActionDone({ count: response.data, messageType: action.payload.messageType });
          }
        })
      )
    ),
    catchError((error, caught) => {
      this.store.dispatch(new NotificationErrorAction({ error: <SimpleResult>{ generalResult: GeneralResultEnum.OnlyDebug, userMessage: "Meldungen konnten nicht gelöscht werden", serverMessage: error?.message } }) );
      return caught;
    })

    )})



  deleteSingleMessages$ = createEffect(() => { return  this.actions.pipe(
    ofType<DeleteSingleMessagesAction>(NotificationsActionType.DeleteSingleMessagesAction),
    withLatestFrom(this.state$),
    mergeMap(([action]) =>
      this.signalmessageService.deletesingleMessage(action.payload.targetUserId, action.payload.messageType, action.payload.id).pipe(
        map(response => {
          if (response.generalResult != GeneralResultEnum.Success) {
            return new NotificationErrorAction({ error: <SimpleResult>response });
          } else {
            return new DeleteSingleMessagesActionDone({ messageType: action.payload.messageType, id: action.payload.id, count: response.data });
          }
        })
      )
    ),
    catchError((error, caught) => {
      this.store.dispatch(new NotificationErrorAction({ error: <SimpleResult>{ generalResult: GeneralResultEnum.OnlyDebug, userMessage: "Meldungen konnten nicht gelöscht werden", serverMessage: error?.message } } ));
      return caught;
    })



    )})



}
