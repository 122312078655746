import { ListModel } from '@app/common/models';

export enum ListOverviewActionType {
    OpenEditor = '[LIST-OVERVIEW] OpenEditor',
    GetListsForUser = '[LIST-OVERVIEW] GetListsForUser',
    GetListsDone = '[LIST-OVERVIEW] GetListsDone',
    ShowValidationModal = '[LIST-OVERVIEW] ShowValidationModal',
    SetLoading = '[LIST-OVERVIEW] SetLoadingTrue'
}

export class OpenEditorAction {
    readonly type = ListOverviewActionType.OpenEditor;
}

export class GetListsForUserAction {
    readonly type = ListOverviewActionType.GetListsForUser;
    constructor() { }
}

export class GetListDoneAction {
    readonly type = ListOverviewActionType.GetListsDone;
    constructor( public payload: { items: ListModel[]}) {}
}

export class ShowValidationModalAction {
    readonly type = ListOverviewActionType.ShowValidationModal;
    constructor(public payload: { id: number; }) {}
}

export class SetLoadingAction {
    readonly type = ListOverviewActionType.SetLoading;
    constructor(public payload: { isLoading: boolean; }) {}
}

export type ListOverViewActions = OpenEditorAction | GetListsForUserAction | GetListDoneAction | ShowValidationModalAction | SetLoadingAction;
