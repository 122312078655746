<div class="grid">
    <div class="ui-g-6"><label for="listname" style="padding-left: 15px; margin-top: 6px; margin-right: 10px;">Listenname</label></div>
    <div class="ui-g-6"><input #listname type="text" pInputText [(ngModel)]="validationListToView.listName" /></div>
    <div class="ui-g-6"><label for="isactive" style="padding-left: 15px; margin-right: 10px; margin-top: 6px;">Aktiv</label></div>
    <div class="ui-g-6"><p-checkbox #isactive [style]="{'margin-top': '10px'}" binary="true" [(ngModel)]="validationListToView.isActive"></p-checkbox></div>
    <div class="ui-g-6"><label for="issupplier" style="padding-left: 15px; margin-right: 10px; margin-top: 6px;">Lieferant</label></div>
    <div class="ui-g-6"><p-checkbox #issupplier [style]="{'margin-top': '10px'}" binary="true" [(ngModel)]="validationListToView.isSupplier"></p-checkbox></div>
</div>

<div class="grid" style="margin-top: 20px; float: right; padding-right: 20px;">
    <button pButton type="button" icon="pi pi-times" label="Abbrechen" style="margin-left: 15px;" (click)="cancel()"></button>
    <button pButton type="button" icon="pi pi-check" label="Speichern" style="margin-left: 15px;" (click)="close()"></button>
</div>
