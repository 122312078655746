import { NgModule } from '@angular/core';

import { ListOverviewComponent } from './list-overview.component';
import { CommonModule } from '@angular/common';
import { ListOverviewStateModule } from './list-overview-state.module';

import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ListEditModule } from '@app/+list-editor/listeditmodule';

@NgModule({
    imports: [TableModule, ButtonModule, CommonModule, ListOverviewStateModule,   ListEditModule, ProgressSpinnerModule, TooltipModule],
    exports: [ListOverviewComponent],
    declarations: [ListOverviewComponent],
    providers: [],
})
export class ListOverviewModule { }
