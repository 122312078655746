import { TaskUserModel } from "@app/common/models";
import { LiPoContact } from "@app/common/models/lipocontact.model";
import { MessageTypeEnum, SendMessageTypeContainer, SignalMessageContentModel } from "@app/common/models/signal.model";


export enum ArticleDataActionType {

  GetArticleData = '[ArticleData] GetData',
  GetArticleDataDone = '[ArticleData] GetDataDone',
  ArticleDataErrorAction = '[ArticleData] DataWrrorAction',
  DeleteArticleDataActionDone = "[ArticleData] DeleteArticleDataActionDone",
  DeleteArticleDataAction = "[ArticleData] DeleteArticleDataAction",
  UpdateArticleDataAction = "[ArticleData] UpdateArticleDataAction",
  UpdateArticleDataActionDone = "[ArticleData] UpdateArticleDataActionDone",

  GetSoMaContactsAction = "GetSoMaContactsAction",
  GetSoMaContactsActionDone = "GetSoMaContactsActionDone",
  InsertArticleDataAction = "InsertArticleDataAction",
  InsertArticleDataActionDone = "InsertArticleDataActionDone",
  GetArticleDataValidationsAction = "GetArticleDataValidationsAction",
  GetArticleDataValidationsActionDone = "GetArticleDataValidationsActionDone",


  ClearArticleDataSuccessResults = "ClearSuccessObjects",

  GetSuppliersContactsActionDone = "[ArticleData] GetSuppliersContactsActionDone",
  GetArticleDataSuppliersContactsAction = "[ArticleData] GetArticleDataSuppliersContactsAction",
  RemoveSuccessResultAction = "[ArticleData] RemoveSuccessResultAction",
  ClearArticleErrors = "ClearArticleErrors"
}


export class GetArticleDataAction {
  readonly type =  ArticleDataActionType.GetArticleData ;
  constructor( public payload: { filterOptions: string, sortoptions: string, somaAlle: boolean, skip: number, take: number }) {}
}


export class GetArticleDataActionDone {
  readonly type =  ArticleDataActionType.GetArticleDataDone ;
  constructor( public payload: { items: TaskUserModel[]  }) {}
}



export class DeleteArticleDataAction {
  readonly type =  ArticleDataActionType.DeleteArticleDataAction ;
  constructor( public payload: { id: number, isManager: boolean }) {}
}

export class DeleteArticleDataActionDone {
  readonly type =  ArticleDataActionType.DeleteArticleDataActionDone ;
  constructor( public payload: { id: number  }) {}
}


export class UpdateArticleDataAction {
  readonly type =  ArticleDataActionType.UpdateArticleDataAction ;
  constructor( public payload: { item: TaskUserModel, successmessage: boolean }) {}
}

export class UpdateArticleDataActionDone {
  readonly type =  ArticleDataActionType.UpdateArticleDataActionDone ;
  constructor( public payload: { changeditem: TaskUserModel, successmessage: boolean  }) {}
}


export class InsertArticleDataAction {
  readonly type =  ArticleDataActionType.InsertArticleDataAction ;
  constructor( public payload: { item: TaskUserModel }) {}
}


export class InsertArticleDataActionDone {
  readonly type =  ArticleDataActionType.InsertArticleDataActionDone ;
  constructor( public payload: { newitem: TaskUserModel  }) {}
}



export class GetArticleDataSoMaContactsAction {
  readonly type =  ArticleDataActionType.GetSoMaContactsAction ;
  constructor( ) {}
}


export class GetArticleDataSoMaContactsActionDone {
  readonly type =  ArticleDataActionType.GetSoMaContactsActionDone ;
  constructor( public payload: { somaContacts: LiPoContact[]  }) {}
}





export class ArticleDataErrorAction {
  readonly type =  ArticleDataActionType.ArticleDataErrorAction ;
  constructor( public payload: { error: any  }) {}
}

export class GetArticleDataValidationsAction {
  readonly type =  ArticleDataActionType.GetArticleDataValidationsAction ;
  constructor( ) {}
}

export class GetArticleDataValidationsActionDone {
  readonly type =  ArticleDataActionType.GetArticleDataValidationsActionDone ;
  constructor( public payload: { validationlist: any[]  }) {}
}

export class ClearArticleDataSuccessRsults {
  readonly type =  ArticleDataActionType.ClearArticleDataSuccessResults ;
}

export class ClearArticleErrors {
  readonly type =  ArticleDataActionType.ClearArticleErrors ;
}


export type ArticleDataAction =  GetArticleDataAction | GetArticleDataActionDone | ArticleDataErrorAction
| DeleteArticleDataAction | DeleteArticleDataActionDone
| UpdateArticleDataAction | UpdateArticleDataActionDone
|InsertArticleDataAction |InsertArticleDataActionDone
| GetArticleDataSoMaContactsAction |GetArticleDataSoMaContactsActionDone
| GetArticleDataValidationsActionDone | GetArticleDataValidationsAction
 | ClearArticleDataSuccessRsults

| GetArticleDataValidationsActionDone | GetArticleDataValidationsAction
 ClearArticleErrors

;
