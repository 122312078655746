import { NgModule } from '@angular/core';
import { CalendarRoutingModule } from './calendar-routing.module';
import { CalendarComponent } from './calendar.component';
import { CommonModule } from '@angular/common';
import { NotificationsModule } from '@app/+notifications/notifications.module';
import { ListOverviewModule } from '@app/+list-overview/list-overview.module';

import { FormsModule } from '@angular/forms';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ToolbarModule} from 'primeng/toolbar';
import {SpinnerModule} from 'primeng/spinner';
import {ButtonModule} from 'primeng/button';
import {RadioButtonModule} from 'primeng/radiobutton';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import { InputNumberModule } from 'primeng/inputnumber';
import {CalendarStateModule} from '@app/+calendar/calendar-state.module'
import { TreeTableModule } from 'primeng/treetable';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { SomaTaskViewDialogComponent } from '@app/dialogs/taskViewDialogs/soma-task-view-dialog/soma-task-view-dialog.component';
import { SupplierTaskDialogComponent } from '@app/dialogs/taskViewDialogs/supplier-task-dialog/supplier-task-dialog.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatIconModule } from '@angular/material/icon';
import { FullCalendarModule } from '@fullcalendar/angular';


@NgModule({
    imports: [
        CalendarRoutingModule, CommonModule, NotificationsModule, ListOverviewModule, FullCalendarModule, FormsModule, ProgressSpinnerModule,

        ToolbarModule, SpinnerModule, RadioButtonModule, CalendarStateModule, OverlayPanelModule, TreeTableModule, CheckboxModule, DropdownModule,
        MultiSelectModule, TableModule, NgxChartsModule, InputNumberModule, ButtonModule, MatIconModule
    ],
    exports: [CalendarComponent, SomaTaskViewDialogComponent, SupplierTaskDialogComponent],
    declarations: [
        CalendarComponent, SomaTaskViewDialogComponent, SupplierTaskDialogComponent
    ],
    providers: []
})
export class CalendarModule { }
